export default class TaskHelpers {

    static getSolutionTextByVerdict(verdict) {

        const verdictToText = {
            notReady: "Отправлено на проверку",
        };

        if (verdictToText[verdict]) {

            return verdictToText[verdict];

        }

        return null;

    }

}
