import React                              from "react";
import { VBox }                           from "@sirius/ui-lib/src/blocks/Layout";
import {SmtLocalePlural, SmtLocaleString} from "Smt/SmtLocaleBlock"
import { block }                          from "./block";
import type { HasLimits }                 from "./block";

const Limits = ( {limits}:HasLimits ) =>

    <VBox className={block.el('limits')}>

        <h5 className={block.el('limits-head')}>
           <SmtLocaleString k="programming.limits.header"/>
        </h5>

        <ul className={block.el('limits-list')}>
            {!!limits.realTime
            && <li className={block.el('limits-item')} >
                <SmtLocaleString k={`programming.limits.realTime`}/>:
                {` ${limits.realTime} `}
                <SmtLocalePlural k="common.format_plural.seconds" value={limits.realTime}/>
            </li>}

            {!!limits.time
            && <li className={block.el('limits-item')}>
                <SmtLocaleString k={`programming.limits.time`}/>:
                {` ${limits.time} `}
                <SmtLocalePlural k="common.format_plural.seconds" value={limits.time}/>
            </li>}

            {!!limits.maxMem
            && <li className={block.el('limits-item')}>
                <SmtLocaleString k={`programming.limits.maxMem`}/>:
                {` ${limits.maxMem} MB`}
            </li>}

            {!!limits.stackMem
            && <li className={block.el('limits-item')}>
                <SmtLocaleString k={`programming.limits.stackMem`}/>:
                {` ${limits.stackMem} MB`}
            </li>}

            {!!limits.compilationTime
            && <li className={block.el('limits-item')}>
                <SmtLocaleString k={`programming.limits.compilationTime`}/>:
                {` ${limits.compilationTime} `}
                <SmtLocalePlural k="common.format_plural.seconds" value={limits.compilationTime}/>
            </li>}

            {!!limits.charactersCount
            && <li className={block.el('limits-item')}>
                <SmtLocaleString k={`programming.limits.charactersCount`}/>:
                {` ${limits.charactersCount}`}
            </li>}
        </ul>
    </VBox>
;

export {Limits};
