import React                 from "react";
import {i18nStr, withLocale} from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {ELocales}            from "@sirius/ui-lib/src/subjects/Locale";

type Props = {
    tpl:           any;
    locale:        ELocales;
    placeholder:   string;
    params:        Record<string, any>;
    inputMaxWidth: number;
    inline:        boolean;
    type:          string;
    innerRef:      (ref: HTMLInputElement) => void;
};

const getSuitablePlaceholder = (placeholder: string, inputMaxWidth: string | number, inline: boolean): string => {

    if (!inline) {

        return placeholder;

    }

    return placeholder.length < inputMaxWidth ? placeholder : "";

};

const FormattedInput = ({tpl, locale, placeholder, inputMaxWidth, inline, innerRef, type, ...params}: Props): React.ReactNode => {

    let inputPlaceholder = "";

    const label = i18nStr({tpl, id: "value", locale});

    if (label) {

        inputPlaceholder = label;

    }

    if (placeholder || placeholder === "") {

        inputPlaceholder = placeholder;

    }

    inputPlaceholder  = getSuitablePlaceholder(inputPlaceholder, inputMaxWidth, inline);
    const charWidthPx = 17;
    const widthInPx   = inputMaxWidth ? inputMaxWidth * charWidthPx : 0;

    const calcWidth  = widthInPx > 720
                           ? '100%'
                           : `${widthInPx}px`
    ;

    const calcMarginRight = widthInPx > 720 ? '0' : '-20px';

    const widthLimit =  type === 'number' &&
                        inline            &&
                        inputMaxWidth
                            ? {
                                width: calcWidth,
                                maxWidth: '100%',
                                marginRight: calcMarginRight
                            }
                            : {}
    ;


    return <input
        {...params}
        ref         = {innerRef}
        placeholder = {inputPlaceholder}
        style       = {{...widthLimit}}
    />;

};

const LocalizedInput = withLocale(FormattedInput);
// @ts-ignore
LocalizedInput.displayName = 'LocalizedInput';

export {LocalizedInput};
