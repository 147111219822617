import React, {FC}                              from "react";
import bnc                                      from "bnc";
import {HBox, LGap}                             from "@sirius/ui-lib/src/blocks/Layout";
import {Icon}                                   from "@sirius/ui-lib/src/blocks/Controls";
import {TaskClientValidationLabelType}          from "./@types";
import {iconLevels}                             from "./IconLevels";
import                                               "./TaskClientValidationLabel.less"

export const block = new bnc('task-client-validation-label');

export const TaskClientValidationLabel : FC<TaskClientValidationLabelType> = ({report:{level, message}}) =>
    <HBox className={block + block.mod('level', level)}>
        <Icon icon={iconLevels[level]}/>
        <LGap/>
        <span>{message}</span>
    </HBox>
;
