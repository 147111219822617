import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const Col1Icon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="7" width="18" height="4" rx="1" fill="#C4C4C4" />
        <rect x="3" y="13" width="18" height="4" rx="1" fill="#C4C4C4" />
    </svg>
</SvgIcon>;


export default Col1Icon;
