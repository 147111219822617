type TranslationsType = {
    [key: string]: Project;
};

type Project = {
    [key: string]: Record<string, any>;
};

export const translations: TranslationsType = {
    cheops: {
        solution_to_show_popup: {
            right_answer: {
                ru: {value: "Показать верный ответ"},
                en: {value: "Show the correct answer"},
            },
            my_answer: {
                ru: {value: "Показать мой ответ"},
                en: {value: "Show my answer"},
            },
        },
        multiple_answer: {
            placeholder_text: {
                ru: {value: "Ответ"},
                en: {value: "Answer"},
            },
            placeholder_number: {
                ru: {value: "Число"},
                en: {value: "Number"},
            },
            placeholder_ratio: {
                ru: {value: "Число или дробь"},
                en: {value: "Number or fraction"},
            },
            delete_answer: {
                ru: {value: "Удалить ответ"},
                en: {value: "Delete answer"},
            },
            hint: {
                ru: {value: "Ответ можно ввести в десятичном виде (3.45726), как обыкновенную дробь (1/5) и как смешанную дробь"},
                en: {value: "The answer can be entered in decimal form (3.45726), as an ordinary fraction (1/5) and as a mixed fraction"},
            },
            add_answer: {
                ru: {value: "Добавить ответ"},
                en: {value: "Add answer"},
            },
        },
        fraction_preview: {
            default_text: {
                ru: {value: "Здесь мы показываем, как видим ваш ответ"},
                en: {value: "You will see here how your answer is recognized by the system"},
            },
        },
        select_option_answer: {
            default_select: {
                ru: {
                    placeholder: "Выбрать",
                    no_option: "Нет вариантов",
                },
                en: {
                    placeholder: "Select",
                    no_option: "No variants",
                },
            },
            answer_select: {
                ru: {
                    placeholder: "Ответ",
                },
                en: {
                    placeholder: "Answer",
                },
            },
        },
        solutions: {
            placeholder_text: {
                ru: {value: "Ответ"},
                en: {value: "Answer"},
            },
            placeholder_number: {
                ru: {value: "Число"},
                en: {value: "Number"},
            },
            placeholder_ratio: {
                ru: {value: "Число или дробь"},
                en: {value: "Number or fraction"},
            },
        },
        copyright: {
            univ: {
                ru: {value: `©\xa0Образовательный\xa0Фонд «Талант\xa0и\xa0успех», 2019–{currentYear}`},
                en: {value: `©\xa0Educational\xa0Foundation “Talent\xa0and\xa0Success”, 2019–{currentYear}`},
            },
            corp: {
                ru: {value: `©\xa0Образовательный\xa0Фонд «Талант\xa0и\xa0успех», 2019–{currentYear}`},
                en: {value: `©\xa0Educational\xa0Foundation “Talent\xa0and\xa0Success”, 2019–{currentYear}`},
            },
            cpm: {
                ru: {value: `© ГАОУ ДПО ЦПМ, 2018–{currentYear} {br} ©\xa0Фонд\xa0«Талант\xa0и\xa0успех», 2018–{currentYear}`},
                en: {value: `© Center of Pedagogical Excellence, 2018–{currentYear} {br} © Educational Foundation “Talent and Success”, 2018–{currentYear}`},
            },
            sirius: {
                ru: {value: `©\xa0Образовательный\xa0Фонд «Талант\xa0и\xa0успех»,  2019–{currentYear}`},
                en: {value: `©\xa0Educational\xa0Foundation “Talent\xa0and\xa0Success”, 2019–{currentYear}`},
            },
            textbook: {
                ru: {value: `©\xa0Образовательный\xa0Фонд «Талант\xa0и\xa0успех»,  2019–{currentYear}`},
                en: {value: `©\xa0Educational\xa0Foundation “Talent\xa0and\xa0Success”, 2019–{currentYear}`},
            },
        },
    },
    latex: {
        errors: {
            too_long: {
                ru: {value: "Кажется, вы ошиблись. Ответ должен быть короче"},
                en: {value: "It seems answer must take less symbol"},
            },
            cant_parse: {
                ru: {value: "Мы не можем понять запись"},
                en: {value: "We can't recognize your input"},
            },
            convert: {
                ru: {value: "Ошибка при конвертации"},
                en: {value: "Convertation error"},
            },
        },
    },

};
