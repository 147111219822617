import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const ModulesAchievedIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>

    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11 0.5C10.1716 0.5 9.5 1.17157 9.5 2C9.5 2.82843 10.1716 3.5 11 3.5C15.1421 3.5 18.5 6.85786 18.5 11C18.5 15.1421 15.1421 18.5 11 18.5C6.85786 18.5 3.5 15.1421 3.5 11C3.5 10.1716 2.82843 9.5 2 9.5C1.17157 9.5 0.5 10.1716 0.5 11C0.5 16.799 5.20101 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5ZM11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16Z"
              fill="#63BF13"
        />
    </svg>


</SvgIcon>;


export default ModulesAchievedIcon;
