import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const UngroupIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 12C17.45 12 17 12.45 17 13V18.22C17 18.77 16.55 19.22 16 19.22H6C5.45 19.22 5 18.77 5 18.22V8C5 7.45 5.45 7 6 7H11C11.55 7 12 6.55 12 6C12 5.45 11.55 5 11 5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V13C19 12.45 18.55 12 18 12Z"
            fill="#909090"
        />
        <path
            d="M19.4309 3.15645L18.0026 4.58481L16.5742 3.15645C16.1924 2.77462 15.5701 2.77462 15.1883 3.15645L15.1671 3.17767C14.7782 3.56658 14.7782 4.18883 15.16 4.57067L16.5883 5.99902L15.1671 7.42031C14.7852 7.80215 14.7852 8.4244 15.16 8.81331C15.1671 8.82038 15.1741 8.82745 15.1812 8.83452C15.5701 9.22343 16.1924 9.22343 16.5813 8.83452L18.0026 7.41324L19.4238 8.83452C19.8057 9.21636 20.435 9.22343 20.8168 8.82745C20.8239 8.82038 20.831 8.81331 20.8381 8.80624C21.2199 8.4244 21.2199 7.80215 20.8381 7.42031L19.4168 5.99902L20.8451 4.57067C21.227 4.18883 21.227 3.56658 20.8451 3.18474L20.8168 3.15645C20.435 2.77462 19.8128 2.77462 19.4309 3.15645Z"
            fill="#909090"
        />
        <path d="M14 9H8C7.45 9 7 9.45 7 10C7 10.55 7.45 11 8 11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9Z"
              fill="#909090"
        />
        <path d="M14 12H8C7.45 12 7 12.45 7 13C7 13.55 7.45 14 8 14H14C14.55 14 15 13.55 15 13C15 12.45 14.55 12 14 12Z"
              fill="#909090"
        />
        <path d="M14 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17H14C14.55 17 15 16.55 15 16C15 15.45 14.55 15 14 15Z"
              fill="#909090"
        />
    </svg>

</SvgIcon>;


export default UngroupIcon;
