import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";
import {SvgIcon} from "@material-ui/core";

const UnpublishResultsIcon: React.FC<CustomIconsProps> = ({className}) =>
    <SvgIcon className={className}>
        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.24946 3.06779L0.193448 2.01312C0.0573178 1.87698 -0.0069657 1.71437 0.00059706 1.52529C0.00815982 1.33621 0.080006 1.1736 0.216136 1.03746C0.352266 0.901321 0.511084 0.833252 0.69259 0.833252C0.874096 0.833252 1.03291 0.901321 1.16904 1.03746L17.6037 17.4512L17.851 17.335L18.7589 16.899C18.7589 16.899 17.9068 17.3098 17.6046 17.4521L18.1399 17.9867C18.276 18.1229 18.3403 18.2855 18.3327 18.4745C18.3252 18.6636 18.2533 18.8262 18.1172 18.9624C17.9811 19.0985 17.8223 19.1666 17.6407 19.1666C17.4592 19.1666 17.3004 19.0985 17.1643 18.9624L15.7 17.4999H12.5C12.0417 17.4999 11.6667 17.1249 11.6667 16.6666C11.6667 16.2083 12.0417 15.8333 12.5 15.8333H14.0312L2.67455 4.49107C2.56524 4.63208 2.5 4.80875 2.5 4.99992V5.83325C2.5 6.29158 2.125 6.66658 1.66667 6.66658C1.20833 6.66658 0.833333 6.29158 0.833333 5.83325V4.16658C0.833333 3.74665 0.990736 3.36168 1.24946 3.06779Z" fill="#999999"/>
            <path d="M18.7589 16.899L17.5 15.6335V4.99992C17.5 4.54158 17.125 4.16658 16.6667 4.16658H6.01562L4.35221 2.49992H17.5C18.4167 2.49992 19.1667 3.24992 19.1667 4.16658V15.8333C19.1667 15.9223 19.1686 16.0019 19.1702 16.0737C19.1803 16.4968 19.1839 16.6518 18.7589 16.899Z" fill="#999999"/>
            <path d="M1.78333 11.7416C1.28333 11.6583 0.833333 12.0666 0.833333 12.5749C0.833333 12.9833 1.13333 13.3249 1.54167 13.3916C3.275 13.6916 4.64167 15.0583 4.94167 16.7916C5.00833 17.1999 5.35 17.4999 5.75833 17.4999C6.26667 17.4999 6.66667 17.0499 6.59167 16.5499C6.19167 14.0833 4.24167 12.1416 1.78333 11.7416Z" fill="#999999"/>
            <path d="M0.833333 14.9999V17.4999H3.33333C3.33333 16.1166 2.21667 14.9999 0.833333 14.9999Z" fill="#999999"/>
            <path d="M1.75 8.37492C1.25833 8.33325 0.833333 8.71658 0.833333 9.20825C0.833333 9.63325 1.15 9.99158 1.56667 10.0333C5.125 10.3749 7.95833 13.2083 8.3 16.7666C8.34167 17.1833 8.7 17.4916 9.125 17.4916C9.625 17.4916 10.0083 17.0583 9.95833 16.5666C9.51667 12.2416 6.075 8.80825 1.75 8.37492Z" fill="#999999"/>
            <path d="M13.8 8.70825C13.7445 8.73603 13.6958 8.74992 13.6542 8.74992L13.5083 8.70825L10.8 7.14575C10.7445 7.11797 10.7028 7.07978 10.675 7.03117C10.6472 6.98256 10.6333 6.93047 10.6333 6.87492C10.6333 6.81936 10.6472 6.76728 10.675 6.71867C10.7028 6.67006 10.7445 6.63186 10.8 6.60408L13.5083 5.04158C13.5639 5.01381 13.6125 4.99992 13.6542 4.99992L13.8 5.04158L16.5083 6.60408C16.5639 6.63186 16.6056 6.67006 16.6333 6.71867C16.6611 6.76728 16.675 6.81936 16.675 6.87492C16.675 6.93047 16.6611 6.98256 16.6333 7.03117C16.6056 7.07978 16.5639 7.11797 16.5083 7.14575L13.8 8.70825Z" fill="#999999"/>
            <path d="M13.8104 11.2083C13.7618 11.2221 13.7097 11.2291 13.6542 11.2291C13.5986 11.2291 13.5465 11.2221 13.4979 11.2083C13.4493 11.1944 13.3972 11.1735 13.3417 11.1458L11.675 10.1874C11.5778 10.1319 11.5014 10.0555 11.4458 9.95825C11.3903 9.86103 11.3625 9.75686 11.3625 9.64575V8.24992L13.6542 9.58325L15.9458 8.24992V9.64575C15.9458 9.75686 15.9181 9.86103 15.8625 9.95825C15.807 10.0555 15.7306 10.1319 15.6333 10.1874L13.9667 11.1458C13.9111 11.1735 13.859 11.1944 13.8104 11.2083Z" fill="#999999"/>
        </svg>
    </SvgIcon>
;

export default UnpublishResultsIcon;
