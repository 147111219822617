import React                       from 'react';
import {Icon as MaterialIcon}      from "@sirius/ui-lib/src/blocks/Controls";
import BemClassName                from "Cheops/BemClassName";
import ModuleIcon                  from "Lab/components/UI2/Atoms/Icons/ModuleIcon";
import UploadIcon                  from "Lab/components/UI2/Atoms/Icons/UploadIcon";
import SolutionToShowIcon          from "Lab/components/UI2/Atoms/Icons/SolutionToShowIcon";
import DiagnosticsIcon             from "Lab/components/UI2/Atoms/Icons/DiagnosticsIcon";
import PasteIcon                   from "Lab/components/UI2/Atoms/Icons/PasteIcon";
import AddOutlineIcon              from "Lab/components/UI2/Atoms/Icons/AddOutlineIcon";
import PublishIcon                 from "Lab/components/UI2/Atoms/Icons/PublishIcon";
import MoveIcon                    from "Lab/components/UI2/Atoms/Icons/MoveIcon";
import EnvironmentIcon             from "Lab/components/UI2/Atoms/Icons/EnvironmentIcon";
import DoNotTouchIcon              from "Lab/components/UI2/Atoms/Icons/DoNotTouchIcon";
import SwitcherOffIcon             from "Lab/components/UI2/Atoms/Icons/SwitcherOffIcon";
import SwitcherOnIcon              from "Lab/components/UI2/Atoms/Icons/SwitcherOnIcon";
import SiriusIcon                  from "Lab/components/UI2/Atoms/Icons/SiriusIcon";
import ExportIcon                  from "Lab/components/UI2/Atoms/Icons/ExportIcon";
import ArchivedIcon                from "Lab/components/UI2/Atoms/Icons/ArchivedIcon";
import InviteIcon                  from "Lab/components/UI2/Atoms/Icons/InviteIcon";
import ModulesAchievedIcon         from "Lab/components/UI2/Atoms/Icons/ModulesAchievedIcon";
import AdvancedModulesAchievedIcon from "Lab/components/UI2/Atoms/Icons/AdvancedModulesAchievedIcon";
import UserIdIcon                  from "Lab/components/UI2/Atoms/Icons/UserIdIcon";
import Col1Icon                    from "Lab/components/UI2/Atoms/Icons/Col1Icon";
import InlineIcon                  from "Lab/components/UI2/Atoms/Icons/InlineIcon";
import GroupIcon                   from "Lab/components/UI2/Atoms/Icons/GroupIcon";
import DownloadsIcon               from "Lab/components/UI2/Atoms/Icons/DownloadsIcon";
import UngroupIcon                 from "Lab/components/UI2/Atoms/Icons/UngroupIcon";
import PublishResultsIcon          from "Lab/components/UI2/Atoms/Icons/PublishResultsIcon";
import UnpublishResultsIcon        from "Lab/components/UI2/Atoms/Icons/UnpublishResultsIcon";
import TeacherMaterialsIcon        from "Lab/components/UI2/Atoms/Icons/TeacherMaterialsIcon";
import {UIAtom, UIAtomProps}       from '../UIAtom';
import './IconAtom.less';

export enum ICON_SIZES {
    SM = "small",
    MD = "medium",
    LG = "large",
}

interface Props extends UIAtomProps {

    name: string;
    size?: ICON_SIZES;
    active?: boolean;
    className?: string;
    darkMode?: boolean;

}


export interface CustomIconsProps {
    className: string;
}

const customIcons: Record<string, React.FunctionComponent<CustomIconsProps>> = {

    module: ModuleIcon,
    upload: UploadIcon,
    downloads: DownloadsIcon,
    solution_to_show: SolutionToShowIcon,
    diagnostics: DiagnosticsIcon,
    paste: PasteIcon,
    add_outline: AddOutlineIcon,
    do_not_touch: DoNotTouchIcon,
    publish: PublishIcon,
    move: MoveIcon,
    environment: EnvironmentIcon,
    switcher_off: SwitcherOffIcon,
    switcher_on: SwitcherOnIcon,
    sirius: SiriusIcon,
    export: ExportIcon,
    archived: ArchivedIcon,
    invite: InviteIcon,
    modulesAchieved: ModulesAchievedIcon,
    advancedModulesAchieved: AdvancedModulesAchievedIcon,
    user_id: UserIdIcon,
    ordinaryCols: Col1Icon,
    inline: InlineIcon,
    group: GroupIcon,
    ungroup: UngroupIcon,
    publish_results: PublishResultsIcon,
    unpublish_results: UnpublishResultsIcon,
    teacher_materials: TeacherMaterialsIcon,
};

export default class IconAtom extends UIAtom<Props> {

    static defaultProps = {
        ...UIAtom.defaultProps,
        size: ICON_SIZES.LG,
        active: false,
        className: "",
        darkMode: false,
    };

    render(): React.ReactNode {

        const {
            size,
            hasErrors,
            disabled,
            active,
            name,
            className,
            darkMode,
        } = this.props;

        const iconClassName = new BemClassName('icon_atom', [], className);

        iconClassName.appendStatusIf(hasErrors, 'has-errors');
        iconClassName.appendStatusIf(disabled, 'disabled');
        iconClassName.appendStatusIf(active, 'active');
        iconClassName.appendStatusIf(darkMode, 'dark-mode');
        iconClassName.appendStatusIf(!!size, `size-${size}`);



        if (customIcons[name]) {
            iconClassName.appendStatus('custom');
            iconClassName.appendAdditionalClasses('material-icons-outlined');
            const IconComponent = customIcons[name];

            return <IconComponent className={iconClassName.toString()} />;
        }

        return <MaterialIcon icon={`${name}`} className={iconClassName.toString()} /> ;

    }

}
