import React, {FC, useEffect, useState}                 from "react";
import bnc                                              from "bnc";
import {useLocale}                                      from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import {VBox}                                           from "@sirius/ui-lib/src/blocks/Layout";
import {TaskElementOrdering2 as TaskElementOrdering}    from '@sirius/ui-shared/src/components/Task/Elements/Ordering2';
import Marked                                           from '@sirius/ui-shared/src/components/DisplayEngine/Marked';
import {OrderingAnswerProps, ItemId, HasItemId}         from "./@types";
import                                                       "./index.less";

const block = new bnc("ordering-answer");

const getApiUrl = () =>
    'smtWebPortal' in CONFIG.Api
        ? CONFIG.Api.smtWebPortal.url
        : CONFIG.Api.webPortal.url
;

const getItemContent = (solution: LabSmt.AnswerItem, isSmt = false, imgClassName: string) =>
    'image' in solution
        ? <img
            draggable = "false"
            src       = {`${getApiUrl()}/content/_image/${solution.image}`}
            key       = {`order-elements-${solution.id}`}
            className = {imgClassName}
          />
        : 'formula' in solution
            ? <Marked>{solution.formula}</Marked>
            : `${solution?.value}`
;

const getSolutionByUserAnswer = (userAnswer: ItemId[], answers: HasItemId[]) => (
    (userAnswer || []).filter( a => a !== null ).map(
        (id: ItemId) => answers.find(
            ({id: answerId}: HasItemId) => (id === answerId)
        )
    )
)

const pickId = ({id}: {id:ItemId}) => id;

const OrderingAnswerComponent:FC<OrderingAnswerProps> = ({
    userAnswer,
    elementContent,
    readOnly,
    onAnswer,
    isSmt
}) => {

    const {answersData} = elementContent;

    const {
        isFirstFixed,
        isLastFixed,
        listOrientation,
        answers,
        partialAnswer
    } = answersData as SmtTask.AnswersData;


    const [solution, setSolution] = useState(getSolutionByUserAnswer(userAnswer, answers));

    useEffect(
        ()=>{
            setSolution(
                getSolutionByUserAnswer(userAnswer, answers)
            );
        }
        ,[userAnswer]
    )

    const onSolutionChange = (solution: any[]) => {
        const next = solution.map( pickId );
        const min = isFirstFixed
                        ? isLastFixed ? 2 : 1
                        : isLastFixed ? 1 : 0
        ;

        const nextFixedFiltered = next.length > min ? next : null;
        let nextIsFilled;
        if (nextFixedFiltered === null) {
            nextIsFilled = null;
        } else {
            const diff = answers.length - (nextFixedFiltered || []).length;
            nextIsFilled = partialAnswer === false
            ? diff > 0
                ? [...nextFixedFiltered, ...(new Array(diff).fill(null))]
                : nextFixedFiltered
            : nextFixedFiltered
        }


        onAnswer( nextIsFilled );
    };

    const locale   = useLocale();

    const solutionHeader = (isSmt && locale === 'en') ? 'Arrange in the correct order' : 'Расставьте в верной последовательности';

    return  <VBox className={block}>
                <TaskElementOrdering
                    solutionHeader   = {solutionHeader}
                    solution         = {solution}
                    answers          = {answers}
                    onSolutionChange = {onSolutionChange}
                    isFirstFixed     = {isFirstFixed}
                    isLastFixed      = {isLastFixed}
                    getItemContent   = {(solution) => getItemContent(solution, isSmt, block.el('image'))}
                    direction        = {listOrientation}
                    readonly         = {readOnly}
                    isSmt            = {isSmt}
                />
            </VBox>
    ;
}

export const OrderingAnswer = React.forwardRef<any, OrderingAnswerProps>(
    (props, ref) =>
        <OrderingAnswerComponent
            {...props}
        />
);

OrderingAnswer.displayName = "OrderingAnswer";
