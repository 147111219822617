import React                 from "react";
import {i18nStr, withLocale} from "@sirius/ui-lib/src/blocks/LocaleBlock";
import { DropdownSelect }    from "@sirius/ui-lib/src/blocks/DropdownSelect";
import {ELocales}            from "@sirius/ui-lib/src/subjects/Locale";

type Props = {
    tpl: any;
    locale: ELocales;
    placeholder: string;
    params: Record<string, any>;
    inputMaxWidth: number;
    inline: boolean;
};

const getSuitablePlaceholder = (placeholder: string, inputMaxWidth: string | number, inline: boolean): string =>
    inline
        ? placeholder.length < inputMaxWidth
            ? placeholder
            : ""
        : placeholder
;

const FormattedSelect = ({tpl, locale, inputMaxWidth, inline, ...params}: Props): React.ReactNode => {
    const placeholder = i18nStr({tpl, id: "placeholder", locale});
    return  <DropdownSelect
                {...params}
                inline={inline}
                inlineMaxWidth={inputMaxWidth}
                placeholder={getSuitablePlaceholder(placeholder, inputMaxWidth, inline)}
            />
    ;
};

const LocalizedSelect = withLocale(FormattedSelect);

export {LocalizedSelect};
