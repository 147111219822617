import React, {useEffect, useState} from "react";
import bnc                          from "bnc";
import {useFilePicker}              from "use-file-picker";
import {UseFilePickerConfig}        from "use-file-picker/dist/interfaces";
import MessagePopup                 from "Cheops/components/MessagePopup";
import Helpers                      from "Cheops/Helpers";
import                                   "./CodeFromFileUploader.less";
import {
    PROGRAMMING_ANSWER_FILE_SIZE_LIMIT_BYTES
}                                   from "Cheops/constants";
import {SmtLocaleString}            from "Smt/SmtLocaleBlock";

const block = new bnc('code-uploader');

const filePickerOptions: UseFilePickerConfig = {
    readAs:      'Text',
    multiple:    false,
    accept:      '.js, .cpp, .css, .java, .md, .hs, .py, .c, .pas, .cs, .go, .rs, .php, .kum',
    maxFileSize: PROGRAMMING_ANSWER_FILE_SIZE_LIMIT_BYTES,
};

type Props = {
    fillFromFile: (code: string) => void;
};

const CodeFromFileUploader = ({fillFromFile}: Props) => {

    const [openFileSelector, {filesContent, errors, clear}] = useFilePicker(filePickerOptions);
    const [tooLarge, setTooLarge]                           = useState<boolean>(false);

    useEffect(() => {
        if (filesContent.length > 0) {

            fillFromFile(filesContent[0].content);

        }
    },[filesContent])

    useEffect(() => {
        if (errors[0] && errors[0].fileSizeToolarge) {

            setTooLarge(true);

        }
    },[errors])

    return (
        <div onClick={(event) => event.stopPropagation()}>

            <button type      = {'button'}
                    className = {`${block}`}
                    onClick   = {() => openFileSelector()}
            >
                <SmtLocaleString k="programming.codeeditor.filebtn"/>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H6C5.45 4 5 4.45 5 5ZM7.41 14H9V19C9 19.55 9.45 20 10 20H14C14.55 20 15 19.55 15 19V14H16.59C17.48 14 17.93 12.92 17.3 12.29L12.71 7.7C12.32 7.31 11.69 7.31 11.3 7.7L6.71 12.29C6.08 12.92 6.52 14 7.41 14Z"
                        fill="#8C8695"
                    />
                </svg>
            </button>

            {tooLarge
            && <MessagePopup
                rightButtonText="Хорошо"
                onRightClick = {() => {
                    setTooLarge(false);
                    clear();
                }}
            >
                <h2>Файл слишком большой</h2>
                {`Максимальный размер — ${Helpers.getHumanReadableSize(PROGRAMMING_ANSWER_FILE_SIZE_LIMIT_BYTES)}`}
            </MessagePopup>}
        </div>

    )
}

export default CodeFromFileUploader;
