import Axios from "axios";
import React from 'react'
import {renderErrorPage} from "./helpers";

const getUrl = (url) => {
    const currentOrigin = document.location.origin;
    let url_obj = new URL(url.startsWith('/') ? `${currentOrigin}${url}` : url );
    const _href = url_obj.href;
    const res   = !_href.startsWith(currentOrigin) ? _href.replace( url_obj.origin, currentOrigin ) : _href;
    return res;
}


export default class AjaxWrapper {


    static get(url, data = {}, params = {}) {

        params.method = 'GET';

        return AjaxWrapper.request(url, data, params);
    }


    static post(url, data = {}, params = {}) {

        params.method = 'POST';

        return AjaxWrapper.request(url, data, params);
    }

    static delete(url, data = {}, params = {}) {

        params.method = 'DELETE';

        return AjaxWrapper.request(url, data, params);
    }

    static put(url, data = {}, params = {}) {

        params.method = 'PUT';

        return AjaxWrapper.request(url, data, params);
    }

    static patch(url, data = {}, params = {}) {

        params.method = 'PATCH';

        return AjaxWrapper.request(url, data, params);
    }

    static request(url, data = {}, params = {}) {

        let default_params = {
            method: "get",
            auth_token: null,
            dataHandler: null,
            throwExceptionOnError: false,
            returnResult: false,
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        params = Object.assign(default_params, params);

        if (params.auth_token) {
            params.headers['Authorization'] = "Bearer " + params.auth_token;
        }

        if (params.method === "get" || params.method === "GET") {

            if (typeof data === 'object') {

                let url_obj = new URL(url, location.origin);

                for (let key in data) {

                    if (data[key] === null) {
                        continue;
                    }

                    if (typeof data[key] === "object") {
                        throw  Error('wrong data type');
                    }
                    url_obj.searchParams.append(key, data[key]);
                }

                url = url_obj.href;
            }
        }

        params.data = data;
        params.url = getUrl(url);

        return Axios(params).then(
            result => {

                if (result.data.error) {

                    if (params.throwExceptionOnError) {

                        throw new RequestError(result);

                    }

                    return result.data;

                }

                if (typeof params.dataHandler === 'function') {

                    return params.dataHandler(result.data);

                }

                if (params.returnResult) {

                    return result;

                }

                return result.data;

            },
            async error => {

                if (error.response) {

                    if (params.throwExceptionOnError) {

                        throw new RequestError(error.response);

                    }

                    if (error.response.status >= 500 && error.response.status < 600) {
                        renderErrorPage(500);
                    }

                    if (error.response.status === 400) {
                        renderErrorPage(500);
                    }

                    if (error.response.status === 404) {
                        renderErrorPage(404);
                    }

                    if (error.response.status === 403) {
                        renderErrorPage(403);
                    }
                }

                console.error(error);
                renderErrorPage(408);


            }
        )
    }
}


export class RequestError extends Error {

    constructor(response, message) {

        super(message);
        this.response = response;

    }

}
