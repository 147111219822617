import React from "react";
import {getNowDate} from "Smt/helpers";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

export default class TimeoutPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popupState: 'hidden',
            task_count: 0,
            answer_count: 0
        }
    }

    UNSAFE_componentWillReceiveProps({state, task_count, answer_count, issuedAt}) {
        this.setState({
            popupState:   state,
            task_count:   task_count,
            answer_count: answer_count,
            issuedAt:     issuedAt,
        });
    }

    render() {
        const {onClick, issuedAt} = this.props;
        const {task_count, answer_count, popupState} = this.state;
        const now        = getNowDate();
        const passed     = now - issuedAt;
        const passed_min = Math.floor(passed / 1000 / 60 );

        return (
            <div className={"timeout-popup timeout-popup--" + popupState}>
                <div className="timeout-popup__overlay"></div>
                <div className="timeout-popup__body">
                    <div className="timeout-popup__remain"><SmtLocaleString k="common.timeout_popup.remain" id="value" /></div>
                    <div className="timeout-popup__title"><SmtLocaleString k="common.timeout_popup.out_of_time" id="value" /></div>
                    <div className="status_row">
                        <div className="status_row_item">
                            <div className="status_row_title"><SmtLocaleString k="common.timeout_popup.tasks" id="value" /></div>
                            <div className="status_row_text">{task_count}</div>
                        </div>
                        <div className="status_row_item">
                            <div className="status_row_title"><SmtLocaleString k="common.timeout_popup.answers" id="value" /></div>
                            <div className="status_row_text">{answer_count}</div>
                        </div>
                        <div className="status_row_item">
                            <div className="status_row_title"><SmtLocaleString k="common.timeout_popup.time" id="value" /></div>
                            <div className="status_row_text"><SmtLocaleString k="common.timeout_popup.duration" id="value" values={{number: passed_min}} /></div>
                        </div>
                    </div>
                    <div className="timeout-popup__condition">
                        <SmtLocaleString k="common.timeout_popup.text" values={{br: <br />}} id="value" />
                    </div>
                    <div className="timeout-popup__buttons">
                        <div className="timeout-popup__button" onClick={onClick}>
                            <SmtLocaleString k="common.timeout_popup.got_it" id="value" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
