import React, {useState}                  from "react";
import {GrowGap, HBox, LGap, SGap}        from "@sirius/ui-lib/src/blocks/Layout";
import {getLanguagePickerName}            from "@sirius/ui-shared/src/components/Programming";
import HistorySolution                    from "./HistorySolution";
import {
    calcBestVerdict,
    OnlineVerdictSolution,
    OnlineVerdictSolutionTM,
    Score
}                                         from "./OnlineVerdicts";
import {
    block,
    HasSolution,
    HasVerdict,
    MaybeHasIsHidden
}                                         from "./block";
import {SmtLocalePlural, SmtLocaleString} from "Smt/SmtLocaleBlock";
import {
    SmtPortal_POST_test_results_testsReport,
    SmtPortal_POST_test_results_testsReport_report
}                                         from "Smt/subjects/ContestOnline";
import {Limits}                           from "Smt/SmtProgrammingAnswer/Limits";
import {Examples}                         from "Smt/SmtProgrammingAnswer/Examples";
import                                         "./elements/el-solution.less";

type ProgrammingSolutionType = {
    forceShowRight: boolean;
    resultData:     SmtPortal.TestResultItem;
    answersData:    SmtTask.AnswersData;
} & MaybeHasIsHidden;

type TypeFixAnswers         = [{answer: SmtPortal.UserAnswerProgramming}];
type TypeFixSolutionToShow  = {solution: SmtPortal.LanguageBlock} ;

const SmtProgrammingSolution: React.FC<ProgrammingSolutionType> = ({
    forceShowRight,
    isHidden,
    answersData: {
        languages,
        sourceFooter,
        sourceHeader,
        limits,
        examples
    },
    resultData: {
        history,
        solution: {answers, solutionToShow},
        taskNo,
        maxScore,
        score,
        verdict,
        token,
        tmSec,
        reportRef,
        review
    }
}) => {
    const [allVerdictsCollapsed, setAllVerdictsCollapsed] = useState<boolean>(true);
    const [answerContainer] = answers as unknown as TypeFixAnswers;
    const answerSolution    = answerContainer?.answer?.solution || null;
    const rightSolution     = (solutionToShow as unknown as TypeFixSolutionToShow)?.solution || null;
    const solution          = forceShowRight ? rightSolution : answerSolution;
    const content: Partial<HasVerdict & HasSolution> =
        forceShowRight
            ? {
                solution,
                verdict: "ok",
            } as HasVerdict & HasSolution
            :  {
                solution,
                id:     `${taskNo}`,
                taskId:  taskNo,
                tmSec,
                score,
                maxScore,
                verdict,
                review
            } as Partial<HasVerdict & HasSolution>
    ;

    const emptySolution: SmtPortal.LanguageBlock =  {
        language: languages[0],
        code: "\n\n"
    };

    const emptyContent: HasVerdict & HasSolution = {
        solution: emptySolution,
        verdict: content.verdict
    };
    const showExamples      = !forceShowRight && examples?.length > 0;
    const showLimits        = !forceShowRight && limits;

    const hasHistory        = history?.length > 0;
    const bestVerdictAnswer = hasHistory && [...history].reverse().find(({review}) => review.current)?.review;
    const bestVerdict       = bestVerdictAnswer?.verdict ?? '';
    const hasScore          = score !== void(0) && maxScore !== void(0);

    const solutionHeader = forceShowRight
        ? <>
            <b><SmtLocaleString k="programming.codeeditor.header1"/></b>
            <GrowGap/>
            {getLanguagePickerName(rightSolution.language)}
        </>
        : verdict === "none"
                ? <>
                    <b><SmtLocaleString k="programming.codeeditor.header1"/></b>
                  </>
                : <>
                        <b>
                            <SmtLocaleString k="programming.codeeditor.header1"/>, {
                            answerSolution
                            && `${getLanguagePickerName(answerSolution.language)}. `
                            }
                        </b>
                        <SGap/>
                        <OnlineVerdictSolutionTM value={tmSec * 1000}/>

                    { verdict !== "annulled" &&
                        <>
                            <GrowGap/>
                            {
                                hasScore
                                ?   <>
                                        <Score score={score} maxScore={maxScore} viewMode/>
                                        <span className={block.el('verdicts-score')}>
                                        . <SmtLocaleString k={`common.verdict_text.${verdict}`}/>
                                        </span>
                                    </>
                                :   <span className={block.el('verdicts-score')}>
                                        <SmtLocaleString k={`common.verdict_text.${verdict}`}/>
                                    </span>
                            }

                        </>
                    }
                  </>

    ;


    const TestsReportCb = forceShowRight
        ? void(0)
        : () => SmtPortal_POST_test_results_testsReport(
                {taskNo, submissionId: reportRef.solutionId},
                `${window.results_token}`
            )
            .then((res) =>
                new Promise <Common.Programming.ReviewTestsReportResponse> ( (resolve, reject) => {
                    const {status, data} = res;
                    (status === 200 && data)
                        ? resolve(data)
                        : reject(status);
                })
            )
    ;

    const TestDetailReportCb = forceShowRight
        ? void(0)
        : (testNo: string) => SmtPortal_POST_test_results_testsReport_report(
                {taskNo, submissionId: reportRef.solutionId, testNo},
                `${window.results_token}`
            )
            .then((res) =>
                new Promise <Common.Programming.ReviewTestProtocolResponse> ( (resolve, reject) => {
                    const {status, data} = res;
                    (status === 200 && data)
                        ? resolve(data)
                        : reject(status);
                })
            )
    ;

    return (
        <>
            {showExamples &&
                <Examples examples={examples}/>
            }
            {showLimits &&
                <>
                    <Limits limits={limits}/>
                    <LGap/>
                </>
            }
            {!content.solution
                ?  <OnlineVerdictSolution
                        className                = {block.el("solution")}
                        isLast                   = {true}
                        isHidden                 = {isHidden}
                        showDetails              = {true}
                        showHeader               = {solutionHeader}
                        pending                  = {false}
                        clickable                = {false}
                        taskId                   = {taskNo}
                        num                      = {taskNo}
                        content                  = {emptyContent}
                        sourceFooter             = {sourceFooter}
                        sourceHeader             = {sourceHeader}
                        taskToken                = {token}
                        toggleVerdictsShowDetails= {() => {}}
                        unlockNextTry            = {() => {}}
                        TestsReportCb            = {TestsReportCb}
                        TestDetailReportCb       = {TestDetailReportCb}
                        hideCopyPanel            = {true}
                        {...reportRef}
                    />

                : <>
                    {!forceShowRight && hasHistory &&
                    <HBox
                        className={
                            block.el('verdicts-toggle-smt') +
                            block.el('verdicts-toggle-smt').bod('collapsed', allVerdictsCollapsed) +
                            block.el('verdicts-toggle-smt').bod(`${bestVerdict}`, bestVerdict && allVerdictsCollapsed) +
                            block.el('verdicts-card')
                        }
                        onClick={() => setAllVerdictsCollapsed(!allVerdictsCollapsed)}
                    >
                        {allVerdictsCollapsed
                            ? <>
                                <p style={{margin: '0 auto'}}>
                                    {`${history.length} `}
                                    <SmtLocalePlural k="programming.onlineverdicts.solutionsNum" value={history.length}/>
                                </p>
                                {bestVerdictAnswer && <Score score={bestVerdictAnswer?.score} maxScore={bestVerdictAnswer?.maxScore}/>}
                            </>
                            : <SmtLocaleString id="value" k="programming.onlineverdicts.hide"/>
                        }
                    </HBox>
                    }
                    {!allVerdictsCollapsed &&
                    history.map(({review, solution, tmSec, reportRef}, hi) =>

                        <HistorySolution
                            key       = {hi}
                            verdict   = {review?.verdict}
                            num       = {hi + 1}
                            solution  = {solution as unknown as SmtPortal.UserAnswerProgramming[]}
                            time      = {tmSec}
                            score     = {review?.score}
                            maxScore  = {review?.maxScore}
                            reportRef = {reportRef}
                            taskNo    = {taskNo}
                            review    = {review}
                        />
                    )
                    }
                    <OnlineVerdictSolution
                        className                = {block.el("solution")}
                        isLast                   = {true}
                        isHidden                 = {isHidden}
                        showHeader               = {solutionHeader}
                        showDetails              = {true}
                        pending                  = {false}
                        clickable                = {false}
                        taskId                   = {taskNo}
                        num                      = {taskNo}
                        content                  = {content}
                        sourceFooter             = {sourceFooter}
                        sourceHeader             = {sourceHeader}
                        taskToken                = {token}
                        toggleVerdictsShowDetails= {() => {}}
                        unlockNextTry            = {() => {}}
                        TestsReportCb            = {TestsReportCb}
                        TestDetailReportCb       = {TestDetailReportCb}
                        hideCopyPanel            = {forceShowRight}
                        {...reportRef}
                    />
                </>
            }
        </>
    );
}


export {SmtProgrammingSolution};
