import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const AdvancedModulesAchievedIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 1.5C11.1716 1.5 10.5 2.17157 10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 11.1716 3.82843 10.5 3 10.5C2.17157 10.5 1.5 11.1716 1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5Z"
              fill="#63BF13"
        />
        <path
            d="M12 14.672L14.1824 16.0532C14.5821 16.3063 15.0712 15.9321 14.966 15.4589L14.3875 12.8616L16.3175 11.1118C16.6699 10.7926 16.4806 10.1874 16.0178 10.1488L13.4777 9.92322L12.4838 7.46905C12.305 7.02334 11.695 7.02334 11.5162 7.46905L10.5223 9.91772L7.98222 10.1433C7.51944 10.1818 7.33012 10.7871 7.68246 11.1063L9.61247 12.8561L9.03399 15.4534C8.92882 15.9266 9.41789 16.3008 9.81757 16.0477L12 14.672Z"
            fill="#63BF13"
        />
    </svg>

</SvgIcon>;


export default AdvancedModulesAchievedIcon;
