/* eslint-disable */
import Axios from "axios";
import JWT from "jsonwebtoken";
import AjaxWrapper from "Cheops/AjaxWrapper";
import store from "Cheops/Store";
import * as auth_actions from "Cheops/actions/auth";

let form_session_token = null;

const VERSION = CONFIG.Version ? "/" + CONFIG.Version : "";

export const getFormSessionToken = (force_update = false) => {

    if (form_session_token && !force_update) {

        return {
            type: "GET_FORM_TOKEN",
            payload: new Promise(function (resolve) {
                    resolve(form_session_token);
                }
            )
        };
    }

    return {
        type: "GET_FORM_TOKEN",
        payload:
            Axios({
                method: "POST",
                url: '/cheops-auth/api/v1/',
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                },
                data: {
                    "jsonrpc": "2.0",
                    "method": "token",
                    "params": {},
                    "id": 1
                }
            })
                .then(result =>
                    Promise.resolve(form_session_token = result.data.result.token)
                )

    };

};


export const getSessionToken = (code, state) => {

    return Axios({
        method: "GET",
        url: `/noo-back${VERSION}/oauth/callback`,
        params: {code, state}
    })
        .then(result => Promise.resolve(result.data.success.token),
            async error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        location.href = '/401.html';
                    }
                    if (error.response.status > 401 && error.response.status < 600) {
                        let new_html = await Axios.get('/500.html');
                        document.open();
                        document.write(new_html.data);
                        document.close();
                    }
                }
                console.error(error);
            }
        );
};

export const redirectToAuth = redirect_to => {
    redirect_to = redirect_to || location.href;

    document.location.assign(
        `${CONFIG.Auth.anonymous_redirect_uri}?redirect_to=${encodeURIComponent(redirect_to)}`
    );
};

export const redirectToRegistration = redirect_to => {
    redirect_to = redirect_to || location.href;

    document.location.assign(
        `${CONFIG.Auth.register_uri}?redirect_to=${encodeURIComponent(redirect_to)}`
    );
};


export const redirectToAuthIf401 = (response, redirect_to = location.href) => {

    if (response.status === 401) {
        if (response.data && response.data.location) {
            redirectToAuth(redirect_to);
        }
    }

};


export const updateTokenFromResponse = (response) => {

    for (let i in response.headers) {

        if (i.toLowerCase() !== 'x-set-bearer') {
            continue
        }

        let token = response.headers[i];

        if (!token) {
            return;
        }

        store.dispatch(auth_actions.setAuthToken(token));

    }

};


export const sendAuthCode = (session_token, email) => {

    return AjaxWrapper.post(CONFIG.Api.sso.url, {
        "jsonrpc": "2.0",
        "method": "login",
        "params": {
            "token": session_token,
            "login": email
        },
        "id": 1
    }, {send_auth_token: true});

};

export const resendAuthCode = (token) => {

    return AjaxWrapper.post(CONFIG.Api.sso.url, {
        "jsonrpc": "2.0",
        "method": "resend",
        "params": {token},
        "id": 1
    }, {send_auth_token: true});

};


export const sendAccessCode = (session_token, auth_code, context) => {

    return AjaxWrapper.post(CONFIG.Api.sso.url, {
        "jsonrpc": "2.0",
        "method": "code",
        "params": {
            "token": session_token,
            "code": auth_code,
            "context": context
        },
        "id": 1
    }, {send_auth_token: true})

};


export const sendProfile = (session_token, first_name, last_name, middle_name, role, region_id, school_id, school_class, birth_date) => {

    return AjaxWrapper.post(CONFIG.Api.sso.url, {
        "jsonrpc": "2.0",
        "method": "profile",
        "params": {
            "token": session_token,
            "profile": {
                "lastName": last_name,
                "firstName": first_name,
                'middleName': middle_name,
                "birthday": birth_date,
                "region": region_id,
                "status": role,
                "grade": school_class,
                "education": school_id,
                "job": school_id
            }
        },
        "id": 1
    }, {send_auth_token: true})

};


export const sendParentCode = (session_token, parent_code) => {

    return AjaxWrapper.post(CONFIG.Api.sso.url, {
        "jsonrpc": "2.0",
        "method": "parent_code",
        "params": {
            "token": session_token,
            "code": parent_code
        },
        "id": 1
    }, {send_auth_token: true});

};

export const getUserInfo = token_field => {

    let token = store.getState().authReducer.auth_token;

    if (!token) {
        return null;
    }

    let jwt_decoded = JWT.decode(token);

    if (!jwt_decoded || typeof jwt_decoded[token_field] === 'undefined') {
        return null;
    }

    return jwt_decoded[token_field];
};


export const setUserEmail = (status) => {
    return {
        type: "USER_EMAIL",
        status
    }
};


export const setContext = (status) => {
    return {
        type: "AUTH_CONTEXT",
        status
    }
};


export const setAuthToken = (token) => {

    let old_token = localStorage.getItem(CONFIG.Auth.localstorage_key);

    if (token) {
        localStorage.setItem(CONFIG.Auth.localstorage_key, token);
    } else {
        localStorage.removeItem(CONFIG.Auth.localstorage_key);
    }

    window.dispatchEvent(new StorageEvent('storage', {
        key: CONFIG.Auth.localstorage_key,
        oldValue: old_token,
        newValue: token
    }));

    return {
        type: "SET_AUTH_TOKEN",
        token
    }
};
