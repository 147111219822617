import AjaxWrapper                  from "Cheops/AjaxWrapper";
import {REPORT_BUG_LVL,
        REPORT_INITIATOR}           from "Cheops/constants";
import {
    getContentByHash_Course,
    getContentByHash_CourseBlock,
    getContentByHash_Element,
    getContentByHash_MainContent,
    getContentByHash_Module
}                                   from "Cheops/actions/noopolis-ts";
import {imagesCacheById}            from "Cheops/actions/noopolisCache";

const VERSION         = CONFIG.Version ? "/" + CONFIG.Version : "";
const CONTENT_VERSION = CONFIG.ContentVersion ? "/" + CONFIG.ContentVersion : "";
const images_cache    = imagesCacheById;

export const getAllNotification = () => {
    return {
        type: "GET_ALL_NOTIFICATIONS",
        payload:
            AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/notification/getAllNotifications', {}, {
                send_auth_token: true,
                dataHandler: async data => {
                    for (let i in data) {
                        if (data[i].lecturer.avatar) {
                            data[i].lecturer.avatar = await getContentImage(data[i].lecturer.avatar);
                        }
                    }
                    return data;
                }
            })
    };
};

export const getNotificationByTM = tm => {
    return {
        type: "GET_NOTIFICATIONS_BY_TM",
        payload:
            AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/notification/getTM', {tm}, {
                send_auth_token: true,
                dataHandler: async data => {
                    let notifications = {};
                    for (let i in data) {
                        if (data[i].lecturer.avatar) {
                            data[i].lecturer.avatar = await getContentImage(data[i].lecturer.avatar);
                        }
                        notifications[data[i].notificationId] = data[i];
                    }
                    return notifications;
                }
            })
    };
};

export const setHasNewNotifications = (status) => ({
    type: "CHANGE_HAS_NEW_NOTIFICATIONS",
    status
});

export const unpinNotification = (notification_id) => {
    return {
        type: "UNPIN_NOTIFICATION",
        payload: AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/notification/unpin', notification_id, {
            send_auth_token: true,
            dataHandler: async data => {
                return notification_id;
            }
        })
    };
};

export const markAsReadNotification = (notification_id) => {
    return {
        type: "MARK_READ_NOTIFICATION",
        payload: AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/notification/markRead', [notification_id], {
            send_auth_token: true,
            dataHandler: async data => {
                return notification_id;
            }
        })
    };
};

export const getQaQuestions = ident => {
    if (!ident) {
        throw  Error('no qa ident');
    }
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/qa/getQA', {ident}, {send_auth_token: true})
};

export const sendQaQuestion = (qa_id, question) => {
    AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/qa/' + qa_id + '/create', JSON.stringify(question), {send_auth_token: true})
};

export const getOpenCourseList = () => {
    return {
        type: "GET_OPEN_COURSE_LIST",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/list', {template: "open"}, {send_auth_token: true})
    };
};

export const getMyCourseList = () => {
    return {
        type: "GET_MY_COURSE_LIST",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/list', {template: "my"}, {send_auth_token: true})
    };
};

export const getUpcomingCourseList = () => {
    return {
        type: "GET_UPCOMING_COURSE_LIST",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/list', {template: "upcoming"}, {send_auth_token: true})
    };
};

export const getFullCourseList = () => {
    return {
        type: "GET_FULL_COURSE_LIST",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/list-full', {}, {send_auth_token: true})
    };
};


export const getCourseLearnData = course_id => {
    if (!course_id) {
        throw Error("no course_id");
    }

    return {
        type: "GET_COURSE_LEARN_DATA",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn', {}, {
            send_auth_token: true,
            dataHandler: data => {
                if (data.modules) {
                    let modules = {};

                    data.modules.map(module => {
                        modules[module.id] = module;
                    });
                    data.modules = modules;
                }

                return data;
            }
        })
    };
};

export const getModuleLearnData = (course_id, module_id) => {

    if (!course_id) {
        throw Error("no course_id");
    }

    if (!module_id) {
        throw Error("no module_id");
    }

    return {
        type: "GET_MODULE_LEARN_DATA",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id, {}, {
            send_auth_token: true,
            dataHandler: data => {
                // let elements = {};

                // for (let i in data.elements) {
                //     elements[data.elements[i].id] = data.elements[i];
                // }
                // data.elements = elements;
                return data;
            }
        })
    };
};

export const getElementLearnData = (course_id, module_id, element_id, element_type) => {

    if (!course_id) {
        throw Error("no course_id");
    }

    if (!module_id) {
        throw Error("no module_id");
    }

    if (!element_id) {
        throw Error("no element_id");
    }

    return {
        type: "GET_ELEMENT_LEARN_DATA",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + "/" + element_id, {}, {
            send_auth_token: true,
            dataHandler: async data => {

                if (data.solution && data.solution.answers) {
                    data.solution.answers.map(
                        (answer) => {
                            if (answer.answer.solution && answer.answer.solution.attachements) {
                                answer.answer.solution.attachements.map(
                                    async attachement => {
                                        if (attachement.preview) {
                                            attachement.preview = await getContentImage(attachement.preview);
                                        }
                                    }
                                );
                            }


                            if (answer.review && answer.review.attachments) {
                                answer.review.attachments.map(
                                    async attachment => {
                                        if (attachment.preview) {
                                            attachment.preview = await getContentImage(attachment.preview);
                                        }
                                    }
                                );
                            }
                        }
                    );
                }

                return {element_id, module_id, data, element_type};
            }
        })
    };
};

export const getElementLearnDataThread = (course_id, module_id, element_id, element_type) => {
    return {
        type: "GET_ELEMENT_LEARN_DATA_THREAD",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + "/" + element_type + "/" + element_id + "/thread", {}, {
            send_auth_token: true,
            dataHandler: async data => {
                if (data.success && data.success.comments) {
                    data.success.comments.map(
                        (comment) => {
                            if (comment.attachments) {
                                comment.attachments.map(
                                    async attachment => {
                                        if (attachment.preview) {
                                            attachment.preview = await getContentImage(attachment.preview);
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
                return data;
            }
        })
    };
};

export const getCourseContent = course_hash => {
    if (!course_hash) {
        throw Error("no course_hash");
    }
    const payload = getContentByHash_Course(course_hash);
    return {
        type: "GET_COURSE_CONTENT",
        payload
    };
};

export const getModuleContent = module_hash => {
    if (!module_hash) {
        throw Error("no module_hash");
    }
    const payload = getContentByHash_Module(module_hash);
    return {
        type: "GET_CONTENT",
        payload
    };
};

export const getElementContent = element_hash => {
    if (!element_hash) {
        throw Error("no element_hash");
    }
    const payload = getContentByHash_Element(element_hash);
    return {
        type: "GET_CONTENT",
        payload
    };
};

export const getCourseBlockContent = (element_type, element_hash) => {
    if (!element_hash) {
        throw Error("no element_hash");
    }
    const payload = getContentByHash_CourseBlock(element_type, element_hash);
    return {
        type: "GET_CONTENT",
        payload
    };
};

export const getCourseData = course_id => {
    return {
        type: "GET_COURSE_DATA",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id, {}, {send_auth_token: true})
    };
};

export const getNewsById = news_id => {
    return {
        type: "GET_NEWS_BY_ID",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/news/getNewsById', {newsId: news_id}, {send_auth_token: true})
    };
};

export const getCertificates = () => {
    return {
        type: "GET_CERTIFICATES",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/certificate', {}, {
            send_auth_token: true,
            dataHandler: async data => {
                data.courses = data.result;
                delete data.result;
                return data;
            }
        })
    };
};

export const getCertificate = hash => {
    return {
        type: "GET_CERTIFICATE",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/certificate/' + hash, {}, {
            send_auth_token: true,
            dataHandler: data => {
                if (data.success) {
                    return data.success;
                };
            }
        }),
    };
};

export const sendCertificateFormData = (data) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/certificate/data', data, {
        send_auth_token: true,
        dataHandler: response => {
            let data = {
                status: Object.keys(response)[0],
                message: response[0]
            };
            return data;
        }
    })
};

export const requestCertificate = (certificate_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/certificate/' + certificate_id + '/request', {}, {
        send_auth_token: true,
        dataHandler: response => {
            let data = {
                status: Object.keys(response)[0],
                message: response[0]
            };
            return data;
        }
    })
};

export const declineCertificate = (certificate_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/certificate/' + certificate_id + '/decline', {}, {
        send_auth_token: true,
        dataHandler: response => {
            let data = {
                status: Object.keys(response)[0],
                message: response[0]
            };
            return data;
        }
    })
};

export const setCertificates = (certificates_obj) => {
    return {
        type: "SET_CERTIFICATES",
        data: certificates_obj
    };
};

export const attendCourse = async course_id => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + "/attend", {}, {send_auth_token: true,});
};


export const getTaskVideo = (course_id, module_id, element_id, video_id) => {
    return {
        type: "GET_TASK_VIDEO",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + element_id + '/video/' + video_id, {}, {send_auth_token: true})
    };
};

export const getContentImageUrl = (image_id) => {
    return CONFIG.Api.noopolis.url + CONTENT_VERSION + '/content/_image/' + image_id;
};

export const getContentImage = async (image_id, content_type) => {
    if (!image_id) {
        return image_id
    }
    let options = {};

    if (images_cache[image_id]) {
        if (typeof images_cache[image_id] === "object") {
            return await images_cache[image_id];
        }

        return images_cache[image_id];
    }

    if (content_type) {
        options['type'] = content_type;
    }

    let image_data_promise = AjaxWrapper.get(CONFIG.Api.noopolis.url + CONTENT_VERSION + '/content/_image/' + image_id, {}, {
        headers: {
            'Accept': 'application/octet-stream',
            'Content-Type': 'application/json;charset=utf-8'
        },
        dataHandler: data => {
            let image_url = window.URL.createObjectURL(new Blob([data], options));
            return image_url;
        },
        responseType: 'blob'
    });

    images_cache[image_id] = image_data_promise;

    let image_url = await image_data_promise;

    images_cache[image_id] = image_url;

    return image_url;
};

export const getCourseAttended = () => {
    return {
        type: "GET_COURSE_ATTENDED",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/attended', {}, {
            send_auth_token: true,
            dataHandler: data => {
                if (data.courses) {
                    let courses = {};

                    data.courses.map(course => {
                        courses[course.id] = course;
                    });
                    data.courses = courses;
                }

                return data;
            }
        })
    };
};

export const getCourseStats = course_id => {
    return {
        type: "GET_COURSE_STATS",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/stats', {}, {
            send_auth_token: true,
            dataHandler: data => {
                if (data.moduleStatistics) {
                    let modules = {};
                    data.moduleStatistics.map(module => {
                        modules[module.id] = module;
                    });
                    data.moduleStatistics = modules;
                }
                return data;
            }
        })
    };
};

export const solveTask = (course_id, module_id, element_id, answer) => {
    return AjaxWrapper.post(`${CONFIG.Api.noopolis.url + VERSION}/course/${course_id}/learn/${module_id}/${element_id}/solve`, answer, {
        send_auth_token: true
    })
};

export const checkTask = (course_id, ids) => {
    return AjaxWrapper.post(`${CONFIG.Api.noopolis.url + VERSION}/course/${course_id}/check`, {ids}, {
        send_auth_token: true
    })
};

export const markTask = (course_id, module_id, type, element_id) => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + type + '/' + element_id + '/mark', {}, {send_auth_token: true})
};

export const updateModuleLearnData = (learn_obj) => {
    return {
        type: "UPDATE_MODULE_LEARN_DATA",
        data: learn_obj
    };
};

export const setCourseLearnData = (learn_obj) => {
    return {
        type: "SET_COURSE_LEARN_DATA_FULFILLED",
        data: learn_obj
    };
};

export const updateElementLearnData = (element_id, module_id, data) => {
    return {
        type: "UPDATE_ELEMENT_LEARN_DATA",
        element_id,
        module_id,
        data
    };
};

export const getMainInfo = () => {
    return {
        type: "GET_MAIN_INFO",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/main', {}, {send_auth_token: true})
    }
};

export const getTaskExplanation = (course_id, module_id, task_id, explanation_id) => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + task_id + "/explanation/" + explanation_id, {}, {send_auth_token: true})
};

export const getTaskHints = (course_id, module_id, task_id) => {
    return {
        type: "GET_TASK_HINTS",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/task/' + task_id + "/hints", {}, {send_auth_token: true})
    }
};

export const getTaskHint = (course_id, module_id, task_id, requested_hint_number) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/task/' + task_id + "/hints", {"id": requested_hint_number}, {
        send_auth_token: true
    })
};

export const getModuleLearnDataBySkin = (course_id, module_id, skin) => {
    return {
        type: "GET_MODULE_LEARN_DATA_BY_SKIN",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/skin/' + skin, {}, {send_auth_token: true})
    }
};

export const putCommentToThread = (course_id, module_id, element_type, element_id, comment) => {
    return AjaxWrapper.put(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + "/" + element_type + "/" + element_id + "/thread", comment, {send_auth_token: true})
};

export const getMainContent = hash => {
    if (!hash) {
        throw Error("no hash");
    }
    const payload = getContentByHash_MainContent(hash);
    return {
        type: "GET_MAIN_CONTENT",
        payload
    };
};

export const uploadToken = (course_id, module_id, task_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/task/' + task_id + '/upload-token', {}, {
        send_auth_token: true
    })
};

export const uploadTokenThread = (course_id, module_id, element_type, element_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + element_type + '/' + element_id + '/thread/upload-token', {}, {
        send_auth_token: true
    })
};

export const getFileInfo = (course_id, module_id, task_id, hash) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/task/' + task_id + '/file-info/' + hash, {}, {
        send_auth_token: true
    })
};

export const getFileInfoThread = (course_id, module_id, element_type, element_id, hash) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + element_type + '/' + element_id + '/thread/file-info/' + hash, {}, {
        send_auth_token: true
    })
};

export const uploadFile = form_data => {
    return AjaxWrapper.post(CONFIG.Api.storage.url + '/upload', form_data, {
        headers: {
            'Accept': 'application/json;charset=utf-8',
            'Content-Type': 'multipart/form-data'
        },
    })
};

export const markThreadAsRead = (course_id, module_id, element_type, element_id) => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + element_type + '/' + element_id + '/thread/mark', {}, {
        send_auth_token: true
    })
};

export const markReviewAsRead = (course_id, module_id, task_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/' + task_id + '/review/mark', {}, {
        send_auth_token: true
    })
};

export const getModuleProgress = (course_id, module_id, table_index) => {
    return {
        type: "GET_MODULE_PROGRESS",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/progress?pos=' + table_index, {}, {send_auth_token: true})
    }
};

export const getParentCodeToken = (parent_code) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/oauth/parent', {code: parent_code})
};

export const getCourseBuckets = (course_id) => {
    return {
        type: "GET_COURSE_BUCKETS",
        payload: AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/stats/buckets', {}, {send_auth_token: true})
    }
};

export const transferProgress = (course_id) => {
    return AjaxWrapper.put(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/transfer', {}, {send_auth_token: true})
};

export const declineProgressTransferring = (course_id) => {
    return AjaxWrapper.put(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/transfer/decline', {}, {send_auth_token: true})
};

export const doneHelp = (course_id, widget_ids) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/help/done', {"ids": widget_ids}, {send_auth_token: true})
};

export const reportBug = (error, level = REPORT_BUG_LVL.WARNING, meta = {}, initiator = REPORT_INITIATOR.FRONT) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/bug', {
        initiator,
        error,
        level,
        meta,
    }, {send_auth_token: true})
};

export const refuseCourse = (course_id) => {
    return AjaxWrapper.delete(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/grant', {}, {send_auth_token: true})
};

