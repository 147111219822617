import {BehaviorSubject}                  from "rxjs";
import Axios, {AxiosError, AxiosPromise}  from "axios";
import {decode}                           from "jsonwebtoken";
import {mixBehavior}                      from "@sirius/ui-lib/src/subjects/mixBehavior";
import {contestOnlinePutReviewsAsyncLock} from "Smt/TaskPage/contestOnlinePutReviewsAsyncLock";
import {renderErrorPage}                  from "Smt/helpers";

type PollingErrorType = {
    type:  'response' | 'offline';
    status: number;
};

type PollingSubjectType = {
    sinceISO?: string;
    solutions: SmtCache.ReviewsResponseSolution[];
    pending:   SmtCache.ReviewsResponsePending[];
    tasksTimers?: SmtCache.TaskSentTime[];
    runningPoll?: boolean;
    pollingError?: PollingErrorType
};

type ReviewsResponseItem = SmtCache.ReviewsResponsePending | SmtCache.ReviewsResponseSolution;

const SCHEDULE_ERROR = {...CONFIG?.SCHEDULE_ERROR};

const checkIsOnlineByJWT = (token: string): boolean => {
    try {
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;
        const {tv} = data;
        const isOnline = tv.reduce( (flag, {i}) => (flag || i !== void(0)), false );
        return isOnline;
    } catch (e) {
        console.error(e);
        return false;
    }
}

const isHiddenByJWT = (token: string, taskNum: string): boolean => {

    try {
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;
        const isHidden = data?.tv[parseInt(`${taskNum}`)]?.i?.includes('h');
        return !!isHidden;
    } catch (e) {
        console.error(e);
        return false;
    }
}

const checkTableByJWT = (token: string): boolean => {
    try {
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;
        return ('tb' in data) && !!data.tb;
    } catch (e) {
        console.error(e);
        return false;
    }
}
const isOnlineByTaskNum = (token: string, taskNum: string): boolean => {
    try {
        const taskIndex = parseInt(taskNum);
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;
        const {tv} = data;
        const isTaskOnline = tv.reduce(
            (flag, {i}, n) => (
                flag || ( i !== void(0) && n === taskIndex )
            ),
            false
        );
        return isTaskOnline;
    } catch (e) {
        console.error(e);
        return false;
    }
}

const getUserNameByJWT = (token: string): string | boolean => {
    try {
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;
        return data?.un;
    } catch (e) {
        console.error(e);
        return false;
    }
}

const getTaskOnlineInfoByJWT = (token: string, taskNum: string): SmtJWT.OnlineInfo | boolean => {
    try {
        const taskIndex = parseInt(taskNum);
        const data = decode(token, {json: true}) as SmtJWT.JWTToken;

        return (Array.isArray(data?.tv) && data.tv[taskIndex]?.i) || '';
    } catch (e) {
        console.error(e);
        return false;
    }
}

const headers = {
    'cache-control': 'no-cache',
    'content-type':  'application/json',
    'Accept':        'application/json',
};

const bearerHeaders = (bearer: string) => ({
    ...headers,
    'Authorization': `Bearer ${bearer}`,
});

const PORTAL_API_URL = () =>`/smt-portal`;

const APIURL = () =>`/smt-cache/v4`;

const SmtCache_v4_POST_standings = (bearer: string, data: SmtCacheV4.StandingsRequest): AxiosPromise<SmtCacheV4.StandingsResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/standings`,
        headers: bearerHeaders(bearer),
        data
    })
;
const SmtCache_v4_POST_token = (bearer: string): AxiosPromise<SmtCache.TokenResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/token`,
        headers: bearerHeaders(bearer)
    })
;

const SmtCache_v4_POST_solution = ({id, taskId}: SmtCache.SolutionRequest, bearer: string): AxiosPromise<SmtCache.SolutionResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/solution`,
        data: {id, taskId},
        headers: bearerHeaders(bearer)
    })
;

const SmtCache_v4_POST_solution__testsReport = ({id, taskId}: SmtCache.TestReportRequest, bearer: string): AxiosPromise<SmtCache.TestsReportResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/solution/testsReport`,
        data: {id, taskId},
        headers: bearerHeaders(bearer)
    })
;
const SmtCache_v4_POST_solution__testsReport_report = ({id, taskId, testNo}: SmtCache.TestReportRequest & { testNo: string }, bearer: string): AxiosPromise<SmtCache.TestProtocolResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/solution/testsReport/report/${testNo}`,
        data: {id, taskId},
        headers: bearerHeaders(bearer)
    })
;


const SmtPortal_POST_test_results_testsReport = ({taskNo, submissionId}: { taskNo: number, submissionId: string }, bearer: string): AxiosPromise<SmtCache.TestsReportResponse> =>
    Axios({
        method: "POST",
        url: `${PORTAL_API_URL()}/test/results/testsReport/${taskNo}/${submissionId}`,
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
        },
        data: `"${bearer}"`,
    })
;


const SmtPortal_POST_test_results_testsReport_report = ({taskNo, testNo, submissionId}: { taskNo: number, testNo: string; submissionId: string }, bearer: string): AxiosPromise<SmtCache.TestProtocolResponse> =>
    Axios({
        method: "POST",
        url: `${PORTAL_API_URL()}/test/results/testsReport/${taskNo}/${submissionId}/report/${testNo}`,
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
        },
        data: `"${bearer}"`,
    })
;


const SmtCache_v4_POST_reviews = ({sinceISO, token}: SmtCache.ReviewsRequest, bearer: string): AxiosPromise<SmtCache.ReviewsResponse> =>
    Axios({
        method: "POST",
        url: `${APIURL()}/reviews`,
        data: {sinceISO, token},
        headers: bearerHeaders(bearer)
    })
;

const requestPollingToken = (bearer: string): Promise<SmtCache.TokenResponse> =>
    SmtCache_v4_POST_token(bearer)
        .then(
            ({data}) => data
        )
;

const parseInitPendingSolutions = ({ pending, reviews, solutions }: SmtCacheV4.GetResponse) => {
    return {
        pending,
        solutions:  Object.entries(solutions).reduce(
            (acc, [taskId, solutions] ) => {
                const [solution] = Object.values(solutions);
                acc.push({
                    taskId,
                    ...solution,
                    ...(taskId in reviews ? reviews[taskId] : null )
                });
                return acc;
            }, []
        ),
    }
}

const updatePolling = (data: SmtCache.ReviewsResponse, {solutions, pending}: PollingSubjectType) => {
    const {
        sinceISO,
        solutions: solutionsPatch = [],
        pending:   pendingPatch   = [],
        tasksTimers = []
    } = data;
    const PatchIds: string[]  = [...solutionsPatch, ...pendingPatch].map( ({id}: ReviewsResponseItem) => id );
    const PatchFilter = ({id}: ReviewsResponseItem) => !PatchIds.includes( id );
    return {
        sinceISO,
        solutions: [
            ...solutions.filter(PatchFilter),
            ...solutionsPatch.map(
                patch => ({
                    ...( solutions.find( ({id})=> id === patch.id ) || pending.find( ({id})=> id === patch.id ) || null ),
                    ...patch
                })
            )
        ],
        pending:   [
            ...pendingPatch.map(
                ({id}) => ({
                    ...pending.find(item => item.id === id)
                })
            )
        ],
        tasksTimers
    };
};

const pollOutOfLoop = (data: SmtCache.ReviewsResponse, polling$: BehaviorSubject<PollingSubjectType>) => {
    const $ = updatePolling(data, polling$.getValue());
    mixBehavior(polling$, $);
};

const setPollingError = (pollingError: PollingErrorType, polling$: BehaviorSubject<PollingSubjectType>, ) =>
    mixBehavior( polling$, {pollingError} )
;

type InitPollingType = Omit<PollingSubjectType, "sinceISO">;
interface UsePollingSubjectArgs {
    bearer: string;
    initPolling?: InitPollingType;
    $?: BehaviorSubject<PollingSubjectType>;
}

const usePollingSubject = ( {bearer, initPolling, $}: UsePollingSubjectArgs ) => {
    const {pending = [], solutions = [], tasksTimers = [], runningPoll = null} = initPolling ?? {};
    const polling$ = $ ?? new BehaviorSubject<PollingSubjectType>({pending, solutions, tasksTimers, runningPoll});
    let renderErrorPageTimer:  NodeJS.Timeout = null;

    const poll = ( token: string ) => (): void => {
        const {sinceISO, pollingError} = polling$.getValue();
        const reviews = () =>
            SmtCache_v4_POST_reviews({token, sinceISO}, bearer)
                .then(
                    ({data}) => {
                        const {availAfterSec, token, pending} = data;
                        const runningPoll = pending?.length > 0;
                        const $ = updatePolling(data, polling$.getValue());
                        mixBehavior(polling$, {...$, runningPoll});
                        runningPoll && schedulePoll({token, availAfterSec});
                        clearTimeout(renderErrorPageTimer);
                    }
                )
                .catch(
                    (error) => {
                        if (error?.isAxiosError && error?.response) {
                            type ServerError = { code: string };
                            const {response: {headers, status}} = error as AxiosError<ServerError>;
                            const {LIMIT_RETRY = 10, INTERVAL_S = headers['retry-after']} = SCHEDULE_ERROR[status] ?? {};

                            if (status === 429 ) {         //ToDo 429 -Too Many requests
                                 renderErrorPage(status);
                            } else if (status === 502) {   //ToDo 502 -Bad Gateway
                                 const retryAfter = parseInt( INTERVAL_S ) | 1;

                                 if (!renderErrorPageTimer) {
                                     renderErrorPageTimer = setTimeout(
                                         () => setPollingError({type: 'response', status}, polling$),
                                         LIMIT_RETRY * retryAfter * 1000
                                     );
                                 }
                                !pollingError && schedulePoll({token, availAfterSec: retryAfter});
                            }

                            return;
                        } else {
                            !window.navigator.onLine &&
                            setPollingError({type: 'offline', status: 408}, polling$)
                        }
                        return error;
                    }
                )
        ;

        contestOnlinePutReviewsAsyncLock(reviews);
    };

    const schedulePoll = ({ token, availAfterSec }: SmtCache.TokenResponse): void => {
        const delay = availAfterSec * 1000;
        setTimeout(
            poll(token),
            delay
        );
    }

    requestPollingToken( bearer ).then( schedulePoll );

    return polling$;
}

type NewPending = SmtCacheV4.ReviewsResponsePending & {
    taskId:   number;
    solution: SmtCacheV4.LanguageBlock;
}

type NewPendingId = SmtCacheV4.ReviewsResponsePending & {
    id:       string;
    taskId:   number;
}

const tmSecNow = (): number => Math.round( (new Date()).getTime() / 1000 );

const newPendingToPolling = ($: BehaviorSubject<PollingSubjectType>, { solution, taskId }: NewPending): void => {
    const {pending} = $.getValue();
    mixBehavior( $, {pending: [...pending, { id:'new', solution, taskId, tmSec: tmSecNow() } ]});
};

const addIdToNewPending = ($: BehaviorSubject<PollingSubjectType>, { id, taskId }: NewPendingId): void => {
    const {pending: pendingPrev} = $.getValue();
    const pendingF = pendingPrev.filter( p => p.id !== 'new' && p.taskId !== taskId );
    const newp     = pendingPrev.find( p => p.id === 'new' && p.taskId === taskId );
    const pending  = [...pendingF, {...newp, id}];
    mixBehavior( $, { pending } );
};

type HasTaskId = {
    taskId: number;
}

const deletePending = ($: BehaviorSubject<PollingSubjectType>, { taskId }:HasTaskId, resendMode = false): void  => {
    const {pending: pendingPrev} = $.getValue();
    const pendingF = pendingPrev.filter( p => p.id !== 'new' && ( resendMode ? true : p.taskId !== taskId) );
    mixBehavior( $, { pending: [...pendingF] } );
};

export {
    getTaskOnlineInfoByJWT,
    getUserNameByJWT,
    checkTableByJWT,
    isOnlineByTaskNum,
    isHiddenByJWT,
    checkIsOnlineByJWT,
    newPendingToPolling,
    addIdToNewPending,
    pollOutOfLoop,
    usePollingSubject,
    deletePending,
    parseInitPendingSolutions,
    SmtCache_v4_POST_solution,
    SmtCache_v4_POST_standings,
    SmtCache_v4_POST_solution__testsReport,
    SmtCache_v4_POST_solution__testsReport_report,
    SmtPortal_POST_test_results_testsReport,
    SmtPortal_POST_test_results_testsReport_report
};
