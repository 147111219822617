import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const InviteIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.3 11.775C8.679 11.775 8.175 12.279 8.175 12.9C8.175 13.521 8.679 14.025 9.3 14.025C9.921 14.025 10.425 13.521 10.425 12.9C10.425 12.279 9.921 11.775 9.3 11.775ZM14.7 11.775C14.079 11.775 13.575 12.279 13.575 12.9C13.575 13.521 14.079 14.025 14.7 14.025C15.321 14.025 15.825 13.521 15.825 12.9C15.825 12.279 15.321 11.775 14.7 11.775ZM12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 12 3ZM12 19.2C8.031 19.2 4.8 15.969 4.8 12C4.8 11.739 4.818 11.478 4.845 11.226C6.969 10.281 8.652 8.544 9.534 6.393C11.163 8.697 13.845 10.2 16.878 10.2C17.58 10.2 18.255 10.119 18.903 9.966C19.092 10.605 19.2 11.289 19.2 12C19.2 15.969 15.969 19.2 12 19.2Z"
            fill="#848484"
        />
        <rect x="13" y="15" width="10" height="7" rx="0.5" fill="#666666" />
        <path
            d="M22 16V17.3781L18.36 19.8863C18.1467 20.0379 17.8533 20.0379 17.6267 19.8863L14 17.3781V16L18 18.7562L22 16Z"
            fill="white"
        />
    </svg>

</SvgIcon>;


export default InviteIcon;
