import {SystemNotificationType} from "Lab/SystemNotifications/SystemNotification";
import {ModalPopupType} from "Lab/components/UI2/Elements/ModalPopup";

export interface ReqResponseError<info = undefined> {

    message: string;
    meta?:
        | info
        | { source: ReqResponseMetaSource[] }
        | { popup: ModalPopupType }
        | { copyMsg: string; copyButtonTitle: string; cancelButtonTitle?: string; onCancel?: (...args: any[]) => void };
    key?: string;
    params?: Record<string, any>;

}

export interface ReqResponseMetaSource {
    type: ReqResponseMetaSourceType;
    taskId?: number;
    taskVersion?: number;
    textId?: number;
    videoId?: number;
    contestId?: number;
    sectionId?: number;
    moduleId?: number;
    environmentId?: number;
    moduleVersionId?: number;
    moduleSection?: string;
    taskVersionId?: number;
    textVersionId?: number;
    videoVersionId?: number;
}


export enum ReqResponseMetaSourceType {
    Contest = 'contest',
    ContestEdit = 'contest-edit',
    ContestSection = 'contest-section',
    Module = 'module',
    Environment = 'environment',
    ContestTask = 'contestTask',
    ModuleTask = 'moduleTask',
    ModuleTaskExtra = 'moduleTaskExtra',
    ContestText = 'contestText',
    ModuleText = 'moduleText',
    ContestVideo = 'contestVideo',
    ModuleVideo = 'moduleVideo',
    Text = 'text',
    Task = 'task',
    Video = 'video',
    AnswersTable = 'answersTable',
    SMTContestEdit = 'smt-contest-edit',
}

export enum ReqResponseDialogueElementControlType {
    Agreement = "agreement",
    Select = "select",
    TextArea = "textArea"
}

export interface ReqResponseDialogueElementControl {
    type: ReqResponseDialogueElementControlType;
    name: string;
    value: string;
    text?: string;
    default?: string;
    options?: { text: string; value: string }[];
}

export interface ReqResponseDialogueElement {
    type: SystemNotificationType;
    text: string;
    meta: ReqResponseError['meta'];
    source?: ReqResponseMetaSource;
    control?: ReqResponseDialogueElementControl;
}


export interface ReqResponseDialogue {
    enabled: boolean;
    title: string;
    description: string;
    questions: ReqResponseDialogueElement[];
    buttonText: string;
}

export interface ReqResponse<success = {}, info = {}> {

    success?: success;
    error?: ReqResponseError<info>[];
    warning?: ReqResponseError<info>[];
    dialogue?: ReqResponseDialogue;

}

export type KeysOfType<TObj, TProp, K extends keyof TObj = keyof TObj> = K extends K ? TObj[K] extends TProp ? K : never : never;
