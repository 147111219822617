import React, {FC}              from "react";
import {VBox}                   from "@sirius/ui-lib/src/blocks/Layout";
import {getOptions}             from "@sirius/ui-lib/src/blocks/SchemaField/ui/default/utils";
import {SchemaFieldProps}       from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {StringFieldENUM}        from "./StringFieldEnum";
import {StringFieldDate}        from "./StringFieldDate";
import {StringFieldText}        from "./StringFieldText";


export const checkEmptyData = (data: SchemaFieldProps['data'] = '') =>
    data === null || (typeof data === 'string' && data.length === 0)
;

export const StringField: FC<SchemaFieldProps> = (props) => {
    const {
        block,
        schema,
        uischema,
        className = '',
        data,
        required,
        deep,
        validation,
        unfilled,
    } = props;
    const {getError}              = getOptions(props, 'ErrorField');
    const {isError: _isError}     = getError({deep, validation});
    const {format, enum: ENUM}    = schema;
    const {MetaField, ErrorField} = uischema;

    const isError              = _isError && !unfilled;
    const isENUM               = !!ENUM;
    const isDateTimeField      = !isENUM && 'format' in schema && ['date-time', 'date'].includes(format);
    const isTextField          = !(isENUM || isDateTimeField);
    const isEmpty              = checkEmptyData(data);
    const fieldClassName       = block.bod('required', required) +
                                 block.el('string') +
                                 block.el('string').bod('datetime', isDateTimeField) +
                                 block.el('string').bod('input',    isTextField) +
                                 block.el('string').mod('has', `${isEmpty ? 'no-' : ''}value`) +
                                 block.el('string').mod('has', `${isError ? '' : 'no-'}errors`) +
                                 className
    ;

    return  <VBox className={fieldClassName}>
                { isENUM
                    ?   <StringFieldENUM {...props} isError={isError}/>
                    :   <label className={
                            block.el('label') +
                            block.el('label').mod('string')
                        }>
                            <MetaField {...props} />
                            { isDateTimeField && <StringFieldDate {...props} isError={isError}/> }
                            { isTextField     && <StringFieldText {...props} isError={isError}/> }
                        </label>
                }
                <ErrorField {...props} />
            </VBox>
    ;
}
