import React, {useState} from 'react';
import ReactDOM          from 'react-dom';
import bnc               from 'bnc';
import {SmtLocaleString} from 'Smt/SmtLocaleBlock';
import UserInfo          from 'Smt/UserInfo';
import                        './UserProfileConfirmationModal.less';

const block = new bnc('user-confirmation-modal');

interface Props {
    tsUser?:              any;
    isExceptionAction?:   boolean;
    exceptionTitleKey?:   string;
    exceptionCaptionKey?: string;
    renderTaskPage?:      () => void;
}

const UserProfileConfirmationModal: React.FC<Props> = (
    {
        tsUser,
        renderTaskPage,
        isExceptionAction,
        exceptionTitleKey   = 'common.profile_confirmation.title',
        exceptionCaptionKey = 'common.profile_confirmation.caption'
    }) => {
    const [isNotMe, setIsNotMe] = useState(false);
    const [isTypo, setIsTypo]   = useState(false);

    return ReactDOM.createPortal(
        <div className = {block}>
            <div className = {block.el('wrapper')}>
                {
                    isExceptionAction
                    ? <>
                        <div className = {block.el('item')}>
                            <div className = {block.el('title')}>
                                <SmtLocaleString k = {exceptionTitleKey}/>
                            </div>
                            <div className = {block.el('caption')}>
                                <SmtLocaleString k = {exceptionCaptionKey} />
                            </div>
                        </div>

                        <div className = {block.el('item')}>
                            <button
                                className = {block.el('button') + block.el('button').mod('main')}
                                onClick   = {() => window.location.reload()}
                            >
                                <SmtLocaleString k='common.profile_confirmation.refresh' />
                            </button>
                        </div>
                    </>
                    : <>
                        {
                            !isNotMe && !isTypo &&
                            <>
                                <div className = {block.el('item')}>
                                    <div className = {block.el('title')}>
                                        <SmtLocaleString k='common.profile_confirmation.is_not_me_title' />
                                    </div>
                                </div>
                                <div className = {block.el('username')}>
                                    <div className = {block.el('username-wrapper')}>
                                        {tsUser?.userName}
                                    </div>
                                </div>

                                <div className = {block.el('item')}>
                                    <button
                                        className = {block.el('button') + block.el('button').mod('main')}
                                        onClick   = {renderTaskPage}
                                    >
                                        <SmtLocaleString k='common.profile_confirmation.confirm' />
                                    </button>
                                    <button className = {block.el('button')} onClick = {() => setIsTypo(true)}>
                                        <SmtLocaleString k='common.profile_confirmation.typo' />
                                    </button>
                                    <button className = {block.el('button')} onClick = {() => setIsNotMe(true)}>
                                        <SmtLocaleString k='common.profile_confirmation.it_is_not_me' />
                                    </button>
                                </div>
                            </>
                        }
                        {
                            isNotMe &&
                            <>
                                <div className = {block.el('item')}>
                                    <div className = {block.el('title')}>
                                        <SmtLocaleString k='common.profile_confirmation.mistake' />
                                    </div>
                                    <div className = {block.el('caption')}>
                                        <SmtLocaleString k='common.profile_confirmation.do_not_solve' />
                                    </div>
                                </div>

                                <div className = {block.el('item')}>
                                    <button
                                        className = {block.el('button') + block.el('button').mod('main')}
                                        onClick   = {() => UserInfo.logout()}
                                    >
                                        <SmtLocaleString k='common.profile_confirmation.exit' />
                                    </button>
                                </div>

                                <div className = {block.el('item')}>
                                    <div className = {block.el('descr')}>
                                        <SmtLocaleString k='common.profile_confirmation.check' />
                                    </div>
                                    <button
                                        className = {block.el('button') + block.el('button').mod('small')}
                                        onClick   = {() => setIsNotMe(false)}
                                    >
                                        <SmtLocaleString k='common.profile_confirmation.back' />
                                    </button>
                                </div>
                            </>
                        }
                        {
                            isTypo &&
                            <>
                                <div className = {block.el('item')}>
                                    <div className = {block.el('title')}>
                                        <SmtLocaleString k='common.profile_confirmation.keep_solving' />
                                    </div>
                                    <div className = {block.el('caption')}>
                                        <SmtLocaleString k='common.profile_confirmation.ask_for_help' />
                                    </div>
                                </div>


                                <div className = {block.el('item')}>
                                    <button
                                        className = {block.el('button') + block.el('button').mod('main')}
                                        onClick   = {renderTaskPage}
                                    >
                                        <SmtLocaleString k='common.profile_confirmation.to_olympiad' />
                                    </button>
                                </div>

                                <div className = {block.el('item')}>
                                    <div className = {block.el('descr')}>
                                        <SmtLocaleString k='common.profile_confirmation.check_again' />
                                    </div>
                                    <button
                                        className = {block.el('button') + block.el('button').mod('small')}
                                        onClick   = {() => setIsTypo(false)}
                                    >
                                        <SmtLocaleString k='common.profile_confirmation.back' />
                                    </button>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>,
        document.body
    );

};


export default UserProfileConfirmationModal;
