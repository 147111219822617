import React from "react";
import {SvgIcon} from "@material-ui/core";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const DoNotTouchIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.1251 3C14.5026 3 14.0001 3.5025 14.0001 4.125V7.875C14.0001 8.085 13.8351 8.25 13.6251 8.25C13.4151 8.25 13.2501 8.085 13.2501 7.875V1.875C13.2501 1.2525 12.7476 0.75 12.1251 0.75C11.5026 0.75 11.0001 1.2525 11.0001 1.875V7.875C11.0001 8.085 10.8351 8.25 10.6251 8.25C10.4151 8.25 10.2501 8.085 10.2501 7.875V1.125C10.2501 0.5025 9.74759 0 9.12509 0C8.50259 0 8.00009 0.5025 8.00009 1.125V7.8675C8.00009 8.0775 7.83509 8.2425 7.62509 8.2425C7.41509 8.2425 7.25009 8.0775 7.25009 7.8675V3.375C7.25009 2.7525 6.74759 2.25 6.12509 2.25C5.50259 2.25 5.00009 2.7525 5.00009 3.375V11.9325L1.91009 10.17C1.47509 9.9225 0.935089 9.99 0.575089 10.335C0.125089 10.77 0.110089 11.49 0.552589 11.9325L5.63759 17.1C6.20009 17.6775 6.96509 18 7.77509 18H13.2501C14.9076 18 16.2501 16.6575 16.2501 15V4.125C16.2501 3.5025 15.7476 3 15.1251 3Z"
            fill="#FFBF00"
        />
    </svg>
</SvgIcon>;


export default DoNotTouchIcon;
