import React, {FunctionComponent} from "react";
import RadioElement from "Lab/components/UI2/Elements/RadioElement";
import {RadioControlLabelPlacement} from "Lab/components/UI2/Controls/RadioControl";
import SystemNotificationsDialogueControl from "Lab/SystemNotifications/SystemNotificationsDialogueControl";
import {ControlSizes} from "Lab/components/UI2/UIControlWrapper";

const SystemNotificationsDialogueSelectControl: FunctionComponent<SystemNotificationsDialogueControl> = (props) => {

    const {
        control,
        value,
        onChange,
        disabled,
    } = props;

    return <>
        {control.options.map((option) => <RadioElement
            key={option.value}
            value={option.value}
            name={control.name}
            disabled={disabled}
            checked={value === option.value}
            labelPlacement={RadioControlLabelPlacement.left}
            onChange={() => onChange(control.name, option.value)}
            color="primary"
            size={ControlSizes.SM}
        >
            {option.text}
        </RadioElement>)}
    </>;

};

export default SystemNotificationsDialogueSelectControl;
