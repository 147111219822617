import React, {ReactElement} from 'react';

export interface UIAtomProps {

    disabled?: boolean;
    hasErrors?: boolean;

}


export abstract class UIAtom<P> extends React.Component<P & UIAtomProps>
    implements ReactElement {


    static defaultProps = {
        disabled: false,
        hasErrors: false,
    };

    key: React.Key | null;
    type: string | React.JSXElementConstructor<any>;

}
