import React             from "react";
import bnc               from "bnc";
import {VBox, HBox}      from "@sirius/ui-lib/src/blocks/Layout";
import {HasExamples}     from "Smt/SmtProgrammingAnswer/block";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

const block = new bnc("programming-answer-examples");

const Examples = ({examples}: HasExamples) =>
    <VBox className = {`${block}`}>

        <h3 className = {block.el('title')}>
            <SmtLocaleString k='programming.examples.title'/>
        </h3>
        <HBox className = {block.el('header')}>
            <h4 className = {block.el('header').mod('title')}>
                <SmtLocaleString k='programming.examples.input'/>
            </h4>
            <h4 className = {block.el('header').mod('title')}>
                <SmtLocaleString k='programming.examples.output'/>
            </h4>
        </HBox>

        {examples.map((example, i) =>
            <HBox className = {block.el('row')} key={i} mobOverturn>
                <VBox className = {block.el('row-part')}>
                    <h4 className = {block.el('row-title')}>
                        <SmtLocaleString k='programming.examples.input'/>
                    </h4>
                    <p className = {block.el('row')+
                                    block.mod('content')}
                    >
                        {example.input}
                    </p>
                </VBox>
                <VBox className = {block.el('row-part')}>
                    <h4 className = {block.el('row-title')}>
                        <SmtLocaleString k='programming.examples.output'/>
                    </h4>
                    <p className = {block.el('row')+
                                    block.mod('content')}
                    >
                        {example.output}
                    </p>
                </VBox>
            </HBox>
        )}
    </VBox>
;

export {Examples};
