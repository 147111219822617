import React, {FC, useEffect, useRef, useState} from "react";
import {block}                                  from "./block";
import {
    SVGEulerCirclesType, SVGEulerCirclesSizes,
    EulerCircleType, EulerCircleSize,
    EulerControlSize, EulerCtrlType,
    EulerCirclePlacementType, EulerCircleSetPlacement,
    Placements, PlacementsKeys,
    AllPlacements, PlacementOffset, PlacementOffsets,
    DragObj, EulerCirclePool,
    MousePositionType, SVGPointXY, HoverCirclesType,
    EulerCircleSetK, EulerCirclePlacementSetType, EulerCircleSetSort
} from "./@types";


const el        = block.el('euler-circles');

const MaxRadius = 93;

const sumNum = (a:number, b:number )=>
    a + b
;

const sumArr = (arr: number[]):number =>
    arr.reduce(sumNum, 0)
;

const pKeysSort = (a: string, b: string) =>
    a.length !== b.length
        ? a.length - b.length
        : a > b
            ? 1
            : -1
;

const centroid  = (k: "cx" | "cy", points: EulerCircleSize[]): number =>
    sumArr( points.map( p=>p[k] ) ) / points.length
;

const getCenter = (points: EulerCircleSize[]): EulerCircleSetPlacement =>
    ({
        x: centroid('cx', points),
        y: centroid('cy', points)
    })
;

const offsetCenter = (offset: PlacementOffset, placement:EulerCircleSetPlacement): EulerCircleSetPlacement => ({
    ...placement,
    x: placement.x + offset.x,
    y: placement.y + offset.y
})

const PlacementSetOffsets: Record<number, Record<number, SVGPointXY>> = {
    1: {
        0: {x:0, y:0}
    },
    2: {
        0: {x:0, y: -0.5},
        1: {x:0, y:  1},
    },
    3: {
        0: {x: 0,   y: 0},
        1: {x:-0.7,   y: 1},
        2: {x: 0.7,   y: 1},
    },
    4: {
        0: {x: -0.7,   y: -0.7},
        1: {x:  0.7,   y: -0.7},
        2: {x:  0.7,   y:  0.7},
        3: {x: -0.7,   y:  0.7},
    },
}


const getEulerCirclePlacementSetOffset = (num: number, placesNum: number, r: number, count: number): SVGPointXY => {
    const d = r * 2;
    const {x,y} = PlacementSetOffsets[placesNum][num];
    return count ? {x: x * d, y: y * d} : {x:-1000, y:-1000};
};

const getAllPlacements = (circles: {A: EulerCircleSize, B: EulerCircleSize, C: EulerCircleSize}, rhalf: number, rthird: number ): AllPlacements => {
    const offsets: PlacementOffsets = {
        "A":   {x:rthird * -1, y:rthird * -.5},
        "B":   {x:rthird,      y:rthird * -.5},
        "C":   {x:0,           y:rthird *  .5},
        "AC":  {x:rthird * -1, y:rthird *  .2},
        "BC":  {x:rthird,      y:rthird *  .2},
        "AB":  {x:0,           y:rthird * -.5},
        "ABC": {x:0,           y:0},
    };
    const keys = Object.keys(Placements) as Placements[];
    const res = keys.reduce(
        (acc, place) => ({
            ...acc,
            [place]:offsetCenter(
                        offsets[place],
                        getCenter(
                            place.split('').map(
                                (k) => circles[k as "A" | "B" | "C"]
                            )
                        )
                    )
        }),
        {} as Record<PlacementsKeys, EulerCircleSetPlacement>
    )
    return res;

}

const calcSizes = (rect: DOMRect): SVGEulerCirclesSizes => {
    const {width, height}       = rect;
    const r                     = Math.min( MaxRadius , Math.min(width, height) / 3.5 );
    const centerX               = width  / 2;
    const centerY               = height / 2;
    const rhalf                 = r / 2;
    const rthird                = r / 3;
    const topPadding            = 21;
    const A:EulerCircleSize     = {r, cx:centerX - rhalf, cy: topPadding + r  , textOffset: {x:rhalf * -1, y:0} };
    const B:EulerCircleSize     = {r, cx:centerX + rhalf, cy: topPadding + r  , textOffset: {x:rhalf     , y:0} };
    const C:EulerCircleSize     = {r, cx:centerX,         cy: topPadding + 2*r, textOffset: {x:0         , y:rhalf} };
    const ctrl:EulerControlSize = {r: r/5};
    const circles = {A,B,C};
    const placements: Record<PlacementsKeys, EulerCircleSetPlacement> = getAllPlacements(circles, rhalf, rthird)

    return {
        A, B, C,
        r,
        rhalf,
        rthird,
        width,
        height,
        centerX,
        centerY,
        topPadding,
        ctrl,
        placements
    }
};

const getLabelForSet = ({sets}: SmtTask.EulerCircleWidget, k: SmtTask.EulerCircleWidgetSetKey): string =>
    sets.find(
        ({key}) => (key === k)
    ).label || "~"
;


const EulerCtrl: FC<EulerCtrlType> = ({
    ctrl,
    mx,
    label,
    k,
    count,
    startDrag,
    disabled
}) => {
    const startDragHandler = !disabled && count > 0
                        ? (e:React.MouseEvent | React.TouchEvent) => {
                            startDrag(e,{src: "bank", k})
                        }
                        : void (0)
    ;

    return  <svg
                className     = {el.mod('circle-set')}
                onMouseDown   = {startDragHandler}
                onTouchStart  = {startDragHandler}
            >
                <circle
                    className={
                        el.mod(`control-circle`) +
                        el.bod('draggable', count > 0) +
                        el.bod('control-empty', count === 0) +
                        el.mod(`control-${k}`)
                    }
                    r={ctrl.r}
                    cx={ctrl.r * mx} cy={0}
                />
                <text className={el.mod('control-label')} x={ctrl.r * mx} y={ctrl.r * 1.7} textAnchor="middle">{label}</text>
                <text className={el.mod('control-count') + el.bod('control-count-empty', count === 0)} x={ctrl.r * mx} y={6}
                      textAnchor="middle">
                    {Number.isFinite(count) ? count : "∞"}
                </text>
            </svg>
};

const EulerCirclePlacementSet:FC<EulerCirclePlacementSetType> = ({
    num,
    place,
    placesNum,
    set,
    count,
    r,
    startDrag,
    disabled
}) => {
    const startDragHandler = disabled
                        ? void (0)
                        : (e: React.MouseEvent | React.TouchEvent) => {
                            startDrag(e, {src: place, k: set})
                        }
    ;
    return  <svg
                onMouseDown   = {startDragHandler}
                onTouchStart  = {startDragHandler}
                className     = {
                    el.mod(`placement`) +
                    el.mod(`placement`, place)
                }
                {...getEulerCirclePlacementSetOffset(num, placesNum, r, count)}
            >
                {
                    count > 2 &&
                    <circle
                        cx={6}
                        r={r}
                        className={
                            el.mod(`control-circle`) +
                            el.mod(`control`, `${set}-many`)
                        }
                    />
                }{
                    count > 1 &&
                    <circle
                        cx={3}
                        r={r}
                        className={
                            el.mod(`control-circle`) +
                            el.mod(`control`, `${set}-second`)
                        }
                    />
                }
                <circle
                    r={r}
                    className={
                        el.mod(`control-circle`) +
                        el.bod('draggable', count > 0) +
                        el.mod(`control`, set)
                    }
                />
                <text
                    className={
                        el.mod('placement-label') +
                        el.mod('control-count') +
                        'text-xxs'
                    }
                    x={0}
                    y={3}
                    textAnchor="middle"
                >{count}</text>
            </svg>
    ;
};

const PlacementSpecOffset: Partial<Record<EulerCircleSetK, SVGPointXY>> = {
    A:  {x: -2, y: -3},
    B:  {x:  2, y: -3},
    AB: {x:  0, y: -3}
}

const EulerCirclePlacement:FC<EulerCirclePlacementType> = ({
    name,
    pool,
    placement,
    r,
    startDrag,
    disabled
}) =>
    <svg
        className={
            el.mod('circles-layer')
        }
        x={placement.x + (name in PlacementSpecOffset ? PlacementSpecOffset[name].x * (r / 10) : 0)}
        y={placement.y + (name in PlacementSpecOffset ? PlacementSpecOffset[name].y * (r / 10) : 0)}
    >
        {
            Object.entries(pool[name]).map(
                ([set, count], si, arr)=>
                    <EulerCirclePlacementSet
                        key       = {si}
                        num       = {si}
                        placesNum = {arr.length}
                        set       = {set as EulerCircleSetSort}
                        place     = {name}
                        count     = {count}
                        r         = {r / 10}
                        startDrag = {disabled ? void(0): startDrag}
                        disabled  = {disabled}
                    />
            )
        }
    </svg>
;

const EulerCircle: FC<EulerCircleType> = ({
    hover,
    dCircle,
    name,
    children
}) =>
    <svg
        className={
            el.mod('circle-set') +
            el.mod(`circle`,name) +
            el.bod(`circle-hover`, hover)
        }
     >
        <circle
            className={
                el.mod('circle-shape')
            }
            r  = {dCircle.r}
            cx = {dCircle.cx}
            cy = {dCircle.cy}
        />
        <text
            y={dCircle.cy + dCircle?.textOffset.y}
            className={el.mod('circle-label')}
        >
            {`${children}`.split(' ').map(
                (word,wi)=>
                    <tspan
                        key        = {wi}
                        x          = {dCircle.cx + dCircle?.textOffset.x}
                        dy         = "1em"
                        textAnchor = "middle"
                    >{word}</tspan>
            )}
        </text>
    </svg>
;

const getPoolByAnswerWidget = (answerWidget: SmtTask.EulerCircleWidget, userAnswer?: ({solution:number[]})[]): EulerCirclePool => {
    const {sets, placements} = answerWidget;
    const {bank, transit} = sets.reduce(
        ({bank, transit}, {key,...set}, si)=>({
            bank:    {
                ...bank,
                [key]: 'count' in set
                    ? set.count - (
                        userAnswer
                            ? userAnswer.reduce(
                                (acc, {solution})=>(acc + parseInt(`${solution[si]}`)),
                                0
                            )
                            : 0
                    )
                    : Infinity
            },
            transit: {...transit, [key]: 0}
        }),
        {bank:{}, transit:{}}
    );
    const places = Object.keys(placements).sort( pKeysSort ).reduce(
        (acc, place, pi)=>({
            ...acc,
            [place as keyof SmtTask.EulerCircleWidgetPlacements]: sets.reduce(
                        (inner,{key}, si)=>({
                            ...inner,
                            [key]: userAnswer && userAnswer[pi]?.solution && userAnswer[pi]?.solution[si]
                                ? parseInt(`${userAnswer[pi]?.solution[si]}`)
                                : 0
                        }),
                        {}
                     )
        }),
        {}
    );
    return {bank, transit, ...places};
}

const isPointInCircle = ({x,y}: SVGPointXY, {cx,cy,r}: EulerCircleSize ) =>
    Math.hypot(cx - x, cy - y) < r
;

const getTargetPlacementByHoverCircles = ( hoverCircles:HoverCirclesType ) =>
    Object.entries(hoverCircles).reduce(
        (places, [k,h]) => {
            h && places.push(k);
            return places;
        },[]
    ).join('') || false;
;

const hasPoolChanged = ({bank:_ab, transit:_at, ...initPool}:EulerCirclePool, {bank:_bb, transit:_bt,...pool}: EulerCirclePool) =>
     JSON.stringify(initPool) !== JSON.stringify(pool)
;

const initHoverCircles  = {A:false,B:false,C:false}
const initMousePosition = {x:0,y:0};

export const SVGEulerCircles: FC<SVGEulerCirclesType> = ({
    rect,
    answerWidget,
    userAnswer,
    onAnswer,
    savedAnswer,
    disabled
}) => {
    const [pool, setEulerPool] = useState<EulerCirclePool>( getPoolByAnswerWidget(answerWidget, userAnswer));
    const [{r, ctrl, height,
            width, centerX,
            A, B, C,
            placements},
            setSizes] = useState<SVGEulerCirclesSizes>( calcSizes(rect) );
    const [mousePosition, setMousePosition] = useState<MousePositionType>(initMousePosition);
    const [hoverCircles, setHoverCircles]   = useState<HoverCirclesType>(initHoverCircles);

    const root = useRef<SVGSVGElement>(null);

    useEffect(
        ()=>{
            //console.log('rect', {height, width}, calcSizes(rect))
            setSizes( calcSizes(rect) );
            setMousePosition(initMousePosition);
            setHoverCircles(initHoverCircles);
        },
        [JSON.stringify(rect)]
    );

    useEffect(
        ()=>{
            const {transit}  = pool;
            const hasTransit = sumArr(Object.values(transit)) > 0;
            if (!hasTransit && !disabled && onAnswer) {
                const pKeys: string[] = Object.keys(answerWidget.placements).sort(pKeysSort)
                const answer = pKeys.map(
                    (k) => ({solution: Object.values(pool[k as EulerCircleSetK])})
                )
                const initPool      = getPoolByAnswerWidget(answerWidget, userAnswer);
                const isPoolChanged = hasPoolChanged(initPool, pool);
                const next          = isPoolChanged ? answer : userAnswer;
                (next !== userAnswer) && onAnswer(next, () => {});
            }
        },
        [pool]
    );

    useEffect(
        ()=>{
            setEulerPool(
                getPoolByAnswerWidget(answerWidget, userAnswer)
            )
        },
        [JSON.stringify(answerWidget), JSON.stringify(userAnswer)]
    );

    useEffect(
        ()=>{
            if (!disabled){
                root.current.addEventListener('mousemove',  mouseMovePreventHandler, {passive:false});
                root.current.addEventListener('touchmove',  mouseMovePreventHandler, {passive:false});
                root.current.addEventListener('mousemove',  mouseMoveHandler );
                root.current.addEventListener('touchmove',  mouseMoveHandler );
            }
            return ()=>{
                !disabled && root.current?.removeEventListener('mousemove',  mouseMoveHandler);
                !disabled && root.current?.removeEventListener('mousemove',  mouseMovePreventHandler);
                !disabled && root.current?.removeEventListener('touchmove',  mouseMoveHandler);
                !disabled && root.current?.removeEventListener('touchmove',  mouseMovePreventHandler);
            }
        },
        [disabled, JSON.stringify(pool)]
    )

    useEffect(
        ()=>{
            const hover = {
                A:isPointInCircle(mousePosition, A),
                B:isPointInCircle(mousePosition, B),
                C:isPointInCircle(mousePosition, C)
            };
            setHoverCircles(hover);
        },
        [mousePosition.x, mousePosition.y]
    )

    const mouseMovePreventHandler = (e: MouseEvent | TouchEvent) => {
        const {type} = e;
        if (type.includes("touch")) {
            (   hasTransit           ||
                type === "touchend" ||
                type === "touchstart"
            ) && (
                e.preventDefault()
            );
        }
    }

    const mouseMoveHandler = (e: MouseEvent | TouchEvent) => {
        const {type} = e;
        const node = e.target as SVGSVGElement;
        const isTouch = type.includes("touch");
        let x;
        let y;
        if (isTouch) {
            const {targetTouches, changedTouches} = e as TouchEvent;
            const touch = (targetTouches || changedTouches).item(0);
            const rect = root.current.getBoundingClientRect();
            x = touch?.pageX - rect.left;
            y = touch?.pageY - rect.top - window.scrollY;
        } else {
            let {offsetX, offsetY} = e as MouseEvent;
            x = offsetX;
            y = offsetY;
        }
        const mouse: SVGPointXY = {x, y};
        (node.nodeName !== 'tspan') &&
        (x !==0 && y !== 0) &&
        (!Number.isNaN(x) && !Number.isNaN(y)) &&
        setMousePosition(mouse);
    }

    const startDragWithPool =
        (pool: EulerCirclePool, {src, k}: DragObj) =>
    {
        if (pool[src][k] > 0) {
            setEulerPool({
                ...pool,
                [src]: {
                    ...pool[src],
                    [k]: pool[src][k] - 1
                },
                "transit": {
                    ...pool.transit,
                    [k]:1
                }
            })
        }
    }

    const stopDragWithPool =
        (pool: EulerCirclePool) =>
    {
        const {transit} = pool;
        const targetPlacement = getTargetPlacementByHoverCircles( hoverCircles );

        if (transit){
            const [k, count] = Object.entries(transit).find( ([,v])=>(v > 0) ) || [];
            if (k && count) {
                const target:EulerCircleSetK = (targetPlacement as PlacementsKeys) || 'bank';
                const next = {
                                ...pool,
                                [target]: {
                                    ...pool[target],
                                    [k]: pool[target][k as SmtTask.EulerCircleWidgetSetKey] + 1
                                },
                                "transit": {
                                    ...pool.transit,
                                    [k]:0
                                }
                            }
                ;
                setEulerPool(next)
            }
        }
    }

    const reset = () => {
        onAnswer(
            savedAnswer || void(0), ()=>{}
        )
    }

    const [Transit] = Object.entries(pool.transit).find( ([k,v])=>v > 0 ) || []

    const hasTransit = Transit !== void(0);

    const startDP = disabled
        ? void(0)
        : (e:React.MouseEvent | React.TouchEvent, drag: DragObj) => {
            mouseMoveHandler (e.nativeEvent);
            startDragWithPool(pool, drag);
        }
    ;
    const stopDP  = disabled
        ? void(0)
        : (e:React.MouseEvent | React.TouchEvent) => {
            mouseMoveHandler (e.nativeEvent);
            stopDragWithPool (pool);
        }
    ;

    return  <svg
                ref            = {root}
                className      = {el}
                width          = {width}
                height         = {height}
                onTouchEnd     = {stopDP}
                onMouseUp      = {stopDP}
                onTouchCancel  = {stopDP}
                onMouseLeave   = {stopDP}
            >
                 {/*<svg className={el.mod('debug')}>
                    <text y="10" className={el.mod('debug-text')}>r: {r}</text>
                    <text y="20" className={el.mod('debug-text')}>w: {width}</text>
                    <text y="30" className={el.mod('debug-text')}>h: {height}</text>
                    <text y="40" className={el.mod('debug-text')}>centerX: {centerX}</text>
                    <text y="50" className={el.mod('debug-text')}>hoverCircles.A: {hoverCircles.A ? '+' : '-'}</text>
                    <text y="60" className={el.mod('debug-text')}>hoverCircles.B: {hoverCircles.B ? '+' : '-'}</text>
                    <text y="70" className={el.mod('debug-text')}>hoverCircles.C: {hoverCircles.C ? '+' : '-'}</text>
                    <text y="80" className={el.mod('debug-text')}>hasTransit {hasTransit ? "[+]" : ""}</text>
                    <line x1={0}               x2={width}           y1={mousePosition.y} y2={mousePosition.y} stroke={'black'}/>
                    <line x1={mousePosition.x} x2={mousePosition.x} y1={0}               y2={height}          stroke={'black'}/>
                </svg>*/}
                <svg className={el.mod('circles-layer')} >
                    <EulerCircle hover={hoverCircles.A} dCircle={A} name="A">{answerWidget.circles.A}</EulerCircle>
                    <EulerCircle hover={hoverCircles.B} dCircle={B} name="B">{answerWidget.circles.B}</EulerCircle>
                    <EulerCircle hover={hoverCircles.C} dCircle={C} name="C">{answerWidget.circles.C}</EulerCircle>
                </svg>
                <svg className={el.mod('circles-placements')}>
                    {
                        (Object.keys(placements).sort( pKeysSort ) as EulerCircleSetK[]).map(
                            (place, pi)=>
                                <EulerCirclePlacement
                                    key       = {pi}
                                    r         = {r}
                                    pool      = {pool}
                                    placement = {placements[place]}
                                    name      = {place}
                                    startDrag = {startDP}
                                    disabled  = {disabled}
                                />
                        )
                    }
                </svg>
                <svg
                    className={el.mod('circles-bottom')}
                    x={centerX - (ctrl.r * 2 * (answerWidget.sets.length - 1))}
                    y={height  -  ctrl.r * 2}
                >
                    {
                        Object.entries(pool.bank).map(
                            ([k ,count] , bi)=>
                                <EulerCtrl
                                    key       = {bi}
                                    count     = {pool.bank[k as SmtTask.EulerCircleWidgetSetKey]}
                                    k         = {k as SmtTask.EulerCircleWidgetSetKey}
                                    ctrl      = {ctrl}
                                    mx        = {bi * 4}
                                    label     = {getLabelForSet(answerWidget, k as SmtTask.EulerCircleWidgetSetKey)}
                                    startDrag = {startDP}
                                    disabled  = {disabled}
                                />
                        )
                    }
                </svg>

                <svg
                    className={el.mod('circle-set')}
                    x={hasTransit ? mousePosition.x - 4 : -1000}
                    y={hasTransit ? mousePosition.y - 4 : -1000}
                >
                    <circle
                        className={
                            el.mod('transit') +
                            el.mod('transit', Transit)
                        }
                        r={ctrl.r}
                    />
                    <text
                        className={
                            el.mod('transit-label') +
                            el.mod('control-count')
                        }
                        x={0}
                        y={6}
                        textAnchor="middle"
                    >1</text>
                </svg>

                {
                    !disabled &&
                    <svg
                        onPointerUp = {reset}
                        onMouseUp   = {reset}
                        className   = {el.mod('reset')}
                        width       = "32"
                        height      = "32"
                        viewBox     = "0 0 32 32"
                        fill        = "none"
                        x           = {width - 40}
                        y           = {10}
                    >
                        <rect width="32" height="32" fill={"white"}/>
                        <path className={el.mod('reset-path')} d="M20.7086 11.2916C19.3502 9.93325 17.4252 9.14991 15.3086 9.36658C12.2502 9.67491 9.73355 12.1582 9.39189 15.2166C8.93355 19.2582 12.0586 22.6666 16.0002 22.6666C18.6586 22.6666 20.9419 21.1082 22.0086 18.8666C22.2752 18.3082 21.8752 17.6666 21.2586 17.6666C20.9502 17.6666 20.6586 17.8332 20.5252 18.1082C19.5836 20.1332 17.3252 21.4166 14.8586 20.8666C13.0086 20.4582 11.5169 18.9499 11.1252 17.0999C10.4252 13.8666 12.8836 10.9999 16.0002 10.9999C17.3836 10.9999 18.6169 11.5749 19.5169 12.4832L18.2586 13.7416C17.7336 14.2666 18.1002 15.1666 18.8419 15.1666H21.8336C22.2919 15.1666 22.6669 14.7916 22.6669 14.3332V11.3416C22.6669 10.5999 21.7669 10.2249 21.2419 10.7499L20.7086 11.2916Z"/>
                    </svg>
                }
            </svg>
    ;
}
