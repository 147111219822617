import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const UploadIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 13V18C19 18.55 18.55 19 18 19H6C5.45 19 5 18.55 5 18V13C5 12.45 4.55 12 4 12C3.45 12 3 12.45 3 13V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13ZM13 6.4125L14.88 8.2925C15.27 8.6825 15.9 8.6825 16.29 8.2925C16.68 7.9025 16.68 7.2725 16.29 6.8825L12.7 3.2925C12.31 2.9025 11.68 2.9025 11.29 3.2925L7.7 6.8825C7.31 7.2725 7.31 7.9025 7.7 8.2925C8.09 8.6825 8.72 8.6825 9.11 8.2925L11 6.4125V15.0825C11 15.6325 11.45 16.0825 12 16.0825C12.55 16.0825 13 15.6325 13 15.0825V6.4125Z"
            fill="white"
        />
    </svg>
</SvgIcon>;


export default UploadIcon;
