import { useEffect, useState }                                   from "react";
import {getVideoResolveContainer as getVideoResolveContainerNoo} from "Cheops/actions/noopolis-ts";
import {getVideoResolveContainer as getVideoResolveContainerSmt} from "Smt/actions/webPortal";
import {
    getVideoResolveContainer as getVideoResolveContainerLabNoo,
    TGetVideoResolveContainer as TGetVideoResolveContainerLabNoo
}                                                                from "Lab/actionsV2/environment";
import {
    getVideoResolveContainer as getVideoResolveContainerLabSmt,
    TGetVideoResolveContainer as TGetVideoResolveContainerLabSmt
}                                                                from "Lab/actionsV2/smt";
import {IVideoContainers}                                        from "Smt/hooks/useTagsParse";

export interface IUseVideoResolveArgs {
    instance: "noo" | "smt" | "lab";
    isSmt?: boolean;
}
type TGetVideoContainer = (url: string, code?: string) => void;
type TUseVideoResolveReturn = [
    Common.Video.VideoResolveContainer | IVideoContainers,
    TGetVideoContainer
];

type TGetVideoResolveContainerLabReturn = TGetVideoResolveContainerLabNoo | TGetVideoResolveContainerLabSmt;
type TGetVideoResolveContainerLab = (isSmt: IUseVideoResolveArgs["isSmt"]) => TGetVideoResolveContainerLabReturn;

const getVideoResolveContainerLab: TGetVideoResolveContainerLab = (isSmt) => {
    return isSmt
        ? getVideoResolveContainerLabSmt
        : getVideoResolveContainerLabNoo
}

export const useVideoResolve = ( {instance, isSmt = false}: IUseVideoResolveArgs ): TUseVideoResolveReturn => {

    const [videoContainer, setVideoContainer] = useState<Common.Video.VideoResolveContainer | IVideoContainers>(null);
    const [urlVideo, setUrlVideo] = useState<Common.Video.VideoResolveContainer["url"]>(null);
    const [codeVideo, setCodeVideo] = useState<string>(null);

    const getVideoResolveContainer = {
        noo: getVideoResolveContainerNoo,
        smt: getVideoResolveContainerSmt,
        lab: getVideoResolveContainerLab(isSmt),
    }
    const instanceLowerCase: IUseVideoResolveArgs["instance"] = instance?.toLowerCase() as IUseVideoResolveArgs["instance"] ;
    useEffect(() => {
        urlVideo
        && getVideoResolveContainer[instanceLowerCase]({urlVideo })
            .then( res => {
                const {type, url} = ("success" in res)
                    ?   res.success as Common.Video.VideoResolveContainer
                    :   res as Common.Video.VideoResolveContainer;

                    codeVideo
                        ? setVideoContainer(
                            {
                                [codeVideo]: {type, url}
                            }
                        )
                        : setVideoContainer({type, url});
                }
            )
            .catch( err => {
                setVideoContainer({type: "unknown", url: urlVideo })
            } );
    }, [urlVideo]);

    const  getVideoContainer: TGetVideoContainer = (url, code = null): void => {
        code && setCodeVideo(code);
        setUrlVideo(url);
    }

    return [videoContainer, getVideoContainer]

}
