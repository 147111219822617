import React, {FC}            from "react";
import {marked}               from "marked";
import {HBox, VBox}           from "@sirius/ui-lib/src/blocks/Layout";
import {SchemaFieldProps}     from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {getOptions}           from "@sirius/ui-lib/src/blocks/SchemaField/ui/default/utils";

const renderer = {
    link: (href: string, title: string, text: string) =>
        `<a onclick="event.stopPropagation()" href="${href}" class="marked-link" title="${title || ''}" rel="noopener" target="_blank">${text}</a>`
};

marked.use({ renderer });

export const MetaField: FC<SchemaFieldProps> = (props) => {
    const {
        block,
        schema,
        children,
        uischema,
        uioptions,
        deep,
        titleClick
    } = props;
    const isRoot        = deep.length === 0;
    const deepRoot      = isRoot ? 'root' : `deep-${deep.length}`;
    const options       = getOptions(props, 'MetaField');
    const {getMeta}     = options;
    const {title}       = getMeta({schema, deep: isRoot ? [deepRoot] : deep, uischema, uioptions});
    const hasMeta       = !!title ;
    const __html        = hasMeta ? marked(title) : void(0);
    const metaClassName = block.el('meta') +
                          block.el('meta').mod(deepRoot)
    ;

    return hasMeta
        ?   <VBox className={metaClassName}>
                {
                    !!title &&
                    <HBox className={block.el('title')} onClick={titleClick}>
                        <span className={block.el('title-label')} dangerouslySetInnerHTML={{__html}}/>
                    </HBox>
                }
                { children }
            </VBox>
        : null
    ;
}
