import React, {FC}                      from "react";
import bnc                              from "bnc";
import {HBox}                           from "@sirius/ui-lib/src/blocks/Layout";
import {InputClientValidationType}      from "./@types";
import                                       "./InputClientValidation.less"

export const block = new bnc('input-client-validation');


export const InputClientValidation : FC<InputClientValidationType> = ({report}) => {

    const {level, message} = report;

    return <div className={
                block +
                block.mod('level', level) +
                block.bod('show', true)
            }>
                <HBox className={block.el('layout') + ' text-m '}>
                    <span>{message}</span>
                </HBox>
           </div>
}
