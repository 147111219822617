import React from "react";
import {i18nStr, withLocale} from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {ELocales} from "@sirius/ui-lib/src/subjects/Locale";

type Props = {
    inputName?: string;
    tpl: any;
    inputValue: string | number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    locale: ELocales;
    inputClass?: string;
    type?: string;
};

const FormattedInput = ({inputName, tpl, inputValue, onChange, locale, type, inputClass}: Props): React.ReactNode => {

    const label = i18nStr({tpl, id: "value", locale});

    return  <input
                type           = {type}
                placeholder    = {label}
                name           = {inputName}
                defaultValue   = {inputValue}
                className      = {inputClass}
                onChange       = {onChange}
                defaultChecked = {type === 'checkbox' ? !!inputValue === true : void(0) }
            />
    ;
};

FormattedInput.defaultProps = {onChange: () => {}, inputName: "", inputClass: "", type: "text"};

const LocalizedInput = withLocale(FormattedInput);
// @ts-ignore
LocalizedInput.displayName = 'LocalizedInput';

export {LocalizedInput};
