import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import FormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel/FormControlLabel';
import {RadioControlLabelPlacement} from "Lab/components/UI2/Controls/RadioControl";
import BemClassName from "Cheops/BemClassName";
import {UIControl, UIControlProps} from "../UIControl";
import './CheckboxControl.less';

export interface CheckboxControlProps extends UIControlProps {

    onChange?(event: React.MouseEvent, checked: boolean): void;
    onClick?(event: React.MouseEvent): void;

    children?: React.ReactNode;
    labelPlacement?: RadioControlLabelPlacement;
    size?: ControlSizes;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    name?: string;
    value?: string;
    fullWidth?: boolean;
    align?: React.CSSProperties["alignItems"];
    color?: "primary" | "secondary" | "default";
    darkMode?: boolean;
    labelColor?: "primary" | "caption";
}


export default class CheckboxControl extends UIControl<CheckboxControlProps> {

    static defaultProps = {
        ...UIControl.defaultProps,
        size: ControlSizes.LG,
        checked: false,
        disabled: false,
        className: "",
        labelPlacement: RadioControlLabelPlacement.right,
        color: "secondary",
    };


    getLabelPlacement(): FormControlLabelProps['labelPlacement'] {

        const {labelPlacement} = this.props;


        const labelPlacementToMUIPlacement = {
            [RadioControlLabelPlacement.right]: 'end',
            [RadioControlLabelPlacement.left]: 'start',
            [RadioControlLabelPlacement.bottom]: 'bottom',
            [RadioControlLabelPlacement.top]: 'top',
        };

        return labelPlacementToMUIPlacement[labelPlacement] as FormControlLabelProps['labelPlacement'];

    }

    render(): React.ReactNode {

        const {
            checked,
            children,
            disabled,
            className,
            value,
            fullWidth,
            onChange,
            size,
            color,
            align,
            darkMode,
            labelColor,
            onClick,
        } = this.props;

        const wrapperClassName = new BemClassName("checkbox_control", null, className);
        wrapperClassName.appendStatusIf(darkMode, "darkMode");
        wrapperClassName.appendStatusIf(!!labelColor, `label-${labelColor}`);


        return <UIControlWrapper className={wrapperClassName.toString()} size={size} fullWidth={fullWidth} alignItems={align}>

            <FormControlLabel
                value={value}
                control={<Checkbox checked={checked} disabled={disabled} color={color} size="small" />}
                label={children}
                onChange={onChange}
                onClick={onClick}
                labelPlacement={this.getLabelPlacement()}
            />

        </UIControlWrapper>;

    }

}
