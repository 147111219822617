import {fromEvent}                          from "rxjs";
export {SchemaField}                        from './SchemaField';
export {StringField}                        from './StringField';
export {BooleanField}                       from './BooleanField';
export {CountryField}                       from './CountryField';
export {ErrorField}                         from './ErrorField';
export {MetaField}                          from './MetaField';
export {getMetaT}                           from './getMeta';
export {translate}                          from './translate';
export {ajvCustomFormats, formatsValidator} from './validation';
import                                          "./styles/index.less";

export const onInputSF$ =  fromEvent(document, 'input');
