const codes = {
    'RESULT':  'result',
    'EXTRA':   'extra',
    'GOOD':    'good',
    'WRONG':   'wrong',
    'W400':    '400',
    'W401':    '401',
    'W503':    '503'
};
export {codes};
