import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const DiagnosticsIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0003 8.57031C12.2103 8.57031 11.5703 9.21031 11.5703 10.0003C11.5703 10.7903 12.2103 11.4303 13.0003 11.4303C13.7903 11.4303 14.4303 10.7903 14.4303 10.0003C14.4303 9.21031 13.7903 8.57031 13.0003 8.57031Z"
            fill="#B0B0B0"
        />
        <path
            d="M13.2098 3.00002C9.36982 2.89002 6.20982 5.87002 6.01982 9.64002L4.09982 12.2C3.84982 12.53 4.08982 13 4.49982 13H5.99982V16C5.99982 17.1 6.89982 18 7.99982 18H8.99982V20C8.99982 20.55 9.44982 21 9.99982 21H14.9998C15.5498 21 15.9998 20.55 15.9998 20V16.32C18.4398 15.16 20.0998 12.64 19.9998 9.74002C19.8598 6.12002 16.8198 3.11002 13.2098 3.00002ZM15.9998 10C15.9998 10.13 15.9898 10.26 15.9798 10.39L16.8098 11.05C16.8898 11.11 16.9098 11.21 16.8598 11.3L16.0598 12.69C16.0098 12.78 15.8998 12.81 15.8198 12.78L14.8298 12.38C14.6198 12.54 14.3998 12.67 14.1598 12.77L13.9998 13.83C13.9898 13.93 13.8998 14 13.7998 14H12.1998C12.0998 14 12.0198 13.93 11.9998 13.83L11.8498 12.77C11.5998 12.67 11.3798 12.54 11.1698 12.38L10.1798 12.78C10.0898 12.81 9.97983 12.78 9.92982 12.69L9.12982 11.3C9.07982 11.22 9.09982 11.11 9.17982 11.05L10.0198 10.39C10.0098 10.26 9.99982 10.13 9.99982 10C9.99982 9.87002 10.0198 9.73002 10.0398 9.61002L9.18982 8.95002C9.10982 8.89002 9.08982 8.79002 9.13982 8.69002L9.93982 7.31002C9.98982 7.22002 10.0898 7.19002 10.1798 7.22002L11.1798 7.62002C11.3798 7.47002 11.6098 7.33002 11.8498 7.23002L11.9998 6.17002C12.0198 6.07002 12.0998 6.00002 12.1998 6.00002H13.7998C13.8998 6.00002 13.9798 6.07002 13.9998 6.17002L14.1498 7.23002C14.3898 7.33002 14.6098 7.46002 14.8198 7.62002L15.8198 7.22002C15.9098 7.19002 16.0198 7.22002 16.0598 7.31002L16.8598 8.69002C16.9098 8.78002 16.8898 8.89002 16.8098 8.95002L15.9598 9.61002C15.9898 9.73002 15.9998 9.86002 15.9998 10Z"
            fill="#B0B0B0"
        />
    </svg>
</SvgIcon>;


export default DiagnosticsIcon;
