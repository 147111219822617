import React, { useEffect, useState }   from "react";
import { LGap, VBox }                   from "@sirius/ui-lib/src/blocks/Layout";
import { CodeEditor }                   from "./CodeEditor";
import { OnlineVerdicts }               from "./OnlineVerdicts";
import { IO }                           from "./IO";
import { Limits }                       from "./Limits";
import {
    block,
    HasAllowResend,
    TaskOnlineStateType
}                                       from "./block";
import type {
    ProgrammingAnswerType,
    ReviewsResponseItemType,
}                                       from "./block";
import {SmtCache_v4_POST_solution}      from "Smt/subjects/ContestOnline";
import                                       "./index.less";

const MINLINES  = 3;
const deepClone = (val: any): any => JSON.parse( JSON.stringify(val));

const checkLangBlockByLang = (lang: SmtTask.Language) => ({language}: SmtTask.LanguageBlock): boolean =>
    lang === language
;

const ResponseSort = (a: ReviewsResponseItemType,  b: ReviewsResponseItemType): number => (a.tmSec - b.tmSec);

type getVerdictsListType = {
     solutions: SmtCache.ReviewsResponseSolution[];
     pending:   SmtCache.ReviewsResponsePending[];
};

const getVerdictsList = ({solutions, pending}: getVerdictsListType): ReviewsResponseItemType[] => (
    [
        ...solutions.map( s => ({...s, pending: false}) ),
        ...pending  .map( s => ({...s, pending: true }) )
    ].sort(
        ResponseSort
    )
);

const getLastVerdict = (verdicts: ReviewsResponseItemType[]): ReviewsResponseItemType | null =>(
    verdicts.length > 0
        ? verdicts[ verdicts.length - 1 ]
        : null
);

const getDisabled = ({readOnly, hasPending, hasSolutionOk, hasSolutionLastNotOk, hasNextTryLock, allowResend}: Partial<TaskOnlineStateType & HasAllowResend & {readOnly: boolean}>): boolean =>
    readOnly             ||
    (allowResend
      ? false
      : hasPending
    )                    ||
    hasSolutionOk        ||
    (hasSolutionLastNotOk && hasNextTryLock)
;

const padCode = (str: string, minln: number): string => {
    let arr   = str.split("\n");
    let padln = minln - arr.length;
    while ( padln > 0 ) {
        arr.push("");
        --padln;
    }
    return arr.join("\n");
};

const padTemplates = (templates: SmtTask.LanguageBlockList): SmtTask.LanguageBlockList =>
    templates.map(
        ({language, code}) => ({
            language,
            code: padCode(code, MINLINES)
        })
    )
;

const getTemplates = ( srcTemplates: SmtTask.LanguageBlockList, languages: SmtTask.Language[] ): SmtTask.LanguageBlockList => {
    const hasSrc = srcTemplates && srcTemplates.length > 0;
    const templates: SmtTask.LanguageBlockList =
        hasSrc
            ? deepClone(srcTemplates)
            : languages.map(
                language => ({code:"", language})
            )
    ;
    return padTemplates( templates );
}

const ProgrammingAnswer: React.FC<ProgrammingAnswerType> = (props) => {

    const {answersData, readOnly, onAnswer, onlineState, taskToken, taskNum, unlockNextTry, isHidden, allowResend = false, userAnswer} = props;
    const {solutions, pending, hasPending, hasSolutionOk, hasSolutionLastNotOk, hasNextTryLock, editSolution, hasSolutions} = onlineState;
    const {limits = {}, examples = [], sourceFooter = [], sourceHeader = [], languages, templates: srcTemplates} = answersData;

    const [loading, setLoading]   = useState(false);
    const [verdicts, setVerdicts] = useState<ReviewsResponseItemType[]>(
        getVerdictsList({solutions, pending})
    );
    useEffect(
        () => {
            const verdictsList = getVerdictsList({solutions, pending});
            setVerdicts( verdictsList );
        },
        [JSON.stringify(solutions), JSON.stringify(pending)]
    );

    const [last, setLast] = useState<ReviewsResponseItemType | null>(
        getLastVerdict(verdicts)
    );
    useEffect(
        () => {
            setLast( getLastVerdict(verdicts) );
        },
        [verdicts]
    );

    const [showEditor, setShowEditor] = useState<boolean>( !hasPending && !hasNextTryLock && !hasSolutionOk && editSolution);
    useEffect(
        () => {
            const hasShowEditor =
                    !hasPending &&
                    (
                        (!hasNextTryLock && !hasSolutionOk) || !hasSolutions
                    )
            ;
            setShowEditor( hasShowEditor );
        },
        [hasPending, hasSolutions, hasNextTryLock, hasSolutionOk]
    );

    const [showLast, setShowLast] = useState<boolean>( !showEditor );
    useEffect(
        () => {
            setShowLast( !showEditor );
        },
        [showEditor]
    );

    const [showVerdicts, setShowVerdicts] = useState<ReviewsResponseItemType[]>( showLast ? verdicts.slice(0, -1) : verdicts );
    useEffect(
        () => {
            !hasPending && setShowVerdicts( showLast ? verdicts.slice(0, -1) : verdicts );
        },
        [showLast, verdicts, hasPending]
    );

    const vNum = showVerdicts.length;
    const [allVerdictsCollapsed, setAllVerdictsCollapsed] = useState<boolean>(true);
    const [verdictsShowDetails, setVerdictsShowDetails]   = useState<string[]>(showLast && last ? [last.id] : []);
    useEffect(
        ()=>{
            setVerdictsShowDetails(showLast && last ? [last.id] : [])
        },[showLast, last?.id]
    );
    const templates =  getTemplates( srcTemplates, languages );

    const [hasInput,  setHasInput ]      = useState<boolean>( !!userAnswer );
    const [userAnswers, setUserAnswers]  = useState<SmtTask.LanguageBlockList>( userAnswer ? [userAnswer] : templates );

    const [{code, language}] = userAnswers;

    const sFooter = (sourceFooter || []).find( checkLangBlockByLang(language) )?.code || '';
    const sHeader = (sourceHeader || []).find( checkLangBlockByLang(language) )?.code || '';

    const hasLimits = Object.keys(limits).length > 0;

    const patchTemplates = (answer: SmtTask.LanguageBlock): void => {
        setUserAnswers([answer, ...userAnswers.filter(t => t.language !== answer.language)]);
    };

    const onCodeChange = (code: string) => {
        patchTemplates({code, language});
    }

    const [disabled, setDisabled ] = useState<boolean>(
        getDisabled({readOnly, hasPending, hasSolutionOk, hasSolutionLastNotOk, hasNextTryLock, allowResend})
    );

    useEffect(
        ()=>{
            setDisabled(
                getDisabled(
                    {
                        readOnly,
                        hasPending,
                        hasSolutionOk,
                        hasSolutionLastNotOk,
                        hasNextTryLock,
                        allowResend
                    }
                )
            )
        },
        [readOnly, hasPending, hasSolutionOk, hasSolutionLastNotOk, hasNextTryLock]
    );

    useEffect(
        () => {
            const hasLastSolution = !disabled && last && ('solution' in last);
            const hasEmptyCode    = !(!!userAnswer);

            if (hasLastSolution && (!allowResend || hasEmptyCode)) {

                patchTemplates(
                    (last as {solution: SmtCacheV4.LanguageBlock}).solution
                )
            }
        },
        [disabled, last]
    );

    const onLanguageChange = (language: SmtTask.Language) => {
        patchTemplates({
            code,
            language
        });
    };

    const onRestoreCode = () => {
        patchTemplates({
            code: templates.find( t => t.language === language )?.code || '',
            language
        });
    };

    useEffect(
        ()=>{
            const tpl = templates.find( t => t.language === language );
            const [c, tc] = [code.trim(), tpl?.code.trim() || ''];
            const hasInput = tc !== c && c !== '';
            setHasInput(hasInput);
        },
        [code, language]
    );

    useEffect(
        ()=>{
            onAnswer(hasInput ? {code, language} : null);
        },
        [code, language, hasInput]
    );

    useEffect(
        ()=>{
            !allVerdictsCollapsed && setVerdictsShowDetails(showLast && last ? [last.id] : [] )
        }
        ,[allVerdictsCollapsed, showLast, last?.id]
    );

    const toggleVerdictsShowDetails = (id: string, val: boolean) => {
        const res = val === true
                        ? [...verdictsShowDetails, id]
                        : verdictsShowDetails.filter( v => v !== id )
        ;
        setVerdictsShowDetails(
            showLast && last && !res.includes(last.id)
                ? [...res, last.id]
                : res
        );
    }

    useEffect(() => {

        if (hasPending && allowResend && !code.trim() && pending.length > 0) {

            setLoading(true);
            const taskNumber  = typeof taskNum === 'string' ?  parseInt(taskNum) : taskNum;
            const pendingItem = pending.find((item) => item.taskId === taskNumber + 1);

            SmtCache_v4_POST_solution({id : pendingItem.id, taskId: pendingItem.taskId}, taskToken)
                .then(
                    (result) => {
                        const {status, data:{input}} = result;
                        if (status === 200) {
                            if ('solution' in input) {
                                const {solution} = input;
                                if ( solution ){
                                    patchTemplates(solution as SmtCache.LanguageBlock);
                                }
                            } else if ('hidden' in input) {
                                patchTemplates({
                                    language: input.lang,
                                    code: ''
                                });
                            }
                        }
                    }
                )
                .finally(
                    ()=>{
                        setLoading(false);
                    }
                )
            ;
        }

    }, []);

    return  <VBox className={block + ' theme-light '}>
                { examples.length > 0 && <IO examples={examples}/> }
                { hasLimits && <LGap /> }
                { hasLimits && <Limits limits={limits}/> }
                { verdicts.length > 0 && <LGap /> }
                {
                    verdicts.length > 0 &&
                    <OnlineVerdicts
                        taskId      = {taskNum - -1}
                        isHidden    = {isHidden}
                        allowResend = {allowResend}
                        {...{
                            showLast,
                            showVerdicts,
                            vNum,
                            last,
                            unlockNextTry,
                            allVerdictsCollapsed,
                            setAllVerdictsCollapsed,
                            verdictsShowDetails,
                            toggleVerdictsShowDetails,
                            verdicts,
                            taskToken,
                            sourceFooter,
                            sourceHeader,
                        }}
                    />
                }
                {showEditor && <LGap/>}
                {
                    (showEditor || (hasPending && allowResend)) && !loading &&
                    <CodeEditor
                        {...{
                            onClick:      (hasNextTryLock && hasSolutionLastNotOk) ? unlockNextTry : void(0),
                            status:       (hasNextTryLock && hasSolutionLastNotOk) ? 'notok' : hasSolutionOk ? 'ok' : void(0),
                            sourceFooter: sFooter,
                            sourceHeader: sHeader,
                            num:          (hasPending && allowResend ? solutions : verdicts).length + 1,
                            disabled,
                            code,
                            language,
                            languages,
                            hasInput,
                            onCodeChange,
                            onLanguageChange,
                            onRestoreCode,
                            className: 'smt-code-editor'
                        }}
                    />
                }
            </VBox>
    ;
}

const SmtProgrammingAnswer = React.forwardRef<any, ProgrammingAnswerType>(
    (props, ref) => <ProgrammingAnswer {...props}/>
);

export {SmtProgrammingAnswer};
