import React, {FC}        from "react";
import {VBox}             from "@sirius/ui-lib/src/blocks/Layout";
import {Checkbox}         from "@sirius/ui-lib/src/blocks/Controls";
import {SchemaFieldProps} from "@sirius/ui-lib/src/blocks/SchemaField/@types";


export const BooleanField: FC<SchemaFieldProps> = (props) => {
    const {
        block,
        onChange,
        schema,
        uischema,
        data,
        className= '',
        disabled = false,
        required,
        unfilled,
    } = props;
    const {MetaField}         = uischema;
    const {default: _default} = schema;
    const checked = data !== void(0)
                    ? data === true
                    : _default !== void(0)
                        ? _default === true
                        : void(0)
    ;

    const fieldClassName = `${className} ` +
                            block.bod('required', required) +
                            block.el('boolean') +
                            block.el('boolean').mod('has', `${unfilled ? 'no-' : ''}value`)
    ;


    const onChangeHandler = (value: boolean) => {
        const next = required && value === false ? void(0) : value;
        onChange(next);
    };

    return  <VBox className={fieldClassName}>
                <MetaField {...props} >
                    <Checkbox
                        onChange  = {onChangeHandler}
                        checked   = {checked}
                        disabled  = {disabled}
                        className = {
                            block.el('checkbox') +
                            block.el('checkbox').bod('unset', checked === void(0) )
                        }
                    />
                </MetaField>
            </VBox>
    ;
}
