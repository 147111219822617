import bnc                              from "bnc";
import ProgrammingSolution              from "./ProgrammingSolution";
import type  {
    ProgrammingSolutionProps,
    TInstance
}                                       from './@types';

const block = new bnc("programming-answer");

const instance = ('CONFIG' in window) && CONFIG?.Instance ? CONFIG.Instance.toLowerCase() as TInstance : 'unknown';

export {
    ProgrammingSolution,
    block,
    instance
};

export type {
    ProgrammingSolutionProps,
    TInstance
};
