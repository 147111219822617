import React from "react";
import {removeContestIdAndSessionId} from "Smt/helpers";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

export default class UserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user_name: props.user_name
        }
    }

    UNSAFE_componentWillReceiveProps({user_name}) {
        this.setState({
            user_name: user_name
        });
    }

    static logout(keep_location_search) {

        keep_location_search = keep_location_search || false;

        removeContestIdAndSessionId();


        if (location.hash.substr(1, 9) === '/contest/') {

            let logoutUrl = CONFIG.Auth.logoutUrl.replaceAll("{href}", encodeURIComponent(document.location.href));
            location.href = logoutUrl;
            return;

        }

        if (location.pathname !== '/') {
            location.pathname = '/';
        } else if (location.search !== '' && !keep_location_search) {
            location.href = location.origin + location.pathname;
        } else {
            location.reload();
        }
    }

    render() {

        let styles = {};
        if (this.props.text_color) {
            styles['color'] = this.props.text_color;
        }

        return (
            <div className="user_info" style={styles}>
                {this.state.user_name}
                <div className="user_info__logout" onClick={UserInfo.logout}>
                    <SmtLocaleString k="common.user_info.logout" id="value" />
                </div>
            </div>
        );
    }
}
