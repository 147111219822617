import React, {FunctionComponent} from "react";
import SystemNotificationsDialogueControl from "Lab/SystemNotifications/SystemNotificationsDialogueControl";
import {RadioControlLabelPlacement} from "Lab/components/UI2/Controls/RadioControl";
import CheckboxElement from "Lab/components/UI2/Elements/CheckboxElement";

const SystemNotificationsDialogueAgreeControl: FunctionComponent<SystemNotificationsDialogueControl> = (props) => {

    const {
        control,
        disabled,
        value,
        onChange,
    } = props;

    return <CheckboxElement
        value={control.value}
        name={control.name}
        disabled={disabled}
        checked={value === control.value}
        labelPlacement={RadioControlLabelPlacement.left}
        onChange={(e, checked) => onChange(control.name, checked ? control.value : null)}
        color="primary"
    >
        {control.text}
    </CheckboxElement>;

};

export default SystemNotificationsDialogueAgreeControl;
