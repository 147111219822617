import React from 'react';
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import {UIControl, UIControlProps} from "Lab/components/UI2/UIControl";
import {TextField} from "@material-ui/core";
import ButtonControl from "Lab/components/UI2/Controls/ButtonControl";
import IconControl from "Lab/components/UI2/Controls/IconControl";
import BemClassName from "Cheops/BemClassName";
import ConfirmEditButtonsElement from "Lab/components/UI2/Elements/ConfirmEditButtonsElement";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";
import IconButtonControl from "Lab/components/UI2/Controls/IconButtonControl";
import TextControl from "Lab/components/UI2/Controls/TextControl";
import {Sizes} from "Lab/constants";
import './TextInputControl.less';


export enum TextInputControlVariant {
    Standard = 'standard',
    Filled = 'filled',
    Outlined = 'outlined',
}

interface Props extends UIControlProps {
    type?: string;
    size: ControlSizes;
    placeholder?: string;
    autoFocus?: boolean;
    clearable?: boolean;
    className?: string;
    value?: string | number;
    disabled?: boolean;
    hasError?: boolean;
    multiline?: boolean;
    rows?: number;
    inputProps?: FilledInputProps['inputProps'];
    mobileHeaderTitle?: string;
    fullWidth?: boolean;
    bordered?: boolean;
    borderless?: boolean;
    restrictHoverEffects?: boolean;
    restrictFocusEffects?: boolean;
    darkMode?: boolean;
    numbersOnly?: boolean;
    isSearchField?: boolean;
    variant?: TextInputControlVariant;

    onFocus?(): void;

    onBlur?(): void;

    onChange?(value: string): void;

    onCancel?(): void;

    onKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void;

    onEnterKeyPress?(): void;

    onClickOutside?(): void;

    onCloseMobile?(): void;

    onBackClick?(): void;
}

type State = {
    initValue: string | number;
    isFocused: boolean;
    isEdited: boolean;
};

export default class TextInputControl extends UIControl<Props, State> {

    state: Readonly<State> = {
        initValue: this.props.value,
        isFocused: false,
        isEdited: false,
    };


    static defaultProps: Props & typeof UIControl.defaultProps = {
        ...UIControl.defaultProps,
        type: "text",
        className: "",
        size: ControlSizes.LG,
        value: "",
        placeholder: "",
        autoFocus: false,
        clearable: false,
        disabled: false,
        hasErrors: false,
        mobileHeaderTitle: "",
        fullWidth: false,
        bordered: false,
        borderless: false,
        restrictFocusEffects: false,
        restrictHoverEffects: false,
        darkMode: false,
        numbersOnly: false,
        variant: TextInputControlVariant.Outlined,
        onKeyUp: () => {
        },
        onChange: () => {
        },
        onEnterKeyPress: () => {
        },
        onBlur: () => {
        },
        onClickOutside: () => {
        },
        onFocus: () => {
        },
        onCloseMobile: () => {
        },
        onBackClick: () => {
        },
    };

    componentDidMount(): void {

        const {numbersOnly} = this.props;

        if (numbersOnly) {

            this.inputRef.current.addEventListener("wheel", this.onInputWheel, {passive: false});

        }

    }

    componentWillUnmount(): void {

        const {numbersOnly} = this.props;

        if (numbersOnly) {

            this.inputRef.current.removeEventListener("wheel", this.onInputWheel);

        }

    }

    onInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {

        const {onKeyUp} = this.props;

        onKeyUp(e);

        if (e.key === "Enter") {

            this.props.onEnterKeyPress();

        }

    };

    onFocus = (): void => {

        if (this.props.onFocus) {

            this.props.onFocus();

        }

        this.setState({isFocused: true});

    };

    confirmEdit = (): void => {

        const {onCloseMobile, onBlur} = this.props;
        this.setState({isFocused: false});

        if (onBlur) {

            onBlur();

        }
        onCloseMobile();

    };

    cancelEdit = (): void => {

        const {onChange} = this.props;
        onChange(this.state.initValue.toString());
        this.setState({isEdited: false});

    };

    changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {

        if (this.props.numbersOnly) {

            if (/^[0-9\n]+$/.exec(e.target.value)) {

                this.props.onChange(e.target.value);

            }

        } else {

            this.props.onChange(e.target.value);

        }

        this.setState({isEdited: true});

    };

    onMobileHeaderBackClick = (): void => {

        this.cancelEdit();
        this.setState({isFocused: false});
        this.props.onBackClick();

    };


    onCancel = (): void => {

        this.cancelEdit();

        this.setState({isFocused: false});

        this.props.onCloseMobile();

    };

    onBlur = (): void => {

        if (window.matchMedia(`(max-width: ${Sizes.mobile}px)`).matches) {

            return;

        }

        this.props.onBlur();

    };

    renderMobileHeader = (): React.ReactNode => {

        return (

            <div className="text_input_control__mobile_header">
                <UIElementWrapper>
                    <IconButtonControl
                        iconName="arrow_back"
                        onClick={this.onMobileHeaderBackClick}
                        restrictHoverEffects
                    />
                    <TextControl primary>{this.props.mobileHeaderTitle}</TextControl>
                    <IconButtonControl
                        iconName="help_outline"
                        size={ControlSizes.MD}
                        restrictHoverEffects
                    />
                </UIElementWrapper>
            </div>

        );

    };

    setEmptyValue = (): void => {

        this.props.onChange("");

    };

    onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {

        if (this.props.numbersOnly) {

            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {

                e.preventDefault();

            }

        }

    };

    onInputWheel = (e: Event): void => e.preventDefault();

    inputRef = React.createRef<HTMLInputElement>();

    render(): React.ReactNode {

        const {
            type,
            placeholder,
            value,
            fullWidth,
            onCancel,
            className,
            size,
            clearable,
            autoFocus,
            onClickOutside,
            disabled,
            hasError,
            multiline,
            rows,
            inputProps,
            bordered,
            borderless,
            restrictHoverEffects,
            restrictFocusEffects,
            darkMode,
            variant,
            isSearchField,
        } = this.props;

        const {isFocused} = this.state;

        const inputWrapperClassName = new BemClassName('text_input_control');

        inputWrapperClassName.appendAdditionalClasses(className);
        inputWrapperClassName.appendStatusIf(isFocused, "focused");
        inputWrapperClassName.appendStatusIf(disabled, "disabled");
        inputWrapperClassName.appendStatusIf(this.state.isEdited, "edited");
        inputWrapperClassName.appendStatusIf(hasError, "hasError");
        inputWrapperClassName.appendStatusIf(fullWidth, "fullWidth");
        inputWrapperClassName.appendStatusIf(bordered, "bordered");
        inputWrapperClassName.appendStatusIf(borderless, "borderless");
        inputWrapperClassName.appendStatusIf(darkMode, "dark_mode");
        inputWrapperClassName.appendAdditionalClassesIf(restrictHoverEffects, "restrictHoverEffects");
        inputWrapperClassName.appendAdditionalClassesIf(restrictFocusEffects, "restrictFocusEffects");

        inputWrapperClassName.appendStatusIf(fullWidth, "full-width");

        const inputClassName = new BemClassName('text_input_control__input');
        inputClassName.appendStatusIf(multiline, 'multiline');
        inputClassName.appendStatusIf(fullWidth, 'full-width');

        const confirmEditButtonsElementClassName = new BemClassName('text_input_control__confirm_edit_btns');


        let closeIconName = "close";
        let onCancelClick = null;

        if (onCancel) {

            onCancelClick = onCancel;

        }

        if (value && clearable) {

            closeIconName = "cancel";
            onCancelClick = () => this.setEmptyValue();

        }


        let _size = size === ControlSizes.LG
            ? ControlSizes.MD : size;

        return <UIControlWrapper className={inputWrapperClassName.toString()} clickOutside={onClickOutside} size={size}>

            {isFocused
            && this.renderMobileHeader()}

            <div className="text_input_control__input_container">

                <TextField
                    type={type}
                    onKeyUp={this.onInputKeyUp}
                    className={inputClassName.toString()}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => this.changeHandler(e)}
                    variant={variant}
                    size={_size}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    autoFocus={autoFocus}
                    inputRef={this.inputRef}
                    disabled={disabled}
                    multiline={multiline}
                    rows={rows}
                    inputProps={inputProps}
                    onKeyDown={this.onInputKeyDown}
                />

                {isSearchField && !value
                && <IconButtonControl
                    size={ControlSizes.LG}
                    iconName="search"
                    onClick={() => this.inputRef.current.focus()}
                    className="text_input_control__search"
                    restrictFocusEffects
                    restrictHoverEffects
                />}

                {onCancelClick
                && <ButtonControl
                    size={ControlSizes.SM}
                    onClick={onCancelClick}
                    className="text_input_control__cancel"
                    restrictFocusEffects
                    restrictHoverEffects
                >
                    <IconControl iconName={closeIconName} size={ControlSizes.SM} />
                </ButtonControl>}
            </div>

            {isFocused
            && <ConfirmEditButtonsElement
                onCancel={this.onCancel}
                onConfirm={this.confirmEdit}
                confirmDisabled={value === this.state.initValue}
                className={confirmEditButtonsElementClassName.toString()}
            />}

        </UIControlWrapper>;

    }

}
