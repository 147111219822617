export const MAP_IS_FOCUSED = 'MAP_IS_FOCUSED';
export const SELECTED_MODULE_ID = 'SELECTED_MODULE_ID';


export const toggleMapFocused = (status) => ({
    type: MAP_IS_FOCUSED,
    status,
});


export const focusMapStatuses = {
    FOCUSED: true,
    NOT_FOCUSED: false,
};


export const setSelectedModuleId = (status) => ({
    type: SELECTED_MODULE_ID,
    status,
});
