import {applyMiddleware, compose, createStore} from "redux";
import cheopsApp from "Cheops/reducers";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";

const store = createStore(cheopsApp, compose(
    applyMiddleware(thunk, promiseMiddleware()),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f,
));

export default store;
