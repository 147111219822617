import React from 'react';
import {UIAtomProps} from "./UIAtom";


export type UIControlProps = UIAtomProps;

export abstract class UIControl<T, S = {}> extends React.Component<T & UIControlProps, S> {


    static defaultProps = {
        disabled: true,
        hasErrors: false,
    };

}
