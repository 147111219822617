export default class Files {

    static download(content: BlobPart, fileName: string, type?: string): void {
        if (content){
            const url = URL.createObjectURL(new Blob([content], {type}));
            const element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download', fileName);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        } else {
            console.info('Files.download skipped')
        }
    }
}
