import React, {FC}                               from "react";
import bnc                                       from "bnc";
import {BoxAlignment, HBox, Screen, VBox}        from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                                  from "@sirius/ui-lib/src/blocks/Controls";
import {ErrLocaleDocumentTitle, ErrLocaleString} from "./ErrLocaleBlock";
import "./style.less";

const block = new bnc("error-page-4xx");

type ErrorPageProps = {
    statusCode: number;
}

export const ErrorPage4xx: FC<ErrorPageProps> = ({statusCode= 429}) => (
    <Screen className={block}>
        <ErrLocaleDocumentTitle k={`errors.${statusCode}.document`} />
        <VBox className={block.el('content')}>
            <HBox className={block.el('logo') + block.el('logo').mod(`${statusCode}`)}/>
            <VBox className={block.el('description')}  align={BoxAlignment.center}>
                <h3 className={block.el('description').mod('title')}>
                    <ErrLocaleString k={`errors.${statusCode}.title`}/>
                </h3>
                <p className={block.el('description').mod('message')}>
                    <ErrLocaleString k={`errors.${statusCode}.message`}
                                     values={{br: <br />}}
                    />
                </p>
                <Button className = {block.el('button')}
                        mode      = {'primary'}
                        form      = {'soft'}
                        icon      = {'replay'}
                        onAction  = {() => window.location.reload()}
                >
                    <ErrLocaleString k={`errors.${statusCode}.button`}/>
                </Button>
            </VBox>
        </VBox>
    </Screen>
);
