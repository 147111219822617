export default class BemClassName {

    private readonly className: string;
    private statuses: string[] = [];
    private additional_classes: string[] = [];


    constructor(className: string, statuses: string | string[] | null = [], additionalClasses: string | string[] | null = []) {

        this.className = className;


        if (typeof statuses === "string") {

            this.statuses = statuses.split(" ");

        } else if (statuses !== null) {

            this.statuses = statuses;

        }


        if (typeof additionalClasses === "string") {

            this.additional_classes = additionalClasses.split(" ");

        } else if (additionalClasses !== null) {

            this.additional_classes = additionalClasses;

        }

    }

    appendStatus(status: string): void {

        this.statuses.push(status);

    }


    appendStatusIf(expression: boolean, status: string): void {

        if (expression === true) {

            this.appendStatus(status);

        }

    }


    removeStatus(status: string): void {

        let index = this.statuses.indexOf(status);

        if (index >= 0) {

            this.statuses.splice(index, 1);

        }

    }

    appendAdditionalClasses(className: string): void {

        this.additional_classes.push(className);

    }

    appendAdditionalClassesIf(expression: boolean, className: string): void {

        if (expression === true) {

            this.additional_classes.push(className);

        }

    }


    removeAdditionalClasses(className: string): void {

        let index = this.additional_classes.indexOf(className);

        if (index >= 0) {

            this.additional_classes.splice(index, 1);

        }

    }

    removeAdditionalClassesIf(expression: boolean, className: string): void {

        if (expression === true) {

            let index = this.additional_classes.indexOf(className);

            if (index >= 0) {

                this.additional_classes.splice(index, 1);

            }

        }

    }

    toString(): string {

        let result = this.className;

        for (let status of this.statuses) {

            result += ` ${this.className}--${status}`;

        }

        if (this.additional_classes.length > 0) {

            result += ` ${this.additional_classes.join(" ")}`;

        }

        return result;

    }

}
