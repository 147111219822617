import React, {FC}                              from "react";
import bnc                                      from "bnc";
import {HBox, VBox}                             from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                                 from "@sirius/ui-lib/src/blocks/Controls";
import {SmtLocaleString}                        from "Smt/SmtLocaleBlock";
import {HasRegistration, HasBack, HasEmail}     from "./@types";
import                                               "./styles/registration-panel.less";


const block = new bnc("smt-auth-registration-panel");

export const RegistrationPanel:FC<HasRegistration & HasEmail & HasBack> = ({
    registration,
    email,
    onBack
}) => {

    const success = 'success' in registration ? registration.success : void(0);

    return  <VBox className={block}>
            {
                success &&
                <VBox className={block.el('wrapper')}>
                    <img src={require('./prof-registration.png')}/>
                    <HBox className={"text-xxl " + block.el('title')}>
                        {success.title}
                    </HBox>
                    <HBox className={"text-l " + block.el('success-message')}>
                        <SmtLocaleString k={`common.registration.success_message_${success.sendCode}`} values={{email}}/>
                    </HBox>
                    <Button
                        className={block.el('back')}
                        mode     = {"secondary"}
                        icon     = {"arrow_back"}
                        onAction = {onBack}
                    >
                        <SmtLocaleString k={`common.registration.back`}/>
                    </Button>
                </VBox>
            }
        </VBox>
    ;
};
