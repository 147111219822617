import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const MoveIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17ZM14.4659 16.2464L17.2559 13.7544C17.4459 13.5644 17.4459 13.2444 17.2559 13.0544L14.4659 10.546C14.1559 10.226 13.6159 10.446 13.6159 10.896V12.4044H7.60594C7.05594 12.4044 6.60594 12.8544 6.60594 13.4044C6.60594 13.9544 7.05594 14.4044 7.60594 14.4044H13.6159V15.8964C13.6159 16.3464 14.1559 16.5664 14.4659 16.2464Z"
              fill="#B0B0B0"
        />
    </svg>
</SvgIcon>;

export default MoveIcon;
