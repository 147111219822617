import React from "react";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import bnc from "bnc";
import { ElementVerdict } from "Cheops/Model/Element";
import { TaskAnswersData } from "Lab/Model/Task";
import { MultiUserAnswer, SingleUserAnswer } from "Cheops/Model/UserAnswer";
import { CheopsLocaleSelect } from "Cheops/CheopsLocaleBlock";
import { Option } from "@sirius/ui-lib/src/blocks/DropdownSelect/Option";
import { DropdownItem } from "@sirius/ui-lib/src/blocks/DropdownSelect/@types";
import './SelectDropdownAnswer.less';

interface Props {

    answersData: TaskAnswersData;
    isResult?: boolean;
    resultVerdict?: ElementVerdict;
    readOnly?: boolean;
    userAnswer?: SingleUserAnswer | MultiUserAnswer;
    inline?: boolean;
    inputMaxWidth?: number; // input max width for inline layout
    isSmt?: boolean;
    multi?: boolean;

    onAnswer?(items: DropdownItem[]): void;

}

const block = new bnc('select-dropdown-answer');

const SelectDropdownAnswer: React.FC<Props> = (
    {
        answersData, userAnswer, onAnswer,
        inline, inputMaxWidth, isSmt,
        multi, resultVerdict, readOnly,
    },
) => {

    const {answers, answerType, inputsInRow} = answersData;

    const getImgUrl = (hash: string): string => (
        isSmt
            ? `${CONFIG.Imgs.smt}/content/_image/${hash}`
            : `${CONFIG.Imgs.noo}/content/_image/${hash}`
    );


    const renderOptionByAnswerType = (answer: LabSmt.AnswerItem): React.ReactNode => {

        const optionValue = {
            id: answer.id,
            caption: answer.caption,
            formula: answer.formula,
            image: answer.image,
            value: answer.value,
        };

        const commonOptionProps = {
            key: answer.id,
            value: optionValue,
            selected: !multi ? userAnswer === answer.id : Array.isArray(userAnswer) && userAnswer.includes(answer.id),
        };

        // TODO It's for legacy cases, remove when backend give answerType for all tasks
        if (!answerType) {
            return answer.image
                ? <Option
                    {...commonOptionProps}
                >
                    <img className={block.el('option').mod('image')} src={getImgUrl(answer.image)} />
                </Option>
                : answer.formula
                    ? <Option
                        {...commonOptionProps}
                        className={block.el('formula')}
                    >
                        <Marked>{`$${answer.formula}$`}</Marked>
                    </Option>
                    : <Option
                        {...commonOptionProps}
                        label={answer.value}
                    />;
        }

        switch (answerType) {

            case 'simple': {
                return <Option
                    {...commonOptionProps}
                    label={answer.value}
                />;
            }
            case "formula": {
                return <Option
                    {...commonOptionProps}
                    className={block.el('formula')}
                >
                    <Marked>{`$${answer.formula}$`}</Marked>
                </Option>;
            }
            case "image": {
                return <Option
                    {...commonOptionProps}
                >
                    <img className={block.el('option').mod('image')} src={getImgUrl(answer.image)} />
                </Option>;
            }
            default: {
                return <Option
                    {...commonOptionProps}
                    label={answer.value}
                />;
            }

        }

    };

    const isExtended = answers.some(({image}) => !!image);
    const isFormulas = answers.every(({formula}) => !!formula);
    const isSimple   = answerType === 'simple';

    const rowSize = inputsInRow && (isExtended || isFormulas)
        ? inputsInRow
        : isExtended
            ? 3
            : void(0)
    ;

    return (<div
        className={
            block +
            block.bod('inline',    inline) +
            block.bod('read-only', readOnly) +
            block.bod('formulas',  isFormulas) +
            block.bod('simple', isSimple )
        }
    >
        <CheopsLocaleSelect
            k={`cheops.select_option_answer.${!readOnly ? 'default_select' : 'answer_select'}`}
            classNamePrefix="input_select"
            onChange={onAnswer}
            inputMaxWidth={inputMaxWidth}
            inline={inline}
            multiple={multi}
            rowSize={rowSize}
            readOnly={readOnly}
            className={
                block.el('dropdown-block')
                + block.el('dropdown-block').mod('verdict', resultVerdict)
            }
        >
            {answers.map((answer) => renderOptionByAnswerType(answer))}
        </CheopsLocaleSelect>
    </div>);

};


export default SelectDropdownAnswer;

SelectDropdownAnswer.defaultProps = {
    inputMaxWidth: 10,
};
