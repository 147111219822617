import { useEffect, useState } from "react";

export interface IVideoContainers {
    [k: string]: Common.Video.VideoResolveContainer;
}
interface IParseTagArgs {
    source:             string;
    inline?:            boolean;
    youtube?:           boolean;
    videoContainers?:   IVideoContainers;
    videoWrapperClassName?:    string;
}
type TParseTag          = (args: IParseTagArgs) => string;
type TParseJustifyTag   = (source: string) => string;

const parseInlineTag: TParseTag = ({source, inline= false}) => {
    if (inline) {
        return source.replace(
            /\$\$([^\$\n]+)\$\$/gi,
            '$$$1$$'
        );
    }

    return source
}
const parseJustifyCenterTag: TParseJustifyTag = (source) => {
    return source.replace(
        /^:--:(.*)$/gm,
        '<span class="d-block text-center">$1</span>'
    );
}
const parseJustifyLeftTag: TParseJustifyTag = (source) => {
    return source.replace(
        /^:--(?!:)(.*)$/gm,
        '<span class="d-block text-left">$1</span>'
    );
}
const parseJustifyRightTag: TParseJustifyTag = (source) => {
    return source.replace(
        /^--:(.*)$/gm,
        '<span class="d-block text-right">$1</span>');
}

const parseJustifyTag: TParseTag = ({source}) => {

    return  parseJustifyLeftTag(
                parseJustifyRightTag(
                    parseJustifyCenterTag(
                        source
                    )
                )
            )
}

interface IRenderVideoContainerArgs {
    urlVideo: string;
}
const renderVideoContainer = ({urlVideo}: IRenderVideoContainerArgs) => {
    const videoContainer = document.createElement("video");
    videoContainer.src = urlVideo;
    // @ts-ignore
    videoContainer.controlsList="nodownload";
    videoContainer.controls = true;
    videoContainer.className = "video-storage";

    return videoContainer;
};

interface IRenderYoutubeContainerArgs {
    code: string;
    start?: string;
    end?: string;
}
const renderYoutubeContainer = ({code, start, end}: IRenderYoutubeContainerArgs) => {

    const videoContainer = document.createElement("iframe");
    videoContainer.src = `https://www.youtube.com/embed/${code}?rel=0&showinfo=0&modestbranding=1&color=white&iv_load_policy=3&cc_load_policy=1&enablejsapi=1&${start ? `${start}&` : ''}${end ? `${end}&` : ''}`;
    videoContainer.allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";
    videoContainer.width = "100%";
    videoContainer.setAttribute("allowFullScreen", '');
    videoContainer.setAttribute("frameborder", '0');
    videoContainer.className = "iframe-youtube";

    return videoContainer
};

const parseYoutubeTag: TParseTag = ({source,  videoContainers, youtube, videoWrapperClassName= ''}) => {
    if (youtube) {
        const videoTags = source?.match(/{youtube:[^}\n]+}/g) ?? [];

        if (!!videoTags.length) {
            videoTags.forEach(tag => {

                const code = tag.replace('{youtube:', '').replace(/\((start|end)=(\d?|\d+)\)/g, '').replace('}', '');
                const [start] = tag.match(/start=\d+/) ?? [];
                const [end] = tag.match(/end=\d+/) ?? [];

                const videoContainerDefault: Common.Video.VideoResolveContainer = {
                    type: "youtube",
                    url: code
                }
                const {type, url} = videoContainers[code] ?? videoContainerDefault;

                const elemVideo = (type === "youtube")
                    ? renderYoutubeContainer({code, start, end})
                    : renderVideoContainer({urlVideo: url});
                elemVideo.setAttribute("data-video", type);
                const container = document.createElement("div");
                container.className = `module-video-content__inner-video task-video-content__inner-video answer-comment-video-content__inner-video ${videoWrapperClassName}`;
                container.setAttribute("data-video-wrapper", type);
                container.appendChild(elemVideo);
                source = source.replace(tag, container.outerHTML);
                container.remove();

            })
        }
    }

    return source
}

type TSetParseSource = (source: string, videoContainers?: IVideoContainers) => void;
interface Args {
    type: "InlineTag" | "YoutubeTag" | "JustifyTag";
    inline?: boolean;
    youtube?: boolean;
    videoWrapperClassName?: string;
}
type TUseTagsParseReturn = [
    string,
    TSetParseSource
]
type TUseTagsParse = (args: Args) => TUseTagsParseReturn;

export const useTagsParse: TUseTagsParse = (
    {
        type,
        inline,
        youtube,
        videoWrapperClassName,
    }
) => {

    const [source, setSource]                   = useState<string>(null);
    const [sourceParsed, setSourceParsed]       = useState<string>(null);

    const [videoContainers, setVideoContainers] = useState<IVideoContainers>({});


    const parseTag = {
        InlineTag: parseInlineTag,
        YoutubeTag: parseYoutubeTag,
        JustifyTag: parseJustifyTag,
    }

    useEffect(() => {
        const sourceAfterParsing = !!source && (type in parseTag)
            ? parseTag[type]({source, inline, youtube, videoContainers, videoWrapperClassName})
            : source;
        setSourceParsed(sourceAfterParsing)
    }, [source]);

    const setParseSource: TSetParseSource = (source, videoContainers= {}) => {
        if (typeof source === "string") {
            setVideoContainers(videoContainers)
            setSource(source)
        }
    };

    return [sourceParsed, setParseSource]

}
