import React from "react";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import FilePreview from "Cheops/components/ModulePassing/FilePreview";
import "./Message.less";

export default class Message extends React.Component {

    static defaultProps = {
        date: null,
        course_id: null,
        module_id: null,
        element_id: null,
        user_id: null,
        firstName: null,
        lastName: null,
        middleName: '',
        messageObj: {
            message: "",
            attachments: [],
        },
        avatar: null,
        isReview: false,
        getFileInfo: null
    };


    constructor(props) {

        super(props);

        this.state = {};

    }

    render() {

        let show_header = !!this.props.firstName || !!this.props.lastName || !!this.props.middleName;

        return (
            <div className="thread_message">
                {show_header
                && <div className="thread_message__header">
                    <div className="thread_message__avatar">
                        {this.props.isReview && <img src={require("Cheops/../img/lecturer_no_avatar_white.svg")} />}
                    </div>
                    <div className="thread_message__name">
                        {`${this.props.firstName} ${this.props.middleName} ${this.props.lastName}`}
                    </div>
                    <div className="thread_message__date">
                        {
                            this.props.date.toLocaleString("ru", {
                                hour: "2-digit",
                                minute: "2-digit",
                                month: "long",
                                day: "numeric",
                            })
                        }
                    </div>
                </div>}
                <div>
                    <Marked>{this.props.messageObj.message}</Marked>
                    <div className="thread_message__attachments">
                        {this.props.messageObj.attachments.map((file_hash) => <FilePreview
                            key          = {file_hash}
                            hash         = {file_hash}
                            course_id    = {this.props.course_id}
                            module_id    = {this.props.module_id}
                            element_id   = {this.props.element_id}
                            element_type = "task"
                            user_id      = {this.props.user_id}
                            getFileInfo  = {this.props.getFileInfo}
                        />)}
                    </div>
                </div>
            </div>
        );

    }

}
