/* eslint-disable */
import {combineReducers} from 'redux'
import {MAP_IS_FOCUSED, focusMapStatuses} from '../actions';
import {selectedModuleId} from './graph'
import autogradeReducer from './autograde'
import authReducer from './auth'
import nooReducer from './noopolis'
import passingReducer from './passing'


function mapIsFocused(state = focusMapStatuses.FOCUSED, action) {

    switch (action.type) {
        case MAP_IS_FOCUSED:
            return action.status;
        default:
            return state
    }
}

export default combineReducers({
    authReducer,
    autogradeReducer,
    mapIsFocused,
    nooReducer,
    passingReducer,
    selectedModuleId
});
