import "navigator.locks";

export const contestOnlinePutReviewsAsyncLock = ( awaitFor: any ) => {

    navigator
        .locks
        .request
            ("reviews-put", async (lock) => {
                await awaitFor();
            }
    );
};
