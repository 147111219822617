import {DocTitle}                           from "@sirius/ui-lib/src/blocks/DocTitle";
import {withTranslation} from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";
import {
    i18nStr,
    withLocale,
    LocaleString,
    HasTpl,
    HasLocale,
    MaybeHasID,
    MaybeHasValues,
}                        from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {translations}    from "./translations";
import {renderErrorPage} from "Smt/helpers";

type ErrLocaleType = ({ k, ...params }: Record<string, any>) => JSX.Element;
type MaybeHasDefaultTitle = { defaultTitle?: string };

type DocumentTitleValueProps =
    HasTpl &
    HasLocale &
    MaybeHasID &
    MaybeHasValues &
    MaybeHasDefaultTitle
;

const DocumentTitleValue = ({tpl, locale, values, id = 'title', defaultTitle = ''}: DocumentTitleValueProps) =>
    DocTitle({title: i18nStr({tpl, id, locale, values}) || defaultTitle})
;
const DocumentTitleLocalized = withLocale(DocumentTitleValue);

let ErrLocaleDocumentTitle: ErrLocaleType;
let ErrLocaleString: ErrLocaleType;
try {
    ErrLocaleDocumentTitle = withTranslation(DocumentTitleLocalized, translations);
    ErrLocaleString        = withTranslation(LocaleString, translations);
} catch (e) {
    renderErrorPage(500);
}

export {
    ErrLocaleDocumentTitle,
    ErrLocaleString
}
