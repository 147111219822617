import {
    TGetTestResultsReport
}                        from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingSolution/@types";


export enum Language2Ext {
    javascript              = "js",
    cpp                     = "cpp",
    css                     = "css",
    java                    = "java",
    markup                  = "md",
    haskell                 = "hs",
    python                  = "py",
    python3                 = "py",
    "python3-ds"            = "py",
    kotlin                  = "kt",
    pascal                  = "pas",
    csharp                  = "cs",
    go                      = "go",
    php                     = "php",
    rust                    = "rs",
    sql                     = "sql",
    kumir                   = "kum",
    "cpp-no-optimization"   = "cpp",
}

export enum VerdictWeights {
    "none" = 1,
    "noneUnscored",
    "wrong",
    "partly",
    "ok",
    "unscored",
    "annulled",
    "notReady",
}

type HasMessage = Pick<LabSmt.UserAnswerDetailedSolution, 'message'>;

type HasHeadline = {
    headline: string;
}

type HasSubmission = {
    submission: HasMessage & HasHeadline;
}

type HasReview = {
    review: HasMessage;
}

type HasVerdict = {
    verdict:  keyof typeof VerdictWeights;
}

type HasTime = {
    time: number;
}

type HasSolution = {
    solution: MaybeHasSolutionToShow & MaybeHasAnswers & MaybeHasSolutionItem;
}

type HasAnswer = {
    answer: MaybeHasSolution;
}

type HasAnswersData = {
    answersData: LabSmt.AnswersData;
}

type HasElementContent = {
    elementContent: MaybeHasAnswersData;
}

type HasUserAnswer = {
    userAnswer: Partial<LabSmt.ProgrammingSolutionItem>;
}

type HasCourseId = {
    courseId: number;
}

type HasSubmissionId = {
    submissionId: number ;
}

type HasFormVisible = {
    formVisible: boolean;
}

type HasIsSolutionToShow = {
    isSolutionToShow: boolean;
}

export type HasElementLearn = {
    elementLearn:  MaybeHasScore
        & MaybeHasMax
        & MaybeHasSolution
        & MaybeHasIsClosed
        & MaybeHasExtraSolutions
        & MaybeHasElementLearnId
    ;
}

type HasElementLearnId = {
    id: number ;
}

type HasProgramming = {
    programming: Partial<LabSmt.ProgrammingSolutionItem>;
}

type HasSolutionToShow = {
    solutionToShow: HasProgramming;
}

type HasAnswers = {
    answers: SolutionType[];
}

export type HasSummary = {
    summary: Common.Programming.ProgrammingReviewSummary
};

type HasExtEnvironments = {
    extEnvironments : TGetTestResultsReport
};

type HasIsClosed = {
    isClosed: boolean;
}

type HasExtraSolutions = {
    extraSolutions: boolean;
}

type HasForceShowRight = {
    forceShowRight: boolean;
}

type HasSolutionIsNotEditable = {
    solutionIsNotEditable: boolean;
}

type HasHideExpandSolutionPanel = {
    hideExpandSolutionPanel: boolean;
}

type HasSolutionIndex = {
    solutionIndex: number;
}

type HasReadOnly = {
    readOnly: boolean;
}

type HasExportPageMode = {
    exportPageMode: boolean;
}

type HasOnAnswer = {
    onAnswer: ( answer: Partial<LabSmt.ProgrammingSolutionItem> ) => void;
}

type HasOnSelectProgrammingLanguage = {
    onSelectProgrammingLanguage: ( language: LabSmt.Language | string ) => void;
}

type HasOpenAnswerForm = {
    openAnswerForm: () => void;
}
type HasCloseAnswerForm = {
    closeAnswerForm: () => void;
}
type HasMax = {
    max: number;
}

type HasIsHidden = {
    isHidden: boolean;
}

type MaybeHasSubmission                  = Partial<HasSubmission>;
type MaybeHasScore                       = Partial<Pick<LabSmt.TaskExportInfo, 'score'>>;
type MaybeHasReview                      = Partial<HasReview>;
type MaybeHasVerdict                     = Partial<HasVerdict>;
type MaybeHasTime                        = Partial<HasTime>;
type MaybeHasAnswer                      = Partial<HasAnswer>;
type MaybeHasAnswersData                 = Partial<HasAnswersData>;
type MaybeHasCourseId                    = Partial<HasCourseId>;
type MaybeHasSubmissionId                = Partial<HasSubmissionId>;
type MaybeHasFormVisible                 = Partial<HasFormVisible>;
type MaybeHasIsSolutionToShow            = Partial<HasIsSolutionToShow>;
type MaybeHasSolutionToShow              = Partial<HasSolutionToShow>;
type MaybeHasAnswers                     = Partial<HasAnswers>;
type MaybeHasSummary                     = Partial<HasSummary>;
type MaybeHasExtEnvironments             = Partial<HasExtEnvironments>;
type MaybeHasSolution                    = Partial<HasSolution>;
type MaybeHasSolutionItem                = Partial<LabSmt.ProgrammingSolutionItem>;
type MaybeHasIsClosed                    = Partial<HasIsClosed>;
type MaybeHasMax                         = Partial<HasMax>;
type MaybeHasExtraSolutions              = Partial<HasExtraSolutions>;
type MaybeHasElementLearnId              = Partial<HasElementLearnId>;
type MaybeHasForceShowRight              = Partial<HasForceShowRight>;
type MaybeHasSolutionIsNotEditable       = Partial<HasSolutionIsNotEditable>;
type MaybeHasHideExpandSolutionPanel     = Partial<HasHideExpandSolutionPanel>;
type MaybeHasSolutionIndex               = Partial<HasSolutionIndex>;
type MaybeHasReadOnly                    = Partial<HasReadOnly>;
type MaybeHasExportPageMode              = Partial<HasExportPageMode>;
type MaybeHasOnAnswer                    = Partial<HasOnAnswer>;
type MaybeHasOpenAnswerForm              = Partial<HasOpenAnswerForm>;
type MaybeHasCloseAnswerForm             = Partial<HasCloseAnswerForm>;
type MaybeHasOnSelectProgrammingLanguage = Partial<HasOnSelectProgrammingLanguage>;
type MaybeHasIsHidden                    = Partial<HasIsHidden>;

export type SolutionType     =  MaybeHasAnswer
    & MaybeHasScore
    & MaybeHasVerdict
    & MaybeHasSummary
    & MaybeHasSubmissionId
    & MaybeHasExtEnvironments
    ;

export type SolutionRendered =  SolutionType
    & MaybeHasSubmission
    & MaybeHasReview
    & MaybeHasTime
    ;

export type TProgrammingAnswerProps =    & HasElementContent
    & HasUserAnswer
    & HasElementLearn
    & MaybeHasCourseId
    & MaybeHasFormVisible
    & MaybeHasIsSolutionToShow
    & MaybeHasForceShowRight
    & MaybeHasSolutionIsNotEditable
    & MaybeHasHideExpandSolutionPanel
    & MaybeHasSolutionIndex
    & MaybeHasReadOnly
    & MaybeHasExportPageMode
    & MaybeHasOnAnswer
    & MaybeHasOnSelectProgrammingLanguage
    & MaybeHasOpenAnswerForm
    & MaybeHasCloseAnswerForm
    & MaybeHasIsHidden
    ;

export type TProgrammingAnswerState = {
    fileDraggedCounter:   number;
    allAnswersExpanded:   boolean;
    expandedAnswers:      number[];
    editorIsFocused:      boolean;
    fileIsUploading:      boolean;
    oversizePopupVisible: boolean;
    editorCodeExportPage: Partial<LabSmt.ProgrammingSolutionItem>;
};

export type AnswerWithVerdictAndScore = Pick<SolutionType, "verdict" | "score">;
