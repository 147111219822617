import Axios, {AxiosPromise}    from "axios";
import AjaxWrapper              from "Cheops/AjaxWrapper";

export const PORTAL_SMT_API_URL = CONFIG.Api?.webPortal?.url ? `${CONFIG.Api.webPortal.url}` : "";

const WITH_AUTH = {send_auth_token: true};
const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    'Accept':       'application/json',
};

interface IVideoResolveContainer {
    urlVideo: string
}
type TGetVideoResolveContainerReturn =
    Promise<Common.Video.VideoResolveContainer>

export const getVideoResolveContainer = (
    {
        urlVideo
    }: IVideoResolveContainer
): TGetVideoResolveContainerReturn => {

    const videoResolveRequest: Common.Video.VideoResolveRequest = {
        url: urlVideo
    };
    return AjaxWrapper.post(
        `${PORTAL_SMT_API_URL}/api/v2/resolve-video`,
        {...videoResolveRequest},
        {...WITH_AUTH}
    )
};


export const getAuthRegistration = (contest_name: string): AxiosPromise<SmtPortal.Methods.AuthContestResponse> =>
    Axios({
        method: "GET",
        url:    `/smt-portal/auth/registration/${contest_name}`
    })
;

export const postAuthRegistrationRegister = (contest_name: string, data: SmtPortal.Methods.AuthContestRegisterRequest): AxiosPromise<SmtPortal.Methods.AuthContestRegisterResponse> =>
    Axios({
        method: "POST",
        url:    `/smt-portal/auth/registration/${contest_name}`,
        data
    })
;

export const getApiCountries = (contestId: string): AxiosPromise<SmtPortal.Methods.CountryList> =>
    Axios({
        method: "GET",
        url:    `/smt-portal/api/countries?contestId=${contestId}`
    })
;

export const postAuthContestRegister = (contest_name: string, data: SmtPortal.Methods.AuthContestRegisterRequest): AxiosPromise<SmtPortal.Methods.AuthContestRegisterResponse> =>
    Axios({
        method: "POST",
        url:    `/smt-portal/auth/contest/${contest_name}/register`,
        data
    })
;

export const postTokenLoginRegister = (data: SmtPortal.Methods.TokenRequest): AxiosPromise<SmtPortal.Methods.TokenRegisterResponse> =>
    Axios({
        method: "POST",
        url:    `/smt-portal/register/login`,
        headers: {...DEFAULT_HEADERS},
        data
    })
;

export const postTokenFillFormRegister = (data: SmtPortal.Methods.FillFormRequest): AxiosPromise<SmtPortal.Methods.LoginInfo> =>
    Axios({
        method: "POST",
        url:    `/smt-portal/register/fillForm`,
        headers: {...DEFAULT_HEADERS},
        data
    })
;

export const postBugReport = (data: BugAPI.BugRequest, auth_token: string = null ): AxiosPromise<void> =>
    Axios({
        method: "POST",
        url:    `/smt-portal/bug`,
        headers: {...DEFAULT_HEADERS},
        params: {
            auth_token,
        },
        data
    })
;

