import React, {FunctionComponent} from "react";
import {ReqResponseError} from "Lab/Types";
import BemClassName from "Cheops/BemClassName";
import SystemNotificationsMeta from "Lab/SystemNotifications/SystemNotificationsMeta";
import TextControl from "Lab/components/UI2/Controls/TextControl";


export interface SystemNotificationData {
    value: ReqResponseError;
    type: SystemNotificationType;
}

export enum SystemNotificationType {
    info = 'info',
    error = 'error',
    warning = 'warning',
    result = 'result',
}

interface Props extends Partial<SystemNotificationData> {

    children?: React.ReactNode;

    onClick?(): void;
}


const SystemNotification: FunctionComponent<Props> = (props) => {

    const {
        children,
        value,
        type,
        onClick,
    } = props;

    const elementClass = new BemClassName('system_notification__item', type);
    elementClass.appendStatusIf(!!onClick, 'clickable');

    let elementMessage = null;

    if (value) {

        elementMessage = value.message.replace(/\n/ig, '<br/>');

    }

    return <div className={elementClass.toString()} onClick={onClick}>

        {elementMessage
        // eslint-disable-next-line react/no-danger
        && <TextControl><span dangerouslySetInnerHTML={{__html: elementMessage}} /></TextControl>}

        {children}

        {!!value
        && <TextControl>{value}</TextControl>
        && <SystemNotificationsMeta meta={value.meta} />}

    </div>;

};

SystemNotification.defaultProps = {
    type: SystemNotificationType.info,
};

export default SystemNotification;
