import React                                                                 from "react";
import ResizeObserver                                                        from "resize-observer-polyfill";
import isEqual                                                               from "lodash/isEqual";
import BemClassName                                                          from "Cheops/BemClassName";
import {ElementVerdict}                                                      from "Cheops/Model/Element";
import {SmtLocaleString}                                                     from "Smt/SmtLocaleBlock";
import Countdown                                                             from "Smt/TaskPage/Task/Countdown";
import TaskSelectorLink                                                      from "./TaskSelectorLink";
import type {MaybeOnlineStateType, OnlineStateType, ReviewsResponseItemType} from "Smt/SmtProgrammingAnswer/block";
import {isOnlineByTaskNum}                                                   from "Smt/subjects/ContestOnline";
import {TaskStates}                                                          from "Cheops/Model/Task";
import {getTaskDataState, setTaskDataState}                                  from "Smt/TaskPage/Task/TaskDataSubject";

export type StatusTasks = Record<string, Array<string>>;
type GetStatusedTasksType = (statusTasks: StatusTasks, taskStates: TaskStates) => Array<string>;
type TasksProps =
    Pick<Props, 'selectedTaskNum' | 'resultsData' | 'onlineState' | 'jwt' | 'savedTasks'> &
    Pick<State, 'inGroupActiveTask'>
;
type GetTaskStatusArgs = {
    taskId: string,
    tasksProps: TasksProps,
    grouped?: boolean,
    states?: Array<string>
};

interface SortableSolution {
    tmSec: number
};

interface State {
    inGroupActiveTask: string;
}

interface Props {
    isOnLoading: boolean;
    tasks?: Record<string, any>;
    selectedTaskNum?: any;
    navType: any;
    text?: any;
    selectedGroup?: any;
    savedTasks?: any;
    userScore?: string | number;
    resultsData?: any;
    selectTask: any;
    buttonStates?: any;
    sections?: any[];
    bgColor?: string;
    hideScoreExplanation?: boolean;
    taskSelectorColor?: string;
    selectedSection: any;
    toggleShowRightAnswer: any;
    onTimeout: any;
    showSection: any;
    onResultClick: any;
    issuedUntil: any;
    onlineState: MaybeOnlineStateType;
    jwt: string;
}


export default class TaskSelector extends React.Component<Props, State> {

    state: State = {
        inGroupActiveTask: null,
    };

    componentDidMount(): void {
        const taskSelector: HTMLDivElement = document.querySelector('.task_selector__task_selector_wrapper');
        const sections = document.querySelectorAll('.task_selector__section');
        window.addEventListener("scroll", this.updateActiveElementState);
        taskSelector.addEventListener(
            "mouseenter",
            () => {
                let totalHeight = 0;
                for (const s of Array.from(sections)) {
                    const wrapperEl: HTMLDivElement = s.querySelector('.task_selector__tasks_wrapper');
                    if (wrapperEl) {
                        const {scrollHeight} = wrapperEl;
                        wrapperEl.style.height = `${scrollHeight}px`;
                        let paddingTop = parseInt(getComputedStyle(s).getPropertyValue('padding-top'));
                        let paddingBottom = parseInt(getComputedStyle(s).getPropertyValue('padding-top'));
                        totalHeight += wrapperEl.scrollHeight + paddingTop + paddingBottom;
                    }
                }
                taskSelector.style.height = `${totalHeight}px`;
            },
            false
        );

        taskSelector.addEventListener(
            "mouseleave",
            () => {
                for (const s of Array.from(sections)) {
                    const wrapperEl: HTMLDivElement = s.querySelector('.task_selector__tasks_wrapper');
                    wrapperEl.style.height = '';
                    taskSelector.style.height = '';
                }
            },
            false
        );

        this.resizeObserver.observe(document.body as Element);

        this.calculateScrollTop();
        this.updateActiveElementState();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.calculateScrollTop();
        if (prevProps.selectedTaskNum !== this.props.selectedTaskNum || prevProps.isOnLoading !== this.props.isOnLoading) {
            this.updateActiveElementState();
        }
    }


    componentWillUnmount(): void {
        this.resizeObserver.unobserve(
            document.body
        );
        window.removeEventListener("scroll", this.updateActiveElementState);
    }

    resizeObserver = new ResizeObserver(
        () => {
            setTimeout(
                this.updateActiveElementState,
                0
            )
        }
    );

    task_num = 100;

    updateActiveElementState = (): void => {
        if (this.props.isOnLoading) {
            return;
        }

        let activeElNum = null;

        let scrollTop = window.scrollY;
        let winH = window.innerHeight;

        const {selectedGroup} = this.props;
        const {inGroupActiveTask} = this.state;

        if (!selectedGroup) {
            if (inGroupActiveTask !== null) {
                this.setState({inGroupActiveTask: null});
            }
            return;
        }

        const groupItems = Object.keys(selectedGroup);
        if (groupItems.length > 1) {
            for (const elementIndex of groupItems) {
                const wrapperEl: HTMLElement = document.querySelector(`#task_${elementIndex}`);
                if (!wrapperEl) {
                    this.setState({inGroupActiveTask: null});
                    return;
                }
                if (wrapperEl.offsetTop < scrollTop + (winH / 3)) {
                    activeElNum = elementIndex;
                }
            }
        }

        if (inGroupActiveTask !== activeElNum) {
            this.setState({inGroupActiveTask: activeElNum});
        }
    };


    calculateScrollTop = (): void => {
        const taskSelector           = document.querySelector<HTMLDivElement>('.task_selector__task_selector_wrapper');
        const wrapperEls             = document.querySelectorAll<HTMLDivElement>('.task_selector__tasks_wrapper');
        let activeEl: HTMLDivElement = document.querySelector('.task_selector__task--active');
        const groupActiveEl          = document.querySelector<HTMLDivElement>('.task_selector__group--active');

        const activeSection = (
                activeEl
                    ? activeEl.offsetParent
                    : (groupActiveEl
                        ? groupActiveEl.offsetParent
                        : null
                    )
            ) as HTMLDivElement
        ;

        for (const wrapperEl of Array.from(wrapperEls)) {
            if (!activeEl && groupActiveEl) {
                activeEl = groupActiveEl;
            }
            if (activeEl) {
                const paddingTop = parseInt(
                    getComputedStyle(activeEl.offsetParent).getPropertyValue('padding-top')
                );
                wrapperEl.scrollTop = activeEl.offsetTop - paddingTop;
            }
        }

        activeSection &&
        (taskSelector.scrollTop = activeSection.offsetTop);
    };

    private tmSecSort = (a:SortableSolution, b:SortableSolution) => (a.tmSec - b.tmSec);

    handleHoverOff(): void {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE') !== -1 || ua.indexOf('Trident/') !== -1) {
            this.calculateScrollTop();
        }
    }

    getFirstTaskNumInGroup(taskNum: number): number {
        const {tasks} = this.props;
        if (typeof this.props.tasks[taskNum] === 'undefined') {
            // probably it's a grouped task
            let selectedTask = taskNum;
            while (!tasks[selectedTask]) {
                selectedTask--;
                if (selectedTask < 0) {
                    break;
                }
                if (typeof this.props.tasks[selectedTask] === 'object') {
                    if (this.props.tasks[selectedTask][taskNum]) {
                        taskNum = selectedTask;
                        break;
                    }
                }
            }
        }
        return taskNum;
    }

    getTaskAnsweringStatus = (taskNum: number) => {
        const {savedTasks} = this.props;
        const {cachedTasks = {}, user_answers} = getTaskDataState();
        const userAnswers = user_answers && !!user_answers[taskNum]
            ? user_answers[taskNum]
                .filter(({solution}: any) => solution !== null && typeof solution !== 'undefined')
            : []
        ;
        const hasChangedAnswer = !!cachedTasks[taskNum] && !!savedTasks &&
            userAnswers?.length > 0 &&
            !isEqual(
                savedTasks[taskNum],
                user_answers[taskNum]
            )
        ;

        return hasChangedAnswer
    }

    getStatusTasks = (tasksGrouped?: Record<string, any>) => {
        const {
            selectedTaskNum,
            resultsData,
            onlineState,
            jwt,
            savedTasks,
            tasks: _tasks
        } = this.props;
        const { inGroupActiveTask } = this.state;

        const tasksProps = {selectedTaskNum, resultsData, onlineState, jwt, savedTasks, inGroupActiveTask};
        const {statusTasks} = getTaskDataState();
        const grouped = !!tasksGrouped;
        const tasks = grouped ? tasksGrouped : _tasks;

        Object.entries(tasks)
            .forEach(
                ([taskId, task]) => {
                    typeof task === 'object'
                        ? this.getStatusTasks(task)
                        : statusTasks[taskId] = this.getTaskStatus({taskId, grouped, tasksProps})
                }
            )
        ;

        statusTasks[TaskStates.NOT_SAVED] = this.getStatusedTasks(statusTasks, TaskStates.NOT_SAVED);
        statusTasks[TaskStates.NOT_ANSWERED] = this.getStatusedTasks(statusTasks, TaskStates.NOT_ANSWERED);

        setTaskDataState({ statusTasks });
        return statusTasks;
    }

    renderTaskSelectorLinks(tasks: any, section: any, grouped = false, numOffset = 0): React.ReactNode {
        const {selectedTaskNum, bgColor, navType} = this.props;
        const links = [];
        const statusTasks = this.getStatusTasks();

        for (const strTaskNum in tasks) {
            const taskNum = parseInt(strTaskNum);
            const taskId = tasks[taskNum];
            let linkText = numOffset;
            for (const [_taskNum, task] of Object.entries(tasks)) {
                if (parseInt(_taskNum) === taskNum) {
                    break;
                }
                if (typeof task === 'object') {
                    linkText += Object.keys(task).length;
                } else {
                    linkText += 1;
                }
            }

            if (typeof taskId === 'object' && !grouped) {
                let wrapperClassName = 'task_selector__group';
                if (taskNum === selectedTaskNum) {
                    wrapperClassName += ' task_selector__group--active';
                }
                links.push(
                    <span className={wrapperClassName} key={taskNum}>
                        {this.renderTaskSelectorLinks(taskId, section, true, linkText)}
                    </span>
                );
                continue;
            }

            const buttonStates   = statusTasks[taskNum] || [];
            const sectionBg      = section?.metadata?.navButtonColor ?? bgColor;
            const sectionNavType = section?.metadata?.navType ?? navType;

            linkText++;

            links.push(
                <TaskSelectorLink
                    selectTask   = {this.props.selectTask}
                    grouped      = {grouped}
                    taskNum      = {taskNum}
                    key          = {taskNum}
                    text         = {linkText.toString()}
                    navType      = {sectionNavType}
                    bgColor      = {sectionBg}
                    buttonStates = {buttonStates}
                />
            );

        }

        return links;
    }

    render(): React.ReactNode {
        const {
            tasks,
            bgColor,
            taskSelectorColor,
            resultsData,
            hideScoreExplanation,
            sections,
            selectedSection,
            toggleShowRightAnswer,
            onTimeout,
            selectedTaskNum,
            userScore,
            showSection,
            onResultClick,
            issuedUntil,
        } = this.props;
        const styles: React.CSSProperties = {};
        const taskSelectorStyle: React.CSSProperties = {};

        if (bgColor) {
            styles.background = bgColor;
        }

        if (taskSelectorColor) {
            taskSelectorStyle.color = taskSelectorColor;
        }

        const wrapperClass = new BemClassName('task_selector');

        if (sections.length > 1 || (sections.length === 1 && sections[0].name)) {
            wrapperClass.appendStatus("left_aligned");
        }

        return  <div className={wrapperClass.toString()} style={styles}>
                    <div className="task_selector__answers_overlay" onClick={toggleShowRightAnswer} />
                    <div className="task_selector__time">
                        {
                            !resultsData &&
                            <Countdown issuedUntil={issuedUntil} onTimeout={onTimeout} />
                        }
                    </div>
                    {
                        userScore && !hideScoreExplanation &&
                        <div className="task_selector__score" style={taskSelectorStyle}>
                            <SmtLocaleString k="common.task_selector.total" id="value" values={{number: userScore}} />
                        </div>
                    }
                    <div
                        className       = "task_selector__task_selector_wrapper"
                        onMouseLeave    = {this.handleHoverOff.bind(this)}
                        onTransitionEnd = {this.calculateScrollTop}
                    >
                        {
                            sections.map(
                                (range, index) => {
                                    const sectionTasks = Object.keys(tasks)
                                        .reduce(
                                            (accumulator, value) => {
                                                if (value >= range.from && value <= range.to) {
                                                    accumulator[value] = tasks[value];
                                                }
                                                return accumulator;
                                            },
                                            {} as any
                                        )
                                    ;

                                    const sectionStyles: React.CSSProperties = {};
                                    let sectionBg = bgColor;

                                    if (range.metadata && range.metadata.navButtonColor) {
                                        sectionBg = range.metadata.navButtonColor;
                                        sectionStyles.background = sectionBg;
                                    }

                                    let sectionLinkStates = ["section"];

                                    if (range.at && selectedSection === range.at - 1) {
                                        sectionLinkStates.push('active');
                                    }

                                    let resultButtonClass = 'task_selector__task task_selector__task--result';

                                    if (selectedSection === null && selectedTaskNum === -1) {
                                        resultButtonClass += " task_selector__task--active";
                                    }

                                    return <div key={index} className="task_selector__section" style={sectionStyles}>
                                            <div className="task_selector__tasks_wrapper">
                                                {
                                                    !!range.name &&
                                                    <TaskSelectorLink
                                                        selectTask={() => showSection(range.from)}
                                                        text={range.name}
                                                        bgColor={sectionBg}
                                                        buttonStates={sectionLinkStates}
                                                    />
                                                }
                                                {this.renderTaskSelectorLinks(sectionTasks, range)}
                                                {
                                                    index === sections.length - 1 && !resultsData &&
                                                    <div onClick={onResultClick} className={resultButtonClass} />
                                                }
                                            </div>
                                        </div>
                                    ;
                                }
                            )
                        }
                    </div>
                </div>
        ;
    }

    private getLastOnlineSolution({solutions, pending}: OnlineStateType, taskNum: number): ReviewsResponseItemType | null {
        const sorted = [
            ...solutions.map( s => ({...s, pending: false})),
            ...pending.map( p => ({...p, pending: true})),
        ]
            .filter( ({taskId})=>taskId === taskNum + 1 )
            .sort( this.tmSecSort )
        ;
        const last = sorted[sorted.length - 1];
        return last || null;
    }

    private getTaskStatus = ({taskId, grouped = false, states = [], tasksProps}: GetTaskStatusArgs): Array<string> => {
        const {
            selectedTaskNum,
            resultsData,
            onlineState,
            jwt,
            savedTasks,
            inGroupActiveTask
        } = tasksProps;

        const taskNum = parseInt(taskId);

         if ( selectedTaskNum === taskNum ) {
            states.push(TaskStates.ACTIVE);
        }

        if ( inGroupActiveTask === taskId ) {
            states.push(TaskStates.ACTIVE_IN_GROUP);
        }

        const firstTaskNum = grouped ? this.getFirstTaskNumInGroup(taskNum) : void(0);

        const resultData   = resultsData
            ? grouped
                ? resultsData[firstTaskNum][taskNum]
                : resultsData[taskNum]
            : null
        ;

        const lastOnline = onlineState && isOnlineByTaskNum(jwt, `${taskNum}` )
            ? this.getLastOnlineSolution(onlineState, taskNum)
            : null
        ;
        const taskOnlineVerdictIsOK = lastOnline && 'verdict' in lastOnline && lastOnline.verdict === ElementVerdict.OK;
        const taskPendingStatus = lastOnline && 'pending' in lastOnline
            ? lastOnline.pending
            : null
        ;

        const nowAnsweringTask = this.getTaskAnsweringStatus(taskNum) && !(taskPendingStatus || taskOnlineVerdictIsOK);

        if (nowAnsweringTask) {
            states.push(TaskStates.NOT_SAVED)
        } else if (lastOnline) {
            if (taskPendingStatus) {
                states.push(TaskStates.PENDING);
            } else if (taskOnlineVerdictIsOK) {
                states.push(TaskStates.RESULT_RIGHT);
            }
            states.push(TaskStates.SAVED);
        } else if (resultData) {
            const {verdict, answer} = resultData;
            if (typeof verdict === "string") {
                const taskStates = this.getTaskStatesByVerdict(verdict);
                taskStates && states.push(taskStates);
            }
            if (answer) {
                states.push(TaskStates.SAVED);
            }
        } else if (typeof savedTasks[taskNum] !== 'undefined' && savedTasks[taskNum] !== null) {
            const savedTask = savedTasks[taskNum]
                .filter((a: any) => a.solution !== null && typeof a.solution !== 'undefined')
            ;
            savedTask.length > 0 && states.push(TaskStates.SAVED);
        } else {
            states.push(TaskStates.NOT_ANSWERED);
        }

        return states;
    }

    private getStatusedTasks: GetStatusedTasksType = (statusTasks, taskStates) =>
        Object.keys(statusTasks).filter( (taskId) => !!statusTasks[taskId] && statusTasks[taskId].includes(taskStates))
    ;

    private getTaskStatesByVerdict = (verdict: string) => {
        const verdictToStates: Record<string, string> = {
            [ElementVerdict.ANNULLED]:      TaskStates.RESULT_ANNULLED,
            [ElementVerdict.UNSCORED]:      TaskStates.RESULT_UNSCORED,
            [ElementVerdict.OK]:            TaskStates.RESULT_RIGHT,
            [ElementVerdict.PARTLY]:        TaskStates.RESULT_PARTIAL_RIGHT,
            [ElementVerdict.WRONG]:         TaskStates.RESULT_NOT_RIGHT,
            [ElementVerdict.NONE]:          TaskStates.RESULT_NOT_RIGHT,
        };

        return verdict in verdictToStates ? verdictToStates[verdict] : null
    }

}
