import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import adminApp from "Lab/reducers";

let store = createStore(adminApp,
    compose(
        applyMiddleware(thunk, promiseMiddleware()),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);

export default store;