import { translations }               from "Smt/translations";
import { LocalePlural, LocaleString, LocaleDate } from "@sirius/ui-lib/src/blocks/LocaleBlock";
import { withTranslation }            from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";

const SmtLocaleString = withTranslation(LocaleString, translations);
const SmtLocalePlural = withTranslation(LocalePlural, translations);

export {
    SmtLocaleString,
    SmtLocalePlural,
    LocaleDate,
    translations
};
