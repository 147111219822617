import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const SolutionToShowIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 2C6.48 2 2 6.48 2 12C2 17.4314 6.33736 21.8559 11.7351 21.9966C11.019 21.2791 10.4482 20.4165 10.0703 19.4575L9.92578 19.0909L10.0703 18.7243C11.1588 15.962 13.8473 14 17.0006 14C18.6449 14 20.1628 14.5335 21.3934 15.4357C21.7858 14.3642 22 13.207 22 12C22 6.48 17.52 2 12 2ZM17.0006 15C18.507 15 19.8919 15.5175 20.989 16.3844C20.989 16.3845 20.9889 16.3846 20.9888 16.3848C21.8778 17.0873 22.5777 18.0192 23 19.0909C22.0564 21.4855 19.7273 23.1818 17 23.1818C14.2727 23.1818 11.9436 21.4855 11 19.0909C11.9363 16.7149 14.2368 15.0263 16.9368 15.0003C16.9578 15.0001 16.9789 15 17 15C17.0002 15 17.0004 15 17.0006 15ZM15.501 21.3692C15.9312 21.653 16.4464 21.8182 17 21.8182C18.5055 21.8182 19.7273 20.5964 19.7273 19.0909C19.7273 17.5855 18.5055 16.3636 17 16.3636C15.4945 16.3636 14.2727 17.5855 14.2727 19.0909C14.2727 20.0428 14.7612 20.8813 15.501 21.3692ZM18.5767 19.5313C18.6156 19.3912 18.6364 19.2435 18.6364 19.0909C18.6364 18.1855 17.9055 17.4545 17 17.4545C16.0945 17.4545 15.3636 18.1855 15.3636 19.0909C15.3636 19.9964 16.0945 20.7273 17 20.7273C17.7528 20.7273 18.385 20.222 18.5767 19.5313ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L15.88 8.29C16.27 7.9 16.9 7.9 17.29 8.29C17.68 8.68 17.68 9.31 17.29 9.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
              fill="#B0B0B0"
        />
    </svg>
</SvgIcon>;


export default SolutionToShowIcon;
