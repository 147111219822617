import AjaxWrapper           from "Cheops/AjaxWrapper";
import {ReqResponse}         from "Lab/Types";
import {TaskContentVersion}  from "Lab/Course/Module/TaskContentVersion";
import {ObjectState}         from "Lab/Model/Object";
import {Module}              from "Lab/Environments/Module/Module";
import {TextContentVersion}  from "Lab/Course/Module/TextContentVersion";
import Marking               from "Lab/Model/Marking";
import {VideoContentVersion} from "Lab/Course/Module/VideoContentVersion";
import Files                 from "Lab/Utils/Files";

const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";
export const LAB_NOO_API = CONFIG.Api?.cheopsLaboratory?.url ? `${CONFIG.Api.cheopsLaboratory.url}` : "";
const WITH_AUTH = {send_auth_token: true};

export type strNumber = string | number;

export const getEnvTask = (envId: strNumber, taskId: strNumber, versionId: strNumber): Promise<ReqResponse<TaskContentVersion>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/task/${taskId}`,
        {version: versionId},
        {send_auth_token: true},
    );

};

export const getEnvTaskInModule = (envId: strNumber, moduleVersion: strNumber, taskId: strNumber, versionId: strNumber): Promise<ReqResponse<LabNoo.ModuleTask>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleVersion}/task/${taskId}/${versionId}`,
        {},
        {send_auth_token: true},
    );

};


type PatchEnvTaskInModuleResponse = {
    task: LabNoo.ModuleTask;
    moduleVersionId: number;
    taskVersionId: number;
};

export const patchEnvTaskInModule = (envId: strNumber, moduleVersion: strNumber, taskId: strNumber, versionId: strNumber, patches: Record<string, any>, files: File[] = []): Promise<ReqResponse<PatchEnvTaskInModuleResponse>> => {


    let formData = new FormData();
    formData.append("patch", JSON.stringify({patches}));

    for (let [index, file] of Object.entries(files)) {

        formData.append(`file-${index}`, file);

    }


    return AjaxWrapper.post(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleVersion}/task/${taskId}/${versionId}`,
        formData,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            send_auth_token: true,
        },
    );

};


export const getEnvText = (envId: strNumber, textId: strNumber, versionId: strNumber): Promise<ReqResponse<TextContentVersion>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/text/${textId}`,
        {version: versionId},
        {send_auth_token: true},
    );

};

export const getEnvVideo = (envId: strNumber, videoId: strNumber, versionId: strNumber): Promise<ReqResponse<VideoContentVersion>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/video/${videoId}`,
        {version: versionId},
        {send_auth_token: true},
    );

};


export interface TaskVersionInfo {
    versionId: number;
    status?: LabNoo.ObjectState;
    textVersion: LabNoo.TaskTextVersion;
    inModule: boolean;
}

export interface GetEnvTaskVersionListResponse {
    version: TaskVersionInfo[];
}


export const getEnvTaskVersionList = (envId: strNumber, taskId: strNumber): Promise<ReqResponse<GetEnvTaskVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/task/${taskId}/version/list`,
        {},
        {send_auth_token: true},
    );

};


export interface TextVersionInfo {
    versionId: number;
    status: ObjectState;
    textVersion: string;
}


export interface GetEnvTextVersionListResponse {
    version: TextVersionInfo[];
}

export const getEnvTextVersionList = (envId: strNumber, textId: strNumber): Promise<ReqResponse<GetEnvTextVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/text/${textId}/version/list`,
        {},
        {send_auth_token: true},
    );

};



export const getContestTaskExport = async (contest_id: strNumber, task_id: strNumber, task_version_id: strNumber): Promise<void> => {
    const response = await AjaxWrapper.get(
        `${CONFIG.Api.smtLaboratory.url}/contest/${contest_id}/task/${task_id}/ejudge/${task_version_id}`,
        {},
        {
            headers: { Accept: 'application/octet-stream' },
            send_auth_token: true,
            responseType: 'blob',
        });

     if (response) {

        Files.download(response, `ejudge-${contest_id}_${task_id}_${task_version_id}.zip`, 'text/csv');

    }


}

export const getEnvironmentTaskExport = async (envId: strNumber, versionId: strNumber, moduleVersionId: strNumber): Promise<void> => {

    const response = await AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url}/environment/${envId}/module/${moduleVersionId}/task/${versionId}/export-ejudge`,
        {},
        {
            send_auth_token: true,
            responseType: 'blob',
        });


    if (response) {

        Files.download(response, `ejudge-${envId}_${versionId}.zip`, 'text/csv');

    }


};


export interface VideoVersionInfo {
    versionId: number;
    status: ObjectState;
    textVersion: string;
}


export interface GetEnvVideoVersionListResponse {
    version: VideoVersionInfo[];
}

export const getEnvVideoVersionList = (envId: strNumber, videoId: strNumber): Promise<ReqResponse<GetEnvVideoVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/video/${videoId}/version/list`,
        {},
        {send_auth_token: true},
    );

};


export const getEnvModuleInfo = (envId: strNumber, moduleId: strNumber, versionId: strNumber = null): Promise<ReqResponse<Module>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleId}`,
        {version: versionId},
        {send_auth_token: true},
    );

};


export const publishTask = (envId: strNumber, versionId: strNumber): Promise<ReqResponse> => {

    return AjaxWrapper.patch(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/task/${versionId}/publish`,
        {},
        {send_auth_token: true},
    );

};


export const publishText = (envId: strNumber, versionId: strNumber): Promise<ReqResponse> => {

    return AjaxWrapper.patch(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/text/${versionId}/publish`,
        {},
        {send_auth_token: true},
    );

};


interface GetTaskStateResponse {
    compileReport: any; // TODO
    solutionsReport: LabNoo.TaskVersion['solutionsReport'];
    checksRunning: boolean;
    state: ObjectState;
}

export const getTaskState = (envId: strNumber, taskId: strNumber): Promise<ReqResponse<GetTaskStateResponse>> => {

    return AjaxWrapper.post(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/task/${taskId}/status`,
        {},
        {send_auth_token: true},
    );

};

interface ValidateElementResponse {
    checksRunning: boolean;
    ok: never;
    warn: string;
}

export const validateTask = (envId: strNumber, taskId: strNumber, versionId: strNumber): Promise<ReqResponse<ValidateElementResponse>> => {

    return AjaxWrapper.patch(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/task/${taskId}/validate?version=${versionId}`,
        {},
        {send_auth_token: true},
    );

};


export const validateText = (envId: strNumber, textId: strNumber, versionId: strNumber): Promise<ReqResponse<ValidateElementResponse>> => {

    return AjaxWrapper.patch(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/text/${textId}/validate?version=${versionId}`,
        {},
        {send_auth_token: true},
    );

};
export const validateVideo = (envId: strNumber, videoId: strNumber, versionId: strNumber): Promise<ReqResponse<ValidateElementResponse>> => {

    return AjaxWrapper.patch(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/video/${videoId}/validate?version=${versionId}`,
        {},
        {send_auth_token: true},
    );

};


type ElementType = 'task' | 'video' | 'text';

interface GetEnvironmentMarkingsResponse {
    element: ElementType;
    value: Marking[];
}

export const getEnvironmentMarkings = (envId: strNumber, elementType: ElementType): Promise<ReqResponse<GetEnvironmentMarkingsResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/markings/${elementType}`,
        {},
        {send_auth_token: true},
    );

};

export const getModulesExport = (environmentId: strNumber, data: strNumber[]): Promise<ReqResponse> => {

    return AjaxWrapper.post(`${CONFIG.Api.cheopsLaboratory.url}/environment/${environmentId}/export/json`,
        data,
        {
            send_auth_token: true,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            responseType: 'blob',
        });

};

export const postModules = (environmentId: strNumber, data: File): Promise<ReqResponse> => {

    let formData = new FormData();

    formData.append('file', data);

    return AjaxWrapper.post(
        `${CONFIG.Api.cheopsLaboratory.url}/environment/${environmentId}/import`,
        formData,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            keep_errors:     true,
            ...WITH_AUTH,
        },
    );

};

export const getEnvTaskVersionInModule = (envId: strNumber, moduleVersionId: strNumber, taskId: strNumber): Promise<ReqResponse<GetEnvTaskVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleVersionId}/task/${taskId}/version/list`,
        {},
        {send_auth_token: true},
    );

};
export const getEnvTextVersionInModule = (envId: strNumber, moduleVersionId: strNumber, taskId: strNumber): Promise<ReqResponse<GetEnvTaskVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleVersionId}/text/${taskId}/version/list`,
        {},
        {send_auth_token: true},
    );

};
export const getEnvVideoVersionInModule = (envId: strNumber, moduleVersionId: strNumber, taskId: strNumber): Promise<ReqResponse<GetEnvTaskVersionListResponse>> => {

    return AjaxWrapper.get(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment/${envId}/module/${moduleVersionId}/video/${taskId}/version/list`,
        {},
        {send_auth_token: true},
    );

};

export const createEnvironment = (data: LabNoo.EnvronmentCreateRequest): Promise<ReqResponse<LabNoo.EnvironmentResponse>> => {

    return AjaxWrapper.put(
        `${CONFIG.Api.cheopsLaboratory.url + VERSION}/environment`,
        data,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'application/json;charset=utf-8',
            },
            send_auth_token: true,
        }
    );

};

export type GetEnvironmentsResponse = Array<LabNoo.Environment>;

export const getEnvironments = (): Promise<GetEnvironmentsResponse> => {

    return  AjaxWrapper.get(
        CONFIG.Api.cheopsLaboratory.url + VERSION + '/environment/all',
        {},
        {send_auth_token: true}
    );
};

interface IVideoResolveContainer {
    urlVideo: string
}
export type TGetVideoResolveContainer = (args: IVideoResolveContainer) =>
    Promise<LabNoo.ResolveVideoResponse>;

export const getVideoResolveContainer: TGetVideoResolveContainer = (
    {
        urlVideo
    }
) => {

    const videoResolveRequest: Common.Video.VideoResolveRequest = {
        url: urlVideo
    };
    return AjaxWrapper.post(
        `${LAB_NOO_API}${VERSION}/api/v2/resolve-video`,
        {...videoResolveRequest},
        {...WITH_AUTH}
    )
};

export const getEnvConfig = (): Promise<LabNoo.ConfigResponse> =>
    AjaxWrapper.get(
        `${LAB_NOO_API}${VERSION}/config`,
        {},
        {send_auth_token: true}
    )
;

export const insertFiles = (data: File, name: string): Promise<Common.File.FileInsertResponse> => {
    const file = new FormData();

    file.append('file', data);

    return AjaxWrapper.post(
        `${LAB_NOO_API}/files/insert?name=${name}`,
        file,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            ...WITH_AUTH,
        }
    );
};

type PostEnvModuleEntitySettingsFormParams<D> = {
    envId:           strNumber,
    moduleVersionId: strNumber,
    data:            D,
};

export const postEnvModuleEntityLoadSettingsForm = (
    {
        envId,
        moduleVersionId,
        data,
    }: PostEnvModuleEntitySettingsFormParams<LabNoo.Env.Module.Entity.SettingsForm.EnviromentModuleEntitySettingsLoadFormRequest>
): Promise<LabNoo.Env.Module.Entity.SettingsForm.EnvironmentModuleEntitySettingsFormResponse> =>

    AjaxWrapper.post(
        `${LAB_NOO_API}/environment/${envId}/module/${moduleVersionId}/entity/settings-form`,
        data,
        {...WITH_AUTH},
    )
;

export const postEnvModuleEntitySaveSettingsForm = (
    {
        envId,
        moduleVersionId,
        data,
    }: PostEnvModuleEntitySettingsFormParams<LabNoo.Env.Module.Entity.SettingsForm.EnviromentModuleEntitySettingsSaveFormRequest>
): Promise<LabNoo.Env.Module.Entity.SettingsForm.EnvironmentModuleEntitySettingsFormResponse> =>

    AjaxWrapper.post(
        `${LAB_NOO_API}/environment/${envId}/module/${moduleVersionId}/entity/settings-form`,
        data,
        {...WITH_AUTH},
    )
;

export const postEnvModuleAutogradeEntityLoadSettingsForm = (
        {
            envId,
            moduleVersionId,
            data,
        }: PostEnvModuleEntitySettingsFormParams<LabNoo.Env.Module.Entity.SettingsForm.EnviromentModuleEntitySettingsLoadFormRequest>
    ): Promise<LabNoo.Env.Module.Entity.SettingsForm.EnvironmentModuleEntitySettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${envId}/module/${moduleVersionId}/auto_grade/entity/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postEnvModuleAutogradeEntitySaveSettingsForm = (
        {
            envId,
            moduleVersionId,
            data,
        }: PostEnvModuleEntitySettingsFormParams<LabNoo.Env.Module.Entity.SettingsForm.EnviromentModuleEntitySettingsSaveFormRequest>
    ): Promise<LabNoo.Env.Module.Entity.SettingsForm.EnvironmentModuleEntitySettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${envId}/module/${moduleVersionId}/auto_grade/entity/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

type PostEnvModuleTaskSettingsFormParams<D> = {
    environmentId:   strNumber,
    moduleVersionId: strNumber,
    taskVersionId:   strNumber,
    data:            D,
};

export const postEnvModuleTaskLoadSettingsForm = (
        {
            environmentId,
            moduleVersionId,
            taskVersionId,
            data,
        }: PostEnvModuleTaskSettingsFormParams<LabNoo.Env.Module.Task.SettingsForm.EnviromentTaskSettingsLoadFormRequest>
    ): Promise<LabNoo.Env.Module.Task.SettingsForm.EnvironmentTaskSettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${environmentId}/module/${moduleVersionId}/task/${taskVersionId}/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postEnvModuleTaskSaveSettingsForm = (
        {
            environmentId,
            moduleVersionId,
            taskVersionId,
            data,
        }: PostEnvModuleTaskSettingsFormParams<LabNoo.Env.Module.Task.SettingsForm.EnviromentTaskSettingsApplyUpdateFormRequest>
    ): Promise<LabNoo.Env.Module.Task.SettingsForm.EnvironmentTaskSettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${environmentId}/module/${moduleVersionId}/task/${taskVersionId}/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

type PostEnvModuleTaskInputsSettingsFormParams<D> = {
    environmentId:   strNumber,
    moduleVersionId: strNumber,
    taskVersionId:   strNumber,
    inputNumber:     strNumber,
    data:            D,
};

export const postEnvModuleTaskInputsLoadSettingsForm = (
        {
            environmentId,
            moduleVersionId,
            taskVersionId,
            inputNumber,
            data,
        }: PostEnvModuleTaskInputsSettingsFormParams<LabNoo.Env.Module.Task.Input.SettingsForm.EnvironmentTaskSettingsDynamicLoadFormRequest>
    ): Promise<LabNoo.Env.Module.Task.Input.SettingsForm.EnvironmentTaskSettingsDynamicLoadFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${environmentId}/module/${moduleVersionId}/task/${taskVersionId}/inputs/${inputNumber}/load-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postEnvModuleTaskInputsSaveSettingsForm = (
        {
            environmentId,
            moduleVersionId,
            taskVersionId,
            inputNumber,
            data,
        }: PostEnvModuleTaskInputsSettingsFormParams<LabNoo.Env.Module.Task.Input.SettingsForm.EnvironmentTaskSettingsDynamicSaveFormRequest>
    ): Promise<LabNoo.Env.Module.Task.Input.SettingsForm.EnvironmentTaskSettingsDynamicSaveFormResponse> =>

        AjaxWrapper.post(
            `${LAB_NOO_API}/environment/${environmentId}/module/${moduleVersionId}/task/${taskVersionId}/inputs/${inputNumber}/save-settings-form`,
            data,
            {...WITH_AUTH},
        )
;
