import React, { FC }                                    from "react";
import {useParams }                                     from "react-router";
import {
    getProgrammingAnswerTestResultsReport
        as nooGetProgrammingAnswerTestResultsReport,
    getProgrammingAnswerTestResultsProtocol
        as nooGetProgrammingAnswerTestResultsProtocol,
}                                                       from "Cheops/actions/noopolis-ts";
import {
    getProgrammingAnswerTestResultsReport
        as labGetProgrammingAnswerTestResultsReport,
    getProgrammingAnswerTestResultsProtocol
        as labGetProgrammingAnswerTestResultsProtocol,
}                                                       from "Lab/actionsV2/laboratory";
import {VBox}                                           from "@sirius/ui-lib/src/blocks/Layout";
import {SolutionTestsReport}                            from "@sirius/ui-shared/src/components/Programming/TestsReport";
import {block, instance}                                from "./index";
import                                                       "./index.less";
import type  {
    ProgrammingSolutionProps,
    IUrlParams,
    TTestsReportResponse,
    TTestsReportSuccess,
    TTestsProtocolResponse,
    TTestProtocolResponseSuccess,
    TGetTestResultsReportWArgs,
    TGetTestDetailReport,
    TGetTestResultsReport,
    TGetProgrammingAnswerTestResultsReport,
    TGetProgrammingAnswerTestResultsProtocol,
}                                                       from "./@types";

const ProgrammingSolution: FC<ProgrammingSolutionProps> = ({ summary, elementId, submissionId, extEnvironments = {}} ) => {
    const {
        courseId,
        moduleId,
        taskVersionId,
        userId
    } = extEnvironments ;

    const {
        course_id   = courseId,
        module_id   = moduleId,
        element_id  = elementId ?? taskVersionId
    }: IUrlParams = useParams();

    const urlArgs : TGetTestResultsReport = {
        courseId:       course_id,
        moduleId:       module_id,
        taskVersionId:  element_id,
        submissionId,
        userId
    };

    const checkTestsReportResponse = (success: TTestsReportSuccess['success']): boolean =>{
        const {columns, rows} = success
        return !!columns && !!rows
    }
    
    const getProgrammingAnswerTestResultsReport: TGetProgrammingAnswerTestResultsReport = {
        noo: nooGetProgrammingAnswerTestResultsReport,
        lab: labGetProgrammingAnswerTestResultsReport
    }

    const getTestResultsReportWArgs: TGetTestResultsReportWArgs = () => getProgrammingAnswerTestResultsReport[instance](urlArgs);

    const testsReportResponse = (data: TTestsReportResponse) => new Promise <Common.Programming.ReviewTestsReportResponse> ( (resolve, reject) => {
            const {success} = data as TTestsReportSuccess;
            (success && checkTestsReportResponse(success))
                ? resolve(success)
                : reject(data);
        }
    );

    const TestsReportCb = (): Promise<Common.Programming.ReviewTestsReportResponse> => {
        return getTestResultsReportWArgs()
            .then(
                testsReportResponse
            );
    };

    const getProgrammingAnswerTestResultsProtocol: TGetProgrammingAnswerTestResultsProtocol = {
        noo: nooGetProgrammingAnswerTestResultsProtocol,
        lab: labGetProgrammingAnswerTestResultsProtocol
    };

    const getTestDetailReport: TGetTestDetailReport = (testNo: string) =>
        getProgrammingAnswerTestResultsProtocol[instance]({...urlArgs, testNo})
    ;

    const testsProtocolResponse = (data: TTestsProtocolResponse) => new Promise <Common.Programming.ReviewTestProtocolResponse> ( (resolve, reject) => {
            const {success} = data as TTestProtocolResponseSuccess;
            (success)
                ? resolve(success)
                : reject(data);
        }
    );

    const TestDetailReportCb = (testNo: string) =>
        getTestDetailReport(testNo)
            .then(
                testsProtocolResponse
            )
    ;

    return (
        <VBox className={block.el('verdicts-review')}>
            {summary
                && TestsReportCb
                && <SolutionTestsReport {...{summary, TestsReportCb, TestDetailReportCb }}/>
            }
        </VBox>
    );

};

export default  ProgrammingSolution;
