import React                    from "react";
import Marked                   from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import BemClassName             from "Cheops/BemClassName";
import {CheopsLocaleString}     from "Cheops/CheopsLocaleBlock";

interface Props {
    value:                 string | number;
    fractionPreviewText:   string;
    fractionPreviewErrors: Common.Math.LatexResponseError[];
}

const MultipleAnswersFractionPreview: React.FC<Props> = ({value, fractionPreviewText, fractionPreviewErrors}) => {
    const fractionClassName = new BemClassName('task__fraction_view');
          fractionClassName.appendStatusIf((!!value && !!fractionPreviewErrors), 'error');

    const text = fractionPreviewText || <CheopsLocaleString k="cheops.fraction_preview.default_text" id="value" />;

    return <div className={`${fractionClassName}`}>{
                fractionPreviewErrors?.length
                    ? fractionPreviewErrors.map(
                        (error: Common.Math.LatexResponseError, i: number) =>
                            <Marked key={i}>{
                                !error.key
                                    ? error.message
                                    : <CheopsLocaleString k={error.key}/>
                            }</Marked>
                        )
                    : <Marked>{text}</Marked>
            }</div>
    ;
};

export default MultipleAnswersFractionPreview;
