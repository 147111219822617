import AjaxWrapper                             from "Cheops/AjaxWrapper";
import {ReqResponse}                           from "Lab/Types";
import {ScorerListItem}                        from "Lab/Model/ScorerListItem";
import {TaskAnswerType}                        from "Cheops/Model/Task";
import {TaskAnswersData}                       from "Lab/Model/Task";
import * as objectHash                         from "object-hash";
import {TaskContentVersion}                    from "Lab/Course/Module/TaskContentVersion";
import {Languages}                             from "Lab/Task/Answers/AnswerTypes/Programming/AnswersProgrammingSettingsAspect";
import {FileInfo, SearchParams, SMT_API}       from "Lab/actionsV2/smt";
import {StudentsJournal}                       from "Lab/Course/Students/@types/StudentsJournal";
import Course                                  from "Lab/Course/Course";
import Files                                   from "Lab/Utils/Files";
import {UserPermissions, UserPermissionsPatch} from "../containers/PermissionPage";

type strNumber = string | number;

const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";
export const LAB_API_URL = CONFIG.Api?.cheopsLaboratory?.url ? `${CONFIG.Api?.cheopsLaboratory?.url}${VERSION}` : "";
const WITH_AUTH = {send_auth_token: true};


export type UploadImageResponse = {
    hash: string;
};

export const uploadImage = (name: string, pic: File, resizeImage = false): Promise<ReqResponse<UploadImageResponse>> => {

    let formData = new FormData();

    formData.append('name', name);
    formData.append('pic', pic);

    return AjaxWrapper.post(`${LAB_API_URL}/images/insert?resize=${resizeImage}`, formData, {
        headers: {
            Accept: 'application/json;charset=utf-8',
            'Content-Type': 'multipart/form-data',
        },
        ...WITH_AUTH,
    });

};


export enum Period {
    D = "day",
    W = "week",
    M = "month"
}

export enum StatisticsDataPlot {
    USERS = "users",
    CONTESTS = "contests",
    COURSE = "course"
}

export type ChartsData = {
    data: {
        x: "string";
        y: number;
    }[];
    title: string;
    approx: number;
    total: number;
    trend: number;
}[];
export type InfoData = {
    users: number;
    activeContestUsers: number;
    activeCourses: number;
    achievedModules: number;
    activeUsers: number;
    achievedTasks: number;
    activeContests: number;
};
export type PersonalStatisticsResponse = {
    plots: ChartsData;
    info: InfoData;
};

export const getPersonalStatistics = (period: Period): Promise<ReqResponse<PersonalStatisticsResponse>> => {

    const data: { period: Period; plot: StatisticsDataPlot }[] = [
        {period, plot: StatisticsDataPlot.USERS},
        {period, plot: StatisticsDataPlot.CONTESTS},
    ];

    return AjaxWrapper.post(`${LAB_API_URL}/api/v2/personal/statistics`, data, {
        headers: {
            Accept: 'application/json;charset=utf-8',
            'Content-Type': 'application/json;charset=utf-8',
        },
        ...WITH_AUTH,
    });

};

export type LeftSidebarStatus = {
    type: string;
    payload: boolean;
};

export const setLeftSidebarStatus = (isOpen: boolean): LeftSidebarStatus => {

    return {
        type: "SET_LEFT_SIDEBAR_STATUS",
        payload: isOpen,
    };

};


export const getScorerList = (): Promise<ScorerListItem[]> => AjaxWrapper.get(
    `${LAB_API_URL}/scorer/list`,
    {},
    WITH_AUTH,
);

export type CommonScorerList = {
    scorer: ScorerListItem[];
};

const storedCommonScorers: Record<string, ReqResponse<CommonScorerList>> = {};

export const getCommonScorerList = async (types: TaskAnswerType[][], answersData: TaskAnswersData[][]): Promise<ReqResponse<CommonScorerList>> => {

    const data = {
        types,
        answersData,
    };

    const dataHash = objectHash.MD5(data);

    if (storedCommonScorers[dataHash]) {

        return storedCommonScorers[dataHash];

    }

    storedCommonScorers[dataHash] = await AjaxWrapper.post(
        `${LAB_API_URL}/config/common-scorer/v2`,
        data,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'application/json;charset=utf-8',
            },
            ...WITH_AUTH,
        },
    );

    return storedCommonScorers[dataHash];

};

export type RightNode = {
    id: string;
    externalId: string;
    title: string;
    permission?: "write" | "read";
    type: string;
    hasChildren: boolean;
} & Partial<{[field in PermissionsFields]: any}>;

export type UserPermissionsType = {
    id?: string;
    externalId?: string;
    nodes: RightNode[];
    parentId?: string;
    type?: string;
    hasChildren: boolean;
};

export type PermissionsFields = "userGroups" | "permission";
export type PermissionsForm = {
    values?: { name: string; description: string; enabled?: boolean }[];
    help: string;
    name: PermissionsFields;
    type: string;
    description: string;
    searchable?: boolean;
    sub: PermissionsForm [];
    entityType?: SearchEntityTypes;
    style?: "group" | "single";
};

type UserPermissionsRes = {
    data: UserPermissionsType[];
    form: PermissionsForm[];
};

export const getUserPermissions = (userId: string): Promise<ReqResponse<UserPermissionsRes>> => {

    return AjaxWrapper.get(
        `${LAB_API_URL}/user/${userId}/permissions/v2`,
        {},
        WITH_AUTH,
    );

};

export type NodePermissionType = {
    children: {
        title: string;
        type: string;
        children: {
            id: string;
            externalId: string;
        };
        hasChildren: boolean;
    }[];
};

export const getNodePermissions = (type: string, externalId: string): Promise<ReqResponse<NodePermissionType>> => {

    return AjaxWrapper.post(
        `${LAB_API_URL}/user/permissions/children/${type}/${externalId}`,
        {},
        WITH_AUTH,
    );

};

export type ResolveNodePermissions = {
    result: {
        id: number;
        externalId: number;
        hasChildren: boolean;
    }[];
};

export const resolveNodePermissionId = (parentId: number, type: string, externalId: number | string): Promise<ReqResponse<ResolveNodePermissions>> => {

    const body = {
        parentId,
        type,
        externalId,
    };

    return AjaxWrapper.post(
        `${LAB_API_URL}/user/permissions/resolve`,
        body,
        WITH_AUTH,
    );

};


export type PatchUserPermissionsPayload = {
    isRW: boolean;
    isR: boolean;
} & Partial<{[field in PermissionsFields]: any}>;

type ModifyUserPermissionsResponse = {
    added: number;
    deleted: number;
};
type PatchUserPermissionsResponse = "write" | "read";

export const patchUserPermissions = (userId: string, nodeId: string, data: PatchUserPermissionsPayload): Promise<ReqResponse<PatchUserPermissionsResponse>> => {

    return AjaxWrapper.patch(
        `${LAB_API_URL}/user/${userId}/permissions/${nodeId}`,
        data,
        WITH_AUTH,
    );

};

export const deleteUserPermissions = (userId: string, nodeId: string): Promise<ReqResponse<ModifyUserPermissionsResponse>> => {

    return AjaxWrapper.delete(
        `${LAB_API_URL}/user/${userId}/permissions/${nodeId}`,
        {},
        WITH_AUTH,
    );

};

export const getTestProgrammingTask = (envId: strNumber, taskId: strNumber, testId: strNumber, type: "input" | "output"): Promise<ReqResponse<LabNoo.TaskTestInfoResponseSuccess>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/environment/${envId}/task/${taskId}/test/${testId}/${type}`, {}, WITH_AUTH);

};

type ValidatorInfoRes = {
    values: {
        name: string;
        description: string;
    }[];
    name: string;
    description: string;
    type: string;
}[];
export const getValidatorInfoEnv = (nameValidator: string): Promise<ReqResponse<ValidatorInfoRes>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/environment/1/task/1/validator/${nameValidator}`, {}, WITH_AUTH);

};

export const getBlacklistValidatorInfoEnv = (languages: Languages[]): Promise<ReqResponse<ValidatorInfoRes>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/environment/1/task/1/validator/blacklist`, {languages}, WITH_AUTH);

};

export type ProgrammingReport = { [k: string]: { headline: string; message: string } };

export const getProgrammingTaskReport = (envId: string, taskId: string, solutions: string[]): Promise<ReqResponse<ProgrammingReport>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/environment/${envId}/task/${taskId}/report`, {solutions}, WITH_AUTH);

};

export type TaskValidateResponse = {
    checksRunning: boolean;
    task: TaskContentVersion;
    warning: string;
};

export const validateTaskInModule = (envId: string, moduleId: string, taskId: string, versionId: string): Promise<ReqResponse<TaskValidateResponse>> => {

    return AjaxWrapper.patch(`${LAB_API_URL}/environment/${envId}/module/${moduleId}/task/${taskId}/${versionId}/validate?version=${versionId}`, {}, WITH_AUTH);

};

export type UserInfo = Common.User.FoundUser;

export const getUserListData = (
    userIds:    Common.User.ResolveIdsJsonRpcParamsIds,
    properties: Common.User.ResolveIdsJsonRpcParamsIds
): Promise<Common.User.ResolveIdsResponse> => {
    const request: Common.User.ResolveIdsRPCRequest =  {
        jsonrpc: "2.0",
        method: "user/list",
        params: {
            ids: userIds,
            properties: properties.includes('id')
                ? properties
                : [...properties, 'id']
            ,
        },
        id: 1
    };
    return AjaxWrapper.post(
        `${LAB_API_URL}/user/resolve-ids`,
        request,
        {...WITH_AUTH, keep_errors: true}
    );
};

export type SearchCourseData = { name: string; id: string };

export const searchCourse = (name: string): Promise<ReqResponse<SearchCourseData[]>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/search?name=${name}`, {}, WITH_AUTH);

};

export enum CertificateType {
    course = 'course',
    quiz = 'quiz',
}

export type Certificate = {
    rank: number;
    name: string;
    ready: number;
    requested: number;
    issued: number;
    error: number;
    certificateType: CertificateType[];
};

export const getCourseTodayStatistic = (courseId: string): Promise<ReqResponse<Certificate>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}/statistics/today`, {}, WITH_AUTH);

};

export enum moduleSuperSessionStatus {
    notStarted = 'notStarted',
    inProgress = 'inProgress',
    finished = 'finished',
}

export type ModulePreviewInfo = {
    courseId: number;
    courseModuleId: number;
    status: moduleSuperSessionStatus;
};

export const getSuperSessionStatus = (envId: string, moduleId: string, moduleVersionId: string): Promise<ReqResponse<ModulePreviewInfo>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/environment/${envId}/module/${moduleId}/preview/${moduleVersionId}`, {}, WITH_AUTH);

};

export const startSessionStatus = (envId: string, moduleId: string, moduleVersionId: string): Promise<ReqResponse<ModulePreviewInfo>> => {

    return AjaxWrapper.put(`${LAB_API_URL}/environment/${envId}/module/${moduleId}/preview/${moduleVersionId}`, {}, WITH_AUTH);

};

export const reStartSessionStatus = (envId: string, moduleId: string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.delete(`${LAB_API_URL}/environment/${envId}/module/${moduleId}/preview`, {}, WITH_AUTH);

};

export const getStudentsJournalTableData = (courseId: number, searchParams: SearchParams): Promise<ReqResponse<ResponsiveTableByBackend.Data<StudentsJournal.CourseUser>>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/users`, searchParams, {...WITH_AUTH, keep_errors: true});

};

export const openModules = (courseId: number, userIds: string[], modules: number[], all = false): Promise<ReqResponse<{}>> => {

    const body = {
        courseId,
        userIds,
        modules,
        all,
    };

    return AjaxWrapper.post(`${LAB_API_URL}/admin/open-modules`, body, WITH_AUTH);


};

export const revokeCourseGrant = (courseId: number, userIds: string[]): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/grant/revoke`, {users: userIds}, WITH_AUTH);

};

export const downloadCourseUsersCsv = (courseId: number, payload: ResponsiveTableByBackend.Data<any>['state'] & { users?: string[] }): Promise<ReqResponse<LabNoo.Course.UsersTableResponseSuccessObject>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/users/csv`, payload, {...WITH_AUTH, keep_errors: true});

};

export const getResultsJournalTable = (courseId: number, moduleId: number, sp: SearchParams): Promise<ReqResponse<ResponsiveTableByBackend.Data<ResultsJournal.ModuleResults>>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/progress_report/${moduleId}`, sp, {...WITH_AUTH, keep_errors: true});

};

// Add new types to UNION when needed
export type AttributeTypes = "user";
export type AttributeObjects = "course" | "user-control";

export const setAttribute = (objectName: string, objectId: string, attr: string, ids: Array<string | number>, type: AttributeTypes): Promise<ReqResponse<ResponsiveTableByBackend.Data<ResultsJournal.ModuleResults>>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/api/v2/attributes/tag/${objectName}/${objectId}/${attr}`, {
        type,
        ids,
    }, WITH_AUTH);

};

export const unsetAttribute = (type: AttributeTypes, attributes: string[]): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.delete(`${LAB_API_URL}/api/v2/attributes/tag/${type}`, {
        attributes,
    }, WITH_AUTH);

};

export const delAttributeEntities = (objectName: string, objectId: string, attr: string, ids: Array<string | number>, type: AttributeTypes): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.delete(`${LAB_API_URL}/api/v2/attributes/tag/${objectName}/${objectId}/${attr}`, {
        type,
        ids,
    }, WITH_AUTH);

};

// Add new types to UNION when needed
export type SearchEntityTypes = "user";

export type UserEntitySearchData = {
    id: string;
    name: string;
    title: string;
};
export type UserEntitySearchRes = {
    data: UserEntitySearchData[];
    hasMore: boolean;
};
export const entitySearch = (entityType: SearchEntityTypes, search: string, limit = 20): Promise<ReqResponse<UserEntitySearchRes>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/api/v2/attributes/tag/${entityType}/search`, {
        search,
        limit,
    }, WITH_AUTH);

};

type CoursePermissions = {
    data: UserPermissions;
    form: PermissionsForm[];
};

export const getCoursePermission = (courseId: string): Promise<ReqResponse<CoursePermissions>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}/permission/v2`, {}, WITH_AUTH);

};

export const patchCourseAddPermission = (courseId: string, data: UserPermissionsPatch): Promise<ReqResponse<{users: {permission: "write" | "read"; user: string}[]}>> => {

    return AjaxWrapper.patch(`${LAB_API_URL}/course/${courseId}/permission`, data, WITH_AUTH);

};

export const getCourseInfo = (courseId: string): Promise<Course> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}`, {}, WITH_AUTH);

};

export const getCourseToc = (courseId: string, data: LabNoo.Course.CourseTOCRequest): Promise<LabNoo.Course.CourseTOCResponse> =>
    AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/toc/getTable`, data, {...WITH_AUTH, keep_errors: true})
;

export const getCourseTocGroupingElement = (courseId: string, groupId: string): Promise<LabNoo.Course.TOC.GetGroupingElementInfoResponse> =>
    AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}/toc/getGroupingElementInfo/${groupId}`, {}, WITH_AUTH)
;

export const addCourseTocGroupingElement = (courseId: string, data: LabNoo.Course.TOC.AddGroupingElementRequest): Promise<LabNoo.Course.TOC.AddGroupingElementResponse> =>
    AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/toc/addGroupingElement`, data, WITH_AUTH)
;

export const updateCourseTocGroupingElement = (courseId: string, data: LabNoo.Course.TOC.UpdateGroupingElementRequest): Promise<LabNoo.Course.TOC.UpdateGroupingElementResponse> =>
    AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/toc/updateGroupingElement`, data, WITH_AUTH)
;

export const deleteCourseTocGroupingElement = (courseId: string, elementId: string): Promise<LabNoo.Course.TOC.DeleteGroupingElementResponse> =>
    AjaxWrapper.delete(`${LAB_API_URL}/course/${courseId}/toc/deleteGroupingElement/${elementId}`, {}, WITH_AUTH)
;

export const moveTocElement = (courseId: string, data: LabNoo.Course.TOC.MoveTOCElementsRequest): Promise<LabNoo.Course.CourseTOCResponse> =>
    AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/toc/move`, data, WITH_AUTH)
;

export const deleteModuleFromCourse = (courseId: string, moduleId: string): Promise<ReqResponse<LabNoo.Course.TOC.Unit>> =>
    AjaxWrapper.delete(`${LAB_API_URL}/course/${courseId}/module/${moduleId}`, {}, WITH_AUTH)
;

export const deleteCourseTOCModules = (courseId: string, data: LabNoo.Course.TOC.DeleteTOCElementsRequest): Promise<LabNoo.Course.TOC.DeleteTOCElementsResponse> =>
    AjaxWrapper.delete(`${LAB_API_URL}/course/${courseId}/toc/mass-delete`, data, WITH_AUTH)
;

export const putModulesToCourse = (courseId: string, data: LabNoo.Course.Module.CourseModulePutRequest): Promise<ReqResponse<LabNoo.Course.Module.CourseModulePutResponseSuccess>> =>
    AjaxWrapper.put(
    `${LAB_API_URL}/course/${courseId}/modules`, data, {
        ...WITH_AUTH,
        keep_errors: true,
    }
);

export const prepareModulesToPaste = (courseId: string, data: LabNoo.Course.Module.CourseModulePrepareRequest): Promise<LabNoo.Course.Module.CourseModulePrepareResponse> =>
    AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/modules/prepare`, data, {...WITH_AUTH, keep_errors: true})
;

export const publishCourse = (courseId: string, update_progress = false): Promise<ReqResponse<LabNoo.Course.CourseVisualizeResponse>> =>
    AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}/visualize${update_progress ? '?update_progress=true' : ''}`, {}, WITH_AUTH)
;

export const patchCoursePermission = (courseId: string, userId: string, objectId: string, data: PatchUserPermissionsPayload): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.patch(`${LAB_API_URL}/course/${courseId}/permission/${userId}/${objectId}`, data, WITH_AUTH);

};

export const addUsersInGroup = (groupName: string, users: string[]): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/user/group/${groupName}`, {users}, WITH_AUTH);

};


type UsersGroupsRes = {
    name: string;
    id: string;
    users_count: number;
}[];
export const getUsersGroupsList = (courseId: string): Promise<ReqResponse<UsersGroupsRes>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/${courseId}/user-groups`, {}, WITH_AUTH);

};

export const downloadCourseUsersResultsJournalCsv = (courseId: number, moduleId: number, payload: ResponsiveTableByBackend.Data<any>['state'] & { users?: string[] }): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/${courseId}/progress_report/csv/${moduleId}`, payload, WITH_AUTH);

};

export const getConfig = (): Promise<LabNoo.ConfigResponse> => {

    return AjaxWrapper.get(`${LAB_API_URL}/config`)
};

export const getTableConfig = (url: string): Promise<ReqResponse<ResponsiveTableByBackend.TableConfig>> => {

    return AjaxWrapper.get(`${url}/info`, {}, {...WITH_AUTH, keep_errors: true});

};

export const patchTableConfig = (url: string, patch: ResponsiveTableByBackend.TableConfig): Promise<ReqResponse<ResponsiveTableByBackend.TableConfig>> => {

    return AjaxWrapper.patch(`${url}/info`, [patch], {...WITH_AUTH, keep_errors: true});

};

export const createCourse = (courseData: Course): Promise<ReqResponse<Course>> => {

    return AjaxWrapper.put(`${LAB_API_URL}/course/v2`, courseData, WITH_AUTH);

};


export type AccessibleCourse = {
    openDate: string;
    closeDate: string;
    bgColor: string;
    isOpen: boolean;
    lecturesCount: number;
    isPublic: boolean;
    tasksCount: number;
    modulesCount: number;
    name: string;
    id: number;
};

export const getAccessibleCourses = (): Promise<AccessibleCourse[]> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/getAccessibleCourses`, {}, {...WITH_AUTH});

};

export const getAccessibleCoursesTable = (data: LabNoo.Course.CoursesTableRequest): Promise<ReqResponse<LabNoo.Course.Table>> => {

    return AjaxWrapper.post(`${LAB_API_URL}/course/getAccessibleCoursesTable`, data, {...WITH_AUTH});

};

export const getUnansweredQuestions = (): Promise<Array<{id: number; value: number}>> => {

    return AjaxWrapper.get(`${LAB_API_URL}/course/qa/unanswered`, {}, {...WITH_AUTH});

};

export const patchAnswerQuestion = (courseId: string, qaId: string, data: LabNoo.Course.QA.QaAnwer) =>
    AjaxWrapper.patch(
        `${LAB_API_URL}/course/${courseId}/qa/${qaId}/answer`,
        data,
        {...WITH_AUTH}
    )
;

export const duplicateCourse = (): Promise<LabNoo.Course.CourseCloneResponse> => {

    return AjaxWrapper.put(`${LAB_API_URL}/course/clone`, {}, {...WITH_AUTH});

};

export const duplicateCourseById = (courseId: string): Promise<LabNoo.Course.CourseCloneResponse> => {

    return AjaxWrapper.put(`${LAB_API_URL}/course/${courseId}/clone`, {}, {...WITH_AUTH});

};

// export const getImgKnowledge = (hash: string) => {
//
//     return AjaxWrapper.get(`${LAB_API_URL}/content/_image/${hash}`, {}, {...WITH_AUTH});
//
// };

export const downloadIngeniousSolutionsListCsv = (): void => {

    AjaxWrapper.get(`${LAB_API_URL}/course/ingenious-solution-list`, {}, {...WITH_AUTH})
        .then((res) => {
            if (res) {

                Files.download(res, `solutions.csv`, 'text/csv');

            }
    });

};

export const uploadTaskFromEjudge = (contestId: number, data: File): Promise<ReqResponse<LabSmt.TaskListTable>> => {
    let formData = new FormData();

    formData.append('file', data);

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/import/tasks`,
        formData,
        {
            headers: {
                'Accept'      : 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            send_auth_token: true,
            keep_errors: true
        }
    )
};

export const uploadTaskFromLink = (contestId: number, data: LabDownloader.DownloaderJobRequest): Promise<LabDownloader.DownloaderJobResponse> => {

    return AjaxWrapper.post(`${SMT_API}/job/import-task/${contestId}`, data, {send_auth_token: true, keep_errors: true})
};

export const getFileInfo = (course_id: number, module_id: number, task_id: number, user_id: number, hash: string): Promise<ReqResponse<{ info: FileInfo }>> => {
     return AjaxWrapper.post(`${LAB_API_URL}/course/${course_id}/module/${module_id}/task/${task_id}/${user_id}/solution/file-info/${hash}`, {}, {...WITH_AUTH})
};

export interface IGetProgrammingAnswerTestResultsReport {
    courseId:       strNumber,
    moduleId:       strNumber,
    taskVersionId:  strNumber,
    submissionId:   strNumber,
    userId:         strNumber,
}

export type TGetProgrammingAnswerTestResultsReportReturn = Promise<LabNoo.Course.Module.Submission.TestsTableResponse>;

export const getProgrammingAnswerTestResultsReport = (
        {
            courseId,
            moduleId,
            taskVersionId,
            submissionId,
            userId
        }: IGetProgrammingAnswerTestResultsReport
    ): TGetProgrammingAnswerTestResultsReportReturn =>
        AjaxWrapper.get(
            `${LAB_API_URL}/course/${courseId}/module/${moduleId}/task/${taskVersionId}/user/${userId}/submission/${submissionId}/test-report`,
            {},
            {...WITH_AUTH}
        )
;

export interface IGetProgrammingAnswerTestResultsProtocol extends IGetProgrammingAnswerTestResultsReport {
    testNo:          strNumber
}

export type TGetProgrammingAnswerTestResultsProtocolReturn = Promise<LabNoo.Course.Module.Submission.TestProtocolResponse>;

export const getProgrammingAnswerTestResultsProtocol = (
        {
            courseId,
            moduleId,
            taskVersionId,
            submissionId,
            userId,
            testNo
        }: IGetProgrammingAnswerTestResultsProtocol
    ): TGetProgrammingAnswerTestResultsProtocolReturn =>
        AjaxWrapper.get(
            `${LAB_API_URL}/course/${courseId}/module/${moduleId}/task/${taskVersionId}/user/${userId}/submission/${submissionId}/test-report/protocol/${testNo}`,
            {},
            {...WITH_AUTH}
        )
;

export const uploadToken = (contestId: number | string): Promise<ReqResponse<LabSmt.Contest.AnswersTable.UploadTokenResponseSuccess>> => {
    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/answers-table/upload-token/`, {}, {...WITH_AUTH});
};

type UploadFileResponse = {
    hash: string;
    info: {
        contentType: string;
        name:        string;
        preview:     string;
        token:       string;
    }
};

export const uploadFile = (token: string, file: File): Promise<ReqResponse<UploadFileResponse>> => {
    let formData = new FormData();

    formData.append('file', file);
    formData.append('token', token);

    return AjaxWrapper.post(CONFIG.Api.storage.url + '/upload', formData, {
        headers: {
            'Accept': 'application/json;charset=utf-8',
            'Content-Type': 'multipart/form-data'
        },
    });
};

export const downloadFileInfo = (contestId: number | string, hash: string): Promise<ReqResponse<LabSmt.Contest.AnswersTable.DownloadTokenResponseSuccess>> => {
    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/answers-table/download-token/${hash}`, {}, {send_auth_token: true, keep_errors: true} );
};

export const saveAnswersTableReview = (contestId: number | string, taskId: number | string, inputId: number | string = 1, answerId: number, data: LabSmt.Contest.AnswersTable.ReviewPutRequest): Promise<ReqResponse<LabSmt.Contest.AnswersTable.ReviewGetResponseSuccessNull | LabSmt.Contest.AnswersTable.ReviewGetResponseSuccessData>> => {
    return AjaxWrapper.put(`${SMT_API}/contest/${contestId}/answers-table/${taskId}/${inputId}/${answerId}/review`, data, {send_auth_token: true, keep_errors: true});
};

export const getCourseAccessTestingInfo = (
    {courseId}: {courseId: strNumber}
): Promise<LabNoo.Course.CourseContestsResponse> =>
    AjaxWrapper.get(
        `${LAB_API_URL}/course/${courseId}/contests`,
        {},
        {...WITH_AUTH}
    )
;

interface PostCourseAccessTestingUserInviteParams {
    courseId: strNumber;
    contestId: strNumber;
    data:  LabNoo.Course.InviteCourseUsersToContestRequest;
}
export const postCourseAccessTestingUserInvite = (
        {
            courseId,
            contestId,
            data,
        }: PostCourseAccessTestingUserInviteParams
    ): Promise<LabNoo.Course.CourseContestsResponse> =>
        AjaxWrapper.post(
            `${LAB_API_URL}/course/${courseId}/contest/${contestId}/users/invite`,
            {...data},
            {...WITH_AUTH}
        )
;

export const getUserConfig = (): Promise<LabNoo.Config.ConfigResponse> =>
    AjaxWrapper.get(
        `${LAB_API_URL}/api/v2/config`,
        {},
        {...WITH_AUTH}
    )
;


export const putUserConfig = (config: LabNoo.Config.UserConfig): Promise<LabNoo.Config.ConfigResponse> =>
    AjaxWrapper.put(
        `${LAB_API_URL}/api/v2/config`,
        config,
        {...WITH_AUTH}
    )
;

interface LabNoo_POST_module__load_settings_formParams {
    courseId: strNumber,
    data: LabNoo.Course.Module.SettingsForm.ModulesSettingsLoadFormRequest
}

export const LabNoo_POST_module__load_settings_form = (
        {
            courseId,
            data,
        }: LabNoo_POST_module__load_settings_formParams
    ): Promise<LabNoo.Course.Module.SettingsForm.ModulesSettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_API_URL}/course/${courseId}/module/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

interface LabNoo_POST_module__save_settings_formParams {
    courseId: strNumber,
    data: LabNoo.Course.Module.SettingsForm.ModulesSettingsSaveFormRequest
}

export const LabNoo_POST_module__save_settings_form = (
        {
            courseId,
            data,
        }: LabNoo_POST_module__save_settings_formParams
    ): Promise<LabNoo.Course.Module.SettingsForm.ModulesSettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_API_URL}/course/${courseId}/module/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

interface LabNoo_POST_module_entity__settings_formParams {
    courseId: strNumber,
    moduleId: strNumber,
    data: LabNoo.Course.Module.Entity.EntitySettingsLoadFormRequest
}

export const LabNoo_POST_module_entity__load_settings_form = (
        {
            courseId,
            moduleId,
            data,
        }: LabNoo_POST_module_entity__settings_formParams
    ): Promise<LabNoo.Course.Module.Entity.EntitySettingsLoadFormResponse> =>

        AjaxWrapper.post(
            `${LAB_API_URL}/course/${courseId}/module/${moduleId}/entity/load-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const LabNoo_POST_module_entity__save_settings_form = (
        {
            courseId,
            moduleId,
            data,
        }: LabNoo_POST_module_entity__settings_formParams
    ): Promise<LabNoo.Course.Module.Entity.EntitySettingsSaveFormResponse> =>

        AjaxWrapper.post(
            `${LAB_API_URL}/course/${courseId}/module/${moduleId}/entity/save-settings-form`,
            data,
            {...WITH_AUTH},
        )
;
