import React, {FunctionComponent} from "react";
import BemClassName from "Cheops/BemClassName";
import "./ModalWindowFooter.less";


interface Props {
    children?: React.ReactNode;
    className?: string;
}


const ModalWindowFooter: FunctionComponent<Props> = ({children, className}) => {

    const modalWindowFooterClass = new BemClassName('modal_window__footer', null, className);
    return <div className={modalWindowFooterClass.toString()}>
        {children}
    </div>;

};


export default ModalWindowFooter;
