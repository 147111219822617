import React from "react";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";


import "./FilePreview.less";

export default class FilePreview extends React.Component {

    static defaultProps = {
        hash: {},
        file_info: null,
        allow_delete: false,
        course_id: null,
        module_id: null,
        user_id: null,
        element_type: null,
        element_id: null,
        getFileInfo: null
    };

    constructor(props) {

        super(props);

        this.state = {
            file_info: props.file_info,
        };

    }

    async componentDidMount() {

        if (!this.state.file_info) {

            this.getFileInfo();

        }

    }

    getFileInfo = async (is_thread) => {

        const {getFileInfo} = this.props;

        let file_info_success;

        if (is_thread) {

            file_info_success = (await noo_actions.getFileInfoThread(this.props.course_id, this.props.module_id, this.props.element_type, this.props.element_id, this.props.hash)).success;

        } else {

            file_info_success = getFileInfo
                ? (await getFileInfo(this.props.course_id, this.props.module_id, this.props.element_id,this.props.user_id, this.props.hash)).success
                : (await noo_actions.getFileInfo(this.props.course_id, this.props.module_id, this.props.element_id, this.props.hash)).success

        }

        if (file_info_success) {

            this.setState({file_info: file_info_success.info});

        }

    };

    render() {

        if (!this.state.file_info) {

            return (
                <div className="file_preview">
                    <CircularProgress marginCenter />
                </div>
            );

        }

        let preview_url = null;

        let download_url = `${CONFIG.Api.storage.url}/download/${this.state.file_info.token}`;

        let file_ext = "file";

        if (this.state.file_info.preview) {

            preview_url = `${CONFIG.Api.storage.url}/download/${this.state.file_info.preview}`;

        } else {

            let file_name = this.state.file_info.name;

            if (file_name.includes(".")) {

                file_ext = this.state.file_info.name.split(".").pop();

                if (file_ext.length > 4) {

                    file_ext = "file";

                }

            }

        }


        return <a className="file_preview" download={this.state.file_info.name} href={download_url} target="_blank">
            {preview_url && <img alt="" src={preview_url} />}
            {!preview_url && <div className="file_preview__file_ext">
                ...
                {file_ext}
            </div>}
            {this.props.allow_delete
            && <div className="file_preview__close" onClick={(e) => {

                e.preventDefault();

            }}
            />}
        </a>;

    }

}
