import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";
import {SvgIcon} from "@material-ui/core";

const PublishResultsIcon: React.FC<CustomIconsProps> = ({className}) =>
    <SvgIcon className={className}>
        <svg width="22" height="22" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49967 0.5H17.4997C18.4163 0.5 19.1663 1.25 19.1663 2.16667V13.8333C19.1663 14.75 18.4163 15.5 17.4997 15.5H12.4997C12.0413 15.5 11.6663 15.125 11.6663 14.6667C11.6663 14.2083 12.0413 13.8333 12.4997 13.8333H16.6663C17.1247 13.8333 17.4997 13.4583 17.4997 13V3C17.4997 2.54167 17.1247 2.16667 16.6663 2.16667H3.33301C2.87467 2.16667 2.49967 2.54167 2.49967 3V3.83333C2.49967 4.29167 2.12467 4.66667 1.66634 4.66667C1.20801 4.66667 0.833008 4.29167 0.833008 3.83333V2.16667C0.833008 1.25 1.58301 0.5 2.49967 0.5Z" fill="#999999"/>
            <path d="M0.833008 10.575C0.833008 10.0667 1.28301 9.65833 1.78301 9.74167C4.24134 10.1417 6.19134 12.0833 6.59134 14.55C6.66634 15.05 6.26634 15.5 5.75801 15.5C5.34967 15.5 5.00801 15.2 4.94134 14.7917C4.64134 13.0583 3.27467 11.6917 1.54134 11.3917C1.13301 11.325 0.833008 10.9833 0.833008 10.575Z" fill="#999999"/>
            <path d="M0.833008 15.5V13C2.21634 13 3.33301 14.1167 3.33301 15.5H0.833008Z" fill="#999999"/>
            <path d="M0.833008 7.20833C0.833008 6.71667 1.25801 6.33333 1.74967 6.375C6.07467 6.80833 9.51634 10.2417 9.95801 14.5667C10.008 15.0583 9.62467 15.4917 9.12467 15.4917C8.69967 15.4917 8.34134 15.1833 8.29967 14.7667C7.95801 11.2083 5.12467 8.375 1.56634 8.03333C1.14967 7.99167 0.833008 7.63333 0.833008 7.20833Z" fill="#999999"/>
            <path d="M13.6455 6.75C13.6872 6.75 13.7358 6.73611 13.7913 6.70833L16.4997 5.14583C16.5552 5.11806 16.5969 5.07986 16.6247 5.03125C16.6525 4.98264 16.6663 4.93056 16.6663 4.875C16.6663 4.81944 16.6525 4.76736 16.6247 4.71875C16.5969 4.67014 16.5552 4.63194 16.4997 4.60417L13.7913 3.04167L13.6455 3C13.6038 3 13.5552 3.01389 13.4997 3.04167L10.7913 4.60417C10.7358 4.63194 10.6941 4.67014 10.6663 4.71875C10.6386 4.76736 10.6247 4.81944 10.6247 4.875C10.6247 4.93056 10.6386 4.98264 10.6663 5.03125C10.6941 5.07986 10.7358 5.11806 10.7913 5.14583L13.4997 6.70833L13.6455 6.75Z" fill="#999999"/>
            <path d="M13.6455 9.22917C13.7011 9.22917 13.7531 9.22222 13.8018 9.20833C13.8504 9.19444 13.9025 9.17361 13.958 9.14583L15.6247 8.1875C15.7219 8.13195 15.7983 8.05555 15.8538 7.95833C15.9094 7.86111 15.9372 7.75694 15.9372 7.64583V6.25L13.6455 7.58333L11.3538 6.25V7.64583C11.3538 7.75694 11.3816 7.86111 11.4372 7.95833C11.4927 8.05555 11.5691 8.13195 11.6663 8.1875L13.333 9.14583C13.3886 9.17361 13.4406 9.19444 13.4893 9.20833C13.5379 9.22222 13.59 9.22917 13.6455 9.22917Z" fill="#999999"/>
        </svg>
    </SvgIcon>
;

export default PublishResultsIcon;
