import AjaxWrapper from "Smt/AjaxWrapper";
import {setContestLoginData} from "Smt/helpers";

export default class ContestAuth {


    static sendToFallback(referer: string): void {

        let refUrl;

        if (referer) {

            refUrl = new URL(referer);

        }

        if (!refUrl || refUrl.origin === location.origin) {

            location.href = CONFIG.Auth.fallbackUrl;
            return;

        }

        location.href = referer;

    }

    static async authCallback(): Promise<any> {

        const searchParamsStr = location.hash.split("?")[1];

        if (!searchParamsStr) {

            location.href = location.origin;

        }

        const searchParams = new URLSearchParams(searchParamsStr);

        let code = searchParams.get('code');
        let state = searchParams.get('state');
        let referer = searchParams.get('referer');

        if (!code || !state) {

            this.sendToFallback(referer);
            return null;

        }

        const _response = await AjaxWrapper.get(`${CONFIG.Api.webPortal.url}/auth/callback`, {
            code,
            state,
        });

        const response = _response?.success || null;

        if (!response) {

            this.sendToFallback(referer);
            throw new Error("Unsuccessful authorize");

        }


        setContestLoginData(response.tsContestId, response.tsUser.userId, response.userToken);

        return response;

    }

    // Depending on the reply from the server this function can either never return and
    // redirect the user to the auth page, or return the contest info, just like authCallback() do.
    // Basically this function can do a shortcut in case if we already know the user.
    static async auth(): Promise<any> {

        let contestId = location.hash.substr(10);

        let token = localStorage.getItem('user_token');

        // If we do not have user_token (we can't run a shortcut, then we use old approach
        // and get redirect URL from the server.
        if (!token) {
            const _targetLocation = await AjaxWrapper.get(`${CONFIG.Api.webPortal.url}/auth/contest/${contestId}`);
            const targetLocation = _targetLocation?.success || null;

            this.redirectUser(targetLocation);
        } else {
            const _response = await AjaxWrapper.post(`${CONFIG.Api.webPortal.url}/auth/contest/${contestId}`, {token});
            if (_response.success) {
                const response = _response?.success || null;

                if (!response) {

                    this.sendToFallback(document.referrer);
                    throw new Error("Unsuccessful authorize");
                }

                setContestLoginData(response.tsContestId, response.tsUser.userId, response.userToken);
                return response;

            } else if (_response.redirect) {

                const targetLocation = _response?.redirect || null;
                this.redirectUser(targetLocation);

            } else {
                this.sendToFallback(document.referrer);
            }
        }

    }

    private static redirectUser(targetLocation?: string) {
        if (!targetLocation) {

            this.sendToFallback(document.referrer);
            return;

        }

        const refererParams = encodeURIComponent(document.referrer);

        const searchParams = encodeURIComponent(`${location.origin}${location.pathname}#/auth_callback?referer=${refererParams}`);

        location.href = `${targetLocation}&redirect_uri=${searchParams}`;
    }


}
