/* eslint-disable */
import React                        from "react";
import ReactDOM                     from "react-dom";
import { take, skip }               from "rxjs/operators";
import svg4everybody                from "svg4everybody";
import cloneDeep                    from "lodash/cloneDeep";
import isEqual                      from "lodash/isEqual";
import bnc                          from "bnc";

import LangSwitcher                 from "@sirius/ui-lib/src/blocks/LangSwitcher/LangSwitcher";
import {CircularProgress}           from "@sirius/ui-lib/src/blocks/CircularProgress";
import {Locale$}                    from "@sirius/ui-lib/src/subjects/Locale";
import {windowScroll$}              from "@sirius/ui-lib/src/subjects/WindowScroll";
import {windowResize$}              from "@sirius/ui-lib/src/subjects/WindowResize";
import {windowBeforeUnload$}        from "@sirius/ui-lib/src/subjects/WindowBeforeUnload";
import {HBox, SGap, VBox}           from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                     from "@sirius/ui-lib/src/blocks/Controls/Button";

import BemClassName                 from "Cheops/BemClassName";
import {TaskStates}                 from "Cheops/Model/Task";

import {
    getUserNameByJWT,
    isOnlineByTaskNum,
    isHiddenByJWT,
    checkTableByJWT,
    checkIsOnlineByJWT,
    usePollingSubject,
    parseInitPendingSolutions,
    newPendingToPolling,
    addIdToNewPending,
    deletePending,
    getTaskOnlineInfoByJWT,
    pollOutOfLoop
}                                   from "Smt/subjects/ContestOnline";
import {SmtLocaleString}            from "Smt/SmtLocaleBlock";
import Task                         from "Smt/TaskPage/Task/Task";
import TaskSelector                 from "Smt/TaskPage/Task/TaskSelector";
import {renderErrorPage}            from "Smt/helpers";
import AjaxWrapper, {RequestError}  from "Smt/AjaxWrapper";
import Result                       from "Smt/TaskPage/Task/Result";
import TimeoutPopup                 from "Smt/TaskPage/Task/TimeoutPopup";
import UserProfileConfirmationModal from "Smt/IndexPage/UserProfileConfirmationModal";
import {StandingsPage}              from "Smt/StandingsPage";
import Countdown                    from "Smt/TaskPage/Task/Countdown";
import OversizeErrorPopup           from "Smt/TaskPage/Task/OversizeErrorPopup/OversizeErrorPopup";
import {
    contestOnlinePutReviewsAsyncLock
}                                   from "Smt/TaskPage/contestOnlinePutReviewsAsyncLock";
import {
    getTaskDataState,
    setTaskDataState
}                                   from "Smt/TaskPage/Task/TaskDataSubject";
import {CloseModal}                 from "Smt/TaskPage/Task/CloseModal";
import {GET_TasksByToken}           from "Smt/actions/smtCache";
import Section                      from "./Section";
import                                   "./t-task-layout.less";
import                                   "./t-online-tabs.less";




svg4everybody({});

const block = new bnc("task_page");

const randomMixer = () => 0.5 - Math.random();

const hasAnswer   = (task) => task.some(({solution}) => solution !== null && solution !== void(0));

const countAnswers = (tasks) => Object.values(tasks)
                                      .filter((task) => task !== void(0))
                                      .reduce((count, task) => count + (hasAnswer(task) ? 1 : 0), 0)
;


const countProgAnswers = (solutions) => new Set(solutions.filter((item) => item.id).map(({taskId}) => taskId)).size;

export default class TaskPage extends React.Component {

    constructor(props) {
        super(props);

        const {tsContest, tasks_token, tasks, results_token} = props;
        const [range]           = this.getSectionRanges(tsContest.sections, tasks);
        const selected_task_num = range.name ? null : 0;
        const selectedSection   = range.name ? 0    : null;

        window.bugReportToken = tasks_token;
        window.results_token  = results_token;

        this.state = {
            isSavingInProgress:       [],
            answersIsFilled:          [],
            answersHasError:          [],
            stateHash:                null,
            hasPrevTask:              false,
            selectedSection,
            selected_task_num,
            isLoaded:                 false,
            show_result:              false,
            saved_tasks:              {},
            cachedTasks:              {},
            timeoutPopupState:       'hidden',
            user_answers:             {},
            show_right_answer:        false,
            onlineState:              null,
            isOnline:                 false,
            oversizeErrorPopupOpened: false,
            resendError:              false,
            showCloseModal:           false,
            sectionsWRange:           [],
        };

    }


    receiveTasks = (result) => {
        const {solutions, hash: stateHash}    = result;
        const {tasks_token, tsContest, tasks} = this.props;
        let user_answers                      = {};
        let answersIsFilled                   = [];
        let saved_tasks                       = {};
        let selected_task_num                 = 0;
        let show_result                       = false;
        const tasksCount                      = this.getTasksCount(tasks);

        for (let task_num in solutions) {
            let solution = solutions[task_num];
            if (this.getAnswersIsFilled(solution)) {
                const solutionIndex = parseInt(task_num) - 1;
                user_answers[solutionIndex] = cloneDeep(solution);
                saved_tasks [solutionIndex] = solution;
                answersIsFilled.push(solutionIndex);
            }
        }

        for (let task_num = 0; task_num < tasksCount; task_num++) {
            if (!saved_tasks[task_num]) {
                selected_task_num = this.getFirstTaskNumInGroup(task_num);
                break;
            }
        }

        if (tasksCount === Object.keys(saved_tasks).length) {
            selected_task_num = -1;
            show_result = true
        }

        const selectedSectionObj = tsContest.sections.find( ({at}) => at - 1 === selected_task_num );
        let selectedSection = null;

        if (selectedSectionObj) {
            selected_task_num = -1;
            selectedSection = selectedSectionObj.at - 1;
        }

        const isTable   = checkTableByJWT(tasks_token);
        const userName  = getUserNameByJWT(tasks_token);
        const isOnline  = checkIsOnlineByJWT(tasks_token);
        let onlineTab;
        if (isOnline) {
            onlineTab = "tasks"; // standings
            const { pending, solutions } = parseInitPendingSolutions(result);
            this.Polling$ = usePollingSubject(
                {
                    bearer: tasks_token,
                    initPolling: { pending, solutions }
                }
            );
            this.subscriptions.push(
                this.Polling$.subscribe(this.readOnlinePolling)
            );
        }

        const sectionsWRange = this.getSectionRanges(tsContest.sections, tasks);

        const next = {
            isLoaded: true,
            stateHash,
            selectedSection,
            show_result,
            saved_tasks,
            user_answers,
            selected_task_num,
            answersIsFilled,
            isOnline,
            onlineTab,
            isTable,
            userName,
            sectionsWRange
        }

        return next;

    }

    changeLocale = (locale) => {
        this.setState({locale});
    }

    async componentDidMount() {
        return new Promise(
            (resolve) => {
                const {tasks_token, results_data, tsContest, tasks} = this.props;
                this.subscriptions = [
                    Locale$.subscribe(this.changeLocale),
                    windowScroll$.subscribe(this.attachPrevNextButtons),
                    windowResize$.subscribe(this.attachPrevNextButtons),
                ];

                this.closeConfirmationSubscription = windowBeforeUnload$.subscribe(this.showCloseConfirmation);

                if (results_data) {
                    this.setState(
                        {isLoaded: true},
                        resolve
                    );
                } else {
                    GET_TasksByToken(tasks_token)
                        .then(this.receiveTasks)
                        .then( next => this.setState(next, resolve) )
                    ;
                }
            }
        )
    };

    componentWillUnmount() {
        this.subscriptions.forEach(
            (s) => s.unsubscribe(),
        );
        this.closeConfirmationSubscription && this.closeConfirmationSubscription.unsubscribe();
    }

    setOnlineTabTasks = () => {
        this.setState({onlineTab:"tasks"});
    }

    setOnlineTabStandings = () => {
        this.setState({onlineTab:"standings"});
    }


    componentDidUpdate() {
        const {tasks} = this.props;
        if (this.state.timeoutPopupState === 'show' || this.state.oversizeErrorPopupOpened) {
            document.body.classList.add('no_scrollable');
        } else {
            document.body.classList.remove('no_scrollable');
        }

        setTimeout(this.attachPrevNextButtons, 300);

        const group = this.getSelectedGroup();
        if (group) {
            for (const taskNum of Object.keys(group)) {
                if (!this.state.cachedTasks[taskNum]) {
                    this.loadTaskContent(taskNum);
                }
            }
        }
        setTaskDataState({...this.state, tasks});
    };

    showCloseConfirmation = (e) => {
       if (this.state.timeoutPopupState === 'hidden' && this.hasNotSavedTasks()) {
           e.preventDefault();
           e.returnValue = true;
       }
    }

    attachPrevNextButtons() {
        let next_button = document.querySelector('.next-task');
        let prev_button = document.querySelector('.prev-task');

        if (window.matchMedia('(min-width: 769px)').matches) {
            next_button && (next_button.style.top = "");
            prev_button && (prev_button.style.top = "");
            return;
        }

        let last_task = document.querySelector('.task:last-child');

        if (!last_task) {
            return;
        }

        let client_rect = last_task.getBoundingClientRect();
        let last_tak_y = client_rect.bottom + window.pageYOffset;

        if (last_tak_y < window.pageYOffset + window.innerHeight - 90) {
            next_button && next_button.classList.add('attached');
            prev_button && prev_button.classList.add('attached');
            next_button && (next_button.style.top = last_task.offsetTop + last_task.clientHeight + "px");
            prev_button && (prev_button.style.top = last_task.offsetTop + last_task.clientHeight + "px");
        } else {
            next_button && next_button.classList.remove('attached');
            prev_button && prev_button.classList.remove('attached');
            next_button && (next_button.style.top = "");
            prev_button && (prev_button.style.top = "");
        }
    }

    getSectionRanges(sections, tasks) {

        let tasksCount = 0;

        for (const task of Object.values(tasks)) {

            if (typeof task === 'object') {

                tasksCount += Object.keys(task).length;

            } else {

                tasksCount += 1;

            }

        }

        if (sections.length === 0) {
            return [{from: 0, to: tasksCount - 1}];
        }

        let reversed_sections = [...sections].reverse();

        const sectionRanges = [];

        let endOfSection = tasksCount - 1;

        reversed_sections.map(section => {

            sectionRanges.unshift({
                from: section.at - 1,
                to: endOfSection,
                ...section,
            });

            endOfSection = section.at - 2;
        });


        if (sectionRanges.length === 0 || sectionRanges[0].from > 0) {
            sectionRanges.unshift({
                from: 0,
                to: endOfSection,
            });
        }

        return sectionRanges;
    }

    readOnlinePolling = ({solutions, pending, tasksTimers, runningPoll, pollingError}) => {

        if (pollingError) {
            const {type, status} = pollingError;
            ['offline', 'response'].includes(type) &&
            ReactDOM.render(
                <UserProfileConfirmationModal isExceptionAction
                                              exceptionTitleKey   = {`common.profile_confirmation.exception_${status}_title`}
                                              exceptionCaptionKey = {`common.profile_confirmation.exception_${status}_caption`}
                />,
                document.getElementById("index")
            );

        } else {

            const onlineState = {solutions, pending, tasksTimers, runningPoll};
            const {isSavingInProgress} = this.state;

            const hasPending = pending.some((item) => isSavingInProgress.includes((item.taskId - 1).toString()));

            const newState = isSavingInProgress.length && !hasPending
                ? {onlineState, isSavingInProgress: []}
                : {onlineState}
            ;

            this.setState( newState );
        }
    }

    contentOnLoading = [];
    subscriptions = null;

    isAllTasksLoaded() {
        const group = this.getSelectedGroup();
        if (!group) {
            return true;
        }
        const {cachedTasks} = this.state;
        for (const taskNum of Object.keys(group)) {
            if (!cachedTasks[taskNum]) {
                return false;
            }
        }
        return this.state.isLoaded;
    }

    loadTaskContent(taskNum) {
        return new Promise(async (done) => {
            const {cachedTasks} = this.state;
            const taskId = this.getTaskHash(taskNum);

            if (cachedTasks[taskNum]) {
                done();
                return;
            }

            if (this.contentOnLoading.includes(taskId)) {
                done();
                return;
            }

            this.contentOnLoading.push(taskId);

            const taskInfo       = await AjaxWrapper.get(`${CONFIG.Api.webPortal.url}/content/${encodeURIComponent(taskId)}`);

            if ((taskInfo.type.includes('multi') || taskInfo.type.includes('single')) && taskInfo.answersData[0].isMixed) {

                taskInfo.answersData[0].answers.sort(randomMixer);

            } else if (taskInfo.type.includes('match')) {

                const idx = taskInfo.type.indexOf('match');

                taskInfo.answersData[idx].columns?.forEach((column, i) =>  {
                    if (column.isMixed) {
                        taskInfo.answersData[idx].columns[i].elements.sort(randomMixer);
                    }
                })
            }

            cachedTasks[taskNum] = taskInfo;

            this.contentOnLoading.splice(this.contentOnLoading.indexOf(taskId), 1);
            this.setState({cachedTasks}, done);
        });
    }

    getTaskHash(taskNum) {
        const {tasks} = this.props;
        if (typeof tasks[taskNum] === 'object') {
            return tasks[taskNum][taskNum];
        }

        if (typeof tasks[taskNum] === 'undefined') {
            let arrayIndex = taskNum;
            while (!tasks[arrayIndex] && arrayIndex >= 0) {
                arrayIndex--;
            }

            return this.props.tasks[arrayIndex][taskNum];
        }

        return this.props.tasks[taskNum];
    }

    changeAnswer = (answer, task_num, done) => {
        const {user_answers, selected_task_num} = this.state;
        task_num = task_num || selected_task_num;
        user_answers[task_num] = answer;
        this.setState({user_answers}, done);
    }

    showResult() {
        window.scrollTo(0, 0);
        this.setState({
            selected_task_num: -1,
            hasPrevTask: true,
            show_result: true,
            selectedSection: null
        });
    }

    showSection = (sectionAt) => {
        window.scrollTo(0, 0);
        this.setState({
            selected_task_num: -1,
            hasPrevTask: sectionAt !== 0,
            show_result: false,
            selectedSection: sectionAt,
        });
    };

    getFirstTaskNumInGroup(taskNum) {
        const {tasks} = this.props;
        if (typeof tasks[taskNum] === 'undefined') {
            // probably it's a grouped task
            let selected_task = taskNum;
            while (!tasks[selected_task]) {
                selected_task--;
                if (selected_task < 0) {
                    break;
                }

                if (typeof tasks[selected_task] === 'object') {
                    if (tasks[selected_task][taskNum]) {
                        taskNum = selected_task;
                        break;
                    }
                }
            }
        }

        return parseInt(taskNum);
    }

    changeTaskNum(task_num) {

        const firstInGroup = this.getFirstTaskNumInGroup(task_num);

        let hasPrevTask = task_num !== 0;

        if (firstInGroup === 0) {

            let ranges = this.getSectionRanges(this.props.tsContest.sections, this.props.tasks);

            if (ranges[0].name) {

                hasPrevTask = true;

            }

        }


        this.setState(
            {
                hasPrevTask,
                selected_task_num: firstInGroup,
                show_result: false,
                selectedSection: null,
            },
            () => setTimeout(
                () => this.scrollToTask(task_num),
                700
            )
        );

    }

    scrollToTask = (taskNum) => {
        const targetTaskEl = document.getElementById(`task_${taskNum}`);
        const firstTask = document.querySelector(`.single_task`);
        if (targetTaskEl) {
            const targetScrollTop = targetTaskEl.offsetTop;
            const parentScrollTop = targetTaskEl.parentElement.offsetTop;
            const behavior = targetTaskEl !== firstTask ? 'smooth' : 'auto';
            // https://caniuse.com/mdn-api_scrolltooptions
            try {
                window.scrollTo({top: targetScrollTop, behavior});
            } catch (e) {
                window.scrollTo(0, targetScrollTop + parentScrollTop);
            }
        }
    };

    addSavedAnswer(task_num, saved_answer) {
        const {saved_tasks} = this.state;
        const {tasks_token} = this.props;
        const isOnline = isOnlineByTaskNum(tasks_token, task_num);

        if (typeof saved_answer === 'object') {
            saved_answer = cloneDeep(saved_answer);
        }
        this.setState(
            {
                saved_tasks: {
                    ...saved_tasks,
                    [task_num]: saved_answer
                }
            },
            () => {

                // user could change task while save is in progress
                if (this.getFirstTaskNumInGroup(task_num) !== this.state.selected_task_num) {
                    return;
                }

                !isOnline && this.toNextTask(true, task_num);
            }
        );
    }

    toPrevTask() {

        let target_task_num = null;
        let candidate_task_num = this.state.selected_task_num;

        let task_keys = Object.keys(this.props.tasks);
        let max_task_num = parseInt(task_keys[task_keys.length - 1]);


        if (this.state.show_result) {

            target_task_num = max_task_num;

        }

        if (this.state.selectedSection === null) {

            const targetSection = this.props.tsContest.sections.findIndex(section => {
                return section.at - 1 === this.state.selected_task_num;
            });

            if (targetSection !== -1) {

                this.showSection(this.state.selected_task_num);
                return;

            }

        } else {

            candidate_task_num = this.state.selectedSection;

        }


        while (candidate_task_num >= 0 && target_task_num === null) {

            candidate_task_num--;

            if (this.props.tasks[candidate_task_num]) {

                target_task_num = candidate_task_num;

            }

        }

        if (target_task_num === null) {

            return;

        }

        if (this.state.selected_task_num === 0) {

            return;

        }

        this.changeTaskNum(target_task_num);

    }

    toNextTask(unanswered_only = false, _selectedInGroupTaskNum = null) {

        let target_task_num = null;

        const selectedInGroupTaskNum = parseInt(_selectedInGroupTaskNum);

        let candidate_task_num = isNaN(selectedInGroupTaskNum) ? this.state.selected_task_num : selectedInGroupTaskNum;

        let task_keys = Object.keys(this.props.tasks);
        let max_task_num = parseInt(task_keys[task_keys.length - 1]);

        if (typeof this.props.tasks[max_task_num] === 'object') {

            task_keys = Object.keys(this.props.tasks[max_task_num]);

            max_task_num = parseInt(task_keys[task_keys.length - 1])

        }

        while (max_task_num > candidate_task_num && !target_task_num) {

            candidate_task_num++;

            if (unanswered_only !== true) {

                if (!this.props.tasks[candidate_task_num]) {
                    continue;
                }

            }

            if (!(unanswered_only === true && this.getAnswersIsFilled(this.state.saved_tasks[candidate_task_num]))) {

                target_task_num = candidate_task_num;

            }

        }


        if (!target_task_num && candidate_task_num === max_task_num) {
            this.showResult();
            return;
        }

        if (this.state.selectedSection === null) {

            const targetSection = this.props.tsContest.sections.findIndex(section => section.at - 1 === target_task_num);

            if (targetSection !== -1) {
                this.showSection(target_task_num);
                return;
            }

        } else {
            target_task_num = this.state.selectedSection;
        }


        if (target_task_num === null) {
            return;
        }

        this.changeTaskNum(target_task_num);
    }

    hasNotSavedTasks = () => {
        const { statusTasks } = getTaskDataState();

        return TaskStates.NOT_SAVED in statusTasks && statusTasks[TaskStates.NOT_SAVED].length > 0;
    }

    setShowCloseModal = (showCloseModal = false) => this.setState({showCloseModal});

    pageCloseHandler = () => {
        this.hasNotSavedTasks()
            ? this.setShowCloseModal(true)
            : this.closeTaskPage()
        ;
    }

    closeTaskPage = () => {
        this.closeConfirmationSubscription?.unsubscribe();
        location.reload()
    }

    async finishSession() {
        await AjaxWrapper.post(
            `${CONFIG.Api.webPortal.url}/test/session-finish?tasksCount=${this.getAnsweredTaskCount()}&tabId=${window.appId}`,
            `"${this.state.userToken}"`,
            {
                auth_token: this.props.tasks_token,
            }
        );
        this.closeTaskPage();
    }

    showTimeoutPopup = () => {
        this.setState({timeoutPopupState: 'show'});
    }

    toggleSizeErrorPopup = () => {
        const {oversizeErrorPopupOpened} = this.state;
        this.setState({oversizeErrorPopupOpened: !oversizeErrorPopupOpened});
    }

    toggleShowRightAnswer = (task_num) => {
        let new_state = task_num;
        if (this.state.show_right_answer !== false) {
            new_state = false;
        }
        this.setState({show_right_answer: new_state});
    }

    resetAnswer = (taskNum, taskId) => {
        const {isSavingInProgress} = this.state;

        this.setState({isSavingInProgress: isSavingInProgress.filter( v => v !== taskNum)});
        deletePending( this.Polling$, { taskId } );

        this.toggleSizeErrorPopup();
    }

    resendAnswer = (taskNum) => {

        this.handleSaveTask(taskNum, true);
    }

    getReplaceItemId = (taskId) => {
        const {pending} = this.state.onlineState;

        return pending?.find((item) => item.taskId === taskId)?.id;
    };

    handleSaveTask = (taskNum, resend = false) => {
        const {tasks_token} = this.props;
        const {isSavingInProgress, onlineState} = this.state;

        contestOnlinePutReviewsAsyncLock(() => this.saveTask(taskNum, resend));

        if ( isOnlineByTaskNum(tasks_token, taskNum) && !onlineState?.runningPoll && isSavingInProgress?.length === 0 ) {
            usePollingSubject(
                {
                    bearer: tasks_token,
                    $: this.Polling$
                }
            );
        }
    };

    saveTask = (taskNum, resend = false) => {

        const {tasks_token} = this.props;
        const {user_answers, saved_tasks, isSavingInProgress} = this.state;
        const isOnline = isOnlineByTaskNum(tasks_token, taskNum);
        if (!isOnline) {
            if (typeof (user_answers[taskNum]) === "undefined" || user_answers[taskNum] === null) {
                if (typeof (saved_tasks[taskNum]) === "undefined" || saved_tasks[taskNum] === null) {
                    return Promise.resolve();
                }
            }

            if (saved_tasks[taskNum] && isEqual(saved_tasks[taskNum], user_answers[taskNum])) {
                return Promise.resolve();
            }
        }

        const payload = user_answers[taskNum];
        const taskId  = Number(taskNum) + 1;

        const replaceSolutionId = resend ? this.getReplaceItemId(taskId) : null;

        if (resend && !replaceSolutionId) {
            return Promise.resolve();
        }

        return new Promise(
            (resolve) => {
                this.setState({
                    isSavingInProgress: [...isSavingInProgress.filter( v => v !== taskNum), taskNum],
                    resendError: false
                }, () => {
                    resend && deletePending( this.Polling$, { taskId: taskNum} );
                    const url = `${CONFIG.Api.persistentCache.url}/v4/put${resend ? `?replace=${replaceSolutionId}` : ''}`;
                    const data = {
                        taskId,
                        payload,
                        version:     CONFIG.Version,
                        clientTime:  (new Date()).toISOString(),
                        count:       this.getAnsweredTaskCount(),
                        tabId:       window.appId,
                        hash:        this.state.stateHash,
                    };
                    const params = {auth_token: this.props.tasks_token, throwExceptionOnError: true, returnResult: true};

                    if (isOnline) {
                        newPendingToPolling(
                            this.Polling$, {
                                id:       'new',
                                solution: payload[0].solution,
                                taskId,
                            }
                        );
                    }

                    AjaxWrapper
                        .post( url, data, params )
                        .then(
                            (result) => {
                                const {data} = result;
                                if (!data?.hash) {
                                    setTimeout(() => {
                                        throw new RequestError(result, `Server returned unexpected data type:${typeof data} value:${data?.toString()}`);
                                    }, 100);
                                    setTimeout(() => {
                                        renderErrorPage(408);
                                    }, 300);
                                    return;
                                }

                                const finishSaving = () => {
                                    const {isSavingInProgress} = this.state;
                                    this.setState(
                                        {
                                            isSavingInProgress: isSavingInProgress.filter( v => v !== taskNum),
                                            stateHash: data.hash
                                        },
                                        () => {
                                            this.addSavedAnswer(taskNum, payload);
                                        }
                                    );
                                };

                                if (isOnline) {
                                    addIdToNewPending( this.Polling$, { id: data.id, taskId } );
                                    this.Polling$
                                        .pipe( skip(1), take(1) )
                                        .subscribe( finishSaving )
                                    ;
                                } else {
                                    finishSaving();
                                }
                            }
                        )
                        .catch(
                            (error) => {
                                if (error?.response?.status === 401) {
                                    this.showTimeoutPopup();
                                    return;
                                } else if ([404, 408, 409, 423, 429].includes(error?.response?.status)) {
                                    renderErrorPage(error.response.status);
                                } else if (error?.response?.status === 413) {
                                    this.resetAnswer(taskNum, taskId);
                                    return;
                                } else if (error?.response?.status >= 400 && error.response.status < 600) {
                                    renderErrorPage(500);
                                } else if (error?.response?.status === 200 && error.response?.data?.error) {
                                    if (error.response.data.error === 'processing') {
                                        this.setState({resendError: true});
                                        deletePending(this.Polling$, taskId, true);
                                        return;
                                    } else if (error.response.data.error === 'alreadyDone') {
                                        error.response.data.review
                                            ? pollOutOfLoop(error.response.data.review, this.Polling$)
                                            : this.setState({resendError: true})
                                        ;
                                        return;
                                    } else {
                                        ReactDOM.render(<UserProfileConfirmationModal isExceptionAction />, document.getElementById("index"));
                                    }
                                } else {
                                    console.error(error);
                                    renderErrorPage(408);
                                }
                            }
                        )
                        .finally(resolve)
                    ;

                });
            }
        )

    };

    getFirstUnansweredTaskNum(taskList = this.props.tasks) {

        for (let i in taskList) {

            if (typeof taskList[i] === 'object') {

                i = this.getFirstUnansweredTaskNum(taskList[i]);

            }

            if (typeof i !== 'undefined' && !this.getAnswersIsFilled(this.state.saved_tasks[i])) {

                return parseInt(i);

            }

        }

    }

    cancelResultSave() {

        let task_num = this.getFirstUnansweredTaskNum();

        if (!task_num) {

            task_num = 0;

        }

        this.changeTaskNum(task_num);
    }

    getTasksCount = (tasks) =>
         Object.values(tasks).reduce(
            (sum, entry)=>(
                sum + (
                    typeof entry === 'object'
                        ? Object.keys(entry).length
                        : 1
                )
            ),
            0
        )
    ;

    getAnsweredTaskCount() {
        const { onlineState, isOnline, saved_tasks } = this.state;

        const hasOnlineState   = isOnline && (onlineState?.solutions?.length || onlineState?.pending?.length);
        const savedTasksLength = Object.keys(saved_tasks).length;

        const pendingIds = onlineState?.pending.map((item) => item.taskId) || [];
        const uniqueSavedTasks = hasOnlineState
            ? Object.fromEntries(
                Object.entries(saved_tasks).filter(
                    ([id]) => !pendingIds.includes(parseInt(id) + 1)
                )
            )
            : []
        ;

        return hasOnlineState
            ? countProgAnswers(onlineState.solutions) >= savedTasksLength
                ? countProgAnswers([...onlineState.pending, ...onlineState.solutions])
                : countProgAnswers(onlineState.pending) + countAnswers(uniqueSavedTasks)
            : countAnswers(saved_tasks)
        ;
    }

    getSelectedSection() {


        if (this.state.selectedSection !== null) {

            return this.props.tsContest.sections.find(section => section.at - 1 === this.state.selectedSection);

        }

        return null;

    }

    getSelectedSectionRange() {


        let selectedSection = this.getSelectedSection();

        const sectionRanges = this.getSectionRanges(this.props.tsContest.sections, this.props.tasks);

        let selectedSectionRange = sectionRanges.find((section) => section.from <= this.state.selected_task_num && section.to >= this.state.selected_task_num);

        if (!selectedSectionRange && selectedSection) {

            selectedSectionRange = selectedSection;

        }

        return selectedSectionRange;

    }

    getWrapperBgStyles() {

        let selectedSection = this.getSelectedSection();

        const sectionRanges = this.getSectionRanges(this.props.tsContest.sections, this.props.tasks);

        let selectedSectionRange = sectionRanges.find((section) => section.from <= this.state.selected_task_num && section.to >= this.state.selected_task_num);

        if (!selectedSectionRange && selectedSection) {

            selectedSectionRange = selectedSection;

        }

        const wrapperBgStyles = {};

        if (selectedSectionRange && selectedSectionRange.metadata) {

            if (selectedSectionRange.metadata.bgImage) {

                wrapperBgStyles.backgroundImage = 'url(' + CONFIG.Api.webPortal.url + "/content/_image/" + selectedSectionRange.metadata.bgImage + ')';

                if (selectedSectionRange.metadata.bgAlign) {

                    wrapperBgStyles.backgroundPosition = selectedSectionRange.metadata.bgAlign;

                }

            }

            if (selectedSectionRange.metadata.bgColor) {

                wrapperBgStyles.backgroundColor = selectedSectionRange.metadata.bgColor;

            }

        }


        if (!wrapperBgStyles.backgroundImage && this.props.metadata.bgImage) {

            wrapperBgStyles.backgroundImage = 'url(' + CONFIG.Api.webPortal.url + "/content/_image/" + this.props.metadata.bgImage + ')';

            if (this.props.metadata.bgAlign) {

                wrapperBgStyles.backgroundPosition = this.props.metadata.bgAlign;

            }

        }

        if (!wrapperBgStyles.backgroundColor && this.props.metadata.bgColor) {

            wrapperBgStyles.backgroundColor = this.props.metadata.bgColor;

        }


        return wrapperBgStyles;


    };


    getSelectedGroup(selectedTaskNum = this.state.selected_task_num) {
        const {tasks} = this.props;
        let taskGroup;
        if (typeof tasks[selectedTaskNum] === 'string') {
            return {[selectedTaskNum]: tasks[selectedTaskNum]};
        }
        if (typeof tasks[selectedTaskNum] === 'object') {
            taskGroup = tasks[selectedTaskNum];
        } else if (typeof tasks[selectedTaskNum] === 'undefined') {
            let array_index = selectedTaskNum;
            while (!tasks[array_index] && array_index >= 0) {
                array_index--;
            }
            taskGroup = tasks[array_index];
        }
        return taskGroup;
    }


    setAnswerIsFilled(value, hasErrors, taskNum) {

        taskNum = parseInt(taskNum);

        const {answersIsFilled, answersHasError} = this.state;

        const filledIndex = answersIsFilled.indexOf(taskNum);
        const errorsIndex = answersHasError.indexOf(taskNum);


        if (filledIndex >= 0 && !value) {

            answersIsFilled.splice(filledIndex, 1);

        }

        if (filledIndex === -1 && value) {

            answersIsFilled.push(taskNum);

        }

        if (errorsIndex >= 0 && !hasErrors) {

            answersHasError.splice(errorsIndex, 1);

        }

        if (errorsIndex === -1 && hasErrors) {

            answersHasError.push(taskNum);

        }

        this.setState({answersIsFilled, answersHasError});

    }

    getAnswersIsFilled(savedTask) {
        return  savedTask &&
                Array.isArray(savedTask) &&
                savedTask.some(
                    ({solution}) => solution !== null && solution !== void(0)
                )
        ;
    }

    getResultDataForTaskNum(taskNum, minTaskNum) {

        const {results_data} = this.props;

        if (!results_data) {

            return null;

        }

        if (results_data[taskNum]) {

            if (results_data[taskNum][taskNum]) {

                return results_data[taskNum][taskNum];

            }

            return results_data[taskNum];
        }

        if (results_data[minTaskNum]) {

            return results_data[minTaskNum][taskNum];

        }

    }


    isTaskGroupCanBeAnswered() {


        let taskGroup = this.getSelectedGroup();

        for (const taskNum of Object.keys(taskGroup)) {

            if (!this.state.cachedTasks[taskNum]) {

                return false;

            }

            const type = this.state.cachedTasks[taskNum].type;

            if (type.filter((t) => t !== 'none').length > 0) {

                return true;

            }

        }

        return false;

    }

    taskOnlineState = (onlineState, jwt, taskNum) =>
        (onlineState !== null && Array.isArray(onlineState.solutions) && isOnlineByTaskNum(jwt, taskNum))
            ? {
                solutions:   onlineState.solutions  .filter(({taskId}) => (taskNum - -1) === taskId),
                pending:     onlineState.pending    .filter(({taskId}) => (taskNum - -1) === taskId),
                tasksTimers: onlineState.tasksTimers.filter(({taskId}) => (taskNum - -1) === taskId)
            }
            : null
    ;

    renderTasksGroup() {
        const {
            selected_task_num,
            isSavingInProgress: isSavingInProgressArr,
            answersIsFilled,
            answersHasError,
            saved_tasks,
            user_answers,
            onlineState,
            cachedTasks,
            show_right_answer,
            resendError,
            locale
        } = this.state;

        const {
            results_data,
            tasks_token,
            tasks,
            metadata
        } = this.props;

        let taskGroup = this.getSelectedGroup();
        if (!taskGroup) { return; }
        const sortedTaskNums = Object.keys(taskGroup).map((k) => parseInt(k)).sort((a, b) => a - b);
        let minTaskNum = sortedTaskNums[0];
        let maxTaskNum = sortedTaskNums.reverse()[0];
        let taskTitle = selected_task_num + 1;
        let offset = 0;
        let selectedSectionRange = this.getSelectedSectionRange();
        if (selectedSectionRange) {
            const sectionTasks = Object.keys(tasks).reduce((accumulator, value) => {
                if (value >= selectedSectionRange.from && value <= selectedSectionRange.to) {
                    accumulator[value] = tasks[value];
                }
                return accumulator;
            }, {});

            taskTitle = 0;

            for (let [taskNum, task] of Object.entries(sectionTasks)) {
                if (parseInt(taskNum) === selected_task_num) {
                    break;
                }
                if (typeof task === 'object') {
                    taskTitle += Object.keys(task).length;
                } else {
                    taskTitle += 1;
                }
            }
            offset = minTaskNum - taskTitle;
            taskTitle += 1;
        }
        if (minTaskNum !== maxTaskNum) {
            taskTitle = `${minTaskNum - offset + 1}-${maxTaskNum - offset + 1}`;
        }
        const wrapperClass = new BemClassName('task');
        wrapperClass.appendStatusIf(Object.keys(taskGroup).length > 1, 'grouped');
        wrapperClass.appendStatusIf(!!results_data, 'is-results');

        let leastOneAnswersIsFilled = false;
        let leastOneAnswersHasError = false;
        let leastOneAnswersIsSaved = false;
        let allAnswersIsEqual = true;

        for (const taskNum of Object.keys(taskGroup)) {
            if (answersHasError.includes(parseInt(taskNum))) {
                leastOneAnswersHasError = true;
            }

            if (answersIsFilled.includes(parseInt(taskNum))) {
                leastOneAnswersIsFilled = true;
            }

            if (allAnswersIsEqual && !isEqual(user_answers[taskNum], saved_tasks[taskNum])) {
                allAnswersIsEqual = false;
            }

            if (saved_tasks[taskNum]) {
                const savedAnswers = this.getAnswersIsFilled(saved_tasks[taskNum]);
                if (savedAnswers) {
                    leastOneAnswersIsSaved = true;
                }
            }
        }

        const grouped                = minTaskNum !== maxTaskNum;

        return <VBox grow className={`t-task-layout ${wrapperClass}`}>
                    <HBox className="task__title">
                        {`№ ${taskTitle}`}
                    </HBox>
                    <VBox grow>
                        {
                            Object
                                .keys(taskGroup)
                                .map(
                                    (taskNum) => {
                                        const taskOnlineState        = this.taskOnlineState(onlineState, tasks_token, taskNum);
                                        const resultData             = this.getResultDataForTaskNum(taskNum, minTaskNum);
                                        const isSavingInProgress     = isSavingInProgressArr.includes(taskNum);
                                        const showRightAnswer        = show_right_answer === taskNum;
                                        const hasErrors              = answersHasError.includes(parseInt(taskNum));
                                        const onAnswerIsFilledChange = (v, e) =>this.setAnswerIsFilled(v, e, taskNum);
                                        const nextTask               = ()=>this.toNextTask(true, taskNum);
                                        const isHidden               = onlineState !== null
                                                                            ? isHiddenByJWT(tasks_token, taskNum)
                                                                            : ["ok", "partly", 'wrong'].includes(resultData?.verdict)
                                                                                && resultData?.solution?.answers.length === 0
                                        ;
                                        const allowResend            = getTaskOnlineInfoByJWT(tasks_token, taskNum)?.includes('r') || false;

                                        return <Task
                                                    grouped                = {grouped}
                                                    taskNum                = {taskNum}
                                                    key                    = {taskNum}
                                                    resultData             = {resultData}
                                                    tasks                  = {tasks}
                                                    isSavingInProgress     = {isSavingInProgress}
                                                    metadata               = {metadata}
                                                    savedTasks             = {saved_tasks}
                                                    tasksToken             = {tasks_token}
                                                    cachedTasks            = {cachedTasks}
                                                    userAnswers            = {user_answers}
                                                    showRightAnswer        = {showRightAnswer}
                                                    hasErrors              = {hasErrors}
                                                    onlineState            = {taskOnlineState}
                                                    onAnswerIsFilledChange = {onAnswerIsFilledChange}
                                                    nextTask               = {nextTask}
                                                    changeAnswer           = {this.changeAnswer}
                                                    toggleShowRightAnswer  = {this.toggleShowRightAnswer}
                                                    saveTask               = {this.handleSaveTask}
                                                    isHidden               = {isHidden}
                                                    resendAnswer           = {this.resendAnswer}
                                                    allowResend            = {allowResend}
                                                    resendError            = {resendError}
                                                    locale                 = {locale}
                                                />
                                    }
                                )
                        }
                    </VBox>
                </VBox>
        ;
    }

    render() {
        const { isLoaded } = this.state;

        return  isLoaded
                ? this.renderPage(this.state)
                : <CircularProgress centerOfWindow />
        ;
    }

    renderOnlineTabs = ({onlineTab}) =>
        <HBox center className={block.el('online-tabs')+ block.el('online-tabs').mod('sticky')}>
            <Button
                active={onlineTab === "tasks"}
                onAction={this.setOnlineTabTasks}
                form="brick"
                mode="text"
                size="s"
                className={block.el('online-tab')}
            >
                <SmtLocaleString k={"olympiad.task-page-tab.tasks"} id={"value"}/>
            </Button>
            <SGap/>
            <Button
                active={onlineTab === "standings"}
                onAction={this.setOnlineTabStandings}
                form="brick"
                mode="text"
                size="s"
                className={block.el('online-tab')}
            >
                <SmtLocaleString k={"olympiad.task-page-tab.participants"} id={"value"}/>
            </Button>
        </HBox>
    ;

    renderPageStandings = ({tasks_token},{userName}) =>
        <>
            <Countdown
                issuedUntil = {this.props.issuedUntil}
                onTimeout   = {this.showTimeoutPopup}
            />
            <StandingsPage
                token     = {tasks_token}
                userName  = {userName}
                onTimeout = {this.showTimeoutPopup}
            />
            <TimeoutPopup
                onClick      = {this.closeTaskPage}
                state        = {this.state.timeoutPopupState}
                issuedAt     = {this.props.issuedAt}
                task_count   = {this.getTasksCount(this.props.tasks)}
                answer_count = {this.getAnsweredTaskCount()}
            />
        </>
    ;

    renderPageTasks ({selected_task_num, show_result, hasPrevTask}) {
        let selectedSection = null;

        if (this.state.selectedSection !== null) {
            selectedSection = this.props.tsContest.sections.find(section => section.at - 1 === this.state.selectedSection);
        }

        let showNextTaskButton = !show_result;

        if (this.props.results_data) {
            let last_task_num = selected_task_num;
            if (typeof this.props.tasks[selected_task_num] === 'object') {
                let group_tasks = this.props.tasks[selected_task_num];
                let last_task_num_in_group = Object.keys(group_tasks)[Object.keys(group_tasks).length - 1];
                last_task_num = parseInt(last_task_num_in_group);
            }
            if ((last_task_num + 1) === this.getTasksCount(this.props.tasks)) {
                showNextTaskButton = false;
            }
        }

        let status_bg = this.props.metadata.navButtonColor
            ? this.props.metadata.navButtonColor
            : null
        ;

        return <>
                <div className="task__section">
                    {
                        show_result
                        && <Result
                            issuedUntil={this.props.issuedUntil}
                            issuedAt={this.props.issuedAt}
                            task_count={this.getTasksCount(this.props.tasks)}
                            answer_count={this.getAnsweredTaskCount()}
                            onSend={this.finishSession.bind(this)}
                            onCancelSave={this.cancelResultSave.bind(this)}
                            onTimeout={this.showTimeoutPopup}
                            onSelectTask = {this.changeTaskNum.bind(this)}
                        />
                    }
                    {
                        !!selectedSection &&
                        <Section
                            isResultPage={!!this.props.results_data}
                            onNext={() => this.changeTaskNum(selectedSection.at - 1)}
                            data={selectedSection}
                            issuedUntil={this.props.issuedUntil}
                            issuedAt={this.props.issuedAt}
                            onTimeout={this.showTimeoutPopup}
                        />
                    }
                    {
                        !show_result && selectedSection === null && this.renderTasksGroup()
                    }
                </div>
                {
                    hasPrevTask &&
                    <div className="prev-task" onClick={this.toPrevTask.bind(this)} />
                }
                {
                    showNextTaskButton &&
                    <div className="next-task" onClick={this.toNextTask.bind(this)} />
                }
                <TaskSelector
                    tasks                 = {this.props.tasks}
                    selectedTaskNum       = {this.state.selected_task_num}
                    savedTasks            = {this.state.saved_tasks}
                    issuedUntil           = {this.props.issuedUntil}
                    resultsData           = {this.props.results_data}
                    userScore             = {this.props.user_score}
                    selectedSection       = {this.state.selectedSection}
                    bgColor               = {status_bg}
                    taskSelectorColor     = {this.props.metadata.totalScoreColor}
                    navType               = {this.props.metadata.navType}
                    sections              = {this.getSectionRanges(this.props.tsContest.sections, this.props.tasks)}
                    hideScoreExplanation  = {this.props.metadata.scoreExplanation === 'hide'}
                    selectTask            = {this.changeTaskNum.bind(this)}
                    onResultClick         = {this.showResult.bind(this)}
                    toggleShowRightAnswer = {this.toggleShowRightAnswer.bind(this)}
                    onTimeout             = {this.showTimeoutPopup}
                    showSection           = {this.showSection}
                    selectedGroup         = {this.getSelectedGroup()}
                    isOnLoading           = {!this.isAllTasksLoaded()}
                    onlineState           = {this.state.onlineState}
                    jwt                   = {this.props.tasks_token}
                />
                <TimeoutPopup
                    onClick={this.closeTaskPage}
                    state={this.state.timeoutPopupState}
                    issuedAt={this.props.issuedAt}
                    task_count={this.getTasksCount(this.props.tasks)}
                    answer_count={this.getAnsweredTaskCount()}
                />
                {this.state.oversizeErrorPopupOpened && <OversizeErrorPopup onClose={this.toggleSizeErrorPopup}/>}

        </>;
    }

    renderPage({timeoutPopupState, isOnline, onlineTab, isTable, show_right_answer, showCloseModal}) {
        const wrapper_class = new BemClassName('task_page');
        wrapper_class.appendStatusIf(timeoutPopupState === 'show', 'timeout');
        wrapper_class.appendAdditionalClassesIf(show_right_answer !== false, 'task_page__show_right_answer');
        const onCloseModal      = () => this.setShowCloseModal();
        const onConfirmModal    = () => this.closeTaskPage();
        const onSelectTask      = (taskNum) => {
            this.setShowCloseModal();
            setTimeout(() => this.changeTaskNum(taskNum), 10);
        };

        return (
            <div className={wrapper_class}>
                <div className="task_page__bg" style={this.getWrapperBgStyles()} />
                <LangSwitcher hideFlags isFixedOnDesktop />
                <div className="task_page_close" onClick={this.pageCloseHandler}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5"
                              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                              fill="white" />
                    </svg>
                </div>
                {
                    isOnline && isTable &&
                    this.renderOnlineTabs(this.state)
                }
                {
                    isOnline && isTable && onlineTab === "standings"
                        ? this.renderPageStandings(this.props, this.state)
                        : this.renderPageTasks(this.state)
                }
                {
                    showCloseModal &&
                    <CloseModal
                        onClose      = {onCloseModal}
                        onConfirm    = {onConfirmModal}
                        onSelectTask = {onSelectTask}
                    />
                }
            </div>
        );
    }
}

/*



*/
