import React from 'react';
import UIControlWrapper from "Lab/components/UI2/UIControlWrapper";
import BemClassName from "Cheops/BemClassName";
import {UIControl, UIControlProps} from "../UIControl";
import './TextControl.less';

interface Props extends UIControlProps {

    children: React.ReactNode;
    className?: string;
    primary?: boolean;
    caption?: boolean;
    fullWidth?: boolean;
    captionHeight?: boolean;
    bold?: boolean;
    darkMode?: boolean;
    clickable?: boolean;
    textAlign?: React.CSSProperties["textAlign"];
    hasHoverEffects?: boolean;
    wrap?: boolean;

    onClick?(e: React.MouseEvent): void;

}

export default class TextControl extends UIControl<Props> {

    static defaultProps = {
        ...UIControl.defaultProps,
        primary: false,
        disabled: false,
        caption: false,
        bold: false,
        darkMode: false,
        clickable: false,
        hasHoverEffects: false,
        wrap: false,
        onClick() {
        },
    };


    render(): React.ReactNode {

        const {
            children,
            primary,
            className,
            fullWidth,
            onClick,
            disabled,
            caption,
            captionHeight,
            bold,
            darkMode,
            clickable,
            hasErrors,
            textAlign,
            hasHoverEffects,
            wrap,
        } = this.props;

        const wrapperClassName = new BemClassName('text_control', null, className);

        wrapperClassName.appendStatusIf(primary, 'primary');
        wrapperClassName.appendStatusIf(disabled, 'disabled');
        wrapperClassName.appendStatusIf(caption, 'caption');
        wrapperClassName.appendStatusIf(captionHeight, 'captionHeight');
        wrapperClassName.appendStatusIf(bold, 'bold');
        wrapperClassName.appendStatusIf(darkMode, 'dark_mode');
        wrapperClassName.appendStatusIf(clickable, 'clickable');
        wrapperClassName.appendStatusIf(hasErrors, 'hasErrors');
        wrapperClassName.appendStatusIf(hasHoverEffects, 'hasHover');
        wrapperClassName.appendStatusIf(!!textAlign, `text-align-${textAlign}`);
        wrapperClassName.appendStatusIf(wrap, 'wrap');

        return <UIControlWrapper className={wrapperClassName.toString()} fullWidth={fullWidth} onClick={onClick}>
            {children}
        </UIControlWrapper>;

    }

}
