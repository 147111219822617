export type ValidatorLocale                 = 'ru' | 'en';
export type ValidatorCacheKey               = string;
export type WorkerValidatorCacheItem        = {
    worker: Worker
    timer:  ReturnType<typeof setTimeout>
};

export type SolutionValidationReportField    = null | SolutionValidationReport[][][];
export type SolutionValidationReportElements = null | SolutionValidationReport[][];
export type SolutionValidationReportTask     = null | SolutionValidationReport[];

export type ValidationReportType = {
    elements: SolutionValidationReportElements,
    field:    SolutionValidationReportField,
    task:     SolutionValidationReportTask,
};

export type SolutionValidationReportMessage = {
    message: string,
    level:   SmtTask.ClientValidationLevel,
    locale:  keyof SmtTask.ClientValidationRegexI18N,
    value:   string
};

export type SolutionValidationReport = SolutionValidationReportMessage | false;

export type WorkerValidatorCacheType = Record<ValidatorCacheKey, WorkerValidatorCacheItem>;
export type WorkerURLCacheType = Record<string, string>;

export type AnswersValidationReportType = {
    field:      SolutionValidationReportField   [number],
    elements:   SolutionValidationReportElements[number]
}

const blobTypeJS                                     = {type: "text/javascript"};
const WorkerValidatorAlive                           = 1000 * 20;
const WorkerURLCache: WorkerURLCacheType             = {};
const WorkerValidatorCache: WorkerValidatorCacheType = {};

const getWorkerFn    = ({function:fn}:SmtTask.ClientValidationFunctionElement | SmtTask.ClientValidationFunctionField | SmtTask.ClientValidationFunctionTask):string => `
    self.onmessage = ({data}) => {
        try {
            ${fn}
            const {value, locale} = JSON.parse(data);
            if (test) {
                const message  = test(value, locale);
                const response = JSON.stringify({message, value, locale});
                self.postMessage(response);
            }
        } catch (err) {
            console.error(err);
            self.postMessage(false);
        } 
    }
`;
const getWorkerRegex = ({regex, i18n}:SmtTask.ClientValidationRegex):string => `
    self.onmessage = ({data}) => {
        try {
            const regex = new RegExp(${regex});
            const i18n = ${JSON.stringify(i18n)};
            const {value, locale} = JSON.parse(data);
            if (regex) {
                const result = regex.test(value);
                const message = result ? false : 
                                locale in i18n ? i18n[locale] :
                                false
                ;
                const response = JSON.stringify({message, value, locale});
                self.postMessage(response);
            }
        } catch (err) {
            console.error(err);
            self.postMessage(false);
        } 
    }
`;

const getWorkerURLForClientValidation = (clientValidation: SmtTask.ClientValidationObject | SmtTask.ClientValidationObjectTask): string => {
    const {type} = clientValidation;
    const fn = type === 'functionElement'  ? getWorkerFn   (clientValidation) :
                type === 'functionField'   ? getWorkerFn   (clientValidation) :
                type === 'functionTask'    ? getWorkerFn   (clientValidation) :
                type === 'regex'           ? getWorkerRegex(clientValidation) :
                null
    ;

    if (!(fn in WorkerURLCache)) {
        const blob = new Blob([fn], blobTypeJS);
        const url  = window.URL.createObjectURL(blob);
        WorkerURLCache[fn] = url;
    }
    return WorkerURLCache[fn];
}

const getSolutionHasMessage = (solution: string | any[]) => typeof solution === 'object' && solution && ('message' in solution) && `${(solution as {message?: string})?.message}`.length > 0;

const isSolutionItemNotEmpty = (solution:null | any[]): boolean =>
    solution !== null && (
        Array.isArray(solution)         ||
        getSolutionHasMessage(solution) ||
        true
    )
;

const isSolutionNotEmpty = (v: any): boolean =>
    (v !== void(0) && v !== null)
;

export const calcSolutionValidation = (solution: string | any[], validator: SmtTask.ClientValidationObject | SmtTask.ClientValidationTask, locale: ValidatorLocale): Promise<SolutionValidationReport> => {
    const workerFeatureDetected = 'Worker' in window;
    const {condition}           = validator as SmtTask.ClientValidationObjectTask;
    const solutionIsString      = typeof solution === 'string';
    const solutionIsArray       = Array.isArray(solution);
    const solutionHasMessage    = getSolutionHasMessage(solution);
    const hasSolutionToValidate =
        condition !== void(0)
            ?   (condition === 'some' && solutionIsArray) ? solution.some ( isSolutionItemNotEmpty ) :
                (condition === 'all'  && solutionIsArray) ? solution.every( isSolutionItemNotEmpty ) :
                false
            :   solutionIsString   ? solution !== '' :
                solutionIsArray    ? solution.some( isSolutionNotEmpty ) :
                solutionHasMessage ? solutionHasMessage :
                false
    ;


    if (workerFeatureDetected && hasSolutionToValidate) {
        return new Promise<SolutionValidationReport>(
            (resolve) => {
                const WorkerURL = getWorkerURLForClientValidation(validator);
                if ( !(WorkerURL in WorkerValidatorCache)) {
                    const worker = new Worker( WorkerURL );
                    const wvcItem: WorkerValidatorCacheItem = { worker, timer: null };
                    WorkerValidatorCache[WorkerURL] = wvcItem;
                }
                const {worker, timer} = WorkerValidatorCache[WorkerURL];
                if (timer !== null) {
                    clearTimeout(timer);
                }
                const workerMessageListener = ({data}: MessageEvent<string>) => {
                    let hit = false;
                    try {
                        const {message, value, locale} = JSON.parse(data);
                        const {level} = validator;
                        if (JSON.stringify(value) === JSON.stringify(solution)) {
                            hit = true;
                            const report: SolutionValidationReport =
                                (typeof message === 'string' && message.trim().length > 0)
                                    ? {message, level, locale, value}
                                    : false
                            ;
                            return resolve(report);
                        }
                    } catch (e) {
                        console.error(e);
                        resolve(false);
                    } finally {
                        if (hit) {
                            worker.removeEventListener("message", workerMessageListener);
                        }
                    }
                }

                WorkerValidatorCache[WorkerURL].timer = setTimeout(
                    (worker: Worker, WorkerURL: string) => {
                        worker.removeEventListener("message", workerMessageListener);
                        worker.terminate();
                        delete WorkerValidatorCache[WorkerURL];
                    },
                    WorkerValidatorAlive,
                    worker,
                    WorkerURL
                );

                const workerMessageStr = JSON.stringify({value: solution, locale})
                worker.addEventListener("message", workerMessageListener );
                worker.postMessage(workerMessageStr);
            }
        );
    } else {
        return Promise.resolve(false);
    }
}

export const isElementValidator  = ({ type }: SmtTask.ClientValidationObject) => type === "functionElement" ;
export const isFieldValidator    = ({ type }: SmtTask.ClientValidationObject) => type === "functionField" || type === "regex";

const getSolutionValidationReportPromises = (solution: (string | number)[], validators: SmtTask.ClientValidationObject[], locale: 'ru' | 'en' ) =>
    solution.map(
        (solutionItem: string | number) =>
            Promise.all(
                validators.map(
                    (validator) =>
                        calcSolutionValidation(
                            `${solutionItem}`.trim(),
                            validator,
                            locale
                        )
                )
            )
    )
;

export const calcTaskValidationReport = (answers: [{ solution: null | any[] }], clientValidations: SmtTask.ClientValidationTask[], locale: string): Promise<SolutionValidationReportTask> => {
    const hasValidationReport = answers && Array.isArray(answers) && clientValidations && (locale === 'ru' || locale === 'en');
    return hasValidationReport
        ? Promise.all(
            clientValidations.map(
                validator => calcSolutionValidation(
                    answers.map( ({solution}) => solution ),
                    validator,
                    locale
                )
            )
        )
        : Promise.resolve(null)
    ;
}

export const calcTaskValidationElementsReport = (answers: any[], clientValidations: SmtTask.ClientValidationObject[][], locale: string): Promise<SolutionValidationReportElements> => {
    const hasValidationReport = answers && clientValidations && (locale === 'ru' || locale === 'en');
    return hasValidationReport
        ? Promise.all(
            answers.map(
                (answer, ai) => {
                    const validators: SmtTask.ClientValidationObject[] = clientValidations[ai];
                    const {solution} = answer;
                    const solutionIsNumber        = typeof solution === "number";
                    const solutionIsNonEmptyArray = !solutionIsNumber && Array.isArray(solution) && solution.length;
                    const solutionHasMessage      = !solutionIsNumber && !solutionIsNonEmptyArray && solution && ('message' in solution);

                    return validators && Array.isArray(validators) && validators.length && solution && (
                        solutionIsNumber        ||
                        solutionIsNonEmptyArray ||
                        solutionHasMessage
                    )
                        ? Promise.all(
                            validators.map(
                                (validator) =>
                                    calcSolutionValidation(
                                        solutionIsNumber ? `${solution}` : solution,
                                        validator,
                                        locale
                                    )
                            )
                        )
                        : Promise.resolve(false)
                    ;
                }
            )
        )
        : Promise.resolve(null)
    ;
}

export const calcTaskValidationFieldReport = (answers: any[], clientValidations: SmtTask.ClientValidationObject[][], locale: string): Promise<SolutionValidationReportField> => {
    const hasValidationReport = answers && clientValidations && (locale === 'ru' || locale === 'en');
    return hasValidationReport
        ? Promise.all(
            answers.map(
                (answer, ai) => {
                    const validators: SmtTask.ClientValidationObject[] = clientValidations[ai];
                    const {solution} = answer;
                    const hasSolutionForValidation = solution !== void(0) && solution !== null && validators && validators.length > 0;
                    return hasSolutionForValidation
                        ? Promise.all(
                            getSolutionValidationReportPromises(
                                solution,
                                validators,
                                locale
                            )
                        )
                        : Promise.resolve(false)
                    ;
                }
            )
        )
        : Promise.resolve(null)
    ;
}

export const isReportError = (item: SolutionValidationReportMessage | false) => (item !== false && 'level' in item && item.level === 'error')

export const checkClientValidationError = ( report: ValidationReportType[keyof ValidationReportType] ) =>
    report
        ? report.flat(Infinity).some( isReportError ) || false
        : false
;

export const errorSortFirst = (
    {level: a}: SolutionValidationReportMessage,
    {level: b}: SolutionValidationReportMessage
)=> (
    a === 'error' && b === 'warning' ? -1 :
    a === 'warning' && b === 'error' ?  1 :
    0
)

const errorReportsFilter = (reports: Array<SolutionValidationReport>) =>
    reports.every(report => report === false)
        ? reports
        : reports.filter(Boolean)
;

export const getElementClientValidationError = ({elements}: ValidationReportType, index: number):  SolutionValidationReport | null =>
    elements === null
        ? null
        : elements[index]
            ? errorReportsFilter(elements[index]).sort(errorSortFirst)[0]
            : null
;

export const getTaskClientValidationError = ({task}: ValidationReportType):  SolutionValidationReport | null =>
    task === null
        ? null
        : task.sort(errorSortFirst)[0]
;

export const checkHasClientValidationError = ({elements, field, task}: ValidationReportType) =>
    checkClientValidationError(elements) ||
    checkClientValidationError(field)    ||
    checkClientValidationError(task)
;


export const answerValidationPromise = (
    answer: [{ solution: null | any[] }],
    clientValidationTask:     SmtTask.ClientValidationTask[],
    clientValidationElements: SmtTask.ClientValidationObject[][],
    clientValidationFields:   SmtTask.ClientValidationObject[][],
    locale: string
) => Promise.all(
    [
        calcTaskValidationReport        (answer, clientValidationTask,     locale),
        calcTaskValidationElementsReport(answer, clientValidationElements, locale),
        calcTaskValidationFieldReport   (answer, clientValidationFields,   locale),
    ]
)
    .then(
        ([task, elements, field]: [
            SolutionValidationReportTask,
            SolutionValidationReportElements,
            SolutionValidationReportField
        ]) => {
            const validationReport: ValidationReportType = {
                task,
                elements,
                field
            }
            return validationReport;
        }
    )
;

export const answerValidationCb = (answer: any, task: SmtTask.Task, locale: string) => {
    const {answersData, clientValidation:clientValidationTask} = task;
    const clientValidationElements = answersData.map( ({clientValidation}) => clientValidation && clientValidation.filter( isElementValidator ) );
    const clientValidationFields   = answersData.map( ({clientValidation}) => clientValidation && clientValidation.filter( isFieldValidator ) );
    return answerValidationPromise(
        answer,
        clientValidationTask,
        clientValidationElements,
        clientValidationFields,
        locale
    )
}
