import React                           from "react";
import bnc                             from "bnc";
import {HBox, MGap, SGap, VBox}        from "@sirius/ui-lib/src/blocks/Layout";
import {CircularProgress}              from "@sirius/ui-lib";
import {SmtLocaleString, translations} from "Smt/SmtLocaleBlock";
import type {
    MaybeTaskOnlineStateType,
    TaskOnlineStateType
}                                      from "Smt/SmtProgrammingAnswer/block";

export const block = new bnc("programming-answer");

type hasIsSavingInProgress = {
    isSavingInProgress: boolean
}

type HasLabel = {
    label: string;
}

type MaybeHasLabel = Partial<HasLabel>;

type HasAllowResend = { allowResend: boolean };
type MaybeHasAllowResend = Partial<HasAllowResend>;

type HasResendError = { resendError: boolean };
type MaybeHasResendError = Partial<HasResendError>;

type TaskSaveButtonLabelProps = {
                onlineState:        MaybeTaskOnlineStateType;
            } & hasIsSavingInProgress & MaybeHasLabel & MaybeHasAllowResend & MaybeHasResendError;

type LastVerdictType = SmtCache.ReviewsResponseSolution & MaybeHasLabel;

const LastVerdict: React.FC<LastVerdictType> = (
    {
        verdict: _verdict,
        score,
        maxScore,
        label = '',
        review = {},
    }
) => {
    const {message, info} = review;
    const result = info?.result ?? '';

    const isPresentationError = _verdict === 'wrong' && result === 'custom';

    const verdict =
        isPresentationError
            ? 'pe'
            : _verdict
    ;

    const scoreText =
        `has_solution_${verdict}` in translations.common.save_button
            ? `common.save_button.has_solution_${verdict}`
            : ''
    ;

    const hasShowScoreOnlineverdicts = !['pe', 'notReady'].includes(verdict);

    const hasShowMessaqePE = isPresentationError && !!message && !label;

    const hasScoreText = scoreText && !label;

    return (
        <VBox>
            <HBox>
                <span className = { block.el('verdicts-last-score') +
                                    block.el('verdicts-last-score').mod(`${verdict}`)}
                >
                    {
                        hasShowScoreOnlineverdicts &&
                        <>
                           {score} <SmtLocaleString id="value" k="programming.onlineverdicts.verdictsScoreFrom"/> {`${maxScore}` + (scoreText ? '.' : '')}
                        </>
                    }
                    {
                        hasScoreText &&
                        <SmtLocaleString k={scoreText}/>
                    }

                </span>

                <SGap/>

                {   label
                        ? <SmtLocaleString k={label} />
                        : [ 'wrong', 'partly', 'pe' ].includes(verdict)
                            ? <SmtLocaleString k="common.save_button.has_solution_again" />
                            : null
                }
            </HBox>
            {
                hasShowMessaqePE &&
                <HBox className={'text-xs'}>
                    {message}
                </HBox>
            }
        </VBox>
    )
};

type TryLockProps = TaskOnlineStateType & hasIsSavingInProgress & MaybeHasLabel & MaybeHasAllowResend & MaybeHasResendError;

const TryLock: React.FC<TryLockProps> = ({isSavingInProgress, label, hasNextTryLock, hasPending, hasSolutions, hasSolutionOk, hasSolutionLastNotOk, solutions, children, allowResend, resendError }) => {

    const bestSolution  = hasSolutions && ([...solutions].reverse().find((solution) => solution.current)
                                           || solutions[solutions.length - 1])
    ;

    return(
         <HBox className="task__footer-label ">
             {
                 (hasPending || isSavingInProgress)
                     ? allowResend
                         ? resendError
                              ? <VBox>
                                   <span className={block.el('verdicts-last-score').mod('wrong')}>
                                       <SmtLocaleString k={"common.task.resend_error"} />
                                   </span>
                                   <SmtLocaleString k={"common.task.resend_try_again"} />
                                </VBox>
                              : <HBox style={{alignItems: "center"}}>
                                    <CircularProgress size={'xs'}/>
                                    <MGap/>
                                    {children}
                                </HBox>
                         : children
                     : (
                         hasNextTryLock
                             ? hasSolutionLastNotOk
                                 ? <LastVerdict {...bestSolution}/>
                                 : children
                             : hasSolutionOk
                                 ? <LastVerdict {...bestSolution}/>
                                 : hasSolutions
                                     ? <LastVerdict {...bestSolution} label={label}/>
                                      : children
                     )
             }
         </HBox>
    )
};

const TaskSaveButtonLabel: React.FC<TaskSaveButtonLabelProps> = ({ onlineState, isSavingInProgress, label, allowResend, resendError}) =>
    <TryLock {...onlineState} isSavingInProgress={isSavingInProgress} label={label} allowResend={allowResend} resendError={resendError}>
        <SmtLocaleString k={label} />
    </TryLock>
;


export {TaskSaveButtonLabel};
