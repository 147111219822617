import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const ArchivedIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19.15 3.55L20.54 5.23C20.83 5.57 21 6.02 21 6.5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V6.5C3 6.02 3.17 5.57 3.46 5.23L4.84 3.55C5.12 3.21 5.53 3 6 3H18C18.47 3 18.88 3.21 19.15 3.55ZM5.93 4L5.12 5H18.87L17.93 4H5.93ZM16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L11 16.4142L7.79289 13.2071C7.40237 12.8166 7.40237 12.1834 7.79289 11.7929C8.18342 11.4024 8.81658 11.4024 9.20711 11.7929L11 13.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289Z"
              fill="#666666"
        />
    </svg>


</SvgIcon>;


export default ArchivedIcon;
