import AjaxWrapper                              from "Cheops/AjaxWrapper";
import {elementsCacheByHash, imagesCacheById}   from "Cheops/actions/noopolisCache";
import {LANDING_COURSE_TYPE}                    from "Cheops/constants";

type strNumber = string | number;

const VERSION           = CONFIG.Version ? `/${CONFIG.Version}` : "";
const CONTENT_VERSION   = CONFIG.ContentVersion ? "/" + CONFIG.ContentVersion : "";
const NOO_API_URL       = CONFIG.Api?.noopolis?.url ? `${CONFIG.Api.noopolis.url}${VERSION}`: "";
const TEACHER_API_URL   = `${NOO_API_URL}/teacher/v0`;
const WITH_AUTH         = {send_auth_token: true};
const images_cache      = imagesCacheById;

export interface IGetProgrammingAnswerTestResults {
    courseId:       strNumber,
    moduleId:       strNumber,
    taskVersionId:  strNumber,
    submissionId:   strNumber
}

export interface IGetProgrammingAnswerTestResultsProtocol extends IGetProgrammingAnswerTestResults {
    testNo:          strNumber
}

export type TGetProgrammingAnswerTestResultsReportReturn =
    Promise<NooBack.TestsReportResponse>
;

export type TGetProgrammingAnswerTestResultsProtocolReturn =
    Promise<NooBack.TestProtocolResponse>
    ;

export type GetModuleContentDataHandlerResult = {
    data: NooBack.Content.ContentModule,
    hash: string
};

export interface IVideoResolveContainer {
    urlVideo: string
}

export type TGetVideoResolveContainer = (args: IVideoResolveContainer) =>
    Promise<Common.Video.VideoResolveContainer>
;

/**
 * @deprecated no spec
 */
type GetContentByHash_CourseBlock_ResultItem = {
    image?: string;
    [k: string]: unknown;
};
/**
 * @deprecated no spec
 */
type GetContentByHash_CourseBlock_Result = Record<string, GetContentByHash_CourseBlock_ResultItem>;


/**
 * @deprecated no spec
 */
namespace _NooBackContent {
/**
 * @deprecated no spec
 */
type ContentResponsePageBlocks = Iterable<ContentResponsePageBlock>;
/**
 * @deprecated no spec
 */
interface ContentResponsePageBlockColumnsBlock {
    image?: string
}
/**
 * @deprecated no spec
 */
interface ContentResponsePageBlock {
    text?: {
        text?: {
            image?: string
        }
    },
    lecturers?: {
        list?: NooBack.Content.LecturersList
    },
    columns?: {
        blocks?: Iterable<ContentResponsePageBlockColumnsBlock>
    }
}
/**
 * @deprecated no spec
 */
interface ContentResponsePageMaybeHasBlocks {
    blocks?: ContentResponsePageBlocks
}
/**
 * @deprecated no spec
 */
export interface ContentResponsePage {
    page?: ContentResponsePageMaybeHasBlocks
}
}


export type GetElementContentDataHandlerResult = {
    data: NooBack.Content.ContentElement,
    hash: string
}

export type GetCourseContentDataHandlerResultCourse_Module = Record<string, NooBack.Content.CourseModuleItem>;

export type GetCourseContentDataHandlerResultCourse = Omit<NooBack.Content.ContentCourse, 'module' | 'lecturers' | 'landingBlocks'> &
    {
        hash:           string,
        module?:        GetCourseContentDataHandlerResultCourse_Module,
        lecturers?:     NooBack.Content.LecturersList,
        landingBlocks?: LandingWidgetTypeDeprecated[]
    }
;

export type GetCourseContentDataHandlerResult = {
    course: GetCourseContentDataHandlerResultCourse
}

export type LandingWidgetTypeDeprecated = {
    text?: {
        text?: {
            image?: string
        }
    },
    lecturers?: {
        list?: NooBack.Content.LecturersList
    },
    columns?: {
        blocks?: Iterable<{image?: string}>
    }
}

export type getFullCourseListResult = {
    type: "GET_FULL_COURSE_LIST",
    payload: Promise<NooBack.ListFullResponseObject>
}

export const getContentImageUrl = (image_id: string) => {
    return CONFIG.Api.noopolis.url + CONTENT_VERSION + '/content/_image/' + image_id;
};

export const extToMIMETypeDict = {
    svg:  'image/svg+xml',
    png:  'image/png',
    gif:  'image/gif',
    jpeg: 'image/jpeg',
    jpg:  'image/jpeg',
}

export const getContentImage = async (image_id: string, content_type?: string): Promise<string> => {
    if (!image_id) {
        return image_id
    }
    let options: BlobPropertyBag = {};
    if (images_cache[image_id]) {
        if (typeof images_cache[image_id] === "object") {
            return await images_cache[image_id];
        }
        return images_cache[image_id];
    }

    const ext = (typeof image_id === "string" && image_id.includes('.'))
        ? image_id.split('.')[1]
        : false
    ;

    if (!!ext && (ext in extToMIMETypeDict)) {
        options['type'] = extToMIMETypeDict[ext as keyof typeof extToMIMETypeDict];
    } else if (content_type) {
        options['type'] = content_type;
    }
    let image_data_promise = AjaxWrapper.get(
        CONFIG.Api.noopolis.url + CONTENT_VERSION + '/content/_image/' + image_id,
        {},
        {
            headers: {
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json;charset=utf-8'
            },
            dataHandler: (data: string) => {
                let image_url = window.URL.createObjectURL(
                    new Blob([data], options)
                );
                return image_url;
            },
            responseType: 'blob'
        }
    );

    images_cache[image_id] = image_data_promise as Promise<string>;
    let image_url = await image_data_promise;
    images_cache[image_id] = image_url;
    return image_url as string;
};

export const getProgrammingAnswerTestResultsReport = (
        {
            courseId,
            moduleId,
            taskVersionId,
            submissionId
        }: IGetProgrammingAnswerTestResults
    ): TGetProgrammingAnswerTestResultsReportReturn =>

        AjaxWrapper.get(
            `${NOO_API_URL}/course/${courseId}/learn/${moduleId}/${taskVersionId}/report/${submissionId}`,
            {},
            {...WITH_AUTH}
        )
;

export const getProgrammingAnswerTestResultsProtocol = (
        {
            courseId,
            moduleId,
            taskVersionId,
            submissionId,
            testNo
        }: IGetProgrammingAnswerTestResultsProtocol
    ): TGetProgrammingAnswerTestResultsProtocolReturn =>

        AjaxWrapper.get(
            `${NOO_API_URL}/course/${courseId}/learn/${moduleId}/${taskVersionId}/report/${submissionId}/${testNo}`,
            {},
            {...WITH_AUTH}
        )
;

export const getVideoResolveContainer: TGetVideoResolveContainer = ({urlVideo}) => {
    const videoResolveRequest: Common.Video.VideoResolveRequest = {url: urlVideo};
    return AjaxWrapper.post(
        `${NOO_API_URL}/api/resolve-video`,
        {...videoResolveRequest},
        {...WITH_AUTH}
    )
};

export const getContestsPageData = (contest_page_name: string): Promise<NooBack.ContestsPage.Static.ContestsPage> => {
    return AjaxWrapper.get(`${NOO_API_URL}/api/contests-page/${contest_page_name}`, {}, {...WITH_AUTH})
};

export const getContestsPageStatuses = (contest_page_name: string): Promise<NooBack.ContestsPage.Status.ContestsPageStatus> => {
    return AjaxWrapper.post(`${NOO_API_URL}/api/contests-page/${contest_page_name}/status`, {}, {...WITH_AUTH})
};

export const getTeacherCourses = (): Promise<NooBack.Teacher.Methods.CoursesGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/courses`, {}, {...WITH_AUTH})
};

export const getTeacherClasses = (): Promise<NooBack.Teacher.Methods.CourseClassesGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/classes`, {}, {...WITH_AUTH})
};

export const createTeacherClass = (title: NooBack.Teacher.ClassTitle): Promise<NooBack.Teacher.Methods.ClassPutResponse> => {
    return AjaxWrapper.put(`${NOO_API_URL}/teacher/v0/classes`, {title}, {...WITH_AUTH})
};

export const addClassesToCourse = (data: NooBack.Teacher.Methods.CourseClassPutRequest, courseId: string): Promise<NooBack.Teacher.Methods.CourseClassPutResponse> => {
    return AjaxWrapper.put(`${NOO_API_URL}/teacher/v0/courses/${courseId}/classes`, data, {...WITH_AUTH})
};

export const getClassStudents = (classId: NooBack.Teacher.ClassId): Promise<NooBack.Teacher.Methods.ClassStudentsGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/classes/${classId}/students`, {}, {...WITH_AUTH})
};

export const getClassInfo = (classId: NooBack.Teacher.ClassId): Promise<NooBack.Teacher.Methods.ClassGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/classes/${classId}`, {}, {...WITH_AUTH})
};

export const addClassGrantToTeachers = (classId: NooBack.Teacher.ClassId, data: NooBack.Teacher.Methods.ClassesTeachersGrantRequest): Promise<NooBack.Teacher.Methods.ClassesTeachersGrantResponse> => {
    return AjaxWrapper.post(`${NOO_API_URL}/teacher/v0/classes/${classId}/teachers/grant`, data, {...WITH_AUTH})
};

export const getClassInviteLink = (classId: NooBack.Teacher.ClassId): Promise<NooBack.Teacher.Methods.ClassGetLinkResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/classes/${classId}/link`, {}, {...WITH_AUTH})
};

export const getCoursesListForClass = (classId: NooBack.Teacher.ClassId): Promise<NooBack.Teacher.Methods.ClassCoursesGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/classes/${classId}/courses/`, {}, {...WITH_AUTH})
};

export const getTokenToSeeAsStudent = (studentId: NooBack.Teacher.StudentId): Promise<NooBack.Teacher.Methods.StudentsImpersonateResponse> => {
    return AjaxWrapper.post(`${NOO_API_URL}/teacher/v0/students/${studentId}/impersonate`, {}, {...WITH_AUTH})
};

export const editStudentNameInClass = (classId: NooBack.Teacher.ClassId, studentId: NooBack.Teacher.StudentId, data: NooBack.Teacher.Methods.ClassStudentPatchRequest): Promise<NooBack.Teacher.Methods.ClassStudentPatchResponse> => {
    return AjaxWrapper.patch(`${NOO_API_URL}/teacher/v0/classes/${classId}/students/${studentId}`, data, {...WITH_AUTH})
};

export const editClassName = (classId: NooBack.Teacher.ClassId, data: NooBack.Teacher.Methods.ClassPatchRequest ): Promise<NooBack.Teacher.Methods.ClassPatchResponse> => {
    return AjaxWrapper.patch(`${NOO_API_URL}/teacher/v0/classes/${classId}`, data, {...WITH_AUTH})
};

export const deleteStudentFromClass = (classId: NooBack.Teacher.ClassId, studentId: NooBack.Teacher.StudentId): Promise<NooBack.Teacher.Methods.ClassGetResponse> => {
    return AjaxWrapper.delete(`${NOO_API_URL}/teacher/v0/classes/${classId}/students/${studentId}`, {}, {...WITH_AUTH})
};

export const getClassesInCourse = (courseId: string): Promise<NooBack.Teacher.Methods.CourseClassesGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/courses/${courseId}/classes`, {}, {...WITH_AUTH})
};

export const deleteClassFromCourse = (courseId: string, classId: NooBack.Teacher.ClassId): Promise<void> => {
    return AjaxWrapper.delete(`${NOO_API_URL}/teacher/v0/courses/${courseId}/classes/${classId}`, {}, {...WITH_AUTH})
};

// export const getCourseModules = (courseId: string): Promise<NooBack.Teacher.Methods.CourseModulesGetResponse> => {
//     return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/courses/${courseId}/modules`, {}, {...WITH_AUTH})
// };

export const getTeacherCourseInfo = (courseId: string, classId?: string): Promise<NooBack.Teacher.Methods.CoursesTheGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/courses/${courseId}${ classId ? `?classId=${classId}` : '' }`, {}, {...WITH_AUTH})
};

export const getModuleClassProgress = (moduleId: string, data: NooBack.Teacher.Methods.ModuleProgressRequest = {}): Promise<NooBack.Teacher.Methods.ModuleProgressResponse> => {
    return AjaxWrapper.post(`${NOO_API_URL}/teacher/v0/modules/${moduleId}/progress`, data, {...WITH_AUTH})
};

export const getModuleClassProgressFlat = (moduleId: string, data: NooBack.Teacher.Methods.ModuleProgressFlatRequest = {}): Promise<NooBack.Teacher.Methods.ModuleProgressFlatResponse> => {
    return AjaxWrapper.post(`${NOO_API_URL}/teacher/v0/modules/${moduleId}/progress-flat`, data, {...WITH_AUTH})
};

export const getTeacherModuleInfo = (moduleId: string): Promise<NooBack.Teacher.Methods.ModuleResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/teacher/v0/modules/${moduleId}`, {}, {...WITH_AUTH})
};

export const getTeacherModuleMaterials = (moduleId: string): Promise<NooBack.Teacher.Methods.ModuleMaterialsResponse> => {
    return AjaxWrapper.get(`${TEACHER_API_URL}/modules/${moduleId}/materials`, {}, {...WITH_AUTH})
};

export interface IPostTeacherAccessArgs {
    id: string;
    classes: Array<NooBack.Teacher.ClassId>;
    courseId?: string;
};
export interface IGetTeacherAccessArgs {
    id: string;
    courseId?: string;
};

export const addTeacherModuleAccess = ({id, classes}: IPostTeacherAccessArgs): Promise<NooBack.Teacher.Methods.ModuleAccessResponse> => {
    return AjaxWrapper.post(`${TEACHER_API_URL}/modules/${id}/access`, {classes}, {...WITH_AUTH})
};

export const cancelTeacherModuleAccess = ({id, classes}: IPostTeacherAccessArgs): Promise<NooBack.Teacher.Methods.ModuleAccessCancelResponse> => {
    return AjaxWrapper.post(`${TEACHER_API_URL}/modules/${id}/access-cancel`, {classes}, {...WITH_AUTH})
};

export const getTeacherModuleAccess = ({id}: IGetTeacherAccessArgs): Promise<NooBack.Teacher.Methods.ModuleAccessGetResult> => {
    return AjaxWrapper.get(`${TEACHER_API_URL}/modules/${id}/access`, {}, {...WITH_AUTH})
};

export const addTeacherContestAccess = ({courseId, id, classes}: IPostTeacherAccessArgs): Promise<NooBack.Teacher.Methods.ContestAccessPostResponse> => {
    return AjaxWrapper.post(`${TEACHER_API_URL}/courses/${courseId}/contests/${id}/access`, {classes}, {...WITH_AUTH})
};

export const getTeacherContestAccess = ({courseId, id}:IGetTeacherAccessArgs): Promise<NooBack.Teacher.Methods.ContestAccessGetResult> => {
    return AjaxWrapper.get(`${TEACHER_API_URL}/courses/${courseId}/contests/${id}/access`, {}, {...WITH_AUTH})
};

export const getContestReport = (courseId: string, contestId: string) => {
    return AjaxWrapper.get(`${TEACHER_API_URL}/courses/${courseId}/contests/${contestId}/report`, {},
        {
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: "blob",
            ...WITH_AUTH
        })
    ;
};

export const getInviteToClassInfo = (hash: NooBack.Student.InviteHash): Promise<NooBack.Student.Methods.InviteGetResponse> => {
    return AjaxWrapper.get(`${NOO_API_URL}/student/v0/invite/${hash}`, {}, {...WITH_AUTH})
};

export const acceptInvitationToClass = (hash: NooBack.Student.InviteHash): Promise<void> => {
  return AjaxWrapper.post(`${NOO_API_URL}/student/v0/invite/${hash}/accept`, {}, {...WITH_AUTH})
};

export const declineInvitationToClass = (hash: NooBack.Student.InviteHash): Promise<void> => {
  return AjaxWrapper.post(`${NOO_API_URL}/student/v0/invite/${hash}/decline`, {}, {...WITH_AUTH})
};

export const deleteTokenToSeeAsStudent = (): Promise<NooBack.Teacher.Methods.ImpersonateDeleteResult> => {
    return AjaxWrapper.delete(`${NOO_API_URL}/teacher/v0/impersonate`, {}, {...WITH_AUTH});
};

export const getContentByHash_Element = async (element_hash: string) => {
    const result = element_hash in elementsCacheByHash
        ? elementsCacheByHash[element_hash]
        : await getContentByHash<NooBack.Content.ContentElement>(
            element_hash,
            {
                dataHandler: getElementContentDataHandler(element_hash)
            }
        )
    ;
    elementsCacheByHash[element_hash] = result;
    return result;
}

export const getContentByHash_Course = async (course_hash: string) => {
    const result = course_hash in elementsCacheByHash
        ? elementsCacheByHash[course_hash]
        : await getContentByHash<NooBack.Content.ContentElement>(
            course_hash,
            {
                dataHandler: getCourseContentDataHandler(course_hash)
            }
        )
    ;
    elementsCacheByHash[course_hash] = result;
    return result;
}

export const getContentByHash = <T>(hash: string, params = {}): Promise<T> =>
    AjaxWrapper.get(
        `${CONFIG.Api.noopolis.url}${CONTENT_VERSION}/api/v2/content/${hash}`,
        {},
        params
    )
;

export const getElementContentDataHandler = (element_hash: string) =>
    (data: NooBack.Content.Content) : GetElementContentDataHandlerResult => {
    const {element, widget} = data as NooBack.Content.ContentResponseElement;
    const result: GetElementContentDataHandlerResult = {
        data: element || widget as NooBack.Content.ContentElement,
        hash: element_hash
    };
    return result;
}

export const getCourseContentDataHandler_lecturers =  (lecturers: NooBack.Content.LecturersList): Promise<NooBack.Content.LecturersList> =>
    Promise.all(
        lecturers.map(
            async (lecturer): Promise<NooBack.Content.Lecturer> => ({
                ...lecturer,
                avatar: await getContentImage(lecturer.avatar)
            })
        )
    )
;

export const getCourseContentDataHandler_module = (moduleList: NooBack.Content.CourseModuleList): Promise<GetCourseContentDataHandlerResultCourse_Module> =>
        Promise.all(
            moduleList.map(
                async module => ({
                    ...module,
                    lecturers: module?.lecturers
                        ? await getCourseContentDataHandler_lecturers(module.lecturers)
                        : void(0)
                })
            )
        ).then(
            (moduleListResolved) =>
                moduleListResolved.reduce(
                    (acc, module) => ({
                        ...acc,
                        [module.id]: module
                    }),
                    {}
                )
        )
;

export const getCourseContentDataHandler_landingBlocks = (landingBlocks: NooBack.Content.LandingWidget[]): Promise<NooBack.Content.LandingWidget[]> =>
    Promise.all(
        landingBlocks.map(
            async _block => {
                const block = {..._block} as LandingWidgetTypeDeprecated;
                if (block?.text?.text?.image) {
                    block.text.text.image = await getContentImage(block.text.text.image)
                }

                if (block.lecturers) {
                    block.lecturers.list = await getCourseContentDataHandler_lecturers(block.lecturers.list)
                }

                if (block.columns) {
                    for (let single_block of block.columns.blocks) {
                        if (single_block.image) {
                            single_block.image = getContentImageUrl(single_block.image);
                        }
                    }
                }
                return _block
            }
        )
    )
;

export const getCourseContentDataHandler =
    (hash: string) =>
    async ({course: responseCourse}: NooBack.Content.ContentResponseCourse) : Promise<GetCourseContentDataHandlerResult> => {

        const lecturers = 'lecturers' in responseCourse
            ? await getCourseContentDataHandler_lecturers(responseCourse.lecturers)
            : void(0)
        ;

        const module = 'module' in responseCourse
            ? await getCourseContentDataHandler_module(responseCourse.module)
            : void(0)
        ;

        const landingBlocks = 'landingBlocks' in responseCourse
            ? await getCourseContentDataHandler_landingBlocks(responseCourse.landingBlocks)
            : void(0)
        ;

        const course: GetCourseContentDataHandlerResultCourse = {
            ...responseCourse,
            hash,
            lecturers,
            module,
            landingBlocks
        };

        return {course};
}

export const getFullCourseList = (): getFullCourseListResult => ({
    type: "GET_FULL_COURSE_LIST",
    payload: AjaxWrapper.get( `${CONFIG.Api.noopolis.url}${VERSION}/course/list-full`, {}, {send_auth_token: true})
});

export const getContentByHash_MainContent = async (hash: string) =>
    await getContentByHash<NooBack.Content.ContentResponsePage>(
        hash,
        {
            dataHandler: getMainContentDataHandler
        }
    )
;

export const getMainContentDataHandler = async (data: _NooBackContent.ContentResponsePage): Promise<_NooBackContent.ContentResponsePage> => {
    const page = {...data.page};
    if (page.blocks) {
        for (let block of page.blocks) {
            if (block.text && block.text.text.image) {
                block.text.text.image = await getContentImage(block.text.text.image)
            }
            if (block.lecturers) {
                block.lecturers.list = await getCourseContentDataHandler_lecturers(block.lecturers.list);
            }
            if (block.columns) {
                for (let single_block of block.columns.blocks) {
                    if (single_block.image) {
                        single_block.image = await getContentImage(single_block.image)
                    }
                }
            }
        }
    }
    return {page};
}

const getCourseContentDataHandler_variant = (variant: NooBack.Content.ModuleVariantsList): Promise<NooBack.Content.ModuleVariantsList> =>
    Promise.all(
        variant.map(
            async variantItem => ({
                ...variantItem,
                icon: await getContentImage(variantItem.icon, 'image/svg+xml')
            })
        )
    )
;

export const getModuleContentDataHandler = (hash: string) =>
    async ({module: responseModule}: NooBack.Content.ContentResponseModule) : Promise<GetModuleContentDataHandlerResult> => {
        const module: NooBack.Content.ContentModule = {
            ...responseModule,
            info: {
                ...responseModule.info,
                displaySettings: responseModule.info.displaySettings || {},
                lecturers: 'lecturers' in responseModule.info
                                ? await getCourseContentDataHandler_lecturers(responseModule.info.lecturers)
                                : void(0)
            },
            variant: 'variant' in responseModule
                    ? await getCourseContentDataHandler_variant(responseModule.variant)
                    : void(0)
        }
        return {
            data: module,
            hash
        };
    }
;

export const getContentByHash_Module = async (module_hash: string) => {
    const result = module_hash in elementsCacheByHash
        ? elementsCacheByHash[module_hash]
        : await getContentByHash<NooBack.Content.ContentElement>(
            module_hash,
            {
                dataHandler: getModuleContentDataHandler(module_hash)
            }
        )
    ;
    elementsCacheByHash[module_hash] = result;
    return result;
}

export const getContentByHash_CourseBlock_dataHandler = (element_type: string, element_hash: string) =>
async (data: GetContentByHash_CourseBlock_Result) => {
    const [firstK] = Object.keys(data);
    const item     = data[firstK];
    const result   = { data: item, hash: element_hash };
    const needProcessImage = element_type === LANDING_COURSE_TYPE.MY && item && item.image && !item.image.startsWith('blob:');
    if (needProcessImage) {
        item.image = await getContentImage(item.image);
    }
    return result;
}

export const getContentByHash_CourseBlock = async (element_type: string, element_hash: string) => {
    const cacheId = `${element_type}_${element_hash}`;
    const result = cacheId in elementsCacheByHash
        ? elementsCacheByHash[cacheId]
        : await getContentByHash<NooBack.Content.ContentElement>(
            element_hash,
            {
                dataHandler: getContentByHash_CourseBlock_dataHandler(element_type, element_hash)
            }
        )
    ;
    elementsCacheByHash[cacheId] = result;
    return result;
}

export const POST_CourseSwitchStyle = (courseId: strNumber, moduleId: strNumber, style: string): Promise<NooBack.Module.Style.ModuleStyleSwitchResponse> => {
    const req: NooBack.Module.Style.ModuleStyleSwitchRequest = {style:{name:style}};
    return AjaxWrapper.post(`${NOO_API_URL}/course/${courseId}/learn/${moduleId}/switchStyle`, req, {...WITH_AUTH})
}

export const POST_CourseSwitchComplexity = (courseId: strNumber, moduleId: strNumber, complexity: number): Promise<NooBack.Module.Complexity.ModuleComplexitySwitchResponse> => {
    const req: NooBack.Module.Complexity.ModuleComplexitySwitchRequest = {complexity}
    return AjaxWrapper.post(`${NOO_API_URL}/course/${courseId}/learn/${moduleId}/switchComplexity`, req, {...WITH_AUTH})
}

export const getAnalogicTask = (courseId: strNumber, moduleId: strNumber, taskId: strNumber): Promise<SmtTask.AnalogicTask> => {
    return AjaxWrapper.get(`${NOO_API_URL}/course/${courseId}/learn/${moduleId}/task/${taskId}/analogicTask`, {}, {...WITH_AUTH})
};

export const getFractionLatex = (ratio: string): Promise<Common.Math.LatexResponse> =>
    AjaxWrapper.get(
        `${NOO_API_URL}/math/ratio/latex?ratio=${ratio}`,
        {},
        {...WITH_AUTH},
    )
;
