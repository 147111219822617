export default class CacheWrapper {

    static getItem(key) {

        let data = localStorage.getItem(key);

        if (!data) {
            return;
        }

        data = JSON.parse(data);

        if (typeof data === 'object' && data.cache_expires && data.cached_data) {
            let expires_date = new Date(data.cache_expires);
            let now = new Date();
            if (now > expires_date) {
                // if key is expired We can remove it;
                localStorage.removeItem(key);
                return;
            }

            data = data.cached_data;
        }

        return data;
    }

    /**
     * Saving data on local storage.
     * @param key
     * @param value
     * @param expires_date
     */
    static setItem(key, value, expires_date) {

        if (expires_date) {

            if (!(expires_date instanceof Date)) {
                expires_date = new Date(expires_date);
            }

            if (isNaN(expires_date.getTime())) {
                console.error('wrong expire data');
                return;
            }

            value = {
                cache_expires: expires_date,
                cached_data: value
            };
        }

        value = JSON.stringify(value);

        localStorage.setItem(key, value);
    }

}

