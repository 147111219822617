import React, {Fragment} from "react";
import BemClassName from "Cheops/BemClassName";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";


export default class DetailedAnswerMessage extends React.Component {

    static defaultProps = {
        message: null,
        element_learn: {},
        solution_counter: 1,
        comments_to_task: [],
    };


    render() {

        let message = this.props.message;
        let element_learn = this.props.element_learn;

        let scores_text = "";
        let message_scores_class = new BemClassName("detailed_answer_message__scores");

        let avatar = message.whoSet.firstName.charAt(0).toUpperCase() + message.whoSet.lastName.charAt(0).toUpperCase();
        let avatar_class = new BemClassName("detailed_answer_message__avatar");
        let user_name = `${message.whoSet.firstName} ${message.whoSet.lastName}`;

        let message_date_final_formatted = "";

        if (message.date) {

            let message_date = new Date(message.date);
            let message_date_final = new Date(message.date);
            let message_date_day = message_date.setHours(0, 0, 0, 0);
            let now = new Date();
            let today = now.setHours(0, 0, 0, 0);

            message_date_final_formatted = message_date_final.toLocaleString("ru", {
                day: "numeric",
                month: "long",
                hour: "2-digit",
                minute: "2-digit",
            });

            if (message_date_day < today) {

                message_date_final_formatted = message_date_final.toLocaleString("ru", {
                    day: "numeric",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                });

            }

        }

        let message_user_class = new BemClassName("detailed_answer_message__user");

        let message_content_text = message.text.split('\n').map((row, i) => <Fragment
            key={i}
        >
            <Marked>{row}</Marked>
            <br />
        </Fragment>);

        if (message.type === "solution") {

            const isRatedSolution = message.score !== undefined;

            if (element_learn.verdict === "notReady" && (!message.comments || !isRatedSolution)) {

                scores_text = <span>
                                Отправлено на проверку
                            </span>;

            }

            if (message.comments && isRatedSolution && !!element_learn.max) { // TODO find out this thing

                scores_text = `${message.score} из ${element_learn.max}`;
                message_scores_class.appendStatus("graduated");

            }

            message_content_text = <span>
                <span className="detailed_answer_message__content_text_solution">
                    {`Решение ${this.props.solution_counter}.`}
                </span>
                {' '}
                {message_content_text}
            </span>;

        }

        if (message.type === "answer") {

            avatar = <img src={require("Cheops/../img/lecturer_no_avatar_white.svg")} />;
            avatar_class.appendStatus("lecturer");
            message_user_class.appendStatus("lecturer");

            if (message.whoSet.middleName) {

                user_name = `${message.whoSet.firstName} ${message.whoSet.middleName} ${message.whoSet.lastName}`;

            }

        }

        return (
            <>
                <div className="detailed_answer_message__header">
                    <div className={message_user_class}>
                        <div className={avatar_class}>{avatar}</div>
                        <div className="detailed_answer_message__user_text">
                            <div className="detailed_answer_message__user_name">
                                {user_name}
                            </div>
                            <div className="detailed_answer_message__date">
                                {message_date_final_formatted}
                            </div>
                        </div>
                    </div>

                    {scores_text
                    && <div className={message_scores_class}>{scores_text}</div>}

                </div>

                <div className="detailed_answer_message__content">
                    <div className="detailed_answer_message__content_text">
                        {message_content_text}
                    </div>
                    {message.imgs && message.imgs.length > 0
                    && <div className="detailed_answer_message__content_imgs">

                        {message.imgs.map((img, j) => {

                            return <a key={j} className="detailed_answer_message__content_image"
                                      download={img.file_name}
                                      href={img.download_url}
                                      target="_blank"
                            >
                                {img.preview_url && <img alt="" src={img.preview_url} />}
                                {!img.preview_url
                                && <div className="detailed_answer__message_file_ext">
                                    ...
                                    {img.file_ext}
                                </div>}
                            </a>;

                        })}
                    </div>}
                </div>
            </>
        );

    }

}
