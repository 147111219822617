import React from "react";
import CircularProgress from "Smt/Ui";
import AjaxWrapper from "Smt/AjaxWrapper";
import {getContestIdAndSesionId} from "Smt/helpers";
import BemClassName from "Cheops/BemClassName";
import "./DetailedAnswer.less";
import {TaskAnswersData} from "Lab/Model/Task";
import AnswerType from "Cheops/components/ModulePassing/Answers/AnswerType";
import cloneDeep from "lodash/cloneDeep";
import {ReqResponse} from "Lab/Types";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

export interface DetailedAnswerSolution {

    message: string;
    attachments?: string[];

}

interface State {

    uploadingFiles: string[];
    uploadedFiles: Record<string, string>;
    isLoading: boolean;
    errorMessage: string;
}

interface Props {

    contestId?: number | string;
    taskToken?: string;
    answersData: TaskAnswersData;

    preventFileUpload?: boolean;
    userAnswer?: DetailedAnswerSolution;
    resultData?: any;
    showRightAnswer?: boolean;

    onAnswer?(userAnswer: DetailedAnswerSolution): void;

    customGetFileInfoAction?(contestId: number | string, hash: string): Promise<ReqResponse<string>>;

}

export default class DetailedAnswer extends React.Component<Props> implements AnswerType<DetailedAnswerSolution> {

    static defaultProps: Partial<Props> = {
        userAnswer: {message: ""},
        showRightAnswer: false,
        onAnswer: () => {
        },
        customGetFileInfoAction: async (contestId, hash) => AjaxWrapper.post(`${CONFIG.Api.webPortal?.url}/test/download-token/${contestId}/${hash}`),
    };


    state: State = {
        uploadingFiles: [],
        uploadedFiles: {},
        isLoading: true,
        errorMessage: null,
    };

    async UNSAFE_componentWillMount(): Promise<void> {

        let {contestId, userAnswer, customGetFileInfoAction, resultData} = this.props;

        if (!contestId) {

            ({contestId} = getContestIdAndSesionId());

        }

        const {uploadedFiles} = this.state;

        if (userAnswer.attachments) {

            for (const hash of userAnswer.attachments) {

                uploadedFiles[hash] = (await customGetFileInfoAction(contestId, hash)).success;

            }

        }

        if (resultData && resultData.answer && resultData.answer.solution.attachments) {

            for (let hash of resultData.answer.solution.attachments) {

                uploadedFiles[hash] = (await customGetFileInfoAction(contestId, hash)).success;

            }

        }


        this.setState({uploadedFiles, isLoading: false});

    }

    getIsAnswerFilled(): boolean {

        const {userAnswer} = this.props;

        return userAnswer?.message?.length > 0 || userAnswer?.attachments?.length > 0;

    }

    getAnswerHasError = (): boolean => false;


    getAreFilesAllowed = (): boolean => {

        const {answersData, resultData} = this.props;

        if (resultData) {

            return false;

        }

        return !!answersData.areFilesAllowed;

    };

    onInputFileClick = (event: React.MouseEvent): void => {

        const {preventFileUpload} = this.props;

        if (preventFileUpload) {

            event.preventDefault();

        }

    };

    onInputFileChange = async (event: React.ChangeEvent): Promise<void> => {

        const {uploadingFiles} = this.state;
        const {taskToken, preventFileUpload} = this.props;

        if (preventFileUpload) {

            event.preventDefault();
            return;

        }

        const input = event.target as EventTarget & HTMLInputElement;

        uploadingFiles.push(input.files[0].name);

        const fileIndex = uploadingFiles.length - 1;

        this.setState({uploadingFiles});

        const getTokenResult = await AjaxWrapper.post(
            `${CONFIG.Api.webPortal.url}/test/upload-token`,
            {},
            {auth_token: taskToken},
        );


        if (!getTokenResult.success) {

            return;

        }

        let formData = new FormData();
        formData.append('token', getTokenResult.success.token);
        formData.append('file', input.files[0]);


        try {

            const uploadResponse = await AjaxWrapper.post(`${CONFIG.Api.storage.url}/upload`, formData, {
                throwExceptionOnError: true,
                headers: {
                    Accept: 'application/json;charset=utf-8',
                    'Content-Type': 'multipart/form-data',
                },
            });

            this.storeImage(uploadResponse.success, fileIndex);

        } catch (e) {

            let message = "common.detailed_answer.cant_load";

            if (e.response.data.error) {

                message = e.response.data.error[0].key || e.response.data.error[0].message;

            }

            if (e.response && e.response.status === 413) {

                message = "common.detailed_answer.cant_load_size";

            }

            const _uploadingFiles = cloneDeep(uploadingFiles);

            _uploadingFiles.splice(_uploadingFiles.indexOf(input.files[0].name), 1);

            this.setState({uploadingFiles: _uploadingFiles, errorMessage: message});

        }

        input.value = '';

    };


    // TODO type
    storeImage = (imgData: { hash: string }, index: number): void => {

        const {uploadingFiles, uploadedFiles} = this.state;

        const {userAnswer} = this.props;

        uploadingFiles.splice(index, 1);
        userAnswer.attachments.push(imgData.hash);

        // TODO
        // @ts-ignore
        uploadedFiles[imgData.hash] = imgData;

        this.props.onAnswer(userAnswer);
        this.setState({uploadingFiles, uploadedFiles});

    };


    prepareUserAnswer(userAnswer: DetailedAnswerSolution): DetailedAnswerSolution {


        if (userAnswer.attachments?.length > 0 || userAnswer.message?.length > 0) {

            return userAnswer;

        }

        return null;

    }

    deleteImage = (index: number): void => {

        const {userAnswer, onAnswer} = this.props;

        userAnswer.attachments.splice(index, 1);

        const _userAnswer = this.prepareUserAnswer(userAnswer);

        onAnswer(_userAnswer);

    };

    textAreaChange = (value: string): void => {

        let {userAnswer, onAnswer} = this.props;

        userAnswer.message = value;

        const _userAnswer = this.prepareUserAnswer(userAnswer);

        onAnswer(_userAnswer);

    };

    render(): React.ReactNode {

        const {isLoading, uploadedFiles, errorMessage} = this.state;
        const {resultData, userAnswer, showRightAnswer} = this.props;

        const allowFileUpload = this.getAreFilesAllowed();

        if (isLoading) {

            return <div className="detailed_answer__content">
                <form className="detailed_answer__message_form">
                    <CircularProgress centerOfWindow />
                </form>
            </div>;

        }

        let wrapperClass = new BemClassName("detailed_answer");


        if (resultData) {

            wrapperClass.appendStatus("has_result");
            wrapperClass.appendStatus(`verdict-${resultData.verdict}`);

        }

        let {attachments} = userAnswer;


        if (resultData?.answer?.solution.attachments) {

            attachments = resultData.answer.solution.attachments;

        }

        if (!attachments) {

            attachments = [];

        }

        return <div className={wrapperClass.toString()}>
            <form className="detailed_answer__message_form">
                {!resultData
                && <>
                    <div className="detailed_answer__message_form_header">
                        <div className="detailed_answer__message_form_title">
                            <SmtLocaleString k="common.detailed_answer.solution" id="value" />
                        </div>
                    </div>
                    <textarea
                        value={userAnswer.message || ''}
                        onChange={(e) => this.textAreaChange(e.target.value)}
                    />
                </>}

                {
                     resultData && resultData.answer
                        && <div className="detailed_answer__result_answer">
                            {
                                showRightAnswer
                                ? resultData.answer.solution?.length > 0 && resultData.answer.solution[0]
                                : !!resultData.answer.solution.message && resultData.answer.solution.message
                            }
                        </div>
                }

                {(allowFileUpload || attachments.length > 0)
                && <div className="detailed_answer__message_form_footer">
                    <div>

                        {attachments.map((fileHash, i) => {

                            // TODO
                            // @ts-ignore
                            if (!uploadedFiles[fileHash]) {

                                return null;

                            }

                            // TODO
                            // @ts-ignore
                            const fileInfo = this.state.uploadedFiles[fileHash].info;

                            let previewUrl = null;
                            let downloadUrl = `${CONFIG.Api.storage.url}/download/${fileInfo.token}`;

                            let fileExt = "file";

                            if (fileInfo.preview) {

                                previewUrl = `${CONFIG.Api.storage.url}/download/${fileInfo.preview}`;

                            } else {

                                fileExt = "";

                                let fileName = fileInfo.name;

                                if (fileName.includes(".")) {

                                    fileExt = fileInfo.name.split(".").pop();

                                    if (fileExt.length > 4) {

                                        fileExt = "";

                                    }

                                }

                            }

                            return <a
                                key={i}
                                className="detailed_answer__message_image"
                                href={downloadUrl}
                                download={fileInfo.name}
                                target="_blank"
                            >
                                {previewUrl && <img alt="" src={previewUrl} />}

                                {!previewUrl
                                && <div className="detailed_answer__message_file_ext">{`...${fileExt}`}</div>}

                                {!resultData
                                && <div className="detailed_answer__message_image_close"
                                        onClick={(e) => {

                                            e.preventDefault();
                                            this.deleteImage(i);

                                        }}
                                />}

                            </a>;

                        })}

                        {this.state.uploadingFiles.map((file, i) => {

                            return (
                                <div key={i} className="detailed_answer__message_image ">
                                    <CircularProgress marginCenter />
                                </div>
                            );

                        })}

                    </div>
                    {!!errorMessage
                    && <div className="detailed_answer__error">
                        <SmtLocaleString k={errorMessage} id="value" />
                        <div
                            className="detailed_answer__error_close"
                            onClick={() => this.setState({errorMessage: null})}
                        />
                    </div>}
                    {!errorMessage && allowFileUpload
                    && <label className="detailed_answer__message_form_add_file" onClick={this.onInputFileClick}>
                        <SmtLocaleString k="common.detailed_answer.add_file" id="value" />
                        <input type="file" onChange={this.onInputFileChange} />
                        <img src={require("Smt/../img/clip.svg")} />
                    </label>}

                </div>}

            </form>
        </div>;

    }

}
