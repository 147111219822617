import * as wrongPallete     from '@sirius/ui-lib/src/styles/values/colors-status-wrong.json';
import * as goodPallete      from '@sirius/ui-lib/src/styles/values/colors-status-good.json';
import * as attentionPallete from '@sirius/ui-lib/src/styles/values/colors-status-attention.json';

export const YANDEX_METRIKA_LAB = {
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        trackHash: true,
    },
};

export const ADD_TASK_IN_TEXT_AREA_TUTORIAL = "https://yadi.sk/i/a1YRjxxKwQTCJw";
export const ADD_FILE_WITH_KEYS_TUTORIAL = "https://edu.sirius.online/noo-back/content/_image/bdb1467dcc038a10427e0246806f85af535f299a";

export const COURSE_CARD_COLOR = [
    "#6F61F2",
    "#625BA6",
    "#5E78F2",
    "#4A69FF",
    "#5969B2",
    "#456DE5",
    "#455A99",
    "#3080F2",
    "#2273E5",
    "#3E6EB2",
    "#178FE5",
    "#3D7399",
    "#0A92BF",
    "#467180",
    "#178E99",
    "#3F6E73",
    "#238C83",
    "#386662",
    "#24664A",
    "#426657",
    "#208048",
    "#38664B",
    "#347339",
    "#3A593D",
    "#406633",
    "#45593E",
    "#56732E",
    "#55663D",
    "#626633",
    "#565936",
    "#73622E",
    "#665933",
    "#99600F",
    "#805A26",
    "#99490F",
    "#804B26",
    "#993D1F",
    "#803D26",
    "#CC473D",
    "#993026",
    "#8C3931",
    "#992626",
    "#8C3131",
    "#992639",
    "#8C3140",
    "#8C314F",
    "#733449",
    "#993667",
    "#803359",
    "#993D8A",
    "#804075",
    "#993D99",
    "#804080",
    "#963BA8",
    "#8B4599",
    "#A352CC",
    "#8C3EB2",
    "#7D4599",
    "#8F52CC",
    "#7C53A6",
    "#7A52CC",
    "#7759B2",
];

export const GRAPH_EXT_RELATION_SPACE = 50;
export const GRAPH_COLOR_GREEN = "#5BC36C";
export const GRAPH_COLOR_ORANGE = "#FF8A00";
export const GRAPH_COLOR_RED = "#FF5E73";

export const TASK_ANSWER_TYPES = {
    NONE: "none",
    STRING: "string",
    NUMBER: "number",
    RANGE: "range",
    SELECT: "select",
    SELECT_ON_IMAGE: "select_on_image",
    SINGLE: "single",
    MULTI: "multi",
    DETAILED: "detailed",
    DETAILED_NO_FILE: "detailed_nofile",
    MATCH: "match",
    COMPOSITE: "composite",
    LIST: "list",
    PROGRAMMING: "programming",
};

export const TASK_ANSWER_TYPE_LABELS = {
    [TASK_ANSWER_TYPES.NONE]: "Нет ввода ответа",
    [TASK_ANSWER_TYPES.STRING]: "Текстовое поле",
    [TASK_ANSWER_TYPES.NUMBER]: "Ввод числа",
    [TASK_ANSWER_TYPES.RANGE]: "Ввод числа (интервал)",
    [TASK_ANSWER_TYPES.SELECT]: "Выпадающий список",
    [TASK_ANSWER_TYPES.SINGLE]: "Выбор одного из списка",
    [TASK_ANSWER_TYPES.MULTI]: "Выбор нескольких из списка",
    [TASK_ANSWER_TYPES.DETAILED]: "Развёрнутый ответ",
    [TASK_ANSWER_TYPES.MATCH]: "Сопоставление",
    [TASK_ANSWER_TYPES.COMPOSITE]: "Много элементов ввода",
    [TASK_ANSWER_TYPES.LIST]: "Множественный ввод",
    [TASK_ANSWER_TYPES.PROGRAMMING]: "Ввод программного кода",
    [TASK_ANSWER_TYPES.SELECT_ON_IMAGE]: "Выбор на картинке",
};

export const TRANSFER_PROGRESS_TYPE = {
    MANUAL: 'manual',
    AUTOMATIC: 'automatic',
    NONE: 'none',
};

export const INTRO_TYPE_FRAME = {
    TEXT_WITH_IMAGE: 'text',
    TEXT_WITH_VIDEO: 'video',
};

export const REPORT_BUG_LVL = {
    WARNING: "warning",
    ERROR: "error",
    CRITICAL: "critical",
    ALERT: "alert",
};

export const REPORT_INITIATOR = {
    FRONT: "front",
    BACK: "back",
};

export const VERIFIED_ACCOUNT_STATUS = {
    PENDING: "pending",
    DECLINED: "declined",
    REJECTED: "rejected",
    VERIFIED: "verified",
};


export const Colors = {
    negative: wrongPallete["wrong-60"],
    middle: attentionPallete["attention-60"],
    live: goodPallete["good-60"],
    inactive_button: "#F5F5F5",
    activeButton: "#B0B0B0",
    main_button: "#333",
    black: "#000",
    chartBar: {
        main: "rgba(255,255,255,0.4)",
        main_weekday: "rgba(255,255,255,0.6)",
        last: "rgba(255,255,255,1)",
        stroke: "rgba(255,255,255,0)",
        highlight: "rgba(220,220,220,0.75)",
        highlightStroke: "rgba(220,220,220,1)",
        tooltipFillColor: "rgba(136, 136, 136, 0.4)",
    },
};

export const COLOR_LEFT_SIDEBAR_BG = "#F2F2F2";
export const COLOR_TEXT_CAPTION = "#717171";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_ACTIVE_EVENT = "#FFFBEF";
export const COLOR_TEXT = "#1e1e1e";
export const COLOR_ACTIVE_FIELD_BG = "#E4EFFF";
export const COLOR_BG_EMPTY = "#F3F3F3";
export const COLOR_BG_SECTION = "#485A74";

export const Sizes = {
    mobile: 768,
    tablet: 1280,
};

export const versionStateColors = {
    published: "#63BF13",
    readyToBePublished: "#FFBF00",
    draft: "#FF5757",
};

export const CHECKER_NAMES = {
    CUSTOM: "Специальный",
};

export const SUPPORT_IMAGE_CONTENT_TYPES = [
    'image/gif',
    'image/svg+xml',
    'image/webp',
    'image/png',
    'image/jpeg',
];
