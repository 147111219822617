import React                from "react";
import bnc                  from "bnc";
import {Button}             from "@sirius/ui-lib/src/blocks/Controls/index";
import {SmtLocaleString}    from "Smt/SmtLocaleBlock";
import {BoxAlignment, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import                           "./OversizeErrorPopup.less";

type OversizeErrorPopupProps = {
    onClose:() => void;
};

const block = new bnc('oversize-error-popup');

const OversizeErrorPopup = ({onClose}: OversizeErrorPopupProps) => {

    return (
            <VBox className={block.el('overlay')} center align={BoxAlignment.center}>
                <VBox className={block} align={BoxAlignment.center}>
                    <h3 className={block.el('title')}>
                        <SmtLocaleString k="common.oversize_popup.title"/>
                    </h3>

                    <p className={block.el('description')}>
                        <SmtLocaleString k="common.oversize_popup.description"/>
                    </p>

                    <Button className={`${block.el('button')}`} size={"l"} mode={"primary"} onAction={onClose}>
                        <SmtLocaleString k="common.oversize_popup.got_it"/>
                    </Button>
                </VBox>
            </VBox>
    );
}

export default OversizeErrorPopup;
