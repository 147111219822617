import React, {useEffect, useRef, useState} from "react";
import {windowResize$}                  from "@sirius/ui-lib/src/subjects/WindowResize";
import {VBox}                           from "@sirius/ui-lib/src/blocks/Layout";
import {SVGEulerCircles}                from "./SVGEulerCircles";
import {SVGTaskMoneyTransferArrows}     from "./SVGTaskMoneyTransferArrows";
import {block}                          from "./block";
import {SmtAnswerWidgetType}            from "./@types";
import                                       "./styles/index.less";


const SmtAnswerWidget: React.FC<SmtAnswerWidgetType> = ({
    answerWidget,
    onAnswer,
    userAnswer,
    savedAnswer,
    disabled,
    verdict,
    children
}) => {
    const [rect, setRect] = useState<DOMRect>(null);

    const {type}          = answerWidget;
    const SVGContainerRef = useRef<HTMLDivElement>(null);
    const setSVGContainerSize = () => {
        const next = SVGContainerRef.current?.getBoundingClientRect();
        next && setRect(next);
    }
    useEffect(
        ()=>{
            setSVGContainerSize();
            const Rsub = windowResize$.subscribe( setSVGContainerSize )
            return ()=>{Rsub.unsubscribe()}
        },
        []
    )

    return  <VBox className={
                block +
                block.mod('disabled', disabled ? 'true' : 'false')
            }>
                <VBox
                    divRef    = {SVGContainerRef}
                    className = {
                        block.el('widget') +
                        block.el('widget').mod('verdict', verdict || 'undefined')
                    }
                >
                    {
                        type === "EulerCircles" &&
                        rect                    &&
                        <SVGEulerCircles
                            rect         = {rect}
                            answerWidget = {answerWidget}
                            disabled     = {disabled}
                            userAnswer   = {userAnswer}
                            savedAnswer  = {savedAnswer}
                            onAnswer     = {onAnswer}
                        />
                    }{
                        type === "TaskMoneyTransferArrows" &&
                        rect                        &&
                        <SVGTaskMoneyTransferArrows
                            rect         = {rect}
                            answerWidget = {answerWidget}
                            disabled     = {disabled}
                            userAnswer   = {userAnswer}
                        >
                            {children}
                        </SVGTaskMoneyTransferArrows>
                    }
                </VBox>
            </VBox>
    ;
}



export {SmtAnswerWidget};
