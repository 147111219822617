import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const SwitcherOffIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#SwitcherOffIconclip0)">
            <rect x="3" y="5" width="32" height="14" rx="7" fill="#CFCFCF" />
            <g filter="url(#SwitcherOffIconfilter0_d)">
                <circle cx="10" cy="12" r="10" fill="white" />
            </g>
        </g>
        <defs>
            <filter id="SwitcherOffIconfilter0_d" x="-2" y="1" width="24" height="24" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
            <clipPath id="SwitcherOffIconclip0">
                <rect width="38" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</SvgIcon>;


export default SwitcherOffIcon;
