import Axios from "axios";
import AjaxWrapper from "Cheops/AjaxWrapper";

const VERSION = CONFIG.Version ? "/" + CONFIG.Version : "";


export const getSessionToken = (code, state) => {

    return Axios({
        method: "GET",
        url: `/lab-noo${VERSION}/oauth/callback`,
        params: {
            code,
            state
        }
    }).then(result => Promise.resolve(result.data.success.token),
        async error => {
            if (error.response) {
                if (error.response.status === 401) {
                    location.href = '/401.html';
                }
                if (error.response.status > 401 && error.response.status < 600) {
                    let new_html = await Axios.get('/500.html');
                    document.open();
                    document.write(new_html.data);
                    document.close();
                }
            }
            console.error(error);
        }
    );
};

export const redirectToAuth = redirect_to => {

    redirect_to = redirect_to || location.href;

    redirect_to = encodeURIComponent(redirect_to);

    location.href = CONFIG.Auth.anonymous_redirect_uri +
        "?client_id=" + CONFIG.Auth.client_id +
        "&redirect_uri=" + encodeURIComponent(CONFIG.Auth.redirect_uri + "?redirect_to=" + redirect_to) +
        "&response_type=code" +
        "&scope=" + CONFIG.Auth.scope +
        "&state=random";

};

export const logOut = () => {

    localStorage.removeItem(CONFIG.Auth.localstorage_key);
    location.href = CONFIG.Auth.logout_uri;

};

export const getUserListData = (user_ids_arr, properties_all) => {

    if (properties_all.indexOf('id') === -1) {
        properties_all.push('id');
    }

    return {
        type: "GET_USER_LIST_DATA",
        payload: AjaxWrapper.post(CONFIG.Api.sso.url, {
            "jsonrpc": "2.0",
            "method": "user/list",
            "params": {
                "ids": user_ids_arr,
                "properties": properties_all
            },
            "id": 1
        }, {send_auth_token: true})
    }

};


export const getUserParentCode = (user_id) => {

    return AjaxWrapper.get(CONFIG.Api.cheopsLaboratory.url + VERSION + "/user/" + user_id + "/parent-code", {}, {
        send_auth_token: true, dataHandler: data => {
            return data.code;
        }
    });

};


export const setAuthToken = (token) => {

    let old_token = localStorage.getItem(CONFIG.Auth.localstorage_key);

    if (token) {
        localStorage.setItem(CONFIG.Auth.localstorage_key, token);
    } else {
        localStorage.removeItem(CONFIG.Auth.localstorage_key);
    }

    window.dispatchEvent(new StorageEvent('storage', {
        key: CONFIG.Auth.localstorage_key,
        oldValue: old_token,
        newValue: token
    }));

    return {
        type: "SET_AUTH_TOKEN",
        token
    }
};
