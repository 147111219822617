import React from "react";
import {getNowDate} from "Smt/helpers";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import BemClassName from "Cheops/BemClassName";
import {SmtLocalePlural, SmtLocaleString} from "Smt/SmtLocaleBlock";

export default class Section extends React.Component {

    timerID = 0;

    constructor(props) {

        super(props);

        this.state = {
            remain: 0,
        };

    }


    componentDidMount() {

        this.timerID = setInterval(this.tick, 1000);
        this.tick();
        this.calculateMargin();

    }

    componentDidUpdate() {

        this.calculateMargin();

    }

    componentWillUnmount() {

        clearInterval(this.timerID);

    }

    tick = () => {

        const remainMin = (this.props.issuedUntil - getNowDate()) / 1000 / 60;

        if (remainMin <= 0) {

            clearInterval(this.timerID);
            this.props.onTimeout();
            return;

        }

        this.setState({
            remain: Math.ceil(remainMin),
        });

    };


    calculateMargin() {

        let task_div = document.getElementsByClassName('task')[0];

        if (!task_div) {
            return false;
        }

        if (task_div.classList.contains('task--grouped')) {
            task_div.style.margin = '';
            return;
        }
        let top = (window.innerHeight - task_div.offsetHeight) / 2;

        task_div.style.margin = "";

        if (top > 120) {
            task_div.style.margin = top + "px auto";
        }
    }


    render() {

        const {data, isResultPage} = this.props;

        const desriptionClassName = new BemClassName('task__section_description');

        desriptionClassName.appendStatusIf(data.description && data.description.length > 120, 'left_aligned');

        return (
            <div className="task task--section">
                {!isResultPage && (
                    <div className="task__remain">
                        <SmtLocaleString
                            k="common.section.time_left"
                            id="value"
                            values={{
                                number: this.state.remain,
                                plural: <SmtLocalePlural k="common.format_plural.minutes" value={this.state.remain} />,
                            }}
                        />
                    </div>
                )}
                <div className="task__section_content">
                    <div className="task__title task__title--section">{data.name}</div>
                    <div className="task__section_description"><Marked>{data.description}</Marked></div>
                </div>
                <div className="task__blue_btn task__blue_btn--centered" onClick={this.props.onNext}>
                    {!isResultPage && <SmtLocaleString k="common.section.forward" id="value" />}
                    {isResultPage && <SmtLocaleString k="common.section.next" id="value" />}
                </div>
            </div>
        );

    }

}
