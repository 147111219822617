import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const PublishIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 0H2C0.9 0 0 0.9 0 2V4C0 4.55 0.45 5 1 5C1.55 5 2 4.55 2 4V3C2 2.45 2.45 2 3 2H19C19.55 2 20 2.45 20 3V15C20 15.55 19.55 16 19 16H14C13.45 16 13 16.45 13 17C13 17.55 13.45 18 14 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM1.14 11.09C0.54 10.99 0 11.48 0 12.09C0 12.58 0.36 12.99 0.85 13.07C2.93 13.43 4.57 15.07 4.93 17.15C5.01 17.64 5.42 18 5.91 18C6.52 18 7 17.46 6.91 16.86C6.43 13.9 4.09 11.57 1.14 11.09ZM0 15V18H3C3 16.34 1.66 15 0 15ZM1.1 7.05C0.51 7 0 7.46 0 8.05C0 8.56 0.38 8.99 0.88 9.04C5.15 9.45 8.55 12.85 8.96 17.12C9.01 17.62 9.44 17.99 9.95 17.99C10.55 17.99 11.01 17.47 10.95 16.88C10.42 11.69 6.29 7.57 1.1 7.05Z"
            fill="#B0B0B0"
        />
    </svg>
</SvgIcon>;


export default PublishIcon;
