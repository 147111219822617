import React,
{FC, useEffect, useState}                       from "react";
import {marked}                                 from "marked";
import MathJax                                  from "@sirius/ui-shared/src/components/DisplayEngine/Mathjax";
import {prepareMathJax, html}                   from "@sirius/ui-shared/src/components/DisplayEngine/MarkedHelpers";
import {useTagsParse, IVideoContainers}         from "Smt/hooks/useTagsParse";
import {useVideoResolve, IUseVideoResolveArgs}  from "Smt/hooks/useVideoResolve";
import                                               "@sirius/ui-shared/src/components/DisplayEngine/Mathjax.less";

const renderer = { html };
marked.use({ renderer });

interface IMarkedProps {
    instance?: IUseVideoResolveArgs["instance"];
    isSmt?: boolean;
    inline?: boolean;
    youtube?: boolean;
    className?: string;
    videoWrapperClassName?: string;
    onClick?: (e?: React.MouseEvent) => void;
}
type THasResolved = Record<string,boolean>;

const MarkedWithVideo: FC<IMarkedProps> = (
    {
        children,
        instance= CONFIG?.Instance as IUseVideoResolveArgs["instance"],
        inline= false,
        youtube = true,
        className = '',
        onClick,
        isSmt = false,
        videoWrapperClassName = '',
    }
) => {
    const [sourceWithJustify, setSourceWithJustify] = useTagsParse({type: "JustifyTag"});
    const [sourceWithInline, setSourceWithInline]   = useTagsParse({type: "InlineTag", inline});
    const [sourceWithVideo, setSourceWithVideo]     = useTagsParse({type: "YoutubeTag", youtube, videoWrapperClassName});
    const [videoContainer, getVideoContainer]       = useVideoResolve({instance, isSmt});
    const [videoContainers, setVideoContainers]     = useState<IVideoContainers>({});
    const [hasResolved, setHasResolved]             = useState<THasResolved>(null);

    const [source, setSource] = useState<string>(null);
    useEffect(() => {
        if (!!videoContainer) {
            setVideoContainers({ ...videoContainers, ...videoContainer as IVideoContainers });
            const [code] = Object.keys(videoContainer);
            setHasResolved({...hasResolved, [code]: true});
        }
    }, [videoContainer]);

    useEffect(() => {
        if (typeof children  === "string") {
            const hasResolvedObj: THasResolved = {};
            const videoTagArr = children?.match(/{youtube:[^}\n]+}/g) ?? [];
            if (!!videoTagArr.length) {
                videoTagArr.forEach(  tag => {
                    const code = tag.replace('{youtube:', '').replace(/\((start|end)=(\d?|\d+)\)/g, '').replace('}', '');
                    hasResolvedObj[code] = false;
                });
                Object.keys(hasResolvedObj).forEach(  code => {
                    setTimeout(() => getVideoContainer(`https://youtu.be/${code}`, code), 10);
                });
            } else {
                hasResolvedObj['unnecessary'] = true;
            }
            setHasResolved(hasResolvedObj);
            setSourceWithJustify(children);
        }
    }, [children]);
    useEffect(() => {
        setSourceWithInline(sourceWithJustify);
    }, [sourceWithJustify]);
    useEffect(() => {
        const isResolved =  !!hasResolved ? Object.values(hasResolved).every(Boolean) : !!hasResolved?.unnecessary;
        isResolved && setSourceWithVideo(sourceWithInline, videoContainers);
    }, [sourceWithInline, hasResolved]);
    useEffect(() => {
        setSource(sourceWithVideo);
    }, [sourceWithVideo]);


    const markedMath: string = !!source ? marked(source) : null;
    const math = !!markedMath ? prepareMathJax(source, markedMath) : markedMath;

    const onClickHandler = (e: React.MouseEvent): void => {
        const target = e.target as HTMLElement;

        if (target && target.className.includes('video-storage')) {
            e.stopPropagation();
            return;
        }

        onClick && onClick(e);
    };


    return !!source
               ? <MathJax
                    math      = {math}
                    className = {className}
                    onClick   = {onClickHandler}
                 />
               : null
    ;

}

export default MarkedWithVideo;
