import React                                 from "react";
import { ElementVerdict }                    from "Cheops/Model/Element";
import Marked                                from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import BemClassName                          from "Cheops/BemClassName";
import { TaskAnswersData }                   from "Lab/Model/Task";
import uniqueId                              from "lodash/uniqueId";
import { MultiUserAnswer, SingleUserAnswer } from "Cheops/Model/UserAnswer";
import cloneDeep                             from "lodash/cloneDeep";
import './SelectOptionAnswer.less';
import SelectDropdownAnswer
                                             from "Cheops/components/ModulePassing/Answers/AnswerTypes/SelectOptionAnswer/SelectDropdownAnswer";
import { TaskAnswerType }                    from "Cheops/Model/Task";
import Assert                                from "Cheops/Utils/Assert";
import UserAnswerAdapter                     from "Cheops/components/ModulePassing/Answers/UserAnswerAdapter";
import memoizeOne                            from "memoize-one";
import type { DropdownItem }                 from "@sirius/ui-lib/src/blocks/DropdownSelect/@types";

interface Props {

    answersData: TaskAnswersData;
    isResult?: boolean;
    resultVerdict?: ElementVerdict;
    readOnly?: boolean;
    answerType?: TaskAnswerType;
    userAnswer?: SingleUserAnswer | MultiUserAnswer;
    inline?: boolean;
    inputMaxWidth?: number; // input max width for inline layout

    onAnswer?(userAnswer: SingleUserAnswer | MultiUserAnswer): void;

}


export default class SelectOptionAnswer extends React.Component<Props> {

    static defaultProps: Partial<Props> = {
        userAnswer: null,
        readOnly:   false,
        isResult:   false,
    };

    shouldComponentUpdate = (nextProps: Readonly<Props>): boolean => !(JSON.stringify(this.props) === JSON.stringify(nextProps));

    getIsAnswerFilled(): boolean {

        const multi = this.getIsAllowMulti();

        const userAnswer = this.getUserAnswer(multi);

        if (multi) {

            Assert(Array.isArray(userAnswer));

            return userAnswer.length > 0;

        }

        Assert(!Array.isArray(userAnswer));

        return Number.isFinite(userAnswer);

    }

    getAnswerHasError = (): boolean => false;


    getIsAllowMulti = (): boolean => {

        const {answerType} = this.props;

        return answerType === TaskAnswerType.MULTI;

    };


    getUserAnswer(multi: boolean): Props['userAnswer'] {

        const userAnswer = UserAnswerAdapter.selectOptionAnswer(this.props.userAnswer, multi);

        if (multi && !Array.isArray(userAnswer)) {

            return [];

        }

        return cloneDeep(userAnswer);

    }

    answerClick = (answerId: number): void => {

        const {onAnswer} = this.props;

        const multi      = this.getIsAllowMulti();
        const userAnswer = this.getUserAnswer(multi);



        if (multi) {

            return;

        }

        if (userAnswer === answerId) {

            onAnswer(null);

        }


    };

    answerChange = (answerId: number, checked = false): void => {


        const {onAnswer} = this.props;

        const multi      = this.getIsAllowMulti();
        const userAnswer = this.getUserAnswer(multi);



        if (!multi) {

            onAnswer(answerId);

            return;

        }


        if (!Array.isArray(userAnswer)) {

            throw Error('userAnswer must be an array');

        }

        if (checked) {

            if (!userAnswer.includes(answerId)) {

                userAnswer.push(answerId);

            }

        } else {

            const index = userAnswer.indexOf(answerId);

            if (index >= 0) {

                userAnswer.splice(index, 1);

            }

        }

        userAnswer.sort();

        if (userAnswer.length === 0) {

            onAnswer(null);
            return;

        }

        onAnswer(userAnswer);

    };


    onSelectChange = (_items: DropdownItem[]): void => {

        const items = _items.filter( v => (v !== void(0) && ('id' in v)) );

        const {onAnswer} = this.props;

        const multi = this.getIsAllowMulti();

        if (!items.length) {
            onAnswer(null);
            return;
        }

        if (!multi) {

            onAnswer(items[0].id);

            return;

        }

        const selectedIds = items.map((v) => v.id);

        onAnswer(selectedIds);


    };


    render(): React.ReactNode {
        const multi                 = this.getIsAllowMulti();
        const userAnswer            = this.getUserAnswer(multi);
        const {answersData, inline} = this.props;
        const {answers, selectType} = answersData;



        if (selectType === "select") {

            return <SelectDropdownAnswer
                {...this.props}
                userAnswer={userAnswer}
                onAnswer={this.onSelectChange}
                multi={multi}
            />;

        }

        return answers.map((answer, index) => {

            const checked   = userAnswer === answer.id || (Array.isArray(userAnswer) && userAnswer.includes(answer.id));
            const className = new BemClassName('select_option_answer');

            const type = multi ? "checkbox" : "radio";

            className.appendStatusIf(multi, 'type-checkbox');
            className.appendStatusIf(!multi, 'type-radio');
            className.appendStatusIf(inline, 'inline');


            const id = `${index + 1}_${uniqueId()}`; // prefix need for autotests

            return <div className={className.toString()} key={`answer_${answer.id}`}>
                <input
                    id={id}
                    type={type}
                    checked={checked}
                    onClick={() => {
                        this.answerClick(answer.id)
                    }}
                    onChange={(e) => this.answerChange(answer.id, e.target.checked)}
                />

                <label htmlFor={id}>
                    <Marked inline={true}>{answer.value}</Marked>
                </label>

            </div>;

        });


    }

}
