import React                 from "react";
import {CustomIconsProps}    from "Lab/components/UI2/Atoms/IconAtom";

const TeacherMaterialsIcon: React.FC<CustomIconsProps> = ({className}) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
            <path d="M12.3476 19.2501C13.9967 19.2501 15.3337 17.9442 15.3337 16.3334C15.3337 14.7226 13.9967 13.4167 12.3476 13.4167C10.6984 13.4167 9.36145 14.7226 9.36145 16.3334C9.36145 17.9442 10.6984 19.2501 12.3476 19.2501Z"
                  fill="#FFFFFF"
            />
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8611 16.3334C16.8611 18.788 14.8712 20.7778 12.4166 20.7778C9.96201 20.7778 7.97217 18.788 7.97217 16.3334C7.97217 13.8788 9.96201 11.8889 12.4166 11.8889C14.8712 11.8889 16.8611 13.8788 16.8611 16.3334ZM15.3796 16.3334C15.3796 17.9698 14.053 19.2963 12.4166 19.2963C10.7802 19.2963 9.45365 17.9698 9.45365 16.3334C9.45365 14.697 10.7802 13.3704 12.4166 13.3704C14.053 13.3704 15.3796 14.697 15.3796 16.3334Z"
                  fill="currentColor"
            />
            <path d="M17.5552 10.3612C19.166 10.3612 20.4719 9.05533 20.4719 7.4445C20.4719 5.83367 19.166 4.52783 17.5552 4.52783C15.9444 4.52783 14.6385 5.83367 14.6385 7.4445C14.6385 9.05533 15.9444 10.3612 17.5552 10.3612Z"
                  fill="#FFFFFF"
            />
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.0003 7.44444C22.0003 9.89904 20.0105 11.8889 17.5559 11.8889C15.1013 11.8889 13.1115 9.89904 13.1115 7.44444C13.1115 4.98985 15.1013 3 17.5559 3C20.0105 3 22.0003 4.98985 22.0003 7.44444ZM20.5189 7.44444C20.5189 9.08084 19.1923 10.4074 17.5559 10.4074C15.9195 10.4074 14.5929 9.08084 14.5929 7.44444C14.5929 5.80805 15.9195 4.48148 17.5559 4.48148C19.1923 4.48148 20.5189 5.80805 20.5189 7.44444Z"
                  fill="currentColor"
            />
            <path d="M6.4445 10.3612C8.05533 10.3612 9.36117 9.05533 9.36117 7.4445C9.36117 5.83367 8.05533 4.52783 6.4445 4.52783C4.83367 4.52783 3.52783 5.83367 3.52783 7.4445C3.52783 9.05533 4.83367 10.3612 6.4445 10.3612Z"
                  fill="#FFFFFF"
            />
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.8889 7.44444C10.8889 9.89904 8.89904 11.8889 6.44444 11.8889C3.98985 11.8889 2 9.89904 2 7.44444C2 4.98985 3.98985 3 6.44444 3C8.89904 3 10.8889 4.98985 10.8889 7.44444ZM9.40741 7.44444C9.40741 9.08084 8.08084 10.4074 6.44444 10.4074C4.80805 10.4074 3.48148 9.08084 3.48148 7.44444C3.48148 5.80805 4.80805 4.48148 6.44444 4.48148C8.08084 4.48148 9.40741 5.80805 9.40741 7.44444Z"
                  fill="currentColor"
            />
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.6388 8.13889H10.4722V6.75H14.6388V8.13889Z"
                  fill="currentColor"
            />
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.2713 10.7779L15.2343 14.3062L13.9513 13.5654L15.9883 10.0372L17.2713 10.7779Z"
                  fill="currentColor"
            />
            <path d="M6.23808 5.63892H7.13878V9.25003H6.07914V6.71709L5.43805 6.86669L5.19434 6.00519L6.23808 5.63892Z"
                  fill="currentColor"
            />
            <path d="M16.2425 9.25V8.48533L17.3973 7.19169C17.4176 7.16725 17.4463 7.13408 17.4834 7.09218C17.5239 7.05028 17.5509 7.0206 17.5645 7.00314C17.5813 6.98568 17.6016 6.96299 17.6252 6.93506C17.6523 6.90363 17.6691 6.87919 17.6759 6.86173C17.686 6.84078 17.6945 6.81983 17.7012 6.79888C17.7113 6.77444 17.7164 6.75 17.7164 6.72556C17.7164 6.6662 17.6961 6.61732 17.6556 6.57891C17.6151 6.53701 17.556 6.51606 17.4784 6.51606C17.2893 6.51606 17.1491 6.64001 17.058 6.88792L16.1665 6.38513C16.2847 6.09532 16.4586 5.87535 16.6882 5.72521C16.9212 5.57507 17.1795 5.5 17.4632 5.5C17.811 5.5 18.1081 5.603 18.3546 5.80901C18.6045 6.01152 18.7294 6.2926 18.7294 6.65223C18.7294 6.87221 18.6821 7.06425 18.5876 7.22835C18.4964 7.39246 18.3512 7.58973 18.152 7.82018L17.7924 8.23394H18.8054V9.25H16.2425Z"
                  fill="currentColor"
            />
            <path d="M12.9106 16.1647C13.1406 16.2345 13.3233 16.3567 13.4587 16.5313C13.5974 16.7024 13.6667 16.9188 13.6667 17.1807C13.6667 17.5857 13.5415 17.893 13.2912 18.1025C13.0408 18.312 12.7262 18.4167 12.3473 18.4167C12.036 18.4167 11.7637 18.3434 11.5302 18.1968C11.2968 18.0501 11.1293 17.8336 11.0278 17.5473L11.921 17.0184C11.992 17.2732 12.1341 17.4007 12.3473 17.4007C12.4386 17.4007 12.5114 17.3762 12.5655 17.3274C12.623 17.2785 12.6518 17.2121 12.6518 17.1283C12.6518 16.9538 12.5503 16.8665 12.3473 16.8665H12.1291L11.7332 16.2589L12.2103 15.6828H11.1395V14.6667H13.4739V15.4733L12.9106 16.1647Z"
                  fill="currentColor"
            />
        </svg>
);

export default TeacherMaterialsIcon;
