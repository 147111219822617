import React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {ReqResponseDialogue, ReqResponseDialogueElementControl, ReqResponseDialogueElementControlType} from "Lab/Types";
import ModalWindow from "Lab/components/UI2/ModalWindow/ModalWindow";
import SystemNotification from "Lab/SystemNotifications/SystemNotification";
import SystemNotificationsDialogueAgreeControl
    from "Lab/SystemNotifications/SystemNotificationsDialogueAgreeControl";

import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";

import AspectElementsGroup from "Lab/components/UI2/Elements/AspectElementsGroup";

import SystemNotificationsDialogueSelectControl
    from "Lab/SystemNotifications/SystemNotificationsDialogueSelectControl";
import ButtonControl, {BUTTON_VARIANTS} from "Lab/components/UI2/Controls/ButtonControl";
import {AxiosRequestConfig} from "axios";
import AjaxWrapper from "Cheops/AjaxWrapper";
import cloneDeep from "lodash/cloneDeep";
import TextControl from "Lab/components/UI2/Controls/TextControl";
import ModalWindowFooter from "Lab/components/UI2/ModalWindow/ModalWindowFooter";
import SystemNotificationsMeta from "Lab/SystemNotifications/SystemNotificationsMeta";
import SystemNotificationsDialogueTextAreaControl
    from "Lab/SystemNotifications/SystemNotificationsDialogueTextAreaControl";
import "./SystemNotificationsDialogue.less";
import {unregisterURL} from "@sirius/ui-lib/src";


interface Props {
    dialogue: ReqResponseDialogue;
    axiosParams: AxiosRequestConfig;

    onResolve(): void;

    removeDialogue(): void;

    onReject(): void;
}

interface State {
    answersByIndex: Record<number, Record<string, string>>;
}

class SystemNotificationsDialogue extends React.Component<Props, State> {


    state: Readonly<State> = {
        answersByIndex: {},
    };


    componentDidMount(): void {

        const {dialogue, axiosParams} = this.props;
        const {questions} = dialogue;

        unregisterURL(axiosParams?.url);
        for (const [index, element] of questions.entries()) {

            if (element.control && typeof element.control.default !== "undefined") {

                this.setControlAnswer(element.control.name, element.control.default, index);

            }

        }

        window.addEventListener('hashchange', this.cancelDialogue);

    }


    componentWillUnmount(): void {

        window.removeEventListener('hashchange', this.cancelDialogue);

    }

    renderControl(control: ReqResponseDialogueElementControl, index: number): React.ReactNode {

        const setControlAnswer = (k: string, v: string): void => this.setControlAnswer(k, v, index);

        switch (control.type) {
            case ReqResponseDialogueElementControlType.Agreement:
                return <SystemNotificationsDialogueAgreeControl
                    control={control}
                    onChange={setControlAnswer}
                    value={this.getControlAnswer(control.name, index)}
                    disabled={!this.props.dialogue.enabled}
                />;

            case ReqResponseDialogueElementControlType.Select:
                return <SystemNotificationsDialogueSelectControl
                    control={control}
                    onChange={setControlAnswer}
                    value={this.getControlAnswer(control.name, index)}
                    disabled={!this.props.dialogue.enabled}
                />;
            case ReqResponseDialogueElementControlType.TextArea:
                return <SystemNotificationsDialogueTextAreaControl
                    control={control}
                    value={this.getControlAnswer(control.name, index)}
                    onChange={setControlAnswer}
                />;
        }

        throw Error("Unknown dialogue control was tried to render");

    }


    getControlAnswer = (key: string, index: number): string => {

        const {answersByIndex} = this.state;

        if (!answersByIndex[index]) {

            return null;

        }

        const value = answersByIndex[index][key];

        if (typeof value !== 'undefined') {

            return value;

        }

        return null;

    };

    setControlAnswer = (key: string, value: string, index: number): void => {

        const {answersByIndex} = this.state;

        if (!answersByIndex[index]) {

            answersByIndex[index] = {};

        }

        if (value === null) {
            delete answersByIndex[index];
        } else {
            answersByIndex[index][key] = value;
        }

        this.setState({answersByIndex});

    };


    getAllControlsIsFilled = (): boolean => {

        const {answersByIndex} = this.state;

        const {dialogue} = this.props;

        for (const [index, question] of dialogue.questions.entries()) {

            if (!question.control) {

                continue;

            }

            if ((!answersByIndex[index] || answersByIndex[index][question.control.name] === null) && question.type !== "info") {

                return false;

            }


        }

        return true;

    };

    renderModalWindowFooter = (): React.ReactNode => {

        const {dialogue} = this.props;

        return <AspectElementsGroup>
            <UIElementWrapper justifyContent='right'>

                <ButtonControl
                    onClick={this.cancelDialogue}
                    variant={BUTTON_VARIANTS.OUTLINED}
                >
                    Отмена
                </ButtonControl>
                {dialogue.enabled && <ButtonControl
                    onClick={this.sendDialogue}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    disabled={!this.getAllControlsIsFilled() || !dialogue.enabled}
                >
                    {dialogue.buttonText}
                </ButtonControl>}

            </UIElementWrapper>
        </AspectElementsGroup>;

    };

    cancelDialogue = (): void => {


        const {
            onReject,
            removeDialogue,
        } = this.props;

        onReject();
        removeDialogue();

    };

    sendDialogue = (): void => {

        const {
            axiosParams,
            onReject,
            onResolve,
            removeDialogue,
        } = this.props;

        const {answersByIndex} = this.state;

        const _axiosParams = cloneDeep(axiosParams);

        const url = new URL(_axiosParams.url.split('?')[0]);
        url.searchParams.set("dialogue", "true");

        for (const [, keyValue] of Object.entries(answersByIndex)) {

            for (const [key, value] of Object.entries(keyValue)) {

                url.searchParams.append(key, value);

            }

        }

        _axiosParams.url = url.href;

        AjaxWrapper.axios(_axiosParams).then(onResolve, onReject);

        removeDialogue();

    };

    render(): React.ReactNode {

        const {dialogue} = this.props;
        const {questions, title, description} = dialogue;

        return <ModalWindow
            narrowWidth
            title={title}
            headerClassName="system_notifications_dialogue__header"
            onClose={this.cancelDialogue}
        >

            <AspectElementsGroup className="system_notifications_dialogue__notifications_block">

                {!!description && <UIElementWrapper>
                    <TextControl>{description}</TextControl>
                </UIElementWrapper>}

                {questions.map((element, i) => <UIElementWrapper key={i}>
                    <SystemNotification type={element.type}>

                        {!!element.text && <TextControl>
                            {element.text}
                        </TextControl>}

                        {!!element.control && this.renderControl(element.control, i)}

                        <SystemNotificationsMeta meta={element.meta} onCancel={this.cancelDialogue} />

                    </SystemNotification>
                </UIElementWrapper>)}

            </AspectElementsGroup>

            <ModalWindowFooter>
                {this.renderModalWindowFooter()}
            </ModalWindowFooter>
        </ModalWindow>;

    }

}


export default function renderDialogue(
    dialogue: ReqResponseDialogue,
    axiosParams: AxiosRequestConfig,
    resolve: () => void,
    reject: () => void,
): void {

    const wrapperEl = document.createElement("div");

    render(<SystemNotificationsDialogue
        dialogue={dialogue}
        axiosParams={axiosParams}
        onResolve={resolve}
        onReject={reject}
        removeDialogue={() => unmountComponentAtNode(wrapperEl)}
    />, wrapperEl);

}
