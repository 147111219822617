import {getNowDate} from "Smt/helpers";
import React from "react";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

interface Props {

    issuedUntil: Date;
    onTimeout: () => void;

}

interface State {
    remain: number;
}

export default class Countdown extends React.Component<Props, State> {

    state = {
        remain: 0,
    };


    componentDidMount(): void {
        this.timerID = setInterval(this.tick, 1000);
        this.tick();
    }

    componentWillUnmount(): void {
        clearInterval(this.timerID);
    }

    timerID: NodeJS.Timeout;

    tick = (): void => {
        const {onTimeout, issuedUntil} = this.props;
        const nowDate: Date = getNowDate();
        let remainMin = (issuedUntil.getTime() - nowDate.getTime()) / 1000 / 60;
        if (remainMin <= 0) {
            clearInterval(this.timerID);
            onTimeout();
            return;
        }
        this.setState({remain: Math.ceil(remainMin)});
    };

    render = (): React.ReactNode =>
        <SmtLocaleString
            k="common.countdown.duration" id="value"
            values={ this.getValues( this.state ) }
        />
    ;


    private formatValueStr = (val: number): string =>
        val === 0
            ? '00'
            :  val < 10
                ? `0${val}`
                : `${val}`
    ;

    private getValues = ({remain}: State) =>({
        number:  remain,
        minutes: this.formatValueStr(remain % 60),
        hours:   this.formatValueStr((remain - remain % 60) / 60)
    });
}
