import {BehaviorSubject}    from "rxjs";
import {mixBehavior}        from "@sirius/ui-lib/src/subjects/mixBehavior";
import {useBehaviorSubject} from "@sirius/ui-lib/src/subjects/useBehaviorSubject";

const INITTASKDATASTATE = {
    statusTasks: {},
};

type TaskDataStateType = Record<string, any>;
const TaskDataState$ = new BehaviorSubject<TaskDataStateType>(INITTASKDATASTATE);

const getTaskDataState = () => TaskDataState$.getValue();

const setTaskDataState = (state : TaskDataStateType) => mixBehavior(TaskDataState$, state);

// TaskDataState$.subscribe(
//     val => {
//         console.debug( 'smtTaskData$', val )
//     }
// )

const useTaskDataState = (): TaskDataStateType => useBehaviorSubject(TaskDataState$);

export {
    TaskDataState$,
    getTaskDataState,
    setTaskDataState,
    useTaskDataState,
};
