import React, {FunctionComponent, useState} from "react";
import {ReqResponseError, ReqResponseMetaSource, ReqResponseMetaSourceType} from "Lab/Types";
import Helpers from "Cheops/Helpers";
import TextControl from "Lab/components/UI2/Controls/TextControl";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";
import ButtonControl, {BUTTON_VARIANTS} from "Lab/components/UI2/Controls/ButtonControl";
import IconButtonControl from "Lab/components/UI2/Controls/IconButtonControl";
import ModalPopup from "Lab/components/UI2/Elements/ModalPopup";

interface Props {

    meta?: ReqResponseError['meta'];
    asButtons?: boolean;

    onCancel?(): void;

}


const getSourceLink = (source: ReqResponseMetaSource): string | null => {

    let sourceLink = null;


    if (source.type === ReqResponseMetaSourceType.AnswersTable) {

        return `/#/contests/${source.contestId}/answers_table/${source.taskId}_${source.taskVersion}`;

    }

    if (source.type === ReqResponseMetaSourceType.ContestEdit) {

        return `/#/contests/${source.contestId}/edit`;

    }

    if (source.type === ReqResponseMetaSourceType.ContestSection) {

        return `/#/contests/${source.contestId}/sections/${source.sectionId}`;

    }

    if (source.contestId) {

        sourceLink = `/#/contests/${source.contestId}`;

        if (source.taskId) {

            sourceLink += `/task_${source.taskId}`;


            if (source.taskVersionId) {

                sourceLink += `_v${source.taskVersionId}`;

            }

        }

    }

    if (source.environmentId) {

        sourceLink = `/#/environment/${source.environmentId}`;

        if (source.moduleId) {

            sourceLink += `/${source.moduleId}`;

            if (source.moduleVersionId) {

                sourceLink += `_${source.moduleVersionId}`;

            }

            if (source.moduleSection === "autoGrade") {

                sourceLink += "/auto_grade";

            }

            if (source.taskId) {

                sourceLink += `/task_${source.taskId}`;

                if (source.taskVersionId) {

                    sourceLink += `_v${source.taskVersionId}`;

                }

            }

            if (source.textId) {

                sourceLink += `/text_${source.textId}`;

                if (source.textVersionId) {

                    sourceLink += `_v${source.textVersionId}`;

                }

            }

            if (source.videoId) {

                sourceLink += `/video_${source.videoId}`;

                if (source.videoVersionId) {

                    sourceLink += `_v${source.videoVersionId}`;

                }

            }

        }

    }

    return sourceLink;

};


const getTypeText = (source: ReqResponseMetaSource): Record<string, string> => {

    let sourceLink = getSourceLink(source);

    const typeToText: Record<ReqResponseMetaSourceType, string> = {
        [ReqResponseMetaSourceType.Contest]         : "В олимпиаду",
        [ReqResponseMetaSourceType.ContestEdit]     : "В настройки",
        [ReqResponseMetaSourceType.ContestSection]  : "В раздел",
        [ReqResponseMetaSourceType.SMTContestEdit]  : "В настройки тестирования",
        [ReqResponseMetaSourceType.ContestTask]     : "В задачу",
        [ReqResponseMetaSourceType.Module]          : "В модуль",
        [ReqResponseMetaSourceType.Environment]     : "В пространство",
        [ReqResponseMetaSourceType.ContestTask]     : "В задачу",
        [ReqResponseMetaSourceType.ModuleTask]      : "В задачу",
        [ReqResponseMetaSourceType.ModuleTaskExtra] : "В задачу",
        [ReqResponseMetaSourceType.ContestText]     : "В текстовый модуль",
        [ReqResponseMetaSourceType.ModuleText]      : "В текстовый модуль",
        [ReqResponseMetaSourceType.ContestVideo]    : "В лекцию",
        [ReqResponseMetaSourceType.ModuleVideo]     : "В лекцию",
        [ReqResponseMetaSourceType.Text]            : "",
        [ReqResponseMetaSourceType.Task]            : "",
        [ReqResponseMetaSourceType.Video]           : "",
        [ReqResponseMetaSourceType.AnswersTable]    : "Веер по задаче",
    };

    const typeText = typeToText[source.type];

    return {typeText, sourceLink};

};


const copyToClipboard = (e: React.MouseEvent, sourceLink: string): void => {

    e.stopPropagation();

    if (!sourceLink) {

        return;

    }

    Helpers.copyToClipboard(window.location.origin + sourceLink);

};

const SystemNotificationsMeta: FunctionComponent<Props> = ({meta, asButtons, onCancel}) => {

    const [showPopup, setShowPopup] = useState(false);

    return <>

        {!!meta && ("source" in meta)
        && meta.source.map((sourceItem, index) => {

            let {typeText, sourceLink} = getTypeText(sourceItem);

            if (!sourceLink) {

                return null;

            }


            return <UIElementWrapper key={index} className="system_notification__meta">
                <a href={sourceLink}>

                    {asButtons
                    && <ButtonControl darkMode color="primary" onClick={onCancel}>{typeText}</ButtonControl>}

                    {!asButtons
                    && <TextControl>{typeText}</TextControl>}

                </a>
                <IconButtonControl
                    className="system_notification__meta_link"
                    iconName="link"
                    onClick={(e) => copyToClipboard(e, sourceLink)}
                    restrictFocusEffects
                    restrictHoverEffects
                />

            </UIElementWrapper>;

        })}

        {!!meta && ("popup" in meta)
        && <>
            <TextControl
                onClick={() => setShowPopup(true)}
                className="system_notification__meta_popup"
            >
                {meta.popup.text}
            </TextControl>
            {showPopup && <ModalPopup {...meta.popup} onClose={() => setShowPopup(false)} />}

        </>}

        {!!meta && ("copyMsg" in meta)
        && <UIElementWrapper className="system_notification__meta-copy">

            <ButtonControl
                onClick={() => Helpers.copyToClipboard(meta.copyMsg)}
            >
                {meta.copyButtonTitle}
            </ButtonControl>

            {meta.cancelButtonTitle && <ButtonControl
                onClick={meta.onCancel}
            >
                {meta.cancelButtonTitle}
            </ButtonControl>}

        </UIElementWrapper>}

    </>;

};


SystemNotificationsMeta.defaultProps = {
    asButtons: false,
};

export default SystemNotificationsMeta;
