import React             from "react";
import bnc               from "bnc";
import {TaskAnswerType}  from "Cheops/Model/Task";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";

interface Props {
    answerType:              TaskAnswerType[];
    isShowRightAnswer:       boolean;
    onToggleShowRightAnswer: () => void;
}
const block = new bnc('task');

const ResultSwitchButton: React.FC<Props> = ({answerType, isShowRightAnswer, onToggleShowRightAnswer}) => {

    const isProgramming = answerType.includes(TaskAnswerType.PROGRAMMING);
    const buttonText    = isProgramming     ? "common.result_switch_button.right_answer_programming" : "common.result_switch_button.right_answer";
    const modName       = isShowRightAnswer ? 'right_answers' : 'user_answers';

    return <button className = {
                                 block.el('result-switch') +
                                 block.el('result-switch').mod(`${modName}`) +
                                 block.el('result-switch').bod('programming', isProgramming)
                               }
                   onClick   = {onToggleShowRightAnswer}
    >
        <SmtLocaleString k={buttonText}/>
    </button>;

};

export default ResultSwitchButton;
