import React                      from "react";
import { HBox, VBox }             from "@sirius/ui-lib/src/blocks/Layout";
import { SmtLocaleString }        from "Smt/SmtLocaleBlock"
import { block }                  from "./block";

const IO = ({examples}: Partial<SmtTask.AnswersData>) =>
    <VBox className={block.el('io')}>
        <HBox className={block.el('io-top')}>
            <VBox className={block.el('io-box')}>
                <h5 className={block.el('io-head')}>
                    <SmtLocaleString id="value" k="programming.io.input"/>
                </h5>
            </VBox>
            <VBox className={block.el('io-box')}>
                <h5 className={block.el('io-head')}>
                    <SmtLocaleString id="value" k="programming.io.output"/>
                </h5>
            </VBox>
        </HBox>
        {
            examples.map(
                ({input, output}, ei) =>
                    <HBox
                        className= {block.el('io-row')}
                        key      = {ei}
                        data-num = {ei}
                    >
                        <VBox className={block.el('io-cell')}>
                            <pre className={block.el('io-pre')}>{input}</pre>
                        </VBox>
                        <VBox className={block.el('io-cell')}>
                            <pre className={block.el('io-pre')}>{output}</pre>
                        </VBox>
                    </HBox>
            )
        }
    </VBox>
;

export {IO};
