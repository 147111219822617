export default class BodyStyles {

    static setBodyScrollDisabled = (isDisabled: boolean): void => {

        if (isDisabled) {

            let marginRightPx = 0;
            if (window.getComputedStyle) {

                let bodyStyle = window.getComputedStyle(document.body);

                if (bodyStyle) {

                    if (bodyStyle.overflow !== 'hidden') {

                        marginRightPx = parseInt(bodyStyle.marginRight);

                    }

                }

            }

            let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;

            if (document.body.classList.contains('locked')) {

                return;

            }

            document.body.style.setProperty('margin-right', `${marginRightPx + scrollbarWidthPx}px`);
            document.body.style.setProperty('overscroll-behavior', 'none');
            document.body.style.setProperty('overflow', 'hidden');
            document.body.classList.add('locked');

        } else {

            document.body.style.removeProperty('overscroll-behavior');
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('margin-right');
            document.body.classList.remove('locked');

        }

    };

    private static scrollY = 0;

    private static disableScrollEvents = (): void => {

        window.addEventListener('scroll', BodyStyles.handleEvents, false);
        document.body.classList.add('locked');

    };

    private static enableScrollEvents = (): void => {

        window.removeEventListener('scroll', BodyStyles.handleEvents, false);
        document.body.classList.remove('locked');

    };

    static setBodyScrollEventDisabled(isDisabled: boolean): void {

        this.scrollY = window.pageYOffset;

        if (isDisabled) {

            if (document.body.classList.contains('locked')) {

                return;

            }

            this.disableScrollEvents();

        } else {

            this.enableScrollEvents();

        }

    }

    private static handleEvents = (e: WheelEvent | TouchEvent): void => {

        BodyStyles.handleDisabledScroll(e);

    };

    private static handleDisabledScroll(e: WheelEvent | TouchEvent): void {

        window.scrollTo(0, this.scrollY);

        e.preventDefault();
        e.stopPropagation();

    }

}
