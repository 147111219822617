import React from 'react';
import {PropTypes} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import IconAtom from "Lab/components/UI2/Atoms/IconAtom";
import BemClassName from "Cheops/BemClassName";
import {UIControl, UIControlProps} from "../UIControl";
import './ButtonControl.less';

export enum BUTTON_SIZES {
    SM = "small",
    MD = "medium",
    LG = "large",
}

export enum BUTTON_VARIANTS {
    TEXT = 'text',
    OUTLINED = 'outlined',
    CONTAINED = 'contained',
    LINK = 'link',
    FAB = 'fab',
    LAYOUT = 'layout',
}

export enum BUTTON_COLOR {
    INHERIT = 'inherit',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    DEFAULT = 'default',
}

export enum BUTTON_TARGET {
    BLANK = "_blank",
    SELF = "_self",
    PARENT = "_parent",
    TOP = "_top"
}

export interface ButtonControlProps extends UIControlProps {

    children?: React.ReactNode;
    onClick?: (event?: React.MouseEvent) => void;
    size?: ControlSizes;
    variant?: BUTTON_VARIANTS;
    pressed?: boolean;
    disabled?: boolean;
    color?: PropTypes.Color;
    className?: string;
    darkMode?: boolean;
    roundShaped?: boolean;
    startIcon?: string | React.ReactNode;
    endIcon?: string | React.ReactNode;
    href?: string;
    target?: string;
    restrictHoverEffects?: boolean;
    restrictFocusEffects?: boolean;
    highlighted?: boolean;
    dataName?: string;
}

export default class ButtonControl extends UIControl<ButtonControlProps> {

    static defaultProps = {
        ...UIControl.defaultProps,
        size: ControlSizes.LG,
        variant: BUTTON_VARIANTS.TEXT,
        disabled: false,
        darkMode: false,
        pressed: false,
        roundShaped: false,
        color: BUTTON_COLOR.DEFAULT,
        className: "",
        target: BUTTON_TARGET.BLANK,
        restrictHoverEffects: false,
        restrictFocusEffects: false,
        hasErrors: false,
        highlighted: false,
        dataName: null as ButtonControlProps['dataName']
    };

    onClick = (e: React.MouseEvent): void => {

        const {onClick, disabled} = this.props;

        if (!onClick || disabled) {

            return;

        }

        onClick(e);

    };


    render(): React.ReactNode {

        const {
            color,
            pressed,
            children,
            disabled,
            className,
            startIcon,
            endIcon,
            darkMode,
            size,
            roundShaped,
            restrictHoverEffects,
            restrictFocusEffects,
            href,
            target,
            hasErrors,
            dataName,
            highlighted,
        } = this.props;

        let {variant} = this.props;

        const buttonClassName = new BemClassName("");

        buttonClassName.appendAdditionalClassesIf(pressed, "pressed");
        buttonClassName.appendAdditionalClassesIf(darkMode, 'dark-mode');
        buttonClassName.appendAdditionalClassesIf(roundShaped, 'round');
        buttonClassName.appendAdditionalClassesIf(restrictHoverEffects, 'restrict-hover');
        buttonClassName.appendAdditionalClassesIf(restrictFocusEffects, 'restrict-focus');
        buttonClassName.appendAdditionalClassesIf(hasErrors, 'hasErrors');
        buttonClassName.appendAdditionalClassesIf(highlighted, 'highlighted');


        let _startIcon: React.ReactNode = startIcon;

        if (_startIcon) {

            _startIcon = typeof startIcon === 'string' ? <IconAtom name={startIcon} /> : startIcon;

        }

        let _endIcon: React.ReactNode = endIcon;

        if (_endIcon) {

            _endIcon = typeof endIcon === 'string' ? <IconAtom name={endIcon} /> : endIcon;

        }

        const wrapperClassName = new BemClassName("button_control", null, className);

        wrapperClassName.appendStatusIf(!!size, size);
        wrapperClassName.appendStatusIf(!!_endIcon, 'endIcon');
        wrapperClassName.appendStatusIf(!!_startIcon, 'startIcon');

        if (variant === BUTTON_VARIANTS.LINK) {

            buttonClassName.appendAdditionalClasses('link');

            variant = BUTTON_VARIANTS.TEXT;

        }

        if (variant === BUTTON_VARIANTS.FAB) {

            buttonClassName.appendAdditionalClasses('fab');

            variant = BUTTON_VARIANTS.TEXT;

        }

        if (variant === BUTTON_VARIANTS.LAYOUT) {

            buttonClassName.appendAdditionalClasses('layout');

            variant = BUTTON_VARIANTS.TEXT;

        }

        if (highlighted) {

            variant = BUTTON_VARIANTS.TEXT;

        }

        let buttonProps = {
            className: buttonClassName.toString(),
            startIcon: _startIcon,
            endIcon: _endIcon,
            color,
            disabled,
            variant,
            size,
            href,
            target,
        };

        return <UIControlWrapper className={wrapperClassName.toString()} size={size} onClick={this.onClick} dataName={dataName}>
            <Button {...buttonProps}>
                {children}
            </Button>
        </UIControlWrapper>;

    }

}
