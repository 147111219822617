/* eslint-disable */
import NotificationsListItem from "Cheops/components/Notifications/NotificationsListItem";
import clonedeep from "lodash/cloneDeep";


export default function (state = {
    notifications: [],
    has_new_notifications: !!JSON.parse(localStorage.getItem("has_new_notifications")),
    open_course_list_is_loading: true,
    my_course_list_is_loading: true,
    upcoming_course_list_is_loading: true,
    full_course_list_is_loading: true,
    my_course_list: [],
    open_course_list: [],
    upcoming_course_list: [],
    full_course_list: [],
    lecturer_list: [],
    news_data: [],
    current_course: null,
    current_course_learn: null,
    current_module_learn: null,
    current_element_learn: {},
    course_contents: {},
    contents_data: {},
    task_videos: {},
    course_attended: [],
    course_stats: [],
    certificates: [],
    certificate_contents: {},
    main_info: {},
    main_content: {},
    current_module_learn_by_skin: null,
    current_element_learn_thread: null,
    module_progress: null,
    taskHints: [],
}, action) {
    switch (action.type) {
        case "GET_ALL_NOTIFICATIONS_FULFILLED":
            return {
                ...state,
                notifications: action.payload,
            };
        case "GET_NOTIFICATIONS_BY_TM_FULFILLED":

            let notifications = [...state.notifications];

            let new_notifications = action.payload;


            let new_notifications_ids = Object.keys(action.payload);

            for (let i in new_notifications_ids) {
                new_notifications_ids[i] = parseInt(new_notifications_ids[i]);
            }

            for (let i in notifications) {
                if (new_notifications_ids.indexOf(notifications[i].notificationId) !== -1) {
                    notifications[i] = new_notifications[notifications[i].notificationId];
                    delete new_notifications[notifications[i].notificationId];
                }
            }

            for (let i in new_notifications) {
                if (new_notifications[i]) {
                    notifications.push(new_notifications[i]);
                }
            }

            return {
                ...state,
                notifications
            };
        case "CHANGE_HAS_NEW_NOTIFICATIONS":

            localStorage.setItem("has_new_notifications", JSON.stringify(action.status));
            window.dispatchEvent(new StorageEvent('storage', {key: "has_new_notifications"}));

            return {
                ...state,
                has_new_notifications: action.status,
            };
        case "GET_TASK_VIDEO_FULFILLED":

            let task_videos = state.task_videos;
            task_videos[action.payload.video.id] = action.payload.video;

            return {
                ...state,
                task_videos,
            };
        case "GET_ELEMENT_LEARN_DATA_FULFILLED":

            let current_element_learn = {...state.current_element_learn};
            if (!current_element_learn[action.payload.module_id]) {
                current_element_learn[action.payload.module_id] = {};
            }
            current_element_learn[action.payload.module_id][action.payload.element_type + action.payload.element_id] = action.payload.data;

            return {
                ...state,
                current_element_learn,
            };
        case "UNPIN_NOTIFICATION_FULFILLED":
            return (() => {

                let notifications = [...state.notifications];

                for (let i in notifications) {
                    if (notifications[i].notificationId === action.payload) {
                        notifications[i].isPinned = false;
                    }
                }

                return {
                    ...state,
                    notifications,
                };
            })();
        case "MARK_READ_NOTIFICATION_FULFILLED":
            return (() => {

                let notifications = [...state.notifications];

                for (let i in notifications) {
                    if (notifications[i].notificationId === action.payload) {
                        notifications[i].status = NotificationsListItem.STATUS.READ;
                    }
                }

                return {
                    ...state,
                    notifications,
                };
            })();
        case "GET_OPEN_COURSE_LIST_FULFILLED":
            return {
                ...state,
                open_course_list_is_loading: false,
                open_course_list: action.payload.courses,
            };

        case "SET_COURSE_LEARN_DATA_FULFILLED":
            return {
                ...state,
                current_course_learn: action.data,
            };
        case "GET_COURSE_LEARN_DATA_FULFILLED":
            return {
                ...state,
                current_course_learn: action.payload,
            };
        case "GET_MODULE_LEARN_DATA_FULFILLED":
            return {
                ...state,
                current_module_learn: action.payload,
            };
        case "UPDATE_MODULE_LEARN_DATA":
            return {
                ...state,
                current_module_learn: clonedeep(action.data),
            };
        case "GET_CERTIFICATES_FULFILLED":
            return {
                ...state,
                certificates: action.payload,
            };
        case "GET_CERTIFICATE_FULFILLED":
            let certificate_contents = state.certificate_contents;
            certificate_contents[action.payload.hash] = action.payload;
            return {
                ...state,
                certificate_contents,
            };
        case "SET_CERTIFICATES":
            return {
                ...state,
                certificates: {...action.data},
            };
        case "UPDATE_ELEMENT_LEARN_DATA":

            let element_learn = clonedeep(state.current_element_learn);

            if (!element_learn[action.module_id]) {
                element_learn[action.module_id] = {};
            }

            element_learn[action.module_id][action.data.type + "" + action.element_id] = action.data;

            return {
                ...state,
                current_element_learn: element_learn,
            };

        case "GET_COURSE_CONTENT_FULFILLED":

            let course_contents = state.course_contents;
            course_contents[action.payload.course.hash] = action.payload.course;

            return {
                ...state,
                ...course_contents,
            };
        case "GET_CONTENT_FULFILLED":

            let contents_data = state.contents_data;
            contents_data[action.payload.hash] = action.payload.data;

            return {
                ...state,
                ...contents_data
            };
        case "GET_MY_COURSE_LIST_FULFILLED":
            return {
                ...state,
                my_course_list_is_loading: false,
                my_course_list: action.payload.courses,
            };
        case "GET_COURSE_DATA_FULFILLED":
            return {
                ...state,
                current_course: action.payload,
            };
        case "GET_NEWS_BY_ID_FULFILLED":
            return {
                ...state,
                news_data: action.payload,
            };
        case "GET_UPCOMING_COURSE_LIST_FULFILLED":

            return {
                ...state,
                upcoming_course_list_is_loading: false,
                course_list: action.payload.courses,
            };
        case "GET_LECTURER_LIST_FULFILLED":
            return {
                ...state,
                lecturer_list: action.payload.lecturers,
            };
        case "GET_OPEN_COURSE_LIST_PENDING":

            return {
                ...state,
                course_list_is_loading: true,
            };
        case "GET_MY_COURSE_LIST_PENDING":
            return {
                ...state,
                my_course_list_is_loading: true,
            };
        case "GET_UPCOMING_COURSE_LIST_PENDING":
            return {
                ...state,
                upcoming_course_list_is_loading: true,
            };
        case "GET_COURSE_ATTENDED_FULFILLED":
            return {
                ...state,
                course_attended: action.payload,
            };
        case "GET_COURSE_STATS_FULFILLED":
            return {
                ...state,
                course_stats: action.payload,
            };
        case "GET_FULL_COURSE_LIST_FULFILLED":
            return {
                ...state,
                full_course_list_is_loading: false,
                full_course_list: action.payload,
            };
        case "GET_FULL_COURSE_LIST_PENDING":
            return {
                ...state,
                full_course_list_is_loading: true,
            };
        case "GET_MAIN_INFO_FULFILLED":
            return {
                ...state,
                main_info: action.payload,
            };

        case "GET_MAIN_CONTENT_FULFILLED":
            return {
                ...state,
                main_content: action.payload,
            };

        case "GET_MODULE_LEARN_DATA_BY_SKIN_FULFILLED":
            return {
                ...state,
                current_module_learn_by_skin: action.payload,
            };
        case "GET_ELEMENT_LEARN_DATA_THREAD_FULFILLED":
            return {
                ...state,
                current_element_learn_thread: action.payload,
            };
        case "GET_MODULE_PROGRESS_FULFILLED":
            return {
                ...state,
                module_progress: action.payload,
            };
        case "GET_COURSE_BUCKETS_FULFILLED":
            return {
                ...state,
                course_buckets: action.payload.success,
            };
        case "GET_TASK_HINTS_FULFILLED":
            return {
                ...state,
                taskHints: action.payload.success.hints,
            };
        default:
            return state
    }
}
