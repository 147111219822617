import React from "react";
import ReactDOM from "react-dom";
import {Screen} from "@sirius/ui-lib/src/blocks/Layout/index";
import TaskPage from "Smt/TaskPage/TaskPage";
import {getContestIdAndSesionId, getUserInfoFromJWT, removeContestIdAndSessionId} from "Smt/helpers";
import CacheWrapper from "Smt/CacheWrapper";
import Axios from "axios";
import CertificatePage from "Smt/CertificatePage";
import {SmtLocalePlural, SmtLocaleString} from "Smt/SmtLocaleBlock";
import {LocaleDate} from "@sirius/ui-lib/src/blocks/LocaleBlock/index";
import UserProfileConfirmationModal from "Smt/IndexPage/UserProfileConfirmationModal";

export class StartButton extends React.Component {

    handleClick(e) {
        if (!this.state.canStart && !this.state.results_token) {
            return;
        }
        if (this.state.results_token && !this.state.results_data) {
            return;
        }

        if (this.state.results_data && !e.target.classList.contains('header__button_to_tasks')) {
            return;
        }

        if (this.state.results_data) {
            ReactDOM.render(
                <Screen>
                    <TaskPage
                        metadata={this.props.metadata}
                        tasks={this.state.tasks}
                        tsContest={this.props.tsContest}
                        user_score={this.state.user_score}
                        results_data={this.state.results_data}
                        results_token={this.state.results_token}
                    />
                </Screen>,
                document.getElementById("index")
            );
            return;
        }

        if (this.state.tasks && Object.keys(this.state.tasks).length) {
            if (this.props.tsContest.authorizationType === "token") {
                this.setState({userProfileConfirmationVisible: true});
                return;
            }
            this.renderTaskPage();
            return;
        }

        this.props.showPrestartPopup();
    }


    renderTaskPage = () => {
        return ReactDOM.render(
            <Screen>
                <TaskPage
                    results_token={this.state.results_token}
                    metadata={this.props.metadata}
                    tasks={this.state.tasks}
                    tsContest={this.props.tsContest}
                    issuedUntil={this.state.issuedUntil}
                    issuedAt={this.state.issuedAt}
                    tasks_token={this.state.tasks_token}
                />
            </Screen>,
            document.getElementById("index")
        );
    };

    constructor(props) {
        super(props);

        this.state = {
            buttonState: '',
            userToken: "",
            tasks: [],
            contest: this.props.contest,
            issuedUntil: "",
            issuedAt: "",
            user_score: null,
            results_token: null,
            results_data: null,
            userProfileConfirmationVisible: false,
            canStart: false,
        };

        if (this.props.tsInfo.tag === 'NotYetStarted') {

            this.state.buttonState = 'not-yet-started';

        }

        if (this.props.tsInfo.tag === 'ContestRunning') {
            this.state.buttonState = 'running';
            this.state.canStart = true;

            if (this.props.tsInfo.contents.tag === 'ValidSession') {
                this.state.buttonState = 'running';
            }

            if (this.props.tsInfo.contents.tag === 'ExpiredSession') {

                this.state.buttonState = 'finished';
                this.state.canStart = false;
            }
        }

        if (this.props.tsInfo.tag === 'ContestFinished') {
            this.state.buttonState = 'finished';


            if (typeof this.props.tsInfo.contents === 'object') {

                let maxScore = '';
                let totalUsers = '';

                let hasResults = false;
                let contestHasResults = false;
                let tookPart = false;


                for (let i in this.props.tsInfo.contents) {
                    let contents = this.props.tsInfo.contents[i];

                    if (contents.tag === "HasResults") {
                        // button must be disabled until results will be loaded;
                        this.state.buttonState = 'finished';
                        this.state.results_token = contents.contents.token;
                        window.bugReportToken = contents.contents.token;
                        this.state.user_score = contents.contents.userScore;
                        hasResults = true;
                        tookPart = true;
                    }

                    if (contents.tag === "ContestHasResults") {
                        this.state.results_token = contents.contents.token;
                        totalUsers = contents.contents.contestApplicants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        maxScore = contents.contents.contestMaxScore;
                        contestHasResults = true;
                        tookPart = true;
                    }

                    if (contents.tag === "NotAttended") {
                        tookPart = false;
                    }
                    if (contents.tag === "NoResults") {
                        tookPart = true;
                    }
                }

                if (contestHasResults) {

                    if (!hasResults) {

                        this.state.buttonState = 'finished';

                        if (this.props.metadata.contestStatus === "switched") {
                            removeContestIdAndSessionId();
                            location.reload();
                            return;
                        }

                    }

                } else {
                    this.state.buttonState = 'finished';

                    if (!tookPart) {

                        if (this.props.metadata.contestStatus === "switched") {
                            removeContestIdAndSessionId();
                            location.reload();
                            return;
                        }
                    }
                }
            }
        }

        if (typeof this.props.tsInfo.contents.contents === "object") {
            let contents = this.props.tsInfo.contents.contents[0] ? this.props.tsInfo.contents.contents[0] : this.props.tsInfo.contents.contents;

            let tasks = {};


            if (contents.taskGroups) {
                contents.taskGroups.forEach((a) => {

                    let start = a[0] - 1;
                    let end = a[1] - 1;

                    let first_task = contents.tasks[start];

                    contents.tasks[start] = {};
                    contents.tasks[start][start] = first_task;

                    for (let i = start + 1; i <= end; i++) {
                        contents.tasks[start][i] = contents.tasks[i];
                        delete contents.tasks[i];
                    }
                });
            }

            contents.tasks.map((task_hash, task_num) => {
                tasks[task_num] = task_hash;
            });

            this.state.tasks = tasks;
            this.state.issuedUntil = new Date(contents.issuedUntil);
            this.state.issuedAt = new Date(contents.issuedAt);
            this.state.tasks_token = this.props.tsInfo.contents.contents[1];
        }

    }

    componentDidMount() {
        if (this.state.results_token) {
            this.loadResults();
        }
    }

    loadResults() {

        let {contestId, sessionId} = getContestIdAndSesionId();

        let storage_result_key = 'results_data';

        let cached_data = CacheWrapper.getItem(storage_result_key);


        if (cached_data) {

            if (cached_data.contestId === contestId && cached_data.sessionId === sessionId) {

                let buttonState = 'finished_with_button';

                if (cached_data.cert) {
                    buttonState = 'finished_with_certificate';
                }

                this.setState({
                    results_data: cached_data.result,
                    tasks: cached_data.tasks,
                    cert_token: cached_data.cert,
                    cert_name: cached_data.cert_name,
                    buttonState,
                });

                this.props.setResultsData(cached_data);

                return;
            }
        }

        Axios({
            method: "POST",
            url: "/smt-portal/test/results",
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json',
            },
            data: `${this.state.results_token}`,
        }).then((result) => {


            let tasks = {};

            if (result.data.groups) {

                result.data.groups.forEach((a) => {
                    let start = a[0] - 1;
                    let end = a[1] - 1;

                    let first_task = result.data.result[start];

                    result.data.result[start] = {};
                    result.data.result[start][start] = first_task;

                    for (let i = start + 1; i <= end; i++) {
                        result.data.result[start][i] = result.data.result[i];
                        delete result.data.result[i];
                    }
                });
            }
            result.data.result.map((task, task_num) => {
                if (!task.token) {
                    for (let i in task) {
                        if (!tasks[task_num]) {
                            tasks[task_num] = {};
                        }
                        tasks[task_num][i] = task[i].token;
                    }
                } else {
                    tasks[task_num] = task.token;
                }
            });

            let cache_data = {
                contestId,
                sessionId,
                result: result.data.result,
                cert: result.data.cert,
                cert_name: result.data.certName,
                prize: result.data.prize,
                tasks,
            };

            let untilDate = new Date((new Date()).getTime() + (CONFIG.ResultCacheLifetimeMin * 60 * 1000));

            CacheWrapper.setItem(storage_result_key, cache_data, untilDate);

            let buttonState = 'finished_with_button';


            if (result.data.cert) {
                buttonState = 'finished_with_certificate';
            }

            this.props.setResultsData(result.data);

            this.setState({
                results_data: result.data.result,
                tasks: tasks,
                cert_token: result.data.cert,
                cert_name: result.data.certName,
                buttonState
            });
        });
    }


    showCertPage() {
        ReactDOM.render(<CertificatePage
            cert_token={this.state.cert_token}
            tsUser={this.props.tsUser}
        />, document.getElementById("index"));
    }

    getStartButtonText = () => {


        let startButtonText = '';

        let startDate = new Date(this.state.contest.startTime);

        if (this.props.tsInfo.tag === 'NotYetStarted') {

            startButtonText = <>
                <SmtLocaleString
                    k={`${CONFIG.currentInstance}.start_button.not_yet_started_date`}
                    id="value"
                    defaultMessage=""
                    values={{
                        date: <LocaleDate
                            dateValue={startDate}
                            dateOptions={{day: "numeric", month: "long", year: "numeric"}}
                        />
                    }}
                />
                {` `}
                <SmtLocaleString
                    k={`${CONFIG.currentInstance}.start_button.not_yet_started_time`}
                    id="value"
                    defaultMessage=""
                    values={{date: <LocaleDate value={startDate} dateOptions={{hour: "2-digit", minute: "2-digit"}}/>}}
                />
            </>;

        }

        if (this.props.tsInfo.tag === 'ContestRunning') {

            startButtonText = <SmtLocaleString k="common.start_button.start" id="value"/>;

            if (this.props.tsInfo.contents.tag === 'ValidSession') {

                startButtonText = <SmtLocaleString k="common.start_button.continue" id="value"/>;

            }

            if (this.props.tsInfo.contents.tag === 'ExpiredSession') {

                startButtonText = <>
                    <SmtLocaleString
                        k={`${CONFIG.currentInstance}.start_button.took_part`}
                        id="value"
                    />
                    <br/>
                    <SmtLocaleString k="common.start_button.result" id="value"/>
                </>;

            }

        }

        if (this.props.tsInfo.tag === 'ContestFinished') {

            if (typeof this.props.tsInfo.contents === 'object') {

                let maxScore = '';
                let totalUsers = '';

                let hasResults = false;
                let contestHasResults = false;
                let tookPart = false;


                for (let i in this.props.tsInfo.contents) {
                    let contents = this.props.tsInfo.contents[i];

                    if (contents.tag === "HasResults") {

                        hasResults = true;
                        tookPart = true;

                    }

                    if (contents.tag === "ContestHasResults") {

                        totalUsers = contents.contents.contestApplicants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        maxScore = contents.contents.contestMaxScore;
                        contestHasResults = true;
                        tookPart = true;

                    }

                    if (contents.tag === "NotAttended") {
                        tookPart = false;
                    }
                    if (contents.tag === "NoResults") {
                        tookPart = true;
                    }
                }

                if (contestHasResults) {

                    if (hasResults) {

                        startButtonText = <><SmtLocaleString
                            k={`${CONFIG.currentInstance}.start_button.took_part`}
                            id="value"
                        />
                            <br/> </>;

                        if (this.props.metadata.scoreExplanation !== "hide") {

                            startButtonText = <>
                                {startButtonText}
                                {' '}
                                <SmtLocaleString
                                    k="common.start_button.your_result"
                                    id="value"
                                    values={{
                                        userScore: <b>{this.state.user_score}</b>,
                                        maxScore: <b>{maxScore}</b>,
                                        points: <SmtLocalePlural k="common.format_plural.points" value={maxScore}/>,
                                    }}
                                />
                            </>;

                        }

                        if (this.props.metadata.totalParticipants !== "hide") {

                            startButtonText = <>
                                {startButtonText}
                                {' '}
                                <SmtLocaleString
                                    k="common.start_button.total_participants"
                                    id="value"
                                    values={{number: <b>{totalUsers}</b>}}
                                />
                            </>;

                        }

                    } else {

                        startButtonText = <>
                            <SmtLocaleString k={`${CONFIG.currentInstance}.start_button.ended`} id="value"/>
                            {' '}
                            <SmtLocaleString k={`${CONFIG.currentInstance}.start_button.dont_took_part`} id="value"/>
                        </>;

                        if (this.props.metadata.totalParticipants !== "hide") {

                            startButtonText = <>
                                {startButtonText}
                                <br/>
                                <SmtLocaleString
                                    k="common.start_button.total_participants"
                                    id="value"
                                    values={{number: <b>{totalUsers}</b>}}
                                />
                            </>;

                        }

                        if (this.props.metadata.contestStatus === "stopped") {

                            startButtonText = <><SmtLocaleString
                                k={`${CONFIG.currentInstance}.start_button.stopped`}
                                id="value"
                            />
                                <br/>
                                <SmtLocaleString
                                    k="common.start_button.come_back_later"
                                    id="value"
                                />
                            </>;

                        }

                    }

                } else {

                    this.state.buttonState = 'finished';
                    if (tookPart) {

                        startButtonText = <>
                            <SmtLocaleString
                                k={`${CONFIG.currentInstance}.start_button.took_part`}
                                id="value"
                            />
                            <br/>
                            <SmtLocaleString
                                k="common.start_button.future_results"
                                id="value"
                            />
                        </>;

                    } else {

                        startButtonText = <>
                            <SmtLocaleString
                                k={`${CONFIG.currentInstance}.start_button.ended`}
                                id="value"
                            />
                            <SmtLocaleString k={`${CONFIG.currentInstance}.start_button.dont_took_part`} id="value"/>
                            <br/>
                            <SmtLocaleString
                                k="common.start_button.future_results"
                                id="value"
                            />
                        </>;

                        if (this.props.metadata.contestStatus === "stopped") {

                            startButtonText = <>
                                <SmtLocaleString
                                    k={`${CONFIG.currentInstance}.start_button.stopped`}
                                    id="value"
                                />
                                <br/>
                                <SmtLocaleString
                                    k="common.start_button.come_back_later"
                                    id="value"
                                />
                            </>;

                        }

                    }

                }

            }

        }

        return startButtonText;

    };

    render() {

        if (CONFIG.IsNoopolis) {
            let roles = getUserInfoFromJWT('roles');

            if (roles && roles.indexOf('nooRO') !== -1 && this.state.buttonState !== 'finished_with_button') {
                return <></>
            }
        }

        return (
            <div className={"header__button header__button--" + this.state.buttonState}
                 onClick={(e) => this.handleClick(e)}>
                <div className="header__button_text">{this.getStartButtonText()}</div>
                <div>
                    <div className="header__button_to_tasks">
                        <SmtLocaleString k="common.start_button.to_results" id="value"/>
                    </div>
                    <div className="header__button_to_certificate" onClick={() => this.showCertPage()}>
                        {this.state.cert_name ? this.state.cert_name : <SmtLocaleString
                            k="common.start_button.document"
                            id="value"
                        />}
                    </div>
                </div>
                {
                    this.state.userProfileConfirmationVisible &&
                    <UserProfileConfirmationModal
                        tsUser={this.props.tsUser}
                        renderTaskPage={this.renderTaskPage}
                    />
                }
            </div>
        )
    }
}
