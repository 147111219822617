import authReducer from './auth'
import labReducer from './laboratory'
import smtReducer from './smt'
import {combineReducers} from 'redux'

export default combineReducers({
	authReducer,
    labReducer,
    smtReducer
});
