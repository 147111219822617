import bnc                 from "bnc";
import Prism               from "prismjs";
import {getPrismLanguages} from "@sirius/ui-shared/src/components/Programming";

export const block = new bnc("programming-answer");

export const PrismLanguages = getPrismLanguages(Prism.languages);

export enum VerdictPriority {
    'wrong',
    'partly'
}

export type OnlineStateType = {
    solutions:              SmtCache.ReviewsResponseSolution[];
    pending:                SmtCache.ReviewsResponsePending[] & {solution?: SmtCacheV4.LanguageBlock}
    tasksTimers:            SmtCache.TaskSentTime[];
};

export type TaskOnlineStateType = OnlineStateType & {
    hasPending:             boolean;
    hasSolutionOk:          boolean;
    hasSolutionLastNotOk:   boolean;
    hasNextTryLock:         boolean;
    editSolution:           boolean;
    hasSolutions:           boolean;
};


export type MaybeOnlineStateType     = OnlineStateType     | null;
export type MaybeTaskOnlineStateType = TaskOnlineStateType | null;

export type HasSolution = {
    solution?: SmtCache.LanguageBlock;
}
export type HasVerdict = {
    verdict?: SmtCache.Verdict;
}

export type HasIsHidden = {
    isHidden: boolean;
}

export type HasAllowResend = {
    allowResend: boolean;
}

type HasHideCopyPanel = {
    hideCopyPanel: boolean;
}

type HasUserAnswer = {
    userAnswer: SmtTask.LanguageBlock;
};

export type MaybeHasIsHidden       = Partial<HasIsHidden>;
export type MaybeHasAllowResend    = Partial<HasAllowResend>;
export type MaybeHasHideCopyPanel  = Partial<HasHideCopyPanel>;
export type MaybeHasUserAnswer     = Partial<HasUserAnswer>;

export type RightSolutionType = { verdict: "ok" } & HasSolution;

export type OnlineVerdictSolutionType = {
    taskId:                    number;
    num:                       number;
    pending:                   boolean;
    isLast:                    boolean;
    showHeader?:               any;
    clickable?:                boolean;
    content:                   Partial<HasSolution & HasVerdict>;
    sourceFooter:              SmtCache.LanguageBlock[];
    sourceHeader:              SmtCache.LanguageBlock[];
    className?:                string;
    taskToken:                 string;
    unlockNextTry:             ()=>void;
    showDetails:               boolean;
    toggleVerdictsShowDetails: (id: string, val: boolean)=>void;
    summary?:                  SmtPortal.ProgrammingReviewSummary;
} & HasTestsReportCb & HasTestDetailReportCb & MaybeHasIsHidden & MaybeHasHideCopyPanel;

export type ReviewsResponseItemType = (SmtCache.ReviewsResponseSolution | SmtCache.ReviewsResponsePending) & {pending: boolean};

export type ProgrammingAnswerType = {
    taskNum:               number;
    taskToken:             string;
    answersData:           SmtTask.AnswersData;
    onlineState:           TaskOnlineStateType;
    readOnly:              boolean;
    isSavingInProgress:    boolean;
    onAnswer:             (val: SmtTask.LanguageBlock) => void;
    unlockNextTry:        () => void;
} & MaybeHasIsHidden & MaybeHasAllowResend & MaybeHasUserAnswer;

export type LimitsList = Array<[string, number]>;
export type HasLimits = {
    limits: SmtTask.Limits;
};
export type HasExamples = {
    examples: SmtTask.AnswersData['examples'];
};
export type LanguagesSelectorType = {
    disabled:          boolean;
    language:          SmtTask.Language;
    languages:         SmtTask.Language[];
    onLanguageChange:  (val: SmtTask.Language) => void;
};

export type CodeEditorType = {
    code:              SmtTask.Code;
    language:          SmtTask.Language;
    languages:         SmtTask.Language[];
    num:               number;
    sourceFooter:      string;
    sourceHeader:      string;
    status?:           string;
    className?:        string;
    disabled:          boolean;
    hasInput:          boolean;
    onCodeChange:      (val: string) => void;
    onLanguageChange:  (val: SmtTask.Language) => void;
    onRestoreCode:     () => void;
    onClick:           () => void;
};

export type OnlineVerdictsType = {
    sourceHeader:               SmtCache.LanguageBlock[];
    sourceFooter:               SmtCache.LanguageBlock[];
    verdicts:                   ReviewsResponseItemType[];
    taskToken:                  string;
    taskId:                     number;
    showLast:                   boolean;
    allVerdictsCollapsed:       boolean;
    setAllVerdictsCollapsed:    (val: boolean) => void;
    verdictsShowDetails:        string[];
    toggleVerdictsShowDetails:  (id: string, val: boolean)=>void;
    unlockNextTry:              ()=>void;
    showVerdicts:               ReviewsResponseItemType[];
    last:                       ReviewsResponseItemType;
    vNum:                       number;
} & MaybeHasIsHidden & MaybeHasAllowResend;

export type EditorBType = {
    clickable?:      boolean;
    hover?:          boolean;
    focused?:        boolean;
    disabled?:       boolean;
    ignoreTabKey?:   boolean;
    value:           string;
    onFocus?:        () => void;
    onBlur?:         () => void;
    onMouseOver?:    () => void;
    onMouseOut?:     () => void;
    onValueChange?:  (val?: string) => void;
    highlight:       (code: string, offset?: number) => string;
};

export type HasSummary = {
    summary?: Common.Programming.ProgrammingReviewSummary
};


export type HasTestsReportCb = {
    TestsReportCb?: () => Promise<Common.Programming.ReviewTestsReportResponse>;
};

export type HasTestDetailReportCb = {
    TestDetailReportCb?: (testNo: string) => Promise<Common.Programming.ReviewTestProtocolResponse>;
};

export type SolutionType = {
    clickable?:     boolean;
    solution:       SmtCache.LanguageBlock;
    review?:        SmtCache.ProgrammingReview;
    sourceHeader?:  string;
    sourceFooter?:  string;
}   & HasSummary
    & HasTestsReportCb
    & HasTestDetailReportCb
    & MaybeHasIsHidden
    & MaybeHasHideCopyPanel
;

export type HistorySolutionProps = {
    verdict: SmtPortal.Verdict,
    num: number,
    solution: SmtPortal.UserAnswerProgramming[],
    time: number,
    score: number,
    maxScore: number,
    reportRef: SmtPortal.TestResultsHistoryItem["reportRef"],
    taskNo: number,
    review: SmtPortal.TestResultsHistoryItem["review"]
};





