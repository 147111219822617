import React                            from "react";
import ReactDOM                         from "react-dom";
import cloneDeep                        from "lodash/cloneDeep";

import {TaskAnswersData}                from "Lab/Model/Task";

import {CheopsInputLocaleBlock,
        CheopsLocaleString}             from "Cheops/CheopsLocaleBlock";
import BemClassName                     from "Cheops/BemClassName";
import MultipleAnswersFractionPreview   from "Cheops/components/ModulePassing/Answers/AnswerTypes/MultipleAnswer/MultipleAnswersFractionPreview";
import UserAnswerAdapter                from "Cheops/components/ModulePassing/Answers/UserAnswerAdapter";
import AnswerType                       from "Cheops/components/ModulePassing/Answers/AnswerType";
import {TaskAnswerType}                 from "Cheops/Model/Task";
import {InputClientValidation}          from "Cheops/components/ModulePassing/InputClientValidation";

import type {MaybeTaskOnlineStateType}  from "Smt/SmtProgrammingAnswer/block";
import type {
    AnswersValidationReportType,
    SolutionValidationReport,
    ValidationReportType
}                                       from "Smt/TaskPage/Task/ClientValidation";

import                                       "./MultipleAnswers.less";

const emptyAnswers = (length: number) => Array(length).fill('');

type UserAnswerType = (number | string)[];

interface State {
    rawUserAnswer: UserAnswerType;
    fractionPreviewText: string[];
    fractionPreviewErrors: Record<number, Common.Math.LatexResponseError[]>;
}

interface Props {

    answersData: TaskAnswersData;
    placeholder?: string;
    numbersOnly?: boolean;
    readOnly?: boolean;
    isSmt?: boolean;
    userAnswer?: UserAnswerType;
    answerType?: TaskAnswerType;
    autoFocus?: boolean;
    inputMaxWidth?: number; // input max width for inline layout
    inline?: boolean;
    onlineState?: MaybeTaskOnlineStateType;
    unlockNextTry?: () => void;
    validationReport?: ValidationReportType;
    answersValidationReport?: AnswersValidationReportType;

    getFractionLatexAction?(value: string): Promise<Common.Math.LatexResponse>;
    onAnswer?(userAnswer: UserAnswerType): void;

}


const pure__getUserAnswerByProps = ({userAnswer}: Props) =>
    UserAnswerAdapter.multipleAnswers(userAnswer)
;

const EMPTY_ANSWER = '';

const emptyOrString = (a: string | null) => (a === null ? EMPTY_ANSWER : `${a}` );

const pure__getInitRawUserAnswerState = (props: Props): UserAnswerType => {
    const {answersData} = props;
    const userAnswer    = pure__getUserAnswerByProps(props);
    const {inputCount}  = answersData;
    const _inputCount = inputCount === 0 ? 1 : inputCount;
    const hasUserAnswer = Array.isArray(userAnswer) && userAnswer.length > 0;
    const result = hasUserAnswer
            ? [
                ...userAnswer.map( emptyOrString ),
                ...(
                    _inputCount > userAnswer.length
                        ? Array(_inputCount - userAnswer.length).fill(EMPTY_ANSWER)
                        : []
                )
            ]
            : _inputCount === -1
                ? [EMPTY_ANSWER]
                : Array(_inputCount).fill(EMPTY_ANSWER)
    ;
    return result;
}

const pure__getInitFractionPreviewTextState = (props: Props): string[] => {
    const userAnswer = pure__getUserAnswerByProps(props);
    return Array.isArray(userAnswer)
        ? userAnswer.map(() => null)
        : []
    ;
}

export default class MultipleAnswers extends React.Component<Props, State> implements AnswerType<UserAnswerType> {

    static defaultProps = {
        inputMaxWidth: 10,
    };

    getInitRawUserAnswerState(): UserAnswerType {
        return pure__getInitRawUserAnswerState(this.props)
    }

    getInitFractionPreviewTextState(): string[] {
        return pure__getInitFractionPreviewTextState(this.props)
    }

    static getDerivedStateFromProps(props: Props): Partial<State> {
        return (CONFIG.Instance === 'lab')
                ? {
                    rawUserAnswer:       pure__getInitRawUserAnswerState(props),
                }
                : null
        ;
    }

    state: Readonly<State> = {
        rawUserAnswer:         this.getInitRawUserAnswerState(),
        fractionPreviewText:   this.getInitFractionPreviewTextState(),
        fractionPreviewErrors: {},
    };


    componentDidMount(): void {

        this.calculateAnswersWrapperHeight();

        const {rawUserAnswer} = this.state;
        const {onAnswer, userAnswer, getFractionLatexAction} = this.props;

        const isAnswerFilled = this.getIsAnswerFilled();
        const isRatioAllowed = this.getIsRatioAllowed();

        if (isRatioAllowed && getFractionLatexAction && isAnswerFilled) {

            for (const [index, value] of rawUserAnswer.entries()) {

                this.getFractionLatexTimeout = setTimeout(() => this.getFractionLatex(value.toString(), index), 0);

            }

            onAnswer(userAnswer);

        }

        if (this.inputRefs.length) {

            this.inputRefs.forEach((input) => input.addEventListener("wheel", this.onInputWheel, {passive: false}));

        }

        this.updateFieldCountInUserAnswer();

    }

    componentDidUpdate(prevProps: Readonly<Props>): void {

        const {answersData} = this.props;

        if (answersData.inputCount !== prevProps.answersData.inputCount && answersData.inputCount !== -1) {

            this.updateFieldCountInUserAnswer();

        }

    }

    componentWillUnmount(): void {

        if (this.inputRefs.length) {

            this.inputRefs.forEach((input) => input.removeEventListener("wheel", this.onInputWheel));

        }

    }

    onInputWheel = (e: Event): void => e.preventDefault();



    getFractionLatexTimeout: NodeJS.Timeout;

    getIsAnswerFilled(): boolean {

        const {answersData, isSmt} = this.props;

        const userAnswer = pure__getUserAnswerByProps(this.props);

        let inputCount = answersData.inputCount ?? 1;

        if (isSmt) {

            return Array.isArray(userAnswer) && userAnswer.filter((a) => a !== '').length > 0;

        }

        if (inputCount === -1) {

            return Array.isArray(userAnswer) && userAnswer.filter((a) => a === '').length === 0;

        }

        return Array.isArray(userAnswer) && userAnswer.filter((a) => a !== '').length === inputCount;

    }

    getAnswerHasError(): boolean {

        const {answersData, isSmt} = this.props;

        const userAnswer = pure__getUserAnswerByProps(this.props);

        const {fractionPreviewErrors} = this.state;

        if (this.getFractionLatexTimeout) {

            return true;

        }

        let inputCount = answersData.inputCount ?? 1;

        if (inputCount !== -1 && !isSmt) {

            const answersLength = userAnswer?.filter((a) => a !== '')?.length;

            if (answersLength !== inputCount && answersLength > 0) {

                return true;

            }

        }

        return Object.keys(fractionPreviewErrors).length > 0;

    }

    getIsOnlyNumbersAllowed = (): boolean => {

        const {answerType} = this.props;

        return answerType === TaskAnswerType.NUMBER;

    };

    getIsRatioAllowed = (): boolean => {

        const {answersData} = this.props;
        const numbersOnly = this.getIsOnlyNumbersAllowed();

        return numbersOnly && answersData.isRatioAllowed;

    };

    updateFieldCountInUserAnswer(): void {

        const {rawUserAnswer,
               fractionPreviewText} = this.state;
        const {answersData}         = this.props;
        const {inputCount}          = answersData;
        const rawCount              = rawUserAnswer.length;
        const needAlterAnswers      = inputCount !== -1 &&
                                      inputCount !== rawCount
        ;

        if (needAlterAnswers) {
            const diffCount    = inputCount - rawCount;
            const addEmpty     = inputCount > rawCount;
            this.setState({
                rawUserAnswer: addEmpty
                                ? [...rawUserAnswer, ...emptyAnswers(diffCount)]
                                : rawUserAnswer.slice(diffCount),
                fractionPreviewText: addEmpty
                                ? [...fractionPreviewText, ...emptyAnswers(diffCount)]
                                : fractionPreviewText.slice(diffCount)
            });
        }
    }

    prepareUserAnswer(userAnswer: UserAnswerType): UserAnswerType {
        if (userAnswer.filter((a) => a !== '' && a !== null).length === 0) {
            userAnswer = null;
        }
        return userAnswer;
    }

    setUserAnswer(rawUserAnswer: UserAnswerType, fractionPreviewText: string[]): void {
        const {onAnswer}              = this.props;
        const {fractionPreviewErrors} = this.state;
        const numbersOnly             = this.getIsOnlyNumbersAllowed();
        const isRatioAllowed          = this.getIsRatioAllowed();

        if (rawUserAnswer.length === 0) {
            rawUserAnswer = [""];
        }
        let userAnswer = cloneDeep(rawUserAnswer);
        if (numbersOnly && !isRatioAllowed) {
            userAnswer = rawUserAnswer.map((answer) => this.getParsedNumber(answer));
        }
        userAnswer.forEach(
            (answer, index) => {
                if (answer === '') {
                    delete fractionPreviewErrors[index];
                    fractionPreviewText[index] = '';
                }
            }
        );

        this.setState({rawUserAnswer, fractionPreviewText, fractionPreviewErrors});
        if (userAnswer.filter((a) => a !== '' && a !== null).length === 0) {
            if (Object.keys(fractionPreviewErrors).length === 0) {
                clearTimeout(this.getFractionLatexTimeout);
                this.getFractionLatexTimeout = null;
            }
        }
        userAnswer = this.prepareUserAnswer(userAnswer);
        onAnswer(userAnswer);
    }


    getParsedNumber(value: string | number): number {
        if (typeof value === "number") {
            return value;
        }
        value = value.replace(',', '.');
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return null;
        }
        return parsedValue;
    }

    removeField(index: number): void {
        let {rawUserAnswer, fractionPreviewText, fractionPreviewErrors} = this.state;
        rawUserAnswer.splice(index, 1);
        fractionPreviewText.splice(index, 1);
        delete fractionPreviewErrors[index];
        const errorKeys = Object.keys(fractionPreviewErrors).map((strKey) => parseInt(strKey));
        for (const errorKey of errorKeys) {
            if (errorKey > index) {
                fractionPreviewErrors[errorKey - 1] = fractionPreviewErrors[errorKey];
                delete fractionPreviewErrors[errorKey];
            }
        }
        this.setState({fractionPreviewErrors}, () => this.setUserAnswer(rawUserAnswer, fractionPreviewText));
    }


    addField = (): void => {
        let {rawUserAnswer, fractionPreviewText} = this.state;
        rawUserAnswer.push('');
        fractionPreviewText.push('');
        this.setUserAnswer(rawUserAnswer, fractionPreviewText);
    };

    getFractionLatex = async (_value: string, index: number): Promise<void> => {
        const {onAnswer} = this.props;
        const {rawUserAnswer} = this.state;

        if (!_value) {
            this.getFractionLatexTimeout = null;
            const _userAnswer = this.prepareUserAnswer(rawUserAnswer);
            onAnswer(_userAnswer);
            return;
        }

        const {getFractionLatexAction} = this.props;
        const {fractionPreviewErrors, fractionPreviewText} = this.state;
        const value = _value.replace(',', '.');

        if (!getFractionLatexAction) {
            this.getFractionLatexTimeout = null;
            const _userAnswer = this.prepareUserAnswer(rawUserAnswer);
            onAnswer(_userAnswer);
            return;
        }

        const result = await getFractionLatexAction(value);

        if (result.error) {
            fractionPreviewErrors[index] = result.error;
        } else {
            delete fractionPreviewErrors[index];
        }

        if (result.success) {
            fractionPreviewText[index] = result.success;
        } else {
            delete fractionPreviewText[index];
        }

        this.getFractionLatexTimeout = null;
        // onAnswer need for update userAnswer in props;
        const _userAnswer = this.prepareUserAnswer(rawUserAnswer);
        this.setState({fractionPreviewErrors, fractionPreviewText}, () => onAnswer(_userAnswer));
    };

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const {readOnly} = this.props;
        if (readOnly) {
            return;
        }

        const {rawUserAnswer, fractionPreviewText} = this.state;
        const {value} = e.target;
        let inputValue = e.target.value;
        const isRatioAllowed = this.getIsRatioAllowed();
        if (isRatioAllowed) {
            clearTimeout(this.getFractionLatexTimeout);
            this.getFractionLatexTimeout = setTimeout(() => this.getFractionLatex(value, index), 700);
        }
        rawUserAnswer[index] = inputValue;
        fractionPreviewText[index] = '';
        this.setUserAnswer(rawUserAnswer, fractionPreviewText);
    };


    calculateAnswersWrapperHeight(): void {
        const thisNode = ReactDOM.findDOMNode(this) as HTMLElement;
        const multiplyRightAnswers = thisNode.querySelector('.multiply_right_answers');
        if (multiplyRightAnswers) {
            const multiplyAnswers = thisNode.querySelector('.multiply_answers');
            (multiplyAnswers as HTMLElement).style.height = `${multiplyRightAnswers.clientHeight}px`;
        }
    }


    onInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    };

    onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    };


    onInputKeypress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const isRatioAllowed = this.getIsRatioAllowed();
        const numbersOnly = this.getIsOnlyNumbersAllowed();
        if (numbersOnly && !isRatioAllowed) {
            const charCode = (typeof event.which === "undefined") ? event.keyCode : event.which;
            const charStr = String.fromCharCode(charCode);
            if (!/^[0-9-.,]+$/.exec(charStr)) {
                event.preventDefault();
            }
        }
    };

    getSuitablePlaceholder = (placeholder: string): string => {
        const {inputMaxWidth, inline} = this.props;
        if (!inline) {
            return placeholder;
        }
        return placeholder.length < inputMaxWidth ? placeholder : "";
    };

    getPlaceholderTemplate = (): string => {
        const numbersOnly    = this.getIsOnlyNumbersAllowed();
        const isRatioAllowed = this.getIsRatioAllowed();
        return isRatioAllowed
                ? 'cheops.multiple_answer.placeholder_ratio'
                : numbersOnly
                    ? 'cheops.multiple_answer.placeholder_number'
                    : 'cheops.multiple_answer.placeholder_text'
            ;
    };

    onInputClick = (e: React.MouseEvent<HTMLInputElement>): void => {
        const {isSmt, unlockNextTry, onlineState} = this.props;
        if (isSmt && onlineState?.hasNextTryLock) {
            unlockNextTry()
        }
    }

    getInputType(): string {
        const numbersOnly = this.getIsOnlyNumbersAllowed();
        const isRatioAllowed = this.getIsRatioAllowed();
        if (!numbersOnly) {
            return "text";
        }
        let type = "number";
        // force show default keyboard on samsung devices
        if (/SM-|SHV-|SPH-|SGH-|SCH-|GT-/.test(navigator.userAgent)) {
            type = "text";
        }
        // for correct handling of decimal points and commas in Firefox
        if (navigator.userAgent.includes("Firefox")) {
            type = "text";
        }
        if (isRatioAllowed) {
            type = "text";
        }
        return type;

    }

    renderDeleteAnswerIcon = (index: number): React.ReactNode =>
        <div
            className='multiply_answers__icon multiply_answers__icon--remove'
        >
            <svg onClick={() => this.removeField(index)}>
                <use xlinkHref={`${require("./img/icons.svg")}#plus`} />
            </svg>

            <div className='multiply_answers__baloon multiply_answers__baloon--right'>
                <CheopsLocaleString k="cheops.multiple_answer.delete_answer" id="value" />
            </div>
        </div>
    ;

    getInputWrapperClassName = (): BemClassName => {
        const {answersData, inline} = this.props;
        const className = new BemClassName('multiply_answers__input_wrapper');
        className.appendStatusIf(answersData.inputCount === 1 || !answersData.inputCount || answersData.inputsInRow === 1, 'singleton');
        className.appendStatusIf(answersData.inputsInRow === 2, 'double');
        className.appendStatusIf(answersData.inputsInRow === 3, 'triple');
        className.appendStatusIf(inline, 'inline');
        return className;
    };

    inputRefs: HTMLInputElement[] = [];

    renderField(index: number): React.ReactNode {

        const {answersData, autoFocus, inputMaxWidth, inline, placeholder, readOnly, answersValidationReport} = this.props;
        const {rawUserAnswer, fractionPreviewText, fractionPreviewErrors} = this.state;
        const value = rawUserAnswer[index];
        const isRatioAllowed = this.getIsRatioAllowed();
        const isFractionPreviewError = (!!value && !!fractionPreviewErrors[index]);

        const className = this.getInputWrapperClassName();
              className.appendStatusIf(isRatioAllowed, 'fraction-allowed');
              className.appendStatusIf(isFractionPreviewError , 'error');

        const inputClassName = new BemClassName('multiply_answers__input');
              inputClassName.appendStatusIf(inline, 'inline');

        const questionIconClassName = new BemClassName('multiply_answers__icon', 'question');
              questionIconClassName.appendStatusIf(inline, 'inline');

        const inputClientValidationReport = (
                answersValidationReport &&
                answersValidationReport?.field &&
                (index in answersValidationReport?.field)
            )
            ? answersValidationReport.field[index].find( (report: SolutionValidationReport) => report !== false )
            : false
        ;

        const inputClientValidationReportLevel = inputClientValidationReport ? inputClientValidationReport.level : false;
              inputClassName.appendStatusIf(inputClientValidationReportLevel !== false, `${inputClientValidationReportLevel}`);

        return (
            <div
                className={`${className}`}
                key={`multiply_answer_field_${index}`}
            >
                <CheopsInputLocaleBlock
                    k             = {this.getPlaceholderTemplate()}
                    innerRef      = {(ref: HTMLInputElement) => ref && this.inputRefs.push(ref)}
                    placeholder   = {placeholder}
                    inputMaxWidth = {inputMaxWidth}
                    inline        = {inline}
                    value         = {value}
                    autoFocus     = {autoFocus}
                    className     = {`${inputClassName}`}
                    type          = {this.getInputType()}
                    onKeyPress    = {this.onInputKeypress}
                    onKeyUp       = {this.onInputKeyUp}
                    onKeyDown     = {this.onInputKeyDown}
                    onChange      = {(e: React.ChangeEvent<HTMLInputElement>) => this.onInputChange(e, index)}
                    size          = {inline ? inputMaxWidth : null}
                    readOnly      = {readOnly}
                    onClick       = {this.onInputClick}
                />
                {
                    isRatioAllowed &&
                    <>
                        <div className={`${questionIconClassName}`}>
                            <svg><use xlinkHref={`${require("./img/icons.svg")}#question`} /></svg>
                        </div>
                        <div className='multiply_answers__baloon multiply_answers__baloon--right'>
                            <CheopsLocaleString k="cheops.multiple_answer.hint" id="value" />
                            <span style={{whiteSpace: "nowrap"}}> (1 3/8)</span>
                        </div>
                    </>
                }
                {
                    answersData.inputCount === -1 && rawUserAnswer.length > 1 &&
                    this.renderDeleteAnswerIcon(index)
                }
                {
                    inputClientValidationReport &&
                    <InputClientValidation report={inputClientValidationReport} />
                }
                {
                    !inputClientValidationReport && isRatioAllowed &&
                    <MultipleAnswersFractionPreview
                        value                 = {value}
                        fractionPreviewText   = {fractionPreviewText[index]}
                        fractionPreviewErrors = {fractionPreviewErrors[index]}
                    />
                }
        </div>);

    }

    render(): React.ReactNode {

        let {answersData} = this.props;
        let {rawUserAnswer} = this.state;

        let inputWrapperClassName = this.getInputWrapperClassName();

        let addEnabled = true;

        if (rawUserAnswer[rawUserAnswer.length - 1] === '') {

            addEnabled = false;

        }

        return (<div className='multiply_answers'>

            {
                rawUserAnswer.map(
                    (a, i) => this.renderField(i)
                )
            }

            {answersData.inputCount === -1
            && <div className={`${inputWrapperClassName}`}>
                <button
                    disabled={!addEnabled}
                    className='multiply_answers__icon multiply_answers__icon--add'
                    onClick={this.addField}
                >
                    <div className='multiply_answers__baloon'>
                        <CheopsLocaleString k="cheops.multiple_answer.add_answer" id="value" />
                    </div>
                    <svg>
                        <use xlinkHref={`${require("./img/icons.svg")}#plus`} />
                    </svg>
                </button>

            </div>}

        </div>);

    }

}
