import React                          from "react";
import bnc                            from "bnc";
import Editor                         from "fork-react-simple-code-editor";
import Prism                          from 'prismjs';
import Select                         from '@material-ui/core/Select';
import MenuItem                       from "@material-ui/core/MenuItem";
import {
    getPrismLanguages,
    getLanguagePickerName
}                                     from "@sirius/ui-shared/src/components/Programming";
import TaskHelpers                    from "Cheops/TaskHelpers";
import Helpers                        from "Cheops/Helpers";
import {ELEMENT_VERDICT}              from "Cheops/constants";
import CircularProgress               from "Smt/Ui";
import {ElementVerdictWeights}        from "Cheops/Model/Element";
import ProgrammingAnswerExamples      from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswerExamples";
import ProgrammingAnswerLimits        from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswerLimits";
import {ProgrammingSolution}          from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingSolution";
import CodeFromFileUploader           from "Cheops/components/ModulePassing/Answers/AnswerTypes/CodeFromFileUploader/CodeFromFileUploader";
import type {
    SolutionType,
    SolutionRendered,
    TProgrammingAnswerProps,
    TProgrammingAnswerState,
    HasElementLearn,
    AnswerWithVerdictAndScore
}                                     from './@types';
import {
    VerdictWeights,
    Language2Ext,
}                                     from './@types';
import                                     "prismjs-material-theme/css/lighter.css";
import                                     "./ProgrammingAnswer.less";

const solutionBlock = new bnc('programming-answer-solution');
const block         = new bnc('programming-answer');

const formatScore = (score: number, max: number) => `${score} из ${max}`;

const lineDelimRegex = /\r?\n/;

export default class ProgrammingAnswer extends React.Component<TProgrammingAnswerProps> {

    static defaultProps: TProgrammingAnswerProps = {
        courseId:                null,
        formVisible:             false,
        isSolutionToShow:        false,
        userAnswer:              {code: ""},
        openAnswerForm:          () => void(0),
        closeAnswerForm:         null,
        elementLearn:            {},
        elementContent:          {},
        forceShowRight:          false,
        solutionIsNotEditable:   false,
        hideExpandSolutionPanel: false,
        exportPageMode:          false,
        onAnswer:                () => {},
    };

    state: Readonly<TProgrammingAnswerState> = {
        fileDraggedCounter:    0,
        allAnswersExpanded:    false,
        expandedAnswers:       [],
        editorIsFocused:       false,
        fileIsUploading:       false,
        oversizePopupVisible:  false,
        editorCodeExportPage:  {code: '', language: null},
    };


    componentDidMount() {

        const {userAnswer, elementLearn} = this.props;

        if (!elementLearn.isClosed) {

            let code = userAnswer.code;

            if (!code) {

                code = this.getTemplate(this.getUserAnswerLanguage());

            }

            this.setUserAnswer(code);

        }

    }

    componentDidUpdate(prevProps: TProgrammingAnswerProps) {

        const {formVisible, isSolutionToShow} = this.props;

        if (!isSolutionToShow && prevProps.formVisible !== formVisible) {

            if (formVisible === false) {

                this.setUserAnswer('');

            } else {

                this.setAnswerFromLastSolution();

            }

        }

    }

    editorRef: React.RefObject<{ _input: HTMLInputElement }> = React.createRef();
    solutionsToShowCount = 1;

    getIsAnswerFilled = () => true;
    getAnswerHasError = () => false;


    fillFromFile = (code: string) => {

         this.setUserAnswer(code);

    }


    focusEditor = () => {
        const _input = this.editorRef?.current?._input;
        _input && _input.focus();
    };


    highlightCode = (code: string, showLineNumbers = true, viewMode = false) => {

        const language = this.getUserAnswerLanguage();
        const PrismLanguages = getPrismLanguages(Prism.languages);

        let highlighted = Prism.highlight(code, PrismLanguages[language], language);

        if (showLineNumbers) {

            const codeLines = viewMode
                        ? highlighted.split(lineDelimRegex).slice(0,999)
                        : highlighted.split(lineDelimRegex)
            ;

            highlighted =  codeLines
                    .map(
                        (line, i) =>
                            `<span class="${block.el('editor-line-number')}">${
                                line +
                                (i === 998 && viewMode ? '\n...' : '')
                            }</span>`
                    )
                    .join('\n')
            ;
        }

        return highlighted;

    };


    getUserAnswerLanguage = () => {

        const {userAnswer, elementContent} = this.props;

        let {language} = userAnswer;

        if (!language) {

            language = elementContent.answersData.languages[0];

        }

        return language;
    };


    setUserAnswer = (code: string) => {

        const {onAnswer} = this.props;
        const userAnswer = {...this.props.userAnswer};

        userAnswer.language = this.getUserAnswerLanguage();
        userAnswer.code = code;

        onAnswer(userAnswer);

    };

    setUserAnswerLanguage = (language: LabSmt.Language) => {

        const {onAnswer} = this.props;
        const userAnswer = {...this.props.userAnswer};

        userAnswer.language = language;
        userAnswer.code = "";

        const templateCode = this.getTemplate(language);

        if (templateCode) {

            userAnswer.code = templateCode;

        }

        onAnswer(userAnswer);

    };

    setAnswerFromLastSolution = () => {

        const {onAnswer, elementLearn} = this.props;

        const {answers} = elementLearn.solution;

        const {solution} = answers[0].answer;

        onAnswer(solution);

    };

    editLastSolution = () => {

        const {openAnswerForm} = this.props;

        this.setAnswerFromLastSolution();
        openAnswerForm();

    };


    expandSolution(index: number) {

        const {expandedAnswers} = this.state;

        expandedAnswers.push(index);

        this.setState({expandedAnswers});

    }


    getTemplate(language: string) {

        const {elementContent} = this.props;
        const {templates} = elementContent.answersData;

        if (templates) {

            const foundTemplate = templates.find((template) => template.language === language);

            if (foundTemplate) {

                return foundTemplate.code;

            }

        }

        return '';

    }


    renderSolution = (solution: SolutionRendered, index= -1) => {

        const timeDate = new Date(solution.time);

        const {
            elementLearn,
            formVisible,
            isSolutionToShow,
            forceShowRight,
        } = this.props;

        const {code, language} = solution.answer.solution;

        const {allAnswersExpanded, expandedAnswers} = this.state;

        if (!allAnswersExpanded) {

            index = elementLearn.solution.answers.length - this.solutionsToShowCount;

        }

        let isEditable = false;

        if (elementLearn.solution.answers.length === index + 1
            && (solution.verdict !== ELEMENT_VERDICT.NOTREADY)
            && (!elementLearn.isClosed || elementLearn.extraSolutions)
            && !this.props.solutionIsNotEditable) {

            isEditable = true;

        }

        if (isEditable && formVisible) {

            return null;

        }

        if (isSolutionToShow && forceShowRight) {

            solution.verdict = ELEMENT_VERDICT.OK as keyof typeof VerdictWeights;

        }

        const isAnswerExpanded = expandedAnswers.includes(index) || elementLearn.solution.answers.length === index + 1;

        const downloadFilename = `code.${ Language2Ext[language as keyof typeof Language2Ext] }`;

        const isDouble = solution.verdict !== ELEMENT_VERDICT.NOTREADY;

        let solutionReview = null;

        if (solution.review) {

            const reviewParts = solution.review.message.split('\n');

            const submission = solution?.summary
                ? null
                : solution.submission;

            solutionReview = {
                message: reviewParts[0],
                errors: reviewParts.slice(1),
                submission,
            };

        }

        let onEditorWrapperClick = null;

        if (isEditable) {

            onEditorWrapperClick = this.editLastSolution;

        }

        let onTitleClick = null;

        if (!isAnswerExpanded) {

            onTitleClick = () => this.expandSolution(index);

        }

        const languageName = getLanguagePickerName(language);

        const sourceHeader = this.getSourceHeader(language);
        const sourceFooter = this.getSourceFooter(language);

        if (this.props.solutionIndex) {

            index = this.props.solutionIndex;

        }

        const solutionMaxScore = elementLearn.score || elementLearn.max;

        return (
        <div className = {solutionBlock
                          + solutionBlock.mod(`verdict-${solution.verdict}`)
                          + solutionBlock.bod('editable', isEditable)
                          + solutionBlock.bod('collapsed', !isAnswerExpanded)}
             key       = {index}
        >
            {!isSolutionToShow
            && <div className = {solutionBlock.el('title')}
                    onClick   = {onTitleClick}
            >
                {`Решение ${index + 1}. ${languageName} `}

                {!isNaN(timeDate.getDate())
                && <span className = {solutionBlock.el('title-time')}>
                      {timeDate.toLocaleString("ru", {
                          hour: "2-digit",
                          minute: "2-digit",
                      })}
                </span>}

                {solution.verdict === ELEMENT_VERDICT.NOTREADY
                && <span className = {solutionBlock.el('verdict')}>
                    {TaskHelpers.getSolutionTextByVerdict(solution.verdict)}
                </span>}

                <span className = {solutionBlock.el('check')
                                  + solutionBlock.el('check').bod('double', isDouble)}
                />

                {solution.verdict !== ELEMENT_VERDICT.NOTREADY
                    && Number.isFinite(solution.score)
                    && Number.isFinite(solutionMaxScore)
                    &&  <span className = {solutionBlock.el('score')}>
                            {`${solution.score} из ${solutionMaxScore}`}
                        </span>
                }

            </div>}

            {isSolutionToShow
            && <div className = {solutionBlock.el('title')}>{languageName}</div>}
            {isAnswerExpanded
            && <div className = {solutionBlock.el('editor-wrapper')
                                + solutionBlock.el('tests-report')}
                >

                {!!sourceHeader
                && <pre className               = {solutionBlock.el('code-header')}
                        dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceHeader, false)}}
                />}

                <div className={solutionBlock.el('editor-textarea-wrapper')}>
                    <Editor
                        onClick           = {onEditorWrapperClick}
                        readOnly
                        textareaClassName = {block.el('editor-textarea')}
                        className         = {block.el('editor') +
                                             block.el('editor').mod('solution')}
                        value             = {code}
                        highlight         = {(code) => this.highlightCode(code, true, true)}
                        onValueChange     = {null}
                        paddingLeft       = {56}
                    />
                </div>

                {!!sourceFooter
                && <pre className               = {solutionBlock.el('code-footer')}
                        dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceFooter, false)}}
                />}

                {!isSolutionToShow
                && <span className = {solutionBlock.el('footer')}>
                    <img onClick = {() => Helpers.copyToClipboard(code)}
                         src     = {require("CheopsRoot/img/icon_copy.svg")}
                         alt     = "Скопировать"
                    />
                    <img onClick = {() => Helpers.downloadFile(downloadFilename, code)}
                         src     = {require("CheopsRoot/img/icon_download.svg")}
                         alt     = "Скачать файл"
                    />

                    {solutionReview
                    && <div className = {solutionBlock.el('error')}>
                          {solutionReview.message}
                          {solutionReview.errors.map((error: string, i: number) =>
                              <div className = {solutionBlock.el('errors')}
                                   key       = {i}
                              >
                                  {error}
                              </div>
                          )}
                    </div>}

                    {solutionReview
                    && solutionReview.submission
                    && <div className = {solutionBlock.el('error-description')}>
                            <div>Подробная информация</div>
                            <div>{solutionReview.submission.headline}</div>
                            <pre>{solutionReview.submission.message}</pre>
                    </div>}

                    {
                        solution
                        && solution.summary
                        &&  <ProgrammingSolution
                            summary={solution.summary}
                            elementId={elementLearn.id}
                            submissionId={solution.submissionId}
                            extEnvironments={solution.extEnvironments}
                        />

                    }

                </span>}
            </div>}
        </div>

    )};

    expandAllSolutions = () => {

        this.setState({allAnswersExpanded: true});

    };


    getSourceHeader(language: string) {

        const {elementContent} = this.props;

        if (elementContent.answersData.sourceHeader) {

            const headerForLang = elementContent.answersData.sourceHeader.find((header) => header.language === language);

            if (headerForLang) {

                return headerForLang.code;

            }

        }

        return null;

    }


    getSourceFooter(language: string) {

        const {elementContent} = this.props;

        if (elementContent.answersData.sourceFooter) {

            const footerForLang = elementContent.answersData.sourceFooter.find((footer) => footer.language === language);

            if (footerForLang) {

                return footerForLang.code;

            }

        }

        return null;

    }

    getBestVerdictAndBestScore(
        answers: SolutionType[]
    ): [bestVerdict:string, bestScore: number] {

        let bestVerdictWeight = -1;
        let bestScore = 0;

        for (const answer of answers) {

            if (answer.score > bestScore) {

                bestScore = answer.score;

            }

            if (VerdictWeights[answer.verdict] > bestVerdictWeight) {

                bestVerdictWeight = VerdictWeights[answer.verdict];

            }

        }

        let bestVerdict = ELEMENT_VERDICT.NONE;

        if (bestVerdictWeight !== -1) {

            [bestVerdict] = Object.entries(ElementVerdictWeights).find(([, r]) => r === bestVerdictWeight);

        }

        return [bestVerdict, bestScore];

    }

    onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        if (this.props.onSelectProgrammingLanguage) {

            this.props.onSelectProgrammingLanguage(event.target.value);

        }

        this.setUserAnswerLanguage(event.target.value as LabSmt.Language);

    }

    onChangeSolutionLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const {answers} = this.props.elementLearn.solution;

        answers.forEach((item) => {

            if (item.answer.solution.language === event.target.value) {
                this.setState({
                    editorCodeExportPage: {
                        language: event.target.value,
                        code:     item.answer.solution.code
                    }
                });
            }
        })
    }


    render() {

        let solutionsToShowCount = this.solutionsToShowCount;

        const {
            userAnswer,
            elementLearn,
            elementContent,
            forceShowRight,
            isSolutionToShow,
            hideExpandSolutionPanel,
            exportPageMode,
            readOnly
        } = this.props;

        let {formVisible} = this.props;

        let {fileDraggedCounter} = this.state;
        const {
            allAnswersExpanded,
            editorIsFocused,
            fileIsUploading,
            editorCodeExportPage
        } = this.state;

        const userLang = this.getUserAnswerLanguage();

        const sourceHeader = this.getSourceHeader(userLang);
        const sourceFooter = this.getSourceFooter(userLang);

        let answers = [...elementLearn.solution.answers];

        let bestVerdict = '';
        let bestScore   = 0;

        if (!allAnswersExpanded) {

            if (formVisible) {

                solutionsToShowCount = 0;

            }

            [bestVerdict, bestScore] = this.getBestVerdictAndBestScore(
                answers.slice(solutionsToShowCount) as AnswerWithVerdictAndScore[]
            );

            answers = answers.slice(0, solutionsToShowCount);

        }

        answers.reverse();

        if (elementLearn.solution.answers.length === 0) {

            formVisible = true;

        }


        if (forceShowRight) {

            const solution = elementLearn.solution.solutionToShow.programming;

            return this.renderSolution({
                answer: {solution},
            });

        }

        const editorCode       = editorCodeExportPage.code || answers[0] && answers[0].answer.solution.code || null;
        const selectorLanguage = editorCodeExportPage.language || answers[0] && answers[0].answer.solution.language || null;

        const hasExamples = !!this.props.elementContent.answersData.examples;
        const hasLimits   = !!this.props.elementContent.answersData.limits;

        const isCodeToShow = exportPageMode
                             && (isSolutionToShow || sourceHeader || sourceFooter)
        ;

        const showExpandButton = !allAnswersExpanded
                                  && !isSolutionToShow
                                  && elementLearn.solution.answers.length > solutionsToShowCount
                                  && !hideExpandSolutionPanel
        ;

        return (
            <div className = {`${ block 
                                + block.bod('file-hover', fileDraggedCounter > 0)}`}
            >

            { showExpandButton
            && <div
                className = {
                    block.el('expand-button')
                    + block.el('expand-button').mod(`verdict-${bestVerdict}`)
                }
                onClick   = {this.expandAllSolutions}
            >
                {`${elementLearn.solution.answers.length - solutionsToShowCount} `}
                {Helpers.formatPlural(elementLearn.solution.answers.length - solutionsToShowCount, ['решение', 'решения', 'решений'])}
                <span className = {solutionBlock.el('score')}>
                    {
                        formatScore(
                            bestScore,
                            Math.max(
                                0,
                                parseInt(`${elementLearn.max   || 0}`, 10),
                                parseInt(`${elementLearn.score || 0}`, 10)
                            )
                        )
                    }
                </span>

            </div>}

            {exportPageMode ?
            <>
                {hasExamples && <ProgrammingAnswerExamples examples = {this.props.elementContent.answersData.examples}/>}
                {hasLimits   && <ProgrammingAnswerLimits   limits   = {this.props.elementContent.answersData.limits}/>}
            </>
            : answers.map(this.renderSolution)}

            {formVisible
            && <div
                className   = {
                            block.el("field-wrapper")
                            + block.bod('focused', editorIsFocused)
                            + block.bod('file-uploading', fileIsUploading)
                            + block.bod('has-parts', !!sourceHeader || !!sourceFooter)
                }
                onDragEnter = {() => this.setState({fileDraggedCounter: ++fileDraggedCounter})}
                onDragLeave = {() => this.setState({fileDraggedCounter: --fileDraggedCounter})}
                onDrop      = {() => this.setState({fileDraggedCounter: 0})}
                onClick     = {this.focusEditor}
            >

                {fileIsUploading
                && <CircularProgress centerOfWindow={true} />}

                {editorIsFocused
                ? <div className = {block.el('title')}>
                    {`Решение ${elementLearn.solution.answers.length + 1}`}
                  </div>
                : <div className = {block.el('title')}>Код</div>}

                {elementContent.answersData.languages.length > 1
                    ?  <Select className = {`${block.el('lang-select')}`}
                               value     = {this.getUserAnswerLanguage()}
                               onChange  = {this.onSelectChange}
                               onClick   = {(e) => e.stopPropagation()}
                       >
                            {elementContent.answersData.languages.map((language) =>
                                <MenuItem value = {language}
                                          key   = {language}
                                >
                                    {getLanguagePickerName(language)}
                                </MenuItem>
                            )}
                       </Select>
                    :   <div className = {`${block.el('lang-select')}`}>
                            {getLanguagePickerName(this.getUserAnswerLanguage())}
                        </div>
                }

                {!!sourceHeader
                && <pre className               = {block.el('editor-header')}
                        dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceHeader, false)}}
                />}

                <div className = {block.el('editor-wrapper')}>
                    <Editor
                        autoFocus         = {elementLearn.solution.answers.length > 0}
                        textareaClassName = {`${block.el('editor-textarea')}`}
                        className         = {block.el('editor')
                                                +block.el('editor').mod('solution')}
                        ref               = {this.editorRef as null}
                        value             = {userAnswer.code}
                        onValueChange     = {this.setUserAnswer}
                        highlight         = {this.highlightCode}
                        onFocus           = {() => this.setState({editorIsFocused: true})}
                        onBlur            = {() => this.setState({editorIsFocused: false})}
                        paddingLeft       = {56}
                        disabled          = {readOnly}
                    />
                </div>

                {!!sourceFooter
                && <pre className               = {block.el('editor-footer')}
                        dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceFooter, false)}}
                />}

                {!readOnly && <CodeFromFileUploader fillFromFile = {this.fillFromFile}/>}

            </div>}

            { isCodeToShow
            && <div className = {block.el("field-wrapper")}>

                    { answers.length > 1
                    ? <Select  className   = {`${block.el('lang-select')}`}
                               value       = {selectorLanguage}
                               onChange    = {this.onChangeSolutionLanguage}
                               onClick     = {(e) => e.stopPropagation()}
                      >
                            {answers.map((solution) =>
                                    <MenuItem value = {solution.answer.solution.language}
                                              key   = {solution.answer.solution.language}
                                    >
                                        {getLanguagePickerName(solution.answer.solution.language)}
                                    </MenuItem>
                            )}
                      </Select>
                    : <div className = {`${block.el('lang-select')}`}>
                        {getLanguagePickerName(selectorLanguage)}
                      </div>
                    }

                    <div className = {block.el('title')}>Код</div>

                    {!!sourceHeader
                    && <pre className               = {block.el('editor-header')}
                            dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceHeader, false)}}
                    />}

                    <div className = {block.el('editor-wrapper')
                                     + block.el('editor-wrapper').mod('export')}
                    >
                        <Editor
                            textareaClassName = {block.el('editor-textarea')}
                            className         = {block.el('editor')
                                                    +block.el('editor').mod('solution')}
                            value             = {editorCode}
                            onValueChange     = {null}
                            highlight         = {() => this.highlightCode(editorCode)}
                            paddingLeft       = {56}
                            disabled
                        />
                    </div>

                    {!!sourceFooter
                    && <pre className               = {block.el('editor-footer')}
                            dangerouslySetInnerHTML = {{__html: this.highlightCode(sourceFooter, false)}}
                    />}

              </div>}
        </div>);

    }

}

export type {
    HasElementLearn
}
