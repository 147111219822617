import React                  from "react";
import type {CellProps}       from "@sirius/ui-lib/src/blocks/Layout/Table";
import {Cell as TCell, HBox, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import {block}                from "Smt/StandingsPage/block";
import {Button}               from "@sirius/ui-lib/src/blocks/Controls";


type Sort = {
    field: string;
    dir: "asc" | "desc";
}

type MaybeSort = Sort | null;

type HasSort = {
    field?:  string;
    sort?:   MaybeSort;
    onSort?: (dir: string) => void;
};

type SortIcon = {
    up:   string;
    down: string;
};

const SortIcon: React.FC<SortIcon> = ({ up, down }) =>
    <VBox className={ block.el('sort-icon') }>
        <span className={ block.el('sort-icon-up')   }>{up}</span>
        <span className={ block.el('sort-icon-down') }>{down}</span>
    </VBox>
;

const CellSort: React.FC<{field: string; sort: MaybeSort; onSort: (dir: string) => void; } > = ({field, sort, onSort }) =>
    <Button
        size="xs"
        mode="text"
        className={
            block.el('cell-sort') +
            block.el('cell-sort').mod(
                'dir',
                sort === null || !sort?.dir
                    ? 'unset'
                    : sort?.dir
            )
        }
        onAction = {
            () => onSort(
                sort?.field === field
                        ? sort?.dir === "asc"
                            ? "desc"
                            : "asc"
                        : sort?.dir || "desc"
            )
        }
    >
        {
            (sort === null || sort?.field !== field || !sort?.dir )
                ? <SortIcon
                    up   = "△"
                    down = "▽" />
                : (sort?.dir === "asc")
                    ? <SortIcon
                    up   = "▲"
                    down = "▽"
                      />
                    : <SortIcon
                        up   = "△"
                        down = "▼"
                    />
        }
    </Button>
;

type StandingsCellType = CellProps & HasSort;

export const StandingsCell: React.FC<StandingsCellType> = ({className = '', sort, onSort, field, children, ...props}) =>
    <TCell
        {...props}
        className={className + ' ' + block.el('cell')}
    >
        <HBox center className={block.el('cell-label')}>
            {children}
            {
                sort !== void(0)
                    ? <CellSort {...{sort, onSort, field}}/>
                    : null
            }
        </HBox>
    </TCell>
;
