import React,{Fragment}             from "react";
import cloneDeep                    from "lodash/cloneDeep";
import {LGap, MGap, VBox}           from "@sirius/ui-lib/src/blocks/Layout";
import Marked                       from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import MarkedWithVideo              from "Smt/MarkedWithVideo";
import {SmtProgrammingSolution}     from "Smt/SmtProgrammingAnswer/Solution";
import DetailedAnswer               from "Smt/TaskPage/DetailedAnswer";
import BemClassName                 from "Cheops/BemClassName";
import Message                      from "Cheops/components/Thread/Message";
import MatchAnswers                 from "Cheops/components/ModulePassing/Answers/AnswerTypes/MatchAnswers/MatchAnswers";
import ProgrammingAnswer            from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswer";
import {OrderingAnswer}             from "Cheops/components/ModulePassing/Answers/AnswerTypes/OrderingAnswer";
import {ELEMENT_VERDICT}            from "Cheops/constants";
import SelectOnImageAnswer          from "Cheops/components/ModulePassing/Answers/AnswerTypes/SelectOnImageAnswer/SelectOnImageAnswer";
import {CheopsLocaleString}         from "Cheops/CheopsLocaleBlock";
import SelectDropdownAnswer         from "./Answers/AnswerTypes/SelectOptionAnswer/SelectDropdownAnswer";
import                                   "./Solutions.less";

const charWidthPx = 15;

export default class Solutions extends React.Component {

    static defaultProps = {
        course_id: null,
        module_id: null,
        user_id: null,
        element_progress: {},
        element_content: {},
        force_show_right: false,
        isSolutionToShow: false,
        solutionIndex: null,
    };


    constructor(props) {

        super(props);
        this.state = {};

    }

    renderMultipleEnter(solution) {

        const {answersData} = this.props.element_content;
        const {inline} = this.props;

        let answers = [];

        if (solution) {
            solution.map((answer, index) => {
                let widthInPx = 0;
                if (answer === '') {
                    let placeholderWidth = 0;

                    answer = <span className="task_solution__field_placeholder">
                        <CheopsLocaleString k="cheops.solutions.placeholder_text" id="value" />
                    </span>;
                    placeholderWidth = 5 * charWidthPx;

                    if (this.props.element_content.type === "number") {

                        answer = <span className="task_solution__field_placeholder">
                            <CheopsLocaleString k="cheops.solutions.placeholder_number" id="value" />
                        </span>;
                        placeholderWidth = 5 * charWidthPx;

                        if (answersData.isRatioAllowed) {

                            answer = <span className="task_solution__field_placeholder">
                                <CheopsLocaleString k="cheops.solutions.placeholder_ratio" id="value" />
                            </span>;
                            placeholderWidth = 15 * charWidthPx;

                        }

                    }
                     widthInPx = placeholderWidth;
                } else {
                    widthInPx  = answer ? answer.toString().length * charWidthPx : 0;
                }
                const className = new BemClassName("task_solution__field");
                className.appendStatusIf(solution.length === 1 && answersData.inputCount !== -1, 'singleton');
                className.appendStatusIf(answersData.inputCount === 1 || !answersData.inputCount || answersData.inputsInRow === 1, 'singleton');
                className.appendStatusIf(answersData.inputsInRow === 2, 'double');
                className.appendStatusIf(answersData.inputsInRow === 3, 'triple');
                className.appendStatusIf(inline, 'inline');

                const calcWidth   = widthInPx > 715
                                        ? '715px'
                                        : 'fit-content'
                ;
                const widthLimit  =  inline ? { width: calcWidth, maxWidth: '100%', fontSize: '16px' } : {};

                answers.push(
                    <div
                        className={className.toString()}
                        key={index}
                        style={{...widthLimit}}
                    >
                        {answer}
                    </div>,
                );

            });

        }

        return answers;

    }

    renderSelectOnImage(solution, verdict) {

        if (solution === null) {

            solution = [];

        }

        const {task_num} = this.state;

        return <div key={`answer_${task_num}`}>
            <SelectOnImageAnswer
                isSmt={this.props.isSmt}
                isResult
                resultVerdict={verdict}
                answerType={this.props.element_content.type}
                userAnswer={solution}
                answersData={this.props.element_content.answersData}
            />
        </div>;

    }


    renderMatch(solution, verdict) {

        if (solution === null || solution === "") {

            solution = [];

        }

        const answersData = cloneDeep(this.props.element_content.answersData);

        answersData.reorderSolution = false;

        return <MatchAnswers
            isSmt={this.props.isSmt}
            readOnly
            key={`answer_${this.state.task_num}`}
            userAnswer={solution}
            verdict={verdict}
            fieldsAddClass={new BemClassName('task_solution__field', `verdict-${verdict}`)}
            answersData={answersData}
        />;

    }

    renderCheckbox(value, checked, index) {
        const {inline} = this.props;

        const classname = new BemClassName('task_solution__field');
        const hasImg    = inline && value.includes('<img');

        classname.appendStatusIf(checked, 'checkbox-checked');
        classname.appendStatusIf(inline, 'inline');
        classname.appendStatusIf(hasImg, 'inline-img');

        return <div className={classname} key={index}>
            <div className="task_solution__value_text">
                <Marked>{value}</Marked>
            </div>
        </div>;

    }


    getConvertedSolutionToShow() {

        const {element_progress} = this.props;

        return {
            answer: {solution: element_progress.solution.solutionToShow.solution},
            verdict: ELEMENT_VERDICT.OK,
        };

    }


    getCompositeSingleElementContent(answerIndex) {


        const elementContent = cloneDeep(this.props.element_content);

        elementContent.answersData = elementContent.answersData[answerIndex];

        elementContent.type = elementContent.type[answerIndex];

        elementContent.answersData = this.overrideAnswerDataProps(elementContent.answersData);

        return elementContent;

    }

    overrideAnswerDataProps = (answersData) => {

        answersData.isMixed = false;

        if (answersData.columns?.length > 0) {

            answersData.columns.map((column) => {

                column.isMixed = false;

                return column;

            });

        }

        return answersData;

    };


    getCompositeSingleElementProgress(answerIndex) {


        const elementProgress = cloneDeep(this.props.element_progress);

        for (const [index, answer] of elementProgress.solution.answers.entries()) {

            const thisSolution = answer.answer[answerIndex];

            if (thisSolution) {

                answer.answer = thisSolution;

            } else {

                delete elementProgress.solution.answers[index];

            }

        }

        if (elementProgress.solution.solutionToShow) {

            elementProgress.solution.solutionToShow = elementProgress.solution.solutionToShow[answerIndex];

        }

        return elementProgress;

    }

    renderSelectDropdownSolution = (solution, type) => {

        const {
            element_content: {answersData},
            element_progress: {verdict},
            isSmt,
            inline,
            inputMaxWidth,
            force_show_right,
        } = this.props;

        return <SelectDropdownAnswer
            readOnly
            resultVerdict={force_show_right ? 'ok' : verdict}
            userAnswer={solution}
            inline={inline}
            inputMaxWidth={inputMaxWidth}
            multi={type === 'multi'}
            isSmt={isSmt}
            answersData={answersData}
        />;

    }

    render() {

        const {inputIndex, isSmt} = this.props;
        let {answers, solutionToShow} = this.props.element_progress.solution;
        let {verdict} = this.props.element_progress;

        let last_answer;
        let type = '';

        if (this.props.force_show_right) {

            verdict = ELEMENT_VERDICT.OK;
            answers = [this.getConvertedSolutionToShow()];

        }

        let solutions = [];

        if (answers.length) {

            last_answer = answers[0];

        }


        let text = '';

        // eslint-disable-next-line react/prop-types
        const {answersData:{selectType: cSelectType}, type: cType} = this.props.element_content;

        switch (cType) {
            case "none":
                return "";
            case "string":
            case "number":
            case "range":
                type = 'multiple';

                if (last_answer) {

                    solution = last_answer.answer.solution;

                } else {

                    solution = [];
                    let countOfFields = this.props.element_content.answersData.inputCount;

                    if (!countOfFields || countOfFields === -1) {

                        countOfFields = 1;

                    }

                    for (let i = 1; i <= countOfFields; i++) {

                        solution.push("");

                    }

                }

                if (!Array.isArray(solution)) {

                    solution = [solution];

                }

                solutions = this.renderMultipleEnter(solution);

                break;
            case "multi":
            case "single":
                type = 'checkbox';

                solutions = [];
                let user_solution = [];

                if (last_answer) {

                    user_solution = last_answer.answer.solution;

                }

                if (cSelectType === 'select') {
                    const _s =
                        cType === 'multi'
                            ? user_solution
                            : Array.isArray(user_solution)
                                ? user_solution[0]
                                : user_solution
                    ;
                    solutions = this.renderSelectDropdownSolution(_s, cType);
                    break;
                }


                if (cSelectType === 'image') {

                    solutions.push(this.renderSelectOnImage(user_solution, verdict));
                    break;

                }

                this.props.element_content.answersData.answers.map(
                    (answer, index) => solutions.push(
                        this.renderCheckbox(
                            answer.value,
                            (
                                        Array.isArray(user_solution) &&
                                        user_solution.includes(answer.id)
                                    ) || user_solution === answer.id
                            ,
                            index
                        ),
                    )
                );

                break;

            case "match":
                type = 'match';
                let solution = [];

                if (last_answer) {

                    solution = last_answer.answer.solution;

                }

                solutions = this.renderMatch(solution, verdict);
                break;
            case "detailed":
                type = 'text';

                if (!last_answer) {

                    if (this.props.isSmt) {

                        solutions = this.renderMultipleEnter([''], this.props.element_progress.verdict);

                    }
                    break;

                }


                if (this.props.isSmt) {

                    solutions.push(
                        <DetailedAnswer
                            key={`answer_${this.state.task_num}`}
                            taskToken={this.props.taskToken}
                            answersData={this.props.element_content.answersData}
                            resultData={{...last_answer, verdict}}
                            customGetFileInfoAction={this.props.customGetFileInfoAction}
                            contestId={this.props.contestId}
                            showRightAnswer={this.props.force_show_right}
                        />
                    );

                    break;

                }


                for (let [index, answer] of answers.slice().reverse().entries()) {

                    text = <>
                        <Message
                            date         = {new Date(answer.time)}
                            course_id    = {this.props.course_id}
                            module_id    = {this.props.module_id}
                            element_id   = {this.props.element_progress.id}
                            element_type = "task"
                            messageObj   = {answer.answer.solution}
                            user_id      = {this.props.user_id}
                            getFileInfo  = {this.props.getFileInfo}
                        />
                        {!!answer.review
                        && <Message
                            date         = {new Date(answer.reviewTime)}
                            course_id    = {this.props.course_id}
                            module_id    = {this.props.module_id}
                            element_id   = {this.props.element_progress.id}
                            element_type = "task"
                            isReview
                            messageObj   = {answer.review}
                            firstName    = {answer.reviewerInfo.firstName}
                            lastName     = {answer.reviewerInfo.lastName}
                            user_id      = {this.props.user_id}
                            getFileInfo  = {this.props.getFileInfo}
                        />}
                    </>;

                    solutions.push(this.renderMultipleEnter([text], answer.verdict, index));

                }


                break;

            case "programming":
                solutions.push(
                    isSmt
                    ?   <SmtProgrammingSolution
                            key            = {`answer_${this.state.task_num}`}
                            resultData     = {this.props.element_progress}
                            answersData    = {this.props.element_content.answersData}
                            contestId      = {this.props.contestId}
                            forceShowRight = {this.props.force_show_right}
                            solutionToShow = {solutionToShow}
                            isHidden       = {this.props.isHidden}
                        />
                    :   <ProgrammingAnswer
                            key              = {`answer_${this.state.task_num}`}
                            userAnswer       = {this.state.user_answer}
                            elementLearn     = {this.props.element_progress}
                            elementContent   = {this.props.element_content}
                            onAnswer         = {this.props.onAnswer}
                            sendAnswer       = {this.props.sendAnswer}
                            formVisible      = {this.props.formVisible}
                            isSolutionToShow = {this.props.isSolutionToShow}
                            forceShowRight   = {this.props.force_show_right}
                            solutionIndex    = {this.props.solutionIndex}
                            readOnly
                        />
                );
                break;

                case "ordering":
                    type = "ordering";
                    solutions.push(
                        <OrderingAnswer
                            readOnly
                            isSolution
                            isSmt            = {isSmt}
                            key              = {`answer_${this.state.task_num}`}
                            userAnswer       = {last_answer?.answer?.solution || []}
                            elementLearn     = {this.props.element_progress}
                            elementContent   = {this.props.element_content}
                            onAnswer         = {this.props.onAnswer}
                            sendAnswer       = {this.props.sendAnswer}
                            formVisible      = {this.props.formVisible}
                            isSolutionToShow = {this.props.isSolutionToShow}
                            forceShowRight   = {this.props.force_show_right}
                            solutionIndex    = {this.props.solutionIndex}
                        />
                    )
                break;
        }


        if (Array.isArray(this.props.element_content.type)) {

            const types = this.props.element_content.type;

            for (let [answerIndex, inputType] of types.entries()) {

                if (Number.isFinite(inputIndex) && inputIndex !== answerIndex) {

                    continue;

                }

                const elementContent = this.getCompositeSingleElementContent(answerIndex);
                const elementProgress = this.getCompositeSingleElementProgress(answerIndex);

                solutions.push(
                    <Fragment key={`answer_${answerIndex}_${inputType}`}>
                        <VBox >
                            {
                                !Number.isFinite(inputIndex) &&
                                    <MarkedWithVideo className="task_solution__composite_text">
                                        {this.props.element_content.answersData[answerIndex].comment}
                                    </MarkedWithVideo>
                            }
                            <Solutions
                                isSmt                   = {this.props.isSmt}
                                isSolutionToShow        = {this.props.isSolutionToShow}
                                element_progress        = {elementProgress}
                                element_content         = {elementContent}
                                course_id               = {this.props.course_id}
                                module_id               = {this.props.module_id}
                                force_show_right        = {this.props.force_show_right}
                                customGetFileInfoAction = {this.props.customGetFileInfoAction}
                                contestId               = {this.props.contestId}
                                inline                  = {this.props.inline}
                                inputIndex              = {this.props.inputIndex}
                                inputMaxWidth           = {this.props.inputMaxWidth}
                                user_id                 = {this.props.user_id}
                                getFileInfo             = {this.props.getFileInfo}
                                isHidden                = {this.props.isHidden}
                                solutionIndex           = {this.props.solutionIndex}
                            />
                        </VBox>
                        {
                            elementContent.answersData.selectType === "select"
                            && <>
                                <LGap/>
                                <MGap/>
                            </>
                        }

                    </Fragment>
                );
            }

            return <div className="task__composite_wrapper">{solutions}</div>;

        }


        const className = new BemClassName('task_solution', [`type-${type}`, `verdict-${verdict}`]);
        className.appendStatusIf(this.props.inline, 'inline');

        className.appendStatusIf(this.props.isSmt, 'smt');
        className.appendStatusIf(this.props.force_show_right, 'force-show-right');

        return <div className={className}>
            {solutions}
        </div>;

    }

}
