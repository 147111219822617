import React, {FC}        from "react";
import {StringFieldProps} from "../@types";

export const StringFieldENUM: FC<StringFieldProps> = (props) => {
    const {
        data,
        schema,
        block,
        onChange,
        isError = false,
    } = props;

    const {enum:enumList} = schema;
    const enumTitles: Record<string, string> = 'x-data-enum-titles' in schema ? schema['x-data-enum-titles'] : {};

    const onFocusHandler = () => { /*console.log('onFocusHandler')*/ }
    const onBlurHandler  = () => { /*console.log('onBlurHandler')*/ }
    return  <ul className={block.el('enum-list')} role="radiogroup">
                {
                    (enumList as (string | number)[] ).map(
                        (item, ii) =>
                            <li key={ii}
                                className={block.el('enum-item')}
                                role={'radio'}
                                tabIndex={0}
                                onClick={()=>{onChange(item)}}
                                onFocus={onFocusHandler}
                                onBlur={onBlurHandler}
                            >
                                <span
                                    className={
                                        block.el('enum-input-radio') +
                                        block.el('enum-input-radio').mod('checked', data == item ? 'true' : 'false')
                                    }
                                />
                                <span className={block.el('enum-input-label')}>{ enumTitles[item] }</span>
                            </li>
                    )
                }
            </ul>
        ;
}
