import React   from "react";
import Helpers from "Cheops/Helpers";
import bnc     from "bnc";
import              "./ProgrammingAnswerLimits.less"

type ProgrammingAnswerLimitsArg = {
    limits: LabSmt.Limits;
}
const block = new bnc("programming-answer-limits");

const ProgrammingAnswerLimits = ({limits}: ProgrammingAnswerLimitsArg ) => {

    return (
        <div className={`${block}`}>
            <h4 className={block.el('title')}>Ограничения</h4>

            {!!limits.realTime
            && <div>
                Время выполнения:
                {` ${limits.realTime} `}
                {Helpers.formatPlural(limits.realTime, ['секунда', 'секунды', 'секунд'])}
            </div>}

            {!!limits.time
            && <div>
                Процессорное время:
                {` ${limits.time} `}
                {Helpers.formatPlural(limits.time, ['секунда', 'секунды', 'секунд'])}
            </div>}

            {!!limits.maxMem
            && <div>
                Память:
                {` ${ limits.maxMem } MB`}
            </div>}

            {!!limits.stackMem
            && <div>
                Размер стека:
                {` ${limits.stackMem} MB`}
            </div>}

            {!!limits.compilationTime
            && <div>
                Время на компиляцию:
                {` ${limits.compilationTime} `}
                {Helpers.formatPlural(limits.compilationTime, ['секунда', 'секунды', 'секунд'])}
            </div>}

            {!!limits.charactersCount
            && <div>
                Количество символов в исходном коде программы:
                {` ${limits.charactersCount}`}
            </div>}

        </div>
    );

};

export default ProgrammingAnswerLimits;
