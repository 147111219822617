import React from 'react';


interface UIElementProps {

    hint?: string;
    hasUnsavedChanges?: boolean;
    hintVisible?: boolean;

}


export default abstract class UIElement<P = {}, S = {}> extends React.Component<P & UIElementProps, S> {


    static defaultProps = {
        hasUnsavedChanges: false,
    };

}
