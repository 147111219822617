import React, {FC, useEffect, useRef, useState, Ref} from "react";
import {merge}                                       from "rxjs";
import {CircularProgress}                            from "@sirius/ui-lib/src/blocks/CircularProgress";
import {GrowGap, HBox, LGap, MGap, Row, Table, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import {i18nStr}                                     from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {Button}                                      from "@sirius/ui-lib/src/blocks/Controls";
import {useLocale}                                   from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import {windowResize$}                               from "@sirius/ui-lib/src/subjects/WindowResize";
import {windowScroll$}                               from "@sirius/ui-lib/src/subjects/WindowScroll";
import {Pager}                                       from "@sirius/ui-lib/src/blocks/Pager";
import {SmtCache_v4_POST_standings}                  from "Smt/subjects/ContestOnline";
import {SmtLocaleString,  translations}              from "Smt/SmtLocaleBlock";
import {InputLocaleBlock}                            from "Smt/SmtLocaleBlock/InputLocaleBlock";
import {arrOf}                                       from "../arrOf";
import {StandingsCell as Cell}                       from "./StandingsCell";
import {block}                                       from "./block";
import                                                    "./index.less";


const defaultPageSize = 20;

const defaultSort: SmtCacheV4.Sort = {
    field: "score",
    dir:   "desc"
};

const defaultFilter: SmtCacheV4.TableRequestFilter = {
    user: null
};

type HasWidth = {
    width?: number;
};

type StandingsPageProps = {
    token:      string;
    userName?:  string;
    onTimeout:  () => void;
};

type ResultRowProps = {
    user?:          SmtCacheV4.StandingsResponseItem;
    current?:       boolean;
    header?:        boolean;
    prevPlace?:     number;
    tasksCount:     number;
    numtasklabel?:  string;
    onSortField?:   (f: string) => (d: SmtCacheV4.SortDirection) => void;
    sort?:          SmtCacheV4.Sort;
    filter?:        SmtCacheV4.TableRequestFilter;
    onUserFilter?:  (field: string, str: string) => void;
    toUserPage?:    ()=>void;
    TailGapRef?:    Ref<HTMLDivElement>;
    headerPosition?:boolean;
} & HasWidth;

const AbsNoResultWarning = () =>
    <HBox className={block.el('absnoresultwarning')}>
        <SmtLocaleString
            k={"olympiad.task-page-tab.no-result-warning"}
            id={"value"}
            values={{br: <br />}}
        />
    </HBox>
;

const ResultRow: React.FC<ResultRowProps> = ({
    sort,
    onSortField,
    filter,
    onUserFilter,
    current,
    numtasklabel,
    toUserPage,
    prevPlace,
    header,
    tasksCount,
    width,
    TailGapRef,
    headerPosition,
    user: {
        name,
        placeFrom,
        placeTo,
        score,
        tasks,
    } = {}
}) =>
    <Row
        className={
            block.el('result-row') +
            block.el('result-row').bod('current', current) +
            block.el('result-row').bod('header',  header) +
            block.el('result-row').bod('header-position',  headerPosition) +
            block.el('result-row').bod('place-delim', prevPlace && prevPlace !== placeFrom)
        }
        style={{minWidth: width}}
    >
        <Cell
            sticky
            className={block.el('result-place')}
        >
            {
                header
                    ? <SmtLocaleString k={"olympiad.task-page-tab.place"} id={"value"}/>
                    : tasks
                        ? (placeTo > 1 && placeFrom !== placeTo)
                            ? `${placeFrom}-${placeTo}`
                            : placeFrom
                        : "–"

            }
        </Cell>
        <Cell
            sticky
            className={
                block.el('result-name') +
                block.el('result-name').bod('current', current) +
                block.el('result-name').bod('link', !!tasks && !!toUserPage)
            }
            onClick={ tasks ? toUserPage : void(0) }
        >
            {
                header
                    ? <HBox center>
                        {
                            (typeof filter.user !== "string")
                                ? <Button
                                    className={block.el('filter-button')}
                                    icon="filter_alt"
                                    iconPlace="end"
                                    size="xs"
                                    mode="text"
                                    onAction={() => onUserFilter('user', '')}
                                >
                                    <SmtLocaleString k={"olympiad.task-page-tab.participant"} id={"value"}/>
                                </Button>
                                :
                                <>
                                    <InputLocaleBlock
                                        k={"olympiad.task-page-tab.participant"}
                                        inputClass={block.el('filter-input')}
                                        defaultValue={filter.user}
                                        onChange={
                                            ({currentTarget: {value}}: React.FormEvent<HTMLInputElement>) =>
                                                onUserFilter('user', value.trim() )
                                        }
                                    />
                                    <MGap/>
                                    <Button
                                        className={block.el('filter-clear')}
                                        icon="clear"
                                        size="xs"
                                        mode="text"
                                        onAction={() => onUserFilter('user', null)}
                                    />
                                </>
                        }
                    </HBox>
                    : name
            }
        </Cell>
        {
            header && 1 === 1 + 1 &&
            <Cell className={ block.el('scroll-button') }>
                <Button form={"rounded"} size={"s"}>{"›"}</Button> {/*‹*/}
            </Cell>
        }
        <Cell
            sticky
            center
            className = {
                block.el('result-col-scroll-left') +
                block.el('result-col-scroll-left').bod('hide', !tasks && !header)
            }
        >
        </Cell>
        <Cell
            sort   = { header ?  sort : void(0) }
            onSort = { onSortField && onSortField( 'score') }
            field  = 'score'
            sticky
            data-numtasklabel={numtasklabel}
            className={
                block.el('result-total') +
                block.el('result-total').bod( 'no-results', !header && !tasks )
            }
        >
            {
                header
                    ? <SmtLocaleString k={"olympiad.task-page-tab.amount"} id={"value"}/>
                    : tasks
                    ? score
                    : <AbsNoResultWarning/>
            }
        </Cell>
        {
            arrOf(tasksCount).map(
                (ii) =>
                    <Cell
                        key       = { ii }
                        className = { block.el('result-score') }
                        sort      = { header ?  sort : void(0) }
                        onSort    = { onSortField && onSortField( `${ii + 1}`) }
                        field     = {`${ii + 1}`}
                    >
                        {
                            header
                                ? `${ii + 1}`
                                : tasks
                                    ? tasks[`${ii + 1}`]
                                    : ''
                        }
                    </Cell>
            )
        }
        <GrowGap divRef={TailGapRef}/>
        <Cell
            center
            sticky
            className={
                block.el('result-col-scroll-right') +
                block.el('result-col-scroll-right').bod('hide', !tasks && !header)
            }
        />
    </Row>
;

const RowHeader: FC<{k: string; width: number}> = ({ k , width}) =>
    <Row style={{minWidth: width}} className={ block.el('result-row') }>
        <Cell sticky >
            <h4 className={block.el('header')}>
                <SmtLocaleString k={k} id={"value"}/>
            </h4>
        </Cell>
        <GrowGap/>
    </Row>
;

const modScrollLeft  = block.el('table').mod('scroll', 'left').trim();
const modScrollRight = block.el('table').mod('scroll', 'right').trim();
const modScrollBoth  = block.el('table').mod('scroll', 'both').trim();

const NoResult = () =>
    <HBox center className={block.el('noresult')}>
        <div className={block.el('fixed')}>
            <SmtLocaleString k={"olympiad.task-page-tab.no-result"} id={"value"} values={{br: <br />}}/>
        </div>
    </HBox>
;
const NoFilteredResult = () =>
    <HBox center className={block.el('noresult')}>
        <div className={block.el('fixed')}>
            <SmtLocaleString k={"olympiad.task-page-tab.no-filtered-result"} id={"value"} values={{br: <br />}}/>
        </div>
    </HBox>
;

type UpdateButtonType = {
    loading:  boolean;
    onUpdate: ()=>void;
};

const UpdateButton: React.FC<UpdateButtonType> = ({loading, onUpdate}) =>
    <>
        <HBox className={block.el('updatebutton')}>
            <Button
                icon      = "refresh"
                mode      = "primary"
                size      = "s"
                loading   = {loading}
                onAction  = {onUpdate}
            >
                <SmtLocaleString k={"olympiad.task-page-tab.refresh"} id={"value"} values={{br: <br />}}/>
            </Button>
        </HBox>
        <LGap/>
    </>
;

const createUserNoTasks = (name: string): SmtCacheV4.StandingsResponseItem => ({
    name,
    placeTo: 0,
    placeFrom: 0,
})

const StandingsPage: React.FC<StandingsPageProps> = ({token, userName, onTimeout}) => {
    const [update,      setUpdate]      = useState<number>(0);
    const [scrollWidth, setScrollWidth] = useState<number>(0);
    const [loading,     setLoading]     = useState<boolean>(false);
    const [askUserPage, setAskUserPage] = useState<boolean>(false);
    const [user,        setUser]        = useState<SmtCacheV4.StandingsResponseItem>();
    const [size,        setSize ]       = useState<number>(defaultPageSize);
    const [page,        setPage]        = useState<number>(0);
    const [total,       setTotal]       = useState<number>(0);
    const [tasksCount,  setTasksCount]  = useState<number>(0);
    const [filter,      setFilter]      = useState<SmtCacheV4.TableRequestFilter>(defaultFilter);
    const [sort,        setSort]        = useState<SmtCacheV4.Sort>(defaultSort);
    const [standings,   setStandings]   = useState<SmtCacheV4.StandingsResponseItemList>([]);
    const TableRef                      = useRef<HTMLDivElement>(null);
    const TailGapRef                    = useRef<HTMLDivElement>(null);
    const locale                        = useLocale();

    const sizeScrollHandler = () => {
        const table = TableRef.current;
        const tail  = TailGapRef.current;
        if (table) {
            const {scrollLeft, scrollWidth, offsetWidth, classList} = table;
            const {scrollWidth: tailW} = tail;
            const mod = scrollLeft === 0
                        ? scrollWidth > offsetWidth
                            ? modScrollLeft
                            : ''
                        : scrollWidth - scrollLeft === offsetWidth
                            ? modScrollRight
                            : modScrollBoth
            ;
            classList.remove(modScrollLeft);
            classList.remove(modScrollRight);
            classList.remove(modScrollBoth);
            mod && classList.add(mod);
            setScrollWidth(scrollWidth - tailW);
            //console.log({scrollWidth,offsetWidth})
        }
    };

    useEffect(
        () => {
            const sub = merge(windowScroll$, windowResize$).subscribe(
                sizeScrollHandler
            );
            return () => {
                sub.unsubscribe();
            }
        },
        ['']
    );
    useEffect(
        sizeScrollHandler,
        [TableRef?.current, TableRef]
    );

    useEffect(
        () => {
            if (!loading) {
                setLoading(true);

                const payload: SmtCacheV4.StandingsRequest = askUserPage
                        ? { sessionId: user.id }
                        : { page, size, sort, filter: filter.user ? filter : void(0) }
                ;

                SmtCache_v4_POST_standings( token, payload )
                    .then(
                        ({data}) => {
                            const { data: standings, tasksCount, user, pager:{total, page}/*, filter, sort*/ } = data || {};
                            setTasksCount(tasksCount);
                            setStandings(standings);
                            setTotal(total);
                            setUser(user || createUserNoTasks(userName) );
                            if (askUserPage) {
                                setPage(page);
                                setSize(size);
                            }
                        }
                    )
                    .catch(
                        (err) => {
                            if (err.response.status === 401) {
                                onTimeout();
                            }
                    })
                    .finally(
                        ()=>{
                            setAskUserPage(false);
                            setLoading(false);
                        }
                    )
            }
        },
        [page, size, sort && sort?.field, sort && sort?.dir, update, filter.user, askUserPage]
    );
    const onPageChange = (p: number): void => { setPage(p); }
    const onSortField = (field: SmtCacheV4.StandingSort) => (dir: SmtCacheV4.SortDirection): void => {
        if (!loading) {
            setSort({field, dir});
        }
    }
    const onUserFilter = (field: string, str: string | null) => {
        setFilter({[field]: str});
        setPage(0);
    };
    const numtasklabel = i18nStr({ tpl: translations["olympiad"]["task-page-tab"]["numtasklabel"] , id: "value", locale});

    const toUserPage = (): void => {
        setFilter({user: null});
        setAskUserPage(true);
    };

    return <VBox className={block}>
            { loading && <CircularProgress centerOfWindow/> }
            {
                (!!user || standings.length) &&
                <VBox className={block.el('panel')}>
                    <UpdateButton
                        loading  = {loading}
                        onUpdate = {()=>setUpdate(update + 1)}
                    />
                    {
                       <Table className={block.el('table') + modScrollLeft} divRef={TableRef}>
                            { user && <RowHeader k="olympiad.task-page-tab.your-position" width={scrollWidth}/> }
                            {
                                user &&
                                <ResultRow
                                    width      = {scrollWidth}
                                    current {...{user, tasksCount, toUserPage}}
                                    headerPosition
                                />
                            }
                            <RowHeader k="olympiad.task-page-tab.all-participants" width={scrollWidth}/>
                            <ResultRow
                                width        = {scrollWidth}
                                sort         = {sort}
                                filter       = {filter}
                                tasksCount   = {tasksCount}
                                numtasklabel = {numtasklabel}
                                header
                                TailGapRef   = {TailGapRef}
                                onSortField  = {onSortField}
                                onUserFilter = {onUserFilter}
                            />
                            {
                                total > 0 && standings.length > 0
                                    ? standings.map(
                                        (item, i) =>
                                            <ResultRow
                                                width      = {scrollWidth}
                                                current    = {user && user.id === item.id}
                                                prevPlace  = { i > 0 ? standings[i - 1]?.placeFrom : void(0) }
                                                user       = {item}
                                                tasksCount = {tasksCount}
                                                key        = {i}
                                            />
                                    )
                                    : filter && filter?.user
                                        ? <NoFilteredResult/>
                                        : <NoResult/>
                            }
                        </Table>
                    }
                {
                    total > size && standings.length > 0 &&
                    <Pager {...{page, size, total, onPageChange}} />
                }
                </VBox>
            }
        </VBox>
    ;
};

export {StandingsPage};
