/* eslint-disable */
import BemClassName from "Cheops/BemClassName";
import Helpers from "Cheops/Helpers";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import DetailedAnswerMessage from "Cheops/components/Section/DetailedAnswerMessage";
import React from "react";
import * as auth_actions from "Cheops/actions/auth";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import isEqual from "lodash/isEqual";

export default class DetailedAnswer extends React.Component {

    static defaultProps = {
        current_element_learn_thread: null,
        allow_file_upload: true,
        answers: {
            message: "",
            attachments: []
        },
        onAnswer: () => {
        },
        element_learn: {
            solution: {},
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            messages_log: [],
            log_expanded: false,
            message_form_type: null,
            attached_files: [],
            uploading_files: [],
            uploaded_files: [],
            in_pending: false,
            is_loading: true,
        };

        this.messages_to_show = 3;
        this.solution_counter = 0;
    }

    componentWillMount() {
        this.getMessages();
    }


    componentDidUpdate(prevProps) {
        const {element_learn, current_element_learn_thread} = this.props;

        if (!isEqual(element_learn, prevProps.element_learn) || !isEqual(current_element_learn_thread, prevProps.current_element_learn_thread)) {

            this.getMessages();

        }

    }


    getIsAnswerFilled = () => true;
    getAnswerHasError = () => false;


    getMessages = async () => {
        this.setState({is_loading: true});

        let uploaded_files = this.state.uploaded_files;

        let messages_log = [];

        if (this.props.current_element_learn_thread && this.props.current_element_learn_thread.comments) {
            for (let comment of this.props.current_element_learn_thread.comments) {
                let message_type = "question";

                if (comment.isTutor) {
                    message_type = "answer";
                }

                let attached_images = [];

                if (comment.attachments) {

                    attached_images = await this.getAttachedImages(comment.attachments, true);
                }

                let middleName = "";

                if (comment.author.middleName && message_type === "answer") {
                    middleName = comment.author.middleName;
                }

                messages_log.push({
                    type: message_type,
                    text: comment.message,
                    date: comment.time,
                    imgs: attached_images,
                    whoSet: {
                        firstName: comment.author.name,
                        lastName: comment.author.surname,
                        middleName
                    }
                })
            }
        }

        if (this.props.element_learn.solution && this.props.element_learn.solution.answers) {
            for (let answer of this.props.element_learn.solution.answers) {

                let solution_message = answer.answer.solution.message;
                let solution_attach = null;

                if (this.props.answer_type === "detailed" && answer.answer.solution.attachments) {
                    solution_attach = answer.answer.solution.attachments;
                }

                let attached_images = [];

                if (solution_attach) {
                    attached_images = await this.getAttachedImages(solution_attach);
                }

                this.solution_counter++;

                let solution_obj = {
                    type: "solution",
                    text: solution_message,
                    date: answer.time,
                    imgs: attached_images,
                    score: answer.score,
                    whoSet: {
                        firstName: auth_actions.getUserInfo('firstName'),
                        lastName: auth_actions.getUserInfo('lastName'),
                        middleName: "",
                    }
                };

                if (answer.wasReviewed) {

                    let attached_images = [];

                    if (answer.review?.attachments) {
                        attached_images = await this.getAttachedImages(answer.review.attachments);
                    }

                    let review_message = null;
                    let whoSet = null;

                    if (answer.review?.message) {
                        review_message = answer.review.message;

                        let middleName = "";

                        if (answer.reviewAuthor.middleName) {
                            middleName = answer.reviewAuthor.middleName;
                        }

                        whoSet = {
                            firstName: answer.reviewAuthor.name,
                            lastName: answer.reviewAuthor.surname,
                            middleName,
                        };

                    }

                    solution_obj.comments = {
                        type: "answer",
                        text: review_message,
                        imgs: attached_images,
                        date: answer.reviewTime,
                        whoSet,
                    }
                }

                messages_log.push(solution_obj)
            }
        }

        messages_log.sort((a, b) => (new Date(a.date) - new Date(b.date)));


        this.setState({messages_log, uploaded_files, is_loading: false})
    };

    getAttachedImages = async (attachments, is_thread) => {

        let images_objs = [];

        for (let file_hash of attachments) {

            let file_info_success;
            let file_info = null;

            if (is_thread) {
                file_info_success = (await noo_actions.getFileInfoThread(this.props.course_id, this.props.module_id, "task", this.props.element_learn.id, file_hash)).success;

                if (file_info_success) {
                    file_info = file_info_success.info
                }

            } else {
                file_info_success = (await noo_actions.getFileInfo(this.props.course_id, this.props.module_id, this.props.element_learn.id, file_hash)).success;

                if (file_info_success) {
                    file_info = file_info_success.info
                }
            }

            if (!file_info) {
                continue;
            }

            let preview_url = null;
            let download_url = CONFIG.Api.storage.url + "/download/" + file_info.token;

            let file_ext = "file";
            let file_name = file_info.name;

            if (file_info.preview) {
                preview_url = CONFIG.Api.storage.url + "/download/" + file_info.preview;
            } else {
                file_ext = "";

                if (file_name.includes(".")) {
                    file_ext = file_info.name.split(".").pop();
                    if (file_ext.length > 4) {
                        file_ext = "";
                    }
                }
            }

            images_objs.push({
                preview_url,
                download_url,
                file_ext,
                file_name
            })
        }

        return images_objs;
    };

    textAreaChange = value => {
        let answers = this.props.answers;
        answers.message = value;
        this.props.onAnswer(answers);
    };


    addFile = async (input) => {

        let files = input.files;
        let uploading_files = this.state.uploading_files;

        uploading_files.push(files[0].name);

        this.setState({uploading_files});

        let upload_token = {};

        if (this.state.message_form_type === "question") {
            upload_token = (await noo_actions.uploadTokenThread(this.props.course_id, this.props.module_id, "task", this.props.element_learn.id)).success;
        }

        if (this.state.message_form_type === "solution") {
            upload_token = (await noo_actions.uploadToken(this.props.course_id, this.props.module_id, this.props.element_learn.id)).success;
        }

        let {token} = upload_token;

        let formData = new FormData();

        formData.append('token', token);
        formData.append('file', files[0]);


        try {
            let upload_new_file = await noo_actions.uploadFile(formData);

            if (upload_new_file.success) {
                this.storeImage(upload_new_file, uploading_files.length - 1)
            }

            if (upload_new_file.error) {
                let message = "";

                for (let error of upload_new_file.error) {
                    message += error.message + "\n";
                    let uploading_files = this.state.uploading_files;
                    uploading_files.splice(uploading_files.indexOf(files[0].name), 1);
                    this.setState({uploading_files, error_message: message});
                }
            }
        } catch (e) {
            let message = "Невозможно загрузить файл, попробуйте уменьшить его размер";
            let uploading_files = this.state.uploading_files;
            uploading_files.splice(uploading_files.indexOf(files[0].name), 1);
            this.setState({uploading_files, error_message: message});
        }

        input.value = '';
    };


    storeImage = (img_data, index) => {
        let uploading_files = this.state.uploading_files;
        let uploaded_files = this.state.uploaded_files;
        let answers = this.props.answers;

        uploading_files.splice(index, 1);
        answers.attachments.push(img_data.success.hash);
        uploaded_files[img_data.success.hash] = img_data.success;

        this.props.onAnswer(answers);
        this.setState({uploading_files, uploaded_files});
    };


    sendMessage = async () => {

        if (Helpers.isParentMode()) {
            return;
        }

        let comment_to_send = {
            message: this.props.answers.message,
            attachments: this.props.answers.attachments,
        };

        if (this.state.message_form_type === "question") {

            await this.props.sendComment("task", comment_to_send);
        }


        if (this.state.message_form_type === "solution") {
            await this.props.sendAnswer();
        }

        this.props.onAnswer({message: "", attachments: []});

        this.setState({
            message_form_type: null,
        });
    };


    deleteImage = (index) => {

        let answers = this.props.answers;

        answers.attachments.splice(index, 1);

        this.props.onAnswer(answers);
    };

    openForm = (message_form_type) => {

        if (Helpers.isParentMode()) {
            return;
        }

        this.setState({message_form_type})
    };

    render() {
        if (this.state.is_loading) {
            return (
                <div className="detailed_answer__content">
                    <CircularProgress marginCenter />
                </div>
            );
        }

        let task_page_class = new BemClassName("detailed_answer");

        let message_expander_number = this.state.messages_log.length - this.messages_to_show;

        if (this.props.element_learn.solutionToShow || this.props.is_locked) {
            message_expander_number = this.state.messages_log.length;
        }

        let task_scores_class = new BemClassName("detailed_answer__task_scores");

        if (this.props.element_learn.reviewStatus === "reviewed") {
            task_scores_class.appendStatus("graduated")
        }

        task_scores_class.appendStatusIf(this.props.element_learn.reviewStatus === "reviewed", "graduated");

        let message_placeholder = "";

        let submit_button_text = <span>Отправить</span>;

        if (this.state.message_form_type === "solution") {
            message_placeholder = "Запишите решение...";
            submit_button_text =
                <span>Отправить<span className="detailed_answer__message_form_submit_review"> на проверку</span></span>;

            if (this.props.answers.message) {
                message_placeholder = "Решение " + (this.solution_counter + 1);
            }
        }

        if (this.state.message_form_type === "question") {
            message_placeholder = "Запишите вопрос...";

            if (this.props.answers.message) {
                message_placeholder = "Вопрос";
            }
        }

        if (this.props.is_locked) {
            task_page_class.appendStatus("closed");
        }

        let message_text_placeholder = "Запишите решение...";

        if (this.state.message_form_type === "question") {
            message_text_placeholder = "Запишите вопрос...";
        }

        let sending_disabled = true;

        if ((this.props.answers.message.trim() || this.props.answers.attachments.length > 0) && !Helpers.isParentMode()) {
            sending_disabled = false;
        }

        let send_solution_button_show = true;
        let send_solution_button_disable = false;

        if (Helpers.isParentMode()) {
            send_solution_button_disable = true;
        }

        if (this.props.element_learn.isClosed) {
            send_solution_button_show = false;
        }

        let set_solution_number = "решение ";

        if (this.solution_counter > 0 && !this.props.element_learn.isClosed) {
            set_solution_number += (this.solution_counter + 1);
        }

        let detailed_answer__interaction_class = new BemClassName("detailed_answer__interaction");

        detailed_answer__interaction_class.appendStatusIf(this.state.message_form_type !== null, "opened");

        let solution_counter = 0;

        let send_solution_button_class = new BemClassName('detailed_answer__send_solution_button');

        send_solution_button_class.appendStatusIf(!this.props.element_content.hasComments, "full-width");


        let ask_button_class = new BemClassName('detailed_answer__ask_button');

        ask_button_class.appendStatusIf(this.props.is_locked, 'full_width');

        return (
            <div className="detailed_answer__content">

                {this.props.element_learn.solutionToShow &&
                <div className="detailed_answer__right_solution">
                    <div className="detailed_answer__solution_title">Решение</div>
                    <div className="detailed_answer__solution_text">
                        <Marked>{this.props.element_learn.solutionToShow.message}</Marked>
                    </div>
                </div>
                }

                {this.state.messages_log.length > 0 &&
                <div className="detailed_answer__conversation">
                    {((this.state.messages_log.length > this.messages_to_show) || this.props.element_learn.solutionToShow || this.props.is_locked) &&
                    !this.state.log_expanded &&
                    <div className="detailed_answer__messages_expander"
                         onClick={() => this.setState({log_expanded: true})}>
                        {message_expander_number} {Helpers.formatPlural(message_expander_number, ["сообщение", "сообщения", "сообщений"])}
                    </div>
                    }

                    {this.state.messages_log.map((message, i) => {

                        let message_class = new BemClassName("detailed_answer_message");

                        message_class.appendStatusIf((message.type === "solution" && !message.comments), "pending");

                        if (message.type === "solution" && message.comments) {

                            if (message.score < this.props.element_learn.max && message.score > 0) {
                                message_class.appendStatus("graduated");
                                message_class.appendStatus("graduated_" + Math.ceil((message.score / (this.props.element_learn.max - 1)) * 20));  // 20 is max index in the less
                            }

                            if (message.score === 0) {
                                message_class.appendStatus("graduated_fail");
                            }

                            message_class.appendStatusIf(message.score === this.props.element_learn.max, "graduated_full");
                        }

                        if (message.type === "solution") {
                            solution_counter++
                        }

                        if ((i >= (this.state.messages_log.length - this.messages_to_show) && !this.props.element_learn.solutionToShow && !this.props.is_locked)
                            || this.state.log_expanded) {

                            return <div className={message_class} key={i}>
                                <DetailedAnswerMessage message={message}
                                                       answer_type={this.props.answer_type}
                                                       element_learn={this.props.element_learn}
                                                       course_id={this.props.course_id}
                                                       module_id={this.props.module_id}
                                                       solution_counter={solution_counter} />
                                {message.comments && message.comments.whoSet &&
                                <div className="detailed_answer_message__comment">
                                    <DetailedAnswerMessage message={message.comments}
                                                           answer_type={this.props.answer_type}
                                                           element_learn={this.props.element_learn}
                                                           course_id={this.props.course_id}
                                                           module_id={this.props.module_id}
                                                           solution_counter={solution_counter} />
                                </div>
                                }
                            </div>
                        }
                    })}
                </div>
                }

                {this.state.message_form_type &&
                <>
                    <form className="detailed_answer__message_form"
                          onSubmit={this.sendMessage}>

                        <div className="detailed_answer__message_form_header">
                            <div className="detailed_answer__message_form_title">{message_placeholder}</div>
                        </div>

                        <textarea onChange={e => this.textAreaChange(e.target.value)}
                                  placeholder={message_text_placeholder}
                                  value={this.props.answers.message} />
                        {this.props.allow_file_upload &&

                        <div className="detailed_answer__message_form_footer">
                            <div>
                                {this.props.answers.attachments.map((file_hash, ii) => {

                                    if (!this.state.uploaded_files[file_hash]) {
                                        return;
                                    }

                                    let file_info = this.state.uploaded_files[file_hash].info;

                                    let preview_url = null;

                                    let download_url = null;

                                    if (file_info) {
                                        download_url = CONFIG.Api.storage.url + "/download/" + file_info.token;
                                    }

                                    let file_ext = "file";

                                    if (file_info && file_info.preview) {
                                        preview_url = CONFIG.Api.storage.url + "/download/" + file_info.preview;
                                    } else {
                                        file_ext = "";

                                        let file_name = "";
                                        if (file_info) {
                                            file_name = file_info.name;
                                        }

                                        if (file_name.includes(".")) {
                                            file_ext = file_info.name.split(".").pop();
                                            if (file_ext.length > 4) {
                                                file_ext = "";
                                            }
                                        }
                                    }

                                    let download = "";

                                    if (file_info) {
                                        download = file_info.name;
                                    }

                                    return <a key={ii}
                                              className="detailed_answer__message_image"
                                              download={download}
                                              href={download_url}
                                              target="_blank">
                                        {preview_url && <img alt="" src={preview_url} />}
                                        {!preview_url &&
                                        <div className="detailed_answer__message_file_ext">...{file_ext}</div>
                                        }
                                        <div className="detailed_answer__message_image_close"
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 this.deleteImage(ii);
                                             }}>
                                        </div>
                                    </a>
                                })}

                                {this.state.uploading_files.map((file, i) => {

                                    return (
                                        <div key={i} className="detailed_answer__message_image ">
                                            <CircularProgress marginCenter />
                                        </div>
                                    );
                                })}
                            </div>
                            {!!this.state.error_message &&
                            <div className="detailed_answer__error">
                                {this.state.error_message}
                                <div className="detailed_answer__error_close"
                                     onClick={() => this.setState({error_message: null})} />
                            </div>
                            }
                            <input type="file"
                                   className="detailed_answer__message_form_add_file"
                                   onChange={e => this.addFile(e.target)} />
                            <span className="detailed_answer__message_form_add_file_label">
                                <img src={require("Cheops/../img/clip.svg")} />
                            </span>
                        </div>
                        }
                    </form>
                </>
                }

                {!this.props.element_learn.solutionToShow &&
                <div className={detailed_answer__interaction_class}>
                    {this.state.message_form_type === null && !this.props.element_learn.isAchieved &&

                    // (!this.props.element_learn.solution ||
                    //     !this.props.element_learn.solution.answers ||
                    //     (this.props.element_learn.solution &&
                    //         this.props.element_learn.solution.answers &&
                    //         this.props.element_learn.solution.answers[0].review)) &&
                    <>
                        {(!this.props.is_locked) && send_solution_button_show &&
                        <button className={send_solution_button_class}
                                disabled={send_solution_button_disable}
                                onClick={() => this.openForm("solution")}>
                                <span
                                    className="detailed_answer__send_solution_button_send">Отправить </span>
                            <span>{set_solution_number}</span>
                        </button>
                        }
                        {this.props.element_content.hasComments && this.props.current_element_learn_thread.status === 'opened' &&
                        <button className={ask_button_class}
                                disabled={Helpers.isParentMode()}
                                onClick={() => this.openForm("question")}>
                            <img src={require("Cheops/../img/clouds.svg")} /><span> Задать вопрос</span>
                        </button>
                        }
                    </>
                    }

                    {/*{this.state.message_form_type === null &&*/}
                    {/*this.props.element_learn.solution !== null &&*/}
                    {/*this.props.element_learn.solution.answers &&*/}
                    {/*!this.props.element_learn.solution.answers[0].review &&*/}
                    {/*<div className="detailed_answer__one_more_solution"*/}
                    {/*onClick={() => this.setState({message_form_type: "solution"})}>*/}
                    {/*Отправить ещё одно решение*/}
                    {/*</div>*/}
                    {/*}*/}

                    {this.state.message_form_type === null && this.props.element_learn.isAchieved && this.props.current_element_learn_thread.status === 'opened' &&
                    <button className="detailed_answer__messages_expander"
                            disabled={Helpers.isParentMode()}
                            onClick={() => this.setState({message_form_type: "question"})}>
                        Задать вопрос
                    </button>
                    }

                    {this.state.message_form_type &&
                    <div className="detailed_answer__message_form_actions">
                        <button className="detailed_answer__message_form_cancel"
                                onClick={() => {
                                    this.setState({message_form_type: null});
                                    this.props.onAnswer({message: "", attachments: []});
                                }}>Отмена
                        </button>
                        <button className="detailed_answer__message_form_submit"
                                onClick={this.sendMessage} disabled={sending_disabled}>
                            {submit_button_text}
                        </button>
                    </div>
                    }
                </div>
                }

            </div>
        );
    }
}
