import React                                                     from "react";
import ReactDOM                                                  from "react-dom";
import Answers                                                   from "Cheops/components/ModulePassing/Answers/Answers";
import Solutions                                                 from "Cheops/components/ModulePassing/Solutions";
import Marked                                                    from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {getVideoResolveContainer as getVideoResolveContainerNoo} from "Cheops/actions/noopolis-ts";
import {getVideoResolveContainer as getVideoResolveContainerSmt} from "Smt/actions/webPortal";
import {TaskAnswerType}                                          from "Smt/Types/Answer";
import type {TGetVideoResolveContainer}                          from "Cheops/actions/noopolis-ts";
import type {ValidationReportType}                               from "Smt/TaskPage/Task/ClientValidation";
import                                                              "./MarkedWithAnswers.less";

type TInstance = "noo" | "smt" ;

type State = {
    initRendered: boolean;
    instance: string;
};

type Props = {
    inline?: boolean;
    renderAnswers: boolean;
    showResults: boolean;
    isSmt?: boolean;
    forceShowRight?: boolean;
    isSolutionToShow?: boolean;
    taskNum?: number;
    // TODO types of answers & userAnswer
    answersData?: any[];
    userAnswer?: any;
    answerType?: TaskAnswerType[];
    element_content: {
        answersData: any[];
        description: string;
        type: TaskAnswerType[];
    };
    taskToken?: string;
    // TODO types of element_progress
    element_progress: any;
    className?: string;
    courseId?: string;
    moduleId?: string;
    allMustBeFilled?: boolean;
    elementLearn?: any; // TODO
    currentElementLearnThread?: any; // TODO
    isLocked?: boolean;
    solutionIsNotEditable?: boolean;
    formVisible?: boolean;
    hideExpandSolutionPanel?: boolean;
    readOnly?: boolean;

    validationReport?: ValidationReportType,

    onAnswer?(answer: any, done: () => void): void;
    getFractionLatex?(value: string): Promise<any>;
    onAnswerIsFilledChange?(value: boolean, hasErrors: boolean): void;
    onClick?(): void;
    sendComment?(a: string, b: any): void;
    sendAnswer?(): void;
    openAnswerForm?(): void;
    closeAnswerForm?(): void;
};

export default class MarkedWithAnswers extends React.Component<Props, State> {

    static defaultProps = {
        inline: false,
        renderAnswers: false,
        readOnly: false,
    };

    state: Readonly<State> = {
        initRendered: false,
        instance: null,
    };

    componentDidMount(): void {
        const instance = CONFIG?.Instance ? `${CONFIG.Instance}` : null;
        this.setState({ instance }, this.waitForMarked );
    }


    waitForMarked = () => {
        const hasRendered = !!this.markedRef.current && !!this.markedRef.current.mathJaxRef.current;
        if (hasRendered) {
            this.setState({ initRendered: true });
        } else {
            setTimeout( this.waitForMarked, 100 )
        }
    }

    markedRef = React.createRef<Marked>();
    filledAnswerIndexes: number[] = [];
    answerWithErrorsIndexes: number[] = [];

    getVideoResolveContainer = (instance: string): TGetVideoResolveContainer => {
        const instanceLowerCase = instance?.toLowerCase() as TInstance;
        const getVideoResolveContainer = {
            noo: getVideoResolveContainerNoo,
            smt: getVideoResolveContainerSmt
        }

        return getVideoResolveContainer[instanceLowerCase] ?? void(0)
    }

    // TODO is horrible pin

    onInlineCompositeAnswerIsFilledChange = (answerIndex: number, value: boolean, hasErrors: boolean): void => {

        const {onAnswerIsFilledChange, allMustBeFilled, answerType} = this.props;

        const oldFilledLength = this.filledAnswerIndexes.length;
        const oldErrorsLength = this.answerWithErrorsIndexes.length;

        const filledIndex = this.filledAnswerIndexes.indexOf(answerIndex);
        const errorIndex = this.answerWithErrorsIndexes.indexOf(answerIndex);

        if (filledIndex >= 0 && !value) {

            this.filledAnswerIndexes.splice(filledIndex, 1);

        }

        if (filledIndex === -1 && value) {

            this.filledAnswerIndexes.push(answerIndex);

        }

        if (errorIndex >= 0 && !hasErrors) {

            this.answerWithErrorsIndexes.splice(errorIndex, 1);

        }

        if (errorIndex === -1 && hasErrors) {

            this.answerWithErrorsIndexes.push(answerIndex);

        }


        if (
            oldFilledLength !== this.filledAnswerIndexes.length
            || oldErrorsLength !== this.answerWithErrorsIndexes.length
        ) {

            let isFilled = this.filledAnswerIndexes.length > 0;

            if (allMustBeFilled) {

                isFilled = this.filledAnswerIndexes.length === answerType.length;

            }

            onAnswerIsFilledChange(
                isFilled,
                this.answerWithErrorsIndexes.length > 0,
            );

        }

    };

    renderAnswers(): React.ReactNode {

        const {
            showResults,
            isSmt,
            forceShowRight,
            isSolutionToShow,
            taskNum,
            answersData,
            userAnswer,
            answerType,
            onAnswer,
            getFractionLatex,
            onAnswerIsFilledChange,
            element_content: elementContent,
            taskToken,
            element_progress: elementProgress,
            elementLearn,
            moduleId,
            courseId,
            allMustBeFilled,
            closeAnswerForm,
            currentElementLearnThread,
            formVisible,
            hideExpandSolutionPanel,
            isLocked,
            openAnswerForm,
            sendAnswer,
            sendComment,
            solutionIsNotEditable,
            readOnly,
            validationReport
        } = this.props;

        const {initRendered} = this.state;



        const inlineAnswers = initRendered
                ? this.markedRef?.current?.mathJaxRef?.current?.preview.querySelectorAll('.inline_answers') as unknown as HTMLSpanElement[]
                : []
        ;

        const answers = [];

        for (const inlineAnswerContainer of Array.from(inlineAnswers)) {

            const isInlineInput = inlineAnswerContainer.classList.contains('inline_answers--inline');

            if (!showResults) {

                answers.push(ReactDOM.createPortal(<Answers
                    readOnly={readOnly}
                    inputIndex={parseInt(inlineAnswerContainer.dataset.index) - 1}
                    inputMaxWidth={parseInt(inlineAnswerContainer.dataset.maxwidth)}
                    isSmt={isSmt}
                    answerType={answerType}
                    taskNum={taskNum}
                    answersData={answersData}
                    userAnswer={userAnswer}
                    onAnswer={onAnswer}
                    getFractionLatex={getFractionLatex}
                    onAnswerIsFilledChange={onAnswerIsFilledChange}
                    inline={isInlineInput}
                    elementContent={elementContent}
                    elementLearn={elementLearn}
                    taskToken={taskToken}
                    allMustBeFilled={allMustBeFilled}
                    closeAnswerForm={closeAnswerForm}
                    courseId={courseId}
                    currentElementLearnThread={currentElementLearnThread}
                    formVisible={formVisible}
                    hideExpandSolutionPanel={hideExpandSolutionPanel}
                    isLocked={isLocked}
                    moduleId={moduleId}
                    openAnswerForm={openAnswerForm}
                    sendAnswer={sendAnswer}
                    sendComment={sendComment}
                    solutionIsNotEditable={solutionIsNotEditable}
                    // TODO is horrible pin
                    onInlineCompositeAnswerIsFilledChange={this.onInlineCompositeAnswerIsFilledChange}
                    validationReport={validationReport}
                />, inlineAnswerContainer));

            }

            if (showResults) {

                answers.push(ReactDOM.createPortal(<Solutions
                    key={inlineAnswerContainer.dataset.index}
                    inputIndex={parseInt(inlineAnswerContainer.dataset.index) - 1}
                    inputMaxWidth={parseInt(inlineAnswerContainer.dataset.maxwidth)}
                    inline={isInlineInput}
                    isSmt={isSmt}
                    answerType={answerType}
                    element_content={elementContent}
                    taskToken={taskToken}
                    element_progress={elementProgress}
                    force_show_right={forceShowRight}
                    isSolutionToShow={isSolutionToShow}
                    course_id={courseId}
                    module_id={moduleId}
                />, inlineAnswerContainer));

            }

        }

        return answers;

    }

    parseAnswersTag = (source: string): string => {

        return source
            .replace(/(%%%|%%)\((\d+)\)(\((\d+)\))?/g, (match, type, index, scoped, width) => (
                `<span class="inline_answers inline_answers--${type === "%%" ? "inline" : "block"}" data-index=${index} data-maxwidth=${width}></span>`));

    };


    render(): React.ReactNode {
        const {renderAnswers, children} = this.props;
        const {initRendered, instance} = this.state;

        if (!children) { return ''; }

        if (typeof children === "object") {
            return children;
        }

        const source = this.parseAnswersTag(children as string);

        return <>
            {
                renderAnswers && initRendered && this.renderAnswers()
            }
            {
                !!instance &&
                <Marked
                    className                = {this.props.className}
                    onClick                  = {this.props.onClick}
                    ref                      = {this.markedRef}
                    getVideoResolveContainer = {this.getVideoResolveContainer(this.state.instance)}
                >
                    {source}
                </Marked>
            }
        </>;

    }

}
