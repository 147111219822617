import React                        from "react";
import isEqual                      from "lodash/isEqual";
import cloneDeep                    from "lodash/cloneDeep";
import {Subscription}               from "rxjs";
import {GrowGap, LGap, VBox}        from "@sirius/ui-lib/src/blocks/Layout";
import {CircularProgress}           from "@sirius/ui-lib/src/blocks/CircularProgress";
import {noop}                       from "@sirius/ui-lib/src/utils/noop";
import {printScreen$}               from "Smt/subjects/PrintScreen";
import {TaskPageData$}              from "Smt/TaskPage/TaskPageDataSubject";
import Answers                      from "Cheops/components/ModulePassing/Answers/Answers";
import Solutions                    from "Cheops/components/ModulePassing/Solutions";
import SolutionToShowPopup          from "Cheops/components/ModulePassing/SolutionToShowPopup";
import BemClassName                 from "Cheops/BemClassName";
import {TaskAnswerType}             from "Cheops/Model/Task";
import {SmtTask}                    from "Lab/actionsV2/smt";
import VerdictText                  from "Smt/TaskPage/Task/VerdictText";
import ResultSwitchButton           from "Smt/TaskPage/Task/ResultSwitchButton";
import {SmtAnswerWidget}            from "Smt/SmtAnswerWidget";
import MarkedWithAnswers            from "Smt/MarkedWithAnswers";
import {TaskSaveButton}             from "Smt/TaskPage/Task/TaskSaveButton";
import {TaskSaveButtonLabel}        from "Smt/TaskPage/Task/TaskSaveButtonLabel";
import {SmtLocaleString}            from "Smt/SmtLocaleBlock";
import {SmtProgrammingAnswer}       from "Smt/SmtProgrammingAnswer";
import {ElementVerdict}             from "Cheops/Model/Element";
import {DetailedAnswerComment}      from "Smt/TaskPage/Task/DetailedAnswerComment/DetailedAnswerComment";
import type {
    OnlineStateType,
    TaskOnlineStateType,
    MaybeOnlineStateType,
    MaybeTaskOnlineStateType,
}                                  from "Smt/SmtProgrammingAnswer/block";
import {
    answerValidationCb,
    checkHasClientValidationError,
    getTaskClientValidationError,
    ValidationReportType
}                                  from "Smt/TaskPage/Task/ClientValidation";
import {TaskClientValidationLabel} from "Cheops/components/ModulePassing/InputClientValidation/TaskClientValidationLabel";
import {getFractionLatex}          from "Smt/actions/smtCache";

interface Props {

    tasks:              Record<string, any>;
    taskNum:            number;
    isSavingInProgress: boolean;
    hasErrors:          boolean;
    tasksToken:         string;
    userAnswers:        any[];
    savedTasks:         any[];
    saveTask:           (taskNum: number) => void;
    nextTask:           (taskNum: number) => void;
    cachedTasks:        any;
    metadata:           any;
    grouped:            boolean;
    showRightAnswer:    boolean;
    resultData:         SmtPortal.TestResultItem;
    onlineState:        MaybeOnlineStateType;
    isHidden:           boolean;
    allowResend:        boolean;
    resendAnswer:       (taskNum: number) => void;
    resendError:        boolean;
    locale:             string;

    changeAnswer(answer: any, taskNum: number, done: () => void): void;
    toggleShowRightAnswer(taskNum?: number): void;
    onAnswerIsFilledChange(value: boolean, hasErrors: boolean): void;
}



const isOK                 = ({verdict}: SmtCache.ReviewsResponseSolution): boolean => verdict === "ok";
const isNotOK              = (solution:  SmtCache.ReviewsResponseSolution): boolean => !isOK(solution);
const ResponseSort         = (a: {tmSec: number}, b: {tmSec: number}): number => (a.tmSec - b.tmSec) >= 1 ? 1 : -1;

const notOkVerdicts = [
    ElementVerdict.OK,
    ElementVerdict.UNSCORED,
    ElementVerdict.NONEUNSCORED,
    ElementVerdict.NOTREADY,
    ElementVerdict.ANNULLED,
];

const RESEND_TIMEOUT_MS = 30000;

const hiddenResultsFor = [TaskAnswerType.NONE];

const calcTaskOnlineState = ({solutions, pending, tasksTimers}: OnlineStateType, {onlineState:prevOnlineState}: State): TaskOnlineStateType => {

    const hasPending           = pending?.length > 0;
    const hasSolutions         = solutions?.length > 0;
    const hasSolutionLastNotOk = !hasPending && solutions.some( isNotOK );
    const hasSolutionOk        = solutions.some( isOK );

    let editSolution , hasNextTryLock;

    if (prevOnlineState?.editSolution) {
        editSolution   = prevOnlineState?.editSolution;
        hasNextTryLock = prevOnlineState?.hasNextTryLock;
    } else {
        editSolution   = !hasSolutions;
        hasNextTryLock = hasSolutions && hasSolutionLastNotOk;
    }

    if (hasPending) {
        editSolution   = false;
    }

    const onlineState: TaskOnlineStateType = {
        solutions: solutions.sort( ResponseSort ),
        pending: pending.sort( ResponseSort ),
        tasksTimers,
        hasPending,
        hasSolutionLastNotOk,
        hasSolutionOk,
        hasNextTryLock,
        editSolution,
        hasSolutions,
    };

    return onlineState;
};

type State = {
    validationReport: ValidationReportType;
    onlineState:      MaybeTaskOnlineStateType;
    isHideContent:    boolean;
    tsContest:        Partial<SmtPortal.ContestData>;
    resendBlocked:    boolean;
    taskNumValidated: number | null;
}

export default class Task extends React.Component<Props> {

    state: State = {
        validationReport: {
            elements: null,
            field:    null,
            task:     null
        },
        onlineState:      null,
        isHideContent:    false,
        tsContest:        {},
        resendBlocked:    true,
        taskNumValidated: null
    }

    static getDerivedStateFromProps({onlineState}: Props, prevState: State) {
        return {
            onlineState: onlineState
                            ? calcTaskOnlineState(onlineState, prevState)
                            : null
        }
    }

    unlockNextTry = (): void => {
        const {onlineState} = this.state;
        if (onlineState) {
            this.setState({
                onlineState: {
                 ...onlineState,
                    hasNextTryLock: false,
                    editSolution:   true,
                }
            });
        }
    }

    private printScreenSubscription: Subscription;
    private hideContentDelay:  NodeJS.Timeout;
    private resendDelay:  NodeJS.Timeout = null;

    componentDidMount(): void {
        const {allowResend, onlineState} = this.props;
        window.addEventListener("resize", this.calculateMargin);
        this.calculateMargin();
        setTimeout(() => {
            const imgs = document.querySelectorAll('.task img');
            for (const img of Array.from(imgs)) {
                img.addEventListener("load", this.calculateMargin);
            }
        }, 200);
        const {tsContest} = TaskPageData$.getValue();
        const stopPrintScreen = tsContest?.disableScreenshot ? this.stopPrintScreen : noop;
        this.printScreenSubscription = printScreen$.subscribe( stopPrintScreen );
        this.setState({tsContest});

        allowResend &&
        this.handleResendMode(onlineState?.pending.length > 0, onlineState?.tasksTimers[0]?.sentAtISO);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {resendError, allowResend, taskNum} = this.props;
        const {resendBlocked, taskNumValidated} = this.state;
        const needTaskToValidate = taskNum !== taskNumValidated;

        this.calculateMargin();

        if (resendError) {
            if (resendBlocked) {
                this.setState({resendBlocked: false});
            }
        } else {
            if (allowResend) {
                const prevHasPending = prevProps.onlineState?.pending.length > 0
                this.handleResendMode(prevHasPending);
            }
        }
        if(needTaskToValidate){
            const {userAnswers, cachedTasks, locale} = this.props;
            const cachedTask = cachedTasks[taskNum];
            const answer = userAnswers[taskNum];
            if (cachedTask) {
                answerValidationCb(answer, cachedTask, locale).then(
                    (validationReport) => {
                        this.setState(
                            {validationReport, taskNumValidated: taskNum}
                        )
                    }
                );
            }
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.calculateMargin);
        this.printScreenSubscription.unsubscribe();
        clearTimeout(this.resendDelay);
    }

    activateResendDelay = (delayMS: number = RESEND_TIMEOUT_MS) => {
        this.resendDelay = setTimeout(
            () => {
                this.setState({resendBlocked: false});
            },
            delayMS
        );
    };

    getDelayMS =(lastSent: string) => {
        const currentTimeMS = new Date().getTime();
        const lastSentMS    = new Date(lastSent).getTime();
        const remainTime    = lastSentMS + RESEND_TIMEOUT_MS;

        return remainTime > currentTimeMS
                ? remainTime - currentTimeMS
                : 0
        ;
    };

    handleResendMode = (prevHasPending: boolean, lastSentAt = '' ) => {
        const {resendBlocked} = this.state;
        if (prevHasPending) {
            if (resendBlocked && !this.resendDelay){
                this.activateResendDelay(
                    lastSentAt && !this.resendDelay
                        ? this.getDelayMS(lastSentAt)
                        : void(0)
                );
            }
        } else {
            if (!resendBlocked) {
                this.setState(
                    {resendBlocked: true},
                    ()=>{
                        if (this.resendDelay) {
                            this.resendDelay = null;
                        }
                    }
                );
            }
        }
    };

    stopPrintScreen = (isHideContent: boolean): void => {
        // @ts-ignore
        const userPlatform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
        const isMac = /(Mac)/i.test(userPlatform);

        if (isMac) {
            this.setState({isHideContent});

             isHideContent
                 ? this.hideContentDelay = setTimeout(
                     () => (isHideContent && printScreen$.next(false)),
                     6000
                 )
                 : clearTimeout(this.hideContentDelay);
        } else {
            const {tsContest: {title}} = this.state;
            isHideContent && navigator?.clipboard?.writeText(`${title} ... `);
            isHideContent && printScreen$.next(false);
        }

    }

    calculateMargin = (): void => {
        const taskDiv = document.getElementsByClassName('task')[0] as HTMLDivElement;
        if (!taskDiv) {
            return;
        }
        if (taskDiv.classList.contains('task--grouped')) {
            taskDiv.style.margin = '';
            return;
        }
        let top = (window.innerHeight - taskDiv.offsetHeight) / 2;
        taskDiv.style.margin = "";
        if (top > 120) {
            taskDiv.style.margin = `${top}px auto`;
        }
    };

    changeAnswer = (answer: any, done: () => void): void => {
        const {taskNum, cachedTasks, locale} = this.props;
        const cachedTask = cachedTasks[taskNum];
        const doneCb = ()=> {
            answerValidationCb(answer, cachedTask, locale).then(
                (validationReport) => {
                    this.setState(
                        {validationReport},
                        done
                    )
                }
            );
        }
        this.props.changeAnswer(
            answer,
            taskNum,
            doneCb
        );
    };


    getSolutionContent = (removeDetailed = false): any => {
        const activeTask: SmtTask = cloneDeep(this.props.cachedTasks[this.props.taskNum]);
        if (removeDetailed) {
            activeTask.type.forEach((type, index) => {
                if (type === TaskAnswerType.DETAILED) {
                    activeTask.type[index] = TaskAnswerType.NONE;
                    activeTask.answersData[index] = {};
                }
            });
        }
        return {
            description: activeTask.description,
            type:        activeTask.type,
            answersData: activeTask.answersData,
        };
    };

    isTaskCanBeAnswered(): boolean {
        const {taskNum, cachedTasks} = this.props;
        if (!cachedTasks[taskNum]) {
            return false;
        }
        const type = cachedTasks[taskNum].type;
        if (type.filter((t: string) => t !== 'none').length > 0) {
            return true;
        }
        return false;
    }

    getAnswersIsFilled(savedTask: any): boolean {
        if (!savedTask) {
            return false;
        }
        const taskAnswers  = savedTask.filter((a: any) => a.solution !== null && typeof a.solution !== 'undefined');
        let result = taskAnswers.length > 0;
        const {taskNum, cachedTasks} = this.props;
        const {type:typeArr, answersData: answersDataArr} = cachedTasks[taskNum];
        const [type] = typeArr;
        if (result === true && type === 'ordering') {
            const [answersData] = answersDataArr;
            const {partialAnswer} = answersData;
            const savedTaskIsFilled = !savedTask.some( ({solution}: {solution:any[]}) => !solution || (Array.isArray(solution) && solution.includes( null )) );
            if (partialAnswer !== true && savedTaskIsFilled !== true) {
                result = false;
            }
        }
        return result;

    }

    getAnswerComponents = (): Record<string, any> => ({
        "programming": SmtProgrammingAnswer
    })

    handleResendAnswer = (taskNum: number) => {
        this.resendDelay = null;
        this.setState(
            {resendBlocked: true},
            () => this.props.resendAnswer(taskNum)
        );
    };

    getIsEqual = (taskNum: any) => {
        const {onlineState, allowResend, userAnswers, savedTasks} = this.props;

        if (allowResend && onlineState.pending.length > 0) {

            const lastPending = onlineState?.pending
                .filter(
                    ({taskId}) => taskId === (parseInt(taskNum) + 1)
                ) as unknown as Array<OnlineStateType['pending']>
            ;

            return lastPending.length > 0 && 'solution' in lastPending[0]
                ? isEqual(userAnswers[taskNum][0].solution, lastPending[0].solution)
                : isEqual(userAnswers[taskNum], savedTasks[taskNum])
            ;
        } else {
            return isEqual(userAnswers[taskNum], savedTasks[taskNum]);
        }
    }


    render(): React.ReactNode {

        const {
            metadata,
            userAnswers,
            hasErrors,
            savedTasks,
            isSavingInProgress,
            saveTask,
            nextTask,
            taskNum,
            showRightAnswer,
            resultData,
            toggleShowRightAnswer,
            tasksToken,
            grouped,
            cachedTasks,
            isHidden,
            allowResend,
            resendError,
            onAnswerIsFilledChange
        } = this.props;

        const {
            onlineState,
            isHideContent,
            resendBlocked,
            tsContest: {
                disableTextCopy = false,
                title = '',
            },
            validationReport
        } = this.state;

        const {hasPending = false} = onlineState ?? {};

        const saveButtonTitle = onlineState
            ? hasPending && allowResend
                ? "common.save_button.checking"
                : "common.task.save_button_title_online"
            :"common.task.save_button_title"
        ;

        const activeTask = cachedTasks[taskNum];

        const wrapperClassName = new BemClassName('single_task', null);

        wrapperClassName.appendStatusIf(grouped, 'grouped');
        wrapperClassName.appendStatusIf(!!resultData, 'has-result-data');
        wrapperClassName.appendStatusIf(showRightAnswer !== false, 'show_right_answer');
        wrapperClassName.appendStatusIf(disableTextCopy, 'noselect');
        wrapperClassName.appendStatusIf(isHideContent, 'hide_text_content');

        const taskTextClassName = new BemClassName('task__text');
        taskTextClassName.appendStatusIf(!!activeTask?.layout?.inline, 'inline');

        if (!activeTask) {
            return   <div className={wrapperClassName.toString()} id={`task_${taskNum}`}>
                        <CircularProgress centerOfWindow={true} />
                    </div>;
        }

        for (const type of activeTask.type) {
            wrapperClassName.appendAdditionalClasses(`type_${type}`);
        }



        const hasSolutionToShow = resultData?.solution?.solutionToShow?.length > 0;

        const showResultSwitchButton = !notOkVerdicts.includes(resultData?.verdict as never) &&
                                       (activeTask.type as TaskAnswerType[]).find((type) => !hiddenResultsFor.includes(type)) &&
                                       hasSolutionToShow
        ;

        const showAnswers = !resultData && !activeTask.layout?.inline;

        const showSolutions = resultData && !activeTask.layout?.inline;

        const showAnswerComment = resultData?.review && !activeTask.type.includes('programming');

        const showVerdict = resultData && activeTask.type !== "none";

        const showResultScore = showVerdict && metadata.scoreExplanation !== 'hide' && !["noneUnscored", "unscored"].includes(resultData.verdict);

        const showTaskCanBeAnswered = this.isTaskCanBeAnswered() && !showVerdict;

        const isLockedAnswers = (isSavingInProgress || hasPending) && !allowResend;

        const answerWidget = activeTask?.answerWidget;
        const SmtAnswerWidgetUserAnswer = showAnswers ? userAnswers[taskNum] : savedTasks[taskNum];


        const hasClientValidationError  = checkHasClientValidationError(validationReport);
        const taskClientValidationError = getTaskClientValidationError(validationReport);

        return (
            <VBox className={`${wrapperClassName}`} id={`task_${taskNum}`} grow >
                <div className={`${taskTextClassName}`}>
                    <MarkedWithAnswers
                        isSmt
                        renderAnswers          = {!!activeTask.layout?.inline}
                        showResults            = {!!resultData}
                        answerType             = {activeTask.type}
                        taskNum                = {taskNum}
                        userAnswer             = {userAnswers[taskNum]}
                        answersData            = {activeTask.answersData}
                        onAnswer               = {this.changeAnswer}
                        getFractionLatex       = {getFractionLatex}
                        onAnswerIsFilledChange = {onAnswerIsFilledChange}
                        element_content        = {this.getSolutionContent()}
                        element_progress       = {resultData}
                        taskToken              = {tasksToken}
                        validationReport       = {validationReport}
                    >
                        {activeTask.description}
                    </MarkedWithAnswers>
                </div>
                {
                    showAnswers &&
                    (
                        answerWidget
                            ? <SmtAnswerWidget
                                answerWidget= {answerWidget}
                                onAnswer    = {this.changeAnswer}
                                userAnswer  = {SmtAnswerWidgetUserAnswer}
                                savedAnswer = {savedTasks[taskNum]}
                            >
                                <Answers
                                        isSmt
                                        AnswerComponents       = {this.getAnswerComponents()}
                                        onlineState            = {onlineState}
                                        unlockNextTry          = {this.unlockNextTry}
                                        answerType             = {activeTask.type}
                                        taskNum                = {taskNum}
                                        userAnswer             = {userAnswers[taskNum]}
                                        taskToken              = {tasksToken}
                                        answersData            = {activeTask.answersData}
                                        onAnswer               = {this.changeAnswer}
                                        getFractionLatex       = {getFractionLatex}
                                        onAnswerIsFilledChange = {onAnswerIsFilledChange}
                                        isSavingInProgress     = {isSavingInProgress}
                                        isHidden               = {isHidden}
                                        isLocked               = {isLockedAnswers}
                                        allowResend            = {allowResend}
                                        validationReport       = {validationReport}
                                />
                            </SmtAnswerWidget>
                            : <Answers
                                    isSmt
                                    AnswerComponents       = {this.getAnswerComponents()}
                                    onlineState            = {onlineState}
                                    unlockNextTry          = {this.unlockNextTry}
                                    answerType             = {activeTask.type}
                                    taskNum                = {taskNum}
                                    userAnswer             = {userAnswers[taskNum]}
                                    taskToken              = {tasksToken}
                                    answersData            = {activeTask.answersData}
                                    onAnswer               = {this.changeAnswer}
                                    getFractionLatex       = {getFractionLatex}
                                    onAnswerIsFilledChange = {onAnswerIsFilledChange}
                                    isSavingInProgress     = {isSavingInProgress}
                                    isHidden               = {isHidden}
                                    isLocked               = {isLockedAnswers}
                                    allowResend            = {allowResend}
                                    validationReport       = {validationReport}
                            />
                    )

                }
                {
                    showRightAnswer &&
                    <SolutionToShowPopup
                        isSmt
                        answerWidget        = {answerWidget}
                        renderInDescription = {!!activeTask.layout?.inline}
                        elementProgress     = {resultData}
                        elementContent      = {this.getSolutionContent()}
                        onClose             = {() => toggleShowRightAnswer(taskNum)}
                    />
                }
                {
                    showSolutions &&
                    (answerWidget
                        ? <SmtAnswerWidget
                                answerWidget= {answerWidget}
                                disabled    = {true}
                                verdict     = {resultData?.verdict}
                                userAnswer  = {resultData?.solution?.answers[ 0 ]?.answer}
                        >
                            <Solutions
                                isSmt
                                answerType       = {activeTask.type}
                                element_content  = {this.getSolutionContent()}
                                taskToken        = {tasksToken}
                                element_progress = {resultData}
                                isHidden         = {isHidden}
                            />
                        </SmtAnswerWidget>

                        : <Solutions
                            isSmt
                            answerType       = {activeTask.type}
                            element_content  = {this.getSolutionContent()}
                            taskToken        = {tasksToken}
                            element_progress = {resultData}
                            isHidden         = {isHidden}
                        />)
                }
                <GrowGap/>
                {
                    taskClientValidationError &&
                    <TaskClientValidationLabel report={taskClientValidationError}/>
                }
                <div className="task__footer">
                    {
                        showVerdict &&
                        <>
                            {
                                showResultScore &&
                                <SmtLocaleString k="common.task.out_of" id="value" values={{value_1: resultData.score, value_2: resultData.maxScore}} />
                            }

                            <VerdictText answerType={activeTask.type} verdict={resultData.verdict as ElementVerdict} />
                            <GrowGap />
                            <LGap />
                            {
                                showResultSwitchButton &&
                                <ResultSwitchButton
                                  answerType={activeTask.type}
                                  isShowRightAnswer={showRightAnswer !== false}
                                  onToggleShowRightAnswer={() => toggleShowRightAnswer(taskNum)}
                                />
                            }
                        </>
                    }
                    {
                        showTaskCanBeAnswered &&
                        <>
                            <TaskSaveButtonLabel
                                onlineState        = {onlineState}
                                isSavingInProgress = {isSavingInProgress}
                                label              = {saveButtonTitle}
                                allowResend        = {allowResend}
                                resendError        = {resendError}
                            />
                            <GrowGap />
                            <LGap />
                            <TaskSaveButton
                                onlineState        = {onlineState}
                                hasSavedAnswer     = {this.getAnswersIsFilled(savedTasks[taskNum])}
                                answersIsFilled    = {this.getAnswersIsFilled(userAnswers[taskNum])}
                                answersHasError    = {hasErrors || hasClientValidationError}
                                answersIsEqual     = {this.getIsEqual(taskNum)}
                                isSavingInProgress = {isSavingInProgress}
                                onSave             = {() => saveTask(taskNum)}
                                onNext             = {() => nextTask(taskNum)}
                                unlockNextTry      = {this.unlockNextTry}
                                allowResend        = {allowResend}
                                resendBlocked      = {resendBlocked}
                                onResend           = {() => this.handleResendAnswer(taskNum)}
                            />
                        </>
                    }
                </div>
                {
                    showAnswerComment &&
                    <DetailedAnswerComment review={resultData.review}/>
                }
                {
                    isHideContent &&
                    <div className={'task__text--printsrn'}>
                        <span className={'task__title'}>
                             {title} ...
                        </span>
                    </div>
                }
            </VBox>
        );
    }
}
