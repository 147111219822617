export enum ElementReviewStatus {
    onReview = "onReview",
    reviewed = "reviewed",
    read = "read",
}


export enum ElementVerdict {
    OK = "ok",
    WRONG = "wrong",
    PARTLY = "partly",
    NOTREADY = "notReady",
    ANNULLED = "annulled",
    UNSCORED = "unscored",
    NONEUNSCORED = "noneUnscored",
    NONE = "none",
}


export const ElementVerdictWeights = {
    [ElementVerdict.NOTREADY]: 8,
    [ElementVerdict.ANNULLED]: 7,
    [ElementVerdict.UNSCORED]: 6,
    [ElementVerdict.OK]: 5,
    [ElementVerdict.PARTLY]: 4,
    [ElementVerdict.WRONG]: 3,
    [ElementVerdict.NONEUNSCORED]: 2,
    [ElementVerdict.NONE]: 1,
};

export interface ElementProgress {

    id: number;
    solution: {
        answers: []; // TODO
        solutionToShow?: {}; // TODO
    };

    max: number;
    current: number;
    isAchieved: boolean;
    isClosed: boolean;
    taskInfo: {};
    type: string;
    hash: string;
    isAdvanced: boolean;
    availability: {}; // TODO
    reviewStatus: ElementReviewStatus;
    verdict: ElementVerdict;

    resultsOpened: boolean;
    videosOpened: boolean;
    explanationsOpened: boolean;
    hintsPenalty: number;
    extraSolutions: boolean;

}
