import Meta from "Smt/Meta";

export default interface Prize {
    description: string;
    title: string;
    link?: string;
    linkText?: string;
    file?: string;
    type: PrizeType;
}


export enum PrizeType {
    congrats = "congrats",
    ordinary = "ordinary",
}



export interface PrizeTypeComponentProps {
    data: Prize;
    meta: Meta;
};
