export const YANDEX_METRIKA_SMT = {
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
    },
};

export const REPORT_BUG_LVL = {
    WARNING: "warning",
    ERROR: "error",
    CRITICAL: "critical",
    ALERT: "alert",
};

export const REPORT_INITIATOR = {
    FRONT: "front",
    BACK: "back",
};

export const MAX_TASK_CONTENT_WIDTH = 715;
