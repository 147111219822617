import Axios, {AxiosError, AxiosPromise} from "axios";
import {BehaviorSubject}                 from "rxjs";
import store                             from "Cheops/Store";
import {setAuthToken}                    from "Lab/actions/auth";

export type MaybeToken        = null | string;

const DELAY                   = 10 * 1000;
const TOKEN_KEY               = CONFIG.Auth.localstorage_key;
const getStoredToken          = (): MaybeToken => localStorage.getItem(TOKEN_KEY);
const initToken: MaybeToken   = getStoredToken();
const token$                  = new BehaviorSubject<MaybeToken>(initToken);

const headers = {
    'cache-control': 'no-cache',
    'content-type':  'application/json',
    'Accept':        'application/json',
};

const bearerHeaders = (bearer: string) => ({
    ...headers,
    'Authorization': `Bearer ${bearer}`,
});

const LabNoo_POST_oauth_token = (bearer: string): AxiosPromise<AuthAPI.TokenResponse> =>
     Axios({
        method: "POST",
        url:    "/noo-back/oauth/token",
        headers: bearerHeaders(bearer)
    })
;


let TokenUpdateTimeout: any;
const scheduleUpdate = (delay?: number): void => {
    const bearer = getStoredToken();
    TokenUpdateTimeout && clearTimeout(TokenUpdateTimeout);
    TokenUpdateTimeout = setTimeout(
        () => {
            LabNoo_POST_oauth_token(bearer)
                .then(
                    ({data, status}) => {
                        if (status === 200) {
                            const {token}         = data as AuthAPI.TokenResponseToken;
                            const {retryAfterSec} = data as AuthAPI.TokenResponseRetryAfter;
                            if (token) {
                                bearer !== token && store.dispatch(setAuthToken(token));
                                scheduleUpdate();
                            } else if (retryAfterSec) {
                                scheduleUpdate(retryAfterSec * 1000);
                            } else {
                                scheduleUpdate();
                            }
                        }
                    }
                )
                .catch(
                    (error: AxiosError<AuthAPI.TokenResponseLocation>)=>{
                        if (error?.isAxiosError){
                            const {response: {status, data}} = error;
                            if (status === 401){
                                const {location} = data;
                                if (location ) {
                                    window.location.href = location;
                                } else {
                                    scheduleUpdate();
                                }
                            }
                        }
                    }
                )
        },
        delay || DELAY
    )
}

token$.subscribe(
    (token) => {
        token && scheduleUpdate();
    }
);

export const WaitAuthToken = (label = ''): Promise<string> => {
    // console.log(`WaitAuthToken call ${label}`)
    return new Promise(
        (resolve)=>{
            // console.log(`WaitAuthToken subscribe ${label}`)
            token$.subscribe(
                (token) => {
                    // console.log(`WaitAuthToken listener ${label}`, !!token)
                    if (token) {
                        resolve(token);
                    }
                }
            )
        }
    )
}

const setTokenFromStorage = () => {
    const token = getStoredToken();
    token !== token$.getValue() && token$.next(token);
}

window.addEventListener('storage', setTokenFromStorage);
