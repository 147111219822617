import React from "react";
import {SvgIcon} from "@material-ui/core";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const ExportIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd" clipRule="evenodd"
            d="M17.2509 2.75301V3.94551C12.0009 4.69551 9.75094 8.44551 9.00094 12.1955C10.8759 9.57051 13.5009 8.37051 17.2509 8.37051V9.63801C17.2509 10.3055 18.0609 10.6355 18.5334 10.163L21.9759 6.72051C22.2684 6.42801 22.2684 5.95551 21.9759 5.66301L18.5334 2.22051C18.0609 1.74801 17.2509 2.08551 17.2509 2.75301ZM6 19H18C18.55 19 19 18.55 19 18V13C19 12.45 19.45 12 20 12C20.55 12 21 12.45 21 13V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H11C11.55 3 12 3.45 12 4C12 4.55 11.55 5 11 5H6C5.45 5 5 5.45 5 6V18C5 18.55 5.45 19 6 19Z"
            fill="#B0B0B0"
        />
    </svg>
</SvgIcon>;


export default ExportIcon;
