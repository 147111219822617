import {UIOptions as DefaultUIOptions}      from "@sirius/ui-lib/src/blocks/SchemaField/ui/default";
import {getItemT}                           from "@sirius/ui-lib/src/blocks/SchemaField/ui/lab";
import {
    MetaFieldOptionsType,
    SchemaFieldOptionsType,
    optionsView,
    UIOptionsType,
    UnionOptionsType,
    ErrorFieldOptionsType,
    ErrKeywords,
}                                           from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {
    ajvCustomFormats,
    formatsValidator,
    getMetaT,
}                                           from "Smt/Auth/SchemaField";
import {translations}                       from "../translations";
import {i18nProject}                        from "../smt-register-form-block";

const getMeta  = getMetaT(translations[i18nProject]);
const getItem  = getItemT(translations);
const errKeywords: ErrKeywords = ['required', 'format', 'minLength', 'maxLength', 'pattern'];

const SchemaField: SchemaFieldOptionsType = {
    ...DefaultUIOptions.SchemaField,
    ajvCustomFormats,
    errKeywords
}

const StringField: UnionOptionsType = {
    ...DefaultUIOptions.StringField,
    enum: {
        getItem,
        button: { form: "rounded" },
        view: optionsView.chips,
    },
    view: optionsView.line,
};

const MetaField: MetaFieldOptionsType = {
    ...DefaultUIOptions.MetaField,
    getMeta
}

const ErrorField: ErrorFieldOptionsType = {
    ...DefaultUIOptions.ErrorField,
    i18nProject,
    formatsValidator,
    showErrorMsg: true
}

export const UIOptions: UIOptionsType = {
    ...DefaultUIOptions,
    MetaField,
    SchemaField,
    StringField,
    ErrorField,
};
