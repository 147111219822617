import React                            from "react";
import { DropdownSelect }               from "@sirius/ui-lib/src/blocks/DropdownSelect";
import { Option }                       from "@sirius/ui-lib/src/blocks/DropdownSelect/Option";
import {getLanguagePickerName}          from "@sirius/ui-shared/src/components/Programming";
import {CodeEditor as CodeEditorTestC}  from "Lab/TestClasses.json";
import { block }                        from "./block";
import type { LanguagesSelectorType }   from "./block";

const LanguagesSelector = ({languages, language, onLanguageChange, disabled}: LanguagesSelectorType) =>
    <DropdownSelect
        inline
        disabled  = { disabled }
        className = { block.el('templates-selector') }
        onChange  = { ([value]: { lang: SmtTask.Language }[]) => value && onLanguageChange(value.lang) }
        cleanable = { false }
    >
        {
            languages.map(
                (lang, li) =>
                    <Option
                        key={li}
                        value={{lang}}
                        selected={lang === language}
                        className={`${CodeEditorTestC}-${lang} `}
                    >
                        {getLanguagePickerName(lang)}
                    </Option>
            )
        }
    </DropdownSelect>
;

export {LanguagesSelector};
