import React, { FunctionComponent, RefObject } from 'react';
import BemClassName from "Cheops/BemClassName";
import { UIControlProps } from './UIControl';

import './UIElementWrapper.less';

interface Props extends UIControlProps {
    children: React.ReactNode;
    className?: string;
    singleton?: boolean;
    justifyContent?: React.CSSProperties["justifyContent"];
    fullWidth?: boolean;
    hasHover?: boolean;
    bordered?: boolean;
    darkMode?: boolean;
    wrap?: boolean;
    outerRef?: RefObject<HTMLDivElement>;

    onMouseLeave?(): void;

    onMouseEnter?(): void;

    onClick?(e?: React.MouseEvent): void;

}


const UIElementWrapper: FunctionComponent<Props> = (props) => {

    const {
        singleton,
        children,
        className,
        justifyContent,
        onMouseLeave,
        onMouseEnter,
        onClick,
        hasHover,
        bordered,
        darkMode,
        wrap,
        outerRef,
        fullWidth,
    } = props;

    const wrapperClassname = new BemClassName('element_wrapper');
    wrapperClassname.appendStatusIf(!!justifyContent, `justify-content-${justifyContent}`);
    wrapperClassname.appendStatusIf(singleton, `singleton`);
    wrapperClassname.appendStatusIf(hasHover, `has-hover`);
    wrapperClassname.appendStatusIf(bordered, `bordered`);
    wrapperClassname.appendStatusIf(darkMode, `dark_mode`);
    wrapperClassname.appendStatusIf(wrap, `wrap`);
    wrapperClassname.appendStatusIf(fullWidth, `full-width`);

    wrapperClassname.appendAdditionalClassesIf(!!className, className);

    return <div
        className={wrapperClassname.toString()}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        ref={outerRef}
    >
        {children}
    </div>;

};

export default UIElementWrapper;

UIElementWrapper.defaultProps = {
    outerRef: null,
};
