import React                                    from "react";
import { Button }                               from "@sirius/ui-lib/src/blocks/Controls/Button";
import {TaskSaveButton as TaskSaveButtonTestC}  from "Lab/TestClasses.json";
import {SmtLocaleString}                        from "Smt/SmtLocaleBlock";
import type { MaybeTaskOnlineStateType }        from "Smt/SmtProgrammingAnswer/block";


interface Props {
    hasSavedAnswer:     boolean;
    answersIsFilled:    boolean;
    answersHasError:    boolean;
    isSavingInProgress: boolean;
    answersIsEqual:     boolean;
    onSave:             () => void;
    onNext:             () => void;
    unlockNextTry:      () => void;
    onResend:           () => void;
    onlineState:        MaybeTaskOnlineStateType;
    allowResend?:       boolean;
    resendBlocked?:     boolean;
}

type ModePrimary = "primary" | "outlined";
type FormSoft    = "soft";
type SizeL       = "l";

const TaskSaveButton: React.FC<Props> = ({
    onSave, onNext,
    isSavingInProgress, hasSavedAnswer, answersHasError,
    answersIsFilled, answersIsEqual, allowResend, resendBlocked, onResend,
    onlineState, unlockNextTry
}) => {
    // const buttonClass = new BemClassName('task_save_btn');

    let buttonText: JSX.Element | string = <SmtLocaleString k="common.save_button.save_the_answer" id="value" />;
    let disabled   = false;
    let loading    = false;
    let onAction   = onSave;
    let className  = TaskSaveButtonTestC;
    let style: any = {minWidth:"180px", color:void(0)};
    let mode: ModePrimary = "primary";
    const form: FormSoft  = "soft";
    const size: SizeL     = "l";

    if (onlineState){
        const { hasPending,
                hasSolutionOk,
                hasNextTryLock,
                hasSolutionLastNotOk,
                               } = onlineState;
        buttonText = <SmtLocaleString k="common.save_button.give_answer" id="value" />;

        if ( isSavingInProgress || hasPending ) {
            loading  = true;
            disabled = true;
            buttonText = <SmtLocaleString k="common.save_button.checking" id="value" />;

            if (hasPending && allowResend) {
                loading = false;
                disabled = (answersIsFilled && answersIsEqual) || !answersIsFilled ? true : resendBlocked;
                buttonText = <SmtLocaleString k="common.save_button.resend" id="value" />;
                onAction = onResend;
            }
        } else if ( hasSolutionOk ) {
            buttonText = <SmtLocaleString k="common.save_button.next_task" id="value" />;
            onAction   = onNext;
       } else if ( hasSolutionLastNotOk && hasNextTryLock ) {
            buttonText = <SmtLocaleString k="common.save_button.next_try" id="value" />;
            onAction   = unlockNextTry;
        } else if (!answersIsFilled || answersHasError) {
            disabled = true;
        } else if (answersIsFilled && answersIsEqual) {
            disabled = true;
        }

    } else {
        if (answersHasError) {
            disabled = true;
        }

        if (answersIsFilled && answersIsEqual) {
            disabled = true;
            // buttonClass.appendStatus('saved');
            buttonText = <SmtLocaleString k="common.save_button.answer_is_saved" id="value" />;
        }

        if (!answersIsFilled) {
            if (hasSavedAnswer) {
                buttonText = <SmtLocaleString k="common.save_button.save" id="value" />;
            }
            if (!hasSavedAnswer) {
                disabled = true;
            }
        }

        if (isSavingInProgress) {
            loading  = true;
            disabled = true;
            buttonText = <SmtLocaleString k="common.save_button.save" id="value" />;
        }
    }

    // buttonClass.appendStatusIf(disabled, 'disabled');
    return  <Button
                {...{disabled, mode, onAction, loading, form, size, className, style}}
            >{buttonText}</Button>
    ;


};


export {TaskSaveButton};
