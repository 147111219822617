import {TranslationsType}    from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";


export const translations: TranslationsType = {
    errors: {
        408: {
            document: {
                ru: {title:'Интернет-соединение неустойчиво'},
                en: {title:'Connection is unstable'},
            },
            title: {
                ru: {value:'Интернет-соединение неустойчиво'},
                en: {value:'Connection is unstable'},
            },
            message: {
                ru: {value:'Проверьте подключение {br} или обратитесь за помощью к специалистам'},
                en: {value:'Check your connection {br} or request your admin for support'},
            },
            button: {
                ru: { value: 'Перезагрузить страницу'},
                en: { value: 'Reload page' },
            }
        },
        409: {
            document: {
                ru: { title: 'Конфликт - другое решение находится на проверке'},
                en: { title: 'Conflict'},
            },
            title: {
                ru: { value: 'Дождитесь завершения проверки'},
                en: { value: 'Wait for the validation to complete'},
            },
            message: {
                ru: { value: 'Не торопитесь. Отправляйте новое решение после проверки предыдущего'},
                en: { value: 'Take your time. Submit a new solution after checking the previous one'},
            },
            button: {
                ru: { value: 'Вернуться в олимпиаду'},
                en: { value: 'Back' },
            }
        },
        423: {
            document: {
                ru: { title: 'Отправка решений заблокирована'},
                en: { title: 'Locked'},
            },
            title: {
                ru: { value: 'Для вас отправка решений заблокирована'},
                en: { value: 'Sending solutions is locked for you'},
            },
            message: {
                ru: { value: 'Вы отправили 1000 решений по одной задаче. Мы считаем это признаком нечестной борьбы. {br} Хотите оспорить — обращайтесь на апелляцию'},
                en: { value: 'You have sent 1000 solutions for one task. We consider this a sign of an unfair struggle. {br} If you want to challenge, please appeal'},
            },
            button: {
                ru: { value: 'Вернуться в олимпиаду'},
                en: { value: 'Back' },
            }
        },
        429: {
            document: {
                ru: { title: 'Очень много запросов'},
                en: { title: 'Too Many requests'},
            },
            title: {
                ru: { value: 'Не так быстро!'},
                en: { value: 'Wait, slow down!'},
            },
            message: {
                ru: { value: 'Вы отправляете решения слишком быстро. Мы считаем это признаком нечестной борьбы. {br} Сбавьте темп, чтобы продолжать'},
                en: { value: 'You are sending solutions too fast. We consider this a sign of an unfair struggle. {br} Slow down the pace to continue'},
            },
            button: {
                ru: { value: 'Вернуться в олимпиаду'},
                en: { value: 'Back' },
            }
        },
    }
};
