import React from "react";
import "./MessagePopup.less";

export default class MessagePopup extends React.Component {

    static defaultProps = {
        leftButtonText: null,
        rightButtonText: null,
        onLeftClick: () => {
        },
        onRightClick: () => {
        },
    };

    render() {

        const {leftButtonText, rightButtonText} = this.props;

        return (<div className="message_popup">
            <div>
                {this.props.children}
                <div className="message_popup__buttons">
                    {leftButtonText
                    && <button onClick={this.props.onLeftClick}>{this.props.leftButtonText}</button>}
                    {rightButtonText
                    && <button onClick={this.props.onRightClick}>{this.props.rightButtonText}</button>}
                </div>
            </div>
        </div>);

    }

}
