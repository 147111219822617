export interface Coordinate {
    x: number;
    y: number;
}


export enum TaskAnswerType {
    NONE = "none",
    MULTI = "multi",
    SINGLE = "single",
    NUMBER = "number",
    STRING = "string",
    SELECT = "select",
    DETAILED = "detailed",
    MATCH = "match",
    COMPOSITE = "composite",
    PROGRAMMING = "programming",
    ORDERING    = "ordering"
}

export enum TaskAnswerTypeDescription {
    none = "",
    multi = "Выбор варианта ответа",
    single = "Выбор варианта ответа",
    number = "Поле ввода: число",
    string = "Поле ввода: текст",
    select = "Выбор варианта ответа",
    detailed = "Развёрнутый ответ",
    match = "Сопоставление",
    composite = "",
    programming = "",
    ordering = "Расставить в верном порядке"
}

export enum AnswersType {
    SELECT = "select",
}

export enum ZoneType {
    rectangle = "rectangle",
    circle = 'circle',
    polygon = 'polygon',
}


export type TaskAnswerFigure = {
    type: ZoneType;
    coordinates: Array<Coordinate>;
    radius?: number;
};

export enum TaskCheckType {
    AUTOMATIC = "automatic",
    MANUAL = "manual",
}

export enum TaskType {
    SECTION = "section",
    ORDINARY = "ordinary",
    ADVANCED = "advanced",
}

export type TaskAnswer = {
    id: number;
    value?: string;
    image?: string;
    figure?: TaskAnswerFigure;
    caption?: string;
    formula?: string;
};

export enum ZoneDisplayMethod {
    full = "full",
    cursor = "cursor",
    nothing = "nothing",
}

export interface AnswersData {

    answers: TaskAnswer[];
    image?: string;
    zoneDisplayMethod: ZoneDisplayMethod;
    isRatioAllowed?: boolean;
}

export enum TaskStates {
    NONE                 = "none",
    PENDING              = "pending",
    RESULT_RIGHT         = "result_right",
    RESULT_NOT_RIGHT     = "result_not_right",
    RESULT_PARTIAL_RIGHT = "result_partial_right",
    RESULT_ANNULLED      = "result_annulled",
    RESULT_UNSCORED      = "result_unscored",
    SAVED                = "saved",
    NOT_SAVED            = "not_saved",
    NOT_ANSWERED         = "not_answered",
    ACTIVE               = "active",
    ACTIVE_IN_GROUP      = "active-in-group",
}

