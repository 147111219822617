import React from "react";
import Helpers from "Cheops/Helpers";
import {getUserInfo} from "Cheops/actions/auth";

export default class NotificationsListItem extends React.Component {

    static defaultProps = {
        notification_obj: {},
        teacher_name: '',
        unpinNotificationRequest: null,
        markAsReadNotification: null,
    };


    static TYPES = {
        NEWS: "course",
        QA: "qa",
        MODULE: "module",
        CERTIFICATE: "certificate",
        TASK_SOLUTION: "taskSolution",
        TASK_COMMENT: "taskComment",
        INVITATION: "invitation",
    };

    static STATUS = {
        READ: "read",
        WAIT: "wait",
        DELIVERED: "delivered",
    };

    constructor(props) {

        super(props);

        let is_new = false;

        if (props.notification_obj && props.notification_obj.status !== NotificationsListItem.STATUS.READ) {

            is_new = true;

        }

        this.state = {
            is_new,
        };

    }

    componentWillMount() {

        if (this.state.is_new && !Helpers.isParentMode()) {

            if (this.props.notification_obj.sourceType !== NotificationsListItem.TYPES.NEWS) {

                this.markAsReadNotificationRequest();

            }

        }

    }

    openLink() {

        if (this.state.is_new && !Helpers.isParentMode()) {

            if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.NEWS) {

                this.markAsReadNotificationRequest();

            }

        }

        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.NEWS) {

            location.hash = `#/news/${this.props.notification_obj.link}`;

        }

        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.QA) {

            location.hash = `#${this.props.notification_obj.link}`;

        }

        // format is "course_id/module_id"
        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.MODULE) {

            location.hash = `#${this.props.notification_obj.link}`;

        }

        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.CERTIFICATE) {

            location.hash = '#/certificate';

        }

        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.TASK_SOLUTION
            || this.props.notification_obj.sourceType === NotificationsListItem.TYPES.TASK_COMMENT
        ) {

            location.hash = `#/course${this.props.notification_obj.link}`;

        }

        if (this.props.notification_obj.sourceType === NotificationsListItem.TYPES.INVITATION) {

            location.hash = `#/course${this.props.notification_obj.link}`;

        }

    }

    unpinItem() {

        if (Helpers.isParentMode()) {

            return;

        }

        this.props.unpinNotificationRequest(this.props.notification_obj.notificationId);

    }

    markAsReadNotificationRequest() {

        let roles = getUserInfo('roles');

        if (roles.indexOf('nooRO') !== -1) {

            return;

        }

        this.props.markAsReadNotification(this.props.notification_obj.notificationId);

    }

    render() {

        let notification_obj = this.props.notification_obj;

        let show_news_link = false;


        if (notification_obj.sourceType
            && [
                NotificationsListItem.TYPES.NEWS,
                NotificationsListItem.TYPES.MODULE,
                NotificationsListItem.TYPES.QA,
                NotificationsListItem.TYPES.CERTIFICATE,
                NotificationsListItem.TYPES.TASK_SOLUTION,
                NotificationsListItem.TYPES.TASK_COMMENT,
                NotificationsListItem.TYPES.INVITATION,
            ].includes(notification_obj.sourceType)
        ) {

            show_news_link = true;

        }

        let list_classes = 'notifications_list_item';

        if (this.props.notification_obj.isPinned) {

            list_classes += ' notifications_list_item--pinned';

        }

        let link_text = 'Перейти';

        if (this.state.is_new) {

            list_classes += ' notifications_list_item--new';

        }

        if (notification_obj.sourceType === NotificationsListItem.TYPES.NEWS || notification_obj.sourceType === NotificationsListItem.TYPES.QA) {

            link_text = "Читать";

        }

        if (notification_obj.sourceType === NotificationsListItem.TYPES.INVITATION) {

            link_text = "Присоединяйтесь!";

        }

        let lecturer_name = [notification_obj.lecturer.firstName,
            notification_obj.lecturer.middleName,
            notification_obj.lecturer.lastName]
            .filter((x) => x)
            .join(" ");


        let notification_text = "";

        if (notification_obj.body) {

            notification_text = notification_obj.body;

        }


        return (
            <div className={list_classes}>
                <div className="notifications_list_item__teacher_photo">
                    {notification_obj.lecturer.avatar
                    && <img src={notification_obj.lecturer.avatar} />}
                    {!notification_obj.lecturer.avatar
                    && <img src={require("../../../img/lecturer-no-avatar--small.svg")} />}
                </div>
                {notification_obj.isPinned
                && <div className="notifications_list_item__close" onClick={this.unpinItem.bind(this)} />}
                <div className="notifications_list_item__content">
                    <div className="notifications_list_item__teacher_name">{lecturer_name}</div>
                    <span className="notifications_list_item__content_text"
                          dangerouslySetInnerHTML={{__html: notification_text}}
                    />

                    {show_news_link
                    && <span onClick={() => this.openLink()} className="notifications_list_item__link">
                        {` ${link_text}`}
                    </span>}
                </div>
            </div>
        );

    }

}
