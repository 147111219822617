import React, {FC}                                          from "react";
import bnc                                                  from "bnc";
import {Box, Modal, ModalBody, ModalFooter, ModalHeader}    from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                                             from "@sirius/ui-lib/src/blocks/Controls";
import {SmtLocaleString}                                    from 'Smt/SmtLocaleBlock';
import {ActionButtonsProps, AppModalProps}                  from "./@types";
import                                                          "./index.less"


const block = new bnc('b-app-modal');

const ACTION_BtnK = 'common.app_modal.apply';
const CANCEL_BtnK = 'common.app_modal.cancel';



const ActionButtons: FC<ActionButtonsProps> = (
    {
        onClose,
        onAction,
        actionBtnK = ACTION_BtnK,
        cancelBtnK = CANCEL_BtnK,
        disabled,
        orient='horizontal',
        size= 's',
        isMobile
    }
) => (
    <Box className = {block.el('button-container')} orient={orient} center={!isMobile}>
        <Button onAction = {onClose}
                size     = {size}
        >
            <SmtLocaleString k = {cancelBtnK}/>
        </Button>
        <Button mode     = {'primary'}
                onAction = {onAction}
                size     = {size}
                disabled = {disabled}
        >
            <SmtLocaleString k = {actionBtnK}/>
        </Button>
    </Box>
);


export const AppModal: FC<AppModalProps> = (
    {
        children,
        onClose,
        onAction,
        titleContent,
        actionBtnK,
        cancelBtnK,
        orientedButtons,
        sizeBtn,
        className = '',
        disabled = false,
        isMobile,
        ...restProps
    }
) => (
    <Modal className   = {block + block.bod('mobile', isMobile)+ className}
           onClose     = {onClose}
           {...restProps}
    >
        { titleContent &&
            <ModalHeader className  = {block.el('header')}
                         title      = {titleContent}
                         onClose    = {onClose}
            />
        }

        <ModalBody className={block.el('body')}>
            {children}
        </ModalBody>

        <ModalFooter className = {block.el('footer')}>
            <ActionButtons {...{onClose, onAction, disabled, isMobile, actionBtnK, cancelBtnK}}
                           orient           = {orientedButtons}
                           size             = {sizeBtn}
            />
        </ModalFooter>
    </Modal>
);
