import React from "react";
import UIElement from "Lab/components/UI2/UIElement";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";
import RadioControl, {RadioControlProps} from "Lab/components/UI2/Controls/RadioControl";
import "./RadioElement.less";


export default class RadioElement extends UIElement<RadioControlProps> {


    render(): React.ReactNode {

        return <UIElementWrapper className="radio_element">
            <RadioControl {...this.props} fullWidth color="primary" />
        </UIElementWrapper>;

    }

}
