import React from "react";
import './AspectElementsGroup.less';
import BemClassName from "Cheops/BemClassName";


interface Props {
    className?: string;
    darkMode?: boolean;
}

export default class AspectElementsGroup extends React.Component<Props> {

    render(): React.ReactNode {

        const {children, className, darkMode} = this.props;

        const wrapperClassName = new BemClassName('aspect_elements_group');

        wrapperClassName.appendAdditionalClassesIf(!!className, className);
        wrapperClassName.appendStatusIf(darkMode, 'darkMode');

        return <>

            <div className={wrapperClassName.toString()}>{children}</div>

        </>;

    }

}
