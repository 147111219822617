import React            from "react";
import ReactDOM         from "react-dom";
import Axios            from "axios";
import CircularProgress from "Smt/Ui";
import IndexPage        from "Smt/IndexPage/IndexPage";
import UserInfo         from "Smt/UserInfo";

import {
    ViberShareButton,
    ViberIcon,
    VKShareButton,
    VKIcon,
    OKShareButton,
    OKIcon,
    TelegramShareButton,
    TelegramIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';
import {SmtLocaleString} from "Smt/SmtLocaleBlock";
import {DocumentTitleLocale} from "Smt/SmtLocaleBlock/DocumentTitleLocale";
import LangSwitcher from "@sirius/ui-lib/src/blocks/LangSwitcher/LangSwitcher";
import {Screen} from "@sirius/ui-lib/src/blocks/Layout";


export default class CertificatePage extends React.Component {


    constructor(props) {
        super(props);

        let is_public_page = false;

        if (props.cert_hash) {
            is_public_page = true;
        }

        this.state = {
            is_loaded: false,
            cert_hash: props.cert_hash,
            is_public_page,
            cert_info: {
                title: null,
                type: null,
                startedAt: 0,
                finishedAt: 0
            },
            share_url: null
        };
    }

    async UNSAFE_componentWillMount() {

        if (!this.state.cert_hash) {

            await Axios({
                method: "POST",
                url: "/smt-portal/certificate/issue",
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                },
                data: `${this.props.cert_token}`
            }).then((response) => {
                this.setState({
                    cert_hash: response.data
                });
            });

        }

        let share_url = location.origin + "/certificate_" + this.state.cert_hash;


        await Axios({
            method: "GET",
            url: "/smt-portal/certificate/info/" + this.state.cert_hash,
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {

            this.setState({
                is_loaded: true,
                share_url,
                cert_info: response.data
            });
        });


    }


    copyUrl() {
        let url = document.querySelector("#url_input");
        url.select();
        document.execCommand('copy');
    }


    renderPrivate() {

        const {cert_info} = this.state;

        const currentYear = new Date().getFullYear();

        const CR = (CONFIG?.DeployName || '').toLowerCase() === 'cpm' ? 'CPM' : 'SIRIUS';

        return (
            <div className="certificate_page">
                <LangSwitcher />
                <DocumentTitleLocale k="common.certificate.document_title" values={{text: cert_info.type}} />
                <div className="certificate_page_header">
                    <UserInfo user_name={this.props.tsUser.userName} />
                    <div className="certificate_page_header__logo" />
                    <div className="certificate_page_header__title">
                        {this.state.cert_info.title}
                    </div>
                </div>
                <div className="certificate_page__title">
                    <SmtLocaleString
                        k="common.certificate.congratulations"
                        id="value"
                        values={{text: this.state.cert_info.type}}
                    />
                </div>
                <div className="certificate_page__text">
                    <SmtLocaleString k={`${CONFIG.currentInstance}.certificate.share`} id="value" /> — <nobr><a
                    target="_blank"
                    href={"/smt-portal/certificate/pdf/" + this.state.cert_hash}
                    download='certificate.pdf'><SmtLocaleString
                    id="value"
                    k="common.certificate.download_file"
                />
                </a> (pdf)
                </nobr> <SmtLocaleString id="value" k="common.certificate.print_file" /> <a href="#" onClick={e => {
                    e.preventDefault();
                    this.copyUrl()
                }}><SmtLocaleString id="value" k="common.certificate.copy" /></a> <SmtLocaleString id="value"
                                                                                                   k="common.certificate.share_link" />
                </div>
                <div className="certificate_page__social">
                    <VKShareButton url={this.state.share_url}>
                        <VKIcon size={32} round={true} />
                    </VKShareButton>
                    <TelegramShareButton url={this.state.share_url}>
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <ViberShareButton url={this.state.share_url}>
                        <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                    <OKShareButton url={this.state.share_url}>
                        <OKIcon size={32} round={true} />
                    </OKShareButton>
                    <EmailShareButton url={this.state.share_url}>
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                </div>
                <img src={CONFIG.Api.webPortal.url + "/certificate/png/" + this.state.cert_hash}
                     className="certificate_page__img" />
                <div className="certificate_page__link">
                    <SmtLocaleString id="value" k="common.certificate.permanent_link" />
                    <input id="url_input" defaultValue={this.state.share_url} />
                </div>
                <div className="certificate_page__links">
                    <span><a target="_blank"
                             href={"/smt-portal/certificate/pdf/" + this.state.cert_hash}
                             download='certificate.pdf'>
                        <SmtLocaleString
                            id="value"
                            k="common.certificate.download_certificate"
                        />
                    </a> (pdf)</span>
                    <span><a href={"#"}
                             onClick={e => {
                                 e.preventDefault();
                                 this.copyUrl()
                             }}><SmtLocaleString id="value" k="common.certificate.copy_link" /></a></span>
                </div>

                <img src={CONFIG.Api.webPortal.url + "/certificate/qr/" + this.state.cert_hash}
                     className="certificate_page__qr" />

                <button className="certificate_page__to_contenst_btn" onClick={() => {
                    ReactDOM.render(<IndexPage />, document.getElementById("index"));
                }}>
                    <SmtLocaleString k={`${CONFIG.currentInstance}.certificate.back`} id="value" />
                </button>
                <footer>
                    <SmtLocaleString
                        k={`${CONFIG.currentInstance}.index.copyright_${CR}`}
                        id="value"
                        values={{currentYear, br: <br />}}
                    />
                </footer>
            </div>
        )
    }


    renderPublic() {

        let start_date = new Date(this.state.cert_info.startedAt);
        let finish_date = new Date(this.state.cert_info.finishedAt);

        const {cert_info} = this.state;


        let date_str = start_date.toLocaleString('ru', {
            day: "numeric",
        }) + "–" + finish_date.toLocaleString('ru', {
            day: "numeric",
            month: "long",
            year: "numeric"
        });

        if (start_date.getFullYear() !== finish_date.getFullYear()) {
            date_str = start_date.toLocaleString('ru', {
                day: "numeric",
                month: "long",
                year: "numeric"
            }) + " — " + finish_date.toLocaleString('ru', {
                day: "numeric",
                month: "long",
                year: "numeric"
            });
        } else if (start_date.getMonth() !== finish_date.getMonth()) {
            date_str = start_date.toLocaleString('ru', {
                day: "numeric",
                month: "long"
            }) + " — " + finish_date.toLocaleString('ru', {
                day: "numeric",
                month: "long",
                year: "numeric"
            });
        } else if (start_date.toDateString() === finish_date.toDateString()) {
            date_str = finish_date.toLocaleString('ru', {
                day: "numeric",
                month: "long",
                year: "numeric"
            });
        }

        const currentYear = new Date().getFullYear();

        const CR = (CONFIG?.DeployName || '').toLowerCase() === 'cpm' ? 'CPM' : 'SIRIUS';

        return (
            <div className="certificate_page">
                <LangSwitcher />
                <DocumentTitleLocale k="common.certificate.document_title" values={{text: cert_info.type}} />
                <div className="certificate_page_header">
                    <div className="certificate_page_header__logo" />
                    <div className="certificate_page_header__title">
                        <SmtLocaleString id="value" k="common.certificate.header_title" />
                    </div>
                    <div className="certificate_page_header__sub_title">
                        <SmtLocaleString id="value" k="common.certificate.verification" />
                    </div>
                </div>
                <div className="certificate_page__title">{this.state.cert_info.type}</div>
                <div className="certificate_page__text">
                    {this.state.cert_info.title}. {date_str}
                </div>
                <img src={CONFIG.Api.webPortal.url + "/certificate/png/" + this.state.cert_hash}
                     className="certificate_page__img" />
                <div className="certificate_page__link">
                    <SmtLocaleString id="value" k="common.certificate.permanent_link" />
                    <input id="url_input" defaultValue={this.state.share_url} readOnly={true} />
                </div>
                <div className="certificate_page__links">
                    <span><a target="_blank"
                             href={ "/smt-portal/certificate/pdf/" + this.state.cert_hash}
                             download='certificate.pdf'><SmtLocaleString id="value" k="common.certificate.download_certificate" /></a> (pdf)</span>
                    <span><a href={"#"}
                             onClick={e => {
                                 e.preventDefault();
                                 this.copyUrl()
                             }}><SmtLocaleString id="value" k="common.certificate.copy_link" /></a></span>
                </div>

                <img src={CONFIG.Api.webPortal.url + "/certificate/qr/" + this.state.cert_hash}
                     className="certificate_page__qr" />

                <footer>
                    <SmtLocaleString
                        k={`${CONFIG.currentInstance}.index.copyright_${CR}`}
                        id="value"
                        values={{currentYear, br: <br />}}
                    />
                </footer>
            </div>
        )
    }

    renderPage = () => {
        if (!this.state.is_loaded) {
            return (<CircularProgress centerOfWindow={true} />);
        }

        if (this.state.is_public_page) {
            return this.renderPublic();

        } else {
            return this.renderPrivate();
        }
    };


    render() {

        return <Screen>
            {this.renderPage()}
        </Screen>;
    }
}
