export default function (state = {
    contest_list: [],
    contest_info: {},
    contest_task_groups: [],
    contest_task_list: [],
    contest_task: null,
    contest_meta: {},
    contest_sections: [],
}, action) {
    switch (action.type) {

        case "GET_CONTEST_LIST_FULFILLED":
            return {
                ...state,
                contest_list: action.payload,
            };

        case "GET_CONTEST_INFO_FULFILLED":
            return {
                ...state,
                contest_info: action.payload,
            };

        case "GET_CONTEST_TASK_GROUPS_FULFILLED":
            return {
                ...state,
                contest_task_groups: action.payload,
            };

        case "GET_CONTEST_TASK_LIST_FULFILLED":
            return {
                ...state,
                contest_task_list: action.payload,
            };

        case "GET_CONTEST_TASK_FULFILLED":
            return {
                ...state,
                contest_task: action.payload,
            };
        case "GET_CONTEST_META_FULFILLED":
            return {
                ...state,
                contest_meta: action.payload,
            };

        case "GET_CONTEST_SECTIONS_FULFILLED":
            return {
                ...state,
                contest_sections: action.payload,
            };

        case "GET_CONTEST_TITLES_FULFILLED":
            return {
                ...state,
                contest_title_list: action.payload,
            };

        case "GET_CONTEST_PERMISSION_FULFILLED":
            return {
                ...state,
                contest_permission: action.payload.success,
            };

        default:
            return state;
    }
}
