import Axios                            from "axios";
import {UpdatePopup}                    from "Cheops/UpdatePopup";
import store                            from "Cheops/Store";
import {getUserInfo, redirectToAuth}    from "Cheops/actions/auth";
import {cluster}                        from "Cheops/../clusters/current";

let initAppVersion = null;
let actualAppVersion = null;

export default class Helpers {


    static assert(condition, errorMessage = "") {

        if (condition !== true) {

            throw new Error(errorMessage);

        }

    }


    static getImageUrl(hash, isSmt = false) {

        if (isSmt) {

            return `${CONFIG.Api.smtWebPortal.url}/content/_image/${hash}`;

        }

        return `${CONFIG.Api.noopolis.url}/content/_image/${hash}`;

    }

    static formatPlural(n, pluralForms) {

        if (n % 10 === 1 && n % 100 !== 11) {

            return pluralForms[0];

        }

        if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {

            return pluralForms[1];

        }

        return pluralForms[2];

    }


    static getInstanceTitleForCurrentDeploy = () => cluster.title;

    static getHumanReadableSize(bytes) {

        if (bytes < 1024) {

            return `${bytes}Б`;

        }

        const kbytes = (bytes / 1024).toFixed(0);

        if (kbytes < 1024) {

            return `${kbytes}Кб`;

        }

        const mbytes = bytes / 1024;

        if (mbytes < 1024) {

            return `${mbytes}Мб`;

        }


        return `${mbytes}Мб`;

    }

    // eslint-disable-next-line consistent-return
    static formatDuration(seconds, exactly = false) {

        seconds = Math.floor(seconds);

        if (seconds < 60) {

            return `${seconds} сек.`;

        }

        if (seconds < 60 * 60) {

            if (exactly) {

                return `${Math.floor(seconds / 60)} мин. ${seconds % 60} сек.`;

            }

            return `${Math.floor(seconds / 60)} мин.`;

        }


        if (seconds < 60 * 60 * 60) {

            if (exactly) {

                let hours = Math.floor(seconds / 60 / 60);
                let minutes = Math.floor(seconds / 60 - (hours * 60));
                // eslint-disable-next-line no-shadow
                let seconds = Math.floor(seconds - (hours * 60 * 60) - (minutes * 60));

                return `${hours} ч. ${minutes} мин. ${seconds} сек.`;

            }

            return `${Math.floor(seconds / 60 / 60)} ч.`;

        }


    }

    /**
     * @deprecated use renderErrorPage instead
     */
    static async render500() {

        let new_html = await Axios.get('/500.html');
        document.open();
        document.write(new_html.data);
        document.close();

    }

    static async renderErrorPage(error_code) {

        if (![403, 404, 500, 600].includes(error_code)) {

            throw Error('unexpected error code');

        }

        let new_html = await Axios.get(`/${error_code}.html`);
        document.open();
        document.write(new_html.data);
        document.close();

        window.addEventListener('hashchange', () => {

            location.reload();

        }, false);

    }


    static downloadFile(filename, content) {

        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }

    static copyToClipboard(string) {

        const selection = document.getSelection();

        if (selection.rangeCount > 0) {

            selection.removeAllRanges();

        }

        const range = document.createRange();

        const span = document.createElement("span");
        span.textContent = string;
        span.style.all = "unset";
        span.style.position = "fixed";
        span.style.top = 0;
        span.style.clip = "rect(0, 0, 0, 0)";
        span.style.whiteSpace = "pre";
        span.style.webkitUserSelect = "text";
        span.style.MozUserSelect = "text";
        span.style.msUserSelect = "text";
        span.style.userSelect = "text";

        if (navigator?.clipboard) {

            navigator?.clipboard?.writeText(string);

            return;

        } else {
            document.querySelector('body').appendChild(span);

            range.selectNodeContents(span);
            selection.addRange(range);

            const successful = document.execCommand("copy");
            document.querySelector('body').removeChild(span);

            if (!successful) {

                throw new Error("copy command was unsuccessful");

            }
        }
    }


    static getVideoIframeUrl(url) {

        let yt_match = url.match(/http(s):\/\/(www\.)?youtube.com\/watch\?.*v=([^&]+)/);

        if (yt_match) {

            return `https://www.youtube.com/embed/${yt_match[3]}?`
                + `rel=0&`
                + `showinfo=0&`
                + `modestbranding=1&`
                + `color=white`;

        }

        let yt_short_match = url.match(/http(s)?:\/\/(www\.)?youtu.be\/([^/]+)/);

        if (yt_short_match) {

            return `https://www.youtube.com/embed/${yt_short_match[3]}?`
                + `rel=0&`
                + `showinfo=0&`
                + `modestbranding=1&`
                + `color=white`;

        }

        return null;

    }

    static isParentMode() {

        const roles = getUserInfo('roles');

        return !!(roles && (roles.includes('nooRO') || roles.includes('i:nooRO')));

    }

    static redirectToAuthIfAnon() {

        let token = store.getState().authReducer.auth_token;

        if (!token) {

            redirectToAuth();

        }

    }

    static renderParentModeBar() {


        let bar_el = document.querySelector('.parent_mode_text');

        if (bar_el) {

            bar_el.parentNode.removeChild(bar_el);

        }

        const roles = getUserInfo('roles');

        if (roles && roles.includes('nooRO') && !roles.includes('i:nooRO')) {

            document.getElementById("index").insertAdjacentHTML(
                'afterend',
                `<div class="parent_mode_text">${cluster.parent.mode_text}</div>`
            );

        }

    }

    static getStartEndDateStr(start_date = null, finish_date = null) {


        if (start_date && !(start_date instanceof Date)) {

            start_date = new Date(start_date);

        }

        if (finish_date && !(finish_date instanceof Date)) {

            finish_date = new Date(finish_date);

        }

        if (!start_date && !finish_date) {

            return '';

        }

        let start_date_options = {day: "numeric", month: "long", year: "numeric"};
        let finish_date_options = {day: "numeric", month: "long", year: "numeric"};

        if (start_date && !finish_date) {

            return start_date.toLocaleString('ru', start_date_options);

        }

        if (finish_date && !start_date) {

            return finish_date.toLocaleString('ru', finish_date_options);

        }

        start_date_options = {day: "numeric"};

        if (start_date.getFullYear() !== finish_date.getFullYear()) {

            start_date_options = {day: "numeric", month: "long", year: "numeric"};

        } else if (start_date.getMonth() !== finish_date.getMonth()) {

            start_date_options = {day: "numeric", month: "long"};

        } else if (start_date.getDate() === finish_date.getDate()) {

            return finish_date.toLocaleString('ru', finish_date_options);

        }


        return `${start_date.toLocaleString('ru', start_date_options)} — ${finish_date.toLocaleString('ru', finish_date_options)}`;

    }

    static timeCounter(rawTime, excludeDays = false, isShort = false) {

        let result_time = rawTime;

        if (typeof result_time === 'string') {

            result_time = new Date(rawTime);

        }

        let now = new Date();
        let time_text = "";

        if (now.getTime() > result_time.getTime()) {

            let seconds_expired = Math.floor((now.getTime() - result_time.getTime()) / (1000));

            if (seconds_expired < 1) {

                seconds_expired = 1;

            }

            time_text = `${seconds_expired} c.`;

        }


        if ((now.getTime() - result_time.getTime()) > (60 * 1000)) {

            let minutes_expired = Math.floor((now.getTime() - result_time.getTime()) / (1000 * 60));
            time_text = `${minutes_expired} мин.`;

        }


        if ((now.getTime() - result_time.getTime()) > (60 * 60 * 1000)) {

            let time_expired = Math.floor((now.getTime() - result_time.getTime()) / (1000 * 60 * 60));
            time_text = `${time_expired} ч.`;

        }

        if (!excludeDays && (now.getTime() - result_time.getTime()) > (24 * 60 * 60 * 1000)) {

            let time_expired = Math.floor((now.getTime() - result_time.getTime()) / (1000 * 60 * 60 * 24));
            time_text = `${time_expired} д.`;

        }

        if (!isShort) {

            time_text += " назад";

        }

        // let start_of_the_month = new Date(now.getFullYear(), now.getMonth(), 1);
        //
        // if (start_of_the_month > result_time.getTime()) {
        //     time_text = result_time.toLocaleString('ru', {day: "numeric", month: "long"});
        // }

        return time_text;

    }

    static cropString(str, length, keepUnbrokenWords = true) {

        if (str.length <= length) {

            return str;

        }

        str = str.substr(0, length);

        if (keepUnbrokenWords) {

            let last_index = str.lastIndexOf(" ");

            if (last_index !== -1) {

                str = str.substr(0, last_index);

            }

        }

        return `${str}...`;

    }

    static runCheckVersionInterval() {

        setInterval(() => {

            if ((initAppVersion && actualAppVersion) && initAppVersion !== actualAppVersion) {

                UpdatePopup();

            }

        }, CONFIG.CheckVersionIntervalMin ? CONFIG.CheckVersionIntervalMin * 60000 : 14400000);

    }

    static setActualVersion(response) {

        if (!response.headers || typeof response.headers !== 'object') {

            return;

        }

        const filtered = Object.keys(response.headers).filter((item) => item.toLowerCase() === 'x-version');

        if (response.headers[filtered]) {

            actualAppVersion = response.headers[filtered];

        }

        if (!!actualAppVersion && !initAppVersion) {

            initAppVersion = actualAppVersion;

        }

    }

    static getFixedNumbers = (value) => {

        // eslint-disable-next-line prefer-template
        let match = ('' + value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        let count = 0;

        if (match) {

            count = Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? match[2] : 0));

        }

        return Number(value).toFixed(count);

    };

    static getExpiredTime = (time, isShort = false) => {

        let expiredTimeFormatted = "";

        if (time) {

            let expiredTime = new Date(time);
            let today = new Date();
            let todayStart = today.setHours(0, 0, 0, 0);
            let yestStart = today.setHours(0, 0, 0, 0) - (24 * 60 * 60 * 1000);
            let beforeYestStart = today.setHours(0, 0, 0, 0) - (2 * 24 * 60 * 60 * 1000);

            expiredTimeFormatted = ` в ${expiredTime.toLocaleString('ru', {
                hour: "2-digit",
                minute: "2-digit",
            })}`;

            if (expiredTime < todayStart && expiredTime >= yestStart) {

                expiredTimeFormatted = `вчера ${!isShort ? expiredTimeFormatted : ''}`;

            }

            if (expiredTime < yestStart && expiredTime >= beforeYestStart) {

                expiredTimeFormatted = `позавчера ${!isShort ? expiredTimeFormatted : ''}`;

            }

            let monthFormat = "long";

            if (isShort) {

                monthFormat = "short";

            }

            if (expiredTime < beforeYestStart) {

                let questionDate = expiredTime.toLocaleString('ru', {
                    day: "numeric",
                    month: monthFormat,
                });


                if (isShort) {

                    questionDate = questionDate.replace(".", " ");

                }

                expiredTimeFormatted = `${questionDate} ${!isShort ? expiredTimeFormatted : ''}`;

            }

        }

        return expiredTimeFormatted;

    };

    static getAllExtDependencies = (moduleId, moduleList, reverse = false) => {

        const module_obj = moduleList[moduleId];

        const depsArray =
            module_obj
                ? reverse
                    ? module_obj.reverseDependencies
                    : module_obj.dependenciesExt
                : void(0)
        ;

        if (!module_obj || !depsArray) {

            return [];
        }

        const extDependencies = depsArray.map((v) => (v.type === 'extension' ? v.id : null)).filter((v) => v);

        let add_dependencies = [];

        for (let dependencyId of extDependencies) {

            add_dependencies = add_dependencies.concat(this.getAllExtDependencies(dependencyId, moduleList, reverse));

        }

        return add_dependencies.concat(extDependencies);

    }

}

Object.compare = function (obj1, obj2) {

    // Loop through properties in object 1
    for (let p in obj1) {

        // Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            // Deep compare objects
            case 'object':
                if (!Object.compare(obj1[p], obj2[p])) return false;
                break;
            // Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            // Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }

    }

    // Check object 2 for any extra properties
    for (let p in obj2) {

        if (typeof (obj1[p]) === 'undefined') return false;

    }

    return true;

};


/**
 * @deprecated use Object.compare instead
 */
/*Array.prototype.equals = function (array, ignore_sort) {

    console.warn('Array.prototype.equals is deprecated! Use Object.compare instead ');

    // eslint-disable-next-line no-bitwise
    ignore_sort |= false;

    // if the other array is a falsy value, return
    if (!array) {

        return false;

    }

    // compare lengths - can save a lot of time
    if (this.length !== array.length) {

        return false;

    }

    for (let i = 0, l = this.length; i < l; i++) {

        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {

            // recurse into the nested arrays
            if (!this[i].equals(array[i], ignore_sort)) {

                return false;

            }

        } else {

            // eslint-disable-next-line no-lonely-if
            if (ignore_sort && this.indexOf(array[i]) === -1) {

                return false;


                // eslint-disable-next-line no-else-return,padded-blocks
            } else if (!ignore_sort && this[i] !== array[i]) {

                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;

            }

        }

    }

    return true;

};

Array.prototype.clone = function () {

    function cloneArr(arr) {

        let i;
        let copy;

        if (Array.isArray(arr)) {

            copy = arr.slice(0);
            for (i = 0; i < copy.length; i++) {

                copy[i] = cloneArr(copy[i]);

            }

            return copy;

        }

        if (typeof arr === 'object') {

            return {...arr};

        }

        return arr;

    }

    return cloneArr(this);

};*/


// Hide method from for-in loops

/**
 * @deprecated use Object.compare instead
 */
Object.defineProperty(Array.prototype, "equals", {enumerable: false});
Object.defineProperty(Array.prototype, "clone", {enumerable: false});


if (!('path' in Event.prototype)) {

    Object.defineProperty(Event.prototype, 'path', {
        get() {

            const path = [];
            let currentElem = this.target;
            while (currentElem) {

                path.push(currentElem);
                currentElem = currentElem.parentElement;

            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document);
            if (path.indexOf(window) === -1) path.push(window);
            return path;

        },
    });

}
