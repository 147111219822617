import React                    from "react";
import {render}                 from "react-dom";
import {ReqResponseError}       from "Lab/Types";
import store                    from "Lab/Store";
import
    SystemNotification,
    {
        SystemNotificationData,
        SystemNotificationType
    }                           from "Lab/SystemNotifications/SystemNotification";
import UIElementWrapper         from "Lab/components/UI2/UIElementWrapper";
import                               "./SystemNotifications.less";

let notifications: SystemNotificationData[] = [];

const wrapperEl = document.getElementById("notification_block");


export const getLargeFileErrorMsg = () => {
    const limit   = store.getState().labReducer.config.fileSizeLimitBytes;
    const limitMb = Math.round(limit / (1024 * 1024));
    return `Невозможно загрузить файл. Файл слишком большой. Уменьшите размер загружаемого файла до ${limitMb} Мб`;
};


export default class SystemNotifications {
    static renderNotification(messages: ReqResponseError[] | string, type = SystemNotificationType.info): void {
        if (typeof messages === "string") {
            const value: ReqResponseError = {
                message: messages,
            };
            notifications.push({value, type});
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            renderNotifications();
            return;
        }

        for (let value of messages) {
            notifications.push({value, type});
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        renderNotifications();
    }

    static closeNotifications(): void {
        if (notifications.length > 0) {
            notifications = [];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            renderNotifications();
        }
    }

    static closeNotification(key: number): void {

        notifications.splice(key, 1);

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        renderNotifications();

    }

}

const renderNotifications = (): void => {
    render(
        <>
            {
                notifications.map(
                    (notification, key) =>
                        <UIElementWrapper key={key} singleton>
                            <SystemNotification
                                {...notification}
                                // links doesn't work without timeout
                                onClick={
                                    () => setTimeout(
                                        () => SystemNotifications.closeNotification(key)
                                    )
                                }
                            />
                        </UIElementWrapper>
                )
            }
        </>,
        wrapperEl
    );
};


if (wrapperEl) {

    window.addEventListener('hashchange', () => {

        SystemNotifications.closeNotifications();

    });

}
