import ModalWindow from "Lab/components/UI2/ModalWindow/ModalWindow";
import React, { Fragment } from "react";
import AspectElementsGroup from "Lab/components/UI2/Elements/AspectElementsGroup";
import TextControl from "Lab/components/UI2/Controls/TextControl";
import {unmountComponentAtNode, render} from "react-dom";

export type ModalPopupType = {
    contentTitle: string;
    content: {
        style: "text" | "caption";
        text: string;
    }[];
    text?: string;
};
type Props = ModalPopupType & {
    onClose?(): void;
};

export default class ModalPopup extends React.Component<Props> {

    public static showPopup = (popup: ModalPopupType): void => {

        const {contentTitle, content} = popup;


        const wrapperEl = document.createElement("div");

        const close = (): void => {

            unmountComponentAtNode(wrapperEl);

        };

        render(
            <ModalWindow
                title={contentTitle}
                onClose={close}
            >
                <AspectElementsGroup>
                    {content.map((v) => {

                        return <>
                            {v.style === "caption" && <TextControl caption>
                                {v.text}
                            </TextControl>}
                            {v.style === "text" && <TextControl wrap>
                                {v.text}
                            </TextControl>}
                        </>;

                    })}
                </AspectElementsGroup>

            </ModalWindow>,
            wrapperEl,
        );

    };

    render(): React.ReactNode {

        const {contentTitle, content, onClose} = this.props;

        return <ModalWindow
            title={contentTitle}
            onClose={onClose}
        >
            <AspectElementsGroup>
                {content.map((v, i) => {

                    return <Fragment key={i}>
                        {v.style === "caption" && <TextControl caption>
                            {v.text}
                        </TextControl>}
                        {v.style === "text" && <TextControl wrap>
                            {v.text}
                        </TextControl>}
                    </Fragment>;

                })}
            </AspectElementsGroup>

        </ModalWindow>;

    }

}
