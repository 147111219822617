/* eslint-disable */
import Axios from "axios";
import * as auth_actions from "Cheops/actions/auth";
import Helpers from "Cheops/Helpers";
import SystemNotifications from "Lab/SystemNotifications/SystemNotifications";
import {registerURL, unregisterURL} from "@sirius/ui-lib/src";
import SystemNotificationsDialogue from "Lab/SystemNotifications/SystemNotificationsDialogue";
import store from "./Store";

const stopPromise = new Promise(() => void(0));

export const passSuccess = (result) => 'success' in result ? result.success : stopPromise;

const {Instance} = CONFIG;

export default class AjaxWrapper {

    static get    = (url, data = {}, params = {}) => AjaxWrapper.request( url, data, {...params, method:'GET'    });
    static post   = (url, data = {}, params = {}) => AjaxWrapper.request( url, data, {...params, method:'POST'   });
    static delete = (url, data = {}, params = {}) => AjaxWrapper.request( url, data, {...params, method:'DELETE' });
    static put    = (url, data = {}, params = {}) => AjaxWrapper.request( url, data, {...params, method:'PUT'    });
    static patch  = (url, data = {}, params = {}) => AjaxWrapper.request( url, data, {...params, method:'PATCH'  });

    static request(arg_url, data = {}, {headers: arg_headers, ...arg_params} = {}) {

        const default_params = {
            method:                'GET',
            send_auth_token:        false,
            keep_errors:            false,
            dataHandler:            null,
            hideErrorNotifications: false,
        };

        const params = {
            ...default_params,
            ...arg_params,
        };

        const default_headers = {
            'Content-Type': 'application/json',
            'Accept':       'application/json',
        };

        const auth_token = store?.getState()?.authReducer?.auth_token;

        const getAuthHeaders = ({ send_auth_token }) =>
            auth_token && send_auth_token
                ? { Authorization: `Bearer ${auth_token}` }
                : null
        ;

        const getHeaders = params => ({
            ...default_headers,
            ...arg_headers,
            ...getAuthHeaders( params )
        });

        const getUrl = ({url, data, method}) => {
            const currentOrigin = document.location.origin;
            let url_obj = new URL(url.startsWith('/') ? `${currentOrigin}${url}` : url );
            if (method === 'GET' && typeof data === 'object') {
                Object
                    .entries(data)
                    .filter( ([,v])=> v !== null )
                    .forEach( entry => url_obj.searchParams.append(...entry) )
                ;
            }
            const _href = url_obj.href;
            const res   = !_href.startsWith(currentOrigin) ? _href.replace( url_obj.origin, currentOrigin ) : _href;
            return res;
        }

        const headers = getHeaders( params );

        const {method:_method, keep_errors} = params;

        const method = `${_method}`.toUpperCase();

        const url = getUrl({url: arg_url, data, method});

        const request = { ...params, method, headers, data, url };

        if (method !== 'GET' && Instance === 'lab' && !keep_errors) {
            SystemNotifications.closeNotifications();
        }

        const axiosWrapper = AjaxWrapper.axios(request);

        if (method !== 'GET') {
            registerURL( url );
            axiosWrapper.finally( () => unregisterURL(url) );
        }

        return axiosWrapper;

    }

    static axios = params =>
        new Promise(
            (resolve, reject) =>
                Axios(params)
                    .then(
                        resultHandler( params, resolve, reject ),
                        errorHandler( reject )
                    )
        )
    ;
}

const errorHandler = reject => error => {
    const {response} = error;
    if (response) {
        auth_actions.redirectToAuthIf401(response);

        const {status} = response;

        if (status >= 500 && status < 600) {
            Helpers.renderErrorPage(500);
        }

        if (status === 400) {
            Helpers.renderErrorPage(500);
        }

        if (status === 404) {
            Helpers.renderErrorPage(404);
        }

        if (status === 403) {
            Helpers.renderErrorPage(403);
        }
    }
    reject(error);
    //console.error(error);
}

const detectDownload = (headers) => {
    return Object.entries(headers)
        .reduce(
            (result, [header, headerValue]) => {
                if (header.toLowerCase() === 'content-disposition') {
                    return headerValue;
                } else {
                    return result;
                }
            }, false
        )
}

const parseFilename = (disposition) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
    }
}

const downloadFileByLink = (filename, content) => {
    const element = document.createElement('a');
    element.setAttribute('href',     content);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const resultHandler = (config, resolve, reject) => result => {
    const {data, headers: resultHeaders} = result;
    const download = detectDownload(resultHeaders);

    //console.log(">", {data, resultHeaders, result})

    if (download && download.indexOf('attachment') !== -1) {
        const filename = parseFilename(download);
        if (filename) {
            if (data instanceof Blob) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    downloadFileByLink(filename, reader.result, data.type);
                    resolve();
                }
                reader.readAsDataURL(data);
            } else {
                Helpers.downloadFile(
                    filename,
                    data
                );
                resolve();
            }
            return;
        }
    }

    const {error: maybeError,     errors: maybeErrorsList,     warning: maybeWarning,     dialogue: maybeDialogue, data: maybeData} = data;
    const {error: maybeDataError, errors: maybeDataErrorsList, warning: maybeDataWarning, dialogue: maybeDataDialogue} = maybeData || {};
    const {method, hideErrorNotifications, dataHandler} = config;

    const error    = maybeError    || maybeErrorsList || maybeDataError || maybeDataErrorsList;
    const warning  = maybeWarning  || maybeDataWarning;
    const dialogue = maybeDialogue || maybeDataDialogue;
    let labShowError = error && Instance === 'lab' && !hideErrorNotifications

    if (method !== 'GET') {
        Helpers.setActualVersion(result);
    }

    if (labShowError) {
        SystemNotifications.renderNotification(error, 'error');
    }

    if (warning && Instance === 'lab') {
        SystemNotifications.renderNotification(warning, 'warning');
    }

    auth_actions.updateTokenFromResponse(result);

    if (dialogue && Instance === 'lab') {
        return SystemNotificationsDialogue(dialogue, config, resolve, () => resolve({}));
    }

    if (labShowError) {
        reject(error);
    } else if (typeof dataHandler === 'function') {
        resolve( dataHandler( data ) );
    } else if (download) {
        /* skip resolving */
    } else {
        resolve( data );
    }
}
