import React, {useEffect, useState}                 from "react";
import {
    SmtPortal_POST_test_results_testsReport,
    SmtPortal_POST_test_results_testsReport_report
}                                                   from "Smt/subjects/ContestOnline";
import {GrowGap, HBox, SGap, VBox}                  from "@sirius/ui-lib/src/blocks/Layout";
import {getLanguagePickerName}                      from "@sirius/ui-shared/src/components/Programming";
import {OnlineVerdictSolutionTM, Score, Solution}   from "Smt/SmtProgrammingAnswer/OnlineVerdicts";
import {postBugReport}                              from "Smt/actions/webPortal";
import {block}                                      from "Smt/SmtProgrammingAnswer/block";
import {SmtLocaleString}                            from "Smt/SmtLocaleBlock";
import {getContestIdAndSesionId}                    from "Smt/helpers";
import {REPORT_BUG_LVL, REPORT_INITIATOR}           from "Cheops/constants";
import {HistorySolutionProps}                       from "./block";


const HistorySolution = ({verdict = 'notReady', num, solution, time, score, maxScore, reportRef, taskNo, review}: HistorySolutionProps) => {

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [reviewValue, setReviewValue] = useState<SmtPortal.TestResultsHistoryItemReview | SmtPortal.ProgrammingReview>(null);

    const summaryValue = reportRef ? reportRef.summary : null;

    useEffect(() => {
        try {
            setReviewValue('message' in review ? review : review.comment);
        }
        catch (err) {
            const {contestId, sessionId} = getContestIdAndSesionId();
            const reportData  = {
                error: `Session ${sessionId} / contestId ${contestId} has tasks in not_ready state`,
                level: REPORT_BUG_LVL.ERROR,
                meta: {
                    url: location.href,
                },
                initiator: REPORT_INITIATOR.FRONT,
            } as BugAPI.BugRequest;

            postBugReport(reportData, window?.bugReportToken)
        }
    }, [review]);

    const testReport = () => SmtPortal_POST_test_results_testsReport(
            {taskNo, submissionId: reportRef.solutionId},
            `${window.results_token}`
        )
            .then((res) =>
                new Promise <Common.Programming.ReviewTestsReportResponse> ( (resolve, reject) => {
                    const {status, data} = res;
                    (status === 200 && data)
                        ? resolve(data)
                        : reject(status);
                })
            )
    ;

    const testReportDetailed = (testNo: string) => SmtPortal_POST_test_results_testsReport_report(
        {taskNo, submissionId: reportRef.solutionId, testNo},
        `${window.results_token}`
    )
        .then((res) =>
            new Promise <Common.Programming.ReviewTestProtocolResponse> ( (resolve, reject) => {
                const {status, data} = res;
                (status === 200 && data)
                    ? resolve(data)
                    : reject(status);
            })
        )
    ;

    return (
        <VBox
            className={
                block.el('verdicts-card') +
                block.el('verdicts-card').bod('ok',  verdict === 'ok') +
                block.el('verdicts-card').bod('notok', verdict !== 'ok' && verdict !== 'partly' && verdict !== 'annulled') +
                block.el('verdicts-card').bod('partly', verdict === 'partly') +
                block.el('verdicts-card').bod('annulled', (verdict === 'annulled'))
            }
        >
            <HBox className = { block.el('verdicts-head') +
                                block.el('verdicts-card').mod('clickable')}
                  onClick   = { () => setShowDetails(!showDetails) }
            >
                <b>
                    <SmtLocaleString k="programming.onlineverdicts.solutionHeader"/>
                    {` ${num}, `}
                    {
                        solution
                        && `${getLanguagePickerName(solution[0].solution.language)}. `
                    }
                </b>
                <span className={block.el('verdicts-string')}>
                    <OnlineVerdictSolutionTM value={time * 1000}/>
                </span>
                <GrowGap/>
                { (score !== void(0) && maxScore !== void(0)) &&
                    <>
                        <Score {...{score, maxScore}}/>
                        {'.'}
                        <SGap/>
                    </>
                }
                <span className={ block.el('verdicts-score') }>
                     <SmtLocaleString k={`common.verdict_text.${verdict}`}/>
                </span>
            </HBox>

            {
                showDetails &&
                <VBox className={block.el('verdicts-code')}>
                    {   solution &&
                    <Solution
                        solution={solution[0].solution}
                        clickable = {false}
                        summary={summaryValue}
                        TestDetailReportCb={testReportDetailed}
                        TestsReportCb={testReport}
                        review={reviewValue as SmtCache.ProgrammingReview}
                    />
                    }
                </VBox>
            }
        </VBox>
    );
};

export default HistorySolution;
