/* eslint-disable */
export default function (state = {
    selected_element_num: 0
}, action) {

    switch (action.type) {
        case "CHANGE_SELECTED_ELEMENT_NUM":
            return {
                ...state,
                selected_element_num: action.status,
            };
        default:
            return state
    }
}
