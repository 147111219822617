import React from "react";
import {
    Coordinate,
    TaskAnswerFigure, TaskAnswerType,
    ZoneDisplayMethod,
    ZoneType,
} from "Cheops/Model/Task";
import {MultiUserAnswer, SingleUserAnswer} from "Cheops/Model/UserAnswer";
import './SelectOnImageAnswer.less';
import BemClassName from "Cheops/BemClassName";
import {MAX_TASK_CONTENT_WIDTH as CHEOPS_MAX_TASK_CONTENT_WIDTH} from "Cheops/constants";
import {MAX_TASK_CONTENT_WIDTH as SMT_MAX_TASK_CONTENT_WIDTH} from "Smt/constants";
import {ElementVerdict} from "Cheops/Model/Element";
import {TaskAnswersData} from "Lab/Model/Task";
import cloneDeep from "lodash/cloneDeep";
import Assert from "Cheops/Utils/Assert";
import UserAnswerAdapter from "Cheops/components/ModulePassing/Answers/UserAnswerAdapter";


type State = {

    svgViewBox: string;
    mouseOnZoneId: number;
};

type Props = {

    answersData: TaskAnswersData;
    isResult?: boolean;
    resultVerdict?: ElementVerdict;
    readOnly?: boolean;
    isSmt?: boolean;
    answerType?: TaskAnswerType;
    alignLeft?: boolean;
    userAnswer: MultiUserAnswer | SingleUserAnswer;
    onAnswer?(userAnswer: Props['userAnswer']): void;

};


type PolygonBoundingBox = {

    coordinates: Coordinate[];
    width: number;
    height: number;

};

export default class SelectOnImageAnswer extends React.Component<Props> {

    static defaultProps = {
        userAnswer: "",
        isSmt: false,
        readOnly: false,
        isResult: false,
        alignLeft: false,
        resultVerdict: ElementVerdict.NONE,
        onAnswer: () => {

        },
    };

    state: State = {
        svgViewBox: "0 0 0 0",
        mouseOnZoneId: null,
    };

    imageRef: HTMLImageElement;

    getIsAllowMulti = (): boolean => {

        const {answerType} = this.props;

        return answerType === TaskAnswerType.MULTI;

    };


    getIsAnswerFilled(): boolean {

        const allowMulti = this.getIsAllowMulti();

        const userAnswer = this.getUserAnswer();

        if (allowMulti) {

            Assert(Array.isArray(userAnswer));

            return userAnswer.length > 0;

        }

        Assert(!Array.isArray(userAnswer));

        return Number.isFinite(userAnswer);

    }

    getAnswerHasError = (): boolean => false;


    getUserAnswer(): Props['userAnswer'] {

        const allowMulti = this.getIsAllowMulti();

        const userAnswer = UserAnswerAdapter.selectOptionAnswer(this.props.userAnswer, allowMulti);

        if (allowMulti && !Array.isArray(userAnswer)) {

            return [];

        }

        return cloneDeep(userAnswer);

    }

    getImageUrl(): string {

        const {isSmt, answersData} = this.props;


        let urlPrefix = '';


        if (isSmt) {

            if (CONFIG.Api.smtWebPortal) {

                urlPrefix = CONFIG.Api.smtWebPortal.url;

            } else {

                urlPrefix = CONFIG.Api.webPortal.url;

            }

        } else {

            urlPrefix = CONFIG.Api.noopolis.url;

        }

        return `${urlPrefix}/content/_image/${answersData.image}`;

    }

    getZoneBoundingBox(figure: LabSmt.Figure): PolygonBoundingBox {

        const coords = figure.coordinates;

        let minX = coords[0].x;
        let minY = coords[0].y;

        let maxX = coords[0].x;
        let maxY = coords[0].y;

        for (const coord of coords) {

            if (coord.x > maxX) {

                maxX = coord.x;

            }

            if (coord.x < minX) {

                minX = coord.x;

            }

            if (coord.y > maxY) {

                maxY = coord.y;

            }

            if (coord.y < minY) {

                minY = coord.y;

            }

        }


        if (figure.type === ZoneType.circle) {

            minX -= figure.radius;
            minY -= figure.radius;
            maxX += figure.radius;
            maxY += figure.radius;

        }

        return {
            coordinates: [
                {x: minX, y: minY},
                {x: maxX, y: minY},
                {x: maxX, y: maxY},
                {x: minX, y: maxY},
            ],
            width: maxX - minX,
            height: maxY - minY,
        } as PolygonBoundingBox;

    }

    zoneClick = (answerId: number): void => {

        const {onAnswer, isResult} = this.props;

        const allowMulti = this.getIsAllowMulti();
        const userAnswer = this.getUserAnswer();

        if (isResult) {

            return;

        }

        if (!allowMulti) {

            if (userAnswer === answerId) {

                onAnswer(null);
                return;

            }

            onAnswer(answerId);
            return;

        }

        if (!Array.isArray(userAnswer)) {

            throw Error("userAnswer must be an array");

        }

        const answerIndex = userAnswer.indexOf(answerId);

        if (answerIndex === -1) {

            userAnswer.push(answerId);

        } else {

            userAnswer.splice(answerIndex, 1);

        }

        userAnswer.sort();

        if (userAnswer.length === 0) {

            onAnswer(null);
            return;

        }

        onAnswer(userAnswer);

    };

    getZoneImagePath(answerId: LabSmt.AnswerItem['id']): string {

        const {isResult, resultVerdict} = this.props;
        const {mouseOnZoneId} = this.state;

        const userAnswer = this.getUserAnswer();


        if (isResult) {

            if (((userAnswer !== answerId) && !Array.isArray(userAnswer)) || (Array.isArray(userAnswer) && !userAnswer.includes(answerId))) {

                return require("Smt/../img/select_on_image_radio.svg");

            }

            if (resultVerdict === ElementVerdict.OK) {

                return require("Smt/../img/select_on_image_radio_right.svg");

            }

            if (resultVerdict === ElementVerdict.PARTLY) {

                return require("Smt/../img/select_on_image_radio_partly.svg");

            }

            return require("Smt/../img/select_on_image_radio_wrong.svg");

        }

        if (userAnswer === answerId || (Array.isArray(userAnswer) && userAnswer.includes(answerId))) {

            return require("Smt/../img/select_on_image_radio_checked.svg");

        }


        if (mouseOnZoneId === answerId) {

            return require("Smt/../img/select_on_image_radio_hover.svg");

        }

        return require("Smt/../img/select_on_image_radio.svg");

    }

    updateSVGDimensions = (): void => {

        const {isSmt} = this.props;

        let maxContentWidth = CHEOPS_MAX_TASK_CONTENT_WIDTH;

        if (isSmt) {

            maxContentWidth = SMT_MAX_TASK_CONTENT_WIDTH;

        }

        let width = this.imageRef.naturalWidth;
        let height = this.imageRef.naturalHeight;

        if (width > maxContentWidth) {

            height = Math.round(height / (width / maxContentWidth));
            width = maxContentWidth;

        }

        const svgViewBox = `0 0 ${width} ${height}`;

        this.setState({svgViewBox});

    };


    setMouseOnZone(mouseOnZoneId: number): void {

        this.setState({mouseOnZoneId});

    }

    renderZone = (answer: LabSmt.AnswerItem): React.ReactNode => {

        const {answersData, isResult} = this.props;

        const allowMulti = this.getIsAllowMulti();
        const userAnswer = this.getUserAnswer();


        const zoneClassName = new BemClassName('select_on_image_answer__zone');

        const boundingBox = this.getZoneBoundingBox(answer.figure);

        zoneClassName.appendStatusIf(allowMulti, 'like-radio');
        zoneClassName.appendStatusIf(!allowMulti, 'like-checkbox');
        zoneClassName.appendStatusIf(isResult, 'result');
        zoneClassName.appendStatus(`display-method-${answersData.zoneDisplayMethod}`);


        let zone = null;

        if (answer.figure.type === ZoneType.circle) {


            zone = <circle
                className={zoneClassName.toString()}
                cx={answer.figure.coordinates[0].x}
                cy={answer.figure.coordinates[0].y}
                r={answer.figure.radius}
            />;

        }

        if (answer.figure.type === ZoneType.rectangle) {

            zone = <rect
                className={zoneClassName.toString()}
                x={answer.figure.coordinates[0].x}
                y={answer.figure.coordinates[0].y}
                width={answer.figure.coordinates[2].x - answer.figure.coordinates[0].x}
                height={answer.figure.coordinates[2].y - answer.figure.coordinates[0].y}
            />;

        }

        if (answer.figure.type === ZoneType.polygon) {

            const points = answer.figure.coordinates.map((c) => `${c.x},${c.y}`).join(" ");

            zone = <polygon
                className={zoneClassName.toString()}
                points={points}
            />;

        }


        let showCheck = false;


        if (answersData.zoneDisplayMethod === ZoneDisplayMethod.full) {

            showCheck = true;

        }


        if (userAnswer === answer.id || (Array.isArray(userAnswer) && userAnswer.includes(answer.id))) {

            showCheck = true;

        }


        return <g
            key={answer.id}
            onClick={() => this.zoneClick(answer.id)}
            onMouseEnter={() => this.setMouseOnZone(answer.id)}
            onMouseLeave={() => this.setMouseOnZone(null)}
        >

            {zone}
            {showCheck
            && <image
                className="select_on_image_answer__zone_dot"
                xlinkHref={this.getZoneImagePath(answer.id)}
                x={boundingBox.coordinates[0].x + (boundingBox.width / 2) - 14}
                y={boundingBox.coordinates[0].y + (boundingBox.height / 2) - 14}
                width={28}
                height={28}
            />}
        </g>;

    };

    render(): React.ReactNode {

        const {answersData, alignLeft} = this.props;
        const {svgViewBox} = this.state;

        const wrapperClassname = new BemClassName('select_on_image_answer__image_wrapper');

        wrapperClassname.appendStatusIf(alignLeft, 'align-left');

        return <div className="select_on_image_answer">
            <div className={wrapperClassname.toString()}>
                <div>
                    <img
                        src={this.getImageUrl()}
                        onLoad={this.updateSVGDimensions}
                        ref={(ref) => this.imageRef = ref}
                    />
                    <svg viewBox={svgViewBox} xmlns="http://www.w3.org/2000/svg">
                        {answersData.answers.map((answer) => this.renderZone(answer))}
                    </svg>
                </div>
            </div>
        </div>;

    }

}
