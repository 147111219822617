import {i18nStr}                    from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {ELocales}                   from "@sirius/ui-lib/src/subjects/Locale";
import {Project, TranslationsType}  from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";
import {translateArgType}           from "Smt/Auth/@types";

const DEBUG = false;

const deepDelim = '/';

export const getTplByDeep = (deep: string[], translations: TranslationsType): Project => {
    for (let i = deep.length; i >= 2; i--) {
        const deepK = deep.slice( i * -1 ).join(deepDelim);
        const tpl = deepK in translations
            ? translations[deepK]
            : void(0)
        ;
        if (tpl) {
            return tpl;
        }
    }
    return void(0);
}

export const translate = ({ deep, src, locale = ELocales.ru, translations, values: _values = {} }: translateArgType) =>
    Object.entries(src).reduce(
        (acc, [k, v]) => {
            const i18nDeep = [...deep, k];
            const tpl = getTplByDeep(i18nDeep, translations);

            if (tpl === void(0)) {
                DEBUG && console.error('no tpl', i18nDeep)
            }

            const values = tpl && locale in tpl && 'values' in tpl[locale]
                ? tpl[locale]['values']
                : _values
            ;

            const mix = {
                [k]: tpl
                    ? i18nStr({locale, tpl, defaultMessage: v, values})
                    : v
            };
            return {
                ...acc,
                ...mix
            }
        },
        {}
    )
;
