import React, {useEffect, useState} from "react";
import {useParams}                  from "react-router-dom";
import bnc, {Bnc}                   from "bnc";
import {
    HBox,
    LGap,
    Tooltip,
    VBox,
    MGap
}                                   from "@sirius/ui-lib/src/blocks/Layout";
import {Icon}                       from "@sirius/ui-lib/src/blocks/Controls/Icon";
import { Text }                     from "@sirius/ui-lib/src/blocks/Controls";  
import {passSuccess}                from "Cheops/AjaxWrapper";
import AjaxWrapper                  from "Smt/AjaxWrapper";
import MarkedWithVideo              from "Smt/MarkedWithVideo";
import {SmtLocaleString}            from "Smt/SmtLocaleBlock";
import {getContestIdAndSesionId}    from "Smt/helpers";
import                                   "./DetailedAnswerComment.less";

const block = new bnc('answer-comment');

const PreviewCard = ({info, block}: {info: Record<string, string>, block: Bnc}) =>

    <a className = {block.el('preview')}
       key       = {info.token}
       download  = {info.name}
       href      = {`${CONFIG.Api.storage.url}/download/${info.token}`}
    >
        {
            info.preview
                ? <img className={block.el('preview-img')}
                       src={`${CONFIG.Api.storage.url}/download/${info.preview}`}/>
                : <div className={block.el('preview-file')}>
                    {`...${info.name.split(".").pop()}`}
                </div>
        }
    </a>
;

const PendingCommentIcon = () =>
    <div className={block.el('pending-icon')}>
        новый
    </div>
;

type Props = {
    review:          SmtPortal.TestResultItem["review"] | LabSmt.Contest.AnswersTable.CommentExtended;
    expandable?:     boolean;
    pending?:        boolean;
    disabled?:       boolean;
    getPreview?:     (contestId: string | number, hash: string) => Promise<any>;
    onCommentClick?: () => void;
};

const getFilePreview =  (contestId: string, hash: string) => AjaxWrapper.post(`${CONFIG.Api.webPortal?.url}/test/download-token/${contestId}/${hash}`);

export const DetailedAnswerComment = ({onCommentClick, review, expandable = false, pending = false, disabled = false, getPreview = getFilePreview}: Props) => {

    const {attachments, message}  = review || {attachments:[],message:''};
    
    const {contestId} = getPreview === getFilePreview
                                ? getContestIdAndSesionId()
                                : useParams<{ contestId: string }>()
    ;

    const [fileInfo, setFileInfo]     = useState<Record<string, string>[]>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (attachments.length > 0) {
            Promise.all(
                attachments.map((hash) =>
                    getPreview(contestId, hash)
                        .then(passSuccess)
                        .then(({info}) => info)
                )
            )
            .then((info) => setFileInfo(info));
        }

    }, [attachments])

    return (

        <VBox className={block + block.bod('expandable', expandable)}>

            {
                expandable
                ? <HBox className = { block.el('title') +
                                      block.el('title').mod('expandable') +
                                      block.el('title').bod('expanded', isExpanded)}
                        onClick   = {() => {setIsExpanded(!isExpanded)}}
                  >
                        <Icon icon = 'chat_bubble_outline'
                              size = 'xs'
                        />
                        <LGap/>
                        <Text className={block.el('title').bod('disabled', disabled)}>
                          Комментарий к оценке
                        </Text>
                        <MGap/>
                        <Icon icon      = {`expand_${isExpanded ? 'less' : 'more'}`}
                         />
                        {
                            pending &&
                            <PendingCommentIcon/>
                        }
                        {
                            disabled && 
                            <VBox onClick = {(e) => e.stopPropagation()}>
                                <Tooltip title     = 'Комментарий будет удалён после нажатия кнопки «Пересчитать»'
                                         className = {block.el('tooltip')}
                                >
                                    <div>
                                        <Icon icon      = 'help_outline'
                                              size      = 'xs'
                                              className = {block.el('tooltip-icon')}
                                        />
                                    </div>
                                </Tooltip>
                            </VBox>
                        }
                        
                  </HBox>

                : <>
                        <div className={block.el('title')}>
                            <SmtLocaleString k="common.detailed_answer.comment_title"/>
                        </div>
                        <div className={block.el('text')} onClick={onCommentClick}>
                            <MarkedWithVideo
                                videoWrapperClassName={block.el('text').mod('inner-video')}
                                onClick = {(e) => e.stopPropagation()}
                            >
                                {message}
                            </MarkedWithVideo>
                        </div>
                        {
                            fileInfo.length > 0 &&
                            <HBox className={block.el('preview-wrapper')}>
                                {
                                    fileInfo.map(
                                        (info) =>
                                            <PreviewCard
                                                info  = {info}
                                                block = {block}
                                                key   = {info.token}
                                            />
                                )}
                            </HBox>
                        }
                </>
            }
            {
                isExpanded &&
                <>
                    <div
                        className={
                            block.el('text') +
                            block.el('text').bod('disabled', disabled)
                        }
                        onClick={onCommentClick}
                    >
                        <MarkedWithVideo
                            videoWrapperClassName={block.el('text').mod('inner-video')}
                        >
                            {message}
                        </MarkedWithVideo>
                    </div>

                    {
                    fileInfo.length > 0 &&
                    <HBox
                        className={
                            block.el('preview-wrapper') +
                            block.el('preview-wrapper').bod('disabled', disabled)
                        }
                    >
                        {
                            fileInfo.map(
                                (info) =>
                                    <PreviewCard
                                        info={info}
                                        block={block}
                                        key={info.token}
                                    />
                            )
                        }
                    </HBox>
                    }
                </>
            }
        </VBox>
    );
};
