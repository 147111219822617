export default function (state = {
    auth_token: localStorage.getItem(CONFIG.Auth.localstorage_key) || null,
    user_list_data: {},
}, action) {
    switch (action.type) {
        case "SET_AUTH_TOKEN":
            return {
                ...state,
                auth_token: action.token
            };
        case "GET_USER_LIST_DATA_FULFILLED":

            let user_list_data = {};

            for (let i in action.payload.result) {
                user_list_data[action.payload.result[i].id] = action.payload.result[i]
            }

            return {
                ...state,
                user_list_data,
            };
        default:
            return state
    }
}
