export default function (state = {
    course_list: [],
    lecturer_list: [],
    course_data: [],
    env_graph_layout: {module_positions: []},
    env_graph_list: null,
    course_graph_layout: {module_positions: []},
    course_graph_list: null,
    env_list: null,
    course_modules_list: null,
    env_task: null,
    contest_task: null,
    env_video: null,
    env_text: null,
    config: {},
    unanswered_questions: [],
    environments_list: [],
    module_data: [],
    news_list: [],
    qa_list: [],
    environment_info: {},
    changed_news_list: [],
    env_module_data: {},
    env_module_version_list: {},
    env_module_version_data: {},
    approve_requests_list: [],
    course_users_grant: {},
    course_users: {},
    user_list_data: {},
    notes_about_user: [],
    env_scorer_list: [],
    widgets: [],
    permissions: [],
    personalConfigMainPage: {},
    course_module_info: {},
    qa_info: {},
    leftSidebarCollapsed: false,
}, action) {

    switch (action.type) {
        case "GET_ACCESSIBLE_COURSES_FULFILLED":
            return {
                ...state,
                course_list: action.payload,
            };
        case "GET_LECTURER_LIST_FULFILLED":
            return {
                ...state,
                lecturer_list: action.payload,
            };
        case "GET_COURSE_INFO_FULFILLED":
            return {
                ...state,
                course_data: action.payload,
            };
        case "GET_UNANSWERED_QUESTIONS_FULFILLED":
            return {
                ...state,
                unanswered_questions: action.payload,
            };
        case "GET_ENVIRONMENT_GRAPH_LAYOUT_FULFILLED":
            if (action.payload) {

                return {
                    ...state,
                    env_graph_layout: action.payload.layout,
                };

            }
            return state;
        case "GET_ENVIRONMENT_GRAPH_LIST_FULFILLED":
            return {
                ...state,
                env_graph_list: action.payload,
            };
        case "GET_COURSE_GRAPH_LAYOUT_FULFILLED":
            if (action.payload) {

                return {
                    ...state,
                    course_graph_layout: action.payload.layout,
                };

            }
            return state;
        case "GET_COURSE_GRAPH_LIST_FULFILLED":
            return {
                ...state,
                course_graph_list: action.payload,
            };
        case "GET_ENVIRONMENT_LIST_FULFILLED":
            return {
                ...state,
                env_list: action.payload,
            };
        case "GET_COURSE_MODULES_LIST_FULFILLED":
            return {
                ...state,
                course_modules_list: action.payload,
            };
        case "GET_ENVIRONMENTS_LIST_FULFILLED":
            return {
                ...state,
                environments_list: action.payload,
            };
        case "GET_ENVIRONMENT_INFO_FULFILLED":
            return {
                ...state,
                environment_info: action.payload,
            };
        case "GET_MODULE_INFO_FULFILLED":
            return {
                ...state,
                module_data: action.payload,
            };
        case "GET_NEWS_LIST_FULFILLED":
            return {
                ...state,
                news_list: action.payload,
            };
        case "GET_QA_LIST_FULFILLED":
            return {
                ...state,
                qa_list: action.payload,
            };
        case "GET_ENV_MODULE_INFO_FULFILLED":
            return {
                ...state,
                env_module_data: action.payload,
            };
        case "UPDATE_MODULE_INFO":
            return {
                ...state,
                env_module_data: {...action.data},
            };
        case "GET_ENV_MODULE_VERSION_lIST_FULFILLED":
            return {
                ...state,
                env_module_version_list: action.payload,
            };

        case "GET_ENV_TASK_VERSION_lIST_FULFILLED":
            return {
                ...state,
                env_task_version_list: action.payload,
            };
        case "GET_ENV_VIDEO_VERSION_lIST_FULFILLED":
            return {
                ...state,
                env_video_version_list: action.payload,
            };
        case "GET_ENV_TEXT_VERSION_lIST_FULFILLED":
            return {
                ...state,
                env_text_version_list: action.payload,
            };

        case "GET_ENV_MODULE_VERSION_FULFILLED":
            return {
                ...state,
                env_module_version_data: action.payload,
            };

        case "GET_ENV_TASK_FULFILLED":
            return {
                ...state,
                env_task: action.payload,
            };

        case "GET_CONTEST_TASK_FULFILLED":
            return {
                ...state,
                contest_task: action.payload,
            };

        case "GET_ENV_VIDEO_FULFILLED":
            return {
                ...state,
                env_video: action.payload,
            };

        case "UPDATE_ENV_VIDEO":
            return {
                ...state,
                env_video: {...action.data},
            };

        case "UPDATE_ENV_TEXT":
            return {
                ...state,
                env_text: {...action.data},
            };
        case "UPDATE_ENV_TASK":
            return {
                ...state,
                env_task: {...action.data},
            };
        case "GET_ENV_TEXT_FULFILLED":
            return {
                ...state,
                env_text: action.payload,
            };
        case "UPDATE_NEWS_LIST":
            return {
                ...state,
                changed_news_list: [...action.news],
            };
        case "GET_APPROVE_REQUESTS_LIST_FULFILLED":
            return {
                ...state,
                approve_requests_list: action.payload.users,
            };
        case "GET_CONFIG_FULFILLED":
            return {
                ...state,
                config: action.payload,
            };
        case "GET_COURSE_USERS_GRANT_FULFILLED":
            return {
                ...state,
                course_users_grant: action.payload,
            };
        case "GET_COURSE_USERS_FULFILLED":
            return {
                ...state,
                course_users: action.payload,
            };
        case "GET_USER_LIST_DATA_FULFILLED":

            let user_list_data = {};

            for (let i in action.payload.result) {

                user_list_data[action.payload.result[i].id] = action.payload.result[i];

            }

            return {
                ...state,
                user_list_data,
            };
        case "GET_NOTES_ABOUT_USER_FULFILLED":
            return {
                ...state,
                notes_about_user: action.payload,
            };
        case "GET_SCORER_LIST_FULFILLED":
            return {
                ...state,
                scorer_list: action.payload,
            };
        case "GET_WIDGET_LIST_FULFILLED":
            return {
                ...state,
                widget_list: action.payload,
            };
        case "GET_SECTION_WIDGET_LIST_FULFILLED":
            return {
                ...state,
                widget_list: action.payload,
            };
        case "GET_ENV_SCORER_LIST_FULFILLED":
            return {
                ...state,
                env_scorer_list: action.payload,
            };
        case "GET_COURSE_HELP_WIDGET_LIST_FULFILLED":
            return {
                ...state,
                help_widget_list: action.payload,
            };
        case "GET_USER_PROGRESS_FULFILLED":
            return {
                ...state,
                user_progress: action.payload.progress,
            };
        case "GET_USER_CERTIFICATE_FULFILLED":
            return {
                ...state,
                user_certificate: action.payload.result,
            };
        case "GET_COURSE_PERMISSION_FULFILLED":
            return {
                ...state,
                course_permission: action.payload.success,
            };
        case "GET_USER_INFO_FULFILLED":
            return {
                ...state,
                user_info: action.payload,
            };
        case "GET_USER_QUESTION_FULFILLED":
            return {
                ...state,
                userQuestion: action.payload,
            };
        case "GET_USER_APPROVE_FULFILLED":
            return {
                ...state,
                userApprove: action.payload.success,
            };
        case "GET_MAIN_INFO_FULFILLED":
            return {
                ...state,
                widgets: action.payload.success.widgets,
            };
        case "GET_PERMISSIONS_FULFILLED":
            return {
                ...state,
                permissions: action.payload.permissions,
            };
        case "GET_PERSONAL_CONFIG_MAIN_PAGE_FULFILLED":
            return {
                ...state,
                personalConfigMainPage: action.payload.success,
            };
        case "GET_COURSE_MODULE_INFO_FULFILLED":
            return {
                ...state,
                course_module_info: action.payload.success,
            };
        case "GET_QA_INFO_FULFILLED":
            return {
                ...state,
                qa_info: action.payload.success,
            };

        case "SET_LEFT_SIDEBAR_STATUS":

            return {
                ...state,
                leftSidebarCollapsed: action.payload,
            };

        case "CLEAR_DATA":
            return {
                ...state,
                course_data:        [],
                course_module_info: [],
                lecturer_list:      []
            };

        default:
            return state;
    }

}
