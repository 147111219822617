import React, {FC, useEffect, useState}             from "react";
import bnc                                          from "bnc";
import {useLocale}                                  from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import {CircularProgress}                           from "@sirius/ui-lib/src/blocks/CircularProgress";
import {Screen, VBox}                               from "@sirius/ui-lib/src/blocks/Layout";
import LangSwitcher                                 from "@sirius/ui-lib/src/blocks/LangSwitcher/LangSwitcher";
import {getAuthRegistration,
        postAuthRegistrationRegister}               from "Smt/actions/webPortal";
import {SmtLocaleString}                            from "Smt/SmtLocaleBlock";
import {AuthRegisterType}                           from "./@types";
import {RegisterForm}                               from "./RegisterForm";
import {RegistrationPanel}                          from "./RegistrationPanel";


const block = new bnc("smt-auth-register-page");
const DN    = (CONFIG?.DeployName || '').toLowerCase() === 'cpm' ? 'CPM' : 'SIRIUS';

export const AuthRegister:FC<AuthRegisterType> = ({contest_name}) => {
    const locale                            = useLocale();
    const [loading,      setLoading]        = useState<boolean>(true);
    const [data,         setData]           = useState<SmtPortal.Methods.AuthContestResponse>(null);
    const [value,        setValue]          = useState<LabSmt.SchemaOptions>({});
    const [options,      setOptions]        = useState<LabSmt.SchemaOptions>({});
    const [disabled,     setDisabled]       = useState<boolean>(true);
    const [registration, setRegistration]   = useState<SmtPortal.Methods.AuthContestRegisterResponse | null>(null);

    const stopLoading = () => setLoading(false);
    const load = (contest_name: string) => {
        setLoading(true);
        getAuthRegistration(contest_name)
            .then(
                ({data}) => {
                    setData(data);
                    setOptions({});
                }
            )
            .finally(stopLoading)
    };

    useEffect(
        () => {
            load(contest_name)
        },
        [contest_name]
    );

    const onRegister = () => {
        setLoading(true);
        postAuthRegistrationRegister(
            contest_name, {
                ...value,
                lang: locale as "ru" | "en"
            })
            .then(
                ({data}) => {
                    setRegistration(data)
                }
            )
            .finally(stopLoading)
    }

    const onChange = (value: any) => {
        setValue(value);
        setDisabled(false);
    }

    const onRegistrationBack = () => {
        setRegistration(null)
    }

    return  loading
            ?  <CircularProgress centerOfWindow={true} />
            :  <Screen className={block}>
                    <LangSwitcher />
                    {
                        registration
                            ? <RegistrationPanel
                                registration = {registration}
                                email        = {`${value?.email}`}
                                onBack       = {onRegistrationBack}
                            />
                            : <VBox className={block.el('content')}>
                                <div className='login_page__gerb'/>
                                <div className="login_page__dep"><SmtLocaleString k={`common.login_page.header_${DN}`}/></div>
                                <div className={"login_page__title login_page__title--normal"}>{data.title}</div>
                                <RegisterForm
                                    schema     = {data.extra}
                                    onChange   = {onChange}
                                    onRegister = {onRegister}
                                    options    = {options}
                                    disabled   = {disabled}
                                    value      = {value}
                                    locale     = {locale}
                                />
                            </VBox>
                    }
                    <VBox className={block.el('footer')}>
                        <SmtLocaleString
                            k={`${CONFIG.currentInstance}.index.copyright_${DN}`}
                            values={{currentYear: new Date().getFullYear(), br: <br />}}
                        />
                    </VBox>
                </Screen>
    ;
}
