import React, {FC}                       from "react";
import bnc                               from "bnc";
import {HBox, LGap, MGap, Spoiler, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import {Chips, ChipsList}                from "@sirius/ui-lib/src/blocks/Controls/Chips";
import {Icon}                            from "@sirius/ui-lib/src/blocks/Controls";
import {ChipsProps}                      from "@sirius/ui-lib/src/blocks/Controls/Chips/types";
import {SmtLocaleString}                 from "Smt/SmtLocaleBlock";
import {TaskStates}                      from "Cheops/Model/Task";
import {getTaskDataState}                from "Smt/TaskPage/Task/TaskDataSubject";
import                                      "./ResultStates.less"

export const block = new bnc('result-states');

enum TextSize {
    l   = 'xl',
    m   = 'l',
    s   = 'm',
    xs  = 's',
    xxs = 'xs',
}
type Section = {
    name: string;
    from: number;
    to:   number;
    at:   number;
}
type TaskWSection  =
    {
        sectionTasks: Array<string>;
    } &
    Pick<Section, 'name' | 'at'>
;
type HasSectionsWRange = { sectionsWRange: Array<Section> };
type ResultStatesProps =
    {
        tasks:         Array<any>;
        mode?:         'modal' | 'spoiler';
        statusType?:   TaskStates;
        onSelectTask?: (value: any) => void;
    } &
    Pick<ChipsProps, 'size'>
;
type SectionListProps =
    {
        tasksWSection: Array<TaskWSection>;
    } &
    ResultStatesProps
;
type TasksListProps =
    ResultStatesProps &
    HasSectionsWRange
;

const inRange = (x: number, minimum: number, maximum: number) => ((x-minimum)*(x-maximum) <= 0);

const getTaskNameInSection = (item: number, at = 1) => `${item - at + 2}`;

const getTasksWSection = (tasks: Array<any>, sectionsWRange: Array<Section>) =>
    tasks && Array.isArray(sectionsWRange)
        ?   sectionsWRange.flatMap(
                ({name, from, to, at}) => {
                    const sectionTasks = tasks.filter( task => inRange(task, from, to))
                    return sectionTasks.length > 0 ? [{at, name, sectionTasks}] : []
                }
            )
        :   []
;

const SectionList: FC<SectionListProps> = ({tasksWSection, size, onSelectTask}) =>  (
    <VBox>
        {
            tasksWSection.map(
                ({sectionTasks, name, at}, si) => (
                    <VBox key={`${at}-${si}`}>
                        <span className = {block.el('section') + `text-${TextSize[size]}`}>
                            {name}
                        </span>
                        <ChipsList
                            wrapperClassName = {block.el('task-list')}
                            align            = {'start'}
                            onChange         = {onSelectTask}
                        >
                            {
                                sectionTasks.map(
                                    (item: string, ci) =>
                                        <Chips
                                            className   = {block.el('task')}
                                            key         = {`task_${at ?? ''}_${ci}`}
                                            size        = {size}
                                            value       = {item}
                                        >
                                            {getTaskNameInSection(parseInt(item), at)}
                                        </Chips>
                                )
                            }
                        </ChipsList>
                    </VBox>
                )
            )
        }
    </VBox>
);


const TasksList: FC<TasksListProps> = (props) => {
    const {sectionsWRange, tasks = [], size, onSelectTask} = props;
    const tasksWSection = getTasksWSection(tasks, sectionsWRange);

    return (
        <>
            { tasksWSection.length > 0
                ?   <SectionList {...props}
                        tasksWSection = {tasksWSection}
                    />

                :   <ChipsList
                        wrapperClassName = {block.el('task-list')}
                        align            = {'start'}
                        onChange         = {onSelectTask}
                    >
                        {
                            tasks.map(
                                (item: string, ci) =>
                                    <Chips
                                        className   = {block.el('task')}
                                        key         = {ci}
                                        size        = {size}
                                        value       = {item}
                                    >
                                        {item}
                                    </Chips>
                            )
                        }
                    </ChipsList>
            }
        </>
    )
};


export const ResultStates: FC<ResultStatesProps> = (props) => {
    const {
        statusType = TaskStates.NOT_SAVED,
        mode = 'spoiler',
        size = 's',
    } = props;

    const { sectionsWRange } = getTaskDataState() as HasSectionsWRange ;

    const attentionMsg =  <SmtLocaleString k={'common.result.attention'} id={statusType} /> ;
    const hasModalMode = mode === 'modal';

    const classNameStates =
        block +
        block.mod('mode', mode) +
        block.mod('status', statusType)
    ;

    return (
        <>
            { hasModalMode
                ?   <VBox className = {classNameStates}>
                        <HBox className = {block.el('title')}>
                            <Icon
                                icon      = {'error_outline'}
                                className = {block.el('title-icon')}
                                size      = {'xs'}
                            />
                            <MGap/>
                            <span className = {block.el('title-text') + 'text-m'}>
                                <SmtLocaleString
                                    k  = {'common.close_page.attention'}
                                    id = {statusType}
                                />
                            </span>
                        </HBox>
                        <LGap />
                        <TasksList {...props}
                            sectionsWRange = {sectionsWRange}
                            size           = {size}
                        />
                        <LGap />
                        <HBox className = {block.el('attention') + `text-${TextSize[size]}`}>
                            <SmtLocaleString
                                k   = {'common.close_page.summary_attention'}
                                id  = {statusType}
                                values={{br: <br />}}
                            />
                        </HBox>
                    </VBox>

                :   <Spoiler
                        className   = {classNameStates}
                        size        = {'l'}
                        icon        = {'error_outline'}
                        summary     = {attentionMsg}
                    >
                        <TasksList {...props}
                            sectionsWRange = {sectionsWRange}
                            size           = {size}
                        />
                    </Spoiler>
            }
        </>

    )

}
