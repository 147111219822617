import React, {RefObject} from 'react';
import './UIControlWrapper.less';
import BemClassName from "Cheops/BemClassName";
import ReactDOM from 'react-dom';

export enum ControlSizes {SM = "small", MD = "medium", LG = "large"}

interface Props {

    children?: React.ReactNode;
    className?: string;
    fullWidth?: boolean;
    size?: ControlSizes;
    dataName?: string;
    alignItems?: React.CSSProperties["alignItems"];
    innerRef?: RefObject<HTMLDivElement>;

    onClick?(e: React.MouseEvent): void;

    clickOutside?(e: MouseEvent): void;
}


export default class UIControlWrapper extends React.Component<Props> {

    static defaultProps = {
        className: "",
        fullWidth: false,
        size: ControlSizes.LG,
        dataName: null as Props['dataName'],
        innerRef: null as RefObject<any>,
    };

    componentDidMount(): void {

        const {clickOutside} = this.props;

        if (clickOutside) {

            document.body.addEventListener("click", this.handleClickOutside);

        }

    }

    componentWillUnmount(): void {

        document.body.removeEventListener("click", this.handleClickOutside);

    }


    handleClickOutside = (e: MouseEvent): void => {

        const domNode = ReactDOM.findDOMNode(this);

        const {target} = e;

        if (!(domNode && target instanceof Node && domNode.contains(target))) {

            this.props.clickOutside(e);

        }

    };

    render(): React.ReactNode {

        const {
            size,
            onClick,
            children,
            className,
            fullWidth,
            alignItems,
            innerRef,
            dataName,
        } = this.props;


        const wrapperClassName = new BemClassName('control_wrapper');

        wrapperClassName.appendStatusIf(fullWidth, 'full-width');

        wrapperClassName.appendAdditionalClassesIf(className.length > 0, className);

        wrapperClassName.appendStatusIf(!!size, `${size}`);
        wrapperClassName.appendStatusIf(!!alignItems, `align-items-${alignItems}`);

        return <div className={wrapperClassName.toString()} onClick={onClick} ref={innerRef} data-name={dataName}>
            {children}
        </div>;

    }

}
