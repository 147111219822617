import type {TranslationsType} from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";

export const app_modal:  TranslationsType = {
    apply: {
        ru: {value: "Применить"},
        en: {value: "Apply"},
    },
    cancel: {
        ru: {value: "Отмена"},
        en: {value: "Cancel"},
    },
    close: {
        ru: {value: "Закрыть"},
        en: {value: "Close"},
    },
    tryAgain: {
        ru: {value: "Повторить попытку"},
        en: {value: "Try again"}
    },
    backTo: {
        ru: {value: "Вернуться"},
        en: {value: "Back"},
    },
    backToEdit: {
        ru: {value: "Вернуться к редактированию"},
        en: {value: "Back to edit"},
    },
};
