import React from "react";
import UIElement from "Lab/components/UI2/UIElement";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";
import CheckboxControl, {CheckboxControlProps} from "Lab/components/UI2/Controls/CheckboxControl";


export default class CheckboxElement extends UIElement<CheckboxControlProps> {


    render(): React.ReactNode {

        return <UIElementWrapper>
            <CheckboxControl {...this.props} fullWidth />
        </UIElementWrapper>;

    }

}
