import React                 from "react";
import ReactDOM, {render}    from "react-dom";
import Axios                 from "axios";
import {YMInitializer}       from "react-yandex-metrika";
import                            "url-search-params-polyfill";
import                            "raf-polyfill";
import                            "whatwg-fetch";
import                            "promise-polyfill/src/polyfill";
import ContestAuth           from "Smt/ContestAuth";
import IndexPage             from "Smt/IndexPage/IndexPage";
import {
    LoginPage,
    AuthRegister
}                            from "Smt/Auth";
import CertificatePage       from "Smt/CertificatePage";
import {renderParentModeBar} from "Smt/helpers";
import {getContestIdAndSesionId,
             syncServerTime} from "./helpers.js";
import {YANDEX_METRIKA_SMT}  from "./constants";
import {codes}               from "./mock/codes";
import                            "../less/style.less";


function MockAlert() {
    const mockAlert = document.createElement('div');
    mockAlert.setAttribute(
        'style',
        'position: absolute; top: 95vh; margin: auto 50%; opacity: .6; color: red; font-size: 9px;'
    );
    mockAlert.innerText = `MOCK: ${ Object.values(codes) }`;
    document.body.appendChild( mockAlert );
    setTimeout(
        ()=>{
            document.body.removeChild(mockAlert);
        },
        5000
    )
}

function main() {
    Axios.defaults.baseURL = '';
    syncServerTime();
    let {contestId, sessionId} = getContestIdAndSesionId();
    window.appId = Math.random().toString(36).substring(2);
    if (CONFIG.IsNoopolis) {
        document.title = 'Сириус.Курсы';
        renderParentModeBar();
    }

    (async () => {
        let contestData;

        if (location.hash.startsWith('#/auth/register')) {
            let contest_name = location.hash.substr(16);
            ReactDOM.render(
                <AuthRegister contest_name={contest_name} />,
                document.getElementById("index")
            );
            return;
        }

        if (location.hash.substr(1, 14) === '/auth_callback') {
            contestData = await ContestAuth.authCallback();
            location.hash = `#/contest/${contestData.tsContestId}`;
        }

        if (!contestData && location.hash.match(/contest\/(\d+)$/)) {
            contestData = await ContestAuth.auth();
            location.hash = `#/contest/${contestData.tsContestId}`;
        }

        if (location.hash.startsWith('#certificate')) {
            let hash = location.hash.substr(13);
            ReactDOM.render(
                <CertificatePage cert_hash={hash} />,
                document.getElementById("index")
            );
            return;
        }

        if ((contestId && sessionId) || contestData) {
            ReactDOM.render(
                <IndexPage contestData={contestData} sessionId={sessionId} />,
                document.getElementById("index")
            );
        } else {
            ReactDOM.render(
                <LoginPage />,
                document.getElementById("index")
            );
            if (MOCK) {
                MockAlert()
            }
        }

    })();

    if (CONFIG.YandexMetrikaId) {
        render(
            <YMInitializer accounts={[CONFIG.YandexMetrikaId]} options={YANDEX_METRIKA_SMT.options} />,
            document.getElementById("metrika_smt"),
        );
    }
}

if (MOCK) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mock/browser');
    worker.start().then( main );
} else {
    main();
}
