import React, {FC, useEffect, useState}   from 'react';
import {windowResize$}                    from "@sirius/ui-lib/src/subjects/WindowResize";
import {Button}                           from "@sirius/ui-lib/src/blocks/Controls";
import {LGap}                             from "@sirius/ui-lib/src/blocks/Layout";
import {getNowDate}                       from "Smt/helpers";
import CircularProgress                   from "Smt/Ui";
import {SmtLocalePlural, SmtLocaleString} from "Smt/SmtLocaleBlock";
import {useTaskDataState}                 from "Smt/TaskPage/Task/TaskDataSubject";
import {ResultStates}                     from "Smt/TaskPage/Task/ResultStates";
import {TaskStates}                       from "Cheops/Model/Task";

const TICK_TIMEOUT_INTERVAL = 1000;

interface ResultProps {
    issuedUntil:    Date;
    issuedAt:       Date;
    task_count:     number;
    answer_count:   number;
    onSend:         () => void;
    onCancelSave:   () => void;
    onTimeout:      () => void;
    onSelectTask:   (value: any) => void;
}

const calculateMargin = () => {
    const taskClassNameElements = document.getElementsByClassName('task') as HTMLCollectionOf<HTMLElement>;
    if (taskClassNameElements.length > 0) {
        const [taskDiv] = taskClassNameElements ;
        const top = (window.innerHeight - taskDiv.offsetHeight) / 2;
        taskDiv.style.margin = "";

        if (top > 120) {
            taskDiv.style.margin = top + "px auto";
        }
    }

}


const Result: FC<ResultProps> = (props) => {
    const {
        issuedUntil,
        issuedAt,
        task_count,
        answer_count,
        onSend,
        onCancelSave,
        onTimeout,
        onSelectTask,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [remain, setRemain]       = useState<number>(0);

    const { statusTasks } = useTaskDataState();


    let timerID:  NodeJS.Timeout = null;

    const tick = () => {
        const remainMin = (issuedUntil.getTime() - getNowDate().getTime()) / 1000 / 60;

        if (remainMin <= 0) {
            clearInterval(timerID);
            onTimeout();
        } else {
            setRemain(Math.ceil(remainMin))
        }
    };

    useEffect(() => {
        calculateMargin();
        timerID = setInterval(tick, TICK_TIMEOUT_INTERVAL);
        tick();
        const windowResizeSub = windowResize$.subscribe(calculateMargin);

        return () => {
            windowResizeSub.unsubscribe();
            clearInterval(timerID);
        }
    }, [])

    const sendHandler = () => {
        setIsLoading(true);
        onSend();
    }

    const passedMin = Math.floor((getNowDate().getTime() - issuedAt.getTime()) / 1000 / 60);
    const resultDuration = passedMin > 0 ? passedMin : 0;

    const notSavedTasks         = statusTasks[TaskStates.NOT_SAVED];
    const hasNotSavedTasks      = notSavedTasks?.length > 0;

    const notAnsweredTasks      = statusTasks[TaskStates.NOT_ANSWERED];
    const hasNotAnsweredTasks   = notAnsweredTasks?.length > 0;

    const attentionMsgType = hasNotSavedTasks
        ? TaskStates.NOT_SAVED
        : void(0)
    ;

    return (
        <div className="task task--result">
            { isLoading
                ?   <CircularProgress centerOfWindow={true} />
                :   <>
                        <div className="task__remain">
                            <SmtLocaleString
                                k="common.result.time_left"
                                values={{
                                    number: remain,
                                    plural: <SmtLocalePlural k="common.format_plural.minutes" value={remain} />,
                                    left:   <SmtLocalePlural k="common.result.left" value={remain} />,
                                }}
                            />
                        </div>
                        <div className="task__title task__title--result">
                            <SmtLocaleString k="common.result.title"  />
                        </div>
                        <div className="status_row">
                            <div className="status_row_item">
                                <div className="status_row_title">
                                    <SmtLocaleString k="common.result.tasks" />
                                </div>
                                <div className="status_row_text">{task_count}</div>
                            </div>
                            <div className="status_row_item">
                                <div className="status_row_title">
                                    <SmtLocaleString k="common.result.answers" />
                                </div>
                                <div className="status_row_text">{answer_count}</div>
                            </div>
                            <div className="status_row_item">
                                <div className="status_row_title">
                                    <SmtLocaleString k="common.result.time" />
                                </div>
                                <div className="status_row_text">
                                    <SmtLocaleString
                                        k="common.result.duration"
                                        values={{number: resultDuration}}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            hasNotSavedTasks &&
                            <div className="task__summary">
                                <ResultStates
                                    tasks           = {notSavedTasks}
                                    statusType      = {TaskStates.NOT_SAVED}
                                    onSelectTask    = {onSelectTask}
                                />
                                <LGap />

                            </div>
                        }
                        {
                            hasNotAnsweredTasks &&
                            <div className="task__summary">
                                <ResultStates
                                    tasks           = {notAnsweredTasks }
                                    statusType      = {TaskStates.NOT_ANSWERED}
                                    onSelectTask    = {onSelectTask}
                                />
                                <LGap />
                            </div>
                        }

                        <div className="task__summary">
                            {
                                attentionMsgType &&
                                <>
                                    <SmtLocaleString k="common.result.summary_attention" id={attentionMsgType} />
                                    <LGap />
                                </>

                            }
                            <SmtLocaleString k="common.result.summary" values={{br: <br />}} />
                        </div>
                        <Button
                            className   = {"task__send_btn"}
                            form        = {"rounded"}
                            size        = {"s"}
                            onAction    = {sendHandler}
                        >
                            <SmtLocaleString k="common.result.send_for_examination" />
                        </Button>
                        <Button
                            className   = {"task__send_cancel_btn"}
                            mode        = {'primary'}
                            form        = {"rounded"}
                            size        = {"s"}
                            onAction    = {onCancelSave}
                        >
                            <SmtLocaleString k="common.result.do_not_send" />
                        </Button>
                    </>
            }
        </div>
    );
}

export default Result;
