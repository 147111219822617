import React, {FC, useEffect, useState}     from "react";
import {HBox, VBox}                         from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                             from "@sirius/ui-lib/src/blocks/Controls";
import {ValidateFieldResult, Validation}    from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {SmtLocaleString}                    from "Smt/SmtLocaleBlock";
import {SchemaField, onInputSF$}            from "Smt/Auth/SchemaField";
import {block}                              from "./smt-register-form-block";
import {UISchema}                           from "./SchemaField/UISchema";
import {UIOptions}                          from "./SchemaField/UIOptions";
import {RegisterFormType}                   from "./@types";
import                                          "./styles/register-form.less";



export const RegisterForm:FC<RegisterFormType> = ({
    schema,
    onRegister,
    options,
    onChange,
    locale,
    value: _value
}) => {
    const [value,        setValue       ] = useState<{}>(_value || {});
    const [validation,   setValidation  ] = useState<Validation>();
    const [onValidate,   setOnValidate  ] = useState<any>(void(0));
    const [valid,        setValid       ] = useState<boolean>(false);
    const [errors,       setErrors      ] = useState<any[]>([]);
    const [hasErrors,    setHasErrors   ] = useState<boolean>(false);
    const [isChanged,    setIsChanged   ] = useState<boolean>(false);

    useEffect(() => {
            const onInputSubj = onInputSF$.subscribe( () => setIsChanged(true) );

            return () => {
                onInputSubj && onInputSubj.unsubscribe();
            };
        }, []
    );

    const onValidateHandler = (validateResult: ValidateFieldResult = {}) => {
        const {isValid, validation, errors} = validateResult;
        setValidation(validation);
        setErrors(errors);
        setValid(isValid);
    };

    useEffect(
        ()=>{
            const hasOnValidate = { onValidate: isChanged ? onValidateHandler : void(0) };
            setOnValidate(hasOnValidate);
        },
        [isChanged]
    );

    useEffect(
        ()=>{
            errors?.length === 0 && setHasErrors(false);
        },
        [errors]
    );

    const onChangeHandler = (next: any) => {
        setValue(next);
        onChange(next);
    };

    const onRegisterHandler = () => {
        valid && onRegister(value);
    };

    return  (
        <VBox className={block}>
            <SchemaField
                uioptions   = {{...UIOptions, options, locale}}
                uischema    = {UISchema}
                schema      = {schema}
                data        = {value}
                onChange    = {onChangeHandler}
                validation  = {validation}
                {...onValidate}
            />
            <Button
                className = {block.el('register-btn')}
                mode      = "primary"
                form      = "rounded"
                disabled  = {!valid}
                onAction  = {onRegisterHandler}
            >
                <SmtLocaleString k={`${CONFIG.currentInstance}.user_form.go_btn`}/>
            </Button>
            { hasErrors &&
                <HBox className = {block.el('has-errors-warning')}>
                    <SmtLocaleString k={`common.registration.has-errors`}/>
                </HBox>
            }
        </VBox>
    );
};
