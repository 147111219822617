import React from "react";
import PrizeTypeCongrats from "Smt/IndexPage/Prize/PrizeTypeCongrats";
import PrizeTypeOrdinary from "Smt/IndexPage/Prize/PrizeTypeOrdinary";
import Prize, {PrizeType} from "Smt/IndexPage/Prize/Prize";
import Meta from "Smt/Meta";


interface Props {
    data: Prize[];
    metadata: Meta;
}

export default class Prizes extends React.Component<Props> {


    render(): React.ReactNode {

        const {data, metadata} = this.props;

        if (!data) {

            return null;

        }

        return data.map((prize, i) => {

            switch (prize.type) {
                case PrizeType.congrats:
                    return <PrizeTypeCongrats data={prize} meta={metadata} key={i} />;
                case PrizeType.ordinary:
                    return <PrizeTypeOrdinary data={prize} meta={metadata} key={i} />;

            }

            throw new Error('unknown prize type');

        });

    }


}
