import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const InlineIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="9" width="8" height="2" rx="1" fill="#C4C4C4" />
        <rect x="3" y="5" width="18" height="2" rx="1" fill="#C4C4C4" />
        <rect x="3" y="13" width="8" height="2" rx="1" fill="#C4C4C4" />
        <rect x="3" y="17" width="18" height="2" rx="1" fill="#C4C4C4" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20 10H14V14H20V10ZM14 9C13.4477 9 13 9.44772 13 10V14C13 14.5523 13.4477 15 14 15H20C20.5523 15 21 14.5523 21 14V10C21 9.44772 20.5523 9 20 9H14Z"
              fill="#C4C4C4"
        />
        <rect x="15" y="11" width="1" height="2" fill="#C4C4C4" />
    </svg>
</SvgIcon>;


export default InlineIcon;
