import AjaxWrapper   from "Smt/AjaxWrapper";

const CACHE_SMT_API_URL = CONFIG.Api?.persistentCache?.url ? `${CONFIG.Api.persistentCache.url}` : "";
const WITH_AUTH = {send_auth_token: true};


const headers = {
    'cache-control': 'no-cache',
    'content-type': 'application/json',
    'Accept': 'application/json',
};

export const GET_TasksByToken = (auth_token:string): Promise<SmtCacheV4.GetResponse> =>
    AjaxWrapper
        .get(
            `${CONFIG.Api.persistentCache.url}/v4/get`,
            {},
            {auth_token, headers}
        )
;

export const getFractionLatex = (ratio: string): Promise<Common.Math.LatexResponse> =>
     AjaxWrapper.get(
        `${CACHE_SMT_API_URL}/math/ratio/latex?ratio=${ratio}`,
        {},
        {...WITH_AUTH},
    )
;
