import React from "react";
import {ModalProps, ModalState, PopoverPosition} from "Lab/components/UI2/ModalWindow/ModalWindow";

export type PopoverRects = {
    left?: string;
    bottom?: string;
    top?: string;
    right?: string;
};

export default class ModalPositionController {

    constructor(
        modalProps: ModalProps,
        modalState: ModalState,
        setModalState: React.Component['setState'],
        modalWindowWrapperRef: React.RefObject<HTMLDivElement>,
        modalWindowRef: React.RefObject<HTMLDivElement>,
    ) {

        this.modalProps = modalProps;
        this.modalState = modalState;
        this.setModalState = setModalState;
        this.modalWindowWrapperRef = modalWindowWrapperRef;
        this.modalWindowRef = modalWindowRef;

    }

    modalProps: ModalProps;
    modalState: ModalState;
    setModalState: React.Component['setState'];

    modalWindowWrapperRef: React.RefObject<HTMLDivElement>;
    modalWindowRef: React.RefObject<HTMLDivElement>;

    marginFromTarget = 5;

    setActualPopoverRects = (): void => {

        const {popoverTargetEl, popoverPosition} = this.modalProps;
        let {popoverRects} = this.modalState;

        if (!window.matchMedia("(max-width: 760px)").matches) {

            const rects = popoverTargetEl.getBoundingClientRect();

            popoverRects = {};


            if (popoverPosition === PopoverPosition.TOP_LEFT) {

                popoverRects.left = `${rects.left}px`;
                popoverRects.bottom = `${document.documentElement.clientHeight - rects.top + this.marginFromTarget}px`;

            }

            if (popoverPosition === PopoverPosition.TOP_RIGHT) {

                popoverRects.right = `${document.documentElement.clientWidth - rects.right}px`;
                popoverRects.bottom = `${document.documentElement.clientHeight - rects.top + this.marginFromTarget}px`;

            }

            if (popoverPosition === PopoverPosition.BOTTOM_LEFT) {

                popoverRects.left = `${rects.left}px`;
                popoverRects.top = `${(rects.bottom + this.marginFromTarget)}px`;

            }
            if (popoverPosition === PopoverPosition.BOTTOM_LEFT_END) {

                popoverRects.left = `${rects.width + rects.left}px`;
                popoverRects.top = `${(rects.bottom + this.marginFromTarget)}px`;

            }

            if (popoverPosition === PopoverPosition.BOTTOM_RIGHT) {

                popoverRects.right = `${document.documentElement.clientWidth - rects.right}px`;
                popoverRects.top = `${(rects.bottom + this.marginFromTarget)}px`;

            }

            if (popoverPosition === PopoverPosition.BOTTOM_CENTER) {

                const rightPos = document.documentElement.clientWidth
                    - rects.right - this.modalWindowWrapperRef.current.clientWidth / 2
                    + popoverTargetEl.clientWidth / 2;
                popoverRects.right = `${rightPos}px`;
                popoverRects.top = `${rects.bottom + this.marginFromTarget}px`;

            }

        } else {

            popoverRects = {
                ...popoverRects,
                bottom: '0',
                left: '0',
            };

        }

        popoverRects = this.fitPopoverRects({...popoverRects});

        this.setModalState({popoverRects, popoverTargetRects: popoverTargetEl.getBoundingClientRect()});

    };

    fitPopoverRects = (popoverRects: PopoverRects): PopoverRects => {

        const {popoverTargetEl} = this.modalProps;

        const popoverMenu = this.modalWindowWrapperRef.current;

        const {clientWidth, clientHeight} = popoverMenu;
        const targetRects = popoverTargetEl.getBoundingClientRect();

        let popoverRectsTop = null;

        if (popoverRects.top) {

            popoverRectsTop = parseInt(popoverRects.top);

            if (popoverRectsTop < 0 && targetRects.bottom > 0) {

                popoverRects.top = `0`;

            }

            if ((popoverRectsTop + clientHeight) > window.innerHeight) {

                popoverRects.top = `${popoverRectsTop - (popoverRectsTop + clientHeight - window.innerHeight)}px`;

            }

            if (targetRects.top > window.innerHeight) {


                popoverRects.top = `${targetRects.top - clientHeight - this.marginFromTarget}px`;

            }

        }

        if (popoverRects.bottom) {

            if (targetRects.top - clientHeight <= 0) {

                popoverRects.bottom = `${window.innerHeight - clientHeight}px`;

            }

            if ((window.innerHeight - (targetRects.bottom + clientHeight) + this.marginFromTarget) >= (window.innerHeight - clientHeight)) {

                popoverRects.bottom = `${window.innerHeight - (targetRects.bottom + clientHeight) + this.marginFromTarget}px`;

            }

        }

        let popoverRectsLeft = null;

        if (popoverRects.left) {

            popoverRectsLeft = parseInt(popoverRects.left);

            if (document.body.clientWidth - popoverRectsLeft < clientWidth) {

                popoverRects.left = `${document.body.clientWidth - clientWidth}px`;

            }

            if (targetRects.right > document.body.clientWidth) {

                popoverRects.left = `${targetRects.right - clientWidth}px`;

            }

        }

        let popoverRectsRight = null;

        if (popoverRects.right) {

            popoverRectsRight = parseInt(popoverRects.right);

            if (window.innerWidth - popoverRectsRight < clientWidth) {

                popoverRects.right = `${window.innerWidth - clientWidth}px`;

            }

            if (targetRects.left < 0) {

                popoverRects.right = `${window.innerWidth - clientWidth - targetRects.left}px`;

            }

        }

        return popoverRects;

    };

    subscribePopoverRectsChange = (): void => {

        this.setActualPopoverRects();
        window.addEventListener('scroll', this.setActualPopoverRects, true);
        window.addEventListener('resize', this.setActualPopoverRects);

        const popoverTargetClientRects = this.modalProps.popoverTargetEl.getBoundingClientRect();

        this.setModalState({popoverTargetRects: popoverTargetClientRects});

    };

    unsubscribePopoverRectsChange = (): void => {

        window.removeEventListener('scroll', this.setActualPopoverRects, true);
        window.removeEventListener('resize', this.setActualPopoverRects);

    };


    updateRectsOnTargetWidthChange = (targetRects: DOMRect, popoverRects: ModalState['popoverTargetRects']): void => {

        const {x: currentX, y: currentY} = targetRects;
        const {x: prevX, y: prevY} = popoverRects;

        if ((currentX !== prevX) || (currentY !== prevY)) {

            this.setActualPopoverRects();

        }

    };

}
