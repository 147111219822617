import React, {RefObject} from 'react';
import IconButton from '@material-ui/core/IconButton';

import ButtonControl, {
    BUTTON_COLOR,
    BUTTON_VARIANTS,
    ButtonControlProps,
} from "Lab/components/UI2/Controls/ButtonControl";
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import BemClassName from "Cheops/BemClassName";
import {UIControl} from "../UIControl";
import IconAtom, {ICON_SIZES} from "../Atoms/IconAtom";
import './IconButtonControl.less';

export interface IconButtonControlProps extends ButtonControlProps {
    iconName: string | React.ReactNode;
    className?: string;
    iconClassName?: string;
    highlighted?: boolean;
    inline?: boolean;
    buttonRef?: RefObject<HTMLDivElement>;
}

const checkEnumStringValuesFit = (valueFrom: ControlSizes): boolean => {

    const enum1Key = (Object.keys(ICON_SIZES) as (keyof typeof ICON_SIZES)[]).find((key) => (
        ICON_SIZES[key] === valueFrom as string));

    return !!enum1Key;

};

export default class IconButtonControl extends UIControl<IconButtonControlProps> {

    static defaultProps = {
        ...ButtonControl.defaultProps,
        size: ControlSizes.LG,
        variant: BUTTON_VARIANTS.TEXT,
        pressed: false,
        className: '',
        disabled: false,
        roundShaped: false,
        darkMode: false,
        hasErrors: false,
        highlighted: false,
        color: BUTTON_COLOR.DEFAULT,
        inline: false,
        buttonRef: null as RefObject<any>,
    };


    render(): React.ReactNode {

        const {
            iconName,
            onClick,
            pressed,
            disabled,
            className,
            darkMode,
            roundShaped,
            size,
            color,
            hasErrors,
            highlighted,
            restrictFocusEffects,
            restrictHoverEffects,
            inline,
            buttonRef,
            iconClassName,
        } = this.props;

        let {variant} = this.props;

        const _iconSize = checkEnumStringValuesFit(size) ? size : ICON_SIZES.LG;
        const _btnSize = size === ControlSizes.LG ? ControlSizes.MD : size;

        let buttonClassName = new BemClassName("");

        const wrapperClassName = new BemClassName("icon_button_control", null, className);

        wrapperClassName.appendStatusIf(!!size, size);
        buttonClassName.appendAdditionalClassesIf(darkMode, 'dark-mode');
        buttonClassName.appendAdditionalClassesIf(roundShaped, 'round');
        buttonClassName.appendAdditionalClassesIf(pressed, 'pressed');
        buttonClassName.appendAdditionalClassesIf(hasErrors, 'error');
        buttonClassName.appendAdditionalClassesIf(highlighted, 'highlighted');

        if (highlighted) {

            variant = BUTTON_VARIANTS.TEXT;

        }

        wrapperClassName.appendStatusIf(variant === BUTTON_VARIANTS.FAB, 'fab');
        wrapperClassName.appendStatusIf(variant === BUTTON_VARIANTS.LAYOUT, 'layout');

        if (variant === BUTTON_VARIANTS.FAB || variant === BUTTON_VARIANTS.LAYOUT) {

            variant = BUTTON_VARIANTS.TEXT;

        }

        buttonClassName.appendAdditionalClassesIf(!!variant, variant);
        buttonClassName.appendAdditionalClassesIf(restrictHoverEffects, 'restrict-hover');
        buttonClassName.appendAdditionalClassesIf(restrictFocusEffects, 'restrict-focus');

        let IconComponent: keyof JSX.IntrinsicElements = "button";

        if (inline) {

            IconComponent = "span";

        }

        return <UIControlWrapper
                className={wrapperClassName.toString()}
                size={size}
                innerRef={buttonRef}
               >
                <IconButton
                    onClick={onClick}
                    className={buttonClassName.toString()}
                    disabled={disabled}
                    color={color}
                    size={_btnSize}
                    component={IconComponent}
                >{
                    typeof iconName === 'string'
                        ? <IconAtom
                            name={iconName}
                            size={_iconSize as ICON_SIZES}
                            hasErrors={hasErrors}
                            disabled={disabled}
                            active={pressed}
                            className={iconClassName}
                        />
                        : iconName
                }
                </IconButton>
            </UIControlWrapper>
        ;
    }
}
