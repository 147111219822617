import {DocTitle}               from "@sirius/ui-lib/src/blocks/DocTitle";
import {ELocales}               from "@sirius/ui-lib/src/subjects/Locale";
import {i18nStr, withLocale}    from "@sirius/ui-lib/src/blocks/LocaleBlock";

type Props = {
    tpl: any;
    locale: ELocales;
    values?: any;
    defaultTitle?: string;
};

const DocumentTitleValue = ({tpl, locale, values, defaultTitle = ""}: Props): void => {

    const formattedString = i18nStr({tpl, id: "value", locale, values});

    return DocTitle({title: formattedString || defaultTitle});

};

DocumentTitleValue.defaultProps = {defaultTitle: ""};

const DocumentTitleLocalized = withLocale(DocumentTitleValue);

export {DocumentTitleLocalized};
