import React, {FC}        from "react";
import {DatePickerInput}  from "@sirius/ui-lib/src/blocks/Controls";
import {StringFieldProps} from "../@types";
import {MaybeHasMode}     from "@sirius/ui-lib/src/blocks/Controls/@types";

export const StringFieldDate: FC<StringFieldProps> = (props) => {
    const {
        data,
        block,
        onChange,
        disabled  = false,
        isError   = false,
        uioptions
    } = props;

    const dateValue = [void(0), null, ''].includes(data) ? null : new Date(data);

    const changeHandler = (dateValue: Date) => {
        const next = dateValue !== null
            ? dateValue.toISOString()
            : void(0)
        ;
        onChange(next);
    };

    const StringFieldDateInput = DatePickerInput;

    const maxDate = new Date();
    const minDate = new Date(maxDate.getFullYear() - 100, 0, 1);

    const fieldModeProps: MaybeHasMode = isError ? {mode: 'wrong'} : void(0);

    return (
        <StringFieldDateInput
            className        = {block.el('datetime')}
            onChange         = {changeHandler}
            value            = {dateValue}
            disabled         = {disabled}
            locale           = {uioptions.locale}
            minDate          = {minDate}
            maxDate          = {maxDate}
            showYearDropdown = {true}
            dropdownMode     = "select"
            {...fieldModeProps}
        />
    );
}
