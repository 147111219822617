import React from "react";
import ReactDOM from "react-dom";
import ResizeObserver from 'resize-observer-polyfill';
import BemClassName from "Cheops/BemClassName";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import uniqueId from "lodash/uniqueId";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import memoizeOne from "memoize-one";
import memoize from "memoizee";
import {TaskAnswersData} from "Lab/Model/Task";
import {ElementVerdict} from "Cheops/Model/Element";
import {MatchUserAnswer} from "Cheops/Model/UserAnswer";
import AnswerType from "Cheops/components/ModulePassing/Answers/AnswerType";
import './MatchAnswers.less';


interface State {
    selectedElement: number;
    disabledColumn: number;
    removeCandidate: number;
}

interface Props {
    isSmt: boolean;
    answersData: TaskAnswersData;
    isResult?: boolean;
    resultVerdict?: ElementVerdict;
    readOnly?: boolean;
    userAnswer?: MatchUserAnswer;
    fieldsAddClass: string;
    ignoreSettings: boolean;
    renderFieldsAs: typeof React.Component;

    onAnswer?(userAnswer: Props['userAnswer']): void;
}

export default class MatchAnswers extends React.Component<Props, State> implements AnswerType<MatchUserAnswer> {

    static EL_TARGET_RADIUS = 17; // 12 + 5; 5 is margin between other targets
    static EL_FIELD_HEIGHT = 96;

    static CURVE_COLORS = [
        "#C629C9",
        "#FF299D",
        "#7B29C9",
        "#7949FF",
        "#00D7E4",
        "#00C087",
        "#00A210",
        "#5FD900",
        "#CADC00",
        "#EEC600",
        "#FF9900",
        "#FF5C00",
        "#477BFF",
        "#47BDFF",
    ];

    static defaultProps: Partial<Props> = {
        ignoreSettings: false,
        readOnly: false,
        answersData: {},
        renderFieldsAs: Marked,
        userAnswer: [] as MatchUserAnswer,
        fieldsAddClass: null,
        onAnswer: () => {
        },
    };


    state: Readonly<State> = {
        selectedElement: null,
        disabledColumn: null,
        removeCandidate: null,
    };

    private domNode: Element = null;
    private timeoutResizeObserver: NodeJS.Timeout = null;

    componentDidMount(): void {
        this.domNode = ReactDOM.findDOMNode(this) as Element;
        this.resizeObserver.observe(this.domNode);
        this.updateCurvePositions();
    }


    componentDidUpdate(): void {

        this.updateCurvePositions();

    }


    componentWillUnmount(): void {
        this.timeoutResizeObserver && clearTimeout(this.timeoutResizeObserver)
        this.resizeObserver.unobserve(this.domNode);
        this.stopCurvesAnimation();
    }

    resizeObserver = new ResizeObserver(
        () => {
            this.timeoutResizeObserver = setTimeout(
                this.updateCurvePositions,
                0
            )
        }
    );

    getIsAnswerFilled(): boolean {

        const {userAnswer, answersData} = this.props;

        if (answersData.partialAnswer) {

            return (userAnswer || []).length > 0;

        }


        for (const answer of answersData.answers) {

            if (!(userAnswer || []).find((ua) => ua.includes(answer.id))) {

                return false;

            }

        }

        return true;

    }

    getAnswerHasError = (): boolean => {


        const {userAnswer, answersData} = this.props;

        if (!answersData.partialAnswer) {

            if (userAnswer && userAnswer.length === 0) {

                return false;

            }

            for (const answer of answersData.answers) {

                if (userAnswer !== null && !userAnswer.find((ua) => ua.includes(answer.id))) {

                    return true;

                }

            }


        }

        return false;

    };

    updateCurvesInterval: NodeJS.Timeout = null;
    updateCurvesStopTimeout: NodeJS.Timeout = null;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurveColor = memoize((from: number, to: number): string => {

        const id = +uniqueId();

        return MatchAnswers.CURVE_COLORS[id % MatchAnswers.CURVE_COLORS.length];

    });

    updateCurvePositions = (): void => {

        let paths = this.domNode?.querySelectorAll('.match_answers__line');

        // @ts-ignore
        for (let path of paths) {

            const from = path.getAttribute("data-from"); // IE Sucks. path.dataset is undefined
            const to = path.getAttribute("data-to");

            const fromEl: HTMLElement = this.domNode.querySelector(`.match_answers__target[data-id="${from}"][data-from="${from}"][data-to="${to}"]`);
            const toEl: HTMLElement = this.domNode.querySelector(`.match_answers__target[data-id="${to}"][data-from="${from}"][data-to="${to}"]`);

            if (!fromEl.offsetParent || !toEl.offsetParent) {

                return; //  In the case of collapsed card in lab, elements cannot be found

            }

            const startX = fromEl.offsetLeft + (fromEl.offsetParent as HTMLElement).offsetLeft + 12;
            const startY = fromEl.offsetTop + (fromEl.offsetParent as HTMLElement).offsetTop + 12;
            const endX = toEl.offsetLeft + (toEl.offsetParent as HTMLElement).offsetLeft + 12;
            const endY = toEl.offsetTop + (toEl.offsetParent as HTMLElement).offsetTop + 12;

            const direction = startX < endX ? 30 : -30;
            const dAttr = `M ${startX} ${startY} C ${startX + direction} ${startY},  ${endX - direction} ${endY},  ${endX} ${endY}`;

            path.setAttribute('d', dAttr);

        }

    }

    isConnectable(from: number, to: number = null): boolean {

        const {readOnly, answersData} = this.props;

        if (readOnly) {

            return false;

        }

        let columnIndex = null;
        let targetColumn = null;

        for (let [index, column] of answersData.columns.entries()) {

            if (column.elements.includes(from)) {

                columnIndex = index;

            } else if (to === null) {

                targetColumn = index;

            }

            if (to !== null && column.elements.includes(to)) {

                targetColumn = index;

            }

        }


        if (columnIndex === null || targetColumn === null) {

            throw Error("element was not found in columns");

        }

        if (columnIndex === targetColumn) {

            return false;

        }

        if (!answersData.columns[columnIndex].multiplicity) {

            const allSolutions = this.getAllSolutionsForIndex(from);

            if (allSolutions.length > 0) {

                return false;

            }

        }

        if (to == null) {

            let hasConnectable = false;

            for (let element of answersData.columns[targetColumn].elements) {

                const solutionIndex = this.findSolution(from, element);

                if (!answersData.columns[targetColumn].multiplicity) {

                    let allSolutions = this.getAllSolutionsForIndex(element);

                    if (allSolutions.length === 0) {

                        hasConnectable = true;

                    }

                } else if (solutionIndex === -1) {

                    hasConnectable = true;

                }

            }

            return hasConnectable;

        }

        if (!answersData.columns[targetColumn].multiplicity) {

            let allSolutions = this.getAllSolutionsForIndex(to);

            if (allSolutions.length > 0) {

                return false;

            }

        }

        const solutionIndex = this.findSolution(from, to);

        return solutionIndex === -1;

    }

    connect = (elementId: number, columnIndex: number): void => {

        const {removeCandidate, disabledColumn, selectedElement} = this.state;
        const {answersData, userAnswer, onAnswer} = this.props;

        if (!this.isConnectable(elementId)) {

            return;

        }

        if (removeCandidate) {

            this.setState({removeCandidate: null}, this.startCurvesAnimation);
            return;

        }

        if (selectedElement === null) {

            let hasNotConnected = false;

            const targetColumn = columnIndex ? 0 : 1;

            for (let element of answersData.columns[targetColumn].elements) {

                const solutionIndex = this.findSolution(element, elementId);

                if (solutionIndex === -1) {

                    hasNotConnected = true;
                    break;

                }

            }

            if (hasNotConnected) {

                this.setState({selectedElement: elementId, disabledColumn: columnIndex});

            }

            return;

        }


        if (selectedElement === elementId) {

            this.setState({selectedElement: null, disabledColumn: null});
            return;

        }

        if (disabledColumn === columnIndex) {

            return;

        }

        const solutionIndex = this.findSolution(selectedElement, elementId);

        if (solutionIndex >= 0) {

            return;

        }

        let solution = cloneDeep(userAnswer || []);

        solution.push([selectedElement, elementId]);

        solution.sort();

        onAnswer(solution);

        this.setState({selectedElement: null}, this.startCurvesAnimation);

    };


    findSolution(from: number, to: number): number {

        return (this.props.userAnswer || []).findIndex((fromTo) => (
            fromTo[0] === from && fromTo[1] === to)
            || (fromTo[1] === from && fromTo[0] === to));

    }

    removeFromSolution = (from: number, to: number): void => {

        let solution = cloneDeep(this.props.userAnswer || []);

        let index = this.findSolution(from, to);

        if (index === -1) {

            throw Error("Solution wasn't found");

        }

        solution.splice(index, 1);

        if (solution.length === 0) {

            solution = null;

        }

        this.props.onAnswer(solution);
        this.setState({removeCandidate: null});

    };

    targetClick(event: React.MouseEvent, elementId: number, fromId: number, toId: number): void {

        const {readOnly} = this.props;
        const {removeCandidate} = this.state;

        event.stopPropagation();


        if (readOnly) {

            return;

        }

        this.setState({selectedElement: null, disabledColumn: null});

        if (removeCandidate && removeCandidate !== elementId) {

            this.setState({removeCandidate: null}, this.startCurvesAnimation);
            return;

        }

        let solutions = this.getAllSolutionsForIndex(elementId);

        if (!solutions.length) {

            throw Error("Solution wasn't found");

        }

        if (solutions.length === 1) {

            this.removeFromSolution(solutions[0][0], solutions[0][1]);
            this.setState({removeCandidate: null}, this.startCurvesAnimation);
            return;

        }

        if (removeCandidate === null) {

            this.setState({removeCandidate: elementId}, this.startCurvesAnimation);
            return;

        }


        this.setState({removeCandidate: null}, this.startCurvesAnimation);
        this.removeFromSolution(fromId, toId);

    }

    startCurvesAnimation = (): void => {

        if (!this.updateCurvesInterval) {

            this.updateCurvesInterval = setInterval(() => this.updateCurvePositions(), 30);
            // this.updateCurvesStopTimeout = setTimeout(this.stopCurvesAnimation, 500);

        } else {

            clearInterval(this.updateCurvesStopTimeout);
            // this.updateCurvesStopTimeout = setTimeout(this.stopCurvesAnimation, 500);

        }

    };

    stopCurvesAnimation = (): void => {

        clearInterval(this.updateCurvesInterval);
        clearInterval(this.updateCurvesStopTimeout);
        this.updateCurvesInterval = null;

    };

    getAllSolutionsForIndex(index: number): [number, number][] {

        const solutions = ((this.props.userAnswer || []).reduce((accumulator: [number, number][], value) => {

            if (value.includes(index)) {

                accumulator.push(value);

            }

            return accumulator;

        }, []));


        solutions.sort((fromTo1, fromTo2) => {

            let targetElementId1 = fromTo1[0] === index ? fromTo1[1] : fromTo1[0];
            let targetElementId2 = fromTo2[0] === index ? fromTo2[1] : fromTo2[0];

            return targetElementId2 - targetElementId1;

        });

        return solutions;

    }

    getExpandedSolutionsVerticalOffset(columnIndex: number): number {

        const {answersData} = this.props;

        let elementIndex = answersData.columns[columnIndex].elements.indexOf(this.state.removeCandidate);
        const totalElements = answersData.columns[columnIndex].elements.length;

        if (elementIndex === -1) {

            throw Error("Element was not found");

        }

        const solutions = this.getAllSolutionsForIndex(this.state.removeCandidate);

        elementIndex++;

        let elementHeight = MatchAnswers.EL_FIELD_HEIGHT;
        let dotRadius = MatchAnswers.EL_TARGET_RADIUS;

        let min = -((elementIndex * elementHeight) - (elementHeight / 2));
        let max = ((totalElements - elementIndex) * (elementIndex * elementHeight) - (elementHeight / 2)) + elementHeight;


        const sideOffset = solutions.length * dotRadius;

        if (-sideOffset < min) {

            return min + sideOffset;

        }

        if (sideOffset > max) {

            return -(sideOffset - max);

        }

        return 0;

    }

    sortElementsWithCombineSort = (columns: TaskAnswersData['columns']): number => {

        const {ignoreSettings, answersData} = this.props;

        if (ignoreSettings || !answersData.reorderSolution) {

            return;

        }

        columns[1].elements.sort((a, b) => {

            const solutionsA = this.getAllSolutionsForIndex(a);
            const solutionsB = this.getAllSolutionsForIndex(b);

            if (!solutionsA[0] && !solutionsB[0]) {

                return 0;

            }

            if (!solutionsA[0]) {

                return 1;

            }

            if (!solutionsB[0]) {

                return -1;

            }

            const targetElementIdA = solutionsA[0][0] === a ? solutionsA[0][1] : solutionsA[0][0];
            const targetElementIdB = solutionsB[0][0] === b ? solutionsB[0][1] : solutionsB[0][0];

            const indexA = columns[0].elements.indexOf(targetElementIdA);
            const indexB = columns[0].elements.indexOf(targetElementIdB);

            return indexA - indexB;

        });

        columns[0].elements.sort((a, b) => {

            const solutionsA = this.getAllSolutionsForIndex(a);
            const solutionsB = this.getAllSolutionsForIndex(b);

            if (!solutionsA[0] && !solutionsB[0]) {

                return 0;

            }

            if (!solutionsA[0]) {

                return 1;

            }

            if (!solutionsB[0]) {

                return -1;

            }

            const targetElementIdA = solutionsA[0][0] === a ? solutionsA[0][1] : solutionsA[0][0];
            const targetElementIdB = solutionsB[0][0] === b ? solutionsB[0][1] : solutionsB[0][0];

            const indexA = columns[1].elements.indexOf(targetElementIdA);
            const indexB = columns[1].elements.indexOf(targetElementIdB);

            return indexA - indexB;

        });

    };

    render(): React.ReactNode {

        const {removeCandidate, selectedElement} = this.state;
        const {answersData, isSmt, renderFieldsAs, readOnly, fieldsAddClass} = this.props;

        let userAnswer = [...this.props.userAnswer || [] ];

        if (removeCandidate) {

            userAnswer = userAnswer.sort((fromTo) => (fromTo.includes(removeCandidate) ? 1 : -1));

        }

        const wrapperClassName = new BemClassName('match_answers');
        wrapperClassName.appendStatusIf(readOnly, 'read-only');


        return <div className={wrapperClassName.toString()}>
            <svg className="match_answers__lines">
                {userAnswer.map((fromTo) => {

                    const className = new BemClassName('match_answers__line');

                    if (removeCandidate) {

                        className.appendStatusIf(!fromTo.includes(removeCandidate), 'faded');

                    }

                    return <path
                        key={`${fromTo[0]}_${fromTo[1]}`}
                        strokeWidth={4}
                        data-from={fromTo[0]}
                        data-to={fromTo[1]}
                        className={className.toString()}
                        stroke={this.getCurveColor(fromTo[0], fromTo[1])}
                        fill="transparent"
                    />;

                })}
            </svg>
            <div>
                {answersData.columns.map((column, columnIndex) => {

                    this.sortElementsWithCombineSort(answersData.columns);

                    return <div key={columnIndex} className="match_answers__fields"
                                data-column_index={columnIndex}
                    >
                        {column.elements.map((elementId) => {

                            const answer = answersData.answers.find((el) => el.id === elementId);

                            let className = new BemClassName('match_answers__field');
                            className.appendStatusIf(selectedElement === elementId, 'selected');
                            className.appendAdditionalClasses(fieldsAddClass);

                            if (selectedElement) {

                                if (!column.elements.includes(selectedElement)) {

                                    className.appendStatusIf(this.isConnectable(selectedElement, elementId), "clickable");

                                }

                            }

                            if (selectedElement === null) {

                                className.appendStatusIf(this.isConnectable(elementId), "clickable");

                            }

                            let solutions = this.getAllSolutionsForIndex(elementId);

                            let focusedDotsOffsets = 0;

                            if (removeCandidate === elementId) {

                                focusedDotsOffsets = this.getExpandedSolutionsVerticalOffset(columnIndex);

                            }

                            let RenderComponent = renderFieldsAs;

                            let imgPreUrl = "";

                            if (!isSmt) {

                                imgPreUrl = `${CONFIG.Api.noopolis.url}/content/_image/`;

                            } else if (isSmt) {

                                if (CONFIG.Api.smtWebPortal) {

                                    imgPreUrl = `${CONFIG.Api.smtWebPortal.url}/content/_image/`;

                                } else {

                                    imgPreUrl = `${CONFIG.Api.webPortal.url}/content/_image/`;

                                }

                            }

                            let elementContent: React.ReactNode = answer.value;

                            if (answer.image) {

                                elementContent = <img
                                    src={imgPreUrl + answer.image}
                                    onLoad={this.updateCurvePositions}
                                />;

                            }

                            return <div
                                className={className.toString()}
                                data-column_index={columnIndex}
                                onClick={() => this.connect(elementId, columnIndex)}
                                key={elementId}
                            >

                                <div className='match_answers__text'>
                                    <RenderComponent element_id={elementId} column_index={columnIndex}>
                                        {elementContent}
                                    </RenderComponent>
                                </div>
                                <div className="match_answers__targets">
                                    {solutions.map((solution, targetIndex) => {

                                        targetIndex++;
                                        const solutionIndex = this.findSolution(solution[0], solution[1]);

                                        const targetClassName = new BemClassName('match_answers__target');
                                        const styles: React.CSSProperties = {background: this.getCurveColor(solution[0], solution[1])};

                                        targetClassName.appendStatusIf(this.state.removeCandidate === elementId, "expanded");

                                        if (this.state.removeCandidate !== null) {

                                            targetClassName.appendStatusIf(!solution.includes(this.state.removeCandidate), 'faded');

                                        }

                                        if (this.state.removeCandidate === elementId) {

                                            const targetHeight = MatchAnswers.EL_TARGET_RADIUS * 2;
                                            styles.marginTop = focusedDotsOffsets - (targetHeight * targetIndex) + (targetHeight * solutions.length / 2);

                                        }

                                        return <div
                                            className={targetClassName.toString()}
                                            data-id={elementId}
                                            data-from={solution[0]}
                                            data-to={solution[1]}
                                            data-column_index={columnIndex}
                                            key={solutionIndex}
                                            style={styles}
                                            onClick={(e) => this.targetClick(e, elementId, solution[0], solution[1])}
                                            onTransitionEnd={this.stopCurvesAnimation}
                                        />;

                                    })}
                                    {solutions.length === 0 && this.state.selectedElement === elementId
                                    && <div className="match_answers__target match_answers__target--single"
                                            data-id={elementId}
                                    />}
                                </div>
                            </div>;

                        })}
                    </div>;

                })}
            </div>
        </div>;

    }

}
