import {BehaviorSubject}        from "rxjs";
import {docKeyDown$}            from "@sirius/ui-lib/src/subjects/DocKeyDown";
import {docKeyUp$}              from "@sirius/ui-lib/src/subjects/DocKeyUp";

const printScreen$ = new BehaviorSubject<boolean>( false );

const detectionPrintScreen = (event: KeyboardEvent) => {
    const {key, shiftKey, metaKey} = event;
    const keyWithModifier = {
        Meta        : shiftKey ,
        Shift       : metaKey,
        PrintScreen : true,
    }

    type Keys = keyof typeof keyWithModifier;

    return keyWithModifier[key as Keys] ?? false
}

docKeyDown$.subscribe(
    (e: KeyboardEvent) => {
        const printScreenKeyDown = detectionPrintScreen(e);
        if (printScreenKeyDown) {
            printScreen$.getValue() === false && printScreen$.next(true);
        }
    }
);
docKeyUp$.subscribe(
    (e: KeyboardEvent) => {
        const printScreenKeyUp = detectionPrintScreen(e);
        if (printScreenKeyUp) {
            printScreen$.getValue() === true && printScreen$.next(false);
        }
    }
);

export {printScreen$};
