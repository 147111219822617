import React, {FC}          from "react";
import {HBox, LGap}         from "@sirius/ui-lib/src/blocks/Layout";
import {getOptions}         from "@sirius/ui-lib/src/blocks/SchemaField/ui/default/utils";
import {SchemaFieldProps}   from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {SmtLocaleString}    from "Smt/SmtLocaleBlock";

export const ErrorField: FC<SchemaFieldProps> = (props) => {
    const {
        block,
        schema,
        deep,
        data,
        validation,
        showErrorMsg: _showErrorMsg,
    } = props;
    const {format} = schema;
    const {getError, formatsValidator, i18nProject, showErrorMsg: showErrorMsgOptions} = getOptions(props, 'ErrorField');
    const {isError, keyword, i18nKey} = getError({deep, validation});

    const showErrorMsg              = _showErrorMsg ?? showErrorMsgOptions;
    const isErrorFormatType         = isError && keyword === 'format';
    const isDefinedFormatValidator  = isErrorFormatType && formatsValidator && format in formatsValidator;

    const errorKey = isDefinedFormatValidator
        ? formatsValidator[format](`${data}`) ?? i18nKey
        : i18nKey
    ;

    const showError = showErrorMsg && isErrorFormatType && errorKey;

    const validationClassName =
        block.el('error') +
        'text-xs'
    ;

    return (
        showError
            ?   <HBox className={validationClassName} >
                    <SmtLocaleString k={`common.${i18nProject}-validation.${errorKey}`}/>
                    <LGap />
                </HBox>
            : null
    );
}
