/* eslint-disable */
export default function (state = {
    user_email: null,
    auth_context: null,
    form_token_ttl: 90,
    auth_token: localStorage.getItem(CONFIG.Auth.localstorage_key) || null,
    user_list_data: {},
}, action) {
    switch (action.type) {
        case "USER_EMAIL":
            return {
                ...state,
                user_email: action.status
            };
        case "AUTH_CONTEXT":
            return {
                ...state,
                auth_context: action.status
            };
        case "GET_FORM_TOKEN_FULFILLED":
            return {
                ...state,
                form_token: action.payload,
            };
        case "SET_AUTH_TOKEN":
            return {
                ...state,
                auth_token: action.token
            };
        case "GET_USER_LIST_DATA_FULFILLED":

            let user_list_data = {};

            for (let i in action.payload.result) {
                user_list_data[action.payload.result[i].id] = action.payload.result[i]
            }

            return {
                ...state,
                user_list_data,
            };
        case `GET_FORM_TOKEN_REJECTED`:
            return {
                ...state
            };
        default:
            return state
    }
}
