import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const UserIdIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
            fill="#7A7A7A"
        />
        <path d="M10.35 14.01C7.62 13.91 2 15.27 2 18V19C2 19.55 2.45 20 3 20H10.35C10.35 16.5 10.35 16 10.35 14.01Z"
              fill="#7A7A7A"
        />
        <path
            d="M14.4258 16.5508V21.1738C14.4258 21.4941 14.3496 21.7363 14.1973 21.9004C14.0449 22.0645 13.8516 22.1465 13.6172 22.1465C13.3828 22.1465 13.1914 22.0625 13.043 21.8945C12.8984 21.7266 12.8262 21.4863 12.8262 21.1738V16.5977C12.8262 16.2812 12.8984 16.043 13.043 15.8828C13.1914 15.7227 13.3828 15.6426 13.6172 15.6426C13.8516 15.6426 14.0449 15.7227 14.1973 15.8828C14.3496 16.043 14.4258 16.2656 14.4258 16.5508ZM13.6348 14.8984C13.4121 14.8984 13.2207 14.8301 13.0605 14.6934C12.9043 14.5566 12.8262 14.3633 12.8262 14.1133C12.8262 13.8867 12.9062 13.7012 13.0664 13.5566C13.2305 13.4082 13.4199 13.334 13.6348 13.334C13.8418 13.334 14.0254 13.4004 14.1855 13.5332C14.3457 13.666 14.4258 13.8594 14.4258 14.1133C14.4258 14.3594 14.3477 14.5527 14.1914 14.6934C14.0352 14.8301 13.8496 14.8984 13.6348 14.8984Z"
            fill="#7A7A7A"
        />
        <path
            d="M20.4668 21.2324V21.0742C20.252 21.3203 20.0391 21.5215 19.8281 21.6777C19.6211 21.8301 19.3945 21.9453 19.1484 22.0234C18.9062 22.1055 18.6406 22.1465 18.3516 22.1465C17.9688 22.1465 17.6133 22.0664 17.2852 21.9062C16.9609 21.7422 16.6797 21.5098 16.4414 21.209C16.2031 20.9082 16.0215 20.5547 15.8965 20.1484C15.7754 19.7422 15.7148 19.3027 15.7148 18.8301C15.7148 17.8301 15.959 17.0508 16.4473 16.4922C16.9355 15.9336 17.5781 15.6543 18.375 15.6543C18.8359 15.6543 19.2246 15.7344 19.541 15.8945C19.8574 16.0508 20.166 16.293 20.4668 16.6211V14.2598C20.4668 13.9316 20.5312 13.6836 20.6602 13.5156C20.793 13.3477 20.9805 13.2637 21.2227 13.2637C21.4648 13.2637 21.6504 13.3418 21.7793 13.498C21.9121 13.6504 21.9785 13.877 21.9785 14.1777V21.2324C21.9785 21.5371 21.9082 21.7656 21.7676 21.918C21.627 22.0703 21.4453 22.1465 21.2227 22.1465C21.0039 22.1465 20.8223 22.0684 20.6777 21.9121C20.5371 21.752 20.4668 21.5254 20.4668 21.2324ZM17.3203 18.8828C17.3203 19.3164 17.3867 19.6855 17.5195 19.9902C17.6562 20.2949 17.8418 20.5254 18.0762 20.6816C18.3105 20.834 18.5664 20.9102 18.8438 20.9102C19.125 20.9102 19.3809 20.8379 19.6113 20.6934C19.8457 20.5449 20.0312 20.3203 20.168 20.0195C20.3086 19.7148 20.3789 19.3359 20.3789 18.8828C20.3789 18.457 20.3086 18.0918 20.168 17.7871C20.0312 17.4785 19.8438 17.2441 19.6055 17.084C19.3711 16.9199 19.1133 16.8379 18.832 16.8379C18.5391 16.8379 18.2773 16.9219 18.0469 17.0898C17.8164 17.2539 17.6367 17.4922 17.5078 17.8047C17.3828 18.1133 17.3203 18.4727 17.3203 18.8828Z"
            fill="#7A7A7A"
        />
    </svg>
</SvgIcon>;


export default UserIdIcon;
