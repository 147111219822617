import {Format as AJVFormat}                            from "ajv";
import {AjvCustomFormats, FormatsValidator, fValidator} from "@sirius/ui-lib/src/blocks/SchemaField/@types";

const mailFormatRegExp = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+)+$/;
const voshFormatRegExp = /^v\d{2}\.\d{3}.\d{3}\s*$/;

const email = (value: string) =>
    mailFormatRegExp.test(value)
;

const checkVoshFormat = (vosh: string) =>
    voshFormatRegExp.test(vosh)
;

const checkVoshCheckSum = (vosh: string) => {
    const value = vosh.split('').map( (a) => parseInt(a) );
    /* v11.111.111 */
    return  (value[10] % 10 === (value[4] + (value[5] * 3) + (value[6] * 7) + (value[8] * 9) + value[9]) % 10) ||
            (value[10] % 10 === (value[4] + (value[5] * 9) + (value[6] * 7) + (value[8] * 3) + value[9]) % 10)
    ;
};

const vosh: AJVFormat = (value: string) =>
    checkVoshFormat(value) &&
    checkVoshCheckSum(value)
;

const checkSnilsOnlyDigits = (snils: string) =>
    /^\d+$/.test(snils)
;

const checkSnilsLength = (snils: string) =>
    snils.length === 11
;

const checkSnilsCheckSum = (snils: string) => {
    /* 17535146171 */
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(snils[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
        checkDigit = sum;
    } else if (sum > 101) {
        checkDigit = parseInt(`${sum % 101}`);
        if (checkDigit === 100) {
            checkDigit = 0;
        }
    }
    return checkDigit === parseInt(snils.slice(-2));
}

const snils: AJVFormat = (value: string) =>
    checkSnilsOnlyDigits(value) &&
    checkSnilsLength(value)     &&
    checkSnilsCheckSum(value)
;

export const ajvCustomFormats: AjvCustomFormats = {
    email,
    snils,
    vosh
};

const snilsValidator: fValidator = (value: string) => {
    if (!checkSnilsOnlyDigits(value)) {
        return 'format_snils_only_digits';
    } else if (!checkSnilsLength(value)) {
        return 'format_snils_eleven';
    } else if (!checkSnilsCheckSum(value)) {
        return 'format_snils_sum';
    } else {
        return null;
    }
}

export const formatsValidator: FormatsValidator = {
    snils: snilsValidator
};
