import React                                from "react";
import ReactDOM                             from "react-dom";
import Axios                                from "axios";
import {Screen}                             from "@sirius/ui-lib/src/blocks/Layout";
import {LocaleDate}                         from "@sirius/ui-lib/src/blocks/LocaleBlock";
import LangSwitcher                         from "@sirius/ui-lib/src/blocks/LangSwitcher/LangSwitcher";
import {
    date,
    formatDate,
    getContestIdAndSesionId,
    getNowDate
}                                           from "Smt/helpers";
import MarkedWithVideo                      from "Smt/MarkedWithVideo";
import UserInfo                             from "Smt/UserInfo";
import Prizes                               from "Smt/IndexPage/Prize/Prizes";
import {SmtLocalePlural, SmtLocaleString}   from "Smt/SmtLocaleBlock";
import {DocumentTitleLocale}                from "Smt/SmtLocaleBlock/DocumentTitleLocale";
import {StartButton}                        from "Smt/IndexPage/StartButton";
import {TaskPageData$}                      from "Smt/TaskPage/TaskPageDataSubject";
import TaskPage                             from "Smt/TaskPage/TaskPage";
import CircularProgress                     from "Smt/Ui";
import                                          "./IndexPage.less";

const TasksStructure = (data) => {
    let tasks = {};

    if (data.taskGroups) {
        data.taskGroups.forEach(
            (a) => {
                let start = a[0] - 1;
                let end   = a[1] - 1;
                let first_task = data.tasks[start];
                data.tasks[start] = {};
                data.tasks[start][start] = first_task;
                for (let i = start + 1; i <= end; i++) {
                    data.tasks[start][i] = data.tasks[i];
                    delete data.tasks[i];
                }
            }
        );
    }

    data.tasks.map(
        (task_hash, task_num) => {
            tasks[task_num] = task_hash;
        }
    );

    return tasks;
}

class PreStartPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            task_count: props.task_count,
            popupState: props.state,
            userToken:  props.userToken,
            metadata:   {}
        }
    }

    componentDidUpdate() {

        if (this.state.popupState === 'show') {
            document.querySelector('body').classList.add('no_scrollable');
        } else {
            document.querySelector('body').classList.remove('no_scrollable');
        }
    }

    UNSAFE_componentWillReceiveProps({state, userToken, task_count}) {
        this.setState({
            task_count: task_count,
            popupState: state,
            userToken: userToken
        });
    }


    startClick = () => {

        if (!this.state.userToken) {
            console.error("User token doesn't exist;");
        }

        Axios({
            method: "POST",
            url: `/smt-portal/test/session?tabid=${window.appId}`,
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json',
            },
            data: `${this.state.userToken}`,
        })
            .then(
                (result) => {
                    const [data, tasks_token] = result.data;
                    const {metadata, tsContest} = this.props
                    const TaskPageData = {
                        issuedUntil: new Date(data.issuedUntil),
                        issuedAt:    new Date(data.issuedAt),
                        tasks:       TasksStructure(data),
                        tasks_token,
                        metadata,
                        tsContest,
                    };
                    TaskPageData$.next({...TaskPageData});
                    window.bugReportToken = tasks_token;
                    ReactDOM.render(
                        <Screen>
                            <TaskPage {...TaskPageData} />
                        </Screen>,
                        document.getElementById("index")
                    );
                },
            )
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    location.href = '/';
                    return;
                }
                console.error(error);
            });
    };

    render() {
        return (
            <div className={"prestart-popup prestart-popup--" + this.state.popupState}>
                <div className="prestart-popup__overlay"></div>
                <div className="prestart-popup__body">
                    <div
                        className="prestart-popup__remain">{this.props.duration} <SmtLocalePlural
                        k="common.format_plural.minutes" value={this.props.duration} /></div>
                    <div className="prestart-popup__condition">
                        <SmtLocaleString k="common.index_page.are_you_ready" id="value" />
                        <br />
                        <SmtLocaleString
                            k="common.pre_start_popup.time_for_contest"
                            id="value"
                            values={{number: this.props.duration, br: <br />}}
                        />
                        {` `}
                        <SmtLocalePlural k="common.format_plural.minutes" value={this.props.duration} />
                    </div>
                    <div className="status_row">
                        <div className="status_row_item">
                            <div className="status_row_title">
                                <SmtLocaleString k="common.pre_start_popup.tasks" id="value" />
                            </div>
                            <div className="status_row_text">{this.state.task_count}</div>
                        </div>
                        <div className="status_row_item">
                            <div className="status_row_title">
                                <SmtLocaleString k="common.pre_start_popup.hand_in" id="value" />
                            </div>
                            <div
                                className="status_row_text">{this.props.time_text}</div>
                        </div>
                        <div className="status_row_item">
                            <div className="status_row_title">
                                <SmtLocaleString k="common.pre_start_popup.time" id="value" />
                            </div>
                            <div className="status_row_text">
                                <SmtLocaleString
                                    k="common.pre_start_popup.duration"
                                    id="value"
                                    values={{number: this.props.duration, br: <br />}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="prestart-popup__buttons">
                        <div className="prestart-popup__button prestart-popup__button--cancel"
                             onClick={this.props.closePrestartPopup}>
                            <SmtLocaleString k="common.pre_start_popup.not_now" id="value" />
                        </div>
                        <div className="prestart-popup__button prestart-popup__button--start"
                             onClick={this.startClick}>
                            <SmtLocaleString k="common.pre_start_popup.start" id="value" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default class IndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            prestartPopupState: 'hidden',
            resultsData: {},
        };
    }

    showPrestartPopup() {
        this.setState({
            prestartPopupState: 'show'
        })

    }

    closePrestartPopup() {
        this.setState({
            prestartPopupState: 'hidden'
        })
    }

    componentDidMount() {

        let search_params = new URLSearchParams(location.search);

        if (search_params.has('user_code')) {
            UserInfo.logout(true);
            return;
        }

        const contestData = this.props?.contestData;

        if (contestData) {
            this.setState({
                isLoaded: true,
                ...this.getStateByData(contestData)
            });
        } else {
            this.fetchContest()
                .then(data => this.setState(this.getStateByData(data)))
                .catch(error => this.setState({error}))
                .finally(() => this.setState({isLoaded: true}))
        }
    };

    fetchContest = () => {
        const {contestId, sessionId} = getContestIdAndSesionId();
        const url = `/smt-portal/test?regQuizId=${contestId}&sid=${sessionId}`;
        const headers = {'cache-control': 'no-cache'}
        return Axios.get(url, {headers}).then(({data}) => data);
    }

    getStateByData = ({tsInfo, tsUser, tsContest, metadata = {}}) => {
        const stateData = {
            isLoaded: true,
            tsContest: {
                ...tsContest,
                startDate: new Date(tsContest.startTime),
                endDate: new Date(tsContest.endTime),
                sections: tsContest.sections || [],
            },
            tsInfo,
            tsUser,
            metadata,
        };
        TaskPageData$.next({...stateData});

        return stateData;
    }


    renderError = ({error}) => {
        if (error.response.status === 401 || error.response.status === 404) {
            if (CONFIG.IsNoopolis) {
                let url_matches = location.hash.match(/contest\/(\d+)\/(\d+)$/);
                location.href = CONFIG.Noopolis.url + "/#/course/" + url_matches[1] + "/final_test";
                return;
            }
            UserInfo.logout(true);
        }
        console.error(error);
        return null;
    }

    renderDocumentTitle = () => {

        const {tsContest} = this.state;
        const {title} = tsContest;

        const template = CONFIG.IsNoopolis
            ? `common.document.noo_title`
            : `common.document.title`;

        return <DocumentTitleLocale k={template} values={{title}} />;

    };

    render() {

        if (!this.state.isLoaded) {
            return (<CircularProgress centerOfWindow={true} />);
        } else if (this.state.error) {
            return this.renderError(this.state);
        }

        let {startDate, endDate} = this.state.tsContest;

        let start_end_time_title = <SmtLocaleString k="common.index_page.hand_in" id="value" />;
        let start_end_time_text = date('d.m H:i', endDate.getTime() / 1000);

        let time_title = <SmtLocaleString k="common.index_page.time" id="value" />;
        let time_text = <SmtLocaleString
            k="common.index_page.duration"
            values={{number: this.state.tsContest.sessionDuration}}
        />;

        let now_date = getNowDate();


        if (this.state.tsInfo.tag === "NotYetStarted") {

            start_end_time_title = <SmtLocaleString
                k={`${CONFIG.currentInstance}.status_row.not_yet_started`}
            />;

            start_end_time_text = <SmtLocaleString
                k={`${CONFIG.currentInstance}.status_row.not_yet_started_date`}
                defaultMessage=""
                values={{
                    date: <LocaleDate dateValue={startDate} dateOptions={{day: "numeric", month: "numeric"}} />
                }}
            />;
        }


        if (this.state.tsInfo.tag === "ContestRunning") {
            if (this.state.tsInfo.contents.tag === 'CanCreateSession') {
                start_end_time_title = <SmtLocaleString k="common.index_page.hand_in" />;
                start_end_time_text = date('d.m H:i', endDate.getTime() / 1000);
            }

            if (this.state.tsInfo.contents.tag === 'ExpiredSession') {
                start_end_time_title = <SmtLocaleString k="common.index_page.over" />;
                start_end_time_text = date('d.m H:i', endDate.getTime() / 1000);
            }

            if (this.state.tsInfo.contents.tag === 'ValidSession') {
                time_title = <SmtLocaleString k="common.index_page.time_left" />;

                let issuedUntil = new Date(this.state.tsInfo.contents.contents[0].issuedUntil);

                time_text = <SmtLocaleString
                    k="common.index_page.duration"
                    values={{number: Math.ceil((issuedUntil - now_date) / 1000 / 60)}}
                />;

                start_end_time_title = '';
                start_end_time_text = '';
            }
        }

        if (this.state.tsInfo.tag === "ContestFinished") {
            start_end_time_title = <SmtLocaleString k={`${CONFIG.currentInstance}.status_row.ended`} />;

            if (now_date < endDate) {
                start_end_time_title = <SmtLocaleString k="common.index_page.over" />;
            }
            start_end_time_text = date('d.m', endDate.getTime() / 1000);
        }


        let userToken = "";

        if (typeof this.state.tsInfo.contents.contents === "string") {
            userToken = this.state.tsInfo.contents.contents;
        }

        let end_time_formated = formatDate(this.state.tsContest.endDate, false, true);
        let status_row_styles = {};
        let status_row_class = "status_row";
        let header_styles = {};
        let header_class = "header";
        let user_info_text_color;

        if (this.state.metadata.statusBg) {
            status_row_styles["background"] = this.state.metadata.statusBg;
        }

        if (this.state.metadata.statusColor) {
            status_row_class += " status_row--custom-color";
            status_row_styles["color"] = this.state.metadata.statusColor;
        }


        if (this.state.metadata.userInfoColor) {
            user_info_text_color = this.state.metadata.userInfoColor;
        }

        if (this.state.metadata.headerColor) {
            header_styles["color"] = this.state.metadata.headerColor;
            header_class += " header--custom-color";
        }


        if (this.state.metadata.mainBgImage) {
            header_styles['backgroundImage'] = 'url(' + CONFIG.Api.webPortal.url + "/content/_image/" + this.state.metadata.mainBgImage + ')';
        }

        if (this.state.metadata.mainBgColor) {
            header_styles["backgroundColor"] = this.state.metadata.mainBgColor;
        }

        if (this.state.metadata.mainBgAlign) {
            header_styles["backgroundPosition"] = this.state.metadata.mainBgAlign;
        }

        let course_id = null;

        if (CONFIG.IsNoopolis) {
            let url_matches = location.hash.match(/contest\/(\d+)\/(\d+)$/);
            course_id = url_matches[1];
        }

        const CR = (CONFIG?.DeployName || '').toLowerCase() === 'cpm' ? 'CPM' : 'SIRIUS';

        return (
            <Screen>
                {this.renderDocumentTitle(this.state)}
                { !CONFIG.IsNoopolis && <LangSwitcher /> }
                <div className={header_class} style={header_styles}>

                    <div className="header__wrapper">
                        {!CONFIG.IsNoopolis &&
                        <UserInfo user_name={this.state.tsUser.userName} text_color={user_info_text_color} />
                        }
                        {!!CONFIG.IsNoopolis &&
                        <div className="course_header">
                            <a href={CONFIG.Noopolis.url + "/#/course/" + course_id + '/final_test'}
                               className="course_header__back_arrow" />
                            <div className="course_header__title">
                                <SmtLocaleString k="common.index_page.final_test" id="value" />
                            </div>
                        </div>
                        }
                        <div className="header__company">{this.state.tsContest.owner}</div>
                        <div className="header__title">{this.state.tsContest.title}</div>
                        <div className={status_row_class}>
                            <div className="status_row_item" style={status_row_styles}>
                                <div className="status_row_title">
                                    <SmtLocaleString k="common.index_page.tasks" id="value" />
                                </div>
                                <div className="status_row_text">{this.state.tsContest.task_num}</div>
                            </div>
                            {start_end_time_title &&
                            <div className="status_row_item" style={status_row_styles}>
                                <div className="status_row_title">{start_end_time_title}</div>
                                <div
                                    className="status_row_text">{start_end_time_text}</div>
                            </div>
                            }
                            <div className="status_row_item" style={status_row_styles}>
                                <div className="status_row_title">{time_title}</div>
                                <div className="status_row_text">{time_text}</div>
                            </div>
                        </div>
                        {
                            this.state.isLoaded &&
                            <StartButton
                                tsInfo={this.state.tsInfo}
                                tsUser={this.state.tsUser}
                                contest={this.state.tsContest}
                                tsContest={this.state.tsContest}
                                showPrestartPopup={this.showPrestartPopup.bind(this)}
                                metadata={this.state.metadata}
                                userToken={userToken}
                                setResultsData={(data) => this.setState({resultsData: data})}
                            />
                        }
                    </div>
                </div>

                <div className="content content-description">
                    <Prizes data={this.state.resultsData.prize} metadata={this.state.metadata} />
                    <MarkedWithVideo videoWrapperClassName="content-description__inner-video">
                        {this.state.tsContest.description}
                    </MarkedWithVideo>
                </div>
                <footer>
                    <SmtLocaleString
                        k={`${CONFIG.currentInstance}.index.copyright_${CR}`}
                        id="value"
                        values={{currentYear: new Date().getFullYear(), br: <br />}}
                    />
                </footer>
                <PreStartPopup
                    state={this.state.prestartPopupState}
                    duration={this.state.tsContest.sessionDuration}
                    task_count={this.state.tsContest.task_num}
                    tsContest={this.state.tsContest}
                    metadata={this.state.metadata}
                    closePrestartPopup={this.closePrestartPopup.bind(this)}
                    time_title={time_title} time_text={end_time_formated} userToken={userToken}
                />
            </Screen>
        );
    }
};
