import React, {useEffect, useMemo, useState} from 'react';
import {HBox, VBox}                         from "@sirius/ui-lib/src/blocks/Layout";
import {Button, Icon}                       from '@sirius/ui-lib/src/blocks/Controls';
import {AppModal}                           from 'Smt/blocks/AppModal';
import {WarningIcon}                        from '@sirius/ui-lib/src/blocks/CustomIcons';
import {useWindowIsMobile}                  from '@sirius/ui-lib/src/subjects/WindowIsMobile';
import {useLocale}                          from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import {ValidateFieldResult, Validation}    from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {SmtLocaleString}                    from "Smt/SmtLocaleBlock";
import {SchemaField, onInputSF$}            from "Smt/Auth/SchemaField";
import {LOGIN_STAGE}                        from 'Smt/Auth/LoginPage';
import {UserFormProps}                      from "../@types";
import {UIOptions}                          from "./SchemaField/UIOptions";
import {UISchema}                           from "./SchemaField/UISchema";
import {block}                              from "./block";
import                                        './styles/index.less';



// @ts-ignore
const {result: userCodeForm}  = { ...require('./schema/user-code-form.yaml') };

const userFormTitleLocaleKey = {
    userDetailInfo:         'common.user_form.title',
    userParticipantCode:    'common.login_page.participant_code_title',
};

const userFormSubmitLocaleKey = {
    userDetailInfo:         `${CONFIG.currentInstance}.user_form.go_btn`,
    userParticipantCode:    'common.login_page.enter',
};

const errWarningLocaleKey = {
    userDetailInfo:         'common.registration.has-errors',
    userParticipantCode:    'common.login_page.participant_code_error',
};


export const UserForm: React.FC<UserFormProps> = (
    {
        schema: _schema,
        options = {},
        loginStage,
        onRegister,
        errorStatus,
    }
) => {
    const locale    = useLocale();
    const isMobile  = useWindowIsMobile();

    const schema = useMemo( () =>
        loginStage === LOGIN_STAGE.participantCode
            ? userCodeForm
            : _schema ?? userCodeForm
    ,[loginStage, _schema]);

    const [validation,   setValidation  ] = useState<Validation>();
    const [onValidate,   setOnValidate  ] = useState<any>(void(0));
    const [valid,        setValid       ] = useState<boolean>(false);
    const [errors,       setErrors      ] = useState<any[]>([]);
    const [value,        setValue       ] = useState<any>(void(0));
    const [hasErrors,    setHasErrors   ] = useState<boolean>(!!errorStatus);
    const [isChanged,    setIsChanged   ] = useState<boolean>(false);
    const [hasShowModal, setHasShowModal] = useState<boolean>(false);
    const [disableResend, setDisableResend] = useState<boolean>(false);

    useEffect(() => {
            const onInputSubj = onInputSF$.subscribe( () => setIsChanged(true) );

            return () => {
                onInputSubj && onInputSubj.unsubscribe();
            };
        }, []
    );

    const resetForm = (initVal?: any) => {
        setValue(initVal);
        setValid(false);
        setIsChanged(false);
        setErrors([]);
        setHasShowModal(false)
    };

    useEffect( resetForm, [loginStage]);

    const onValidateHandler = (validateResult: ValidateFieldResult = {}) => {
        const {isValid, validation, errors} = validateResult;
        setValidation(validation);
        setErrors(errors);
        setValid(isValid);
    };

    useEffect( () => {
        const hasOnValidate = { onValidate: isChanged ? onValidateHandler : void(0) };
        setOnValidate(hasOnValidate);
    }, [isChanged]);

    useEffect( () => {
        if (isChanged && errors?.length === 0) {
            setHasErrors(false)
        }
    }, [isChanged, errors]);

    useEffect( () => {
        const hasErrStatus = !!errorStatus;
        const hasStatusErr5xx = hasErrStatus && errorStatus >= 500 && errorStatus < 600;
        if (hasStatusErr5xx) {
            setDisableResend(true);
            setTimeout( () => setDisableResend(false), 4000);
        }
        setHasErrors(hasErrStatus);
        setHasShowModal(hasStatusErr5xx);
    }, [errorStatus]);

    const onRegisterHandler = () => {
            setIsChanged(false);
            valid && onRegister(value);
    };

    const onCloseModal = () => setHasShowModal(false);

    const onActionModal = () => {
        onRegisterHandler();
        onCloseModal();
    };


    return (
        <VBox className={block}>
            <HBox className = {block.el('form-title')}>
                <SmtLocaleString k={userFormTitleLocaleKey[loginStage]}/>
            </HBox>
            { schema &&
               <>
                    <SchemaField
                        uioptions           = {{...UIOptions, options, locale}}
                        uischema            = {UISchema}
                        schema              = {schema}
                        data                = {value}
                        onChange            = {setValue}
                        block               = {block}
                        validation          = {validation}
                        {...onValidate}
                    />
                    <Button
                        className = {block.el('register-btn')}
                        mode      = "primary"
                        form      = "rounded"
                        disabled  = {!valid || disableResend}
                        onAction  = {onRegisterHandler}
                    >
                        <SmtLocaleString k={userFormSubmitLocaleKey[loginStage]}/>
                    </Button>
                    { hasErrors  &&
                        <HBox className = {block.el('has-errors-warning')}>
                            <SmtLocaleString k={errWarningLocaleKey[loginStage]}/>
                        </HBox>
                    }
                    { hasShowModal &&
                        <AppModal className       = {block.el('modal')}
                                  onAction        = {onActionModal}
                                  onClose         = {onCloseModal}
                                  sizeBtn         = {isMobile ? 'l' : 's'}
                                  actionBtnK      = {'common.app_modal.tryAgain'}
                                  cancelBtnK      = {'common.app_modal.backToEdit'}
                                  orientedButtons = {isMobile ? 'vertical-reverse' : 'horizontal'}
                                  titleContent    = {' '}
                                  isMobile        = {isMobile}
                                  wsize           = {'l'}
                                  disabled        = {disableResend}
                        >
                            <VBox className={block.el('modal-body') + 'text-s'} center>
                                <Icon className={block.el('modal-body-icon')} icon={WarningIcon} size={'xxl'}/>
                                <h3 className={block.el('modal-body-header')}>
                                    <SmtLocaleString k={'common.user_form.statusErr5xx'}/>
                                </h3>
                                <SmtLocaleString k={'common.user_form.statusErr5xxMsg'}/>
                            </VBox>
                        </AppModal>
                    }
                </>
            }
        </VBox>
    );
}
