import {MultiUserAnswer, SingleUserAnswer} from "Cheops/Model/UserAnswer";
import Assert from "Cheops/Utils/Assert";

export default class UserAnswerAdapter {


    static multipleAnswers(userAnswer: (number | string)[] | number | string): (number | string)[] {

        if (typeof userAnswer === "string" || typeof userAnswer === "number") {

            return [userAnswer];

        }

        return userAnswer;

    }

    static selectOptionAnswer(userAnswer: SingleUserAnswer | MultiUserAnswer, isMultiAllowed: boolean): SingleUserAnswer | MultiUserAnswer {


        if (!isMultiAllowed && Array.isArray(userAnswer)) {

            Assert(userAnswer.length <= 1, 'Too many options in user answer');

            return userAnswer[0];

        }

        return userAnswer;

    }

}
