import React from "react";

export type SVGPointXY = {
    x:number,
    y:number
}

export type HasUserAnswer = {
    userAnswer: any;
}

export type HasOnAnswer = {
    onAnswer:      (answer: any, done: () => void) => void;
}

export type MaybeHasOnAnswer = Partial<HasOnAnswer>;

export type HasDisabled = {disabled?:  boolean; };

export type MaybeHasDisabled = Partial<HasDisabled>

export type HasVerdict = {
    verdict: SmtPortal.Verdict;
}
export type MaybeHasVerdict = Partial<HasVerdict>;

export type HasSavedAnswer = {
    savedAnswer?: any;
};

export type MaybeHasSavedAnswer = Partial<HasSavedAnswer>;

export type SmtAnswerWidgetType =
    {answerWidget:  SmtTask.AnswerWidget} &
    MaybeHasDisabled &
    MaybeHasOnAnswer &
    MaybeHasVerdict &
    MaybeHasSavedAnswer &
    HasUserAnswer
;

export type HasDOMRect = {
    rect: DOMRect;
}

export type SVGEulerCirclesSizes = {
    r:           number;
    rhalf:       number;
    rthird:      number;
    width:       number;
    height:      number;
    centerX:     number;
    centerY:     number;
    topPadding:  number;
    A:           EulerCircleSize;
    B:           EulerCircleSize;
    C:           EulerCircleSize;
    ctrl:        EulerControlSize;
    placements:  Partial<Record<EulerCircleSetK, EulerCircleSetPlacement>>;
}

export type EulerCircleType = {
    dCircle:      EulerCircleSize;
    name:         "A" | "B" | "C";
    hover:        boolean;
}

export type EulerCircleSize = {
    r:  number;
    cx: number;
    cy: number;
    textOffset: SVGPointXY
}

export type EulerControlSize = {
    r:  number;
}

export type DragObj = {
    k:   EulerCircleSetSort;
    src: EulerCircleSetK;
}

export type EulerCtrlType = {
    count: number;
    ctrl:  EulerControlSize;
    k:     EulerCircleSetSort;
    mx:    number;
    label: string;
} & MaybeHasDisabled & HasStartDrag;

export type EulerCircleSetPlacement = SVGPointXY;

export enum Placements {
    A   = "A",
    B   = "B",
    C   = "C",
    AB  = "AB",
    BC  = "BC",
    AC  = "AC",
    ABC = "ABC"
}

export type PlacementsKeys = keyof typeof Placements;

export type EulerCircleSetSort  = SmtTask.EulerCircleWidgetSetKey;
export type EulerCircleSetK     = PlacementsKeys | "transit" | "bank";
export type EulerCircleSetValue = Partial<Record<EulerCircleSetSort, number>>;
export type EulerCirclePool     = Partial<Record<EulerCircleSetK, EulerCircleSetValue>>;

export type EulerCirclePlacementType = {
    name:      EulerCircleSetK;
    pool:      EulerCirclePool;
    placement: EulerCircleSetPlacement;
    r:         number;
} & MaybeHasDisabled & HasStartDrag;

export type AllPlacements = Record<PlacementsKeys, EulerCircleSetPlacement>;
export type PlacementOffset = SVGPointXY;
export type PlacementOffsets = Record<PlacementsKeys, PlacementOffset>;
export type MousePositionType = SVGPointXY;

export type HoverCirclesType = {A:boolean,B:boolean,C:boolean}

export type HasStartDrag = {
    startDrag: (e:React.MouseEvent | React.TouchEvent, drag: DragObj)=>void;
}

export type EulerCirclePlacementSetType = {
    num:       number;
    place:     EulerCircleSetK;
    set:       EulerCircleSetSort;
    count:     number;
    r:         number;
    placesNum: number;
} & MaybeHasDisabled & HasStartDrag

/* ################# */
export type SVGEulerCirclesType     = {answerWidget: SmtTask.EulerCircleWidget            } & HasDOMRect & HasUserAnswer & MaybeHasSavedAnswer & MaybeHasDisabled & MaybeHasOnAnswer;
export type SVGTaskStocksArrowsType = {answerWidget: SmtTask.TaskMoneyTransferArrowsWidget} & HasDOMRect & HasUserAnswer & MaybeHasSavedAnswer & MaybeHasDisabled;
