import React from "react";
import ReactDOM from "react-dom";

import isEqual from "lodash/isEqual";

import IconButtonControl from "Lab/components/UI2/Controls/IconButtonControl";
import BemClassName from "Cheops/BemClassName";

import BodyStyles from "Lab/Utils/BodyStyles";
import ButtonControl, {BUTTON_COLOR, BUTTON_VARIANTS} from "Lab/components/UI2/Controls/ButtonControl";
import "./ModalWindow.less";
import {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import ModalWindowFooter from "Lab/components/UI2/ModalWindow/ModalWindowFooter";
import TextControl from "Lab/components/UI2/Controls/TextControl";
import ModalPositionController, {PopoverRects} from "Lab/components/UI2/ModalWindow/ModalPositionController";
import ModalScrollController from "Lab/components/UI2/ModalWindow/ModalScrollController";
import { block as screenBlock } from "@sirius/ui-lib/src/blocks/Layout/Screen";

export enum PopoverPosition {
    TOP_LEFT = "TopLeft",
    TOP_RIGHT = "TopRight",
    BOTTOM_LEFT = "BottomLeft",
    BOTTOM_LEFT_END = "BottomLeftEnd",
    BOTTOM_RIGHT = "BottomRight",
    BOTTOM_CENTER = "BottomCenter",
}

export interface ModalHeaderButton {

    iconName?: string;
    title: string;
    disabled?: boolean;
    darkMode?: boolean;
    color?: BUTTON_COLOR;
    variant?: BUTTON_VARIANTS;
    startIcon?: string;
    endIcon?: string;
    onClick?: () => void;
    href?: string;

}

export interface ModalProps {

    title?: string;
    children?: React.ReactNode;
    isPopover?: boolean;
    popoverTargetEl?: Element;
    innerRef?: React.RefObject<HTMLDivElement>;
    popoverPosition?: PopoverPosition;
    narrowWidth?: boolean;
    buttons?: ModalHeaderButton[];
    hideCloseButton?: boolean;
    className?: string;
    wrapperClassName?: string;
    headerClassName?: string;
    hasHint?: boolean;
    highlighted?: boolean;
    disableOverflow?: boolean;
    disabledMessage?: string;
    hasPermanentShadow?: boolean;

    onClose?(e: React.MouseEvent | MouseEvent | UIEvent): void;
}


export interface ModalState {
    isScrollable: boolean;
    popoverRects: PopoverRects;
    popoverTargetRects: { x: number; y: number };
}

export default class ModalWindow extends React.Component<ModalProps, ModalState> {

    static defaultProps: Partial<ModalProps> = {
        popoverPosition: PopoverPosition.TOP_LEFT,
        hideCloseButton: false,
        buttons: [],
        hasHint: false,
        highlighted: false,
        disableOverflow: false,
        hasPermanentShadow: false,
    };

    constructor(props: ModalProps) {

        super(props);
        this.ModalPositionController = new ModalPositionController(this.props, this.state, this.setState.bind(this), this.modalWindowWrapperRef, this.modalWindowRef);
        this.ModalScrollController = new ModalScrollController(this.props, this.state, this.setState.bind(this), this.contentWrapper);

    }

    state: Readonly<ModalState> = {
        isScrollable: false,
        popoverRects: {},
        popoverTargetRects: {} as { x: number; y: number },
    };


    componentDidMount(): void {

        const {isPopover, onClose} = this.props;

        if (isPopover && onClose) {

            document.body.addEventListener("click", this.handleClickOutside);

        }

        this.ModalScrollController.setScrollDisabled();
        this.ModalScrollController.subscribeScrollUpdate();

        if (isPopover) {

            this.clearAnimationTimer = setTimeout(() => {

                this.modalWindowWrapperRef.current.style.animation = "none";
                this.modalWindowWrapperRef.current.style.removeProperty("animation");

            }, 250);

            this.ModalPositionController.subscribePopoverRectsChange();

        }

        this.ModalScrollController.updateIsScrollable();

        document.addEventListener("keydown", this.closableByControlsKeysHandler);

    }

    componentDidUpdate(prevProps: Readonly<ModalProps>): void {

        this.ModalScrollController.setScrollDisabled();

        const {children, popoverTargetEl, isPopover} = this.props;

        if (!isEqual(children, prevProps.children)) {

            this.ModalScrollController.updateIsScrollable();

        }

        if (isPopover) {

            this.ModalPositionController.updateRectsOnTargetWidthChange(popoverTargetEl.getBoundingClientRect(), this.state.popoverTargetRects);

        }

    }

    componentWillUnmount(): void {

        document.body.removeEventListener("click", this.handleClickOutside);

        this.ModalScrollController.unsubscribeScrollUpdate();

        if (this.props.isPopover) {

            this.ModalPositionController.unsubscribePopoverRectsChange();

        }

        clearTimeout(this.clearAnimationTimer);

        BodyStyles.setBodyScrollDisabled(false);

        document.removeEventListener("keydown", this.closableByControlsKeysHandler);

    }

    handleClickOutside = (e: MouseEvent | UIEvent): void => {

        const {onClose} = this.props;
        const domNode = this.modalWindowRef;

        const modalWindowsNodes = Array.from(document.querySelectorAll(`.${this.modalWindowClassName}`));

        const {target} = e;
        const checkForTarget = (): boolean => {

            if (target instanceof Node) {

                if (modalWindowsNodes.every((node) => node.className.includes("popover"))) {

                    return modalWindowsNodes.some((modal) => modal.contains(target));

                }

                return domNode.current.contains(target);

            }
            return false;

        };

        if (!(domNode && checkForTarget())) {

            onClose(e);

        }

    };

    ModalPositionController: ModalPositionController;
    ModalScrollController: ModalScrollController;
    modalWindowRef = React.createRef<HTMLDivElement>();
    modalWindowWrapperRef = this.props.innerRef || React.createRef<HTMLDivElement>();

    private clearAnimationTimer: NodeJS.Timeout;
    private contentWrapper = React.createRef<HTMLDivElement>();
    modalWindowClassName = "modal_window";

    overlayClick = (e: React.MouseEvent): void => {

        if (this.modalWindowWrapperRef.current.contains(e.target as Node)) {

            return;

        }

        e.stopPropagation();

        const {onClose} = this.props;

        if (onClose && e.target === this.modalWindowRef.current) {

            onClose(e);

        }

    };


    renderFooter = (): React.ReactNode => {

        const {children} = this.props;

        const childrenArr = React.Children.toArray(children);

        for (const child of childrenArr) {

            if (React.isValidElement(child) && child.type === ModalWindowFooter) {

                return child;

            }

        }

        return null;

    };


    renderChildren = (): React.ReactNode => {

        const {children} = this.props;

        const childrenArr = React.Children.toArray(children);

        for (let [index, child] of childrenArr.entries()) {

            if (React.isValidElement(child) && (child.type === ModalWindowFooter)) {

                childrenArr[index] = null;

            }

        }

        return childrenArr;

    };

    closableByControlsKeysHandler = (e: KeyboardEvent): void => {

        if (e.key === "Escape") {

            this.handleClickOutside(e);

        }

        if (e.key === "Enter") {

            // TODO new confirm method when needed

        }

    };

    private renderButton = (button: ModalHeaderButton): React.ReactNode => {

        let variant = button.variant || BUTTON_VARIANTS.OUTLINED;

        if (button.iconName) {

            return <IconButtonControl
                key={button.title + button.iconName}
                iconName={button.iconName}
                color={button.color}
                variant={variant}
                onClick={button.onClick}
                disabled={button.disabled}
                darkMode={button.darkMode}
            >
                {button.title}
            </IconButtonControl>;

        }

        return <ButtonControl
            key={button.title}
            color={button.color}
            onClick={button.onClick}
            disabled={button.disabled}
            startIcon={button.startIcon}
            variant={variant}
            endIcon={button.endIcon}
            darkMode={button.darkMode}
            href={button.href}
        >
            {button.title}
        </ButtonControl>;

    };

    render(): React.ReactNode {

        const {
            title,
            narrowWidth,
            onClose,
            isPopover,
            hideCloseButton,
            buttons,
            className,
            wrapperClassName,
            hasHint,
            highlighted,
            headerClassName,
            disableOverflow,
            hasPermanentShadow
        } = this.props;

        const {isScrollable, popoverRects} = this.state;

        const modalWindowClass = new BemClassName(this.modalWindowClassName);
        modalWindowClass.appendStatusIf(isPopover, 'popover');
        modalWindowClass.appendAdditionalClassesIf(!!className, className);

        const wrapperClass = new BemClassName('modal_window__wrapper');
        wrapperClass.appendAdditionalClassesIf(!!wrapperClassName, wrapperClassName);
        wrapperClass.appendStatusIf(hasPermanentShadow || isScrollable, 'scrollable');
        wrapperClass.appendStatusIf(!!narrowWidth, 'narrow');
        wrapperClass.appendStatusIf(isPopover, 'popover');
        wrapperClass.appendStatusIf(highlighted, 'highlighted');

        const headerClass = new BemClassName('modal_window__header');
        headerClass.appendAdditionalClassesIf(!!headerClassName, headerClassName);

        const contentClassName = new BemClassName("modal_window__content");
        contentClassName.appendStatusIf(disableOverflow, "disabledOverflow");

        return ReactDOM.createPortal(
                <div className={modalWindowClass.toString()} onMouseDown={!isPopover ? this.overlayClick : null} ref={this.modalWindowRef}>

                    <div
                        className={wrapperClass.toString()}
                        style={isPopover ? popoverRects : {}}
                        ref={this.modalWindowWrapperRef}
                    >

                        {((!!onClose && !hideCloseButton) || !!title || buttons.length !== 0)
                        && <div className={headerClass.toString()}>

                            {!!title
                            && <div className="modal_window__header_title">

                                <TextControl primary>{title}</TextControl>
                                {hasHint && <IconButtonControl iconName="help_outline" size={ControlSizes.MD} />}

                            </div>}

                            {(buttons.length !== 0 || (!!onClose && !hideCloseButton))
                            && <div className="modal_window__controls">

                                {buttons.length !== 0
                                && <div className="modal_window__buttons">
                                    {buttons.map(this.renderButton)}
                                </div>}

                                {!!onClose && !hideCloseButton && <IconButtonControl iconName='close' onClick={onClose} />}

                            </div>}

                        </div>}

                        <div className={contentClassName.toString()} ref={this.contentWrapper}>
                            {this.renderChildren()}
                        </div>

                        {this.renderFooter()}

                    </div>
                </div>
            ,
            document.querySelector(`.${screenBlock}`)
        );

    }

}
