import React, {Component} from "react";
import {TaskAnswerType} from "Cheops/Model/Task";
import {ElementVerdict} from "Cheops/Model/Element";
import {SmtLocaleString} from "Smt/SmtLocaleBlock";


interface Props {

    answerType: TaskAnswerType[];
    verdict: ElementVerdict;

}

class VerdictText extends Component<Props> {

    render(): React.ReactText | any {

        let {answerType, verdict} = this.props;

        if (answerType.filter((type) => type !== TaskAnswerType.NONE).length === 0) {

            return null;

        }

        switch (verdict) {
            case ElementVerdict.OK:
                return <SmtLocaleString k="common.verdict_text.ok" id="value" />;
            case ElementVerdict.WRONG:
                return <SmtLocaleString k="common.verdict_text.wrong" id="value" />;
            case ElementVerdict.NONEUNSCORED:
            case ElementVerdict.UNSCORED:
                return '';
            case ElementVerdict.PARTLY:
                return <SmtLocaleString k="common.verdict_text.partly" id="value" />;
            case ElementVerdict.NOTREADY:
                return <SmtLocaleString k="common.verdict_text.not_ready" id="value" />;
            case ElementVerdict.ANNULLED:
                return <SmtLocaleString k="common.verdict_text.annulled" id="value" />;
        }

        return <SmtLocaleString k="common.verdict_text.no_verdict" id="value" />;

    }

}


export default VerdictText;
