import React           from "react";
import bnc             from "bnc";
import {ShortTestInfo} from "Lab/Course/Module/TaskContentVersion";
import {VBox}          from "@sirius/ui-lib/src/blocks/Layout";
import                      "./ProgrammingAnswerExamples.less";

type Props = {
    examples: LabSmt.AnswersData["examples"] | ShortTestInfo[];
};
const block = new bnc("programming-answer-examples");

const ProgrammingAnswerExamples = ({examples}: Props) => {

    return (
        <VBox className={`${block}`}>

            <h3 className={block.el('title')}>Примеры</h3>

            <div className={block.el('header')}>
                <div className={block.el('header').mod('title')}>Ввод</div>
                <div className={block.el('header').mod('title')}>Вывод</div>
            </div>

            {examples.map((example, i) =>
                <div className={block.el('row')} key={i}>
                    <div className={block.el('row-part')}>
                        <div className={block.el('row')+block.mod('content')}>
                            {example.input}
                        </div>
                    </div>
                    <div className={block.el('row-part')}>
                        <div className={block.el('row')+block.mod('content')}>
                            {example.output}
                        </div>
                    </div>
                </div>
            )}
        </VBox>
    );
}

export default ProgrammingAnswerExamples;
