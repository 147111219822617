import React, {useEffect, useState}                     from "react";
import {AxiosError}                                     from "axios";
import {GrowGap, LGap, HBox, VBox, MGap, SGap}          from "@sirius/ui-lib/src/blocks/Layout";
import { CircularProgress }                             from "@sirius/ui-lib/src/blocks/CircularProgress";
import {Button}                                         from "@sirius/ui-lib/src/blocks/Controls";
import {getLanguagePickerName}                          from "@sirius/ui-shared/src/components/Programming";
import {reviewResult}                                   from "@sirius/ui-shared/src/Translations/Programming";
import {SmtLocalePlural, SmtLocaleString, LocaleDate}   from "Smt/SmtLocaleBlock";
import {CopyPanel}                                      from "Smt/SmtProgrammingAnswer/CopyPanel";
import {
    SmtCache_v4_POST_solution,
    SmtCache_v4_POST_solution__testsReport,
    SmtCache_v4_POST_solution__testsReport_report
}                                                       from "Smt/subjects/ContestOnline";
import {
    SolutionTestsReport
}                                                       from "@sirius/ui-shared/src/components/Programming/TestsReport";
import {
    EditorAdapter,
    highlightByLanguages
}                                                       from "@sirius/ui-shared/src/components/Programming/EditorAdapter";
import {
    block,
    MaybeHasIsHidden,
    PrismLanguages,
    VerdictPriority
}                                                       from "./block";
import type {
                HasSolution,
                HasTestsReportCb,
                HasTestDetailReportCb,
                HasVerdict,
                HasSummary,
                OnlineVerdictSolutionType,
                OnlineVerdictsType,
                SolutionType,
            }                                           from "./block";


const LocaleStringYear:  Intl.DateTimeFormatOptions = { day: "numeric", month: "long", year: "numeric" };
const LocaleStringMonth: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
const LocaleStringTime:  Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

const now  = new Date();
const nowY = now.getFullYear();

const getSourceByLanguage = ( sources: SmtPortal.LanguageBlock[] | string[], language: string ): string => {
    const [first] = sources;
    const code = typeof first === 'string'
        ? first
        : (sources as SmtPortal.LanguageBlock[]).find( s=>s.language === language)?.code
    ;
    return code;
}

const OnlineVerdictDateFormat = (date: Date) =>
    date.toDateString() === now.toDateString()
        ? LocaleStringTime
        : date.getFullYear() !== nowY
            ? LocaleStringYear
            : LocaleStringMonth
;

export const OnlineVerdictSolutionTM = ({value}: {value?: number}) =>
    <span className={ block.el('verdicts-tm') }>

        { value
            ? <LocaleDate dateValue={value} dateOptions={ OnlineVerdictDateFormat( new Date(value) ) } />
            : <SmtLocaleString id="value" k="programming.onlineverdicts.justnow" />
        }
    </span>
;

const Score = ({score, maxScore}: {score?: number; maxScore?: number; [_k: string]: unknown; }) => (
        (score !== void(0) && maxScore !== void(0))
            ?  <span className={ block.el('verdicts-score') }>
                {score} <SmtLocaleString id="value" k="programming.onlineverdicts.verdictsScoreFrom"/> {maxScore}
               </span>
            : null
    )
;

const ReviewResult = ({ info, message }: Partial<SmtCache.ProgrammingReview> ) => {
    const [messageFirstRow, ...messageRows] = (message || '').split("\n");
    const isReviewResult = info && 'result' in info && info.result in reviewResult;

    return (
        <div className={
            block.el('verdicts-review-result') +
            block.el('verdicts-review-panel')
        }>
            { isReviewResult
               ? <SmtLocaleString k={`programming.reviewResult.${info.result}`}/>
               : messageFirstRow
            }
            { messageRows.length > 0 &&
                messageRows.map(
                    (str, si) =>
                        <div key={si} className={block.el('verdicts-review-result-sub')}>{str}</div>
                )
            }
        </div>
    )
};

const Review = ({ info, message, summary, TestsReportCb, TestDetailReportCb }: SmtCache.ProgrammingReview & HasSummary & HasTestsReportCb & HasTestDetailReportCb ) =>  (
    <VBox className={block.el('verdicts-review')}>
        { message &&
            <ReviewResult {...{info, message}}/>
        }
        {summary && TestsReportCb &&
            <SolutionTestsReport {...{summary, TestsReportCb, TestDetailReportCb}}/>
        }
    </VBox>
);

const trimCodeBlocks = (blocks: string[]) => {
    const res =  blocks.filter(s => !!s.trim()).join("\n");
    return res === "" ? blocks.join("") : res;
};


const highlightCode = highlightByLanguages(PrismLanguages);

const HiddenModeText = ({isSharp = false}) =>
    <HBox className = { block.el('hidden-part') +
                        block.el('hidden-part').bod('sharp', isSharp)}
          center
    >
        <p className = { block.el('hidden-part').mod('text') }>
            <SmtLocaleString k='programming.codeeditor.hiddentxt'/>
        </p>
    </HBox>
;

export const Solution = ({
    solution: {language, code},
    review,
    summary,
    sourceHeader = '',
    sourceFooter = '',
    clickable,
    TestsReportCb,
    TestDetailReportCb,
    isHidden = false,
    hideCopyPanel
}: SolutionType) => {

    const footerNum:number = sourceHeader
                                ? sourceHeader.split('\n').length + 3
                                : 3
    ;

    return(
        <VBox className={block.el('verdicts-solution')}>
            {
                <>
                    {
                        isHidden
                        ? <>
                                {
                                    sourceHeader &&
                                    <EditorAdapter
                                        clickable={clickable}
                                        highlight={highlightCode(language)}
                                        value={sourceHeader}
                                        block={block}
                                    />
                                }
                                <HiddenModeText isSharp = {!!review || !!summary}/>
                                {
                                    sourceFooter &&
                                    <EditorAdapter
                                        clickable={clickable}
                                        highlight={highlightCode(language, footerNum)}
                                        value={sourceFooter}
                                        block={block}
                                    />
                                }
                        </>
                        : <>
                            <EditorAdapter
                                clickable={clickable}
                                value={trimCodeBlocks([sourceHeader, code, sourceFooter])}
                                highlight={highlightCode(language)}
                                block={block}
                            />
                            <LGap/>
                            {
                                !hideCopyPanel &&
                                <CopyPanel code     = {trimCodeBlocks([sourceHeader, code, sourceFooter])}
                                           language = {language}
                                           isRound  = {!summary && !review}
                                />
                            }
                        </>
                    }

                    {
                        (review || summary) &&
                        <Review {...{...review, summary, TestsReportCb, TestDetailReportCb}}/>
                    }
                </>
            }
        </VBox>
    )
};

type SolutionErrorType = {
    solutionError:    Error | AxiosError;
    setSolutionError: (val: null)=>void;
};

const SolutionAxiosErrorStatuses = [401, 403, 404];
const SolutionAxiosError = ({response:{status}}: AxiosError) =>
    <SmtLocaleString
        k={`programming.onlineverdicts.solutionError_${
            SolutionAxiosErrorStatuses.includes(status) ? status : 401 
        }`}
        id="value"
    />
;

const SolutionError = ({solutionError, setSolutionError}: SolutionErrorType) =>
    <HBox className={block.el('verdicts-error')}>
        {
            (solutionError as AxiosError)?.response
                ? <SolutionAxiosError {...(solutionError as AxiosError)}/>
                : <SmtLocaleString k="programming.onlineverdicts.solutionError" id="value"/>
        }

        <MGap/>
        <Button size={'xs'} onAction={ ()=>{setSolutionError(null);} }>
            <SmtLocaleString k="programming.onlineverdicts.solutionRetryButton" id="value"/>
        </Button>
    </HBox>
;

const hasSelection = () => window.getSelection().toString() !== "";

type HeaderContent = HasSolution & HasVerdict & {
    num?:      number,
    pending?:  boolean,
    tmSec?:    number,
    score?:    number,
    maxScore?: number,
}

const Header = ({
    num,
    solution,
    pending,
    tmSec,
    verdict,
    score,
    maxScore,
}: HeaderContent) =>
    <>
        <b><SmtLocaleString k="programming.onlineverdicts.solutionHeader" /> {num}. </b>
        {
            pending
                ? <>
                    <SGap/>
                    <OnlineVerdictSolutionTM />
                    {
                        solution
                        && `. ${getLanguagePickerName(solution.language)}`
                    }
                </>
                : !(verdict === 'none' || verdict === 'annulled')
                      ?  <>
                            <span className={block.el('verdicts-string')}>
                                <OnlineVerdictSolutionTM value={tmSec * 1000}/>
                                {'. '}
                                {
                                    solution
                                    && `${getLanguagePickerName(solution.language)}. `
                                }
                                <SmtLocaleString k={`programming.verdict.${verdict}`}/>
                            </span>
                            <GrowGap/>
                            <Score {...{score, maxScore}}/>
                        </>
                      : ''
        }
    </>
;
const OnlineVerdictSolution = ({
    isLast, num, taskId, pending, content,
    taskToken, sourceFooter, sourceHeader,
    unlockNextTry, showDetails, toggleVerdictsShowDetails,
    className, showHeader = null, clickable = true,
    summary, TestsReportCb, TestDetailReportCb, isHidden,
    hideCopyPanel = false
}: OnlineVerdictSolutionType) => {
    const {id, tmSec, verdict, score, maxScore, review, summary:contentSummary} = content as SmtCache.ReviewsResponseSolution;
    const [ codeLoading, setCodeLoading ]     = useState<boolean>( false );
    const [ solutionError, setSolutionError ] = useState(null);
    const [ solution, setSolution ]           = useState<SmtCache.LanguageBlock | null>(
        content?.solution || null
    );
    const [ hiddenMode, setHiddenMode ]       = useState<boolean>(false);

    useEffect(
        () => {
            if (solution) {
                setSolution(solution);
            }
        },[solution]
    );

    useEffect(
        ()=>{
            if (showDetails && solution === null && solutionError === null && codeLoading === false) {
                setCodeLoading(true);
                SmtCache_v4_POST_solution({id, taskId}, taskToken)
                    .then(
                        (result) => {
                            const {status, data:{input}} = result;
                            if (status === 200) {
                                if ('solution' in input) {
                                    const {solution} = input;
                                    if ( solution ){
                                        setSolution(solution as SmtCache.LanguageBlock);
                                    }
                                } else if ('hidden' in input) {
                                    setSolution({
                                        language: input.lang,
                                        code: ''
                                    });
                                    setHiddenMode(true);
                                }
                            }
                        }
                    )
                    .catch(
                        setSolutionError
                    )
                    .finally(
                        ()=>{
                            setCodeLoading(false);
                            toggleVerdictsShowDetails(id, true);
                        }
                    )
            }
        },
        [showDetails, solution, solutionError]
    );

    const onClick = clickable
                    ? isLast
                        ? verdict !== "ok"
                                ?   () =>   !hasSelection() &&
                                            ( toggleVerdictsShowDetails(id, true),
                                              unlockNextTry() )
                                :   void(0)
                        : () => {
                            !hasSelection() &&
                            toggleVerdictsShowDetails(id, !showDetails)
                        }
                    : void(0)
    ;

    return <>
        <VBox
            className={
                block.el('verdicts-card') +
                block.el('verdicts-card').mod('pending', pending ? 'true' : 'false') +
                block.el('verdicts-card').bod('ok', !pending && verdict === 'ok') +
                block.el('verdicts-card').bod('notok', !pending && verdict !== 'ok' && verdict !== 'partly' && verdict !== 'annulled') +
                block.el('verdicts-card').bod('partly', !pending && verdict === 'partly') +
                block.el('verdicts-card').bod('annulled', !pending && (verdict === 'annulled')) +
                ` ${className || ''}`
            }
            onClick={ onClick }
        >
            {

                   <HBox className={
                        block.el('verdicts-head') +
                        block.el('verdicts-card').bod('clickable', !!onClick)
                   }>{
                       showHeader
                           ? showHeader
                           : <Header
                               {...{
                                    num,
                                    solution,
                                    pending,
                                    tmSec,
                                    verdict,
                                    score,
                                    maxScore
                               }}
                           />
                   }</HBox>
            }{
                showDetails &&
                <VBox className={block.el('verdicts-code')}>
                    {   codeLoading &&
                        <CircularProgress size="xs"/>
                    }
                    {
                        solutionError &&
                        <SolutionError {...{solutionError, setSolutionError}} />
                    }
                    {   solution &&
                        <Solution
                            {...{ solution, review, summary:summary || contentSummary, TestsReportCb, TestDetailReportCb }}
                            clickable    = {!!onClick}
                            sourceHeader = { getSourceByLanguage(sourceHeader || [], solution.language) }
                            sourceFooter = { getSourceByLanguage(sourceFooter || [], solution.language) }
                            isHidden     = { isHidden || hiddenMode }
                            hideCopyPanel= { hideCopyPanel || verdict === 'none' }
                        />
                    }
                </VBox>
            }
        </VBox>
    </>;
};

type RenderVerdictType = {
    taskId:                    number;
    taskToken:                 string;
    sourceFooter:              SmtCache.LanguageBlock[];
    sourceHeader:              SmtCache.LanguageBlock[];
    isLast?:                   boolean;
    verdictsShowDetails:       string[];
    toggleVerdictsShowDetails: (id: string, val: boolean)=>void;
    unlockNextTry?:            () => void;
} & MaybeHasIsHidden;

const renderVerdict =
    ({
        taskId,
        taskToken,
        sourceFooter,
        sourceHeader,
        isLast = false,
        unlockNextTry = () => void (0),
        verdictsShowDetails,
        toggleVerdictsShowDetails,
        isHidden
    }: RenderVerdictType) =>
    ({
         pending,
      ...content
     }: any,
     vi: number
    ): React.ReactNode => {

    const TestsReportCb = (): Promise<Common.Programming.ReviewTestsReportResponse> => {
        return SmtCache_v4_POST_solution__testsReport({id: content?.id, taskId}, taskToken)
                .then((res) =>
                    new Promise <Common.Programming.ReviewTestsReportResponse> ( (resolve, reject) => {
                        const {status, data} = res;
                        (status === 200 && data)
                            ? resolve(data)
                            : reject(status);
                    })
                )
    };

    const TestDetailReportCb = (testNo: string) =>
        SmtCache_v4_POST_solution__testsReport_report(
            {id: content?.id, taskId, testNo},
            taskToken
        )
        .then((res) =>
            new Promise <Common.Programming.ReviewTestProtocolResponse> ( (resolve, reject) => {
                const {status, data} = res;
                (status === 200 && data)
                    ? resolve(data)
                    : reject(status);
            })
        )
    ; //void(0)//

    return (
        <OnlineVerdictSolution
            key      = {vi}
            num      = {vi + 1}
            isHidden = {isHidden}
            {...{
                unlockNextTry,
                isLast,
                taskId,
                pending,
                content,
                sourceFooter,
                sourceHeader,
                taskToken,
                showDetails: verdictsShowDetails.includes( content?.id ),
                toggleVerdictsShowDetails,
                TestsReportCb: TestsReportCb,
                TestDetailReportCb: TestDetailReportCb
            }}
        />
    )
}

;

export const calcBestVerdict = (verdicts: HasVerdict[]): string | undefined =>
     VerdictPriority[
         verdicts
             .map(({verdict}) => VerdictPriority[verdict as keyof typeof VerdictPriority])
             .reduce((acc, item ) => item > acc ? item : acc, -1)
        ]
;


const OnlineVerdicts = ({
    showLast,
    verdictsShowDetails, toggleVerdictsShowDetails,
    allVerdictsCollapsed, setAllVerdictsCollapsed,
    taskToken, taskId,
    sourceFooter, sourceHeader, unlockNextTry,
    showVerdicts,
    vNum,
    last,
    isHidden,
    allowResend
}: OnlineVerdictsType) => {
    const hasVerdicts       = showVerdicts?.length > 0;
    const verdictsCopy      = [...showVerdicts] as SmtCache.ReviewsResponseSolution[];

    const bestVerdictAnswer = hasVerdicts && verdictsCopy.reverse().find((item) => item.current);
    const bestVerdict       = bestVerdictAnswer ? bestVerdictAnswer.verdict : '';
    const isLastPendingResend = last?.pending && allowResend;

     return (
         <VBox
            className={
                block.el('verdicts') +
                block.el('verdicts').bod('collapsed', allVerdictsCollapsed)
            }
        >
            {vNum > 0 &&
            <HBox
                className={
                    block.el('verdicts-toggle-smt') +
                    block.el('verdicts-toggle-smt').bod('collapsed', allVerdictsCollapsed) +
                    block.el('verdicts-toggle-smt').bod(`${bestVerdict}`, bestVerdict && allVerdictsCollapsed) +
                    block.el('verdicts-card')
                }
                onClick={() => setAllVerdictsCollapsed(!allVerdictsCollapsed)}
            >{
                allVerdictsCollapsed
                    ? <>
                        <p style={{margin: '0 auto'}}>
                            {`${vNum} `}
                            <SmtLocalePlural k="programming.onlineverdicts.solutionsNum" value={vNum}/>
                        </p>
                        {bestVerdictAnswer && <Score score={bestVerdictAnswer?.score} maxScore={bestVerdictAnswer?.maxScore}/>}
                    </>
                    : <SmtLocaleString id="value" k="programming.onlineverdicts.hide"/>
            }</HBox>
            }
            {
                !allVerdictsCollapsed &&
                showVerdicts.map(
                    renderVerdict({
                        taskId,
                        taskToken,
                        sourceFooter,
                        sourceHeader,
                        verdictsShowDetails,
                        toggleVerdictsShowDetails,
                        isHidden
                    })
                )
            }
            {
                showLast && !isLastPendingResend &&
                renderVerdict({
                    taskId,
                    taskToken,
                    sourceFooter,
                    sourceHeader,
                    verdictsShowDetails,
                    toggleVerdictsShowDetails,
                    isLast: true,
                    unlockNextTry,
                    isHidden
                })(last, showVerdicts.length)
            }
        </VBox>
     )}
;


export {OnlineVerdicts, OnlineVerdictSolution, Score, Review, ReviewResult};
