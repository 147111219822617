import React, {FunctionComponent} from "react";
import './PrizeTypeOrdinary.less';
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {PrizeTypeComponentProps} from "Smt/IndexPage/Prize/Prize";

const PrizeTypeOrdinary: FunctionComponent<PrizeTypeComponentProps> = (props) => {

    const {
        title,
        description,
        file,
        link,
        linkText,
    } = props.data;

    return <div className="prize_type_ordinary">

        <div className="prize_type_ordinary__title">{title}</div>

        {!!description
        && <div className="prize_type_ordinary__description">
            <Marked>{description}</Marked>
        </div>}

        {!!link
        && <a href={link} target="_blank" className="prize_type_ordinary__link">{linkText}</a>}

        {!!file
        && <a
            href={`/smt-portal/test/download/${file}`}
            target="_blank" download
            className="prize_type_ordinary__link"
        >
            {linkText}
        </a>}


    </div>;


};


export default PrizeTypeOrdinary;
