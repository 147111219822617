import React, {FC, useEffect, useState} from "react";
import {JSONSchema7}                    from "json-schema";
import bnc                              from "bnc";
import {CircularProgress}               from "@sirius/ui-lib/src/blocks/CircularProgress";
import {VBox}                           from "@sirius/ui-lib/src/blocks/Layout";
import {SchemaFieldProps}               from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {getApiCountries}                from "Smt/actions/webPortal";
import {MaybeHasCountriesSrc}           from "../@types";
import {getOptions}                     from "@sirius/ui-lib/src/blocks/SchemaField/ui/default/utils";


const getContestIdBySchema = (schema: JSONSchema7): string | number | void => {
    const {contestId} = (schema as MaybeHasCountriesSrc)['x-data-countries-src'];
    return contestId;
}

const inputBlock     = new bnc('ui-input');
const textInputBlock = new bnc('ui-textinput');


const sortCountriesByLocale =
(locale: keyof SmtPortal.Methods.CountryListItem) =>
(a:SmtPortal.Methods.CountryListItem, b:SmtPortal.Methods.CountryListItem): number =>
    a[locale] < b[locale] ? -1 : 1
;

export const CountryField: FC<SchemaFieldProps> = (props) => {
    const {
        block,
        onChange,
        schema,
        deep,
        data,
        uischema,
        uioptions,
        className= '',
        required,
        validation,
    } = props;
    const [contestId, setContestId]         = useState<string | number | void>( getContestIdBySchema(schema) );
    const [value, setValue]                 = useState<string>(data);
    const [loading, setLoading]             = useState<boolean>(true);
    const [countriesList, setCountriesList] = useState<SmtPortal.Methods.CountryList | null>(null);
    useEffect(
        ()=>{
            if (contestId){
                setLoading(true);
                getApiCountries(`${contestId}`)
                    .then(
                        ({data: countries }) => {
                            setCountriesList( countries );
                        }
                    )
                    .finally(
                        ()=> {
                            setLoading(false);
                        }
                    )
            }
        },
        [contestId]
    );

    useEffect(
        ()=>{
            setContestId(
                getContestIdBySchema(schema)
            )
        },
        [schema]
    );

    const {MetaField}    = uischema;
    const {locale}       = uioptions;
    const isEmpty        = !value;
    const {getError} = getOptions(props, 'ErrorField');
    const {isError}  = getError({deep, validation});
    const fieldClassName = block.bod('required', required) +
                           block.el('string') +
                           block.el('string').bod('input',    true) +
                           block.el('string').mod('has', `${isEmpty ? 'no-' : ''}value`) +
                           block.el('string').mod('has', `${isError ? '' : 'no-'}errors`) +
                           className
    ;
    const modeVal = isError ? 'wrong' : 'default';
    const wrapperClass  =   `has-outline-w text-s ${
                                inputBlock.mod('wrapper') +
                                inputBlock.mod('wrapper-country') +
                                inputBlock.mod('wrapper-mode', modeVal) +
                                inputBlock.mod('wrapper-size-s') +
                                textInputBlock +
                                block.el('input')
                            }`
    ;

    const countryItemMapper = ({key, ...country}:SmtPortal.Methods.CountryListItem, ci: number) =>
                                locale in country
                                    ? <option key={ci} value={key}>
                                        {country[locale as 'ru' | 'en']}
                                      </option>
                                    : null
    ;

    const onChangeHandler:React.ChangeEventHandler<HTMLSelectElement> = (event) => {
        const value = `${event.target.value}`;
        onChange(value);
        setValue(value);
    }

    return  <VBox className={fieldClassName}>
                <label
                    className={
                    block.el('label') +
                    block.el('label').mod('string')
                }>
                    <MetaField {...props}/>
                    <VBox className={wrapperClass}>
                        {
                            loading
                                ? <CircularProgress size={"xs"}/>
                                : <select
                                    onChange     = {onChangeHandler}
                                    value = {data}
                                    className    = {
                                        block.el('country-select') +
                                        textInputBlock.el('input').mod('input', 'reset')
                                    }
                                >
                                    <option key={'empty'}></option>
                                    {
                                        countriesList &&
                                        countriesList
                                            .sort(sortCountriesByLocale(locale as keyof SmtPortal.Methods.CountryListItem))
                                            .map(countryItemMapper)
                                    }
                                </select>
                        }
                    </VBox>
                </label>
            </VBox>
    ;
}
