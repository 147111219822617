import React from "react";
import {render} from "react-dom";
import "./UpdatePopup.less";

// eslint-disable-next-line import/prefer-default-export
export const UpdatePopup = () => {

    const container = document.getElementById("popup_window");

    if (!container) {

        throw new Error('Missing "popup_window" container on page');

    }

    if (!container.innerHTML) {

        render(
            <div className='update_popup'>
                <div className="update_popup__window">
                    <div className="update_popup__window_title">
                        Мы выпустили обновление, пока вас не было
                    </div>
                    <div className="update_popup__window_text">
                        Сохраните изменения и перезагрузите страницу
                    </div>
                    <div className="update_popup__window_cheops" />
                    <div className="update_popup__window_footer">
                        <button className="update_popup__window_button" onClick={() => render(<></>, container)}>
                            Ясно
                        </button>
                    </div>
                </div>
            </div>,
            container,
        );

    }

};
