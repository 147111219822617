import {MaybeHasValues}                    from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {TranslationsType}                  from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";
import {GetMetaArgType, GetMetaResultType} from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {translate}                         from "./translate";

const DEBUG = false;

export const getMetaT = (translations: TranslationsType) => (arg: GetMetaArgType): GetMetaResultType => {
    const {schema, uioptions, deep} = arg;
    const src     = { title: schema.title || '' };
    const locale  =  uioptions.locale;
    const values  = 'x-data-i18n' in schema ? (schema['x-data-i18n'] as MaybeHasValues )?.values : {};
    const resI18n: { [p: string]: string } = translate({
        deep,
        src,
        locale,
        translations,
        values
    });
    DEBUG && resI18n.title === schema.title && console.debug(`getMeta ${schema.title}`,{deep, src, translations, resI18n})
    return resI18n;
}
