import React, {useRef, useState}     from "react";
import { GrowGap, LGap, HBox, VBox } from "@sirius/ui-lib/src/blocks/Layout";
import { Button }                    from "@sirius/ui-lib/src/blocks/Controls/Button";
import { getLanguagePickerName }     from "@sirius/ui-shared/src/components/Programming";
import {
    EditorAdapter,
    highlightByLanguages
}                                    from "@sirius/ui-shared/src/components/Programming/EditorAdapter";
import { SmtLocaleString }           from "Smt/SmtLocaleBlock";
import CodeFromFileUploader          from "Cheops/components/ModulePassing/Answers/AnswerTypes/CodeFromFileUploader/CodeFromFileUploader";
import { LanguagesSelector }         from "./LanguagesSelector";
import { block, PrismLanguages }     from "./block";
import type { CodeEditorType }       from "./block";


const CodeEditor: React.FC<CodeEditorType> = ({
        code,language, num, status,
        disabled = false, languages,
        sourceFooter, sourceHeader,
        hasInput,
        onCodeChange, onLanguageChange, onRestoreCode, onClick, className = ''
    }) => {
        const [codeFocus, setCodeFocus] = useState( false );
        const [codeHover, setCodeHover] = useState( false );
        const onCodeFocus               = () => { setCodeFocus(true);  }
        const onCodeBlur                = () => { setCodeFocus(false); }
        const onMouseOver               = () => { setCodeHover(true);  }
        const onMouseOut                = () => { setCodeHover(false); }
        const focusOnTextarea           = (event: React.MouseEvent<HTMLDivElement>): void => {
            const [ta1, ta2]  = blockEl.current.getElementsByClassName( block.el('editor-textarea') );
            const ta = sourceHeader ? ta2 : ta1;
            ta.disabled = false;
            ta.focus();
        }
        const blockEl                   = useRef(null);

        const sourceHeaderNumOffset     = sourceHeader ? sourceHeader.split("\n").length : 0;
        const codeNumOffset             = code         ? code.split("\n").length         : 1;
        const highlightCode             = highlightByLanguages(PrismLanguages);

        return  <VBox
                    onClick  = { onClick ? onClick : focusOnTextarea }
                    divRef   = { blockEl }
                    className={
                        block.el('editor') +
                        (codeFocus ? '' : block.el('editor-clickable')) +
                        block.el('editor').bod('focus-within-textarea', codeFocus) +
                        block.el('editor').mod('status', status || 'none') +
                        className
                    }
                    onMouseOver   = {onMouseOver}
                    onMouseOut    = {onMouseOut}
                >
                    <HBox className={block.el('editor-head')}>
                        <b>
                            {
                                num > 1
                                    ? <><SmtLocaleString k="programming.codeeditor.header"/> {num}</>
                                    : <SmtLocaleString k="programming.codeeditor.header1"/>
                            }
                        </b>
                        <GrowGap/>
                        {
                            languages.length > 1
                                ? <HBox><LanguagesSelector {...{languages, language, onLanguageChange, disabled}} /></HBox>
                                : <span>{getLanguagePickerName(language)}</span>
                        }
                    </HBox>
                    <LGap />
                    <div className={block.el('editor-code')}>
                        {
                            sourceHeader &&
                            <EditorAdapter
                                clickable = {!codeFocus}
                                highlight = {highlightCode(language)}
                                value     = {sourceHeader}
                                block     = {block}
                            />
                        }
                        <EditorAdapter
                            clickable     = {!codeFocus}
                            focused       = {codeFocus}
                            hover         = {codeHover}
                            highlight     = {highlightCode(language, sourceHeaderNumOffset)}
                            value         = {code}
                            onValueChange = {onCodeChange}
                            disabled      = {disabled}
                            onFocus       = {onCodeFocus}
                            onBlur        = {onCodeBlur}
                            block         = {block}
                        />
                        {
                            sourceFooter &&
                            <EditorAdapter
                                clickable = {!codeFocus}
                                value     = {sourceFooter}
                                highlight = {highlightCode(language,codeNumOffset + sourceHeaderNumOffset)}
                                block     = {block}
                            />
                        }
                    </div>
                    <LGap />
                    <HBox className={block.el('file')}>
                        <div style={{paddingLeft: '12px', alignSelf: 'center'}}>
                          <CodeFromFileUploader fillFromFile={onCodeChange}/>
                        </div>
                        <GrowGap/>
                        {
                            hasInput &&
                            <Button iconPlace="end" mode="text" size="xs" icon="replay" disabled={disabled} onAction={onRestoreCode}>
                                <SmtLocaleString k="programming.codeeditor.reset"/>
                            </Button>
                        }
                    </HBox>
                </VBox>;
    }
;

export {CodeEditor};
