import React, {FunctionComponent} from "react";
import './PrizeTypeCongrats.less';
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {PrizeTypeComponentProps} from "Smt/IndexPage/Prize/Prize";


const PrizeTypeCongrats: FunctionComponent<PrizeTypeComponentProps> = (props) => {

    const {data, meta} = props;
    const {title, description} = data;

    const wrapperStyles: React.CSSProperties = {};


    if (meta.statusBg) {

        wrapperStyles.background = meta.statusBg;

    }

    if (meta.statusColor) {

        wrapperStyles.color = meta.statusColor;

    }


    return <div className="prize_type_congrats" style={wrapperStyles}>


        <div className="prize_type_congrats__title">{title}</div>

        <img className="prize_type_congrats__img" src={require("./img/prof_congrats.png")} />

        {!!description
        && <div className="prize_type_congrats__description">
            <Marked>{description}</Marked>
        </div>}

    </div>;


};


export default PrizeTypeCongrats;
