import React from "react";
import ModalWindow, {ModalProps, ModalState} from "Lab/components/UI2/ModalWindow/ModalWindow";
import BodyStyles from "Lab/Utils/BodyStyles";

export default class ModalScrollController {

    constructor(
        modalProps: ModalProps,
        modalState: ModalState,
        setModalState: React.Component['setState'],
        contentWrapper: React.RefObject<HTMLDivElement>,
    ) {

        this.modalProps = modalProps;
        this.modalState = modalState;
        this.setModalState = setModalState;
        this.contentWrapper = contentWrapper;

    }

    modalProps: ModalProps;
    modalState: ModalState;
    setModalState: React.Component['setState'];

    contentWrapper: React.RefObject<HTMLDivElement>;

    setScrollDisabled = (): void => {

        BodyStyles.setBodyScrollDisabled(this.getIsScrollDisabled());

    };

    updateIsScrollable = (): void => {

        const isScrollable = this.contentWrapper.current.scrollHeight > this.contentWrapper.current.clientHeight;

        this.setModalState({isScrollable});

    };

    getIsScrollDisabled = (): boolean => {

        if (!this.modalProps.isPopover) {

            return true;

        }
        return window.matchMedia("(max-width: 760px)").matches;

    };

    subscribeScrollUpdate = (): void => {

        window.addEventListener('resize', this.updateIsScrollable);

    };

    unsubscribeScrollUpdate = (): void => {

        window.removeEventListener('resize', this.updateIsScrollable);

    };

}
