import React, {FC}        from "react";
import * as LibSchema     from "@sirius/ui-lib/src/blocks/SchemaField/ui/lab";
import {SchemaFieldProps} from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {block}            from "./block";
import                         "./styles/index.less";

const {UISchema:    LibUISchema    } = LibSchema;
const {SchemaField: LibSchemaField } = LibUISchema;

export const SchemaField:FC<SchemaFieldProps> = ( props) =>
    <LibSchemaField {...props} block={block}/>
;
