import React, {FC}        from "react";
import bnc                from "bnc";
import {
    GrowGap, MGap,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
}                         from "@sirius/ui-lib/src/blocks/Layout";
import { Button }         from "@sirius/ui-lib/src/blocks/Controls";
import {ModalProps}       from "@sirius/ui-lib/src/blocks/Layout/Modal";
import {SmtLocaleString}  from "Smt/SmtLocaleBlock";
import {useTaskDataState} from "Smt/TaskPage/Task/TaskDataSubject";
import {ResultStates}     from "Smt/TaskPage/Task/ResultStates";
import {TaskStates}       from "Cheops/Model/Task";
import                         "./CloseModal.less"


type CloseModalProps = {
    onConfirm?(): void;
    resultStatusType?:  TaskStates;
    onSelectTask?: (value: any) => void;
} & ModalProps;

const block = new bnc('task-close');

export const CloseModal: FC<CloseModalProps> = (props) => {
    const {
        onClose,
        onConfirm,
        resultStatusType = TaskStates.NOT_SAVED,
        onSelectTask,
    } = props;

    const { statusTasks } = useTaskDataState();
    const resultTasks     = statusTasks[resultStatusType];
    const headerTitle     = <SmtLocaleString k="common.close_page.title" />;
    const classNameModal  = block + block.mod('status', resultStatusType);

    return (
        <Modal
            className   = {classNameModal}
            onClose     = {onClose}
            narrowWidth
        >
            <ModalHeader
                className   = {block.el('header')}
                title       = {headerTitle}
                onClose     = {onClose}
            />

            <ModalBody className = {block.el('body')} >
                <ResultStates
                    mode         = {'modal'}
                    size         = {'xs'}
                    tasks        = {resultTasks}
                    statusType   = {resultStatusType}
                    onSelectTask = {onSelectTask}
                />
            </ModalBody>

            <ModalFooter className = {block.el('footer')}>
                <GrowGap />
                <Button
                    className   = {block.el('footer-return')}
                    onAction    = {onClose}
                    size        = {'s'}
                >
                    <SmtLocaleString k="common.close_page.return" />
                </Button>
                <MGap />
                <Button
                    className   = {block.el('footer-confirm')}
                    mode        = {'primary'}
                    onAction    = {onConfirm}
                    size        = {'s'}
                >
                    <SmtLocaleString k="common.close_page.close" />
                </Button>
            </ModalFooter>
        </Modal>
    )
}
