import React, {FunctionComponent, useState} from "react";
import SystemNotificationsDialogueControl from "Lab/SystemNotifications/SystemNotificationsDialogueControl";
import TextInputControl from "Lab/components/UI2/Controls/TextInputControl";
import "./SystemNotificationsDialogueTextAreaControl.less";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";

const SystemNotificationsDialogueTextAreaControl: FunctionComponent<SystemNotificationsDialogueControl> = (props) => {

    const {
        control,
        onChange,
        value,
    } = props;

    return <UIElementWrapper className="system_notifications_dialogue__textarea_element">
        <TextInputControl
            value={value || ""}
            placeholder={control.value}
            multiline
            fullWidth
            bordered
            rows={5}
            onChange={(textValue) => onChange(control.name, textValue)}
            className="system_notifications_dialogue__textarea"
        />
    </UIElementWrapper>;

};

export default SystemNotificationsDialogueTextAreaControl;
