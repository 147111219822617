import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const SwitcherOnIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>

    <svg width="24" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="4" width="32" height="14" rx="7" fill="#759DF4" />
        <g filter="url(#SwitcherOnIconfilter0_d)">
            <circle cx="25" cy="11" r="10" fill="#255ACC" />
        </g>
        <defs>
            <filter id="SwitcherOnIconfilter0_d" x="13" y="0" width="24" height="24" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>

</SvgIcon>;


export default SwitcherOnIcon;
