import AjaxWrapper                                                                     from "Cheops/AjaxWrapper";
import {ReqResponse}                                                                   from "Lab/Types";
import Task, {
    AnnulType,
    TaskAnswersData,
    TaskSection,
    TaskSolution,
    TaskSolutionToShow,
}                                                                                      from "Lab/Model/Task";
import {
    ContestReportStatus,
    ContestReportTaskAnswer,
    ContestReportTaskScorer,
    ContestReportTaskStatus,
    ContestReportTaskVersion,
    ContestReportTaskRecalculation,
    ContestReportTaskVersionV2,
}                                                                                      from "Lab/Contest/ContestReport/ContestReport";
import ResultTableCheckResponse                                                        from "Lab/Model/Smt/ResultTableCheckResponse";
import {ElementVerdict}                                                                from "Cheops/Model/Element";
import {ScorerParam, ScorerParams}                                                     from "Lab/Model/ScorerParams";
import {strNumber, TaskVersionInfo}                                                    from "Lab/actionsV2/environment";
import {CommonScorerList, PatchUserPermissionsPayload, PermissionsForm}                from "Lab/actionsV2/laboratory";
import {TaskAnswerType}                                                                from "Cheops/Model/Task";
import * as objectHash                                                                 from "object-hash";
import {ContestTasks}                                                                  from "Lab/Contest/ContestTasksTable/@types/ContestTasks";
import {ContestProgress}                                                               from "Lab/Contest/ContestProgress/@types/ContestProgress";
import Files                                                                           from "Lab/Utils/Files";
import {UserPermissions, UserPermissionsPatch}                                         from "../containers/PermissionPage";
import {WaitAuthToken} from "Lab/subjects/AuthTokenPolling";

const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";
export const SMT_API = CONFIG.Api?.smtLaboratory?.url ? `${CONFIG.Api.smtLaboratory.url}` : "";
export const LAB_SMT_API = CONFIG.Api?.smtLaboratory?.url ? `${CONFIG.Api.smtLaboratory.url}` : "";
const WITH_AUTH = {send_auth_token: true};

export const getContestInfo = (contestId: number | string): Promise<LabSmt.ContestResponse> =>
    AjaxWrapper.get(`${SMT_API}${VERSION}/contest/${contestId}`, {}, WITH_AUTH)
;

export const getContestExportPrintInfo = (contestId: number | string): Promise<ReqResponse<LabSmt.ExportPrintContest>> => {

    return AjaxWrapper.post(`${SMT_API}${VERSION}/contest/${contestId}/export-print`, {}, {
        send_auth_token: true,
    });


};


type UploadImageResponse = {
    hash: string;
};


export const uploadImage = (name: string, pic: File, resizeImage = false): Promise<ReqResponse<UploadImageResponse>> => {

    let formData = new FormData();

    formData.append('name', name);
    formData.append('pic', pic);

    return AjaxWrapper.post(`${SMT_API}/images?resize=${resizeImage}`, formData, {
        send_auth_token: true,
        headers: {
            Accept: 'application/json;charset=utf-8',
            'Content-Type': 'multipart/form-data',
        },
    });

};


export interface AnswerTableResponse {
    contestId: number;
    reportNewSessions: number;
    reportStatus: ContestReportStatus;
    tasks: Task[];
    sections: TaskSection[];
}

export const getAnswerTable = (contestId: number | string): Promise<ReqResponse<AnswerTableResponse>> => {

    return AjaxWrapper.get(
        `${SMT_API}/contest/${contestId}/answers-table/main`,
        {},
        {send_auth_token: true},
    );

};

export const generateAnswerTable = (contestId: number | string): Promise<ReqResponse> => {

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/answers-table/generate`,
        {},
        {send_auth_token: true},
    );

};

export type FileInfo = NooStorage.PublicInfo;

export const getFileInfo = (contestId: number | string, fileToken: string): Promise<ReqResponse<{ info: FileInfo }>> => {

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/file-info/${fileToken}`,
        {},
        {send_auth_token: true},
    );

};


export interface AnswerTableTaskResponse {
    id: number;
    versionId: number;
    original: Task;
    input: number;
    status: ContestReportTaskStatus;
    isAnnulled: AnnulType;
    annulComment: string;
    recalculations: ContestReportTaskRecalculation[];
    versions: ContestReportTaskVersion[];
    hasReport: boolean;
    version?: ContestReportTaskVersionV2;
}

export const getAnswerTableTask = (contestId: number | string, taskId: number | string, taskVersionId: number | string = null, inputId: number | string = null): Promise<ReqResponse<LabSmt.Contest.AnswersTable.TaskPageInfo>> => {


    let url = `${SMT_API}/contest/${contestId}/answers-table/task/v2/${taskId}`;

    if (taskVersionId) {

        url += `/${taskVersionId}`;

    }

    if (inputId !== null) {

        url += `?input=${inputId}`;

    }

    return AjaxWrapper.get(
        url,
        {},
        {send_auth_token: true},
    );

};

type AnswerTableTaskAnswersResponse = ResponsiveTableByBackend.Data<ContestReportTaskAnswer>;

export const getAnswerTableTaskAnswers = (contestId: number | string, taskId: number | string, taskVersionId: number | string, searchParams: SearchParams, inputId: number | string = null): Promise<ReqResponse<AnswerTableTaskAnswersResponse>> => {

    let url = `${SMT_API}/contest/${contestId}/answers-table/task/v2/${taskId}/${taskVersionId}/answers`;

    if (inputId !== null) {

        url += `?input=${inputId}`;

    }

    return AjaxWrapper.post(
        url,
        searchParams,
        {send_auth_token: true, keep_errors: true},
    );

};

const storedCommonScorers: Record<string, ReqResponse<CommonScorerList>> = {};

export const getContestCommonScorerList = async (types: TaskAnswerType[][], answersData: TaskAnswersData[][]): Promise<ReqResponse<CommonScorerList>> => {

    const data = {
        types,
        answersData,
    };


    const dataHash = objectHash.MD5(data);

    if (storedCommonScorers[dataHash]) {

        return storedCommonScorers[dataHash];

    }

    storedCommonScorers[dataHash] = await AjaxWrapper.post(
        `${SMT_API}/config/common-scorer/v2`,
        {types, answersData},
        {send_auth_token: true, keep_errors: true},
    );

    return storedCommonScorers[dataHash];

};

export const setAnswerTableTaskInputVerdict = (
    contestId: number | string,
    taskId: number | string,
    answerId: number | string,
    taskVersionId: number | string,
    verdict: ElementVerdict,
    tm: string,
    score: number = undefined,
    inputNumber: number | string = null,
): Promise<ReqResponse<ContestReportTaskAnswer>> => {


    let url = `${SMT_API}/contest/${contestId}/answers-table/${taskId}/${answerId}`;

    if (inputNumber) {

        url = `${SMT_API}/contest/${contestId}/answers-table/${taskId}/${taskVersionId}/${+inputNumber + 1}/${answerId}`;

    }

    return AjaxWrapper.put(
        url,
        {verdict, score, tm},
        {send_auth_token: true},
    );

};

export const applyAnswerTableTaskVerdict = (contestId: number | string, taskId: number | string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/answers-table/${taskId}/apply/v2`,
        {},
        {send_auth_token: true},
    );

};

export const checkAnswerTable = (contestId: number | string): Promise<ReqResponse<ResultTableCheckResponse>> => {

    return AjaxWrapper.get(
        `${SMT_API}/contest/${contestId}/answers-table/check`,
        {},
        {send_auth_token: true},
    );

};

export const setAnswerTableTaskScorers = (
    contestId: number | string,
    taskId: number | string,
    taskVersionId: number | string,
    scorer: ScorerParam[],
): Promise<ReqResponse<ContestReportTaskScorer>> => {

    return AjaxWrapper.put(
        `${SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${taskVersionId}/scorer`,
        {scorer},
        {send_auth_token: true},
    );

};

type PatchContestAnswerTableTaskParams = {
    contestId:      strNumber,
    taskId:         strNumber,
    versionId:      strNumber,
    data:           LabSmt.Contest.AnswersTable.TaskPatch,
};
export const patchContestAnswerTableTask = (
    {contestId, taskId, versionId, data}: PatchContestAnswerTableTaskParams
): Promise<LabSmt.Contest.AnswersTable.TaskPatchResponse> =>
    AjaxWrapper.put(
        `${LAB_SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${versionId}`,
        data,
        {...WITH_AUTH},
    )
;

export const exportContest = (contestId: number | string): Promise<Blob> => {

    return AjaxWrapper.get(
        `${SMT_API}/contest/${contestId}/export-json`,
        {},
        {
            send_auth_token: true,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            responseType: 'blob',
        },
    );

};

export const getContestResultsExport = (contestId: string | number): Promise<Blob> => {

    return AjaxWrapper.get(`${SMT_API}/contest/${contestId}/export`, {}, {
        send_auth_token: true,
        responseType: 'blob',
    });

};

// ContestId
export type ImportContestResult = string | number;

type HandlerContestParams = { folder_id: LabSmt.ContestFolderId } | { tour_id: LabSmt.TourId } | {};

export const importContest = (data: File, params: HandlerContestParams  = {}): Promise<ReqResponse<ImportContestResult, undefined>> => {

    let formData = new FormData();

    formData.append('file', data);

    return AjaxWrapper.post(
        `${SMT_API}/contest/import-json${ 'tour_id' in params ? `?tour_id=${params.tour_id}` : '' }${ 'folder_id' in params ? `?folder_id=${params.folder_id}` : '' }`,
        formData,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            ...WITH_AUTH,
        },
    );

};

export const annulTask = (contestId: number | string, taskId: number | string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/answers-table/${taskId}/annul`,
        {},
        {send_auth_token: true},
    );

};


export const cancelAnnulledTask = (contestId: number | string, taskId: number | string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.delete(
        `${SMT_API}/contest/${contestId}/answers-table/${taskId}/annul`,
        {},
        {send_auth_token: true},
    );

};

export const createContest = (data: LabSmt.Contest.InputContestObject, params: HandlerContestParams = {} ): Promise<ReqResponse<LabSmt.Contest.OutputContestObject>> =>
    AjaxWrapper.put(
        `${SMT_API}/contest/${ 'tour_id' in params ? `?tour_id=${params.tour_id}` : '' }${ 'folder_id' in params ? `?folder_id=${params.folder_id}` : '' }`,
        data,
        {send_auth_token: true},
    )
;


type OnlyFieldScorerParams = {
    params: ScorerParams;
};

export type TaskLayout = {
    preset?: number[][];
    inline?: {};
};

export interface SmtTaskVersion extends Task {
    contestId: number;
    taskId: number;
    versionId: number;
    version: number;
    number?: number;
    layout?: TaskLayout;
    scorerParams: ScorerParams;
    tests?: LabSmt.TaskTestList;
    checker?: LabSmt.CheckerObject;
    validator?: any;
}

export interface SmtTask extends Task {
    contestId: number;
    score?: number;
    number?: number;
    layout?: TaskLayout;
    versions?: SmtTaskVersion[];
    versionId: number;
}

export const putContestTaskScorer = (contestId: number | string, taskId: number | string, data: OnlyFieldScorerParams): Promise<ReqResponse<SmtTask>> => {

    return AjaxWrapper.put(
        `${SMT_API}/contest/${contestId}/task/${taskId}/scorer`,
        data,
        {send_auth_token: true},
    );

};

export interface GetContestTaskVersionListResponse {
    version: TaskVersionInfo[];
}


export const getContestTaskVersionList = (contestId: string | number, taskId: string | number): Promise<ReqResponse<GetContestTaskVersionListResponse>> => {

    return AjaxWrapper.get(
        `${SMT_API}/contest/${contestId}/task/${taskId}/version/list`,
        {},
        {send_auth_token: true},
    );

};

export const getContestTask = (contestId: number | string, taskId: number | string): Promise<ReqResponse<LabSmt.TaskGetResponseSuccess>> => {

    return AjaxWrapper.get(`${SMT_API}/contest/${contestId}/task/${taskId}`, {}, {
        send_auth_token: true,
    });

};

export const getContestTaskList = (contestId: number | string): Promise<ReqResponse<SmtTask[]>> => {

    return AjaxWrapper.get(`${SMT_API}/contest/${contestId}/list-tasks`, {}, {
        send_auth_token: true,
    });

};


interface TaskCreate {
    number?: number;
    description?: string;
    type?: TaskAnswerType[];
    solution?: TaskSolution[];
    solutionToShow?: TaskSolutionToShow[];
    answersData?: TaskAnswersData[];
    scorerType?: string;
    scorerParams?: ScorerParams;
}

export const putContestTask = (contestId: number | string, patches: TaskCreate): Promise<ReqResponse<{ taskId: number; versionId: number }>> => {

    return AjaxWrapper.put(`${SMT_API}/contest/${contestId}/task`, patches, {send_auth_token: true});

};


export const patchContestTask = (contestId: number | string, elementId: number | string, patches: LabSmt.SmtTask): Promise<ReqResponse<string>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/task/${elementId}`, patches, {send_auth_token: true, keep_errors: true});

};

export enum SearchTags {
    soon = "soon",
    actual = "actual",
    archive = "archive",
    all = "all",
}

export enum SortFields {
    title = "title",
    users = "users",
    tasks = "tasks",
    time = "time",
}

export enum SortOrders {
    ASC = "asc",
    DESC = "desc",
}

export type SortParam = {
    field: SortFields;
    order: SortOrders;
};

export type SearchParams = {
    currentPage: number;
    itemsPerPage: number;
    filter: { [k: string]: string };
    tags: SearchTags[];
    sort: SortParam[];
};

export type TagsInfoType = {
    title: string;
    name: SearchTags;
    count: number;
};

export type ContestStatus =
    | "readyToBePublished"
    | "published"
    | "archive"
    | "ongoing";

export type ContestState =
    | "finished"
    | "ongoing";

export type TourSearchEntry = {
    tourId?: string;
    contestId: number;
    title: string;
    users: number;
    tasks: number;
    ongoingCount?: number;
    contestCount?: number;
    timeStart?: string;
    timeFinish?: string;
    isApproved: boolean;
    isPublished: boolean;
    status: ContestStatus;
    state: ContestState;
};

export type SearchInfo = ResponsiveTableByBackend.Data<TourSearchEntry>;

export const searchContests = (searchParams: SearchParams): Promise<ReqResponse<SearchInfo>> => {

    return AjaxWrapper.post(`${SMT_API}/api/v2/contest/search`, searchParams, {send_auth_token: true, keep_errors: true});

};

export const publishContest = (contestId: string | number): Promise<ReqResponse<string>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/publish`, {}, {send_auth_token: true});

};

export const approveContest = (contestId: string): Promise<ReqResponse<string>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/approve`, {}, {send_auth_token: true});

};


export const deleteContestSession = (contestId: string | number): Promise<ReqResponse<string>> => {

    return AjaxWrapper.delete(`${SMT_API}/contest/${contestId}/super-session/delete`, {}, {send_auth_token: true});

};

export const deleteTour = (tourId: string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.delete(`${SMT_API}/tour/${tourId}`, {}, {send_auth_token: true});

};

export const deleteContests = (ids: number[]): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/delete`, {ids}, {send_auth_token: true});

};

type SearchContestData = { name: string; id: string }[];

export const searchContest = (name: string): Promise<ReqResponse<SearchContestData>> => {

    return AjaxWrapper.get(`${SMT_API}/contest/search?query=${name}`, {}, {
        send_auth_token: true,
    });

};



export const importContestByUrls = (req: Common.Downloader.DownloaderJobRequest, params: HandlerContestParams = {}): Promise<Common.Downloader.DownloaderJobResponse> =>
    AjaxWrapper.post(
        `${SMT_API}/job/import-contest/${ 
                'tour_id'   in params ? `?tour_id=${params.tour_id}`     : '' 
            }${ 
                'folder_id' in params ? `?folder_id=${params.folder_id}` : '' 
            }`
        ,
        req,
        {send_auth_token: true}
    )
;

export const archiveContest = (id: number | string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${SMT_API}/job/archive-contest/${id}`, {}, {send_auth_token: true});

};

export const unarchiveContest = (id: number | string): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.post(`${SMT_API}/job/unarchive-contest/${id}`, {}, {send_auth_token: true});

};

type ContestPermissions = {
    data: UserPermissions;
    form: PermissionsForm[];
};

export const getContestPermission = (contestId: string): Promise<ReqResponse<ContestPermissions>> => {

    return AjaxWrapper.get(`${SMT_API}/permission/${contestId}/v2`, {}, {send_auth_token: true});

};

export const patchContestAddPermission = (contestId: string, data: UserPermissionsPatch): Promise<ReqResponse<{users: {permission: "write" | "read"; user: string}[]}>> => {

    return AjaxWrapper.patch(`${SMT_API}/permission/${contestId}`, data, {send_auth_token: true});

};

export const patchContestPermission = (contestId: string, userId: string, objectId: string, data: PatchUserPermissionsPayload): Promise<ReqResponse<{}>> => {

    return AjaxWrapper.patch(`${SMT_API}/permission/${contestId}/${userId}/${objectId}`, data, {send_auth_token: true});

};

export const uploadMarkup = (contestId: string, taskId: string, data: File): Promise<ReqResponse<{}>> => {

    let formData = new FormData();

    formData.append('file', data);

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/answers-table/upload/${taskId}`,
        formData,
        {
            send_auth_token: true,
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
        },
    );

};

export const downloadMarkup = (contestId: string, taskId: string): Promise<Blob> => {

    return AjaxWrapper.post(
        `${SMT_API}/contest/${contestId}/answers-table/download/${taskId}`,
        {},
        {
            send_auth_token: true,
            headers: {
                'Content-Type': 'octet/stream',
                Accept: 'application/octet-stream',
            },
            responseType: 'blob',
        },
    );

};

export const getContestTasksTable = (searchParams: SearchParams, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/list-tasks/v2`, searchParams, {send_auth_token: true, keep_errors: true});

};

export type ContestTasksMoveData = {
    target: ContestTasks.TaskEntity;
    source: ContestTasks.TaskEntity[];
    direction: "below" | "above" | "variant";
};

export const moveContestTasks = (moveData: ContestTasksMoveData, pagerRequest: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/list-tasks/v2/move`, {...moveData, pagerRequest}, {send_auth_token: true, keep_errors: true});

};

export const addContestTasksSection = (section: ContestTasks.ContestTasksSectionsData, pagerRequest: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.put(`${SMT_API}/contest/${contestId}/list-tasks/v2`, {section, pagerRequest}, {send_auth_token: true, keep_errors: true});

};


export type ContestTaskDeleteData = ContestTasks.TaskEntity[];
export const deleteContestTaskEntity = (data: ContestTaskDeleteData, pagerRequest: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.delete(`${SMT_API}/contest/${contestId}/list-tasks/v2`, {targets: data, pagerRequest}, {send_auth_token: true, keep_errors: true});

};

export const getContestTasksSection = (contestId: number, sectionId: number): Promise<ReqResponse<ContestTasks.ContestTasksSectionsData>> => {

    return AjaxWrapper.get(`${SMT_API}/contest/${contestId}/sections/${sectionId}`, {}, {send_auth_token: true, keep_errors: true});

};

export const updateContestTasksSection = (contestId: number, sectionId: number, section: ContestTasks.ContestTasksSectionsData): Promise<ReqResponse<ContestTasks.ContestTasksSectionsData>> => {

    return AjaxWrapper.put(`${SMT_API}/contest/${contestId}/sections/${sectionId}`, section, {send_auth_token: true, keep_errors: true});

};

export const groupContestTasks = (ids: Array<number>, pagerRequest: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/groups`, {ids, request: pagerRequest}, {send_auth_token: true, keep_errors: true});

};

export const ungroupContestTasks = (ids: Array<number>, pagerRequest: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.patch(`${SMT_API}/contest/${contestId}/groups`, {ids, request: pagerRequest}, {send_auth_token: true, keep_errors: true});

};

export const getContestProgressTable = (searchParams: SearchParams, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestProgress.Progress>>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/progress`, searchParams, {send_auth_token: true, keep_errors: true});

};

export const getContestProgressTableV2 = (request: LabSmt.Contest.ProgressTable.ContestProgressRequest, contestId: string): Promise<ReqResponse<LabSmt.Contest.ProgressTable.ContestProgressResponse['success']>> =>
    AjaxWrapper.post(`${SMT_API}/contest/${contestId}/progress-v2`, request, {send_auth_token: true, keep_errors: true})
;

export const editNameInContestProgressTable = (data: LabSmt.Contest.UserInfo.RegInfoUserEditRequest, contestId: string): Promise<ReqResponse<LabSmt.Contest.UserInfo.RegUserInfoEditResponse>> =>
    AjaxWrapper.post(`${SMT_API}/contest/${contestId}/reg-user-info-edit`, data, {send_auth_token: true, keep_errors: true})
;

export type NewTask = {
    contestId: number;
    description: string;
    number: number | string;
    direction: "below" | "above" | "variant";
};

export const createContestTask = (task: NewTask, request: ResponsiveTableByBackend.PageRequest, contestId: number): Promise<ReqResponse<ResponsiveTableByBackend.Data<ContestTasks.ContestTasksData>>> => {

    return AjaxWrapper.post(`${SMT_API}/contest/${contestId}/tasks`, {task, request}, {send_auth_token: true, keep_errors: true});

};

export const downloadProgressCsv = (contestId: number, payload: ResponsiveTableByBackend.Data<any>['state']): void => {

    AjaxWrapper.post(`${SMT_API}/contest/${contestId}/progress/csv`, payload, {send_auth_token: true, keep_errors: true})
        .then((res) => {
            if (res) {
                Files.download(res, `contest-${contestId}-progress.csv`, 'text/csv');
            }
        });

};

export const downloadProgressCsvV2Selected = (contestId: string, data: LabSmt.Contest.ProgressTable.ContestProgressCSVSessionsRequest): void => {
    AjaxWrapper.post(`${SMT_API}/contest/${contestId}/progress-v2/csv-sessions`, data, {send_auth_token: true, keep_errors: true})
        .then((res) => {
            res && Files.download(res, `contest-${contestId}-progress.csv`, 'text/csv');
        })
    ;
};

export const downloadProgressCsvV2 = (contestId: string, data: LabSmt.Contest.ProgressTable.ContestProgressCSVRequest): void => {
    AjaxWrapper.post(`${SMT_API}/contest/${contestId}/progress-v2/csv`, data, {send_auth_token: true, keep_errors: true})
        .then((res) => {
            res && Files.download(res, `contest-${contestId}-progress.csv`, 'text/csv');
        })
    ;
};


export const LabSmt_POST_ProgrammingUploadTests = (formData: FormData): Promise<LabSmt.TestUploadResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/programming/upload-tests`,
        formData,
        {
            send_auth_token: true,
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
        },
    )

export const LabSmt_POST_ProgrammingUploadChecker = (formData: FormData): Promise<LabSmt.UploadCheckerResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/programming/upload-checker`,
        formData,
        {
            send_auth_token: true,
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
        },
    )

export const LabSmt_GET_loadSmtTest = ({contestId, taskId, testId}: {contestId: string, taskId: string, testId: string | number} ): Promise<LabSmt.TaskGetTestResponse> =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/task/${taskId}/test/${testId}`,
        {},
        {
            send_auth_token: true,
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
        }
    )

export const LabSmt_GET_loadCheckers = (): Promise<LabSmt.CheckerDescriptionListResponse> =>
    WaitAuthToken('LabSmt_GET_loadCheckers').then(
        () =>
            AjaxWrapper.get(
                `${LAB_SMT_API}/programming/list-checkers`,
                {},
                { send_auth_token: true }
            )
    )
;


export const LabSmt_POST_contest__task_settings_form__update = (
    contestId: number,
    data: Pick<LabSmt.TasksSettingsFormRequest, "target"> & {value: object}
): Promise<LabSmt.TasksSettingsFormResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/tasks-settings-form`,
        data,
        {send_auth_token: true},
    )
;
export const LabSmt_POST_contest__task_settings_form = (
    contestId: number,
    data: Pick<LabSmt.TasksSettingsFormRequest, "target">
): Promise<LabSmt.TasksSettingsFormResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/tasks-settings-form`,
        data,
        {send_auth_token: true},
    )
;


type GetParam = number | string;

type LabSmt_POST_contest_settings_form_Params = {
    contest_id:      GetParam;
    task_id:         GetParam;
    task_version_id: GetParam;
}

export const LabSmt_POST_contest__load_settings_form = (
    params: LabSmt_POST_contest_settings_form_Params,
    data: LabSmt.TaskSettingsLoadFormRequest
): Promise<LabSmt.TasksSettingsFormResponse> => {
    const {contest_id, task_id, task_version_id} = params;
    return AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contest_id}/task/${task_id}/${task_version_id}/load-settings-form`,
        data,
        {send_auth_token: true},
    )
};

export const LabSmt_POST_contest__save_settings_form = (
    params: LabSmt_POST_contest_settings_form_Params,
    data: LabSmt.TaskSettingsSaveFormRequest
): Promise<LabSmt.TaskSettingsSaveFormResponse> => {
    const {contest_id, task_id, task_version_id} = params;
    return AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contest_id}/task/${task_id}/${task_version_id}/save-settings-form`,
        data,
        {send_auth_token: true},
    )
};

type LabSmt_POST_contest__inputs__settings_form_Params = {
    contestId:      GetParam;
    elementId:      GetParam;
    versionId:      GetParam;
    inputNumber:    GetParam;
}

export const LabSmt_POST_contest__inputs__load_settings_form = (
    params: LabSmt_POST_contest__inputs__settings_form_Params,
    data: LabSmt.TaskInputSettingsLoadFormRequest
): Promise<LabSmt.TaskInputSettingsLoadFormResponse> => {
    const {contestId, elementId, versionId, inputNumber} = params;
    return AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/task/${elementId}/${versionId}/inputs/${inputNumber}/load-settings-form`,
        data,
        {send_auth_token: true},
    )
}

export const LabSmt_POST_contest__inputs__save_settings_form = (
    params: LabSmt_POST_contest__inputs__settings_form_Params,
    data: LabSmt.TaskInputSettingsSaveFormRequest
): Promise<LabSmt.TaskInputSettingsSaveFormResponse> => {
    const {contestId, elementId, versionId, inputNumber} = params;
    return AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/task/${elementId}/${versionId}/inputs/${inputNumber}/save-settings-form`,
        data,
        {send_auth_token: true},
    )
}



interface IVideoResolveContainer {
    urlVideo: string
}
export type TGetVideoResolveContainer = (args: IVideoResolveContainer) =>
    Promise<LabSmt.ResolveVideoResponse>;

export const getVideoResolveContainer: TGetVideoResolveContainer = (
    {
        urlVideo
    }
) => {

    const videoResolveRequest: Common.Video.VideoResolveRequest = {
        url: urlVideo
    };
    return AjaxWrapper.post(
        `${LAB_SMT_API}/api/v2/resolve-video`,
        {...videoResolveRequest},
        {...WITH_AUTH}
    )
};

export const downloadTaskSolutions = (contestId: string, sessionId: string, taskNum: number): void => {
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/progress/solutions/${sessionId}/${taskNum}`, {},
        {
            send_auth_token: true,
            keep_errors: true,
            headers: {
                Accept: 'text/plain',
            },
        })
        .then((res) => {
            if(res) {
                Files.download(res, `contest-${contestId}-session-${sessionId}-solutions.txt`, 'text/plain');
            }
        })
    ;
};

interface IGetContestProgrammingTaskReportParams  {
    contestId: GetParam;
    elementId: GetParam;
    versionId: GetParam;
}
type TGetContestProgrammingTaskReport = ( params: IGetContestProgrammingTaskReportParams) => Promise<LabSmt.TaskStandardSolutionsResponse>;

export const getContestProgrammingTaskReport: TGetContestProgrammingTaskReport = (
    {contestId, elementId, versionId}
) =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/task/${elementId}/${versionId}/std-solutions`,
        {},
        {send_auth_token: true},
    )
;

interface IGetContestCheckerCompilationReportParams  {
    contestId: GetParam;
    elementId: GetParam;
    versionId: GetParam;
}
type TGetContestCheckerCompilationReport = ( params: IGetContestCheckerCompilationReportParams ) => Promise<LabSmt.TaskCheckerCompilationReport>;

export const getContestCheckerCompilationReport: TGetContestCheckerCompilationReport = (
    {contestId, elementId, versionId}
) =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/task/${elementId}/${versionId}/checker-compilation`,
        {},
        {send_auth_token: true},
    )
;

type TGetContestLanguagesList = ( ) => Promise<LabSmt.LanguagesListResponse>;
export const getContestLanguagesList: TGetContestLanguagesList = () =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/programming/list-languages`,
        {},
        {send_auth_token: true},
    )
;

interface IGetContestScorerReportParams  {
    contestId: GetParam;
    elementId: GetParam;
    versionId: GetParam;
}
type TGetContestScorerReport = ( params: IGetContestScorerReportParams ) => Promise<LabSmt.TaskCheckerCompilationReport>;

export const getContestScorerReport: TGetContestScorerReport = (
        {contestId, elementId, versionId}
    ) =>
        AjaxWrapper.get(
            `${LAB_SMT_API}/contest/${contestId}/task/${elementId}/${versionId}/checker-report`,
            {},
            {send_auth_token: true},
        )
;

export const publishContestResults = (contestId: number | string): Promise<LabSmt.ResultsPublishResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/publish-results`,
        {},
        {send_auth_token: true},
    )
;

export const publishContestResultsForSelected = (contestId: string, selected: LabSmt.ContestPublishResultsForRequest): Promise<LabSmt.ResultsPublishResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/publish-results-for`,
        selected,
        {send_auth_token: true},
    )
;

export const unpublishContestResults = (contestId: number | string): Promise<LabSmt.UnpublishResultsResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/unpublish-results`,
        {},
        {send_auth_token: true},
    )
;

export const unpublishContestResultsForSelected = (contestId: string, selected: LabSmt.ContestPublishResultsForRequest): Promise<LabSmt.ResultsPublishResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest/${contestId}/unpublish-results-for`,
        selected,
        {send_auth_token: true},
    )
;

export const getProgressTableUserInfo = (contestId: string, sessionId: string): Promise<LabSmt.Contest.Progress.User.ProgressUserInfoResponse> =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/progress/user/${sessionId}`,
        {},
        {send_auth_token: true, keep_errors: true},
    )
;

export const ContestFoldersTable = (req: LabSmt.TableViewRequest): Promise<LabSmt.TableViewResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/table-view`,
        req,
        {send_auth_token: true, keep_errors: true},
    )
;

export const PutContestFolderTable = (req: LabSmt.Contest.Folder.ContestFolderCreateRequest): Promise<LabSmt.Contest.Folder.ContestFolderResponse> =>
    AjaxWrapper.put(
        `${LAB_SMT_API}/contest-folder`,
        req,
        {send_auth_token: true},
    )
;

export const PutContestTour = (req: LabSmt.Tour.TourCreateRequest, contest_folder_id?: LabSmt.ContestFolderId): Promise<LabSmt.Tour.TourCreateResponse> =>
    AjaxWrapper.put(
        `${LAB_SMT_API}/tour${ contest_folder_id ? `?contest_folder_id=${contest_folder_id}` : '' }`,
        req,
        {send_auth_token: true},
    )
;

export const DeleteMassDelete = (req: LabSmt.MassDeleteRequest): Promise<LabSmt.MassDeleteResponse> =>
    AjaxWrapper.delete(
        `${LAB_SMT_API}/mass-delete`,
        req,
        {send_auth_token: true},
    )
;

export const PostContestFolderMoveMany = (req: LabSmt.Contest.Folder.MoveManyRequest): Promise<LabSmt.Contest.Folder.MoveManyResponse> =>
    AjaxWrapper.post(
        `${LAB_SMT_API}/contest-folder/move-many`,
        req,
        {send_auth_token: true},
    )
;

export interface LabSmt_POST_TableView__load_settings_formParams {
    data: LabSmt.Contest.Folder.SettingsForm.TableViewSettingsLoadFormRequest
}
export const LabSmt_POST_TableView__load_settings_form = (
        {
            data,
        }: LabSmt_POST_TableView__load_settings_formParams
    ): Promise<LabSmt.Contest.Folder.SettingsForm.TableViewSettingsFormResponse> =>

        AjaxWrapper.post(
            `${LAB_SMT_API}/contest-folder/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export interface LabSmt_POST_TableView__save_settings_formParams {
    data: LabSmt.Contest.Folder.SettingsForm.TableViewSettingsSaveFormRequest
}
export const LabSmt_POST_TableView__save_settings_form = (
        {
            data,
        }: LabSmt_POST_TableView__save_settings_formParams
    ): Promise<LabSmt.Contest.Folder.SettingsForm.TableViewSettingsFormResponse> =>
        AjaxWrapper.post(
            `${LAB_SMT_API}/contest-folder/settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const getContestValidatorsList = (): Promise<LabSmt.ClientValidation.ValidatorList> =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/client-validation/list`,
        {},
        {send_auth_token: true},
    )
;

export const getContestOwners = (contestId: GetParam): Promise<LabSmt.Owners.OwnersResponseSuccess> =>
    AjaxWrapper.get(
        `${LAB_SMT_API}/contest/${contestId}/owners`,
        {},
        {...WITH_AUTH}
    )
;

export const insertFiles = (data: File, name: string): Promise<Common.File.FileInsertResponse> => {
    const file = new FormData();

    file.append('file', data);

    return AjaxWrapper.post(
        `${LAB_SMT_API}/api/v2/files/insert?name=${name}`,
        file,
        {
            headers: {
                Accept: 'application/json;charset=utf-8',
                'Content-Type': 'multipart/form-data',
            },
            ...WITH_AUTH,
        }
    );
};

type PostContestAnswersTableTaskSettingsFormParams<D> = {
    contestId:      strNumber,
    taskId:         strNumber,
    taskVersionId:  strNumber,
    data:           D,
};

export const postContestAnswersTableTaskLoadSettingsForm = (
        {
            contestId,
            taskId,
            taskVersionId,
            data,
        }: PostContestAnswersTableTaskSettingsFormParams<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormLoadRequest>
    ): Promise<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormLoadResponse> =>

        AjaxWrapper.post(
            `${LAB_SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${taskVersionId}/load-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postContestAnswersTableTaskSaveSettingsForm = (
        {
            contestId,
            taskId,
            taskVersionId,
            data,
        }: PostContestAnswersTableTaskSettingsFormParams<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormSaveRequest>
    ): Promise<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormSaveResponse> =>

        AjaxWrapper.post(
            `${LAB_SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${taskVersionId}/save-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

type PostContestAnswersTableTaskInputsSettingsFormParams<D> = {
    contestId:      strNumber,
    taskId:         strNumber,
    taskVersionId:  strNumber,
    inputNumber:    strNumber,
    data:           D,
};

export const postContestAnswersTableTaskInputsLoadSettingsForm = (
        {
            contestId,
            taskId,
            taskVersionId,
            inputNumber,
            data,
        }: PostContestAnswersTableTaskInputsSettingsFormParams<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormLoadRequest>
    ): Promise<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormLoadResponse> =>

        AjaxWrapper.post(
            `${LAB_SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${taskVersionId}/inputs/${inputNumber}/load-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postContestAnswersTableTaskInputsSaveSettingsForm = (
        {
            contestId,
            taskId,
            taskVersionId,
            inputNumber,
            data,
        }: PostContestAnswersTableTaskInputsSettingsFormParams<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormSaveRequest>
    ): Promise<LabSmt.Contest.AnswersTable.SettingsForm.SettingsFormSaveResponse> =>

        AjaxWrapper.post(
            `${LAB_SMT_API}/contest/${contestId}/answers-table/task/${taskId}/${taskVersionId}/inputs/${inputNumber}/save-settings-form`,
            data,
            {...WITH_AUTH},
        )
;

export const postContest = (contestId: string, data: LabSmt.Contest.InputContest): Promise<LabSmt.ContestInputResponse> =>
    AjaxWrapper.post(`${LAB_SMT_API}/contest/${contestId}`, data, {...WITH_AUTH})
;

export const putContest = (data: LabSmt.Contest.InputContestObject): Promise<LabSmt.Contest.OutputContestObject> =>
    AjaxWrapper.put(`${LAB_SMT_API}/contest`, data, {...WITH_AUTH})
;

export const putContestMeta = (contestId: string, data: LabSmt.Contest.Settings.ContestSettingsMetadataInfo): Promise<LabSmt.Contest.Settings.UpdateContestSettingsMetadataResponse> =>
    AjaxWrapper.put(`${LAB_SMT_API}/contest/${contestId}/metadata`, data, {
        ...WITH_AUTH,
        // dataHandler: data => data.success
    })
;

export const getContestMeta = (contestId: string): Promise<LabSmt.Contest.Settings.ContestSettingsMetadataResponse> =>
    AjaxWrapper.get(`${LAB_SMT_API}/contest/${contestId}/metadata`, {}, {
        ...WITH_AUTH,
        // dataHandler: data => data.success
    })
;

export const getContestListTitles = (contestIds: LabSmt.Methods.ContestId[]): Promise<LabSmt.Methods.ResolveContestIdsResponse> =>
    AjaxWrapper.post(`${LAB_SMT_API}/resolve`, {ids: contestIds}, {...WITH_AUTH})
;
