import {UISchemaType}                   from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import * as LibSchema                   from "@sirius/ui-lib/src/blocks/SchemaField/ui/lab";
import {
    MetaField,
    CountryField,
    StringField,
    BooleanField,
    SchemaField,
    ErrorField,
}                       from "Smt/Auth/SchemaField";


const {UISchema: LibUISchema} = LibSchema;

export const UISchema: UISchemaType = {
    ...LibUISchema,
    BooleanField,
    StringField,
    MetaField,
    "School-countryField": CountryField,
    SchemaField,
    ErrorField
};
