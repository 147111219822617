import {
    LocalePlural,
    LocaleString,
} from "@sirius/ui-lib/src/blocks/LocaleBlock";
import {withTranslation} from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";
import {translations} from "Cheops/translations";
import {LocalizedSelect} from "Cheops/CheopsLocaleBlock/FormattedSelect";
import {LocalizedInput} from "Cheops/CheopsLocaleBlock/FormattedInput";

const CheopsLocaleString = withTranslation(LocaleString, translations);
const CheopsLocalePlural = withTranslation(LocalePlural, translations);
const CheopsLocaleSelect = withTranslation(LocalizedSelect, translations);
const CheopsInputLocaleBlock = withTranslation(LocalizedInput, translations);
// @ts-ignore
CheopsInputLocaleBlock.displayName = 'CheopsInputLocaleBlock';
export {
    CheopsLocaleString,
    CheopsLocalePlural,
    CheopsLocaleSelect,
    CheopsInputLocaleBlock,
};
