/* eslint-disable */
import {SELECTED_MODULE_ID} from "../actions";



export function selectedModuleId(state = null, action) {
    switch (action.type) {
        case SELECTED_MODULE_ID:
            return action.status;
        default:
            return state
    }
}
