import React, {FC}        from "react";
import {TextInput}        from '@sirius/ui-lib/src/blocks/Controls/TextInput';
import {MaybeHasMode}     from "@sirius/ui-lib/src/blocks/Controls/@types";
import {StringFieldProps} from "../@types";

export const StringFieldText: FC<StringFieldProps> = (props) => {
    const {
        data,
        block,
        onChange,
        disabled = false,
        isError  = false,
    } = props;

    const onChangeHandler = (value: string ) => {
        const next = value.trim();
        onChange(next);
    };

    const fieldModeProps: MaybeHasMode = isError ? {mode: 'wrong'} : void(0);

    return  <TextInput {...fieldModeProps}
                       className    = {block.el('input')}
                       onChange     = {onChangeHandler}
                       defaultValue = {data}
                       disabled     = {disabled}
            />
    ;
}
