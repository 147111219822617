import React from "react";
import {CircularProgress}        from "@sirius/ui-lib/src/blocks/CircularProgress";


interface Props {
    buttonStates: string[];
    grouped?: boolean;
    bgColor: string;
    selectTask?: (taskNum: number) => void;
    navType?: any;
    text: string;
    taskNum?: number;
}

const TaskSelectorLink: React.FC<Props> = ({grouped, text, taskNum, buttonStates, selectTask, bgColor, navType}) => {
    let className = "task_selector__task";
    let isResult = false;
    for (let state of buttonStates) {
        if (state === 'active' && grouped) {
            continue;
        }
        if (['result_not_right', 'result_partial_right', 'result_right'].includes(state)) {
            isResult = true;
        }
        className += ` task_selector__task--${state}`;
    }

    let selectTaskCallback: Props['selectTask'] = () => null;
    if (selectTask) {
        selectTaskCallback = selectTask;
    }

    let styles: React.CSSProperties = {};

    if (bgColor && !buttonStates?.length) {
        styles.background = bgColor;
    }

    if (navType) {
        className += ` task_selector__task--${navType}`;
    }

    return  <div
                onClick={() => selectTaskCallback(taskNum)}
                style={styles}
                className={className}
            >
                {
                    buttonStates.includes("pending") &&
                    <div className="task_selector__pending-progress">
                        <CircularProgress centerOfWindow white/>
                    </div>
                }
                {text}
            </div>
    ;
};

export default TaskSelectorLink;
