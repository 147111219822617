/* eslint-disable */
export default function (state = {
    confirmed_courses: [],
    applied_autograde_count: null,
}, action) {
    switch (action.type) {
        case "CONFIRM_AUTOGRADE":
            let confirmed_courses = [...state.confirmed_courses];
            confirmed_courses.push(action.course_id);

            return {
                ...state,
                confirmed_courses,
            };

        case "APPLIED_AUTOGRADE_COUNT":
            return {
                ...state,
                applied_autograde_count: action.count,
            };

        default:
            return state
    }
}
