import {TranslationsType}   from "@sirius/ui-lib/src/blocks/LocaleBlock/WithTranslation";
import {i18nProject}        from "./smt-register-form-block";

export const translations: TranslationsType = {
    [`${i18nProject}-validation`]: {
        'format_email': {
            ru: {value: "Неверный email"},
            en: {value: "Wrong email"},
        },
        'format_vosh-login': {
            ru: {value: "Неверный ВОШ-логин. Попробуйте ещё раз"},
            en: {value: "Vosh-login incorrect. Try again"},
        },
        'format_vosh-login-optional': {
            ru: {value: "Неверный ВОШ-логин. Попробуйте ещё раз"},
            en: {value: "Vosh-login incorrect. Try again"},
        },
        'format_snils': {
            ru: {value: "Неверный СНИЛС. Попробуйте ещё раз"},
            en: {value: "SNILS incorrect. Try again"},
        },
        'format_snils-opt': {
            ru: {value: "Неверный СНИЛС. Попробуйте ещё раз"},
            en: {value: "SNILS incorrect. Try again"},
        },
        'format_snils_only_digits': {
            ru: {value: "СНИЛС должен содержать только цифры"},
            en: {value: "SNILS have to contains digits only"},
        },
        'format_snils_eleven': {
            ru: {value: "СНИЛС должен состоять ровно из 11 цифр"},
            en: {value: "SNILS have to be of 11 digits"},
        },
        'format_snils_sum': {
            ru: {value: "Контрольная сумма СНИЛС не совпадает"},
            en: {value: "Checksum of SNILS is not valid"},
        },
        'format_birth-date': {
            ru: {value: "Неверная дата"},
            en: {value: "Wrong date"},
        },
    },
    [i18nProject]: {
        'root/title': {
            ru: {value: "Укажите данные о себе"},
            en: {value: "Your personal data"},
        },
        'firstName/title': {
            ru: {value: "Имя"},
            en: {value: "First name"},
        },
        'lastName/title': {
            ru: {value: "Фамилия"},
            en: {value: "Last name"},
        },
        'patronymic/title': {
            ru: {value: "Отчество"},
            en: {value: "Patronymic"},
        },
        'birth-date/title': {
            ru: {value: "Дата рождения"},
            en: {value: "Birth date"},
        },
        'email/title': {
            ru: {value: "Электронная почта"},
            en: {value: "Email"},
        },
        'country/title': {
            ru: {value: "Страна"},
            en: {value: "Country"},
        },
        'city/title': {
            ru: {value: "Город"},
            en: {value: "Сity"},
        },
        'school/title': {
            ru: {value: "Информация о школе"},
            en: {value: "Information about school"},
        },
        'school/school/title': {
            ru: {value: "Школа"},
            en: {value: "School"},
        },
        'school/grade/title': {
            ru: {value: "Класс"},
            en: {value: "Grade"},
        },
        'switch/title': {
            ru: {value: "switch"},
            en: {value: "switch"},
        },
        'profession/title': {
            ru: {value: "Профессия"},
            en: {value: "Profession"},
        },
        'school/organization/title': {
            ru: {value: "Название организации"},
            en: {value: "Organisation name"},
        },
        'vosh-login/title': {
            ru: {value: "ВОШ-логин"},
            en: {value: "VOSH-login"},
        },
        'vosh-login-optional/title': {
            ru: {value: "ВОШ-логин"},
            en: {value: "VOSH-login"},
        },
        'phone/title': {
            ru: {value: "Телефон"},
            en: {value: "Phone"},
        },
        'snils/title': {
            ru: {value: "СНИЛС"},
            en: {value: "Snils"},
        },
        'snils-opt/title': {
            ru: {value: "СНИЛС"},
            en: {value: "Snils"},
        },
        'familiarized-with-the-rules/title': {
            ru: {value: 'Я прочитал [правила проведения]({link})'},
            en: {value: 'Familiarized with [the rules]({link})'},
        },
        'confirmation-of-veracity/title': {
            ru: {value: "Я подтверждаю правильность указанных данных"},
            en: {value: "Confirm input data"},
        },
        'users-agreement-and-personal-data/title': {
            ru: {
                value: "Даю согласие на [обработку персональных данных]({linkPersonalData}) в соответствии  с [Политикой конфиденциальности]({linkPrivacyPolicy})",
                values: {
                    linkPrivacyPolicy: "https://sochisirius.ru/o-siriuse/privacy-policy",
                    linkPersonalData:  "https://sochisirius.ru/uploads/2023/12/SOGLASIE_RU.pdf",
                },
            },
            en: {
                value: "I give my consent to the [processing of personal data]({linkPersonalData}) in accordance with the [Privacy Policy]({linkPrivacyPolicy})",
                values: {
                    linkPrivacyPolicy: "https://sochisirius.ru/o-siriuse/privacy-policy",
                    linkPersonalData:  "https://sochisirius.ru/uploads/2023/12/SOGLASIE_ENG.pdf",
                },
            },
        },
    }
};
