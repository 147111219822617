import {SvgIcon} from "@material-ui/core";
import React from "react";
import {CustomIconsProps} from "Lab/components/UI2/Atoms/IconAtom";

const PasteIcon: React.FC<CustomIconsProps> = ({className}) => <SvgIcon className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M4 1L15 0.999999C15.55 0.999999 16 1.45 16 2C16 2.55 15.55 3 15 3L5 3C4.45 3 4 3.45 4 4L4 16C4 16.55 3.55 17 3 17C2.45 17 2 16.55 2 16L2 3C2 1.9 2.9 1 4 1ZM9 5C7.34315 5 6 6.34315 6 8L6 20C6 21.6569 7.34315 23 9 23L18 23C19.6569 23 21 21.6569 21 20L21 8C21 6.34314 19.6569 5 18 5L9 5Z"
              fill="white"
        />
    </svg>
</SvgIcon>;


export default PasteIcon;
