import React from 'react';
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import BemClassName from "Cheops/BemClassName";
import {BUTTON_COLOR, BUTTON_VARIANTS} from "Lab/components/UI2/Controls/ButtonControl";
import {UIControl} from "../UIControl";
import IconAtom, {ICON_SIZES} from "../Atoms/IconAtom";
import "./IconControl.less";

export type IconControlProps = {

    iconName: string;
    size?: ControlSizes;
    className?: string;
    iconClassName?: string;
    active?: boolean;
    darkMode?: boolean;
    roundShaped?: boolean;
    color?: BUTTON_COLOR;
    highlighted?: boolean;
    variant?: BUTTON_VARIANTS;

};

const checkEnumStringValuesFit = (valueFrom: ControlSizes): boolean => {

    const enum1Key = (Object.keys(ICON_SIZES) as (keyof typeof ICON_SIZES)[]).find((key) => (
        ICON_SIZES[key] === valueFrom as string));

    return !!enum1Key;

};

export default class IconControl extends UIControl<IconControlProps> {

    static defaultProps = {
        ...UIControl.defaultProps,
        iconName: "",
        size: ControlSizes.LG,
        color: BUTTON_COLOR.DEFAULT,
        variant: BUTTON_VARIANTS.TEXT,
        className: "",
        active: false,
        roundShaped: false,
        darkMode: false,
        hasErrors: false,
        disabled: false,
        highlighted: false,
    };


    render(): React.ReactNode {

        const {
            iconName,
            size,
            className,
            active,
            hasErrors,
            darkMode,
            roundShaped,
            color,
            disabled,
            highlighted,
            variant,
            iconClassName,
        } = this.props;

        const wrapperClassName = new BemClassName('icon_control');

        wrapperClassName.appendAdditionalClassesIf(className.length > 0, className);
        wrapperClassName.appendAdditionalClassesIf(hasErrors, 'error');
        wrapperClassName.appendAdditionalClassesIf(active, 'active');
        wrapperClassName.appendAdditionalClassesIf(darkMode, 'dark-mode');
        wrapperClassName.appendAdditionalClassesIf(roundShaped, 'round');
        wrapperClassName.appendAdditionalClassesIf(disabled, 'disabled');
        wrapperClassName.appendAdditionalClassesIf(color === BUTTON_COLOR.PRIMARY, 'colorPrimary');
        wrapperClassName.appendAdditionalClassesIf(highlighted, 'highlighted');
        wrapperClassName.appendAdditionalClassesIf(!!variant, variant);
        wrapperClassName.appendStatusIf(!!size, size);

        const _iconSize = checkEnumStringValuesFit(size) ? size : ICON_SIZES.LG;

        return (<UIControlWrapper size={size} className={wrapperClassName.toString()}>
                <IconAtom
                    name={iconName}
                    active={active}
                    size={_iconSize as ICON_SIZES}
                    hasErrors={hasErrors}
                    disabled={disabled}
                    className={iconClassName}
                />
            </UIControlWrapper>
        );

    }

}
