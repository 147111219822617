import React from 'react';
import Radio from "@material-ui/core/Radio";
import UIControlWrapper, {ControlSizes} from "Lab/components/UI2/UIControlWrapper";
import FormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel/FormControlLabel';
import BemClassName from "Cheops/BemClassName";
import {UIControl, UIControlProps} from "../UIControl";
import './RadioControl.less';


export interface RadioControlProps extends UIControlProps {

    onChange?(event: React.MouseEvent, checked: boolean): void;

    children?: React.ReactNode;
    labelPlacement?: RadioControlLabelPlacement;
    size?: ControlSizes;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    name?: string;
    value?: string;
    fullWidth?: boolean;
    color?: "primary" | "secondary" | "default";
    darkMode?: boolean;
    highlightLabelOnCheck?: boolean;
}


export enum RadioControlLabelPlacement {
    left, top, right, bottom
}

export default class RadioControl extends UIControl<RadioControlProps> {

    static defaultProps = {
        ...UIControl.defaultProps,
        size: ControlSizes.LG,
        checked: false,
        disabled: false,
        className: "",
        labelPlacement: RadioControlLabelPlacement.right,
        color: "secondary",
        highlightLabelOnCheck: false,
    };


    getLabelPlacement(): FormControlLabelProps['labelPlacement'] {

        const {labelPlacement} = this.props;


        const labelPlacementToMUIPlacement = {
            [RadioControlLabelPlacement.right]: 'end',
            [RadioControlLabelPlacement.left]: 'start',
            [RadioControlLabelPlacement.bottom]: 'bottom',
            [RadioControlLabelPlacement.top]: 'top',
        };

        return labelPlacementToMUIPlacement[labelPlacement] as FormControlLabelProps['labelPlacement'];

    }

    render(): React.ReactNode {

        const {
            checked,
            children,
            disabled,
            className,
            value,
            fullWidth,
            onChange,
            size,
            color,
            darkMode,
            highlightLabelOnCheck,
        } = this.props;

        const wrapperClassName = new BemClassName("radio_control", null, className);
        wrapperClassName.appendStatusIf(darkMode, "darkMode");
        wrapperClassName.appendStatusIf(highlightLabelOnCheck && checked, "highlight");

        const _size = size === ControlSizes.LG ? ControlSizes.MD : size;

        return <UIControlWrapper className={wrapperClassName.toString()} size={size} fullWidth={fullWidth}>
            <FormControlLabel
                value={value}
                control={<Radio checked={checked} disabled={disabled} size={_size} color={color} />}
                label={children}
                onChange={onChange}
                onClick={(event: React.MouseEvent)=> { event.stopPropagation(); }}
                labelPlacement={this.getLabelPlacement()}
            />
        </UIControlWrapper>;

    }

}
