import React, {FC, useEffect, useState}     from "react";
import {block}                              from "./block";
import {SVGTaskStocksArrowsType}            from "Smt/SmtAnswerWidget/@types";

const el = block.el('task-money-transfer-arrows');

const Arrows: (keyof SmtTask.TaskMoneyTransferArrowsWidgetSelected)[] = ["ab", "bd", "ag", "gd", "gb", "fd", "ef", "ca", "ce", "eg", "fg"];

const getSelectedRectsFilter = ([,v]: [string, boolean])=>!!v;

const getSelectedRects = (selected:  SmtTask.TaskMoneyTransferArrowsWidgetSelected) =>
    [...new Set(
        Object
            .entries( selected )
            .filter( getSelectedRectsFilter )
            .reduce(
                (acc: string[], [k])=>([...acc, ...k.split('')])
                ,
                []
            )
    )]
;

const vInArr = (ch: string, arr: string[]) => arr.includes(ch)

export const SVGTaskMoneyTransferArrows:FC<SVGTaskStocksArrowsType> = ({
    answerWidget,
    disabled,
    children
}) => {
    const [ selected,    setSelected ]    = useState<SmtTask.TaskMoneyTransferArrowsWidgetSelected>(answerWidget.selected);
    const [ checkedRect, setCheckedRect ] = useState<string | null>(null);
    const [ onRect,      setOnRect ]      = useState<string | null>(null);

    const toggleSelectedArrow = (arrow: keyof SmtTask.TaskMoneyTransferArrowsWidgetSelected) => {
        if (!disabled){
            setSelected({
                ...selected,
                [arrow]: !selected[arrow]
            })
        }
    }

    const onRectClick = (rect: string) => () => {
        if (!disabled){
            if (checkedRect) {
                if (checkedRect === rect) {
                    setCheckedRect( null );
                } else if (onRect){
                    const arrow = Arrows
                        .filter( a => a.split('').includes(checkedRect) )
                        .find(   a => a.split('').includes(onRect))
                    ;
                    if (arrow) {
                        setCheckedRect(null);
                        toggleSelectedArrow(arrow);
                    }
                }
            } else {
                setCheckedRect( rect );
            }
        }
    }

    const onRectMouseEnter = (rect: string) => () => {
        if (!disabled){
            setOnRect(rect)
        }
    }

    const onRectMouseLeave = (rect: string) => () => {
        if (!disabled){
            setOnRect(null)
        }
    }

    const isArrowHL = (arrow: string) => {
        return arrow.split('').includes(onRect);
    }

    const reset = ()=>{
        setCheckedRect(null);
        setSelected(answerWidget.selected)
    }

    const selectedRects = getSelectedRects(selected)

    return <>
            <svg className={el + el.mod('disabled', disabled ? 'true' : 'false' )} width="269" height="345" viewBox="0 0 269 345" fill="none">
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("ab")) +
                        el.bod('arrow-selected', selected.ab)
                    }
                    onMouseUp={()=>toggleSelectedArrow('ab')}
                >
                    <path
                        className={ el.mod('arrow-path') }
                        d="M127 21C126.448 21 126 21.4477 126 22C126 22.5523 126.448 23 127 23V21ZM151.707 22.7071C152.098 22.3166 152.098 21.6834 151.707 21.2929L145.343 14.9289C144.953 14.5384 144.319 14.5384 143.929 14.9289C143.538 15.3195 143.538 15.9526 143.929 16.3431L149.586 22L143.929 27.6569C143.538 28.0474 143.538 28.6805 143.929 29.0711C144.319 29.4616 144.953 29.4616 145.343 29.0711L151.707 22.7071ZM127 23H151V21H127V23Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("ef")) +
                        el.bod('arrow-selected', selected.ef)
                    }
                    onMouseUp={()=>toggleSelectedArrow('ef')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M127 205C126.448 205 126 205.448 126 206C126 206.552 126.448 207 127 207V205ZM151.707 206.707C152.098 206.317 152.098 205.683 151.707 205.293L145.343 198.929C144.953 198.538 144.319 198.538 143.929 198.929C143.538 199.319 143.538 199.953 143.929 200.343L149.586 206L143.929 211.657C143.538 212.047 143.538 212.681 143.929 213.071C144.319 213.462 144.953 213.462 145.343 213.071L151.707 206.707ZM127 207H151V205H127V207Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("ca")) +
                        el.bod('arrow-selected', selected.ca)
                    }
                    onMouseUp={()=>toggleSelectedArrow('ca')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M71 92C71 92.5523 71.4477 93 72 93C72.5523 93 73 92.5523 73 92L71 92ZM72.7071 43.2929C72.3166 42.9024 71.6834 42.9024 71.2929 43.2929L64.9289 49.6569C64.5384 50.0474 64.5384 50.6805 64.9289 51.0711C65.3195 51.4616 65.9526 51.4616 66.3431 51.0711L72 45.4142L77.6569 51.0711C78.0474 51.4616 78.6805 51.4616 79.0711 51.0711C79.4616 50.6805 79.4616 50.0474 79.0711 49.6569L72.7071 43.2929ZM73 92L73 44L71 44L71 92L73 92Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("fd")) +
                        el.bod('arrow-selected', selected.fd)
                    }
                    onMouseUp={()=>toggleSelectedArrow('fd')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M197 184C197 184.552 197.448 185 198 185C198.552 185 199 184.552 199 184L197 184ZM198.707 135.293C198.317 134.902 197.683 134.902 197.293 135.293L190.929 141.657C190.538 142.047 190.538 142.681 190.929 143.071C191.319 143.462 191.953 143.462 192.343 143.071L198 137.414L203.657 143.071C204.047 143.462 204.681 143.462 205.071 143.071C205.462 142.681 205.462 142.047 205.071 141.657L198.707 135.293ZM199 184L199 136L197 136L197 184L199 184Z"
                    />
                </svg>

                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("bd")) +
                        el.bod('arrow-selected', selected.bd)
                    }
                    onMouseUp={()=>toggleSelectedArrow('bd')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M199 44C199 43.4477 198.552 43 198 43C197.448 43 197 43.4477 197 44L199 44ZM197.293 92.7071C197.683 93.0976 198.317 93.0976 198.707 92.7071L205.071 86.3431C205.462 85.9526 205.462 85.3195 205.071 84.9289C204.681 84.5384 204.047 84.5384 203.657 84.9289L198 90.5858L192.343 84.9289C191.953 84.5384 191.319 84.5384 190.929 84.9289C190.538 85.3195 190.538 85.9526 190.929 86.3431L197.293 92.7071ZM197 44L197 92L199 92L199 44L197 44Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("ce")) +
                        el.bod('arrow-selected', selected.ce)
                    }
                    onMouseUp={()=>toggleSelectedArrow('ce')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M73 136C73 135.448 72.5523 135 72 135C71.4477 135 71 135.448 71 136L73 136ZM71.2929 184.707C71.6834 185.098 72.3166 185.098 72.7071 184.707L79.0711 178.343C79.4616 177.953 79.4616 177.319 79.0711 176.929C78.6805 176.538 78.0474 176.538 77.6569 176.929L72 182.586L66.3431 176.929C65.9526 176.538 65.3195 176.538 64.9289 176.929C64.5384 177.319 64.5384 177.953 64.9289 178.343L71.2929 184.707ZM71 136L71 184L73 184L73 136L71 136Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("eg")) +
                        el.bod('arrow-selected', selected.eg)
                    }
                    onMouseUp={()=>toggleSelectedArrow('eg')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M73 228C73 227.448 72.5523 227 72 227C71.4477 227 71 227.448 71 228L73 228ZM151.707 298.707C152.098 298.317 152.098 297.683 151.707 297.293L145.343 290.929C144.953 290.538 144.319 290.538 143.929 290.929C143.538 291.319 143.538 291.953 143.929 292.343L149.586 298L143.929 303.657C143.538 304.047 143.538 304.681 143.929 305.071C144.319 305.462 144.953 305.462 145.343 305.071L151.707 298.707ZM71 228L71 282L73 282L73 228L71 228ZM88 299L151 299L151 297L88 297L88 299ZM71 282C71 291.389 78.6112 299 88 299L88 297C79.7157 297 73 290.284 73 282L71 282Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("ag")) +
                        el.bod('arrow-selected', selected.ag)
                    }
                    onMouseUp={()=>toggleSelectedArrow('ag')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M198.707 320.009C198.317 319.618 197.683 319.618 197.293 320.009L190.929 326.372C190.538 326.763 190.538 327.396 190.929 327.787C191.319 328.177 191.953 328.177 192.343 327.787L198 322.13L203.657 327.787C204.047 328.177 204.681 328.177 205.071 327.787C205.462 327.396 205.462 326.763 205.071 326.372L198.707 320.009ZM16.7981 23C17.3504 23 17.7981 22.5523 17.7981 22C17.7981 21.4477 17.3504 21 16.7981 21L16.7981 23ZM1.8839e-07 37.7981L3.64901e-06 328L2 328L2 37.7981L1.8839e-07 37.7981ZM17 345L182 345L182 343L17 343L17 345ZM199 328L199 320.716L197 320.716L197 328L199 328ZM182 345C191.389 345 199 337.389 199 328L197 328C197 336.284 190.284 343 182 343L182 345ZM3.64901e-06 328C3.76097e-06 337.389 7.61116 345 17 345L17 343C8.71573 343 2 336.284 2 328L3.64901e-06 328ZM2 37.7981C2 29.6253 8.62535 23 16.7981 23L16.7981 21C7.52078 21 7.77594e-08 28.5208 1.8839e-07 37.7981L2 37.7981Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("gb")) +
                        el.bod('arrow-selected', selected.gb)
                    }
                    onMouseUp={()=>toggleSelectedArrow('gb')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M245 297C244.448 297 244 297.448 244 298C244 298.552 244.448 299 245 299V297ZM244.293 21.2929C243.902 21.6834 243.902 22.3166 244.293 22.7071L250.657 29.0711C251.047 29.4616 251.681 29.4616 252.071 29.0711C252.462 28.6805 252.462 28.0474 252.071 27.6569L246.414 22L252.071 16.3431C252.462 15.9526 252.462 15.3195 252.071 14.9289C251.681 14.5384 251.047 14.5384 250.657 14.9289L244.293 21.2929ZM258 22V23V22ZM245 299H258V297H245V299ZM275 282V38H273V282H275ZM258 21L245 21L245 23L258 23L258 21ZM275 38C275 28.6112 267.389 21 258 21L258 23C266.284 23 273 29.7157 273 38H275ZM258 299C267.389 299 275 291.389 275 282H273C273 290.284 266.284 297 258 297V299Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("gd")) +
                        el.bod('arrow-selected', selected.gd)
                    }
                    onMouseUp={()=>toggleSelectedArrow('gd')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M245 297C244.448 297 244 297.448 244 298C244 298.552 244.448 299 245 299V297ZM244.293 113.293C243.902 113.683 243.902 114.317 244.293 114.707L250.657 121.071C251.047 121.462 251.681 121.462 252.071 121.071C252.462 120.681 252.462 120.047 252.071 119.657L246.414 114L252.071 108.343C252.462 107.953 252.462 107.319 252.071 106.929C251.681 106.538 251.047 106.538 250.657 106.929L244.293 113.293ZM245 299H252V297H245V299ZM269 282V130H267V282H269ZM252 113L245 113L245 115L252 115V113ZM269 130C269 120.611 261.389 113 252 113V115C260.284 115 267 121.716 267 130H269ZM252 299C261.389 299 269 291.389 269 282H267C267 290.284 260.284 297 252 297V299Z"
                    />
                </svg>
                <svg
                    className={
                        el.mod('arrow') +
                        el.bod('arrow-hl', isArrowHL("fg")) +
                        el.bod('arrow-selected', selected.fg)
                    }
                    onMouseUp={()=>toggleSelectedArrow('fg')}
                >
                    <path
                        className={el.mod('arrow-path')}
                        d="M199 228C199 227.448 198.552 227 198 227C197.448 227 197 227.448 197 228L199 228ZM197.293 276.707C197.683 277.098 198.317 277.098 198.707 276.707L205.071 270.343C205.462 269.953 205.462 269.319 205.071 268.929C204.681 268.538 204.047 268.538 203.657 268.929L198 274.586L192.343 268.929C191.953 268.538 191.319 268.538 190.929 268.929C190.538 269.319 190.538 269.953 190.929 270.343L197.293 276.707ZM197 228L197 276L199 276L199 228L197 228Z"
                    />
                </svg>
{/*============*/}
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "a" ) +
                        el.bod('rect-selected', vInArr("a", selectedRects) )
                    }
                    onClick      = { onRectClick("a") }
                    onMouseEnter = { onRectMouseEnter("a") }
                    onMouseLeave = { onRectMouseLeave("a") }
                    x="17"
                    y="0"
                >
                    <rect className={el.mod('rect-shape')} width="110" height="44" rx="22" />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={110 / 2} y={26} textAnchor="middle">Юридическое</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "b" ) +
                        el.bod('rect-selected', vInArr("b", selectedRects) )
                    }
                    onClick      = { onRectClick("b") }
                    onMouseEnter = { onRectMouseEnter("b") }
                    onMouseLeave = { onRectMouseLeave("b") }
                    x="151"
                    y="0"
                >
                    <rect className={el.mod('rect-shape')} width="94"  height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={94  / 2} y={26} textAnchor="middle">Ивент</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "c" ) +
                        el.bod('rect-selected', vInArr("c", selectedRects) )
                    }
                    onClick      = { onRectClick("c") }
                    onMouseEnter = { onRectMouseEnter("c") }
                    onMouseLeave = { onRectMouseLeave("c") }
                    x="17"
                    y="92"
                >
                    <rect className={el.mod('rect-shape')} width="110" height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={110 / 2} y={26} textAnchor="middle">Консалтинговое</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "d" ) +
                        el.bod('rect-selected', vInArr("d", selectedRects) ) +
                        " d "
                    }
                    onClick      = { onRectClick("d") }
                    onMouseEnter = { onRectMouseEnter("d") }
                    onMouseLeave = { onRectMouseLeave("d") }
                    x="151"
                    y="92"
                >
                    <rect className={el.mod('rect-shape')} width="94"  height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={94  / 2} y={26} textAnchor="middle">Свой счёт</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "e" ) +
                        el.bod('rect-selected', vInArr("e", selectedRects) )
                    }
                    onClick      = { onRectClick("e") }
                    onMouseEnter = { onRectMouseEnter("e") }
                    onMouseLeave = { onRectMouseLeave("e") }
                    x="17"
                    y="184"
                >
                    <rect className={el.mod('rect-shape')} width="110" height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={110 / 2} y={26} textAnchor="middle">Парикмахерская</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "f" ) +
                        el.bod('rect-selected', vInArr("f", selectedRects) )
                    }
                    onClick      = { onRectClick("f") }
                    onMouseEnter = { onRectMouseEnter("f") }
                    onMouseLeave = { onRectMouseLeave("f") }
                    x="151"
                    y="184"
                >
                    <rect className={el.mod('rect-shape')} width="94"  height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={94  / 2} y={26} textAnchor="middle">Ресторан</text>
                </svg>
                <svg
                    className={
                        el.mod('rect') +
                        el.bod('rect-checked', checkedRect === "g" ) +
                        el.bod('rect-selected', vInArr("g", selectedRects) )
                    }
                    onClick      = { onRectClick("g") }
                    onMouseEnter = { onRectMouseEnter("g") }
                    onMouseLeave = { onRectMouseLeave("g") }
                    x="151"
                    y="276"
                >
                    <rect className={el.mod('rect-shape')} width="94"  height="44" rx="22"  />
                    <text className={el.mod('rect-label') + 'text-xxs'} x={94  / 2} y={26} textAnchor="middle">Шиномонтаж</text>
                </svg>
                {
                    !disabled &&
                    <svg onMouseUp={reset} className={el.mod('reset')} width="32" height="32" viewBox="0 0 32 32" fill="none" x={259} y={0}>
                        <rect width="32" height="32" fill={"transparent"}/>
                        <path className={el.mod('reset-path')} d="M20.7086 11.2916C19.3502 9.93325 17.4252 9.14991 15.3086 9.36658C12.2502 9.67491 9.73355 12.1582 9.39189 15.2166C8.93355 19.2582 12.0586 22.6666 16.0002 22.6666C18.6586 22.6666 20.9419 21.1082 22.0086 18.8666C22.2752 18.3082 21.8752 17.6666 21.2586 17.6666C20.9502 17.6666 20.6586 17.8332 20.5252 18.1082C19.5836 20.1332 17.3252 21.4166 14.8586 20.8666C13.0086 20.4582 11.5169 18.9499 11.1252 17.0999C10.4252 13.8666 12.8836 10.9999 16.0002 10.9999C17.3836 10.9999 18.6169 11.5749 19.5169 12.4832L18.2586 13.7416C17.7336 14.2666 18.1002 15.1666 18.8419 15.1666H21.8336C22.2919 15.1666 22.6669 14.7916 22.6669 14.3332V11.3416C22.6669 10.5999 21.7669 10.2249 21.2419 10.7499L20.7086 11.2916Z"/>
                    </svg>
                }
            </svg>

            {children}
        </>
    ;
}
