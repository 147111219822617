import React          from "react";
import {HBox}         from "@sirius/ui-lib/src/blocks/Layout";
import {block}        from "Smt/SmtProgrammingAnswer/block";
import {useDownload}  from "@sirius/ui-lib/src/hooks/useDownload";

type CopyPanelProps = {
    code:     SmtCache.Code;
    language: SmtCache.Language;
    isRound:  boolean;
}

enum LangExtension  {
    python3               = 'py',
    'python3-ds'          = 'py',
    cpp                   = 'cpp',
    java                  = 'java',
    kotlin                = 'kt',
    pascal                = 'pas',
    csharp                = 'cs',
    go                    = 'go',
    php                   = 'php',
    rust                  = 'rs',
    kumir                 = 'kum',
    'cpp-no-optimization' = 'cpp'
}

export const CopyPanel = ({code, language, isRound}:CopyPanelProps) => {

    const info = {
      filename:   'code',
      content:    code,
      ext:        LangExtension[language as keyof typeof LangExtension],
    };

    const [downloadFile] = useDownload(info);

    const copyCode = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigator.clipboard.writeText(code);
    };

    const downloadCodeFile = (e: React.MouseEvent) => {
        e.stopPropagation();
        downloadFile();
    };

    return(
        <HBox className = {block.el('copy-panel') +
                          block.el('copy-panel').bod('round', isRound)}
        >
            <img onClick   = {copyCode}
                 className = {block.el('copy-panel').mod('icon')}
                 src       = {require("../../img/icon_copy.svg")}
                 alt       = "Скопировать"
            />
            <img onClick   = {downloadCodeFile}
                 className = {block.el('copy-panel').mod('icon')}
                 src       = {require("../../img/icon_download.svg")}
                 alt       = "Скачать файл"
            />
        </HBox>
    );
};
