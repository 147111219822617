import React, {useEffect, useState}     from "react";
import Solutions                        from "Cheops/components/ModulePassing/Solutions";
import BemClassName                     from "Cheops/BemClassName";
import BodyStyles                       from "Lab/Utils/BodyStyles";
import MarkedWithAnswers                from "Smt/MarkedWithAnswers";
import {CheopsLocaleString}             from "Cheops/CheopsLocaleBlock";
import                                       "./SolutionToShowPopup.less";
import {SmtAnswerWidget} from "Smt/SmtAnswerWidget";

interface SolutionToShowPopupProps {
    onClose?:             () => void;
    renderInDescription?: boolean;
    courseId?:            number;
    moduleId?:            number;
    elementProgress:      any;
    elementContent:       any;
    isSmt?:               boolean;
    answerWidget?:        SmtTask.AnswerWidget
}

const SolutionToShowPopup: React.FC<SolutionToShowPopupProps> = ({
    courseId,
    moduleId,
    elementProgress,
    elementContent,
    onClose,
    isSmt,
    renderInDescription = false,
    answerWidget
}) => {

    const [showUserAnswer, setShowUserAnswer] = useState(false);

    useEffect(
        () => {
            BodyStyles.setBodyScrollEventDisabled(true);
            return () => {
                BodyStyles.setBodyScrollEventDisabled(false);
            };
        },
        []
    );

    const {type: typeArr} = elementContent as {type: string[]};
    const [solutionType] = typeArr;
    const hasUserAnswer = solutionType !== "programming" && !!elementProgress.solution.answers.length;
    const solutionsClass = new BemClassName('solution_to_show_popup__solutions');
          solutionsClass.appendStatusIf(showUserAnswer, 'show_user_answer');
          solutionsClass.appendStatusIf(renderInDescription, 'inline')
    ;
    const popupClass = new BemClassName('solution_to_show_popup');
          popupClass.appendStatusIf(isSmt, 'is-smt');
          popupClass.appendStatusIf(!!solutionType, solutionType)
    ;

    const showAnswerWidget = answerWidget && answerWidget.type === "EulerCircles";

    return  <div
                className={popupClass.toString()}
                onClick={onClose}
            >
                <div className="solution_to_show_popup__close" />
                <div
                    className="solution_to_show_popup__window"
                    onClick={(event) => event.stopPropagation()}
                >
                    <div className={solutionsClass.toString()}>
                        {
                            showAnswerWidget
                            ?   <SmtAnswerWidget
                                    answerWidget= {answerWidget}
                                    disabled    = {true}
                                    verdict     = {
                                        showUserAnswer
                                            ? elementProgress?.verdict
                                            : "ok"
                                    }
                                    userAnswer  = {
                                        showUserAnswer
                                            ? elementProgress.solution.answers[ 0 ].answer
                                            : elementProgress.solution.solutionToShow
                                    }
                                />
                            : <>
                                <div className="solution_to_show_popup__solution_text">
                                {
                                    renderInDescription
                                    ? <MarkedWithAnswers
                                            isSmt={isSmt}
                                            renderAnswers
                                            showResults
                                            forceShowRight
                                            element_content={elementContent}
                                            element_progress={elementProgress}
                                        >{elementContent.description}</MarkedWithAnswers>
                                    : <Solutions
                                            isSmt={isSmt}
                                            isSolutionToShow
                                            element_progress={elementProgress}
                                            element_content={elementContent}
                                            course_id={courseId}
                                            module_id={moduleId}
                                            force_show_right={true}
                                        />
                                }
                                </div>
                                {
                                    hasUserAnswer &&
                                    <div className="solution_to_show_popup__solution_text">
                                        {
                                            renderInDescription
                                            ?   <MarkedWithAnswers
                                                    isSmt={isSmt}
                                                    renderAnswers
                                                    showResults
                                                    isSolutionToShow
                                                    element_content={elementContent}
                                                    element_progress={elementProgress}
                                                >{elementContent.description}</MarkedWithAnswers>
                                            :   <Solutions
                                                    isSmt={isSmt}
                                                    isSolutionToShow
                                                    element_progress={elementProgress}
                                                    element_content={elementContent}
                                                    course_id={courseId}
                                                    module_id={moduleId}
                                                />
                                        }
                                    </div>
                                }
                            </>
                        }

                    </div>
                    {
                        hasUserAnswer &&
                        <div
                            className="solution_to_show_popup__toggle"
                            onClick={() => setShowUserAnswer(!showUserAnswer)}
                        >
                            {
                                showUserAnswer
                                ? <CheopsLocaleString k="cheops.solution_to_show_popup.right_answer" id="value" />
                                : <CheopsLocaleString k="cheops.solution_to_show_popup.my_answer" id="value" />
                            }
                        </div>
                    }
                </div>
            </div>
;

};

export default SolutionToShowPopup;
