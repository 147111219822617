import React from "react";
import UIElement from "Lab/components/UI2/UIElement";
import UIElementWrapper from "Lab/components/UI2/UIElementWrapper";
import ButtonControl, {BUTTON_COLOR, BUTTON_VARIANTS} from "Lab/components/UI2/Controls/ButtonControl";
import AspectElementsGroup from "Lab/components/UI2/Elements/AspectElementsGroup";
import BemClassName from "Cheops/BemClassName";
import * as testClass from "Lab/TestClasses.json";
import "./ConfirmEditButtonsElement.less";

type State = {};

type Props = {
    confirmDisabled?: boolean;
    onConfirm(e?: React.MouseEvent): void;
    onCancel(e?: React.MouseEvent): void;
    className?: string;
    variant?: BUTTON_VARIANTS;
};


export default class ConfirmEditButtonsElement extends UIElement<Props, State> {

    static defaultProps = {
        ...UIElement.defaultProps,
        confirmDisabled: false,
        variant: BUTTON_VARIANTS.TEXT,
    };

    render(): React.ReactNode {

        const {onCancel, onConfirm, confirmDisabled, className, variant} = this.props;
        const ButtonsClassName = new BemClassName("confirm_edit_buttons", null, className);

        return <AspectElementsGroup className={ButtonsClassName.toString()}>
            <UIElementWrapper className="confirm_edit_buttons__wrapper">

                <ButtonControl
                    onClick={onConfirm}
                    color={BUTTON_COLOR.PRIMARY}
                    disabled={confirmDisabled}
                    className={testClass.ConfirmEditButtonsConfirm}
                    variant={variant}
                >
                    Готово
                </ButtonControl>

                <ButtonControl
                    onClick={onCancel}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    className={`confirm_edit_buttons__cancel ${testClass.ConfirmEditButtonsCancel}`}
                >
                    Отмена
                </ButtonControl>

            </UIElementWrapper>
        </AspectElementsGroup>;

    }

}
